export const cl = {
  styledAutocomplete: {
    paperWrapper: {
      border: "1px solid #D5D9D9",
      width: "100%",
      backgroundColor: "#ffffff",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12) !important",
      borderRadius: "4px",
      marginTop: "8px",
      padding: "8px 0",
      position: "fixed",
      zIndex: 1300,
      overflow: "auto",
    },
    formSx: {
      "& .MuiOutlinedInput-root": {
        pr: "8px",
        "& fieldset": {
          borderColor: "rgb(213,217,217)",
        },
      },
      "& .MuiOutlinedInput-root:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#47A06D",
        },
      },
    },
  },
};
