import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DISCOUNT_TYPES } from "./constants";
import { containsEvery } from "helpers/helpers";
import { error, success } from "utils/notifications";
import { SCROLL_LIMIT } from "utils/constants";
import {
  getProductsPriceListsByIdService,
  updatePriceListProductsService,
  updateProductsPriceListService,
} from "services/priceLists";
import { GlobalPageContext } from "Pages/MasterPage/MasterPage";
import { useDebounce } from "helpers/hooks";

const SORT_FIELDS = ["sort_by_price", "sort_by_manufacturer", "sort_by_name"];

const DEFAULT_PARAMS = {
  page: 1,
  limit: SCROLL_LIMIT,
  search: "",
  sort_by_price: "",
  sort_by_manufacturer: "",
  sort_by_name: "asc",
};

export const useProducts = (currentPriceList) => {
  const { setGlobalLoading } = useContext(GlobalPageContext);
  const [openAllProducts, setOpenAllProducts] = useState(false);
  const [tempCheckedProducts, setTempCheckedProducts] = useState([]);
  const [productMessages, setProductMessages] = useState({});
  const [allProductsList, setAllProductsList] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productsListState, setProductsListState] = useState({
    list: [],
    loading: true,
    count: 0,
  });
  const [priceListParams, setPriceListParams] = useState(DEFAULT_PARAMS);

  const setListSearch = (search) => {
    setPriceListParams((prev) => ({ ...prev, search }));
  };

  const searchInputDebounced = useDebounce(priceListParams.search, 500);

  const onOpenAllProducts = () => setOpenAllProducts(true);
  const onCloseAllProducts = () => setOpenAllProducts(false);

  const onCancelProductSelection = () => setSelectedProducts([]);

  const checkForProductSelection = (id) => {
    return selectedProducts.some((customer) => customer.id === id);
  };

  const onProductSelect = (product) => {
    if (checkForProductSelection(product.id)) {
      const filteredList = selectedProducts.filter(
        ({ id }) => id !== product.id
      );

      return setSelectedProducts(filteredList);
    }
    return setSelectedProducts((prev) => [...prev, product]);
  };

  const getAllProductsForPopup = useCallback(async () => {
    if (allProductsList.length === productsListState.list.length) {
      setAllProductsList(productsListState.list);
      setOpenAllProducts(true);
      return;
    }

    setGlobalLoading(true);
    try {
      const res = await getProductsPriceListsByIdService(currentPriceList.id);
      const rows = res?.rows || [];

      setAllProductsList(rows);
      setOpenAllProducts(true);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setGlobalLoading(false);
    }
  }, [
    allProductsList.length,
    currentPriceList.id,
    productsListState.list,
    setGlobalLoading,
  ]);

  const fetchAndCheckAll = async () => {
    if (productsListState.list.length < productsListState.count) {
      setLoading(true);
      try {
        const res = await getProductsPriceListsByIdService(currentPriceList.id);

        const rows = res?.rows || [];
        const count = res?.count || 0;

        setProductsListState((prev) => ({
          ...prev,
          list: rows,
          count,
        }));

        setSelectedProducts(rows);

        const preparedList = rows.map((product) => product?.product);
        setTempCheckedProducts(preparedList);
        return;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        //setProductMessage(id, "error");
      } finally {
        setLoading(false);
      }
    }
    setSelectedProducts(productsListState.list);
    const preparedList = productsListState.list.map(
      (product) => product?.product
    );
    setTempCheckedProducts(preparedList);
  };

  const checkAllProducts = () => {
    if (selectedProducts.length !== productsListState.list.length)
      return setSelectedProducts(productsListState.list);
    return setSelectedProducts([]);
  };

  const onUncheckAllProducts = useCallback(() => setSelectedProducts([]), []);

  const onProductStateUpdate = (updates) =>
    setProductsListState((prev) => ({ ...prev, ...updates }));

  const setProductMessage = (id, message, type) => {
    setProductMessages((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [type]: message,
      },
    }));

    if (type === "loading" || type === "error") return;

    setTimeout(() => {
      setProductMessages((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          [type]: "",
        },
      }));
    }, 2000);
  };

  const onProductByIdUpdate = useCallback(async (id, data, skipIcons) => {
    if (skipIcons) {
      try {
        await updateProductsPriceListService(id, data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      }
      return;
    }

    try {
      setProductMessages((prev) => ({
        ...prev,
        [id]: {
          ...prev[id],
          success: "",
          loading: true,
        },
      }));

      await updateProductsPriceListService(id, data);
      setProductMessage(id, "", "loading");
      setProductMessage(id, true, "success");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    }
  }, []);

  const setLoading = (loading) =>
    setProductsListState((prev) => ({ ...prev, loading }));

  const handleFetchProducts = useCallback(
    async (page) => {
      if (!currentPriceList.id) return;
      if (!page) {
        setLoading(true);
      }

      const isFetchingLastElement =
        page > 1 && productsListState.count === SCROLL_LIMIT;

      try {
        const res = await getProductsPriceListsByIdService(
          currentPriceList.id,
          {
            ...priceListParams,
            page: isFetchingLastElement ? 1 : page ? page : 1,
          }
        );

        const rows = res?.rows || [];
        const count = res?.count || 0;

        setProductsListState((prev) => ({
          ...prev,
          list:
            page > 1 && !isFetchingLastElement ? [...prev.list, ...rows] : rows,
          count,
        }));

        const preparedList = rows.map((product) => product?.product);

        setTempCheckedProducts((prev) =>
          page > 1 && !isFetchingLastElement
            ? [...prev, ...preparedList]
            : preparedList
        );

        setPriceListParams((prev) => ({
          ...prev,
          page: isFetchingLastElement ? 1 : page ? page : 1,
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setLoading(false);
        setGlobalLoading(false);
      }
    },
    [
      currentPriceList.id,
      priceListParams,
      productsListState.count,
      setGlobalLoading,
    ]
  );

  const onSaveProductsFromPopup = async () => {
    try {
      setGlobalLoading(true);
      await updatePriceListProductsService(currentPriceList.id, {
        productsIds: tempCheckedProducts.map(
          (product) => product.product?.id || product.id
        ),
      });

      if (tempCheckedProducts.length)
        success(
          tempCheckedProducts.length === 1 ? "Product added" : "Products added"
        );
      if (tempCheckedProducts.length) return await handleFetchProducts();

      onProductStateUpdate({
        list: [],
        count: 0,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setGlobalLoading(false);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setGlobalLoading(false);
    }
  };

  useEffect(() => {
    if (!currentPriceList?._count.products) setLoading(false);
    handleFetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPriceList._count.products,
    searchInputDebounced,
    priceListParams?.sort_by_price,
    priceListParams?.sort_by_manufacturer,
    priceListParams?.sort_by_name,
  ]);

  const onTempProductSelect = useCallback(
    (product) => {
      if (product.isMultiple) {
        const filteredChildren = product.childProducts.filter(
          (childProduct) =>
            !productsListState.list?.some((prod) => prod.id === childProduct.id)
        );
        if (containsEvery(filteredChildren, tempCheckedProducts)) {
          const newChecked = [...tempCheckedProducts];
          product.childProducts.forEach((childProduct) => {
            const index = newChecked.findIndex(
              (checkedCustomer) => checkedCustomer.id === childProduct.id
            );
            newChecked.splice(index, 1);
          });
          return setTempCheckedProducts([...newChecked]);
        }
        const addProducts = filteredChildren.map((childProduct) => {
          return { ...childProduct, quantity: 1, parentProduct: product };
        });
        const uniqueProducts = [
          ...new Map(
            [...tempCheckedProducts, ...addProducts].map((item) => [
              item["id"],
              item,
            ])
          ).values(),
        ];
        return setTempCheckedProducts(uniqueProducts);
      }
      const index = tempCheckedProducts.findIndex(
        (item) => item.id === product.id
      );
      if (index > -1) {
        const newProducts = tempCheckedProducts.filter(
          (item) => item.id !== product.id
        );
        return setTempCheckedProducts(newProducts);
      }
      setTempCheckedProducts([...tempCheckedProducts, product]);
    },
    [tempCheckedProducts, productsListState.list]
  );

  const allProductsChecked = useMemo(
    () => productsListState.list.length === selectedProducts.length,
    [productsListState.list.length, selectedProducts.length]
  );

  const handleSetSort = useCallback(
    (sortField) => {
      const sortFieldToDefault = Object.keys(priceListParams).filter(
        (item) => SORT_FIELDS.includes(item) && item !== sortField
      );

      setPriceListParams({
        ...priceListParams,
        [sortField]: priceListParams?.[sortField] !== "desc" ? "desc" : "asc",
        ...sortFieldToDefault.reduce(
          (acc, field) => ({ ...acc, [field]: "" }),
          {}
        ),
      });
    },
    [priceListParams]
  );

  const updateFieldByIndex = ({ index, field, value, skipIcons = false }) => {
    const isDiscountValue = field === "discountValue";
    const list = productsListState.list;
    const item = list[index];
    if (!item) return;

    list[index] = {
      ...item,
      discountValue: !isDiscountValue ? 0 : +item.discountValue || 0,
      [field]: value,
    };

    onProductByIdUpdate(
      item.id,
      {
        discountValue: !isDiscountValue ? 0 : +item.discountValue || 0,
        discountType: item.discountType,
        [field]: isDiscountValue ? Number(value || 0) : value,
      },
      skipIcons
    );
    setProductsListState((prev) => ({ ...prev, list }));
  };

  const getFinalPrice = (price, discountValue, discountType) => {
    if (!discountValue) return price;

    switch (discountType) {
      case DISCOUNT_TYPES[0]:
        return price - price * (discountValue / 100);
      case DISCOUNT_TYPES[1]:
        return price - discountValue;
      default:
        return price;
    }
  };

  const quickSort = useMemo(() => {
    return {
      sort_by_price: priceListParams?.sort_by_price,
      sort_by_manufacturer: priceListParams?.sort_by_manufacturer,
      sort_by_name: priceListParams?.sort_by_name,
    };
  }, [
    priceListParams?.sort_by_price,
    priceListParams?.sort_by_manufacturer,
    priceListParams?.sort_by_name,
  ]);

  const isAllowed = (discountType, values) => {
    const { floatValue, formattedValue } = values;

    if (discountType === "PERCENTAGE")
      return (
        (floatValue <= 100 &&
          floatValue >= 0 &&
          !formattedValue.includes("-") &&
          !/^0\d/.test(formattedValue)) ||
        formattedValue === ""
      );

    return (
      (!/^0\d/.test(formattedValue) && formattedValue.length <= 10) ||
      formattedValue === ""
    );
  };

  return {
    selectedProducts,
    setSelectedProducts,
    productsList: productsListState.list,
    productsCount: productsListState.count,
    productsLoading: productsListState.loading,
    allPricesChecked: false,
    checkAllProducts,
    onUncheckAllProducts,
    onProductStateUpdate,
    handleFetchProducts,
    onProductSelect,
    onCancelProductSelection,
    checkForProductSelection,
    tempCheckedProducts,
    onTempProductSelect,
    onOpenAllProducts,
    onCloseAllProducts,
    openAllProducts,
    setTempCheckedProducts,
    allProductsChecked,
    onSaveProductsFromPopup,
    setListSearch,
    searchInput: priceListParams.search,
    page: priceListParams.page,
    quickSort,
    handleSetSort,
    updateFieldByIndex,
    getFinalPrice,
    productMessages,
    isAllowed,
    setProductsListState,
    setProductMessage,
    fetchAndCheckAll,
    getAllProductsForPopup,
    searchInputDebounced,
  };
};
