import { func, object, string } from "prop-types";

import { DrawerHeader } from "./components/DrawerHeader";
import { DrawerFooter } from "./components/DrawerFooter";
import { DrawerContent } from "./components/DrawerContent";

import { useCategoriesDrawer } from "./useCategoriesDrawer";

import { Box, Drawer } from "@mui/material";
import AllProductsPopup from "Pages/CartPage/components/AllProductsPopup/AllProductsPopup";
import { useCategoryProductsWithTabs } from "helpers/useCategoryProductsWithTabs";

export const AddCategoryDrawer = ({
  openState = null,
  onClose = () => {},
  onSaveCallback = () => {},
  creatingMsg = "Category created",
}) => {
  const {
    loading,
    isEdit,
    handleSubmit,
    onSubmit,
    control,
    formField,
    disabledSaveButton,
    onCloseDrawer,
    categoriesLoading,
    categoriesList,
    categoriesCount,
    categoriesPage,
    onDelete,
    setValue,
    handleFetchCategories,
    isParent,
    onConfirmProductsSelect,
    onSaveProductsFromPopup,
    getDataLoading,
    resetSelectedProducts,
    availableProducts,
    clearErrors,
    disabledDeleteCategories,
  } = useCategoriesDrawer({
    openState,
    onClose,
    onSaveCallback,
    creatingMsg,
  });

  const {
    showInactive,
    setShowInactive,
    changedIds,
    openAllProducts,
    onProductsPopupOpen,
    onCloseAllProducts,
    onTempProductSelect,
    tempCheckedProducts,
    onProductsStateUpdate,
    isAllUnchecked,
    setIsAllUnchecked,
    handleAddProducts,
  } = useCategoryProductsWithTabs({
    onSaveProductsFromPopup,
    onConfirmProductsSelect,
    productsList: formField?.categoryProducts || [],
    inactiveProductsList: formField?.inactiveCategoryProducts || [],
  });

  return (
    <Drawer
      anchor="right"
      open={openState.state}
      onClose={onCloseDrawer}
      PaperProps={{ sx: { width: "540px" } }}
    >
      <Box
        component="form"
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
        id="category-form-item"
        onSubmit={handleSubmit(onSubmit)}
      >
        <DrawerHeader
          titleLabel={isEdit ? "Edit Category" : "Add Category"}
          onClose={onCloseDrawer}
        />

        <DrawerContent
          {...{
            isParent,
            getDataLoading,
            setValue,
            isEdit,
            control,
            formField,
            categoriesLoading,
            categoriesList,
            categoriesCount,
            categoriesPage,
            handleFetchCategories,
            resetSelectedProducts,
            count: formField?.selectedCount + formField?.selectedInactiveCount,
            onEdit: onProductsPopupOpen,
            parentName: openState?.parent?.name,
            clearErrors,
          }}
        />
        <DrawerFooter
          {...{
            onClose: onCloseDrawer,
            loading,
            isEdit,
            disabledSaveButton,
            onDelete,
            disabledDeleteCategories,
          }}
        />
      </Box>

      {openAllProducts && (
        <AllProductsPopup
          showActiveTabs
          dialogProps={{
            BackdropProps: { sx: { backgroundColor: "transparent" } },
          }}
          isPriceList
          hideChildCheckbox
          headerTitle="Select Products"
          isOpen={openAllProducts}
          handleClose={() => onCloseAllProducts()}
          handleCheckProduct={onTempProductSelect}
          handleCancelProducts={() => onCloseAllProducts()}
          setCheckState={(data) => {
            onProductsStateUpdate({ tempCheckedProducts: data });
          }}
          handleAddProducts={handleAddProducts}
          checkedProducts={tempCheckedProducts}
          addedProducts={[]}
          allowMultipleSelect
          hideGreenNavigationBar
          isOrder
          skipExpanding
          hideAvailable
          disableOutOfStock={false}
          allowSelectAll
          allCheckedByDefault
          excludeIds={changedIds}
          setExcludeIds={(data) => onProductsStateUpdate({ changedIds: data })}
          availableAssignedProducts={availableProducts}
          isAllUncheckedProducts={formField.isAllUncheckedProducts}
          {...{
            showInactive,
            setShowInactive,
            setIsAllUnchecked,
            isAllUnchecked,
          }}
        />
      )}
    </Drawer>
  );
};

AddCategoryDrawer.propTypes = {
  openState: object,
  onClose: func,
  onSaveCallback: func,
  creatingMsg: string,
};
