export const handlePrepareSubmitData = (data, isEdit) => {
  const {
    name,
    parentCategoryId,
    isAllUncheckedProducts,
    changedIds,
    categoryProducts,
    showAllProducts,
    selectedCount,
    isAllUncheckedInactiveProducts,
    changedInactiveIds,
    inactiveCategoryProducts,
    showAllInactiveProducts,
    selectedInactiveCount,
  } = data;

  const preparedData = { name };

  if (typeof parentCategoryId === "string") {
    preparedData.parentCategoryId = null;
  } else {
    preparedData.parentCategoryId = parentCategoryId;
  }

  const categoryProductIds = categoryProducts?.length
    ? categoryProducts.map((item) => item.id)
    : [];

  const inactiveCategoryProductIds = inactiveCategoryProducts?.length
    ? inactiveCategoryProducts.map((item) => item.id)
    : [];

  if (!isEdit) {
    if (isAllUncheckedProducts) {
      preparedData.includeProductsIds = categoryProductIds?.length
        ? categoryProductIds
        : [];
    } else {
      preparedData.excludeProductsIds = changedIds?.length ? changedIds : null;
    }
  }

  if (isEdit) {
    if (showAllProducts) {
      preparedData.productsIds = null;
    } else if (categoryProducts.length) {
      preparedData.productsIds = categoryProductIds;
    } else {
      if (selectedCount === 0) {
        preparedData.productsIds = [];
      }
    }
  }

  if (!isEdit) {
    if (isAllUncheckedInactiveProducts) {
      preparedData.includeInactiveProductsIds =
        inactiveCategoryProductIds?.length ? inactiveCategoryProductIds : [];
    } else {
      preparedData.excludeInactiveProductsIds = changedInactiveIds?.length
        ? changedInactiveIds
        : null;
    }
  }

  if (isEdit) {
    if (showAllInactiveProducts) {
      preparedData.inactiveProductsIds = null;
    } else if (categoryProducts.length) {
      preparedData.inactiveProductsIds = inactiveCategoryProductIds;
    } else {
      if (selectedInactiveCount === 0) {
        preparedData.inactiveProductsIds = [];
      }
    }
  }

  return preparedData;
};
