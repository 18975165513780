import { object, bool, func, number, string, array } from "prop-types";
import { ActionControlBlock } from "./components/ActionControlBlock";

import { Box } from "@mui/material";

export const TableHeaderControlPanel = ({
  headerComponent,
  hasCheckedItems,
  cancelSelection,
  availableSelectCount,
  selectName,
  actionWrapperProps,
  onSelectVisible,
  onSelectAll,
  loading,
  loadingAllItems,
  checkedCount,
  actionsList,
  dropDownActions,
  counterNumber,
  loadingWrapperProps = {},
  isSmallScreen = false,
}) => {
  return (
    <Box sx={{ position: "relative" }}>
      {hasCheckedItems && (
        <ActionControlBlock
          {...{
            loading,
            selectName,
            actionsList,
            onSelectAll,
            onSelectVisible,
            dropDownActions,
            checkedCount,
            cancelSelection,
            actionWrapperProps,
            availableSelectCount,
            counterNumber,
            loadingAllItems,
            loadingWrapperProps,
            isSmallScreen,
          }}
        />
      )}
      {headerComponent}
    </Box>
  );
};

TableHeaderControlPanel.propTypes = {
  wrapperProps: object,
  hasCheckedItems: bool,
  cancelSelection: func,
  onSelectVisible: func,
  headerComponent: object,
  availableSelectCount: number,
  selectName: string,
  actionWrapperProps: object,
  onSelectAll: func,
  loading: bool,
  loadingAllItems: bool,
  checkedCount: number,
  actionsList: array,
  dropDownActions: array,
  counterNumber: number,
  loadingWrapperProps: object,
  isSmallScreen: bool,
};

TableHeaderControlPanel.defaultProps = {
  wrapperProps: {},
  hasCheckedItems: false,
  cancelSelection: () => {},
  onSelectVisible: () => {},
  onSelectAll: () => {},
  selectName: "",
  availableSelectCount: 0,
  checkedCount: 0,
  actionWrapperProps: {},
  loading: false,
  loadingAllItems: false,
  counterNumber: 0,
  actionsList: [],
  dropDownActions: [],
};
