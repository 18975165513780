import { useContext, useMemo } from "react";
import { object, bool, string, array } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { separateNumWithComma } from "helpers/helpers";

import { PhotoItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/PhotoItem";
import { NoteItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/NoteItem";
import { RemovedPhotoItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/RemovedPhotoItem";

import { OrderItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/OrderItem";
import { TaskItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/TaskItem";
import { CustomerProfileContext } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfilePage";
import { StyledTooltip } from "components";
import { useRepsPermissions } from "helpers/hooks";
import { FormItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/FormItem";

export const MainBlock = ({
  photoItems,
  setRemovedBy,
  setRemovedAt,
  isDeletedPic,
  isDeletedNote,
  isDeletedTask,
  isOnlyCheckIn,
  orders,
  notes,
  activity,
  forms,
}) => {
  const navigate = useNavigate();
  const repPermissions = useRepsPermissions();

  const onFormClick = (form) => {
    const reps = activity?.representativeDuplicate?.representativeId
      ? ["All reps", activity?.representativeDuplicate?.representativeId]
      : ["All reps"];
    const customers = activity.customer ? [activity.customer] : [];

    const state = {
      reps,
      customers,
      data: form.completedAt,
      tab: 1,
      showFilters: true,
    };

    navigate(`/form/${form?.form?.id}`, { state });
  };

  const {
    photoGroupPopupState,
    handleOpenTask,
    customerState,
    handleOpenGlobalNote,
  } = useContext(CustomerProfileContext);
  const { handleOpenPhotoGroupPopup } = photoGroupPopupState || {};

  const disabledFormsCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.forms?.create_edit;
    }
  }, [repPermissions]);

  return (
    <Box
      sx={{
        width: "100%",
        height: isOnlyCheckIn ? "auto" : "calc(100% - 46px)",
        display: "flex",
        alignItems: "center",
        px: 1.75,
        gap: 1.75,
      }}
    >
      {/* tasks */}
      {activity?.tasks?.length
        ? activity.tasks.map((task) => (
            <TaskItem
              key={task?.id}
              repPermissions={repPermissions}
              onClick={() => {
                if (repPermissions && !repPermissions?.tasks?.create_edit)
                  return;
                handleOpenTask(task);
              }}
            />
          ))
        : null}

      {/* orders */}
      {orders?.length
        ? orders?.map((order) => {
            return (
              <Box key={order?.id}>
                <StyledTooltip
                  arrow
                  placement="top"
                  isDark
                  title={
                    <Box padding="2px 10px">
                      <Box color="#ffffff" fontWeight="300" fontSize="12px">
                        Order: {order?.customId}
                      </Box>
                      <Box color="#ffffff" fontWeight="300" fontSize="12px">
                        Total amount: $
                        {separateNumWithComma(order?.totalAmount)}
                      </Box>
                    </Box>
                  }
                  followCursor={false}
                >
                  <Box>
                    <OrderItem
                      onClickOnOrder={() => {
                        if (repPermissions && !repPermissions?.orders?.view)
                          return;
                        navigate(`/orders/${order?.id}`);
                      }}
                      order={order}
                    />
                  </Box>
                </StyledTooltip>
              </Box>
            );
          })
        : null}

      {/* photos / photos comments */}
      {photoItems?.length
        ? photoItems.map((photo) => {
            return (
              <PhotoItem
                key={photo?.id}
                repPermissions={repPermissions}
                photo={photo}
                onOpenProductDialog={(e) => {
                  if (repPermissions && !repPermissions?.customers?.gallery)
                    return;
                  return handleOpenPhotoGroupPopup(e);
                }}
              />
            );
          })
        : null}

      {/* notes */}
      {notes?.length
        ? notes.map((note) => {
            return (
              <NoteItem
                key={note?.id}
                repPermissions={repPermissions}
                handleClickCustomerNotes={() => {
                  if (repPermissions && !repPermissions?.customers?.create_edit)
                    return;
                  handleOpenGlobalNote({
                    customer: {
                      id: customerState?.customer?.id,
                      name:
                        customerState?.customer?.displayName ||
                        customerState?.customer?.name,
                    },
                  });
                }}
              />
            );
          })
        : null}

      {forms?.length
        ? forms.map((form) => {
            return (
              <FormItem
                key={form.id}
                callback={() => {
                  if (disabledFormsCreateEdit) return;
                  !!forms?.length && onFormClick(form);
                }}
              />
            );
          })
        : null}

      {/* deleted a pic */}
      {isDeletedPic && (
        <RemovedPhotoItem
          type="Photo"
          removedBy={setRemovedBy}
          removedAt={setRemovedAt}
        />
      )}

      {/* deleted a note */}
      {isDeletedNote && (
        <RemovedPhotoItem
          type="Note"
          removedBy={setRemovedBy}
          removedAt={setRemovedAt}
        />
      )}

      {/* deleted a task */}
      {isDeletedTask && (
        <RemovedPhotoItem
          type="Task"
          removedBy={setRemovedBy}
          removedAt={setRemovedAt}
        />
      )}
    </Box>
  );
};

MainBlock.propTypes = {
  photoItems: array,
  setRemovedBy: string,
  setRemovedAt: string,
  isDeletedPic: bool,
  isDeletedNote: bool,
  isDeletedTask: bool,
  isOnlyCheckIn: bool,
  orders: array,
  forms: array,
  notes: array,
  activity: object,
};
MainBlock.defaultProps = {};
