import { useCallback, useMemo, useRef, useState } from "react";
import { array, bool, func, object, string } from "prop-types";
import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { StyledButton, StyledTextField, StyledTooltip } from "components";
import { CrossIcon, SearchIcon } from "components/Icons";
import AllProductsPopup from "../AllProductsPopup/AllProductsPopup";
import ProductsHeader from "../ProductsTable/ProductsHeader";
import ProductsItem from "../ProductsTable/ProductsItem";
import ProductsSearchItem from "../ProductsSearchItem/ProductsSearchItem";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";
import { calculateAvailable, containsEvery } from "helpers/helpers";
import { useRepsPermissions } from "helpers/hooks";

export const FirstCustomerStep = ({
  //orderId = "",
  formField = {},
  fields = [],
  control,
  errors = {},
  clearErrors = () => {},
  setValue = () => {},
  classes = {},
  productsSearchInput = "",
  setProductsSearchInput = () => {},
  productsLoading = false,
  productsList = [],
  setProductsList = () => {},
  lastProductsOrders = {},
  productsAvailableLessThanMinimum = () => {},
  calcAvailable = () => {},
  isThirdParty = false,
  checkedProducts = [],
  setCheckedProducts = () => {},
  isAdmin = false,
  allProductsOpen = false,
  setAllProductsOpen = () => {},
  productsExist = false,
  priceListId = "",
  handleOpenDialog = () => {},
  setFreeProducts = () => {},
  orderCompleted,
  deliveryStatus,
  handleAddMSD,
  priceList = {},
  productDict,
  handleCheckRemove = () => {},
  handleSubtractQty = () => {},
  paymentStatus,
  checkSellingOutStock,
  handleApplyDiscount,
  availableDiscountsAll,
  calcProductPriceByPriceList,
  priceListLoading,
  onAllowToOpenAdjustingDialog = () => {},
  findAndUpdateFreeCaseProduct = () => {},
}) => {
  const productsRefs = useRef([]);
  const pricesRefs = useRef({});
  const repPermissions = useRepsPermissions();

  const [textProduct, setTextProduct] = useState("");
  const [currentProductRef, setCurrentProductRef] = useState(false);

  const productHighestPriceWidth = useMemo(() => {
    const highestPrice = fields.reduce(
      (max, item) => (item?.price > max?.price ? item : max),
      fields[0]
    );

    const productHighestPrice = pricesRefs.current[highestPrice?.id];

    if (productHighestPrice) {
      if (productHighestPrice?.offsetWidth < productHighestPrice?.scrollWidth) {
        return productHighestPrice?.offsetWidth;
      }
      return productHighestPrice?.offsetWidth + 8;
    }

    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, lastProductsOrders, formField, priceListLoading]);

  const handleGetQuantity = () => {
    let total = 0;
    formField?.products?.map((obj) => {
      total += obj.quantity;
    });
    return total;
  };

  const handleCheckProduct = useCallback(
    (product) => {
      const isNonInventory =
        product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

      if (product.isMultiple) {
        const filteredChildren = product.childProducts
          .filter(
            (childProduct) =>
              !formField?.products?.some(
                (prod) =>
                  prod?.id === childProduct?.id ||
                  prod?.product?.id === childProduct?.id
              )
          )
          .filter((prod) => {
            if (isThirdParty || isNonInventory)
              return !prod?.missingFields?.length;
            return (
              ((!product.sellingOutOfStock &&
                prod.inventory?.onHand - prod.inventory?.allocated > 0) ||
                product.sellingOutOfStock) &&
              !productsAvailableLessThanMinimum(prod) &&
              !prod?.missingFields?.length
            );
          });

        if (containsEvery(filteredChildren, checkedProducts)) {
          const newChecked = [...checkedProducts];
          product.childProducts.forEach((childProduct) => {
            const index = newChecked.findIndex(
              (checkedCustomer) => checkedCustomer.id === childProduct.id
            );
            newChecked.splice(index, 1);
          });
          return setCheckedProducts([...newChecked]);
        }

        const addProducts = filteredChildren.map((childProduct) => {
          return {
            ...childProduct,
            quantity: childProduct.minOrderQTY || 1,
            parentProduct: product,
            itemDiscountType: "PERCENTAGE",
            itemDiscountAmount: 0,
            isNewAdded: true,
            discountQty: 0,
          };
        });

        const uniqueProducts = [
          ...new Map(
            [...checkedProducts, ...addProducts].map((item) => [
              item["id"],
              item,
            ])
          ).values(),
        ];

        return setCheckedProducts(uniqueProducts);
      }
      const index = checkedProducts.findIndex((item) => item.id === product.id);

      if (index > -1)
        return setCheckedProducts(
          checkedProducts.filter((item) => item.id !== product.id)
        );
      setCheckedProducts([
        ...checkedProducts,
        { ...product, quantity: product.minOrderQTY || 1 },
      ]);
    },
    [
      checkedProducts,
      setCheckedProducts,
      formField?.products,
      isThirdParty,
      productsAvailableLessThanMinimum,
    ]
  );

  const getOutOfStock = (product, index) => {
    const available = calculateAvailable(
      product.inventory?.onHand || product.product?.inventory?.onHand,
      product.inventory?.allocated || product.product?.inventory?.allocated
    );
    const qty = formField?.products?.[index]?.quantity - product.quantity;
    //const editOrderCondition = orderId ? !!product.isNewAdded : true;
    return (
      !isThirdParty &&
      //editOrderCondition &&
      !product?.sellingOutOfStock &&
      !product?.product?.sellingOutOfStock &&
      !product?.parentProduct?.sellingOutOfStock &&
      !product?.product?.parentProduct?.sellingOutOfStock &&
      available - qty <= 0
    );
  };

  const handleAddProducts = useCallback(() => {
    setCheckedProducts([]);
    clearErrors("products");
    if (formField.products)
      return setValue("products", [...formField.products, ...checkedProducts], {
        shouldDirty: true,
      });
    setValue("products", [...checkedProducts], { shouldDirty: true });
  }, [
    checkedProducts,
    clearErrors,
    formField?.products,
    setCheckedProducts,
    setValue,
  ]);

  const productsDropDown = useMemo(
    () =>
      productsSearchInput &&
      (productsList?.length ? (
        <>
          <StyledTooltip
            style={{ zIndex: 1401, whiteSpace: "pre-wrap" }}
            open={!!currentProductRef}
            title={textProduct}
            placement="top"
          >
            <Paper
              className={classes.section}
              sx={{
                position: "absolute",
                zIndex: 1,
                width: "100%",
                left: 0,
              }}
            >
              <Stack sx={{ maxHeight: "50vh", overflow: "auto" }}>
                {productsList.map((product) =>
                  product.isMultiple ? (
                    product.childProducts.map((child) => {
                      child.sellingOutOfStock = product?.sellingOutOfStock;
                      child.minOrderQTY = product?.minOrderQTY;
                      return (
                        <ProductsSearchItem
                          ref={(el) => (productsRefs.current[child.id] = el)}
                          onMouseEnter={() => {
                            if (productsAvailableLessThanMinimum(child)) {
                              setCurrentProductRef(child?.id);
                              setTextProduct(
                                `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                                  child
                                )}, Minimum Order QTY: ${product?.minOrderQTY}`
                              );
                            }
                          }}
                          onMouseLeave={() => {
                            setCurrentProductRef(false);
                            setTextProduct("");
                          }}
                          key={child.id}
                          product={{ ...child, parentProduct: product }}
                          name={product.name}
                          manufacturer={product.manufacturer}
                          handleCheckProduct={handleCheckProduct}
                          isChecked={checkedProducts.some(
                            (prod) => prod.id === child.id
                          )}
                          disabled={
                            formField.products?.some(
                              (prod) =>
                                prod.id === child.id ||
                                prod.product?.id === child.id
                            ) || productsAvailableLessThanMinimum(child)
                          }
                          outOfStock={
                            !isThirdParty &&
                            !product.sellingOutOfStock &&
                            child.inventory?.onHand -
                              child.inventory?.allocated <=
                              0
                          }
                          hideAvailable={isThirdParty}
                          planeView
                        />
                      );
                    })
                  ) : (
                    <ProductsSearchItem
                      ref={(el) => (productsRefs.current[product.id] = el)}
                      onMouseEnter={() => {
                        if (
                          productsAvailableLessThanMinimum(product) &&
                          !product?.isMultiple
                        ) {
                          setCurrentProductRef(product?.id);
                          setTextProduct(
                            `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                              product
                            )}, Minimum Order QTY: ${product?.minOrderQTY}`
                          );
                        }
                      }}
                      onMouseLeave={() => {
                        setCurrentProductRef(false);
                        setTextProduct("");
                      }}
                      key={product.id}
                      product={product}
                      name={product.name}
                      manufacturer={product.manufacturer}
                      handleCheckProduct={handleCheckProduct}
                      isChecked={checkedProducts.some(
                        (prod) => prod.id === product.id
                      )}
                      disabled={
                        formField.products?.some(
                          (prod) =>
                            prod.id === product.id ||
                            prod.product?.id === product.id
                        ) || productsAvailableLessThanMinimum(product)
                      }
                      outOfStock={
                        !isThirdParty &&
                        !product.sellingOutOfStock &&
                        product.inventory?.onHand -
                          product.inventory?.allocated <=
                          0
                      }
                      hideAvailable={isThirdParty}
                      planeView
                    />
                  )
                )}
              </Stack>
              <Box
                display="flex"
                justifyContent="flex-end"
                p="7px 16px 8.4px"
                alignItems="center"
              >
                <StyledButton
                  label="Cancel"
                  sx={{ height: "27px", mr: "8px" }}
                  color="cancel"
                  onClick={() => {
                    setProductsSearchInput("");
                    setCheckedProducts([]);
                    setProductsList([]);
                    setProductsSearchInput("");
                  }}
                  fontWeight="600"
                  fontSize="10px"
                />
                <StyledButton
                  disabled={isAdmin}
                  label="Add"
                  variant="contained"
                  sx={{ height: "27px" }}
                  fontWeight="600"
                  fontSize="10px"
                  onClick={() => {
                    setProductsSearchInput("");
                    setProductsList([]);
                    handleAddProducts();
                  }}
                />
              </Box>
            </Paper>
          </StyledTooltip>
        </>
      ) : (
        <Paper
          className={classes.section}
          sx={{
            position: "absolute",
            zIndex: 1,
            width: "100%",
            left: 0,
            color: "rgb(156, 156, 148)",
            maxHeight: "50vh",
            overflow: "auto",
          }}
        >
          <MenuItem sx={{ color: "rgb(156, 156, 148)" }}>
            No products found
          </MenuItem>
        </Paper>
      )),
    [
      productsSearchInput,
      productsList,
      currentProductRef,
      textProduct,
      classes?.section,
      isAdmin,
      handleCheckProduct,
      checkedProducts,
      formField?.products,
      productsAvailableLessThanMinimum,
      isThirdParty,
      calcAvailable,
      setProductsSearchInput,
      setCheckedProducts,
      setProductsList,
      handleAddProducts,
    ]
  );

  return (
    <Box
      className={classes.section}
      sx={{
        height: "fit-content",
        backgroundColor: "#ffff !important",
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          pt: "clamp(8px, 1.1vw, 16px)",
          pb: "clamp(10px, 1.1vw, 20px)",
          px: "clamp(14px, 1.6vw, 29px)",
          position: "relative",
        }}
      >
        <Box display="inline-flex" alignItems="center">
          <Typography className={classes.stepTitle}>Step 1</Typography>
          {errors.products && (
            <Typography
              fontSize="14px"
              color="#FF6969"
              ml="16px"
              fontWeight="400"
            >
              {errors.products.message}
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center" mt="10px" position="relative">
          <Box position="relative" mr="10px" width="65%">
            <StyledTextField
              fullWidth
              value={productsSearchInput}
              onChange={(e) => setProductsSearchInput(e.target.value)}
              formSx={{ mr: "10px" }}
              size="small"
              InputProps={{
                className: classes.textInput,
                endAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                      sx={{ position: "absolute", left: 9 }}
                    >
                      <SearchIcon />
                    </InputAdornment>
                    {productsLoading && (
                      <InputAdornment position="end">
                        <CircularProgress size="20px" />
                      </InputAdornment>
                    )}
                    {productsSearchInput && (
                      <InputAdornment position="end" sx={{ mr: "-4px" }}>
                        <IconButton
                          sx={{ p: "1px" }}
                          onClick={() => setProductsSearchInput("")}
                        >
                          <CrossIcon size="15" />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
              InputLabelProps={{
                className: classes.inputLabel,
              }}
              placeholder="Search products by name, manufacturer or SKU"
            />
            {productsDropDown}
          </Box>
          <AllProductsPopup
            isOpen={allProductsOpen}
            handleClose={() => setAllProductsOpen(false)}
            handleCheckProduct={handleCheckProduct}
            handleCancelProducts={() => setAllProductsOpen(false)}
            handleAddProducts={() => {
              setAllProductsOpen(false);
              handleAddProducts();
            }}
            checkedProducts={checkedProducts}
            addedProducts={formField.products}
            disableOutOfStock={!isThirdParty}
            isOrder
            hideAvailable={isThirdParty}
            environment="cart_page"
            priceListId={priceListId}
          />
          <Box>
            <StyledTooltip
              style={{ whiteSpace: "pre-wrap" }}
              title="Add at least 1 product to access the cart"
              arrow
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
              }}
              data-testid="all-products"
              disableHoverListener={!!productsExist}
              placement="top"
            >
              <Box>
                <StyledButton
                  label="View all"
                  disabled={!productsExist}
                  onClick={() => {
                    setProductsSearchInput("");
                    setAllProductsOpen(true);
                  }}
                  variant="outlined"
                  color="greyBtn"
                  fontSize="15px"
                  sx={{
                    color: "#6A6A6A",
                    height: "33px",
                    whiteSpace: "nowrap",
                  }}
                />
              </Box>
            </StyledTooltip>
          </Box>
          <Typography
            fontSize="clamp(15px, 1.3vw, 25px)"
            color="#707070"
            fontWeight={600}
            ml="14px"
            whiteSpace="nowrap"
          >
            Order{" "}
            <span
              style={{
                fontWeight: 400,
                color: "#9C9C94",
              }}
            >
              ({handleGetQuantity()} items)
            </span>
          </Typography>
        </Box>
      </Box>
      {!!formField.products?.length && (
        <>
          <ProductsHeader
            handleDeleteAllProducts={() =>
              handleOpenDialog({
                title: "Delete all products?",
                text: "Are you sure you want to proceed? This will erase all drafted products and assigned discounts.",
                onConfirm: () => {
                  if (formField.discount?.type !== "total")
                    setValue("discount", null, { shouldDirty: true });
                  setValue("manufacturerDiscounts", [], {
                    shouldDirty: true,
                  });
                  setValue("products", [], { shouldDirty: true });
                  setValue("productsData", [], { shouldDirty: true });
                  setValue("freeCasesProductsIds", [], {
                    shouldDirty: true,
                  });
                  setFreeProducts([]);
                },
              })
            }
            formDiscountType={formField.discount?.type}
            orderCompleted={orderCompleted}
            deliveryStatus={deliveryStatus}
            hideAvailable={isThirdParty}
          />
          {fields?.map((product, index) => {
            return (
              <ProductsItem
                hasPriceListPrice={
                  !!priceList?.[product.product?.id || product.id]
                }
                hasCustomerPriceList={
                  !!priceList && !!Object.keys(priceList)?.length
                }
                refPrice={(el) => (pricesRefs.current[product.id] = el)}
                handleAddDiscount={handleAddMSD}
                prodDict={productDict}
                key={product?.id}
                priceListId={priceListId}
                repPermissions={repPermissions}
                product={formField?.products?.[index]}
                quantity={formField?.products?.[index]?.quantity}
                setValue={setValue}
                index={index}
                customerSelected={!!formField?.customer}
                handleRemoveOrderProduct={handleCheckRemove}
                handleOpenDiscountsDialog={handleOpenDialog}
                handleSubtractQty={handleSubtractQty}
                control={control}
                formDiscountType={formField.discount?.type}
                orderCompleted={orderCompleted}
                outOfStock={getOutOfStock(product, index)}
                paymentStatus={paymentStatus}
                isThirdParty={isThirdParty}
                checkSellingOutStock={checkSellingOutStock}
                handleApplyDiscount={handleApplyDiscount}
                availableDiscounts={availableDiscountsAll[index] || []}
                lastProductsOrdersData={
                  !!lastProductsOrders?.data[
                    product?.product?.id || product?.id
                  ]?.lastOrders &&
                  lastProductsOrders?.data[product?.product?.id || product?.id]
                    ?.lastOrders
                }
                gapBetweenPriceAndInfo={productHighestPriceWidth || 0}
                {...{
                  calcProductPriceByPriceList,
                  priceListLoading,
                  onAllowToOpenAdjustingDialog,
                  findAndUpdateFreeCaseProduct,
                }}
              />
            );
          })}
        </>
      )}
    </Box>
  );
};

FirstCustomerStep.propTypes = {
  orderId: string,
  formField: object,
  fields: array,
  control: object,
  errors: object,
  clearErrors: func,
  setValue: func,
  classes: object,
  productsSearchInput: string,
  setProductsSearchInput: func,
  productsLoading: bool,
  productsList: array,
  setProductsList: func,
  lastProductsOrders: object,
  productsAvailableLessThanMinimum: func,
  calcAvailable: func,
  isThirdParty: bool,
  checkedProducts: array,
  setCheckedProducts: func,
  isAdmin: bool,
  allProductsOpen: bool,
  setAllProductsOpen: func,
  productsExist: bool,
  priceListId: string,
  handleOpenDialog: func,
  setFreeProducts: func,
  orderCompleted: bool,
  deliveryStatus: string,
  handleAddMSD: func,
  priceList: object,
  productDict: array,
  paymentStatus: string,
  checkSellingOutStock: func,
  handleApplyDiscount: func,
  availableDiscountsAll: array,
  calcProductPriceByPriceList: func,
  priceListLoading: bool,
  handleCheckRemove: func,
  handleSubtractQty: func,
  onAllowToOpenAdjustingDialog: func,
  findAndUpdateFreeCaseProduct: func,
};
