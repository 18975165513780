import { bool, func, object } from "prop-types";

import { Box, Menu } from "@mui/material";
import { cl } from "./NotificationCenter.styles";
import { useNotifications } from "./useNotifications";
import { TabsBlock } from "./components/TabsBlock";
import { ContentBlock } from "./components/ContentBlock";
import { Header } from "./components/Header";
import { SettingsBlock } from "./components/SettingsBlock";
import { NoteDrawer, TaskDrawer } from "components";
import { TABS } from "./NotificationCenter.constants";
import PhotoGroupPopup from "Pages/CustomersPage/components/GalleryTab/components/PhotoGroupPopup/PhotoGroupPopup";

export const NotificationCenter = ({ anchorEl, open, handleClose }) => {
  const {
    tab,
    page,
    setTab,
    onClose,
    notificationState,
    onMarkAllAsRead,
    showSettings,
    userSettings,
    setShowSettings,
    preparedSettingsData,
    onUserSettingsChange,
    onReadNotification,
    fetchNotifications,
    onCheckForDefaultSettings,
    noteDialogState,
    onNotesClose,
    taskDialogState,
    getDrawerActionFunc,
    allNotificationsAreHidden,
    onTasksClose,
    photoGroupPopupState,
    currentUser,
    onClosePhotoGroupPopup,
    onNotificationNavigate,
    repPermissions,
  } = useNotifications({ handleClose, open });

  return (
    <>
      {!!noteDialogState.open && (
        <NoteDrawer
          open={noteDialogState.open}
          onClose={onNotesClose}
          data={noteDialogState.data}
          titleLabel="Notes"
        />
      )}

      {taskDialogState.open && (
        <TaskDrawer
          open={taskDialogState.open}
          onClose={onTasksClose}
          taskId={taskDialogState.data?.id}
        />
      )}

      {!!photoGroupPopupState && (
        <PhotoGroupPopup
          user={{
            avatar: currentUser?.profilePhoto,
            name: currentUser?.name,
            id: currentUser?.id,
          }}
          hidePagination
          isOpenProductDialog={photoGroupPopupState}
          handleCloseProductDialog={onClosePhotoGroupPopup}
        />
      )}

      <Menu
        sx={cl.menu}
        id="notification-center-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: cl.paperContainer,
        }}
      >
        <Header {...{ showSettings, setShowSettings }} />

        {showSettings ? (
          <SettingsBlock
            {...{
              preparedSettingsData,
              onUserSettingsChange,
              userSettings,
              onCheckForDefaultSettings,
            }}
          />
        ) : (
          <Box>
            <TabsBlock
              {...{
                tab,
                setTab,
                onMarkAllAsRead,
                showMarkAll:
                  tab === TABS[0] &&
                  !allNotificationsAreHidden &&
                  !!notificationState?.[TABS[0]]?.list?.length,
              }}
            />
            <ContentBlock
              {...{
                notificationState,
                tab,
                fetchNotifications,
                onNotificationNavigate,
                onReadNotification,
                page,
                getDrawerActionFunc,
                allNotificationsAreHidden,
                currentUser,
                repPermissions,
              }}
            />
          </Box>
        )}
      </Menu>
    </>
  );
};

NotificationCenter.propTypes = {
  anchorEl: object,
  open: bool,
  handleClose: func,
};
