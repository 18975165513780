/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";

export const CommentIcon = ({ width = 22, height = 22, ...props }) => (
  <SvgIcon
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fill: "none", width, height }}
    {...props}
  >
    <path
      d="M19.583 10.325c0 4.315-3.789 7.908-8.583 7.908q-.675 0-1.322-.093a1.4 1.4 0 0 0-.688.095 3.3 3.3 0 0 0-.504.258c-.155.094-.318.202-.472.305l-.006.004q-.234.158-.467.304c-.698.434-1.158.566-1.5.422-.19-.08-.351-.237-.491-.52-.146-.291-.245-.67-.322-1.107a18 18 0 0 1-.109-.711 18 18 0 0 0-.087-.584 4.4 4.4 0 0 0-.143-.612 1.6 1.6 0 0 0-.344-.618c-.507-.538-1.047-1.396-1.461-2.352-.415-.96-.667-1.936-.667-2.699 0-4.315 3.788-7.907 8.583-7.907 4.794 0 8.583 3.592 8.583 7.907Z"
      stroke="#A5A5A5"
      strokeWidth={1.5}
    />
  </SvgIcon>
);
