import { Controller } from "react-hook-form";
import { array, bool, func, number, object, string } from "prop-types";

import { SubcategoriesBlock } from "./SubcategoriesBlock";
import { ProductAssignBlock } from "./ProductAssignBlock";
import { StyledTextField, StyledTooltip } from "components";

import { Box, CircularProgress, DialogContent, Stack } from "@mui/material";

export const DrawerContent = ({
  control,
  formField,
  categoriesLoading,
  categoriesList,
  categoriesCount,
  categoriesPage,
  handleFetchCategories,
  count,
  onEdit,
  isEdit,
  isParent,
  getDataLoading,
  setValue,
  resetSelectedProducts,
  parentName = "",
  clearErrors = () => {},
}) => {
  return (
    <DialogContent
      sx={{
        p: "32px",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      {getDataLoading ? (
        <Stack height="100%" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <StyledTooltip
                  arrow
                  placement="left"
                  isError
                  title={error?.message || ""}
                  open={error?.type === "uniq"}
                  PopperProps={{
                    modifiers: [
                      { name: "offset", options: { offset: [0, -5] } },
                    ],
                  }}
                >
                  <Box component="span">
                    <StyledTextField
                      label="Category name"
                      fullWidth
                      error={!!error}
                      sx={{
                        "& .MuiOutlinedInput-input": { p: "12px 14px" },
                        "& .MuiInputLabel-root.Mui-focused": { top: 1.5 },
                        "& .MuiInputLabel-shrink": { top: 1.5 },
                        "& .MuiOutlinedInput-root": {
                          height: "44px",
                          fontSize: "14px",
                          fontWeight: 300,
                        },
                      }}
                      InputProps={{ style: { fontSize: 14 } }}
                      InputLabelProps={{
                        sx: {
                          fontSize: 14,
                          color: "#B1B1B1",
                          top: 3,
                        },
                      }}
                      {...field}
                    />
                  </Box>
                </StyledTooltip>
              );
            }}
            name="name"
            control={control}
          />

          <SubcategoriesBlock
            {...{
              control,
              formField,
              categoriesLoading,
              categoriesList,
              categoriesCount,
              categoriesPage,
              handleFetchCategories,
              count,
              onEdit,
              isEdit,
              isParent,
              setValue,
              resetSelectedProducts,
              parentName,
              clearErrors,
            }}
          />

          <ProductAssignBlock
            {...{ count, onEdit, showAllProducts: formField.showAllProducts }}
          />
        </>
      )}
    </DialogContent>
  );
};

DrawerContent.propTypes = {
  control: object,
  formField: object,
  categoriesLoading: bool,
  isEdit: bool,
  isParent: bool,
  getDataLoading: bool,
  categoriesList: array,
  categoriesCount: number,
  categoriesPage: number,
  handleFetchCategories: func,
  count: number,
  onEdit: func,
  setValue: func,
  resetSelectedProducts: func,
  parentName: string,
  clearErrors: func,
};
