/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";
import * as React from "react";

export const NotificationIcon = ({ stroke = "none", ...props }) => (
  <SvgIcon
    viewBox="0 0 22 22"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: "22px",
      height: "22px",
      fill: "none",
    }}
    {...props}
  >
    <path
      d="m2.111 19.922-.74-.124a.75.75 0 0 0 .864.863zm.702-4.213-.74-.124zm.99-1.924-.53-.53zM14.111 3.477l.53.53zM6.324 19.22l.124.74zm1.924-.99-.53-.53zM18.556 7.923l-.53-.53zM7.4 18.955l.342.668v-.001zm.006-.003.343.667.002-.001zm11.15-15.475.53-.53zM3.078 14.632l-.667-.343v.001zm.003-.006-.666-.345v.002zm16.586 5.792a.75.75 0 0 0 0-1.5zm-8-1.5a.75.75 0 0 0 0 1.5zm-8.816 1.127.702-4.213-1.48-.247-.701 4.213zm1.483-5.73L14.642 4.008l-1.061-1.06L3.273 13.255zm-2.1 6.346 4.214-.702-.247-1.48-4.213.703zm6.544-1.901L19.086 8.452l-1.06-1.06-10.31 10.306zm-2.33 1.2c.508-.085.921-.146 1.295-.337l-.684-1.335c-.118.06-.259.092-.858.192zm1.27-2.26c-.43.429-.537.525-.655.586l.689 1.332c.372-.193.662-.494 1.026-.858zm.026 1.922.006-.003-.686-1.334-.006.003zM18.025 4.007c.935.935.935 2.45 0 3.384l1.061 1.06a3.893 3.893 0 0 0 0-5.504zm-3.383 0a2.393 2.393 0 0 1 3.383 0l1.061-1.06a3.893 3.893 0 0 0-5.505 0zM3.553 15.832c.1-.6.132-.74.192-.858L2.41 14.29c-.19.374-.252.787-.336 1.295zm-.28-2.577c-.364.364-.666.654-.858 1.026l1.332.69c.061-.119.157-.226.587-.655zm.472 1.72.003-.006-1.334-.686-.003.006zm13.119-5.362L12.419 5.17l-1.06 1.06 4.444 4.445zm2.803 9.305h-8v1.5h8z"
      fill="#A5A5A5"
      stroke={stroke}
    />
  </SvgIcon>
);
