import { useMemo } from "react";
import { bool, func, object, string } from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { LargeCheckbox } from "components/Checkboxes";
import { columnLayoutsSelector } from "redux/selectors/settings";
import { ArrowDownIcon, ArrowUpIcon } from "../../../components/Icons/index.js";
import { cl } from "../../CatalogPage/ProductsTab/ProductsList/ProductList.styles.js";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));

const SORTABLE_COLUMNS = {
  "Rep Name": "sort_name",
  Role: "sort_role",
  "Work Status": "sort_work_status",
  "Latest Activity": "sort_latest_activity",
  Revenue: "sort_revenue",
  Orders: "sort_orders",
  Customers: "sort_customers",
  "Visits Today": "sort_visits",
  Territory: "sort_territory",
};

const ReprHeaderComponent = ({
  checkAllUsers,
  allChecked,
  currentTab,
  handleSetSortBy,
  quickSort,
}) => {
  const { columnLayouts } = useSelector(selector);
  const rep_all_tab_disable = columnLayouts.rep_all.disable;
  const rep_all_tab = columnLayouts.rep_all.main;

  const columnWidth = {
    Status: 4,
    "Rep Name": 17,
    Role: 10,
    Territory: 12,
    "Work Status": 9,
    "Latest Activity": 15,
    "Visits Today": 7,
    Revenue: 7,
    Orders: 6.5,
    Customers: 6.5,
  };

  const thirdPartyColumn = {
    Status: 0.9,
    "Rep Name": 2.25,
    Role: 1.5,
    Territory: 3,
    Customers: 2,
  };

  const columns = useMemo(() => {
    if (rep_all_tab_disable)
      return [
        ...rep_all_tab_disable,
        ...rep_all_tab.filter((col) => col.selected),
      ];

    return [];
  }, [rep_all_tab, rep_all_tab_disable]);

  const thirdPartyColumns = Object.keys(thirdPartyColumn);

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    // eslint-disable-next-line
    []
  );

  const classes = {
    grid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    typography: {
      fontSize: "12px",
      fontWeight: "400",
      color: "#6A6A6A",
      textTransform: "uppercase",
    },
  };

  const getDefaultClasses = (columnName, isThirdParty = true) => {
    const setWidth = columnWidth?.[columnName]
      ? `${columnWidth?.[columnName]}%`
      : `${
          (100 - defaultColumnWidth) /
          rep_all_tab.filter((col) => col.selected).length
        }%`;

    const setPl = (columnName) => {
      if (columnName === "Rep Name") return "40px";
      if (columnName === "Territory") return "30px";
      return 0;
    };

    let result = {
      ...classes.typography,
      display: "flex",
      justifyContent: columnName === "Status" ? "center" : "flex-start",
      alignItems: "center",
      gap: 1,
    };

    if (!isThirdParty) {
      result = {
        ...result,
        pl: setPl(columnName),
        width: setWidth,
        minWidth: setWidth,
        maxWidth: setWidth,
      };
    }

    return result;
  };

  const getSortedIconBtn = (columnName) => {
    const sortedColumnName = SORTABLE_COLUMNS[columnName];

    const shouldShowIcon =
      currentTab === "All" || (currentTab !== "All" && columnName !== "Role");

    if (!sortedColumnName || !shouldShowIcon) return null;
    return (
      <Box
        sx={cl.arrowBox}
        onClick={() => {
          return handleSetSortBy(sortedColumnName);
        }}
      >
        {quickSort[sortedColumnName] === "asc" ? (
          <ArrowDownIcon />
        ) : (
          <ArrowUpIcon />
        )}
      </Box>
    );
  };

  return currentTab !== "3rd Party" ? (
    <Paper
      sx={{
        height: "38px",
        backgroundColor: "#EEEEEE",
        alignItems: "center",
        borderWidth: "0px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
      }}
      elevation={0}
      square
      component={Grid}
      container
    >
      <Box width="50px" sx={classes.grid}>
        <LargeCheckbox
          sx={{ padding: 0 }}
          formSx={{ m: 0 }}
          size={22}
          checked={allChecked}
          onChange={checkAllUsers}
        />
      </Box>

      {columns.map((col) => (
        <Box key={col.id} sx={getDefaultClasses(col.name, false)}>
          {col.name}
          {getSortedIconBtn(col.name)}
        </Box>
      ))}
      <Grid item xs />
    </Paper>
  ) : (
    <Paper
      sx={{
        height: "37px",
        backgroundColor: "#EEEEEE",
        alignItems: "center",
        borderWidth: "0px 1px 1px 1px",
        borderStyle: "solid",
        borderColor: "#D5D9D9",
        pr: "10px",
      }}
      component={Grid}
      elevation={0}
      square
      container
    >
      <Box width="50px" sx={classes.grid}>
        <LargeCheckbox
          sx={{ padding: 0 }}
          formSx={{ m: 0 }}
          size={22}
          checked={allChecked}
          onChange={checkAllUsers}
        />
      </Box>

      {thirdPartyColumns.map((columnName) => (
        <Grid
          key={columnName}
          item
          xs={thirdPartyColumn[columnName]}
          sx={getDefaultClasses(columnName)}
        >
          <Typography
            variant="header"
            pl={
              columnName === "Rep Name"
                ? "40px"
                : columnName === "Territory"
                ? "25px"
                : 0
            }
          >
            {columnName === "Customers" ? "Customer count" : columnName}
          </Typography>
          {getSortedIconBtn(columnName)}
        </Grid>
      ))}
    </Paper>
  );
};

ReprHeaderComponent.propTypes = {
  checkAllUsers: func,
  allChecked: bool,
  currentTab: string,
  quickSort: object,
  handleSetSortBy: func,
};

ReprHeaderComponent.defaultProps = {
  allChecked: false,
};

export default ReprHeaderComponent;
