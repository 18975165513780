import { string, func, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import { NoteIcon } from "components/Icons/Activities";
import { ActionRestrictionWrapper } from "components";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const NoteItem = ({
  repPermissions,
  handleClickCustomerNotes,
  size,
}) => {
  const isSmallSize = size === "small";

  const isDisabled = repPermissions && !repPermissions?.customers?.create_edit;

  return (
    <ActionRestrictionWrapper
      title={ADMIN_ONLY_VIEW_MESSAGE}
      disableHoverListener={!isDisabled}
      //tooltipProps={{
      //  sx: {
      //    "& .MuiTooltip-tooltip": {
      //      mr: "6px",
      //    },
      //  },
      //}}
    >
      <Box
        sx={{
          border: "1px solid #D5D9D9",
          borderRadius: "4px",
          width: isSmallSize ? "40px" : "56px",
          maxWidth: isSmallSize ? "40px" : "56px",
          minWidth: isSmallSize ? "40px" : "56px",
          height: isSmallSize ? "40px" : "56px",
          maxHeight: isSmallSize ? "40px" : "56px",
          minHeight: isSmallSize ? "40px" : "56px",
          display: "flex !important",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          py: "10px",
          gap: isSmallSize ? "2px" : "6px",
          cursor: "pointer",
          backgroundColor: "#F6F6F6",
          position: "relative",
        }}
        onClick={handleClickCustomerNotes}
      >
        <NoteIcon size={isSmallSize ? 15 : 18} color="#5F6267" />

        <Typography
          sx={{
            fontSize: 8,
            lineHeight: "10px",
            color: "#5F6267",
          }}
        >
          Note
        </Typography>
      </Box>
    </ActionRestrictionWrapper>
  );
};

NoteItem.defaultProps = {
  handleClickCustomerNotes: () => {},
  size: "big",
};

NoteItem.propTypes = {
  handleClickCustomerNotes: func,
  size: string,
  repPermissions: object,
};
