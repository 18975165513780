import { TableHeaderBlock } from "./components/TableHeaderBlock";
import React, { useContext } from "react";
import { object } from "prop-types";
import { CategoriesContext } from "Pages/CatalogPage";
import { TableItem } from "./components/TableItem";
import {
  EmptyScreen,
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
} from "components";

import { Box, CircularProgress, Collapse, Stack } from "@mui/material";

export const CategoriesList = ({ repPermissions = {} }) => {
  const {
    list,
    onEditCategoryDrawerOpen,
    expandParentId,
    handleExpandChildrenList,
    categoryPage,
    handleFetchCategories,
    categoryCount,
    categoriesLoading,
  } = useContext(CategoriesContext);

  return (
    <Box height="100%">
      {list.length ? (
        <>
          <TableHeaderBlock />

          <Stack
            sx={{
              borderRadius: "0 0 4px 4px",
              maxHeight: "calc(100vh - 260px)",
              height: "100%",
              borderWidth: "0 0.5px 0.5px 0.5px",
              borderStyle: "solid",
              borderColor: "#D5D9D9",
              overflow: "hidden",
            }}
          >
            <InfiniteScrollWrapper
              maxHeight="calc(100vh - 260px)"
              dataLength={list.length}
              id="category-list-scroll-table"
              loading={categoriesLoading}
              nextFunc={() => handleFetchCategories(categoryPage + 1)}
              loader={
                <CircularProgress
                  sx={{ ml: "calc(50% - 15px)", mt: "10px" }}
                  size="30px"
                />
              }
              hasMoreItems={list.length < categoryCount}
            >
              {list?.map((category) => (
                <Box key={category.id}>
                  <TableItem
                    key={category.id}
                    isLastCategory={category?.id === list[list.length - 1]?.id}
                    item={category}
                    isExpanded={expandParentId === category.id}
                    onExpand={() => handleExpandChildrenList(category.id)}
                    onEdit={(parent, isCreate) =>
                      onEditCategoryDrawerOpen({
                        element: category,
                        parent,
                        isCreate,
                      })
                    }
                    repPermissions={repPermissions}
                  />
                  <Collapse in={expandParentId === category.id} timeout={300}>
                    {category?.childCategories?.map((child, indexChild) => (
                      <TableItem
                        key={child.id}
                        item={child}
                        isChild
                        isLastCategory={
                          category?.childCategories.length === indexChild + 1
                        }
                        onEdit={() =>
                          onEditCategoryDrawerOpen({
                            element: child,
                            parent: category,
                          })
                        }
                      />
                    ))}
                  </Collapse>
                </Box>
              ))}
              {!categoriesLoading && list.length < categoryCount && (
                <InfiniteLoadMoreBtn
                  onClick={() => handleFetchCategories(categoryPage + 1)}
                />
              )}
            </InfiniteScrollWrapper>
          </Stack>
        </>
      ) : (
        <EmptyScreen
          type="categories"
          height="calc(100vh - 242px)"
          loading={categoriesLoading}
        />
      )}
    </Box>
  );
};

CategoriesList.propTypes = {
  repPermissions: object,
};
