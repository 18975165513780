import { bool, func, object } from "prop-types";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { ArrowNextIcon, OutlinedPlusIcon } from "components/Icons";
import { useState } from "react";

export const CategoryItem = ({
  isAddCategory,
  category,
  isChild = false,
  isExpanded = false,
  onExpand = () => {},
  onClick = () => {},
  disabled = false,
}) => {
  const [isItemHovered, setIsItemHovered] = useState(false);
  const { name, childCategories } = category || {};

  const isUncategorized = name === "Uncategorized";

  return (
    <Grid
      onMouseEnter={() => setIsItemHovered(true)}
      onMouseLeave={() => setIsItemHovered(false)}
      onClick={(e) => {
        if (disabled) return;
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      sx={{
        p: "4px 10px 4px 16px",
        overflow: "hidden",
        cursor: disabled ? "default" : "pointer",
        background: isItemHovered && !disabled ? "#F5F5F5" : "#ffff",
        opacity: disabled ? 0.5 : 1,
        position: "relative",
        maxHeight: "32px",
        height: "32px",
      }}
      alignItems="center"
      justifyContent="space-between"
      container
    >
      {isAddCategory ? (
        <Stack alignItems="center" direction="row" gap="5px">
          <OutlinedPlusIcon strokeWidth="1.2" size="13" plusStrokeWidth="0.3" />
          <Typography fontSize={12} fontWeight={400} color="#409A65" noWrap>
            Add category
          </Typography>
        </Stack>
      ) : (
        <>
          <Typography
            fontSize={12}
            fontWeight={isChild ? 300 : 400}
            color="#1C1C19"
            noWrap
            maxWidth={!isChild ? "calc(100% - 34px)" : "100%"}
          >
            {name}
          </Typography>

          {!isChild &&
            !isUncategorized &&
            !!onExpand &&
            !!childCategories?.length && (
              <IconButton
                disableRipple
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onExpand();
                }}
                sx={{
                  p: "2px",
                  width: "16px",
                  opacity: 0.5,
                  transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
                  transition: "all 0.3s",
                }}
              >
                <ArrowNextIcon color="#707070" width={5.9} height={10.9} />
              </IconButton>
            )}
        </>
      )}
    </Grid>
  );
};

CategoryItem.propTypes = {
  category: object,
  onExpand: func,
  isChild: bool,
  isAddCategory: bool,
  isExpanded: bool,
  disabled: bool,
  onClick: func,
};
