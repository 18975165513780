import { useCallback, useEffect, useState } from "react";
import { oneOf } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { Box, Menu, MenuList } from "@mui/material";
import IOSSwitch from "../../IOSSwitch/IOSSwitch";

import { updateDistributorSettingsService } from "services/account";
import { currentUserSelector } from "redux/selectors/auth";
import { updateUserFieldAction } from "redux/actions/auth";
import { repsCountSelector } from "redux/selectors/reps";

import { error } from "utils/notifications";
import { SETTINGS } from "./TotalSummariesToggles.constants";
import StyledTooltip from "../../StyledTooltip";
import { getRepsSummariesAction } from "redux/actions/reps";
import {
  getTotalSummariesCatalogAction,
  getTotalSummariesOrdersAction,
} from "redux/actions/total-summaries";

const selector = createSelector(
  currentUserSelector,
  repsCountSelector,
  (currentUser, repsCount) => ({
    currentUser,
    repsCount,
  })
);

export const TotalSummariesToggles = ({ settingsType, ...props }) => {
  const { currentUser, repsCount } = useSelector(selector);
  const { totalSummarySettings, topThreeAvailability } = currentUser || {};
  const dispatch = useDispatch();

  const [state, setState] = useState({
    loading: false,
    list: [],
  });
  const { loading, list } = state;

  const setTooltipTitle = useCallback((item) => {
    if (!item?.checked) {
      if (item?.type === "topCustomers")
        return (
          <span>
            You must have at least 3 customers
            <br />
            with orders to display this widget
          </span>
        );
      if (item?.type === "topProducts")
        return (
          <span>
            You must have at least 3 products
            <br />
            with orders to display this widget
          </span>
        );
      if (item?.type === "topRepresentatives")
        return (
          <span>
            You must have at least 3 sales reps
            <br />
            with orders to display this widget
          </span>
        );
    }
    return " ";
  }, []);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      list: SETTINGS.map((el) => {
        return {
          ...el,
          checked: totalSummarySettings?.[settingsType]?.[el?.type],
          top_three_availability: !topThreeAvailability?.[el?.type],
        };
      }),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSummarySettings, repsCount]);

  const handleSetTopSummary = async (data) => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      const res = await updateDistributorSettingsService({
        totalSummarySettings: {
          ...totalSummarySettings,
          [settingsType]: data,
        },
      });

      if (settingsType === "representatives")
        dispatch(getRepsSummariesAction());

      dispatch(
        updateUserFieldAction("totalSummarySettings", {
          ...res?.totalSummarySettings,
        })
      );
      dispatch(getTotalSummariesCatalogAction());
      dispatch(getTotalSummariesOrdersAction());
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleApplyFilter = (type, checked) => {
    const preparedData = Object.keys({
      ...totalSummarySettings?.[settingsType],
    }).reduce((acc, cur) => {
      acc[cur] = false;
      return acc;
    }, {});

    handleSetTopSummary({ ...preparedData, [type]: checked });
  };

  return (
    <Menu
      id="toggles-menu"
      MenuListProps={{
        "aria-labelledby": "basic-button",
        sx: {
          width: "340px",
          px: "24px",
          py: "16px",
        },
      }}
      {...props}
    >
      {list.map((set) => (
        <Box key={set.label}>
          <StyledTooltip
            placement="right"
            arrow
            title={setTooltipTitle(set)}
            left="auto"
            disableHoverListener={!set?.top_three_availability}
          >
            <MenuList>
              <IOSSwitch
                disabled={loading || set?.top_three_availability}
                small
                label={set?.label}
                checked={set.checked}
                sx={{ cursor: "auto" }}
                onChange={(e) => {
                  handleApplyFilter(set.type, e.target.checked);
                }}
                formSx={{
                  "& .MuiFormControlLabel-label": { fontSize: "12px" },
                }}
              />
            </MenuList>
          </StyledTooltip>
        </Box>
      ))}
    </Menu>
  );
};

TotalSummariesToggles.propTypes = {
  settingsType: oneOf(["orders", "products", "representatives"]),
};
