import { useState } from "react";
import { bool, func, number, object, string } from "prop-types";
import { Box, IconButton, Paper, Skeleton, Typography } from "@mui/material";
import { PenIcon } from "components/Icons";
import DeliveryFeeDialog from "Pages/OrdersPage/components/DeliveryFeeDialog/DeliveryFeeDialog";
import { defaultValues } from "Pages/CartPage/CartPage.constants";

export const SummaryBlock = ({
  formField = {},
  reset = () => {},
  classes = {},
  priceListLoading = false,
  calculatedTotal = 0,
  totalOrderDiscount = 0,
  isThirdParty = false,
  isAdmin = false,
  deliveryFee = 0,
  deliveryCapAmount = 0,
  deliveryFeeAmount = 0,
  deliveryFeeType = "",
  setManualDeliveryFee = () => {},
  setDeliveryFee = () => {},
  control,
  setValue,
  errors,
  returnDelivery = () => {},
  grandTotal = 0,
  calculatedItemsDiscount = 0,
}) => {
  const [deliveryFeeOpen, setDeliveryFeeOpen] = useState(false);

  const handleUpdateDeliveryFee = () => {
    setDeliveryFeeOpen(false);
    setManualDeliveryFee(formField.manualDeliveryFee);
    if (formField.manualDeliveryFee)
      setDeliveryFee(Number(formField.deliveryFee));
  };

  return (
    <Paper className={classes.section} sx={{ p: "9px 36px 13px 28px" }}>
      <Typography
        color="#707070"
        fontWeight={600}
        fontSize="clamp(15px, 1.3vw, 25px)"
      >
        Summary
      </Typography>
      <Box borderBottom="1px solid #707070" pb="11px" mt="9px">
        <Box className={classes.summaryRow}>
          <Typography>Items total:</Typography>
          {priceListLoading ? (
            <Skeleton
              variant="text"
              sx={{
                fontSize: "clamp(15px, 1.3vw, 25px)",
                width: "20%",
              }}
            />
          ) : (
            <Typography>$ {calculatedTotal?.toFixed(2)}</Typography>
          )}
        </Box>
        <Box className={classes.summaryRow}>
          <Typography>Discounts:</Typography>
          {priceListLoading ? (
            <Skeleton
              variant="text"
              sx={{
                fontSize: "clamp(15px, 1.3vw, 25px)",
                width: "20%",
              }}
            />
          ) : (
            <Typography>
              {(calculatedItemsDiscount + totalOrderDiscount)?.toFixed(2)}
            </Typography>
          )}
        </Box>
        <Box className={classes.summaryRow}>
          <Box display="flex" gap="5px" alignItems="center">
            <Typography>Delivery:</Typography>
            {isThirdParty ? null : (
              <IconButton
                disabled={isAdmin}
                sx={{
                  backgroundColor: "#E6E6E6",
                  width: 19,
                  height: 19,
                  p: 0,
                  "&.Mui-disabled": {
                    backgroundColor: "#D5D9D9",
                    opacity: 0.5,
                  },
                }}
                onClick={() => setDeliveryFeeOpen(true)}
              >
                <PenIcon size={9} color="#000000" />
              </IconButton>
            )}
          </Box>
          {!!deliveryFeeOpen && (
            <DeliveryFeeDialog
              currentDeliveryFee={deliveryFee || deliveryCapAmount || 0}
              orderTotal={`${deliveryFeeAmount}${
                deliveryFeeType === "PERCENTAGE" ? "%" : "$"
              }`}
              open={deliveryFeeOpen}
              onClose={() => {
                reset({
                  ...formField,
                  manualDeliveryFee: defaultValues.manualDeliveryFee,
                  deliveryFee: defaultValues.deliveryFee,
                });
                setDeliveryFeeOpen(false);
              }}
              {...{ handleUpdateDeliveryFee, control, setValue, errors }}
            />
          )}
          <Typography>
            {isThirdParty
              ? "N/A"
              : `$ ${returnDelivery()?.toFixed(2)}` || "free"}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        color="#000000"
        mt="7px"
      >
        <Typography fontWeight="600" fontSize="20px">
          Grand total:
        </Typography>
        {priceListLoading ? (
          <Skeleton
            variant="text"
            sx={{
              fontSize: "20px",
              width: "25%",
            }}
          />
        ) : (
          <Typography fontWeight="600" fontSize="20px">
            ${grandTotal?.toFixed(2)}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

SummaryBlock.propTypes = {
  formField: object,
  reset: func,
  classes: object,
  priceListLoading: bool,
  calculatedTotal: number,
  totalOrderDiscount: number,
  isThirdParty: bool,
  isAdmin: bool,
  setDeliveryFeeOpen: func,
  deliveryFee: number,
  deliveryCapAmount: number,
  deliveryFeeAmount: number,
  deliveryFeeType: string,
  setManualDeliveryFee: func,
  setDeliveryFee: func,
  control: object,
  setValue: func,
  errors: object,
  returnDelivery: func,
  grandTotal: number,
  calculatedItemsDiscount: number,
};
