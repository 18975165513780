/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";
import * as React from "react";

export const PencilIcon = (props) => (
  <SvgIcon
    viewBox="0 0 15 15"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ width: 15, height: 15, fill: "none" }}
    {...props}
  >
    <path
      d="m1.333 13.69-.493-.082a.5.5 0 0 0 .575.575zm.527-3.16-.494-.082zm.742-1.442-.354-.354zm7.731-7.731.354.353zm-5.84 11.807.082.493zm1.442-.743-.353-.353zm7.731-7.73-.353-.354zM5.3 12.964l.228.445zm.005-.002.229.445v-.001zm8.361-11.606.354-.354zM2.058 9.723l-.445-.229v.001zm.002-.005-.444-.23v.002zM14.5 14a.5.5 0 0 0 0-1zm-6-1a.5.5 0 1 0 0 1zm-6.674.772.527-3.16-.987-.164-.526 3.16zm1.13-4.33 7.73-7.732-.707-.707-7.73 7.731zm-1.54 4.741 3.159-.526-.164-.987-3.16.527zm4.873-1.408 7.73-7.731-.706-.707-7.731 7.73zm-1.714.882c.385-.064.684-.109.953-.247l-.456-.89c-.1.051-.216.076-.661.15zm1.007-1.59c-.32.32-.408.4-.507.452l.46.888c.268-.139.478-.356.754-.632zm-.053 1.343.005-.002-.458-.89-.005.003zm7.784-11.7a1.857 1.857 0 0 1 0 2.627l.707.707a2.857 2.857 0 0 0 0-4.04zm-2.626 0a1.857 1.857 0 0 1 2.626 0l.707-.707a2.857 2.857 0 0 0-4.04 0zm-8.334 8.903c.074-.446.099-.563.15-.662l-.89-.456c-.138.27-.182.568-.247.953zm-.105-1.879c-.276.276-.493.486-.632.755l.888.46c.052-.1.132-.188.452-.508zm.254 1.218.003-.005-.89-.457-.002.004zm9.851-3.949L9.02 2.67l-.707.707 3.333 3.333zM14.5 13h-6v1h6z"
      fill="#A5A5A5"
    />
  </SvgIcon>
);
