/* eslint-disable react/prop-types */
import * as React from "react";
import { SvgIcon } from "@mui/material";
export const CloseIcon = ({
  width = 14,
  height = 14,
  sx = {},
  closeToast,
  ...props
}) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width, height, ...sx }}
      onClick={closeToast}
      {...props}
    >
      <path
        d="M12.293.293a1 1 0 1 1 1.414 1.414L8.414 7l5.293 5.293a1 1 0 0 1-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L5.586 7 .293 1.707A1 1 0 0 1 1.707.293L7 5.586z"
        fill="#fff"
      />
    </SvgIcon>
  );
};
