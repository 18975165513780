import { DISCOUNT_APPLICABLE_ON_SCOPES } from "Pages/DiscountsPage/components/NewDiscountPage/NewDiscountsPage.constants";
import pluralize from "pluralize";

export const getProductId = (product) => {
  return product?.productId || product?.id || product?.product?.id;
};

export const getDiscountId = (discount) => {
  return (
    discount?.manufacturerDiscountId ||
    discount?.discountDuplicateId ||
    discount?.id
  );
};

export const getDisabledForMultiplier = ({
  discount,
  checkedDiscounts,
  productsList,
  prodDict,
}) => {
  let allowedCount = 0;
  let disabledCondition = false;
  const discountId = getDiscountId(discount);

  if (discount.productType === "ALL_PRODUCTS") {
    const currentManufacturerDiscounts = checkedDiscounts.filter(
      (checked) => checked.manufacturer?.id === discount?.manufacturer?.id
    );
    const checkedMOQSum = currentManufacturerDiscounts.reduce((acc, cur) => {
      return acc + (cur?.minQuantity * cur?.multiplier || 0);
    }, 0);

    if (!prodDict[discountId]) return true;

    const countQtyProducts = prodDict[discountId]?.reduce((acc, cur) => {
      return acc + cur?.quantity;
    }, 0);

    allowedCount = countQtyProducts - checkedMOQSum;
    disabledCondition = allowedCount < discount?.minQuantity;
  }

  if (discount.productType === "SPECIFIC_PRODUCTS") {
    const productsWithSameManufacturer = productsList.filter(
      (product) => getProductManufacturer(product) === discount.manufacturer?.id
    );

    const countQtyAllManufacturerProducts = productsWithSameManufacturer.reduce(
      (acc, cur) => {
        return acc + cur?.quantity;
      },
      0
    );

    if (!prodDict[discountId]) return true;

    const countQtyCurrentDiscountProducts = prodDict[discountId].reduce(
      (acc, cur) => {
        return acc + cur?.quantity;
      },
      0
    );

    const currentManufacturerDiscounts = checkedDiscounts.filter(
      (checked) => checked.manufacturer?.id === discount?.manufacturer?.id
    );
    const checkedMOQSum = currentManufacturerDiscounts.reduce((acc, cur) => {
      return acc + (cur?.minQuantity * cur?.multiplier || 0);
    }, 0);

    allowedCount = countQtyAllManufacturerProducts - checkedMOQSum;

    const currentManufacturerDiscountsByProduct =
      getCurrentManufacturerDiscounts({ discount, checkedDiscounts });

    const checkedMOQSumByProduct = currentManufacturerDiscountsByProduct.reduce(
      (acc, cur) => {
        return acc + (cur?.minQuantity * cur?.multiplier || 0);
      },
      0
    );

    const allowedCountByProduct =
      countQtyCurrentDiscountProducts - checkedMOQSumByProduct;

    disabledCondition =
      allowedCount < discount?.minQuantity ||
      allowedCountByProduct < discount?.minQuantity;
  }

  return disabledCondition;
};

export const getAllowedCountForMultiplier = ({
  discount,
  checkedDiscounts,
  productsList,
  prodDict,
}) => {
  let allowedCount = 0;
  const discountId = getDiscountId(discount);
  if (discount.productType === "ALL_PRODUCTS") {
    const currentManufacturerDiscounts = checkedDiscounts.filter(
      (checked) => checked.manufacturer?.id === discount?.manufacturer?.id
    );
    const checkedMOQSum = currentManufacturerDiscounts.reduce((acc, cur) => {
      return getDiscountId(cur) !== discountId
        ? acc + (cur?.minQuantity * cur?.multiplier || 0)
        : acc;
    }, 0);

    if (!prodDict[discountId]) return 0;

    const countQtyProducts = prodDict[discountId]?.reduce((acc, cur) => {
      return acc + cur?.quantity;
    }, 0);

    allowedCount = countQtyProducts - checkedMOQSum;

    return allowedCount;
  }

  if (discount.productType === "SPECIFIC_PRODUCTS") {
    const productsWithSameManufacturer = productsList.filter(
      (product) => getProductManufacturer(product) === discount.manufacturer?.id
    );

    const countQtyAllManufacturerProducts = productsWithSameManufacturer.reduce(
      (acc, cur) => {
        return acc + cur?.quantity;
      },
      0
    );

    if (!prodDict[discountId]) return 0;

    const countQtyCurrentDiscountProducts = prodDict[discountId].reduce(
      (acc, cur) => {
        return acc + cur?.quantity;
      },
      0
    );

    const currentManufacturerDiscounts = checkedDiscounts.filter(
      (checked) => checked.manufacturer?.id === discount?.manufacturer?.id
    );
    const checkedMOQSum = currentManufacturerDiscounts.reduce((acc, cur) => {
      return getDiscountId(cur) !== discountId
        ? acc + (cur?.minQuantity * cur?.multiplier || 0)
        : acc;
    }, 0);

    allowedCount = countQtyAllManufacturerProducts - checkedMOQSum;

    const currentManufacturerDiscountsByProduct =
      getCurrentManufacturerDiscounts({ discount, checkedDiscounts });

    const checkedMOQSumByProduct = currentManufacturerDiscountsByProduct.reduce(
      (acc, cur) => {
        return getDiscountId(cur) !== discountId
          ? acc + (cur?.minQuantity * cur?.multiplier || 0)
          : acc;
      },
      0
    );

    const allowedCountByProduct =
      countQtyCurrentDiscountProducts - checkedMOQSumByProduct;

    return Math.min(allowedCount, allowedCountByProduct);
  }

  return allowedCount;
};

export const getCurrentManufacturerDiscounts = ({
  discount,
  checkedDiscounts,
}) => {
  return checkedDiscounts.filter((checked) => {
    const matchedByManufacturer =
      checked.manufacturer?.id === discount?.manufacturer?.id;

    const matchedByProduct = discount.products?.some((disProd) =>
      checked.products?.some(
        (checkedDisProd) =>
          (disProd.product?.id &&
            disProd.product?.id === checkedDisProd.product?.id) ||
          (disProd.productId &&
            disProd.productId === checkedDisProd.productId) ||
          (disProd.product?.id && disProd.product?.id === checkedDisProd.id) ||
          (disProd.productId &&
            disProd.productId === checkedDisProd.product?.id)
      )
    );

    return discount.productType === "ALL_PRODUCTS"
      ? matchedByManufacturer
      : matchedByManufacturer && matchedByProduct;
  });
};

const getQuantityDiscountSum = ({
  currentDiscount,
  chosenFreeCaseProducts,
  discountsList,
}) => {
  const currentProducts = chosenFreeCaseProducts.filter(
    (p) =>
      !!p.freeCaseChosen &&
      getProductManufacturer(p) === currentDiscount.manufacturer?.id
  );

  const currentQuantityDiscounts = discountsList.filter(
    ({ type, manufacturer }) =>
      type === "QUANTITY" &&
      manufacturer.id === currentDiscount.manufacturer?.id
  );

  const assignedFreeCasesState = currentQuantityDiscounts?.reduce(
    (acc, cur) => {
      let currentMultiplierLimit = cur?.multiplier * cur?.quantityDiscount;

      const limitedProductsList = currentProducts.map((p) => {
        const previouslyUsedQty = Object.values(acc)
          .flatMap((val) => val)
          .filter(({ id }) => id === p.id)
          .reduce(
            (sum, currentProduct) => sum + (currentProduct?.discountQty || 0),
            0
          );

        const discountQty = p?.discountQty - previouslyUsedQty;

        const isInLimit = discountQty <= currentMultiplierLimit;

        const limitedProduct = isInLimit
          ? { ...p, discountQty }
          : { ...p, discountQty: currentMultiplierLimit };

        currentMultiplierLimit =
          currentMultiplierLimit - discountQty < 0
            ? 0
            : currentMultiplierLimit - discountQty;

        return limitedProduct;
      });

      return Object.assign(acc, {
        [cur?.id]: limitedProductsList,
      });
    },
    {}
  );

  const currentDiscountSum =
    assignedFreeCasesState[currentDiscount.id]?.reduce((sum, cur) => {
      // eslint-disable-next-line no-prototype-builtins
      const price = cur.price ?? cur.wholesalePrice ?? 0;
      return sum + cur.discountQty * price;
    }, 0) || 0;

  return currentDiscountSum;
};

export const getCurrentDiscountSum = ({
  currentDiscount,
  productsList,
  discountsList,
  isPayed,
  chosenFreeCaseProducts,
}) => {
  if (!currentDiscount) return 0;
  if (currentDiscount?.type === "QUANTITY") {
    const sum = getQuantityDiscountSum({
      currentDiscount,
      chosenFreeCaseProducts,
      discountsList,
    });

    return sum;
  } else {
    return getMSDDiscountSum(
      productsList,
      [currentDiscount],
      isPayed,
      chosenFreeCaseProducts
    );
  }
};

export const getMSDDiscountSum = (
  productsList,
  discountsList,
  isPayed,
  chosenFreeCaseProducts
) => {
  const discountSum = productsList.reduce((prevSum, currProd) => {
    // match discount with a product
    const discSum = getSingleMSD(
      currProd,
      discountsList,
      isPayed,
      chosenFreeCaseProducts,
      productsList
    );
    return prevSum + discSum;
  }, 0);
  return discountSum;
};

export const getSingleMSD = (
  product,
  discountsList,
  isPayed,
  chosenFreeCaseProducts,
  productsList
) => {
  const freeCaseProduct = chosenFreeCaseProducts?.find(
    (p) => p?.id === product?.id || p?.id === product?.product?.id
  );
  const matched = discountsList.filter((discount) => {
    const disManId = discount.manufacturer?.id;

    const containsDiscount = product.manufacturerDiscountDuplicates?.find(
      (d) => getDiscountId(d) === getDiscountId(discount)
    );
    if (isPayed && !containsDiscount && !product.isNewAdded) return 0;
    // match by manufacturer first
    const matchedByManufacturer =
      disManId === product?.manufacturer?.id ||
      disManId === product?.manufacturerId ||
      disManId === product?.parentProduct?.manufacturer?.id;
    // match by product just to be sure
    const matchedByProduct = discount?.products?.some((disProd) => {
      const discountProductId = disProd?.product?.id || disProd?.productId;
      const productId = product?.product?.id || product?.id;

      return discountProductId === productId;
    });

    const currentManufacturerProducts = productsList.filter(
      ({ manufacturer, parentProduct, manufacturerId }) =>
        manufacturer?.id === disManId ||
        disManId === parentProduct?.manufacturer?.id ||
        disManId === manufacturerId
    );

    const currentManufacturerProductsQtySum =
      currentManufacturerProducts.reduce(
        (prevSum, { quantity }) => prevSum + (quantity || 0),
        0
      );

    const currentManufacturerProductsPurchaseSum =
      currentManufacturerProducts.reduce(
        (prevSum, { quantity, wholesalePrice, price }) =>
          prevSum + (quantity || 0) * (price ?? wholesalePrice),
        0
      );

    const matchedByQuantity =
      discount?.type === "QUANTITY"
        ? product?.discountQty >= 0 && !!freeCaseProduct?.freeCaseChosen
        : discount?.requirements === "MIN_QUANTITY"
        ? currentManufacturerProductsQtySum >= discount?.minQuantity
        : true;

    const matchedByMPA =
      discount?.requirements === "MIN_PURCHASE_AMOUNT"
        ? currentManufacturerProductsPurchaseSum >= discount.minPurchaseAmount
        : true;

    // if applied to all products, use only first criteria, if applied to specific => use both
    return discount?.productType === "ALL_PRODUCTS"
      ? matchedByManufacturer && matchedByQuantity && matchedByMPA
      : matchedByManufacturer &&
          matchedByProduct &&
          matchedByQuantity &&
          matchedByMPA;
  });
  // sum up all matched discounts, without ABSOLUTE type
  const discSum = matched.reduce((prevDiscSum, currDisc) => {
    const price = product.price ?? product.wholesalePrice ?? 0;

    // SD-8490: Cart Total - the discount is applied on sum of the product; Each SKU - the new one which will apply the discount value on each added product. E.g: if 10 pcs of Cola1 were added to the cart, the Each SKU discount type will by applied on each of 10 added pcs.
    const quantityFactor =
      currDisc.scope === DISCOUNT_APPLICABLE_ON_SCOPES.CART_TOTAL
        ? 1
        : product.quantity;

    const disc =
      (currDisc.type === "PERCENTAGE" &&
        (currDisc.percentDiscount / 100) * price * product.quantity) ||
      (currDisc.type === "QUANTITY" && (product?.discountQty || 0) * price) ||
      (currDisc.type === "ABSOLUTE" &&
        currDisc.absoluteDiscount * quantityFactor);

    // add this sum to previous sum
    return prevDiscSum + disc;
  }, 0);
  return discSum;
};

export const getMatchedByProduct = ({ isEdit, discount, product }) => {
  if (isEdit && discount.orderProducts) {
    return discount.orderProducts?.some(
      (disProd) => disProd.orderProductId === product.id
    );
  }

  if (isEdit && discount.draftOrderProducts) {
    return discount.draftOrderProducts?.some(
      (disProd) => disProd.draftOrderProductId === product.id
    );
  }

  // SD-8280
  return discount.products?.some(
    (disProd) =>
      disProd.product?.id === product.id ||
      disProd.product?.id === product.product?.id ||
      disProd.productId === product.id
  );
};

// create discounts dictionary: product.id: [discounts]
export const getDiscountsDictionary = ({
  productsList,
  checkedDiscounts,
  isPayed,
  isEdit,
}) => {
  const discountsDict = [];
  productsList.filter(Boolean).forEach((product) => {
    const matched = checkedDiscounts.filter((checkedDiscount) => {
      const disManId = checkedDiscount.manufacturer?.id;
      const matchedByManufacturer =
        disManId === product.manufacturer?.id ||
        disManId === product.parentProduct?.manufacturer?.id ||
        disManId === product.manufacturerId;

      const matchedByProduct = getMatchedByProduct({
        isEdit,
        discount: checkedDiscount,
        product,
      });

      const currentManufacturerProducts = productsList.filter(
        ({ manufacturer, parentProduct, manufacturerId }) =>
          manufacturer?.id === disManId ||
          disManId === parentProduct?.manufacturer?.id ||
          disManId === manufacturerId
      );

      const currentManufacturerProductsQtySum =
        currentManufacturerProducts.reduce(
          (prevSum, { quantity }) => prevSum + (quantity || 0),
          0
        );

      const currentManufacturerProductsPurchaseSum =
        currentManufacturerProducts.reduce(
          (prevSum, { quantity, wholesalePrice, price }) =>
            prevSum + (quantity || 0) * (price ?? wholesalePrice),
          0
        );

      const matchedByQuantity =
        checkedDiscount?.requirements !== "MIN_QUANTITY" ||
        currentManufacturerProductsQtySum >= checkedDiscount?.minQuantity;

      const matchedByMPA =
        checkedDiscount?.requirements === "MIN_PURCHASE_AMOUNT"
          ? currentManufacturerProductsPurchaseSum >=
            checkedDiscount.minPurchaseAmount
          : true;

      return checkedDiscount.productType === "ALL_PRODUCTS"
        ? matchedByManufacturer && matchedByQuantity && matchedByMPA
        : matchedByManufacturer &&
            matchedByProduct &&
            matchedByQuantity &&
            matchedByMPA;
    });
    if (!product.isNewAdded && isPayed) {
      // const findDiscountsInDuplicates = product.manufacturerDiscountDuplicates.map(d => )
      return (discountsDict[product.id] = []);
    }
    if (product.id in discountsDict) discountsDict[product.id].push(matched);
    else discountsDict[product.id] = matched;
  });
  return discountsDict;
};

// create products dictionary: discount.id: [products]
export const getProductsDictionary = (productsList, discountsList, isPayed) => {
  const productsDict = [];
  discountsList.forEach((discount) => {
    const matched = productsList
      .filter((p) => (isPayed ? p.isNewAdded : p))
      .filter((product) => {
        const disManId = discount.manufacturer?.id;
        const matchedByManufacturer =
          disManId === product.manufacturer?.id ||
          disManId === product.parentProduct?.manufacturer?.id ||
          disManId === product.manufacturerId;

        const matchedByProduct = discount.products?.some(
          (disProd) =>
            (disProd.product?.id && disProd.product?.id === product.id) ||
            (disProd.productId && disProd.productId === product.id) ||
            (disProd.product?.id &&
              disProd.product?.id === product.product?.id) ||
            (disProd.productId && disProd.productId === product.product?.id)
        );

        return discount.productType === "ALL_PRODUCTS"
          ? matchedByManufacturer
          : matchedByManufacturer && matchedByProduct;
      });
    if (discount.id in productsDict) productsDict[discount.id].push(matched);
    else productsDict[discount.id] = matched;
  });
  return productsDict;
};

// ACTUAL: take all products.quantity and price for MPA comparison (removed previous condition) (SD-8755)
export const filterByMPA = (discount, dictionary) => {
  const productsPurchaseAmountSum = dictionary[discount.id]?.reduce(
    (prevSum, currProd) => {
      const price = currProd?.price ?? currProd?.wholesalePrice;
      return prevSum + currProd?.quantity * price;
    },
    0
  );

  return productsPurchaseAmountSum < discount.minPurchaseAmount;
};

// OUTDATED: if discount type is 'quantity' (free cases), take all products.quantity for MOQ comparison. Otherwise take 1 product.quantity (SD-5478)

// ACTUAL: take all products.quantity for MOQ comparison (removed previous condition) (SD-8403)
export const filterByMOQ = (discount, dictionary) => {
  const productsQuantity = dictionary[discount.id]?.reduce(
    (prevSum, currProd) => prevSum + currProd?.quantity,
    0
  );

  return productsQuantity < discount.minQuantity;
};

export const filterByUsage = (discount) => {
  return discount?.limit && discount?.limit <= discount?.usageCount;
};

export const filterByRequirements = (discount, dictionary) => {
  return (
    filterByMOQ(discount, dictionary) ||
    filterByMPA(discount, dictionary) ||
    filterByUsage(discount)
  );
};

export const getValueLabel = (item) => {
  if (item?.productType !== "ALL_PRODUCTS") return "off specific products";
  if (item?.type === "QUANTITY")
    return pluralize("free case", item.quantityDiscount, false);
  if (item?.type === "ABSOLUTE") return "off all products";
  return "off all products";
};

export const setDiscountValue = (item) => {
  switch (item.type) {
    case "ABSOLUTE":
      return `$${item?.absoluteDiscount.toFixed(2)}`;
    case "PERCENTAGE":
      return `${item?.percentDiscount}%`;
    default:
      return item?.quantityDiscount;
  }
};

export const getProductManufacturer = ({
  manufacturer,
  parentProduct,
  manufacturerId,
}) => {
  return manufacturer?.id || parentProduct?.manufacturer?.id || manufacturerId;
};

export const getProductManufacturerName = ({
  manufacturer,
  parentProduct,
  manufacturerName,
}) => {
  return (
    manufacturer?.name || parentProduct?.manufacturer?.name || manufacturerName
  );
};

const getMOQDisabled = (discount, prodDict) => {
  // MOQ - minimum order quantity
  return filterByMOQ(discount, prodDict);
};

const getMPADisabled = (discount, prodDict) => {
  // MPA - minimum purchase amount
  return filterByMPA(discount, prodDict);
};

export const checkDisabledDiscount = (
  discount,
  discountsList,
  checkedDiscounts,
  prodDict
) => {
  const sameManufacturerMSD = discountsList.filter(
    (msd) => msd?.manufacturer?.id === discount?.manufacturer?.id
  );

  const oneSiblingChecked = sameManufacturerMSD?.some((child) =>
    checkedDiscounts
      .filter((checked) => checked.manufacturer?.id === child?.manufacturer?.id)
      .find((checked) => {
        if (checked.productType === "ALL_PRODUCTS")
          return (
            (checked.id === child.id ||
              checked.manufacturerDiscountId === child.id) &&
            child.id !== discount.id
          );
        if (checked.productType === "SPECIFIC_PRODUCTS") {
          return (
            discount.productType === "ALL_PRODUCTS" ||
            checked.products.some(
              (p) =>
                checked.id !== discount.id &&
                checked.manufacturerDiscountId !== discount.id &&
                discount.products.find(
                  ({ product: compareProduct }) =>
                    compareProduct?.id === p.product?.id ||
                    compareProduct?.id === p.productId
                )
            )
          );
        }
        return false;
      })
  );
  // if an MSD accordion has 1 checked discount, disable the rest -- OUTDATED --
  // One product specific discount can be added to the order with other product specific discount if these discounts will be applied to different product
  //  If All products discount was added to the shopping cart, no other discount could be added from the same Manufacturer
  // When non-conflicting (product specific) MSD's are selected - disable All products discounts in the list
  // When product specific MSD is selected - all conflicting MSD's will be disabled in the discounts list
  return (
    // check if sum of qty or price of one category products add up to discount's minQ ir minPA
    getMOQDisabled(discount, prodDict) ||
    getMPADisabled(discount, prodDict) ||
    oneSiblingChecked
  );
};
