/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";

export const WarningIcon = ({ width = 4, height = 14, ...props }) => (
  <SvgIcon
    viewBox="0 0 4 16"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fill: "none", width, height }}
    {...props}
  >
    <path d="M2 1v9" stroke="#A5A5A5" strokeWidth={1.5} strokeLinecap="round" />
    <circle cx={2} cy={14.25} r={0.75} fill="#A5A5A5" stroke="#A5A5A5" />
  </SvgIcon>
);
