import { useTheme } from "@emotion/react";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { StyledButton, StyledTextField } from "components";
import useStyles from "./styles";
import { bool, func, object } from "prop-types";
import { CheckmarkIcon, CrossIcon } from "components/Icons";
import { checkStoreNameAvailabilityService } from "services/order-directs";
import { useDebounce } from "helpers/hooks";
import {
  CUSTOMER_SHOP_LINK_LOGIN,
  removeProtocol,
} from "../../OrderDirectTab.constants";

const SetStoreLinkModal = ({
  open,
  onClose,
  onConfirm,
  onLinkChange,
  widgetState,
  updateAvailability,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { uniqueChecked, link, error } = useMemo(
    () => widgetState,
    [widgetState]
  );

  const validationIcon = useMemo(() => {
    if (error)
      return (
        <CrossIcon size="42" opacity={1} fill={theme.palette.error.light} />
      );

    if (uniqueChecked) {
      return <CheckmarkIcon width="42" height="36" />;
    }
    return (
      <Box display="flex" alignItems="center">
        <CircularProgress size={20} />
      </Box>
    );
  }, [error, theme.palette.error.light, uniqueChecked]);

  const linkDebounced = useDebounce(link, 500);

  const checkUniqueStoreLink = useCallback(async () => {
    if (!open || widgetState.error || !linkDebounced) return;
    updateAvailability({ error: false, uniqueChecked: false });

    const res = await checkStoreNameAvailabilityService(linkDebounced);

    const exists = res?.exists;

    updateAvailability({ error: exists, uniqueChecked: true });
  }, [linkDebounced, open, updateAvailability, widgetState.error]);

  useEffect(checkUniqueStoreLink, [checkUniqueStoreLink]);

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      <DialogTitle>Store Link Set</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.fieldWrapper}>
          <Typography>
            Once store name is confirmed you will not be able to make changes.
            Please confirm.
          </Typography>
          <StyledTextField
            error={error ? "Store name is already used" : ""}
            onChange={onLinkChange}
            value={link}
            startAdornment={
              <Typography className={`${classes.storeLinkPrefix} dialogField`}>
                {removeProtocol(CUSTOMER_SHOP_LINK_LOGIN)}
              </Typography>
            }
            className={`${classes.storeLinkField} dialogField`}
            endIcon={validationIcon}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <StyledButton
          label="Confirm"
          disabled={!uniqueChecked || error}
          variant="contained"
          fontSize="20px"
          onClick={() => {
            onClose();
            onConfirm();
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

SetStoreLinkModal.propTypes = {
  open: bool,
  onClose: func,
  onConfirm: func,
  onLinkChange: func,
  widgetState: object,
  updateAvailability: func,
};

SetStoreLinkModal.defaultProps = {
  open: false,
  onClose: () => {},
  onConfirm: () => {},
  onLinkChange: () => {},
  widgetState: {},
  updateAvailability: () => {},
};

export default SetStoreLinkModal;
