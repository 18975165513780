import axios from "axios";

export const getNotificationsService = async (params) => {
  return await axios
    .get("/notification-centre/notifications", { params })
    .then((res) => res.data);
};

export const updateNotificationService = async (id) => {
  return await axios
    .put(`/notification-centre/notifications/${id}`)
    .then((res) => res.data);
};

export const bulkUpdateByArrayNotificationService = async (data) => {
  return await axios
    .put("/notification-centre/notifications/bulk", data)
    .then((res) => res.data);
};

export const bulkUpdateNotificationService = async (data) => {
  return await axios
    .put(`/notification-centre/notifications/bulk`, data)
    .then((res) => res.data);
};

export const getNotificationSettingsService = async () => {
  return await axios
    .get("/notification-centre/settings")
    .then((res) => res.data);
};

export const updateNotificationSettingsService = async (data) => {
  return await axios
    .put("/notification-centre/settings", data)
    .then((res) => res.data);
};
