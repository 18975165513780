import { object, func } from "prop-types";
import useStyles from "./styles";
import { IconButton, Menu, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CrossIcon, DiscountBreakdownIcon } from "../../../../components/Icons";
import { DISCOUNT_TYPES } from "../../../CartPage/CartPage.constants";

const DiscountsPopper = ({ anchorEl, order, handleClose }) => {
  const classes = useStyles();

  const discountRequirements = (discount) => {
    switch (discount.requirements) {
      case "NONE":
        return "";
      case "MIN_PURCHASE_AMOUNT":
        return `min PA: $${discount.minPurchaseAmount.toFixed(2)}`;
      case "MIN_QUANTITY":
        return `min QTY: ${discount.minQuantity}`;
      default:
        return "";
    }
  };

  const discountSum = (discount) => {
    const absolute = discount.type === "ABSOLUTE";
    const quantity = discount.type === "QUANTITY";
    const percent = discount.type === "PERCENTAGE";

    const filteredProducts = order?.products
      ?.map((orderProd) => {
        const foundDuplicate = orderProd.manufacturerDiscountDuplicates.find(
          (prodDiscount) => prodDiscount.discountDuplicateId === discount.id
        );
        if (foundDuplicate)
          return { ...orderProd, discountQty: foundDuplicate.quantity };
      })
      .filter(Boolean);

    const sum = filteredProducts.reduce(
      (prevSum, currProd) =>
        (absolute &&
          discount.absoluteDiscount *
            (discount?.orderProducts?.length ||
              discount?.draftOrderProducts?.length)) ||
        (quantity &&
          prevSum +
            currProd.discountQty *
              (currProd.price || currProd.wholesalePrice || 0)) ||
        (percent && discount.percentDiscount),
      0
    );

    const discountValue = discount?.manufacturerDiscountValue;

    return absolute || quantity ? `-$${discountValue}` : `-${sum}%`;
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      PaperProps={{ className: classes.menu }}
      sx={{
        ".MuiMenu-list": {
          padding: 0,
        },
      }}
    >
      <Box className={classes.popperWrapper}>
        <Box className={classes.popperTitleWrapper}>
          {/* <InformationBoldIcon /> */}
          <Typography className={classes.popperTitle}>
            Discount breakdown
          </Typography>
          <IconButton onClick={handleClose}>
            <CrossIcon />
          </IconButton>
        </Box>
        {!!order.customerDiscount && (
          <Box className={classes.discountBlock}>
            <Box className={classes.discountTitleWrapper}>
              <DiscountBreakdownIcon />
              <Typography className={classes.discountTitle}>
                Customer discount
              </Typography>
            </Box>
            <Box className={classes.ISDWrapper}>
              <Typography className={classes.discountTitle}>
                Customer discount
              </Typography>
              <Typography className={classes.discountTitle}>
                -{order.customerDiscount}%
              </Typography>
            </Box>
          </Box>
        )}
        {order.appliedDiscountsType === DISCOUNT_TYPES.manufacturer && (
          <Box className={classes.discountBlock}>
            <Box className={classes.discountTitleWrapper}>
              <DiscountBreakdownIcon />
              <Typography className={classes.discountTitle}>
                Manufacturer specific discounts
              </Typography>
            </Box>
            {order?.manufacturerDiscountDuplicates?.map((discount) => (
              <Box key={discount.id} className={classes.MSDWrapper}>
                <Box display="flex" justifyContent="space-between">
                  <Typography className={classes.discountTitle} noWrap>
                    {discount.name}
                  </Typography>
                  <Typography className={classes.discountTitle} noWrap>
                    {discountSum(discount)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                >
                  <Box maxWidth="65%">
                    <Typography
                      className={classes.discountTitle}
                      fontWeight="400"
                      noWrap
                    >
                      by {discount.manufacturer?.name}
                    </Typography>
                    {order?.products
                      ?.filter((orderProd) =>
                        orderProd.manufacturerDiscountDuplicates.some(
                          (prodDiscount) =>
                            prodDiscount.discountDuplicateId === discount.id
                        )
                      )
                      .map((product) => (
                        <Typography
                          className={classes.discountTitle}
                          fontWeight="400"
                          key={product.id || product.productId}
                          noWrap
                        >
                          {product.sku}{" "}
                          {product.size || product.color
                            ? `/ ${product.size || product.color}`
                            : ""}
                        </Typography>
                      ))}
                  </Box>
                  <Typography
                    className={classes.discountTitle}
                    fontWeight="400"
                    whiteSpace="nowrap"
                  >
                    {discountRequirements(discount)}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {order.appliedDiscountsType === DISCOUNT_TYPES.item && (
          <Box className={classes.discountBlock}>
            <Box className={classes.discountTitleWrapper}>
              <DiscountBreakdownIcon />
              <Typography className={classes.discountTitle}>
                Item specific discounts
              </Typography>
            </Box>
            {order?.products
              ?.filter((p) => p.itemDiscountAmount)
              ?.map((product) => (
                <Box key={product.id} className={classes.MSDWrapper}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography className={classes.discountTitle} noWrap>
                      {product.name}
                    </Typography>

                    <Typography className={classes.discountTitle}>
                      {product?.itemDiscountType === "PERCENTAGE"
                        ? `-${product?.itemDiscountAmount}%/per item`
                        : `-$${product?.itemDiscountValue?.toFixed(
                            2
                          )}/per item`}
                    </Typography>
                  </Box>
                  <Typography
                    className={classes.discountTitle}
                    fontWeight="400"
                    noWrap
                  >
                    by {product.manufacturerName}
                  </Typography>
                  <Typography
                    className={classes.discountTitle}
                    fontWeight="400"
                    noWrap
                  >
                    {product.sku}{" "}
                    {product.size || product.color
                      ? `/ ${product.size || product.color}`
                      : ""}
                  </Typography>
                </Box>
              ))}
          </Box>
        )}
        {order.appliedDiscountsType === DISCOUNT_TYPES.total && (
          <Box className={classes.discountBlock}>
            <Box className={classes.discountTitleWrapper}>
              <DiscountBreakdownIcon />
              <Typography className={classes.discountTitle}>
                Invoice total discount
              </Typography>
            </Box>
            <Box className={classes.ISDWrapper}>
              <Typography className={classes.discountTitle}>
                Invoice total discount
              </Typography>
              <Typography className={classes.discountTitle} noWrap>
                {order?.totalOrderDiscountType === "PERCENTAGE"
                  ? `-${order?.totalOrderDiscountAmount}%/per item`
                  : `-$${order?.totalOrderDiscountValue?.toFixed(2)}/per item`}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Menu>
  );
};

DiscountsPopper.propTypes = {
  anchorEl: object,
  order: object,
  handleClose: func,
};

export default DiscountsPopper;
