import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { array, bool, func, object, shape, string } from "prop-types";
import { isEqual } from "lodash";

import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import useStyles from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/styles";
import { cl } from "./TaskDrawer.styles";
import { CheckmarkIcon, CrossIcon } from "components/Icons";
import {
  ActionRestrictionWrapper,
  AddFormsComponent,
  StyledButton,
  StyledSelect,
  StyledTextField,
  StyledSelectTime,
  StyledTooltip,
} from "components";
import {
  useCustomerTasks,
  useFormFields,
  useTaskAssigne,
} from "./TaskDrawer.hooks";
import { Controller } from "react-hook-form";
import { ActivityBlock } from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/ActivityBlock/ActivityBlock";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { getPriority } from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/TaskDrawer.hooks";
import { AdvancedDrawerContent } from "./components/AdvancedDrawerContent";
import { ConfirmTaskDialog } from "./components/ConfirmTaskDialog";
import AllCustomersPopup from "Pages/CreateRoutePage/components/AllCustomersPopup";
import { useRepsPermissions } from "helpers/hooks";
import {
  isValidNotPastDate,
  onPasteTextWithMaxLength,
  useAdmin,
} from "helpers/helpers";
import { setFormChangedAction } from "redux/actions/confirmDialogs";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { confirmDialogFormChangedSelector } from "redux/selectors/confirmDialogs";
import { ClockNewIcon } from "components/Icons/ClockNewIcon";
import { DeleteDialog } from "Pages/TasksPage/components/TasksTab/components/TasksList/components";
import { DueDateBlock } from "./components";
import {
  MAX_CHARACTERS_DESCRIPTIONS,
  MAX_CHARACTERS_TITLE,
} from "./TaskDrawer.constants";

const selector = createSelector(
  currentUserSelector,
  confirmDialogFormChangedSelector,
  (currentUser, formChanged) => ({
    currentUser,
    formChanged,
  })
);

export const TaskDrawer = ({
  taskId = null,
  open,
  onClose,
  data,
  refetchCallback,
  selectedCustomers,
  customer,
  setCheckedCustomers,
  isAdvancedDrawer,
  resetData,
}) => {
  const isAdmin = useAdmin();
  const classes = useStyles();
  const dispatch = useDispatch();
  const errorMsgRef = useRef({});
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    isDeleting: false,
  });

  const { currentUser, formChanged } = useSelector(selector);
  const { timeZone } = currentUser || {};

  const repPermissions = useRepsPermissions();

  const incomingTask = data?.task || data;

  const {
    formField,
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    isDirty: isDirtyFields,
    errors,
    PRIORITIES,
    STATUSES,
    trigger,
    taskLoading,
  } = useFormFields({
    open,
    taskState: incomingTask,
    isAdvancedDrawer,
    isEdit: !!incomingTask || !!taskId,
    taskId,
  });

  const currentFormsList = useMemo(
    () => incomingTask?.assignedForms || formField?.assignedForms,
    [formField?.assignedForms, incomingTask?.assignedForms]
  );

  const incomingFormIds = useMemo(
    () =>
      currentFormsList?.length ? currentFormsList?.map((f) => f?.form?.id) : [],
    [currentFormsList]
  );
  const currentFormIds = formField.formIds;

  const isDirtyFormIds = useMemo(
    () => !isEqual(incomingFormIds, currentFormIds),
    [currentFormIds, incomingFormIds]
  );

  const isDirty = isDirtyFields || isDirtyFormIds;

  const {
    status,
    distributorId,
    representativeId,
    assignedDistributorId,
    assignedRepresentatives,
  } = formField || {};

  const errorsArr = Object.keys(errors);

  const disabledAddBtn = useMemo(() => {
    return repPermissions
      ? !repPermissions?.tasks?.create_edit
      : errorsArr.length > 0 || !isDirty;
  }, [repPermissions, errorsArr, isDirty]);

  const {
    handleSaveTask,
    handleErrorTask,
    handleCloseTask,
    handleDeleteTask,
    loading,
    taskState,
    editTask,
    handleUpdateCompletedTask,
    handleConfirmIncompleteTask,
    repsState,
    handleFetchReps,
    // handleOnDeleteDrawer,
    handleOnCloseDrawer,
    handleConfirmDelete,
    deleteDialogData,
    handleCloseDeleteDialog,
  } = useCustomerTasks({
    isAdvancedDrawer,
    selectedCustomers,
    customer,
    open,
    taskState: incomingTask,
    onClose,
    refetchCallback,
    setCheckedCustomers,
    formField,
    setDialogState,
    resetData,
    formChanged,
    handleSubmit,
    trigger,
    disabledAddBtn,
    isDirty,
    id: taskId,
  });

  const [allCustomersOpen, setAllCustomersOpen] = useState(false);

  const activities = taskState?.activities || formField?.activities;

  const getTitleLabel = useMemo(() => {
    if (editTask) return "Edit";

    return "Create";
  }, [editTask]);

  const disabledCancelBtn = useMemo(() => {
    return loading;
  }, [loading]);

  const disabledDeleteBtn = useMemo(() => {
    return repPermissions ? !repPermissions?.tasks?.delete : loading;
  }, [loading, repPermissions]);

  const setStatusLabelBtn = useMemo(() => {
    if (status === STATUSES[0]) return "Mark Complete";
    if (status === STATUSES[1]) return "Mark Incomplete";
  }, [STATUSES, status]);

  const isValidDate = (str) => {
    if (!str) return false;
    const parsedDate = moment
      .tz(str, "MM/DD/YYYY", timeZone)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .utc()
      .toISOString();

    return isValidNotPastDate(parsedDate, timeZone);
  };

  const {
    customersList,
    checkedTaskCustomers,
    customersLoading,
    customersSearchInput,
    setCustomersList,
    handleCheckCustomer,
    setCheckedTaskCustomers,
    setCustomersSearchInput,
  } = useTaskAssigne({
    isAdvancedDrawer,
    selectedCustomers,
    setCheckedCustomers,
  });

  useEffect(() => {
    if (errors?.selectedCustomersCount && selectedCustomers?.length > 0) {
      clearErrors("selectedCustomersCount");
    }
  }, [clearErrors, errors?.selectedCustomersCount, selectedCustomers?.length]);

  useEffect(() => {
    if (isAdvancedDrawer) {
      setValue("selectedCustomersCount", selectedCustomers?.length);
    }
  }, [isAdvancedDrawer, selectedCustomers, setValue]);

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));

    if (!open) dispatch(setFormChangedAction(false));
  }, [isDirty, dispatch, open]);

  const isMarkBtnDisabled = useMemo(() => {
    if (!repPermissions) return assignedDistributorId !== currentUser?.id;

    const repsIds = assignedRepresentatives?.map(
      (rep) => rep?.representative?.id
    );

    return !repsIds?.includes(repPermissions?.representativeId);
  }, [
    assignedDistributorId,
    assignedRepresentatives,
    currentUser?.id,
    repPermissions,
  ]);

  const editCheckedForms = useMemo(() => {
    return currentFormsList?.map((item) => ({
      id: item?.form?.id,
      title: item?.form?.title,
    }));
  }, [currentFormsList]);

  const handleSetCheckedFormsIds = useCallback(
    (ids) => setValue("formIds", ids),
    [setValue]
  );

  const isNotAllowedToEdit = useMemo(() => {
    if (!data) return false;

    if (!repPermissions)
      return distributorId !== currentUser?.id || !!representativeId;

    return repPermissions?.representativeId !== representativeId;
  }, [currentUser?.id, data, distributorId, repPermissions, representativeId]);

  const handleOnChange = useCallback(
    ({ value, field, name, maxCharacters }) => {
      if (value?.length <= maxCharacters + 1) {
        field.onChange(value);
        trigger([name]);
      }
    },
    [trigger]
  );

  const handleOnPaste = useCallback(
    ({ event, field, name, maxCharacters }) => {
      onPasteTextWithMaxLength({
        event,
        value: field.value,
        onChange: (v) => {
          field.onChange(v);
          trigger([name]);
        },
        maxCharacters,
      });
    },
    [trigger]
  );

  return (
    <>
      {!!deleteDialogData.open && (
        <DeleteDialog
          open={deleteDialogData.open}
          onClose={handleCloseDeleteDialog}
          title={deleteDialogData.title}
          text={deleteDialogData.text}
          isShowIcon={deleteDialogData.isShowIcon}
          onConfirm={handleConfirmDelete}
        />
      )}

      {!!dialogState.isOpen && (
        <ConfirmTaskDialog
          {...dialogState}
          loading={loading}
          handleSaveTask={handleSaveTask}
          formField={formField}
          //isRepeatedTask={isRepeatedTask}
          onClose={handleOnCloseDrawer}
          setValue={setValue}
          setDialogState={setDialogState}
          handleConfirmDelete={handleConfirmDelete}
        />
      )}

      {!!allCustomersOpen && (
        <AllCustomersPopup
          hideActive
          handleAddCustomers={(customers) => {
            setAllCustomersOpen(false);
            setCheckedCustomers(customers);
            setCheckedTaskCustomers(customers);
          }}
          open={allCustomersOpen}
          onClose={() => setAllCustomersOpen(false)}
          // addedStops={formField.customers}
          addedStops={selectedCustomers}
          allowMissingInfo={true}
        />
      )}

      <Drawer open={open} onClose={handleCloseTask} anchor="right">
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Typography>{getTitleLabel} task</Typography>
            {loading && <CircularProgress size={23} />}
            <IconButton onClick={handleCloseTask}>
              <CrossIcon />
            </IconButton>
          </Box>

          {taskLoading ? (
            <Stack
              sx={{
                height: "calc(100vh - 147px)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={36} />
            </Stack>
          ) : (
            <Box
              className={classes.bodyWrapper}
              sx={{
                gap: isAdvancedDrawer ? "18px !important" : "24px !important",
              }}
            >
              <Box display="flex" flexDirection="column" gap="24px">
                {isAdvancedDrawer && (
                  <AdvancedDrawerContent
                    setCheckedCustomers={setCheckedCustomers}
                    customers={formField.customers}
                    isAdvancedDrawer={isAdvancedDrawer}
                    selectedCustomers={
                      // customer?.id ? [customer] : selectedCustomers
                      selectedCustomers
                    }
                    setAllCustomersOpen={setAllCustomersOpen}
                    {...{
                      customersList,
                      checkedTaskCustomers,
                      customersLoading,
                      customersSearchInput,
                      setCustomersList,
                      handleCheckCustomer,
                      setCheckedTaskCustomers,
                      setCustomersSearchInput,
                      errors,
                    }}
                  />
                )}

                <Typography fontSize={18} color="#5F6267">
                  Task Details
                </Typography>

                {/* Title */}
                <Controller
                  render={({ field, fieldState: { error } }) => {
                    if (error?.message)
                      errorMsgRef.current.title = error?.message;

                    return (
                      <StyledTooltip
                        arrow
                        placement="top"
                        title={errorMsgRef.current?.title || ""}
                        open={!!error}
                        PopperProps={cl.title.PopperProps}
                      >
                        <Box component="span">
                          <StyledTextField
                            placeholder="Task Title"
                            label="Task Title"
                            fullWidth
                            error={!!error}
                            disabled={isNotAllowedToEdit}
                            InputProps={cl.title.InputProps}
                            InputLabelProps={cl.title.InputLabelProps}
                            sx={cl.title.sx}
                            {...field}
                            onChange={({ target: { value } }) =>
                              handleOnChange({
                                value,
                                field,
                                name: "title",
                                maxCharacters: MAX_CHARACTERS_TITLE,
                              })
                            }
                            onPaste={(event) =>
                              handleOnPaste({
                                event,
                                field,
                                name: "title",
                                maxCharacters: MAX_CHARACTERS_TITLE,
                              })
                            }
                          />
                        </Box>
                      </StyledTooltip>
                    );
                  }}
                  name="title"
                  control={control}
                />

                {/* Description */}
                <Controller
                  render={({ field, fieldState: { error } }) => {
                    if (error?.message)
                      errorMsgRef.current.description = error?.message;

                    return (
                      <StyledTooltip
                        arrow
                        placement="top"
                        title={errorMsgRef.current?.description || ""}
                        open={!!error}
                        PopperProps={cl.description?.PopperProps}
                      >
                        <Box component="span">
                          <StyledTextField
                            placeholder="Description"
                            label="Description"
                            multiline
                            rows={5}
                            fullWidth
                            error={!!error}
                            disabled={isNotAllowedToEdit}
                            InputProps={cl.description?.InputProps}
                            InputLabelProps={cl.description?.InputLabelProps}
                            sx={cl.description.sx}
                            {...field}
                            onChange={({ target: { value } }) =>
                              handleOnChange({
                                value,
                                field,
                                name: "description",
                                maxCharacters: MAX_CHARACTERS_DESCRIPTIONS,
                              })
                            }
                            onPaste={(event) =>
                              handleOnPaste({
                                event,
                                field,
                                name: "description",
                                maxCharacters: MAX_CHARACTERS_DESCRIPTIONS,
                              })
                            }
                          />
                        </Box>
                      </StyledTooltip>
                    );
                  }}
                  name="description"
                  control={control}
                />

                <Stack flexDirection="row" gap={1.75}>
                  {/* Due date */}
                  <Box width="67%">
                    <DueDateBlock
                      {...{
                        formField,
                        timeZone,
                        setValue,
                        control,
                        isNotAllowedToEdit,
                        isValidDate,
                        setError,
                        clearErrors,
                      }}
                    />
                  </Box>

                  {/* Due time */}
                  <Box width="33%">
                    <Controller
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <StyledSelectTime
                            disabled={
                              isNotAllowedToEdit ||
                              !formField?.dueDate ||
                              isAdmin
                            }
                            label="Due time"
                            fullWidth
                            error={!!error}
                            formSx={cl.dueTime.formSx}
                            sx={cl.dueTime.sx}
                            labelSx={cl.dueTime.labelSx}
                            IconComponent={ClockNewIcon}
                            height="44px"
                            fontSize="14px !important"
                            InputProps={cl.dueTime.InputProps}
                            labelProps={cl.dueTime.labelProps}
                            {...field}
                          />
                        );
                      }}
                      name="dueTime"
                      control={control}
                    />
                  </Box>
                </Stack>

                {/* <Stack direction="row" alignItems="center" gap="9px">
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      disabled={isNotAllowedToEdit}
                      size={16}
                      formSx={{ m: 0 }}
                      sx={{ padding: 0 }}
                      {...field}
                      onChange={(e, newVal) => {
                        setValue("addTime", newVal);

                        if (!newVal) {
                          setValue("repeatInterval", "day");
                          setValue("repeatStep", "1");
                        }
                      }}
                    />
                  )}
                  name="addTime"
                  control={control}
                />
              </Box>
              <Typography variant="header" fontSize="13px">
                Add time
              </Typography>
            </Stack> */}

                {/* Interval */}
                {/* {formField.dueDate && (
              <IntervalContainer
                adminIsAllowed={false}
                control={control}
                formField={formField}
                setValue={setValue}
                isNotAllowedToEdit={isNotAllowedToEdit}
              />
            )} */}

                <Stack flexDirection="row" gap={1.75}>
                  {/* Assignee */}
                  <Box width="67%" maxWidth="calc(67% - 9px)">
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledSelect
                          disabled={
                            repsState?.loading || isNotAllowedToEdit || isAdmin
                          }
                          label="Assignee"
                          fullWidth
                          error={!!error}
                          labelSx={cl.assignee.labelSx}
                          height="44px"
                          fontSize="14px !important"
                          InputProps={cl.assignee.InputProps}
                          labelProps={cl.assignee.labelProps}
                          PaperPropsSx={cl.assignee.paperPropsSx}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            if (errors?.representative)
                              clearErrors("representative");
                          }}
                          dataLength={repsState?.list?.length}
                          dataCount={repsState?.count}
                          handleFetch={handleFetchReps}
                        >
                          {repPermissions === null ? (
                            <MenuItem
                              value="distributor"
                              sx={{ maxWidth: "316px" }}
                            >
                              Admin&nbsp;
                              <Box component="span" fontWeight={300}>
                                (You)
                              </Box>
                            </MenuItem>
                          ) : (
                            <MenuItem
                              value="distributor"
                              sx={{ maxWidth: "316px" }}
                            >{`${currentUser?.name}`}</MenuItem>
                          )}

                          {repsState?.loading &&
                            !!field.value &&
                            field.value !== "distributor" && (
                              <MenuItem
                                value={field.value}
                                sx={{ maxWidth: "316px" }}
                              >
                                Loading
                              </MenuItem>
                            )}

                          {repsState?.list?.length && !repsState?.loading
                            ? repsState.list.map((rep) => (
                                <MenuItem
                                  key={rep?.id}
                                  sx={{ maxWidth: "316px" }}
                                  value={rep?.id}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 400,
                                      color: "#3F3F3F",
                                    }}
                                    noWrap
                                  >
                                    {rep?.name}{" "}
                                    {rep?.id ===
                                      repPermissions?.representativeId && (
                                      <Box component="span" fontWeight={300}>
                                        (You)
                                      </Box>
                                    )}
                                  </Typography>
                                </MenuItem>
                              ))
                            : null}
                        </StyledSelect>
                      )}
                      name="representative"
                      control={control}
                    />
                  </Box>

                  {/* Priority */}
                  <Box width="33%">
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledSelect
                          label="Priority"
                          fullWidth
                          disabled={isNotAllowedToEdit || isAdmin}
                          error={!!error}
                          height="44px"
                          fontSize="14px !important"
                          labelSx={cl.priority.labelSx}
                          InputProps={cl.priority.InputProps}
                          PaperPropsSx={cl.priority.paperPropsSx}
                          {...field}
                        >
                          {PRIORITIES.map((p) => (
                            <MenuItem key={p} value={p}>
                              {getPriority({
                                type: p,
                                sx: cl.priority.MenuItemSx,
                              })}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      )}
                      name="priority"
                      control={control}
                    />
                  </Box>
                </Stack>

                {/* Add Forms */}
                <AddFormsComponent
                  isAdmin={isAdmin}
                  isNotAllowedToEdit={isNotAllowedToEdit}
                  editCheckedForms={editCheckedForms}
                  handleSetCheckedFormsIds={handleSetCheckedFormsIds}
                />
              </Box>

              <Box display="flex" flexDirection="column" gap="24px">
                {/* activities */}
                {editTask ? (
                  <ActivityBlock
                    {...{ activities }}
                    titleProps={{ fontSize: 18, color: "#5F6267" }}
                    containerGridProps={{ height: "70px" }}
                    leftGridProps={{ xs: 1, mr: 2 }}
                    rightGridProps={{
                      sx: {
                        border: "1px solid #47A06D50",
                        borderRadius: "4px",
                        alignItems: "center",
                        height: "56px",
                      },
                    }}
                    expandCircleDownProps={{
                      marginTop: "-15px",
                      marginRight: "-15px",
                      fontSize: 30,
                    }}
                  />
                ) : null}
              </Box>
            </Box>
          )}

          {/* Action buttons */}
          <Box className={classes.footer}>
            {editTask && (
              <ActionRestrictionWrapper
                disableHoverListener={!disabledDeleteBtn}
              >
                <StyledButton
                  disabled={disabledDeleteBtn || isNotAllowedToEdit || isAdmin}
                  label="Delete"
                  color="error"
                  sx={{ bgcolor: "#FDEBEA80" }}
                  variant="outlined"
                  onClick={() => {
                    // if (isRepeatedTask) return handleOnDeleteDrawer();
                    return handleDeleteTask();
                  }}
                />
              </ActionRestrictionWrapper>
            )}

            {editTask && (
              <StyledButton
                sx={{
                  ml: "11px",
                  width:
                    setStatusLabelBtn === "Mark Complete" ? "153px" : "135px",
                  p: 0,
                }}
                disabled={
                  isMarkBtnDisabled ||
                  (repPermissions && !repPermissions?.tasks?.create_edit) ||
                  isAdmin
                }
                label={setStatusLabelBtn}
                variant="outlined"
                color="cancel"
                startIcon={
                  setStatusLabelBtn === "Mark Complete" ? (
                    <CheckmarkIcon width="11.18" height="8" stroke="#A0A0A0" />
                  ) : null
                }
                onClick={() => {
                  if (status === STATUSES[0]) {
                    setValue("status", STATUSES[1]);
                    handleUpdateCompletedTask(STATUSES[1]);
                    handleCloseTask();
                  }

                  if (status === STATUSES[1]) {
                    handleConfirmIncompleteTask({ setValue });
                  }
                }}
              />
            )}

            <Box display="flex" gap="12px" ml="auto">
              <StyledButton
                disabled={disabledCancelBtn}
                label="Cancel"
                color="cancel"
                variant="outlined"
                sx={{ width: "80px" }}
                height="32px"
                onClick={handleCloseTask}
              />
              <StyledButton
                disabled={
                  disabledAddBtn || loading || isAdmin || isNotAllowedToEdit
                }
                sx={{
                  width: "80px",
                  "&.Mui-disabled": {
                    color: "#FFF",
                    bgcolor: ({ palette }) =>
                      `${palette.primary.main}50 !important`,
                  },
                }}
                label={editTask ? "Save" : "Add"}
                variant="contained"
                onClick={() => handleSubmit(handleSaveTask, handleErrorTask)()}
              />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

TaskDrawer.propTypes = {
  open: bool,
  onClose: func,
  viewOnly: bool,
  refetchCallback: func,
  data: shape({
    title: string,
    status: string,
    representativeId: string,
    priority: string,
    id: string,
    dueDate: string,
    distributorId: string,
    description: string,
    customerId: string,
    createdAt: string,
    assignedRepresentatives: array,
    assignedDistributorId: string,
    activityId: string,
    assignedDistributor: shape({
      name: string,
      id: string,
      profileProho: string,
    }),
    activities: array,
  }),
  selectedCustomers: array,
  customer: object,
  setCheckedCustomers: func,
  isAdvancedDrawer: bool,
  resetData: bool,
  taskId: string,
};

TaskDrawer.defaultProps = {
  open: false,
  onClose: () => {},
  viewOnly: false,
  refetchCallback: () => {},
  data: null,
  //data: {
  //  title: "",
  //  status: "",
  //  representativeId: "",
  //  priority: "",
  //  id: "",
  //  dueDate: "",
  //  distributorId: "",
  //  description: "",
  //  customerId: "",
  //  createdAt: "",
  //  assignedRepresentatives: [],
  //  assignedDistributorId: "",
  //  activityId: null,
  //  resetData: true,
  //  assignedDistributor: shape({
  //    name: "",
  //    id: "",
  //    profileProho: null,
  //  }),
  //  activities: [],
  //},
  selectedCustomers: [],
  setCheckedCustomers: () => {},
  isAdvancedDrawer: false,
};
