import { useState } from "react";
import { array, bool, func, object } from "prop-types";

import { CategoryItem } from "./CategoryItem";

import { cl } from "components/TextFields/TextFields.styles";

import { Box, Collapse, Paper } from "@mui/material";
import { ActionRestrictionWrapper } from "components";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

const PAPER_HEIGHT = 235;

export const CategoriesList = ({
  list,
  setOpen,
  setSearchInput,
  setValue,
  onOpenCategoryDrawer,
  disabledCreateEditCategories,
  repPermissions,
}) => {
  const [expandParentId, setExpandParentId] = useState(null);

  const handleExpandChildrenList = (id) => {
    if (id === expandParentId) {
      setExpandParentId(null);
    } else {
      setExpandParentId(id);
    }
  };

  return (
    <Paper
      sx={{
        ...cl.styledAutocomplete.paperWrapper,
        maxHeight: `${PAPER_HEIGHT}px`,
      }}
    >
      <ActionRestrictionWrapper
        disableHoverListener={!disabledCreateEditCategories}
        title={
          repPermissions?.role === "SALES"
            ? ADMIN_ONLY_VIEW_MESSAGE
            : ADMIN_ONLY_VIEW_MESSAGE_PAGE
        }
      >
        <CategoryItem
          disabled={disabledCreateEditCategories}
          isAddCategory
          onClick={() => {
            setOpen(false);
            onOpenCategoryDrawer();
          }}
        />
      </ActionRestrictionWrapper>
      {list?.map((category) => (
        <Box key={category.id}>
          <CategoryItem
            key={category.id}
            category={category}
            isExpanded={expandParentId === category.id}
            onExpand={() => handleExpandChildrenList(category.id)}
            onClick={() => {
              setSearchInput("");
              setOpen(false);
              setValue("category", category, { shouldDirty: true });
            }}
          />
          <Collapse in={expandParentId === category.id} timeout={300}>
            {category?.childCategories?.map((child) => (
              <CategoryItem
                key={child.id}
                category={child}
                isChild
                onClick={() => {
                  setSearchInput("");
                  setOpen(false);
                  setValue("category", child, { shouldDirty: true });
                }}
              />
            ))}
          </Collapse>
        </Box>
      ))}
    </Paper>
  );
};

CategoriesList.propTypes = {
  list: array,
  setOpen: func,
  setSearchInput: func,
  setValue: func,
  onOpenCategoryDrawer: func,
  disabledCreateEditCategories: bool,
  repPermissions: object,
};
