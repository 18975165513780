import { useCallback, useMemo, useState } from "react";
import { array, bool, func, number, object, string } from "prop-types";
import { Link as NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Link,
  ListItemText,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import {
  ActiveIcon,
  CrossIcon,
  InformationIcon,
  StyledProductIco,
  TriangleDownIcon,
  TriangleRightIcon,
} from "components/Icons";
import { cl } from "./ProductList.styles";

import {
  ActionRestrictionWrapper,
  LargeCheckbox,
  StyledMenu,
  StyledTooltip,
  ThreeDotButton,
} from "components";

import {
  containsEvery,
  getFormattedDate,
  getRangeWholeSalePrice,
  photoUrl,
} from "helpers/helpers";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
  PRODUCT_TYPE_INVENTORY,
} from "utils/constants";

const ProductListItem = ({
  isVisible,
  product,
  parent,
  lastChild,
  hasChildren,
  hasChildrenInActiveForCustomers,
  isChild,
  onExpandChildrenList,
  chosenParentId,
  selectedProducts,
  selectedChildrenOfProducts,
  handleCheckProduct,
  timeZone,
  currentTab,
  lastItemIdWithoutBottomLine,
  repPermissions,
  getActionsList,
  handleClickOnEditProduct,
}) => {
  const navigate = useNavigate();

  const ACTIONS_LIST = getActionsList(product, isChild, true);

  const isNonInventory = product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

  const [anchorEl, setAnchorEl] = useState(null);
  const openHeaderList = Boolean(anchorEl);
  const { pathname } = useLocation();

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.catalog?.create_edit;
    }
  }, [repPermissions]);

  const calculatePosition = useMemo(() => {
    if (chosenParentId === product.id) return "12px";
    if (lastChild) return "-16px";
  }, [chosenParentId, lastChild, product.id]);

  const setProductStatus = useMemo(() => {
    if (product.status !== "INACTIVE") return <ActiveIcon />;
    return <CrossIcon fill="#FF0000" />;
  }, [product.status]);

  const isChecked = useMemo(
    () =>
      isChild
        ? selectedChildrenOfProducts
            .map((prod) => prod[1])
            .flat()
            .includes(product.id)
        : product.isMultiple
        ? containsEvery(
            product.childProducts,
            selectedChildrenOfProducts.map((prod) => prod[1]).flat()
          )
        : selectedProducts.findIndex(
            (checkedProduct) => checkedProduct.id === product.id
          ) > -1,
    [
      isChild,
      product.childProducts,
      product.id,
      product.isMultiple,
      selectedChildrenOfProducts,
      selectedProducts,
    ]
  );

  const isMissingInfo = useMemo(
    () => product?.missingFields,
    [product?.missingFields]
  );

  const handleRedirectMissingInfo = () => {
    if (!isMissingInfo?.length) return;

    if (isMissingInfo?.length) {
      const state = { checkMissingInfo: true };
      return navigate(`/catalog/${product?.id}`, {
        state,
      });
    }
  };

  const setIndeterminate = (parentItem, isCheckedParent, checkedItems) => {
    if (isCheckedParent) return false;
    const childProducts = parentItem?.childProducts ?? [];
    if (!childProducts?.length) return false;

    return checkedItems.some((item) => {
      return item?.[0]?.id === parentItem?.id;
    });
  };

  const indeterminateParent = setIndeterminate(
    product,
    isChecked,
    selectedChildrenOfProducts
  );

  const handleClickOnDotsBtn = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleCloseLogoClient = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const setBackgroundColor = useMemo(() => {
    if (isChecked) return "#ECF5F0";
    if (chosenParentId === product.id || isChild)
      return "rgba(71, 160, 109, 0.1)";
  }, [chosenParentId, isChecked, isChild, product.id]);

  const setChildrenName = useMemo(() => {
    if (!product.sku) return "-";
    return `${product.sku} ${product.size ? `/ ${product.size}` : ""} ${
      product.color ? `/ ${product.color}` : ""
    }`;
  }, [product.color, product.size, product.sku]);

  const setCategoryName = useMemo(() => {
    if (isChild) {
      return (
        parent?.productCategory?.name || parent?.category?.name || "-"
        // || "Uncategorized"
      );
    }

    if (!isChild) {
      return (
        product?.productCategory?.name || product?.category?.name || "-"
        // || "Uncategorized"
      );
    }
  }, [
    isChild,
    parent?.category?.name,
    parent?.productCategory?.name,
    product?.category?.name,
    product?.productCategory?.name,
  ]);

  const isInActiveForCustomer = useMemo(() => {
    return (
      product?.status === "INACTIVE_FOR_CUSTOMERS" ||
      hasChildrenInActiveForCustomers
    );
  }, [hasChildrenInActiveForCustomers, product?.status]);

  return (
    <Paper
      sx={{
        ...cl.paper,
        ...cl.menu,
        overflow: "hidden",
        display: isVisible ? "flex" : "none",
        backgroundColor: setBackgroundColor,
        borderBottom:
          lastItemIdWithoutBottomLine === product?.id
            ? "none"
            : "1px solid #D5D9D9",
        opacity: isInActiveForCustomer ? 0.5 : 1,
      }}
      elevation={0}
      square
      data-testid={isChild ? "product-list-item-child" : "product-list-item"}
    >
      {(chosenParentId === product.id || isChild) && (
        <Box sx={cl.line} top={calculatePosition} />
      )}

      {/* checkbox */}
      <Box
        sx={cl.boxWrapper}
        justifyContent="center"
        ml="20px"
        data-testid="product-checkbox"
      >
        <LargeCheckbox
          sx={{
            p: 0,
            "&.MuiCheckbox-indeterminate": { mx: "-4px" },
            "&.MuiCheckbox-indeterminate svg": { fontSize: 30 },
          }}
          formSx={{ marginX: 0 }}
          size={22}
          checked={isChecked}
          indeterminate={indeterminateParent}
          adminIsAllowed
          onChange={(e) =>
            handleCheckProduct(product, parent, e.target.checked)
          }
        />
      </Box>

      {/* status */}
      <Box
        sx={{ ...cl.boxWrapper, justifyContent: "center" }}
        width="4.8%"
        minWidth="4.8%"
        data-testid="product-status"
      >
        {isMissingInfo?.length ? (
          <StyledTooltip
            arrow
            title={
              <Box textAlign="left">
                <Typography fontSize={12}>Missing info:</Typography>
                {isMissingInfo?.map((info) => (
                  <Typography key={info} fontSize={12} fontWeight={300}>
                    {info}
                  </Typography>
                ))}
              </Box>
            }
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -6] },
                },
              ],
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={handleRedirectMissingInfo}
            >
              <InformationIcon size="16" stroke="#FF6969" />
            </Box>
          </StyledTooltip>
        ) : isInActiveForCustomer ? (
          <Box>
            <StyledTooltip
              isDark
              arrow
              title={
                <Box textAlign="left">
                  <Typography fontSize={12} color="#FFF">
                    Inactive for customers
                  </Typography>
                </Box>
              }
              placement="top"
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [0, -8] } }],
              }}
            >
              <Box display="flex" alignItems="center">
                <InformationIcon size="16" stroke="#FF6969" />
              </Box>
            </StyledTooltip>
          </Box>
        ) : (
          <Box sx={cl.gridCenter}>{setProductStatus}</Box>
        )}
      </Box>

      {/* Product Name */}
      <Box
        sx={cl.boxWrapper}
        justifyContent="center"
        width="21.4%"
        minWidth="21.4%"
        data-testid="product-name"
      >
        {/* logo */}
        <Box sx={cl.productPhotoWrapper}>
          <Box sx={cl.productPhoto}>
            <StyledProductIco
              src={
                product?.photos?.length
                  ? photoUrl(product.photos[0].fileName)
                  : ""
              }
              styles={cl.emptyProduct}
            />
          </Box>
        </Box>

        {/* name */}
        <Box sx={cl.nameWrapper}>
          <Box display="flex" alignItems="center" maxWidth="90%">
            <Typography
              sx={cl.productName}
              component={disabledCreateEdit ? "span" : NavLink}
              to={`/catalog/${isChild ? parent?.id : product.id}`}
              state={{ product, isChild, currentTab }}
              from={pathname}
              onClick={(e) => {
                if (disabledCreateEdit) return;

                if (e.metaKey || e.ctrlKey) return;
                e.stopPropagation();
                e.preventDefault();
                setAnchorEl(null);
                handleClickOnEditProduct(
                  e,
                  isChild ? parent : product,
                  isChild ? product?.id : false
                );
              }}
              noWrap
            >
              {isChild ? parent?.name ?? "-" : product?.name ?? "-"}
            </Typography>
          </Box>
          {!hasChildren && (
            <Typography sx={cl.productNameVariation} noWrap>
              {setChildrenName}
            </Typography>
          )}
          {!!hasChildren && (
            <Box width="100%">
              <Link
                sx={cl.link}
                component="button"
                underline="hover"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onExpandChildrenList(product?.id);
                }}
              >
                <Box width="6px">
                  {chosenParentId === product.id ? (
                    <TriangleDownIcon />
                  ) : (
                    <TriangleRightIcon />
                  )}
                </Box>
                <Box mr="4px" component="span" />
                {hasChildren} Variations
              </Link>
            </Box>
          )}
        </Box>
      </Box>

      {/* Variations */}
      {/* <Box sx={cl.boxWrapper} width="9.4%">
        <Typography sx={cl.text} noWrap>
          {isChild
            ? setChildrenName
            : product?.sku || (!!hasChildren && "Parent")}
        </Typography>
      </Box> */}

      {/* Manufacturer */}
      <Box
        sx={cl.boxWrapper}
        width="11.2%"
        minWidth="11.2%"
        data-testid="product-manufacturer"
      >
        <Typography sx={cl.manufacturerName} noWrap>
          {isChild ? parent?.manufacturer?.name : product?.manufacturer?.name}
        </Typography>
      </Box>

      {/* Category */}
      <Box
        sx={cl.boxWrapper}
        width="11%"
        minWidth="11%"
        data-testid="product-category"
      >
        <Typography sx={cl.text} noWrap>
          {setCategoryName}
        </Typography>
      </Box>

      {/* Date Created */}
      <Box
        sx={{
          ...cl.boxWrapper,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
        justifyContent="center"
        width="10.6%"
        minWidth="10.6%"
        data-testid="product-created-date"
      >
        {getFormattedDate(product?.createdAt, timeZone)
          .split(", ")
          ?.map((el) => (
            <Typography key={el} sx={cl.text} ml={1}>
              {el}
            </Typography>
          ))}
      </Box>

      {/* Wholesale Price */}
      <Box
        sx={cl.boxWrapper}
        width="10.3%"
        minWidth="10.3%"
        data-testid="product-wholesale-price"
      >
        <Typography sx={cl.text}>
          {product?.wholesalePrice
            ? `$${product?.wholesalePrice.toFixed(2)}`
            : !isChild &&
              hasChildren &&
              getRangeWholeSalePrice(product?.childProducts)}
        </Typography>
      </Box>

      {/* On Hand */}
      <Box
        sx={cl.boxWrapper}
        justifyContent="center"
        width="7.7%"
        minWidth="7.7%"
        data-testid="product-on-hand"
      >
        <Typography sx={cl.inventoryText}>
          {isNonInventory ? "-" : product?.inventory?.onHand}
        </Typography>
      </Box>

      {/* Allocated */}
      <Box
        sx={cl.boxWrapper}
        justifyContent="center"
        width="7.7%"
        minWidth="7.7%"
        data-testid="product-allocated"
      >
        <Typography sx={cl.text}>
          {isNonInventory ? "-" : product?.inventory?.allocated}
        </Typography>
      </Box>

      {/* Available */}
      <Box
        sx={cl.boxWrapper}
        justifyContent="center"
        width="7.7%"
        minWidth="7.7%"
        data-testid="product-avaiable"
      >
        <Typography sx={cl.availableText}>
          {isNonInventory
            ? "-"
            : product?.inventory?.onHand - product?.inventory?.allocated}
        </Typography>
      </Box>

      {/* menu */}
      <Box
        sx={cl.boxWrapper}
        justifyContent="flex-end"
        width="100%"
        data-testid="product-menu"
      >
        {!isChild && (
          <ActionRestrictionWrapper
            disableHoverListener={!disabledCreateEdit}
            title={
              repPermissions?.role === "SALES"
                ? ADMIN_ONLY_VIEW_MESSAGE
                : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            }
          >
            <ThreeDotButton
              paddingRight="14px"
              disabled={disabledCreateEdit}
              onClick={handleClickOnDotsBtn}
            />
          </ActionRestrictionWrapper>
        )}

        <StyledMenu
          sx={{ paddingY: 0 }}
          anchorEl={anchorEl}
          isOpen={openHeaderList}
          handleClose={handleCloseLogoClient}
        >
          {ACTIONS_LIST.map(
            ({ sx, label, disabled, onClick, show, icon }, index) => (
              <Box
                key={index}
                sx={{
                  "& .MuiDivider-root": {
                    height: "0.5px",
                    mt: "6px",
                    mb: "6px",
                  },
                }}
              >
                {show && (
                  <MenuItem
                    sx={sx}
                    disabled={disabled}
                    onClick={(e) => {
                      onClick(e);
                      setAnchorEl(null);
                      handleCloseLogoClient(e);
                    }}
                  >
                    {icon}
                    <ListItemText>{label}</ListItemText>
                  </MenuItem>
                )}
              </Box>
            )
          )}
        </StyledMenu>
      </Box>
    </Paper>
  );
};
ProductListItem.propTypes = {
  isVisible: bool,
  product: object,
  parent: object,
  lastChild: bool,
  hasChildren: number,
  hasChildrenInActiveForCustomers: bool,
  isChild: bool,
  onExpandChildrenList: func,
  chosenParentId: string,
  checkedProducts: array,
  selectedProducts: array,
  selectedChildrenOfProducts: array,
  handleCheckProduct: func,
  handleOpenConfirmDialog: func,
  timeZone: string,
  getActionsList: func,
  handleClickOnEditProduct: func,
  currentTab: string,
  lastItemIdWithoutBottomLine: string,
  repPermissions: object,
};

ProductListItem.defaultProps = {
  isVisible: true,
  lastItem: false,
  hasChildren: 0,
  hasChildrenInActiveForCustomers: false,
  checkedProducts: [],
  selectedProducts: [],
  selectedChildrenOfProducts: [],
  handleClickOnEditProduct: () => {},
  getActionsList: () => {},
  isChild: false,
  lastChild: false,
  lastItemIdWithoutBottomLine: "",
};

export default ProductListItem;
