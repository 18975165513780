import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { STATUSES_TASKS, TABS_PAGE } from "../TasksPage.constants";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { currentUserSelector } from "redux/selectors/auth";
import { useRepsPermissions } from "helpers/hooks";
import { tasksFilterAction } from "redux/actions/tasks";
import {
  bulkMarkAsCompletedService,
  bulkSetDueDateService,
  bulkTasksAssignRepresentativesService,
  deleteTaskService,
  duplicateTaskService,
} from "services/tasks";
import { handleError } from "helpers/helpers";
import { error, success } from "utils/notifications";
import { uniqBy } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import pluralize from "pluralize";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const useTasksPage = ({ tasksState }) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector(selector);
  const navigate = useNavigate();
  const repPermissions = useRepsPermissions();

  const {
    tasksList,
    tasksFilter,
    checkedTasks,
    setCheckedTasks,
    allTasksChecked,
    setAllTasksChecked,
    setTasksState,
  } = tasksState || {};

  const { state } = useLocation();

  const [currentTab, setCurrentTab] = useState(TABS_PAGE[0].value);

  useEffect(() => {
    if (state?.tab === "Forms") {
      setCurrentTab(
        TABS_PAGE.find((tab) => tab.label === state.tab)?.value || 0
      );
    }
  }, [state?.tab]);

  const handleChangeCurrentTab = (_, tab) => {
    setCurrentTab(tab);
  };

  const handleCheckTask = (task) => {
    const taskIndex = checkedTasks.findIndex(
      (checkedTask) => checkedTask?.id === task?.id
    );
    if (taskIndex > -1) {
      const newTasks = [...checkedTasks];
      newTasks.splice(taskIndex, 1);
      return setCheckedTasks([...newTasks]);
    }

    setCheckedTasks([...checkedTasks, task]);
  };

  const handleUncheckAllTasks = useCallback(() => {
    setAllTasksChecked(false);
    setCheckedTasks([]);
  }, [setAllTasksChecked, setCheckedTasks]);

  const checkAllTasks = useCallback(() => {
    dispatch(
      tasksFilterAction({ ...tasksFilter, page: undefined, limit: undefined })
    );
  }, [dispatch, tasksFilter]);

  const handleSelectAvailableTasks = useCallback(() => {
    if (tasksList?.length === checkedTasks?.length) {
      handleUncheckAllTasks();
      return;
    }

    setCheckedTasks(tasksList);
  }, [checkedTasks?.length, handleUncheckAllTasks, setCheckedTasks, tasksList]);

  const handleMarkAsCompleted = useCallback(
    async ({ status, taskId = null, onSuccess = null, onError = null }) => {
      if (!taskId) return error("Tasks must be assigned to you to complete");

      const setStatus = status
        ? status === STATUSES_TASKS.PENDING
          ? STATUSES_TASKS.COMPLETED
          : STATUSES_TASKS.PENDING
        : STATUSES_TASKS.COMPLETED;

      try {
        await bulkMarkAsCompletedService({
          tasksIds: [taskId],
          status: setStatus,
        });

        const foundTaskIndex = tasksList.findIndex(
          (task) => task?.id === taskId
        );

        if (foundTaskIndex > -1) {
          const newTasksList = [...tasksList];
          newTasksList.splice(foundTaskIndex, 1, {
            ...tasksList[foundTaskIndex],
            status: setStatus,
          });
          setTasksState((prev) => ({
            ...prev,
            tasksList: newTasksList,
          }));
        }

        !!onSuccess && onSuccess();
      } catch (error) {
        handleError(error);
        !!onError && onError();
      }
    },
    [setTasksState, tasksList]
  );

  const handleConfirmCompleteTaskPopper = useCallback(
    async ({ taskId, dueDate, dueTime }) => {
      if (!taskId) return error("Task id is required");

      try {
        setTasksState((prev) => ({ ...prev, loadingTasksList: true }));

        await duplicateTaskService({
          taskId,
          ...(dueDate && { dueDate }),
          ...(dueTime && { dueTime }),
        });

        dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
        setCheckedTasks([]);
        success("Task created");
      } catch (error) {
        handleError(error);
      } finally {
        setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
      }
    },
    [dispatch, setCheckedTasks, setTasksState, tasksFilter]
  );

  const handleBulkMarkAsCompleted = useCallback(
    async ({ currentUserId = "" }) => {
      const pendingTasksIds = checkedTasks
        .filter((checkedTask) => checkedTask?.status === STATUSES_TASKS.PENDING)
        .filter((checkedTask) => {
          if (!currentUserId) return true;
          return [
            checkedTask?.assignedDistributorId,
            checkedTask?.assignedDistributor?.id,
          ].includes(currentUserId);
        })
        .map((task) => task?.id);

      if (!pendingTasksIds.length)
        return error("Tasks must be assigned to you to complete");

      try {
        setTasksState((prev) => ({ ...prev, loadingTasksList: true }));

        await bulkMarkAsCompletedService({
          tasksIds: pendingTasksIds,
          status: STATUSES_TASKS.COMPLETED,
        });

        dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
        setCheckedTasks([]);
        success("Tasks marked as completed");
      } catch (error) {
        handleError(error);
      } finally {
        setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
      }
    },
    [checkedTasks, dispatch, setCheckedTasks, setTasksState, tasksFilter]
  );

  const handleBulkMarkAsIncomplete = useCallback(
    async ({ currentUserId = "" }) => {
      const completedTasksIds = checkedTasks
        .filter(
          (checkedTask) => checkedTask?.status === STATUSES_TASKS.COMPLETED
        )
        .filter((checkedTask) => {
          if (!currentUserId) return true;

          const assignedDistributorId =
            checkedTask?.assignedDistributorId ||
            checkedTask?.assignedDistributor?.id;
          return assignedDistributorId === currentUserId;
        })
        .map((task) => task?.id);

      if (!completedTasksIds?.length)
        return error("Tasks must be assigned to you to incomplete");

      try {
        setTasksState((prev) => ({ ...prev, loadingTasksList: true }));

        await bulkMarkAsCompletedService({
          tasksIds: completedTasksIds,
          status: STATUSES_TASKS.PENDING,
        });

        dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
        setCheckedTasks([]);
        success("Tasks marked as incomplete");
      } catch (error) {
        handleError(error);
      } finally {
        setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
      }
    },
    [checkedTasks, dispatch, setCheckedTasks, setTasksState, tasksFilter]
  );

  const handleCreateRoute = useCallback(() => {
    const checkedTasksIds = checkedTasks.map((task) => task?.id);

    if (!checkedTasksIds.length) return error("Nothing to create route");

    if (repPermissions && !repPermissions?.routes?.create_edit) return;

    if (checkedTasksIds?.length > tasksList?.length) {
      const checkedCustomers = uniqBy(
        checkedTasks.map(({ customer }) => customer),
        ({ id }) => id
      );
      navigate("/routes/new", { state: { checkedCustomers } });
      return;
    }

    const orderObjects = checkedTasksIds.map((orderId) =>
      tasksList.find(({ id }) => id === orderId)
    );

    const checkedCustomers = uniqBy(
      orderObjects.map(({ customer }) => customer),
      ({ id }) => id
    );

    navigate("/routes/new", { state: { checkedCustomers } });
  }, [checkedTasks, navigate, repPermissions, tasksList]);

  const handleBulkAssignRepresentatives = useCallback(
    async ({ repId, isDistributor }) => {
      const tasksIds = checkedTasks.map((task) => task?.id);

      if (!tasksIds.length || !repId) return error("Nothing to assign rep");

      try {
        setTasksState((prev) => ({ ...prev, loadingTasksList: true }));
        const preparedData = {
          tasksIds,
          assignToDistributor: !!isDistributor,
        };

        if (isDistributor) preparedData.representativesIds = [];
        if (!isDistributor) preparedData.representativesIds = [repId];

        await bulkTasksAssignRepresentativesService(preparedData);

        dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
        setCheckedTasks([]);
        success("Assigned rep updated");
      } catch (error) {
        handleError(error);
      } finally {
        setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
      }
    },
    [checkedTasks, dispatch, setCheckedTasks, setTasksState, tasksFilter]
  );

  const handleSaveDueDate = useCallback(
    async (data) => {
      try {
        setTasksState((prev) => ({ ...prev, loadingTasksList: true }));

        await bulkSetDueDateService(data);

        dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
        setCheckedTasks([]);
        success("Due date updated");
      } catch (error) {
        handleError(error);
      } finally {
        setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
      }
    },
    [dispatch, setCheckedTasks, setTasksState, tasksFilter]
  );

  const [checkedCustomers, setCheckedCustomers] = useState([]);

  const [taskDrawerState, setTaskDrawerState] = useState({
    open: false,
    data: null,
  });

  const handleCreateTask = (data) => {
    if (repPermissions && !repPermissions?.tasks?.create_edit) return;

    setCheckedCustomers([]);
    handleCloseCompleteTaskPopper();
    setTaskDrawerState((prev) => ({
      ...prev,
      open: true,
      data: data || null,
    }));
  };

  const handleCloseTaskDrawer = () => {
    setAllTasksChecked(false);
    setCheckedTasks([]);

    setCheckedCustomers([]);
    setTaskDrawerState({
      open: false,
      data: null,
    });
  };

  const handleFetchList = () =>
    dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));

  const INIT_DELETE_DIALOG_DATA = useMemo(
    () => ({
      open: false,
      title: "",
      text: "",
      isShowIcon: false,
    }),
    []
  );
  const [deleteDialogData, setDeleteDialogData] = useState(
    INIT_DELETE_DIALOG_DATA
  );

  const handleOpenDeleteDialog = () => {
    setDeleteDialogData((prev) => ({
      ...prev,
      open: true,
      title: "Delete tasks",
      // text: "Tasks will be deleted. This action can’t be undone.",
      text: (
        <>
          Are you sure you want to delete this task(s)?
          <br />
          This action can’t be undone.
        </>
      ),
      isShowIcon: true,
    }));
  };
  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteDialogData(INIT_DELETE_DIALOG_DATA);
  }, [INIT_DELETE_DIALOG_DATA]);
  const handleDeleteTask = () => handleOpenDeleteDialog();

  const handleConfirmDelete = useCallback(
    async ({ notifyAssignee }) => {
      try {
        const checkedTasksIds = checkedTasks.map((task) => task?.id);

        if (!checkedTasksIds.length) return error("Nothing to create route");

        if (repPermissions && !repPermissions?.routes?.create_edit) return;

        setTasksState((prev) => ({ ...prev, loadingTasksList: true }));
        await deleteTaskService({
          tasksIds: checkedTasksIds,
          notifyAssignee,
          // ...(editTask &&
          // isRepeatedTask && {
          //   applyForFutureTasks: data?.applyForFutureTasks || false,
          // }),
        });

        handleCloseDeleteDialog();
        setCheckedTasks([]);

        success(
          `${checkedTasksIds.length} ${pluralize(
            "Task",
            checkedTasksIds.length
          )} deleted`
        );
        dispatch(tasksFilterAction({ ...tasksFilter, page: 1 }));
      } catch (err) {
        handleError(error);
      } finally {
        setTasksState((prev) => ({ ...prev, loadingTasksList: false }));
      }
    },
    [
      checkedTasks,
      dispatch,
      handleCloseDeleteDialog,
      repPermissions,
      setCheckedTasks,
      setTasksState,
      tasksFilter,
    ]
  );

  useEffect(() => {
    if (tasksList?.length && tasksList?.length === checkedTasks?.length) {
      setAllTasksChecked(true);
    } else {
      setAllTasksChecked(false);
    }
  }, [checkedTasks?.length, setAllTasksChecked, tasksList?.length]);

  const [loadingCompletion, setLoadingCompletion] = useState({});

  const [completionTask, setCompletionTask] = useState(null);

  const completeTaskDialogAnchorRef = useRef(null);

  const assignedTo = useMemo(() => {
    let name = "";

    if (completionTask?.assignedRepresentatives?.length) {
      name = completionTask?.assignedRepresentatives?.[0]?.representative?.name;
    }
    if (completionTask?.assignedDistributor?.name) {
      name = completionTask.assignedDistributor.name;
    }

    return name;
  }, [
    completionTask?.assignedDistributor?.name,
    completionTask?.assignedRepresentatives,
  ]);

  const handleCloseCompleteTaskPopper = useCallback(() => {
    setCompletionTask(null);
    completeTaskDialogAnchorRef.current = null;
  }, []);

  const checkNotAllowedToEdit = useCallback(
    (task) => {
      const assignedDistributorId =
        task?.assignedDistributorId || task?.assignedDistributor?.id;
      const assignedRepresentatives = task?.assignedRepresentatives;

      if (!repPermissions) return assignedDistributorId !== currentUser?.id;

      const repsIds = assignedRepresentatives?.map(
        (rep) => rep?.representative?.id
      );

      return !repsIds?.includes(repPermissions?.representativeId);
    },
    [currentUser?.id, repPermissions]
  );

  const handleClickCompletion = useCallback(
    async ({ event, task, status }) => {
      setLoadingCompletion((prev) => ({
        ...prev,
        [task.id]: !loadingCompletion[task?.id],
      }));

      if (status === "PENDING") {
        completeTaskDialogAnchorRef.current = null;
        completeTaskDialogAnchorRef.current = event.currentTarget;
        setCompletionTask(task);
      }

      handleMarkAsCompleted({
        status,
        taskId: task.id,
        onSuccess: () => {
          setLoadingCompletion((prev) => ({
            ...prev,
            [task.id]: false,
          }));
        },
        onError: () => {
          setLoadingCompletion((prev) => ({
            ...prev,
            [task.id]: false,
          }));
        },
      });
    },
    [handleMarkAsCompleted, loadingCompletion]
  );

  return {
    TABS_PAGE,
    currentTab,
    handleChangeCurrentTab,
    currentUser,
    checkedTasks,
    handleCheckTask,
    allTasksChecked,
    setAllTasksChecked,
    checkAllTasks,
    handleSelectAvailableTasks,
    repPermissions,
    handleMarkAsCompleted,
    handleConfirmCompleteTaskPopper,
    handleBulkMarkAsCompleted,
    handleBulkMarkAsIncomplete,
    handleBulkAssignRepresentatives,
    handleCreateRoute,
    handleSaveDueDate,
    taskDrawerState,
    setTaskDrawerState,
    handleCreateTask,
    handleCloseTaskDrawer,
    checkedCustomers,
    setCheckedCustomers,
    handleFetchList,
    handleUncheckAllTasks,
    handleDeleteTask,
    handleConfirmDelete,
    deleteDialogData,
    handleCloseDeleteDialog,

    loadingCompletion,
    completionTask,
    completeTaskDialogAnchorRef,
    assignedTo,
    handleCloseCompleteTaskPopper,
    checkNotAllowedToEdit,
    handleClickCompletion,
  };
};
