/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";
import * as React from "react";

export const ClockIcon = (props) => (
  <SvgIcon
    viewBox="0 0 10 14"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: "10px",
      height: "14px",
      fill: "none",
    }}
    {...props}
  >
    <path
      d="M2.188 13h5.624l-.338-3.612a6 6 0 0 0-.045-.41c-.18-1.077-1.02.113-2.042.029C5.301 9 5.2 9 5 9s-.3 0-.387.007C3.59 9.09 2.752 7.9 2.57 8.977a6 6 0 0 0-.045.41z"
      fill="#D9D9D9"
      fillOpacity={0.5}
    />
    <path
      d="M.5 1h9m-9 12h9M5 7c-.2 0-.3 0-.387-.007-1.022-.084-1.861-.895-2.042-1.97a6 6 0 0 1-.045-.411L2.188 1h5.624l-.338 3.612c-.02.213-.03.32-.045.41-.18 1.076-1.02 1.887-2.042 1.97C5.301 7 5.2 7 5 7Zm0 0c-.2 0-.3 0-.387.007-1.022.084-1.861.895-2.042 1.97a6 6 0 0 0-.045.411L2.188 13h5.624l-.338-3.612a6 6 0 0 0-.045-.41c-.18-1.076-1.02-1.887-2.042-1.97C5.301 7 5.2 7 5 7Z"
      stroke="#959595"
      strokeLinecap="round"
    />
  </SvgIcon>
);
