/* eslint-disable no-prototype-builtins */
import { useState, useRef, useCallback, useMemo, useEffect } from "react";
import { Controller, useWatch } from "react-hook-form";
import {
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
  Box,
  Skeleton,
} from "@mui/material";
import {
  bool,
  func,
  number,
  object,
  string,
  array,
  oneOfType,
} from "prop-types";
import {
  StyledTooltip,
  PriceFormat,
  StyledTextField,
  StyledButton,
  ValueToggleButtonGroup,
  LastOrderPrice,
} from "components";

import { cl } from "./ProductsItem.styles";
import { CartTrashIcon, DollarIcon, StyledProductIco } from "components/Icons";
import { calculateAvailable, useAdmin, photoUrl } from "helpers/helpers";
import AssignedRepsPopper from "../../../CustomersPage/components/CustomersTab/components/AssignedRepsPopper/AssignedRepsPopper";
import { useTheme } from "@emotion/react";
import { checkDisabledDiscount } from "Pages/DiscountsPage/components/ApplyDiscountPage/components/MSDPage/MSDPage.helpers";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";
import { debounce } from "lodash";

const DELAY_SAVE_QTY_MS = 100;

const DEFAULT_PRICE_STATE = {
  value: 0,
  prevValue: null,
};

const ProductsItem = ({
  hasPriceListPrice,
  product,
  quantity,
  setValue,
  index,
  handleRemoveOrderProduct,
  handleSubtractQty,
  control,
  formDiscountType,
  orderCompleted,
  outOfStock,
  paymentStatus,
  isThirdParty,
  checkSellingOutStock,
  availableDiscounts,
  customerSelected,
  handleAddDiscount,
  prodDict,
  lastProductsOrdersData,
  refPrice,
  gapBetweenPriceAndInfo,
  handleApplyDiscount,
  priceListLoading,
  calcProductPriceByPriceList,
  repPermissions,
  onAllowToOpenAdjustingDialog = () => {},
  findAndUpdateFreeCaseProduct = () => {},
  hasCustomerPriceList = false,
}) => {
  const isAdmin = useAdmin();
  const boxRef = useRef();
  const minusRef = useRef();
  const trashIconRef = useRef();
  const theme = useTheme();
  const formField = useWatch({ control });
  const [tempProductValue, setTempProductValue] = useState(DEFAULT_PRICE_STATE);

  useEffect(() => {
    if (product.allowCalcPrice && !hasCustomerPriceList) {
      setTempProductValue({
        value: product?.wholesalePrice || 0,
        prevValue: price,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSelected]);

  const handleDiscountChange = (val) => {
    const newValue = Number(val.value === "" ? 0 : val.value);
    setTempProductValue({
      value: newValue,
      prevValue: price,
    });

    setValue(`products.${index}.price`, newValue, {
      shouldDirty: true,
    });

    setValue(`products.${index}.allowCalcPrice`, false);

    findAndUpdateFreeCaseProduct(product, {
      price: newValue,
      allowCalcPrice: false,
    });
  };

  const isAllowedToChangePrice = repPermissions
    ? repPermissions?.orders?.edit_prices
    : true;

  const PL_PRICE = 8;
  const PL_TOTAL = 8;
  const PL_PRICE_BY_CHANGE = -11;

  const isNonInventory = product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

  const price = useMemo(() => {
    if (hasPriceListPrice && product?.hasOwnProperty("price")) {
      return product?.price || 0;
    }

    if (hasPriceListPrice) {
      const newPrice = calcProductPriceByPriceList({ product });
      setValue(`products.${index}.price`, newPrice, {
        shouldDirty: true,
      });
      setValue(`products.${index}.allowCalcPrice`, false, {
        shouldDirty: true,
      });
      return newPrice;
    }

    //set this in func at clear customer
    //if (!product?.hasOwnProperty("price")) {
    //  setValue(`products.${index}.price`, product?.wholesalePrice || 0, {
    //    shouldDirty: true,
    //  });

    //  setTempProductValue((prev) => ({
    //    ...prev,
    //    value: product?.wholesalePrice || 0,
    //  }));
    //}

    return product?.hasOwnProperty("price")
      ? product?.price
      : product?.wholesalePrice || 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasPriceListPrice,
    product,
    calcProductPriceByPriceList,
    hasCustomerPriceList,
  ]);

  useEffect(() => {
    setTempProductValue({ value: price, prevValue: price });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCustomerPriceList, hasPriceListPrice]);

  const calcAvailable = useCallback((product) => {
    const onHand =
      product?.inventory?.onHand || product?.product?.inventory?.onHand;
    const allocated =
      product?.inventory?.allocated || product?.product?.inventory?.allocated;
    return onHand - allocated;
  }, []);

  const itemDiscount =
    quantity *
    (product?.itemDiscountType === "PERCENTAGE"
      ? (tempProductValue.value * (product?.itemDiscountAmount || 0)) / 100
      : product?.itemDiscountAmount || 0);
  const calculatedDiscount =
    product?.itemDiscountType === "PERCENTAGE"
      ? itemDiscount
      : product?.itemDiscountAmount || 0;

  const [deliveryTooltipOpen, setDeliveryTooltipOpen] = useState(false);
  const [openDeliveredTooltip, setOpenDeliveredTooltip] = useState(false);

  const openToolTip = useMemo(
    () =>
      product?.minOrderQTY > quantity ||
      product?.product?.minOrderQTY > quantity ||
      product?.parentProduct?.minOrderQTY > quantity ||
      product?.product?.parentProduct?.minOrderQTY > quantity,
    [product, quantity]
  );

  const deleteDisabled = () => {
    const isDelivered = !!product.totalDelivered;
    const isDeleted = !product.product;
    return (orderCompleted || !isDeleted) && isDelivered;
  };

  const setQtyText = useMemo(() => {
    const availableProduct = calcAvailable(product);
    if (availableProduct <= 0) return "Out of stock";
    return `Stock: ${availableProduct} item`;
  }, [calcAvailable, product]);

  const slisedAvailiableDiscounts = useMemo(
    () => availableDiscounts?.slice(0, 10),
    [availableDiscounts]
  );

  const slisedMoreAvailiableDiscounts = useMemo(
    () => availableDiscounts.slice(10),
    [availableDiscounts]
  );

  const totalPrice = useMemo(
    () =>
      calculatedDiscount <= tempProductValue.value * product?.quantity
        ? quantity * tempProductValue.value - calculatedDiscount
        : 0,
    [calculatedDiscount, product?.quantity, tempProductValue.value, quantity]
  );

  useEffect(() => {
    if (tempProductValue.prevValue === null) return;
    return onAllowToOpenAdjustingDialog(product, totalPrice, () => {
      setTempProductValue({
        value: tempProductValue.prevValue,
        prevValue: tempProductValue.prevValue,
      });
      setValue(`products.${index}.price`, tempProductValue.prevValue, {
        shouldDirty: true,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPrice]);

  const setDiscountsTooltipTitle = useMemo(() => {
    return (
      <Box>
        <Typography fontSize={12} fontWeight={500} color="#000">
          Available Discounts:
        </Typography>
        <Box display="flex" flexDirection="column">
          {slisedAvailiableDiscounts.map((discount) => {
            const isAdded = formField.manufacturerDiscounts.some(
              ({ id, manufacturerDiscountId }) =>
                id === discount?.id || manufacturerDiscountId === discount?.id
            );

            const notMatchedRequirements = checkDisabledDiscount(
              discount,
              availableDiscounts,
              formField.manufacturerDiscounts,
              prodDict
            );
            const disabled = isAdded || notMatchedRequirements;
            const color = disabled
              ? theme.palette.disable.main
              : theme.palette.primary.main;
            return (
              <Box
                key={discount?.name}
                display="inline-flex"
                gap="2px"
                alignItems="center"
                color={color}
              >
                <span>- </span>
                <Link
                  fontSize={12}
                  fontWeight={300}
                  color={color}
                  component="p"
                  underline={disabled ? "none" : "always"}
                  sx={{ cursor: "pointer" }}
                  onClick={() => (disabled ? {} : handleAddDiscount(discount))}
                >
                  {discount?.name}
                </Link>
              </Box>
            );
          })}
        </Box>
        {!!slisedMoreAvailiableDiscounts.length && (
          <Box mt="10px">
            <Typography
              fontSize={12}
              fontWeight={300}
              onClick={() => handleApplyDiscount("manufacturer")}
              color={theme.palette.primary.main}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              + {slisedMoreAvailiableDiscounts.length} more
            </Typography>
          </Box>
        )}
      </Box>
    );
  }, [
    availableDiscounts,
    formField.manufacturerDiscounts,
    handleAddDiscount,
    handleApplyDiscount,
    prodDict,
    slisedAvailiableDiscounts,
    slisedMoreAvailiableDiscounts.length,
    theme.palette.disable.main,
    theme.palette.primary.main,
  ]);

  const immediateSubtractQty = debounce((field, handleSubtractQty) => {
    handleSubtractQty(product, field.value, index);
  }, DELAY_SAVE_QTY_MS);

  const debouncedAddQty = debounce((field, setValue) => {
    setValue(`products.${index}.quantity`, field.value + 1, {
      shouldDirty: true,
    });
  }, DELAY_SAVE_QTY_MS);

  if (!product) return <></>;

  return (
    <Paper
      sx={{
        py: 1,
        mt: "1px",
        height: "57px",
        cursor: "pointer",
        "&:not(:last-of-type)": { borderBottom: "0.5px solid #D4D4D4" },
        ":last-child": { borderRadius: "0 0 4px 4px" },
      }}
      elevation={0}
      square
      component={Grid}
      container
      columns={21}
    >
      <AssignedRepsPopper
        anchorEl={trashIconRef.current}
        open={trashIconRef.current && deliveryTooltipOpen && deleteDisabled()}
        rep={{ name: "This product has been fulfilled and cannot be removed" }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [6, 6],
            },
          },
        ]}
        placement="top"
      />
      <Grid
        item
        xs={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "38px",
            backgroundColor: "white",
            border: "1px solid #D5D9D9",
            borderRadius: "4px",
            position: "relative",
          }}
          height="38px"
        >
          {/* {!!showDiscountBadge && customerSelected && ( */}
          {!!availableDiscounts.length && customerSelected && (
            <StyledTooltip
              title={setDiscountsTooltipTitle}
              sx={{ whiteSpace: "pre-wrap" }}
              placement="top"
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [50, -6] } }],
              }}
              arrow
            >
              <Box sx={cl.dollarIconWrapper}>
                <DollarIcon />
              </Box>
            </StyledTooltip>
          )}
          <StyledProductIco
            src={
              product?.photos?.length
                ? photoUrl(product.photos[0].fileName)
                : product?.photo
                ? photoUrl(product?.photo?.fileName)
                : ""
            }
            styles={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
              borderRadius: "4px",
            }}
          />
        </Box>
      </Grid>
      <Grid
        item
        xs={formDiscountType === "item" ? 6.4 : 8.6}
        sx={cl.nameWrapper}
      >
        <Typography fontSize="12px" fontWeight="500" noWrap>
          {product?.name || product?.parentProduct?.name}
        </Typography>
        <Typography fontSize="12px" fontWeight="400" noWrap>
          {product?.sku}
          {(product?.size || product?.color) &&
            ` / ${product?.size || product?.color}`}
        </Typography>
      </Grid>
      <Grid item xs={1.5} sx={cl.inventoryWrapper}>
        {!isThirdParty && (
          <Typography fontSize="12px" fontWeight="400">
            {isNonInventory
              ? "-"
              : product?.product?.inventory || product?.inventory
              ? calculateAvailable(
                  product?.product?.inventory?.onHand ||
                    product?.inventory?.onHand ||
                    0,
                  product?.product?.inventory?.allocated ||
                    product?.inventory?.allocated ||
                    0
                )
              : "-"}
          </Typography>
        )}
      </Grid>

      <Grid
        item
        xs={formDiscountType === "item" ? 2.3 : 3}
        sx={cl.inventoryWrapper}
      >
        <Box ref={boxRef} display="flex" sx={{ height: "24px" }}>
          <AssignedRepsPopper
            open={boxRef.current && openToolTip}
            anchorEl={boxRef.current}
            rep={{
              name: `Minimum Order Quantity: ${
                product?.minOrderQTY ||
                product?.product?.minOrderQTY ||
                product?.parentProduct?.minOrderQTY ||
                product?.product?.parentProduct?.minOrderQTY
              }`,
            }}
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [0, 13],
                },
              },
            ]}
          />
          <AssignedRepsPopper
            open={minusRef.current && openDeliveredTooltip}
            anchorEl={minusRef.current}
            rep={{
              name: `${product.totalDelivered} items had been already fulfilled`,
            }}
            modifiers={[
              {
                name: "offset",
                options: {
                  offset: [4, 10],
                },
              },
            ]}
          />
          <AssignedRepsPopper
            open={
              boxRef.current &&
              !isNonInventory &&
              checkSellingOutStock(product) &&
              !openToolTip
            }
            anchorEl={boxRef.current}
            rep={{ name: setQtyText }}
            modifiers={[{ name: "offset", options: { offset: [-3, 13] } }]}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                value={quantity || 1}
                size="small"
                formSx={{ width: "80px" }}
                InputProps={{
                  startAdornment: (
                    <Box
                      ref={minusRef}
                      onMouseEnter={() => {
                        if (product.totalDelivered === quantity)
                          setOpenDeliveredTooltip(true);
                      }}
                      height="23.5px"
                      onMouseLeave={() => setOpenDeliveredTooltip(false)}
                    >
                      <StyledButton
                        label="–"
                        disabled={
                          isAdmin ||
                          field.value === 1 ||
                          product.totalDelivered === quantity
                        }
                        sx={{
                          ...cl.quantityButton,
                          borderColor: error
                            ? "#EB4233!important"
                            : "#D5D9D9!important",
                          "&:hover": {
                            borderWidth: "0.5px",
                            borderTop: "none",
                            borderBottom: "none",
                            borderColor: error
                              ? "#EB4233!important"
                              : "#D5D9D9!important",
                          },
                        }}
                        color="edit"
                        onClick={() =>
                          immediateSubtractQty(field, handleSubtractQty)
                        }
                        variant="outlined"
                      />
                    </Box>
                  ),
                  endAdornment: (
                    <StyledTooltip
                      arrow
                      placement="top"
                      title={`Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                        product
                      )}, Minimum Order QTY: ${
                        product?.minOrderQTY || product?.product?.minOrderQTY
                      }`}
                      disableHoverListener={!outOfStock}
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: { offset: [0, -5] },
                          },
                        ],
                      }}
                    >
                      <Box component="span" height="100%">
                        <StyledButton
                          disabled={
                            isAdmin ||
                            (!isNonInventory && outOfStock) ||
                            (!isNonInventory && checkSellingOutStock(product))
                          }
                          label="+"
                          sx={{
                            ...cl.quantityButton,
                            borderRadius: "0 4px 4px 0",
                            height: "23px",
                            borderColor: error
                              ? "#EB4233!important"
                              : "#D5D9D9!important",
                            "&:hover": {
                              borderWidth: "0.5px",
                              borderTop: "none",
                              borderBottom: "none",
                              borderColor: error
                                ? "#EB4233!important"
                                : "#D5D9D9!important",
                            },
                          }}
                          color="edit"
                          onClick={() => debouncedAddQty(field, setValue)}
                          variant="outlined"
                        />
                      </Box>
                    </StyledTooltip>
                  ),
                  sx: {
                    height: "24px",
                    borderRadius: "4px",
                    fontSize: "9px",
                    p: 0,
                    "& fieldset": {
                      borderColor: error
                        ? "#EB4233!important"
                        : "#D5D9D9!important",
                      borderRight: "none",
                      borderWidth: "0.5px!important",
                      p: 0,
                    },
                    "& > input": {
                      p: 0,
                      textAlign: "center",
                    },
                  },
                }}
                sx={{
                  borderColor: "#707070",
                  borderWidth: "0.5px!important",
                }}
                error={error ? " " : ""}
                {...field}
                onChange={(e) => {
                  if (isAdmin) return;
                  const val = parseInt(e.target.value, 10);
                  if (
                    (outOfStock && val > product.quantity) ||
                    val < product.totalDelivered
                  )
                    return;
                  setValue(
                    `products.${index}.quantity`,
                    e.target.value > 0
                      ? parseInt(e.target.value, 10)
                      : product?.minOrderQTY
                      ? product?.minOrderQTY
                      : 1,
                    { shouldDirty: true }
                  );
                }}
              />
            )}
            name={`products.${index}.quantity`}
            control={control}
          />
        </Box>
      </Grid>

      {/*Price*/}
      <Grid
        item
        xs={2.2}
        sx={{
          display: "flex",
          alignItems: "center",
          position: "relative",
          pl: `${PL_PRICE}px`,
        }}
      >
        {priceListLoading ? (
          <Skeleton variant="text" sx={{ fontSize: "12px", width: "50%" }} />
        ) : (
          <>
            {isAllowedToChangePrice ? (
              <StyledTooltip
                title="Edit item price for this order"
                placement="top"
              >
                <span>
                  <PriceFormat
                    errorMsgSx={{
                      transform: "translate(-100%, 0)",
                      bottom: 0,
                      left: -5,
                    }}
                    value={tempProductValue.value}
                    noErrorMessage
                    onValueChange={handleDiscountChange}
                    onFocus={() => {}}
                    fullWidth
                    allowNegative={false}
                    style={{
                      "& input::placeholder": {
                        color: "red !important",
                      },
                    }}
                    InputProps={{ sx: cl.moqInput }}
                  />
                </span>
              </StyledTooltip>
            ) : (
              <Typography
                ref={refPrice}
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  maxWidth: "calc(100% - 20px)",
                }}
                noWrap
              >
                {price.toFixed(2)}
              </Typography>
            )}

            <Box
              sx={{
                position: "absolute",
                left: isAllowedToChangePrice
                  ? `${PL_PRICE_BY_CHANGE}px`
                  : `${gapBetweenPriceAndInfo + PL_PRICE}px`,
                textAlign: "center",
              }}
              width="20px"
            >
              {!!lastProductsOrdersData?.length && (
                <LastOrderPrice lastOrders={lastProductsOrdersData} />
              )}
            </Box>
          </>
        )}
      </Grid>
      {formDiscountType === "item" && (
        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {product.totalDelivered > 0 ||
          (!product.isNewAdded &&
            paymentStatus &&
            paymentStatus !== "PENDING") ? (
            <Typography
              fontSize="14px"
              fontWeight="400"
              whiteSpace="nowrap"
              color="#5F6267"
            >
              ${calculatedDiscount.toFixed(2)}
            </Typography>
          ) : (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <PriceFormat
                  type={product.itemDiscountType}
                  noErrorMessage
                  fullWidth
                  formSx={{ maxWidth: "120px" }}
                  error={error?.message || ""}
                  disabled={
                    isAdmin ||
                    product.totalDelivered > 0 ||
                    (!product.isNewAdded &&
                      paymentStatus &&
                      paymentStatus !== "PENDING")
                  }
                  InputProps={{
                    sx: {
                      height: "31px",
                      fontSize: "12px",
                      pr: "2px",
                      pl: "2px",
                      "& input": { pl: "8px" },
                    },
                    endAdornment: (
                      <Controller
                        render={({ field }) => (
                          <ValueToggleButtonGroup
                            qtyPicker={false}
                            type={field.value}
                            {...field}
                          />
                        )}
                        name={`products.${index}.itemDiscountType`}
                        control={control}
                      />
                    ),
                  }}
                  {...field}
                />
              )}
              name={`products.${index}.itemDiscountAmount`}
              control={control}
            />
          )}
        </Grid>
      )}

      {/*Total price*/}
      <Grid
        item
        xs={2.6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          pl: `${PL_TOTAL}px`,
        }}
      >
        {priceListLoading ? (
          <Skeleton variant="text" width="50%" />
        ) : (
          <Typography fontSize="12px" fontWeight="600" noWrap>
            <span style={{ color: "#B5B5B5", fontWeight: 400 }}>$</span>{" "}
            {totalPrice.toFixed(2)}
          </Typography>
        )}
      </Grid>

      <Grid
        item
        xs={1}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          pr: "8px",
        }}
      >
        <Box
          onMouseEnter={() => setDeliveryTooltipOpen(true)}
          onMouseLeave={() => setDeliveryTooltipOpen(false)}
        >
          <IconButton
            onClick={() => handleRemoveOrderProduct(product)}
            disabled={isAdmin || deleteDisabled()}
            ref={trashIconRef}
            sx={{
              "&.Mui-disabled svg": {
                opacity: 0.5,
              },
            }}
          >
            <CartTrashIcon />
          </IconButton>
        </Box>
      </Grid>
    </Paper>
  );
};

ProductsItem.propTypes = {
  product: object,
  quantity: number,
  setValue: func,
  index: number,
  handleRemoveOrderProduct: func,
  customerSelected: bool,
  handleSubtractQty: func,
  control: object,
  formDiscountType: string,
  orderCompleted: bool,
  outOfStock: bool,
  paymentStatus: string,
  isThirdParty: bool,
  priceListLoading: bool,
  hasPriceListPrice: bool,
  checkSellingOutStock: func,
  availableDiscounts: array,
  handleAddDiscount: func,
  prodDict: array,
  lastProductsOrdersData: oneOfType([bool, array]),
  refPrice: func,
  repPermissions: object,
  calcProductPriceByPriceList: func,
  gapBetweenPriceAndInfo: number,
  handleApplyDiscount: func,
  onAllowToOpenAdjustingDialog: func,
  findAndUpdateFreeCaseProduct: func,
  hasCustomerPriceList: bool,
};

ProductsItem.defaultProps = {
  product: null,
  quantity: 1,
  customerSelected: false,
  priceListLoading: false,
  hasPriceListPrice: false,
  orderCompleted: false,
  isThirdParty: false,
  handleAddDiscount: () => {},
  calcProductPriceByPriceList: () => {},
  prodDict: [],
};

export default ProductsItem;
