import { useCallback, useEffect, useState } from "react";
import { getDistributorSubscriptionBillingService } from "../../../../../../../services/stripe";
import { FETCH_LIMITS } from "../../../../../../../utils/constants";
import { error } from "../../../../../../../utils/notifications";
import { useRepsPermissions } from "helpers/hooks";

export const useBillingActions = () => {
  const repPermissions = useRepsPermissions();

  const [billingData, setBillingData] = useState({
    loading: false,
    list: [],
    count: null,
    params: {
      limit: FETCH_LIMITS.BILLING_PLAN,
      page: 1,
      sort_by_date: "desc",
    },
  });

  const fetchBillingData = useCallback(
    async (didCancel) => {
      try {
        setBillingData((prev) => ({ ...prev, loading: true }));
        const data = await getDistributorSubscriptionBillingService(
          billingData.params
        );
        if (didCancel) return;
        setBillingData((prev) => ({
          ...prev,
          list: [...prev?.list, ...data?.rows],
          count: data?.count,
          params: {
            ...prev?.params,
            page: prev?.params?.page + 1,
          },
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setBillingData((prev) => ({ ...prev, loading: false }));
      }
    },
    [billingData.params]
  );

  useEffect(() => {
    let didCancel = false;
    if (repPermissions) return;
    fetchBillingData(didCancel);

    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { billingData, fetchBillingData };
};
