import { useEffect, useMemo, useState } from "react";
import { object, func, string } from "prop-types";
import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import { StyledAutocomplete } from "../../../../../../../components/TextFields/TextFields";
import {
  useDebounce,
  useRepsPermissions,
} from "../../../../../../../helpers/hooks";
import useStyles, { cl } from "./styles";

import { createSelector } from "reselect";
import {
  categoriesListSelector,
  isLoadingCategoriesSelector,
} from "../../../../../../../redux/selectors/categories";
import { useSelector, useDispatch } from "react-redux";
import { getCategoriesListAction } from "../../../../../../../redux/actions/categories";
import { CategoriesList } from "./CategoriesList";
import { AddCategoryDrawer } from "Pages/CatalogPage/CategoriesTab/components/AddCategoryDrawer";
import { DEFAULT_DRAWER_STATE } from "Pages/CatalogPage/CategoriesTab/useCategoriesTab";

const selector = createSelector(
  isLoadingCategoriesSelector,
  categoriesListSelector,
  (isLoadingCategories, categoriesList) => ({
    isLoadingCategories,
    categoriesList,
  })
);

export const CategoriesComponent = ({
  control,
  setValue,
  label = "Category",
  inputProps = {},
}) => {
  const classes = useStyles();

  const repPermissions = useRepsPermissions();
  const { isLoadingCategories, categoriesList } = useSelector(selector);
  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const [openAddDrawer, setOpenAddDrawer] = useState(DEFAULT_DRAWER_STATE);

  const searchInputDebounced = useDebounce(searchInput, 500);

  const onOpenCategoryDrawer = () =>
    setOpenAddDrawer({ state: true, element: null, parent: null });

  const onDrawerClose = () => setOpenAddDrawer(DEFAULT_DRAWER_STATE);

  const disabledCreateEditCategories = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.catalog?.create_edit_categories;
    }
  }, [repPermissions]);

  useEffect(() => {
    dispatch(getCategoriesListAction({ search: searchInputDebounced }));
  }, [dispatch, searchInputDebounced]);

  return (
    <Grid xs={12} item position="relative">
      <Controller
        render={({ field, fieldState: { error } }) => (
          <StyledAutocomplete
            list={categoriesList}
            error={error ? error.message : ""}
            noErrorMessage
            label={label}
            childItemProps={{
              isAdd: false,
              onClick: (item) => {
                setSearchInput("");
                setValue("category", item, {
                  shouldDirty: true,
                });
              },
            }}
            loading={isLoadingCategories}
            className={classes.textfield}
            {...field}
            value={searchInput ? searchInput : field?.value?.name}
            PaperComponent={CategoriesList}
            PaperComponentProps={{
              disabledCreateEditCategories,
              repPermissions,
              list: categoriesList,
              onOpenCategoryDrawer,
              setSearchInput,
              setValue,
            }}
            onChange={(e) => {
              setSearchInput(e.target.value);
              setValue("category", null);
              field.onChange(null);
            }}
            showClearIcon={
              (!!searchInput && !field?.value) ||
              (!searchInput && !!field?.value)
            }
            onClear={() => {
              setSearchInput("");
              setValue("category", null);
            }}
            InputProps={inputProps}
            sx={cl.searchInput}
            hideEmptyPlaceholder
          />
        )}
        name="category"
        control={control}
      />

      <AddCategoryDrawer
        {...{
          creatingMsg: "Category Added",
          openState: openAddDrawer,
          onClose: onDrawerClose,
          onSaveCallback: () => {
            onDrawerClose();
            dispatch(getCategoriesListAction({ search: searchInputDebounced }));
          },
        }}
      />
    </Grid>
  );
};

CategoriesComponent.propTypes = {
  control: object.isRequired,
  inputProps: object,
  setValue: func.isRequired,
  label: string,
};
