import { ManufacturerBlock } from "Pages/CatalogPage/CatalogNewProductPage/ContentTabs/RequiredInfoTab/components";
import { array, object, func, number, string } from "prop-types";
import { useSelector } from "react-redux";
import {
  manufacturersCountSelector,
  manufacturersListSelector,
} from "redux/selectors/manufacturers";
import { createSelector } from "reselect";

const selector = createSelector(
  manufacturersListSelector,
  manufacturersCountSelector,
  (manufacturers, manufacturersCount) => ({
    manufacturers,
    manufacturersCount,
  })
);

export const AssignManufacturerBlock = ({
  handleFetchManufacturers,
  setCustomersSearchInput,
  customersSearchInput,
  setIsOpenNewManDialog,
  formField,
  setValue,
  control,
  page,
}) => {
  const { manufacturers, manufacturersCount } = useSelector(selector);

  return (
    <>
      <ManufacturerBlock
        {...{
          manufacturersPage: page,
          manufacturers,
          manufacturersCount,
          handleFetchManufacturers,
          setCustomersSearchInput,
          customersSearchInput,
          setIsOpenNewManDialog,
          formField,
          fontSize: "12px",
          formSx: {
            "&.MuiFormControl-root .MuiOutlinedInput-root": {
              fontWeight: "300",
            },
          },
          labelSx: {
            "&.MuiFormLabel-filled": {
              mt: "4.5px !important",
            },
            "&.MuiInputLabel-root.Mui-focused": {
              mt: "4.5px !important",
            },
          },
          setValue,
          control,
        }}
      />
    </>
  );
};

AssignManufacturerBlock.propTypes = {
  manufacturers: array,
  manufacturersCount: number,
  page: number,
  handleFetchManufacturers: func,
  setCustomersSearchInput: func,
  customersSearchInput: string,
  setIsOpenNewManDialog: func,
  formField: object,
  setValue: func,
  control: object,
};
