/* eslint-disable react/prop-types */
import * as React from "react";
import { SvgIcon } from "@mui/material";
export const WarningIcon = ({ width = 24, height = 24, sx = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ width, height, ...sx }}
    {...props}
  >
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.736 2.4c1.006-1.867 3.522-1.867 4.528 0l9.382 17.4c1.006 1.867-.252 4.2-2.265 4.2H2.62C.606 24-.652 21.667.354 19.8zM12 6a1 1 0 0 1 1 1v9a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1m0 15.5a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5"
          fill="#fff"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);
