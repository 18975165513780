import { string } from "prop-types";
import { Stack, Typography } from "@mui/material";
import { truncateText } from "helpers/helpers";
import { MAX_CHARACTERS_OPTIONS_TITLE } from "Pages/FormViewPage/FormViewPage.constants";

export const SingleDialogBlock = ({ text = "" }) => {
  return (
    <Stack flexDirection="row" gap="4px">
      <Typography fontSize={13} fontWeight={400} color="#5F6267">
        Replied: {truncateText(text, MAX_CHARACTERS_OPTIONS_TITLE)}
      </Typography>
    </Stack>
  );
};

SingleDialogBlock.propTypes = { text: string };
