import axios from "axios";

export const getTasksService = async (params, resFetchId) => {
  return await axios
    .get("tasks", { params })
    .then((res) => ({ ...res.data, resFetchId }));
};

export const getTaskByIdService = async (id) => {
  return await axios.get(`/tasks/${id}`).then((res) => res.data);
};

export const createTaskService = async ({ data }) => {
  return await axios.post("/tasks", data).then((res) => res.data);
};

export const createTaskBulkService = async ({ data }) => {
  return await axios.post("/tasks/bulk", data).then((res) => res.data);
};

export const updateTaskService = async ({ taskId, data }) => {
  return await axios.put(`/tasks/${taskId}`, data).then((res) => res.data);
};

export const updateCompletedTaskService = async ({ taskId, data }) => {
  return await axios
    .put(`/tasks/status/${taskId}`, data)
    .then((res) => res.data);
};

export const deleteTaskService = async (data) => {
  return await axios.delete("/tasks", { data }).then((res) => res.data);
};

export const getTasksTotalSummariesService = async (params) => {
  return await axios
    .get("/tasks/total-summaries", { params })
    .then((res) => res.data);
};

export const bulkMarkAsCompletedService = async (data) => {
  return await axios
    .put("/tasks/bulk-mark-as-completed", data)
    .then((res) => res.data);
};

export const bulkTasksAssignRepresentativesService = async (data) => {
  return await axios
    .put("/tasks/bulk-assign-representatives", data)
    .then((res) => res.data);
};

export const bulkSetDueDateService = async (data) => {
  return await axios
    .put("/tasks/bulk-set-due-date", data)
    .then((res) => res.data);
};

export const duplicateTaskService = async (data) => {
  return await axios.post("/tasks/duplicate", data).then((res) => res.data);
};
