/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";

export const FormIcon = ({ width = 16, height = 20, ...props }) => (
  <SvgIcon
    viewBox="0 0 16 20"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fill: "none", width, height }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.286 0h5.428c.902 0 1.647 0 2.236.08.618.082 1.165.264 1.602.701s.619.984.702 1.602c.08.59.08 1.334.08 2.237v10.76c0 .903 0 1.648-.08 2.237-.083.618-.264 1.165-.702 1.602s-.984.619-1.602.702c-.59.079-1.334.079-2.236.079H5.286c-.903 0-1.647 0-2.236-.08-.619-.082-1.165-.264-1.602-.701s-.62-.984-.702-1.602c-.08-.59-.08-1.334-.08-2.237V4.62c0-.903 0-1.648.08-2.237.083-.618.264-1.165.702-1.602S2.43.162 3.05.08C3.639 0 4.383 0 5.286 0M3.227 1.4c-.47.064-.688.175-.837.324-.148.148-.26.366-.323.837C2.001 3.051 2 3.705 2 4.667v10.666c0 .962.001 1.616.067 2.106.063.47.175.689.323.837s.367.26.837.323c.49.066 1.145.068 2.106.068h5.333c.962 0 1.616-.002 2.106-.068.47-.063.689-.175.837-.323s.26-.366.324-.837c.065-.49.067-1.144.067-2.106V4.667c0-.962-.002-1.616-.067-2.106-.064-.47-.175-.689-.324-.837-.148-.149-.366-.26-.837-.323-.49-.066-1.144-.068-2.106-.068H5.333c-.961 0-1.616.002-2.106.068M7.333 5c0-.368.299-.667.667-.667h4a.667.667 0 1 1 0 1.334H8A.667.667 0 0 1 7.333 5M8 9.333a.667.667 0 0 0 0 1.334h4a.667.667 0 0 0 0-1.334zM7.333 15c0-.368.299-.667.667-.667h4a.667.667 0 0 1 0 1.334H8A.667.667 0 0 1 7.333 15M4 4.333a.667.667 0 0 0 0 1.334h2a.667.667 0 0 0 0-1.334zM3.333 10c0-.368.299-.667.667-.667h2a.667.667 0 0 1 0 1.334H4A.667.667 0 0 1 3.333 10M4 14.333a.667.667 0 0 0 0 1.334h2a.667.667 0 0 0 0-1.334z"
      fill="#A5A5A5"
    />
  </SvgIcon>
);
