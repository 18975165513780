import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, MenuItem } from "@mui/material";
import {
  FilterChip,
  FilterSearchTextField,
  SquareSelect,
  StyledButton,
} from "components";
import { FilterIcon } from "components/Icons";
import useStyles from "./styles";
import { CustomerProfileContext } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfilePage";
import { useDebounce } from "helpers/hooks";
import FilterMenu from "components/FilterMenu/FilterMenu";
import { useTableFilters } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfile.hooks";
import { normalizeSnakeCaseString, truncateText } from "helpers/helpers";
import { FILTERS_MAP } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfile.constants";
import { useLocation } from "react-router-dom";

const Filters = () => {
  const classes = useStyles();
  const filterAnchor = useRef();
  const location = useLocation();
  const { currentTable, handleDataSearch, customerState, setTableQuery } =
    useContext(CustomerProfileContext);

  const { bulkActions, filterProps } = useMemo(
    () => currentTable || {},
    [currentTable]
  );

  const [search, setSearch] = useState("");
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [switches, setSwitches] = useState(filterProps?.switches || []);
  const [filterFields, setFilterFields] = useState(
    filterProps?.filterFields || {}
  );

  const searchDebounced = useDebounce(search, 500);
  const filterFieldsDebounced = useDebounce(filterFields, 500);

  const debouncedSearch = useCallback(() => {
    setTableQuery((prev) => ({
      ...prev,
      cursor: "",
    }));

    const currentTabFilters = FILTERS_MAP[customerState.tableTab.value];
    const isDefaultFilters = Object.keys(filterFieldsDebounced).every(
      (key) => filterFieldsDebounced[key] === currentTabFilters[key]
    );
    !isDefaultFilters && setIsFilterChanged(true);
    handleDataSearch({
      search: searchDebounced,
      filterFields:
        !isFilterChanged && isDefaultFilters ? null : filterFieldsDebounced,
    });
  }, [
    customerState.tableTab.value,
    filterFieldsDebounced,
    handleDataSearch,
    isFilterChanged,
    searchDebounced,
    setTableQuery,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(debouncedSearch, [filterFieldsDebounced, searchDebounced]);

  useEffect(() => setIsFilterChanged(false), [customerState.tableTab.value]);

  const changeTabFilters = useCallback(() => {
    setTableQuery((prev) => ({
      ...prev,
      cursor: "",
    }));
    setFilterFields(filterProps?.filterFields);
    setSwitches(filterProps?.switches);
    setSearch("");
  }, [filterProps?.filterFields, filterProps?.switches, setTableQuery]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(changeTabFilters, [customerState.tableTab.value]);

  const { currentMatchFilterData } = useTableFilters();

  const handleApplyFilter = (newSwitches, newFields) => {
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
    setTableQuery((prev) => ({
      ...prev,
      cursor: "",
    }));
  };

  const getChipLabel = (field) => {
    const { name } = field || {};
    if (name) return truncateText(name);
    if (typeof field === "string")
      return normalizeSnakeCaseString(truncateText(field));
    return field
      .map((s) => normalizeSnakeCaseString(truncateText(s)))
      .join(", ");
  };

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const handleDeleteFilter = useCallback(
    (key) => {
      const newState = [...switches];
      const index = newState.findIndex((s) => s.value === key);
      const insert = {
        ...newState[index],
        checked: false,
      };
      if (index > -1) {
        newState.splice(index, 1, insert);
        setSwitches([...newState]);
      }
      setTableQuery((prev) => ({
        ...prev,
        cursor: "",
      }));
      setFilterFields((prev) => {
        return { ...prev, [key]: "" };
      });
    },
    [switches, setTableQuery]
  );

  useEffect(() => {
    if (location?.state?.orderFilters) {
      setFilterFields({
        ...filterFields,
        ...location?.state?.orderFilters,
      });
      setSwitches([
        ...(switches || []),
        ...(location?.state?.orderSwitchers || []),
      ]);
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state]);

  const showFilterBlock = useMemo(() => {
    const isSwitcherEmpty = filterProps?.switches?.length === 0;
    const isFilterFieldsEmpty =
      [...Object.keys(filterProps?.filterFields)]?.length === 0;

    return !(isSwitcherEmpty && isFilterFieldsEmpty);
  }, [filterProps?.filterFields, filterProps?.switches?.length]);

  return showFilterBlock ? (
    <Box className={classes.filterWrap} width="100%">
      <FilterSearchTextField
        adminIsAllowed
        formSx={{ minWidth: "380px" }}
        placeholderWidth="310px"
        placeholder={`Search ${customerState.tableTab.label.toLowerCase()}`}
        fullWidth
        onChange={({ target }) => setSearch(target.value)}
        loading={
          !!customerState.tableData.items?.length &&
          !!customerState.tableData.loading?.[customerState.tableTab.value]
        }
        value={search}
        // onChange={handleDataSearch}
        // value={filterSearch}
        customAdornment={
          <Box
            display="flex"
            gap="5px"
            overflow="auto"
            sx={{
              py: "4px",
              "&::-webkit-scrollbar": {
                height: "2px",
              },
            }}
          >
            {filterChipKeys.map((key) => (
              <FilterChip
                key={key}
                onDelete={() => handleDeleteFilter(key)}
                title={normalizeSnakeCaseString(
                  filterFields[key]?.label || key
                )}
                text={getChipLabel(filterFields[key])}
              />
            ))}
          </Box>
        }
        adornmentProps={{
          sx: {
            justifyContent: "flex-end",
          },
        }}
      />
      <StyledButton
        label="Filter"
        startIcon={<FilterIcon />}
        variant="outlined"
        ref={filterAnchor}
        color="edit"
        sx={{
          height: "39px",
          width: "100%",
          maxWidth: "74px",
          border: "0.5px solid #D5D9D9",
          "& .MuiButton-startIcon": {
            ml: 0,
          },
        }}
        fontSize="15px"
        onClick={() => setFilterMenuOpen(true)}
      />
      {bulkActions && (
        <SquareSelect
          value=""
          label="Bulk Actions"
          sx={{ backgroundColor: "white" }}
          formSx={{ maxWidth: "125px" }}
          radiused
          leftSidedIcon
          unfocused
          fullWidth
          onChange={(e) => {
            e.preventDefault();
          }}
        >
          {bulkActions?.map(({ label, onClick, disabled }) => (
            <MenuItem key={label} disabled={disabled} onClick={onClick}>
              {label}
            </MenuItem>
          ))}
        </SquareSelect>
      )}
      <FilterMenu
        anchorEl={filterAnchor.current}
        open={filterMenuOpen}
        onClose={() => setFilterMenuOpen(false)}
        filterFields={filterFields}
        isCustomFulfillmentStatus={true}
        switches={switches}
        handleApplyFilter={handleApplyFilter}
        selectMatchData={currentMatchFilterData}
      />
    </Box>
  ) : null;
};

Filters.propTypes = {};

export default Filters;
