import { object, func, array } from "prop-types";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import {
  CheckBoxReportCheckedIcon,
  CheckBoxReportUncheckedIcon,
  ReorderBurgerIcon,
} from "../../../../../components/Icons";

export const ReportCheckbox = ({
  item,
  setValue,
  handleAddColumn,
  selectAndReorderColumns,
  selectAndReorderColumnsBottom,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <ReorderBurgerIcon />

      <FormControlLabel
        sx={{
          ml: "-10px",
          mr: 0,
        }}
        control={
          <Checkbox
            checked={!!item?.selected}
            icon={<CheckBoxReportUncheckedIcon />}
            checkedIcon={<CheckBoxReportCheckedIcon />}
            onChange={(e) => {
              const currentItemIndex = selectAndReorderColumns?.findIndex(
                (col) => col?.type === item?.type
              );

              setValue(
                `selectAndReorderColumns[${currentItemIndex}].selected`,
                e.target.checked
              );
              if (!item?.isHidden) {
                const i = selectAndReorderColumnsBottom?.findIndex(
                  (col) => col?.type === item?.type
                );
                handleAddColumn(item);
                setValue(
                  `selectAndReorderColumnsBottom[${i}].selected`,
                  e.target.checked
                );
                setValue(
                  `selectAndReorderColumnsBottom[${i}].chosen`,
                  e.target.checked
                );
              }

              if (item?.type === "productName") {
                selectAndReorderColumnsBottom?.forEach((el, i) => {
                  if (!!el.bottom && !!el.selected) {
                    setValue(
                      `selectAndReorderColumnsBottom[${i}].selected`,
                      false
                    );
                    setValue(
                      `selectAndReorderColumnsBottom[${i}].chosen`,
                      false
                    );
                  }
                });
                setValue(
                  "selectAndReorderColumns",
                  selectAndReorderColumnsBottom?.filter((el) => !!el.chosen)
                );
              }
            }}
          />
        }
        label={
          <Typography fontSize={17} fontWeight={300} color="#707070">
            {item?.name}
          </Typography>
        }
      />
    </Box>
  );
};
ReportCheckbox.propTypes = {
  item: object,
  setValue: func,
  handleAddColumn: func,
  selectAndReorderColumnsBottom: array,
  selectAndReorderColumns: array,
};
