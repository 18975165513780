import { containsEvery, normalizeSnakeCaseString } from "helpers/helpers";
import { useDebounce } from "helpers/hooks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getProductsService } from "services/products";
import {
  PRODUCT_TYPE_INVENTORY,
  PRODUCTS_STATUSES,
  SCROLL_LIMIT,
  UNCATEGORIZED_CATEGORY,
} from "utils/constants";
import { error } from "utils/notifications";
import { defaultFilters, getFilterSwitches } from "./components/constants";
import { Box, Typography } from "@mui/material";
import { isEqual } from "lodash";

const DEFAULT_STATE = {
  loading: true,
  list: [],
  count: 0,
  countWithVariations: 0,
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: SCROLL_LIMIT,
  status: '["active", "inactive_for_customers"]',
  with_missing_info: undefined,
  search: "",
  exclude_negative_in_parents_inventory: true,
};

export const useAllProducts = ({
  isOpen = true,
  showInactive = false,
  priceListId,
  specificManufacturer,
  setCheckState,
  checkedProducts,
  isOrder,
  isPriceList,
  disableOutOfStock,
  addedProducts,
  fetchQuery = {},
  isEditCustomer = false,
  allCheckedByDefault = false,
  excludeIds,
  handleAddProducts,
  setExcludeIds = () => {},
  isAllUncheckedProducts = false,
  handleCancelProducts = () => {},
  isAllUnchecked = false,
  setIsAllUnchecked = () => {},
  availableAssignedProducts = [],
}) => {
  const [productsActiveState, setProductsActiveState] = useState(DEFAULT_STATE);
  const [productsInactiveState, setProductsInactiveState] =
    useState(DEFAULT_STATE);
  const [productParams, setProductParams] = useState(DEFAULT_PARAMS);
  const [expandedParentId, setExpandedParentId] = useState(null);
  const [allowSelectByDefault, setAllowSelectByDefault] = useState(false);
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  const filterAnchor = useRef();

  const setStateFunc = useMemo(
    () => (showInactive ? setProductsInactiveState : setProductsActiveState),
    [showInactive]
  );

  const productsState = useMemo(
    () => (showInactive ? productsInactiveState : productsActiveState),
    [productsActiveState, productsInactiveState, showInactive]
  );

  const searchInputDebounced = useDebounce(productParams.search, 500);
  const [filterFields, setFilterFields] = useState(defaultFilters);
  const [switches, setSwitches] = useState(
    getFilterSwitches(!!specificManufacturer)
  );

  const filterChipKeys = useMemo(
    () => Object.keys(filterFields).filter((key) => filterFields[key]),
    [filterFields]
  );

  const getChipLabel = (key) => {
    const icon = filterFields[key]?.icon;
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {normalizeSnakeCaseString(filterFields[key]?.label || key)}:{" "}
          {!icon && (
            <span style={{ color: "#5F6368", marginTop: !!icon && "5px" }}>
              {normalizeSnakeCaseString(
                filterFields[key]?.name || filterFields[key]
              )}
            </span>
          )}
        </Typography>
        {icon}
      </Box>
    );
  };

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    const insert = {
      ...switches[index],
      checked: false,
    };
    newState.splice(index, 1, insert);
    setSwitches([...newState]);
    setFilterFields((prev) => {
      return { ...prev, [key]: "" };
    });
  };

  const handleApplyFilter = (newSwitches, newFields) => {
    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const setLoading = useCallback(
    (loading) => setStateFunc((prev) => ({ ...prev, loading })),
    [setStateFunc]
  );

  const [currentProductRef, setCurrentProductRef] = useState(false);
  const [textProduct, setTextProduct] = useState("");

  const productsRefs = useRef([]);

  const calcAvailable = useCallback((product) => {
    const onHand = product?.inventory?.onHand;
    const allocated = product?.inventory?.allocated;

    return onHand - allocated;
  }, []);

  const productsAvailableLessThanMinimum = useCallback(
    (product) => {
      const isNonInventory =
        product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

      const available = calcAvailable(product);
      if (
        product?.sellingOutOfStock ||
        product?.childProducts?.length ||
        isNonInventory
      )
        return false;
      return available < product?.minOrderQTY;
    },
    [calcAvailable]
  );

  const supplierWithOnlyOneProductWithVariations = useMemo(() => {
    const productsWithChildren = productsState?.list?.filter(
      (p) => p?.childProducts?.length
    );
    if (productsWithChildren?.length === 1) return true;
    return false;
  }, [productsState?.list]);

  // If all products of the parent product are out of stock
  const calcProductOutOfStockForParent = useCallback(
    (product) => {
      if (product?.childProducts?.length && !product?.sellingOutOfStock) {
        return !product?.childProducts?.some((prod) => calcAvailable(prod) > 0);
      }
      return false;
    },
    [calcAvailable]
  );

  const handleDisableIfChosenProduct = useCallback(
    (product, parent, allowMultiple = false) => {
      if (isOrder || !checkedProducts?.length || isPriceList) return false;
      const manufacturerId =
        product?.manufacturer?.id || parent?.manufacturer?.id;

      const checkedManufacturerId =
        checkedProducts?.[0]?.manufacturer?.id ||
        checkedProducts?.[0]?.parentProduct?.manufacturer?.id;

      if (manufacturerId === checkedManufacturerId && allowMultiple)
        return false;

      const selectedParentId =
        checkedProducts?.[0]?.parentProduct?.id ||
        checkedProducts?.[0]?.parentId ||
        checkedProducts?.[0]?.id;

      const currentProductId = parent?.id || product?.id;

      return !(selectedParentId === currentProductId);
    },
    [checkedProducts, isOrder, isPriceList]
  );

  const checkOnDisabledChild = useCallback(
    (child) => {
      const isNonInventory =
        child?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

      const isOutOfStock =
        !isNonInventory &&
        disableOutOfStock &&
        productsAvailableLessThanMinimum(child);

      return isOutOfStock;
    },
    [disableOutOfStock, productsAvailableLessThanMinimum]
  );

  const areAllChildProductsDisabled = useCallback(
    (product) => {
      if (product?.childProducts?.length) {
        const disabledProducts = product.childProducts.filter((child) => {
          const isOutOfStock = checkOnDisabledChild({
            ...child,
            sellingOutOfStock: product?.sellingOutOfStock,
            minOrderQTY: product?.minOrderQTY,
          });
          if (
            isOutOfStock ||
            child?.missingFields?.length ||
            addedProducts?.some(
              (addedProduct) =>
                addedProduct?.id === child?.id ||
                addedProduct?.product?.id === child?.id
            )
          )
            return child;
        });

        return disabledProducts.length === product?.childProducts?.length;
      }
      return false;
    },
    [addedProducts, checkOnDisabledChild]
  );

  const handleProductsDisable = useCallback(
    (product, isChild) => {
      if (isPriceList) return false;
      if (isChild || !product.isMultiple)
        //if (!addedProducts?.length) return false;

        return (
          (disableOutOfStock && productsAvailableLessThanMinimum(product)) ||
          addedProducts?.some(
            (addedProduct) =>
              addedProduct?.id === product?.id ||
              addedProduct?.product?.id === product?.id
          )
        );

      const isAllChildDisabled = areAllChildProductsDisabled(product);

      return (
        (disableOutOfStock && productsAvailableLessThanMinimum(product)) ||
        containsEvery(
          product?.childProducts?.filter(
            // if isMultiple, check if Added products (formfield) contain
            // any of child products from this parent
            (childProduct) =>
              !addedProducts?.some(
                (prod) =>
                  prod?.id === childProduct?.id ||
                  prod?.product?.id === childProduct?.id
              )
          ),
          addedProducts
        ) ||
        isAllChildDisabled
      );
    },
    [
      isPriceList,
      disableOutOfStock,
      productsAvailableLessThanMinimum,
      addedProducts,
      areAllChildProductsDisabled,
    ]
  );

  const getFlatProductsList = useCallback(() => {
    if (!productsState?.list?.length || !setCheckState) return [];
    let products = [];

    productsState?.list.forEach((product) => {
      if (product?.childProducts?.length) {
        products = [...products, ...product.childProducts];
      } else {
        products.push(product);
      }
    });

    return products;
  }, [productsState?.list, setCheckState]);

  const flatList = useMemo(() => getFlatProductsList(), [getFlatProductsList]);

  const handleFlatAndSetState = useCallback(
    ({ list, isFetchAll = false, setExclude = false }) => {
      if (!list?.length || !setCheckState) return;

      const flattenProducts = (products) => {
        const flatList = [];
        products.forEach((product) => {
          if (excludeIds.includes(product.id) && !isFetchAll && !setExclude)
            return;
          if (product.childProducts?.length) {
            flatList.push(...flattenProducts(product.childProducts));
          } else {
            flatList.push(product);
          }
        });
        return flatList;
      };

      const updatedCategories = flattenProducts(list);

      if (setExclude) {
        const ids = updatedCategories.map((p) => p?.id);
        return setExcludeIds(ids);
      }

      return setCheckState(updatedCategories);
    },
    [excludeIds, setCheckState, setExcludeIds]
  );

  const onCreateSelectAllProducts = (isChecked) => {
    if (!isChecked) {
      setIsAllUnchecked(true);
      setCheckState([]);
      const ids = flatList.map((p) => p?.id);
      setExcludeIds(ids);
      setAllowSelectByDefault(false);
      return;
    }

    if (productsState.list.length === productsState.count) {
      setIsAllUnchecked(false);
      setExcludeIds([]);
      setAllowSelectByDefault(false);
      return setCheckState(flatList);
    }

    setCheckState(flatList);
    setExcludeIds([]);
    setIsAllUnchecked(false);
    setAllowSelectByDefault(true);
    return;
  };

  const handleSelectAllProducts = (isChecked) => {
    setExcludeIds([]);
    if (allCheckedByDefault) return onCreateSelectAllProducts(isChecked);

    if (productsState.countWithVariations === checkedProducts?.length) {
      //if (flatList?.length === checkedProducts?.length) {
      if (checkedProducts?.length)
        setExcludeIds(checkedProducts?.map((p) => p?.id));
      setCheckState([]);
      return;
    }

    setExcludeIds([]);
    if (flatList?.length === productsState.countWithVariations) {
      setAllowSelectByDefault(false);
      return setCheckState(flatList);
    }

    if (
      isEditCustomer &&
      availableAssignedProducts?.length === productsState.countWithVariations
    )
      return setCheckState(availableAssignedProducts);

    handleFetchProducts({ page: 1, fetchAll: true, unselectAll: true });
  };

  const setSearchInput = (value) =>
    setProductParams((prev) => ({ ...prev, search: value }));

  const handleProductsChecked = (product) => {
    const disabled = handleProductsDisable(product);

    if (disabled) return false;

    if (product.isMultiple) {
      const filteredChildren = product?.childProducts?.filter(
        (childProduct) =>
          !addedProducts?.some(
            (prod) =>
              prod?.id === childProduct?.id ||
              prod?.product?.id === childProduct.id
          )
      );

      return containsEvery(filteredChildren, checkedProducts);
    }
    return checkedProducts.some(
      (checkedProduct) => checkedProduct?.id === product?.id
    );
  };

  const paramsRef = useRef(null);

  const handleFetchProducts = useCallback(
    async ({ page, fetchAll = false }) => {
      if (!!specificManufacturer && !filterFields.manufacturer)
        return setLoading(false);

      if (!page || fetchAll) {
        setLoading(true);
      }

      try {
        const preparedData = {
          ...productParams,
          manufacturer_id:
            specificManufacturer?.id || filterFields?.manufacturer_id?.value,
          category_id:
            filterFields?.category_id?.value === UNCATEGORIZED_CATEGORY.id
              ? "null"
              : filterFields?.category_id?.value,
          tag_ids: filterFields?.tag_ids?.value
            ? JSON.stringify([filterFields?.tag_ids?.value])
            : null,
          include_price_list_id: priceListId ? priceListId : null,
          status: showInactive
            ? PRODUCTS_STATUSES.INACTIVE
            : PRODUCTS_STATUSES.ACTIVE,
          ...fetchQuery,
        };

        if (specificManufacturer?.id)
          preparedData.manufacturer_id = specificManufacturer?.id;

        paramsRef.current = preparedData;

        const res = await getProductsService({
          ...preparedData,
          ...(fetchAll
            ? { page: null, limit: null }
            : { page: page ? page : 1 }),
        });

        if (
          paramsRef &&
          paramsRef.current &&
          !isEqual(paramsRef.current, preparedData)
        )
          return;

        const rows = res?.rows || [];
        const count = res?.count || 0;
        const countWithVariations = res?.countWithVariations || 0;
        const list = fetchAll
          ? rows
          : page > 1
          ? [...productsState.list, ...rows]
          : rows;

        setStateFunc((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count,
          countWithVariations,
        }));
        setLoading(false);

        setProductParams((prev) => ({ ...prev, page: page ? page : 1 }));

        if (fetchAll)
          return handleFlatAndSetState({ list: rows, isFetchAll: true });

        if ((allCheckedByDefault && !isEditCustomer) || allowSelectByDefault) {
          if (isAllUnchecked || (isAllUncheckedProducts && !page)) {
            //handleFlatAndSetState({ list, setExclude: true });
            return;
          }
          handleFlatAndSetState({ list });
        }
      } catch (err) {
        error(err?.response?.data?.message || "Something went wrong");
      }
    },
    [
      allCheckedByDefault,
      allowSelectByDefault,
      fetchQuery,
      filterFields?.category_id?.value,
      filterFields.manufacturer,
      filterFields?.manufacturer_id?.value,
      filterFields?.tag_ids?.value,
      handleFlatAndSetState,
      isAllUnchecked,
      isAllUncheckedProducts,
      isEditCustomer,
      priceListId,
      productParams,
      productsState.list,
      setLoading,
      setStateFunc,
      showInactive,
      specificManufacturer,
    ]
  );

  useEffect(() => {
    if (isOpen) {
      handleFetchProducts({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputDebounced, filterFields, isOpen, showInactive]);

  useEffect(() => {
    setExpandedParentId(null);
  }, [isOpen]);

  const onSave = () => {
    const selectedCount = isAllUnchecked
      ? checkedProducts.length
      : excludeIds.length
      ? productsState.countWithVariations - excludeIds.length
      : null;

    const editSelectedCount =
      checkedProducts.length == 0 ? 0 : checkedProducts.length;

    const showAllProducts = isEditCustomer
      ? checkedProducts.length === productsState.countWithVariations ||
        allowSelectByDefault
      : !excludeIds.length;

    return handleAddProducts({
      productExcludeIds: excludeIds,
      checkedProducts,
      selectedCount: isEditCustomer ? editSelectedCount : selectedCount,
      showAllProducts,
      isAllUnchecked,
      listCount: productsState.countWithVariations,
      inactiveProductsState: productsInactiveState,
      activeProductsState: productsActiveState,
      isEdit: isEditCustomer,
    });
  };

  const allProductsAreChecked = useMemo(
    () =>
      allCheckedByDefault
        ? !!checkedProducts?.length &&
          checkedProducts?.length >= flatList?.length
        : !!checkedProducts?.length &&
          checkedProducts?.length >= productsState?.countWithVariations,
    [
      allCheckedByDefault,
      checkedProducts?.length,
      flatList?.length,
      productsState?.countWithVariations,
    ]
  );

  const isIndeterminate = useMemo(
    () =>
      allCheckedByDefault
        ? !!checkedProducts?.length &&
          checkedProducts?.length < flatList?.length
        : !!checkedProducts?.length &&
          checkedProducts?.length < productsState?.countWithVariations,
    [
      allCheckedByDefault,
      checkedProducts?.length,
      flatList?.length,
      productsState?.countWithVariations,
    ]
  );

  const onCancel = () => {
    handleCancelProducts();

    if (allCheckedByDefault) {
      setCheckState(availableAssignedProducts);
    }
  };

  return {
    productsList: productsState?.list,
    productsCount: productsState?.count,
    productsLoading: productsState?.loading,
    productsWithVariations: productsState?.countWithVariations,
    handleFetchProducts,
    page: productParams?.page,
    expandedParentId,
    setExpandedParentId,
    currentProductRef,
    productsRefs,
    textProduct,
    flatList,
    handleSelectAllProducts,
    handleDisableIfChosenProduct,
    productsAvailableLessThanMinimum,
    calcProductOutOfStockForParent,
    handleProductsChecked,
    setCurrentProductRef,
    setTextProduct,
    supplierWithOnlyOneProductWithVariations,
    handleProductsDisable,
    areAllChildProductsDisabled,
    calcAvailable,
    filterMenuOpen,
    handleApplyFilter,
    filterAnchor,
    setFilterMenuOpen,
    filterChipKeys,
    filterFields,
    setSearchInput,
    switches,
    setFilterFields,
    getChipLabel,
    setSwitches,
    searchInput: productParams.search,
    handleDeleteFilter,
    onSave,
    allProductsAreChecked,
    isIndeterminate,
    onCancel,
  };
};
