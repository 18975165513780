import { array, func, object } from "prop-types";
import {
  AccordionComponent,
  DetailsComponent,
  LargeCheckbox,
  SummaryComponent,
} from "components";
import { SETTING_ICONS } from "components/NotificationCenter/NotificationCenter.constants";
import { IosArrowForward } from "components/Icons";

import { cl } from "./SettingsBlock.styles";

import { Badge, Box, CircularProgress, Stack, Typography } from "@mui/material";

export const SettingsBlock = ({
  preparedSettingsData,
  onUserSettingsChange,
  userSettings,
  onCheckForDefaultSettings,
}) => {
  const { loading } = userSettings || {};

  return (
    <Box>
      {loading && (
        <Stack minHeight="58px" alignItems="center" justifyContent="center">
          <CircularProgress size={24} />
        </Stack>
      )}

      {!loading &&
        !!preparedSettingsData.length &&
        preparedSettingsData?.map((item) => {
          const { name, id } = item;

          const Icon = SETTING_ICONS[item?.id]?.icon;
          const iconProps = SETTING_ICONS[item?.id]?.iconProps;
          const isDefaultStateChanged = onCheckForDefaultSettings(id);

          return (
            <AccordionComponent
              key={name}
              item={item}
              expandedDflt={false}
              sx={cl.accordionComponent}
            >
              <SummaryComponent
                item={item}
                expandIcon={<IosArrowForward height={10} />}
              >
                <Stack width="20px" alignItems="center">
                  <Badge
                    variant="dot"
                    color="primary"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    sx={{
                      "& .MuiBadge-badge": {
                        opacity: isDefaultStateChanged ? 1 : 0,
                      },
                    }}
                    badgeContent=""
                  >
                    <Icon {...iconProps} />
                  </Badge>
                </Stack>
                <Typography variant="body2" color="black" ml="17px">
                  {name}
                </Typography>
              </SummaryComponent>

              <DetailsComponent sx={cl.detailsComponent}>
                {item?.children?.map((c) => (
                  <Box key={c?.name}>
                    <LargeCheckbox
                      key={c?.name}
                      size={16}
                      formSx={{ m: 0 }}
                      label={
                        <Typography
                          fontSize={13}
                          fontWeight={400}
                          color="#565656"
                        >
                          {c?.name}
                        </Typography>
                      }
                      checked={c?.value}
                      onChange={() => {
                        onUserSettingsChange({
                          type: id,
                          name: c.id,
                          value: !c?.value,
                        });
                      }}
                    />
                  </Box>
                ))}
              </DetailsComponent>
            </AccordionComponent>
          );
        })}
    </Box>
  );
};

SettingsBlock.propTypes = {
  preparedSettingsData: array,
  onUserSettingsChange: func,
  onCheckForDefaultSettings: func,
  userSettings: object,
};
