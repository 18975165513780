import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateUserFieldAction } from "redux/actions/auth";
import { setStoreDataService } from "services/order-directs";
import { error } from "utils/notifications";

export const useStorefront = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleSwitch = async (type, value, data = {}) => {
    try {
      setLoading(true);
      await setStoreDataService({
        [type]: value,
        ...data,
      });
      dispatch(updateUserFieldAction(type, value));

      if (data)
        Object.keys(data).forEach((key) => {
          dispatch(updateUserFieldAction(key, data[key]));
        });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return [loading, handleSwitch];
};
