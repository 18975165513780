import { useCallback, useEffect, useMemo, useState } from "react";
import { object, bool } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { createSelector } from "reselect";
import { isEqual } from "lodash";
import { Grid } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment-timezone";
import { currentUserSelector } from "redux/selectors/auth";
import {
  setEditOrder,
  updateDraftOrderAction,
  updateOrderAction,
} from "redux/actions/orders";
import { validationSchema } from "./OrdersBottomInfoBar.validation";
import PaymentDetails from "./components/PaymentDetails";
import Activity from "./components/Activity/Activity";
import OrderNote from "./components/OrderNote";
import DeliveryFeeDialog from "../../components/DeliveryFeeDialog/DeliveryFeeDialog";
import { error, success } from "utils/notifications";
import { updateOrderService } from "services/orders";
import { DRAFT_ORDER_TYPES } from "utils/constants";
import { updateDraftOrderService } from "services/draft_orders";
import { useTagsActions } from "helpers/useTagsActions";
import { useDebounce } from "helpers/hooks";
import { confirmDialogFormChangedSelector } from "redux/selectors/confirmDialogs";
import {
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { DEBOUNCED_ORDER_TAGS_MILLISECONDS } from "./OrdersBottomInfoBar.constants";

const selector = createSelector(
  currentUserSelector,
  confirmDialogFormChangedSelector,
  (currentUser, formChanged) => ({
    currentUser,
    formChanged,
  })
);

const OrdersBottomInfoBar = ({
  order,
  repPermissions,
  isThirdParty,
  isThirdPartyDraft,
}) => {
  const isOrderDraft = [
    DRAFT_ORDER_TYPES.open,
    DRAFT_ORDER_TYPES.closed,
  ].includes(order?.status);

  const dispatch = useDispatch();

  const { currentUser, formChanged } = useSelector(selector);

  const { automaticFulfillByDate, automaticFulfillByDateAmount } =
    currentUser || {};

  const {
    list: tagsList,
    count: orderTagsCount,
    handleFetchTags,
    page: tagsPage,
  } = useTagsActions({ type: "order" });

  const [isDeliveryFeeDialogShow, setIsDeliveryFeeDialogShow] = useState(false);

  const [firstRender, setFirstRender] = useState(false);

  const handleCloseDeliveryFeeDialog = () => {
    setIsDeliveryFeeDialogShow(false);
  };

  const {
    control,
    reset,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      id: 0,
      customId: "",
      orderNote: "",
      manualDeliveryFee: false,
      deliveryFee: "",
      orderTags: [],
      fulfillBy: "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });
  const { orderTags, fulfillBy } = formField || {};

  const tags = useMemo(
    () => (order?.tags?.length ? order.tags.map((t) => t?.tag) : []),
    [order?.tags]
  );

  const isoDate = useMemo(
    () =>
      moment()
        .tz(currentUser?.timeZone)
        // .endOf("day")
        .add(automaticFulfillByDateAmount || 0, "day")
        .format("YYYY-MM-DD"),
    [automaticFulfillByDateAmount, currentUser?.timeZone]
  );

  const setFulFillBy = useMemo(() => {
    const orderFulfillByFormatted = moment(order?.fulfillBy)
      .tz(currentUser?.timeZone)
      .format("YYYY-MM-DD");

    if (isOrderDraft) {
      // if (orderFulfillByFormatted >= isoDate) {
      //   return moment(order?.fulfillBy)
      //     .tz(currentUser?.timeZone)
      //     .format("YYYY-MM-DD");
      // }

      return automaticFulfillByDate ? isoDate : "";
    }

    return order?.fulfillBy ? orderFulfillByFormatted : "";
  }, [
    automaticFulfillByDate,
    currentUser?.timeZone,
    isOrderDraft,
    isoDate,
    order?.fulfillBy,
  ]);

  useEffect(() => {
    reset({
      ...formField,
      id: order?.id,
      customId: order?.customId?.customId,
      orderNote: order?.note?.text,
      manualDeliveryFee: order?.manualDeliveryFee,
      deliveryFee: order?.deliveryFee,
      tagIds: tags?.length ? tags.map((t) => t?.id) : [],
      orderTags: tags,
      fulfillBy: setFulFillBy,
    });
    if (order?.id) setFirstRender(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, reset, isDeliveryFeeDialogShow]);

  const handleSaveNote = useCallback(() => {
    if (isOrderDraft) {
      return dispatch(
        updateDraftOrderAction({
          id: order?.id,
          data: { note: { text: formField?.orderNote.trim() } },
        })
      );
    }

    dispatch(
      updateOrderAction({
        id: order?.id,
        data: { note: { text: formField.orderNote.trim() } },
      })
    );
  }, [dispatch, formField?.orderNote, isOrderDraft, order?.id]);

  const handleUpdateDeliveryFee = () => {
    if (Object.keys(errors).length) return;

    const preparedData = {
      id: order?.id,
      data: { manualDeliveryFee: false },
    };

    if (formField.manualDeliveryFee) {
      preparedData.data.manualDeliveryFee = true;
      preparedData.data.deliveryFee = Number(formField.deliveryFee);
    }

    if (isOrderDraft) {
      dispatch(updateDraftOrderAction(preparedData));
    } else {
      dispatch(updateOrderAction(preparedData));
    }
    handleCloseDeliveryFeeDialog();
  };

  const [savingTags, setSavingTags] = useState(false);

  const handleSaveTags = useCallback(async () => {
    const preparedData = {};

    const tagIds = [];
    const newTags = [];

    formField?.orderTags.forEach((tag) => {
      return tag?.newTag
        ? newTags.push(tag?.tag)
        : tagIds.push(tag?.id || tag?.tag?.id);
    });

    preparedData.tagIds = tagIds;
    if (newTags?.length) preparedData.newTags = newTags;

    try {
      setFirstRender(false);
      setSavingTags(true);
      const getService = (id, data) =>
        isOrderDraft
          ? updateDraftOrderService({ id, data })
          : updateOrderService(id, data);

      const res = await getService(order?.id, preparedData);

      const tags = res?.tags?.length ? res.tags.map((t) => t?.tag) : [];

      reset({
        ...formField,
        tagIds: tags?.length ? tags.map((t) => t?.id) : [],
        orderTags: tags,
      });
      setFirstRender(true);

      dispatch(setEditOrder(res));

      success("Tag updated");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setSavingTags(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField?.tagIds, order?.id]);

  const debouncedSaveTags = useDebounce(
    formField?.tagIds,
    DEBOUNCED_ORDER_TAGS_MILLISECONDS
  );

  useEffect(() => {
    if (firstRender && !isEqual(tags, orderTags)) {
      handleSaveTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSaveTags]);

  const debounceFulfillBy = useDebounce(formField?.fulfillBy, 700);

  useEffect(() => {
    if (isThirdParty) return;

    const orderFulfillBy = order?.fulfillBy
      ? moment(order?.fulfillBy).tz(currentUser?.timeZone).format("YYYY-MM-DD")
      : "";

    const formFulfillBy = fulfillBy || "";

    const isValidFormattedDate = moment(formFulfillBy).isValid();

    if (
      firstRender &&
      !isEqual(orderFulfillBy, formFulfillBy) &&
      isValidFormattedDate
    ) {
      if (isOrderDraft) {
        if (isThirdParty || isThirdPartyDraft) return;

        dispatch(
          updateDraftOrderAction({
            id: order?.id,
            data: { fulfillBy: fulfillBy || null },
          })
        );
      } else {
        dispatch(
          updateOrderAction({
            id: order?.id,
            data: { fulfillBy: fulfillBy || null },
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceFulfillBy]);

  useEffect(() => {
    setValue(
      "tagIds",
      formField?.orderTags?.length
        ? formField.orderTags?.map((t) => (typeof t === "string" ? t : t?.id))
        : []
    );
  }, [formField?.orderTags, setValue]);

  useEffect(() => {
    dispatch(setFormChangedAction(!!dirtyFields?.orderNote));
    dispatch(setEditTypeAction("order_note", true));
  }, [dirtyFields?.orderNote, dispatch, formChanged, handleSaveNote]);

  return (
    <>
      <DeliveryFeeDialog
        currentDeliveryFee={order?.deliveryFee || 0}
        orderTotal={`${currentUser?.deliveryFeeAmount}${
          currentUser?.deliveryFeeType === "PERCENTAGE" ? "%" : "$"
        }`}
        open={isDeliveryFeeDialogShow}
        onClose={handleCloseDeliveryFeeDialog}
        handleUpdateDeliveryFee={handleUpdateDeliveryFee}
        control={control}
        setValue={setValue}
        errors={errors}
      />
      <Grid mt={2} columnSpacing={1} container>
        <OrderNote
          {...{
            order,
            currentUser,
            control,
            handleSaveNote,
            tagsList,
            orderTagsCount,
            handleFetchTags,
            tagsPage,
            setValue,
            errors,
            setError,
            clearErrors,
            repPermissions,
            savingTags,
          }}
        />
        <Activity order={order} />
        <PaymentDetails
          {...{ order, repPermissions, isThirdParty, isThirdPartyDraft }}
          handleOpenDeliveryFeeDialog={() => setIsDeliveryFeeDialogShow(true)}
        />
      </Grid>
    </>
  );
};

OrdersBottomInfoBar.propTypes = {
  order: object,
  repPermissions: object,
  isThirdParty: bool,
  isThirdPartyDraft: bool,
};

OrdersBottomInfoBar.defaultProps = {
  order: null,
};

export default OrdersBottomInfoBar;
