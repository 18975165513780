import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { object, string } from "prop-types";

import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { DangerIcon } from "../../../../components/Icons";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import AcceptPaymentPopup from "./AcceptPaymentPopup.jsx";
import OrdersListHeader from "./OrdersListHeader.jsx";
import OrdersListItem from "./OrdersListItem.jsx";
import StyledButton from "../../../../components/StyledButton";

import { error, success } from "../../../../utils/notifications.js";

import {
  deletePaymentsService,
  deleteRefundService,
} from "../../../../services/orders.js";
import { getOrderByIdAction } from "../../../../redux/actions/orders.js";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "../../../../redux/actions/confirmDialogs.js";
import { cl } from "./OrdersPaymentDeliveryBar.styles";
import {
  useAdmin,
  normalizeSnakeCaseString,
  separateNumWithComma,
  getFormattedDate,
} from "../../../../helpers/helpers";
import moment from "moment";
import { getPaymentDue } from "Pages/OrdersPage/Orders.helper";
import { ActionRestrictionWrapper } from "components";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

const OrdersPaymentDeliveryBar = ({
  order,
  title,
  status,
  timeZone,
  repPermissions,
}) => {
  const isAdmin = useAdmin();
  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  const [editPayment, setEditPayment] = useState({});
  const [viewPayment, setViewPayment] = useState({});
  const [isOpenBodyComponent, setIsOpenBodyComponent] = useState(false);
  const [isAcceptPaymentPopupOpen, setIsAcceptPaymentPopupOpen] =
    useState(false);

  const handleCloseAcceptPaymentPopup = () => {
    setIsAcceptPaymentPopupOpen(false);
  };

  const hideDueDate = useMemo(
    () =>
      order?.orderStatus === "CANCELED" ||
      order?.paymentStatus === "PAID" ||
      !order?.paymentTermsDuplicate ||
      order?.deliveryStatus !== "FULFILLED",
    [
      order?.deliveryStatus,
      order?.orderStatus,
      order?.paymentStatus,
      order?.paymentTermsDuplicate,
    ]
  );

  const dueData = useMemo(() => {
    return hideDueDate
      ? false
      : getPaymentDue({
          fulfilledAt: order?.fulfilledAt || order?.shippedAt,
          daysInvoices: order?.paymentTermsDuplicate?.daysInvoices,
        });
  }, [
    hideDueDate,
    order?.fulfilledAt,
    order?.paymentTermsDuplicate?.daysInvoices,
    order?.shippedAt,
  ]);

  const finalDate =
    moment(getFormattedDate(dueData?.dueDate, timeZone))?.format(
      "MMM DD, YY"
    ) || "";

  // const finalDate = moment(
  //   moment(getFormattedDate(order?.createdAt, timeZone)).format("MMM D, YY")
  // )
  //   .add(order?.paymentTermsDuplicate?.daysInvoices, "days")
  //   .format("MMM D, YY");

  useEffect(() => {
    let paymentsListWithType = [];
    let refundsListWithType = [];

    if (order?.orderPayments?.length) {
      paymentsListWithType = order?.orderPayments?.map((order) => ({
        ...order,
        type: "Payment",
      }));
    }
    if (order?.orderRefunds?.length) {
      refundsListWithType = order?.orderRefunds?.map((order) => ({
        ...order,
        type: "Refund",
      }));
    }

    const sortedList = [...paymentsListWithType, ...refundsListWithType];

    sortedList?.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA - dateB;
    });

    setList(sortedList);
  }, [order]);

  const handleClickEdit = (item) => {
    setIsAcceptPaymentPopupOpen(false);
    setViewPayment({});
    setEditPayment({});
    if (item?.type === "Payment") {
      setEditPayment({ ...item, titlePopUp: "Edit payment" });
    }
    if (item?.type === "Refund") {
      setEditPayment({ ...item, titlePopUp: "Edit refund" });
    }
    setIsAcceptPaymentPopupOpen(true);
  };

  const handleViewDetails = (item) => {
    setIsAcceptPaymentPopupOpen(false);
    setViewPayment({});
    setEditPayment({});
    if (item?.type === "Payment") {
      setViewPayment({ ...item, titlePopUp: "View payment" });
    }
    if (item?.type === "Refund") {
      setViewPayment({ ...item, titlePopUp: "View refund" });
    }
    setIsAcceptPaymentPopupOpen(true);
  };

  const handleEditDetails = (item) => {
    setIsAcceptPaymentPopupOpen(false);
    setViewPayment({});
    setEditPayment({});
    if (item?.type === "Payment") {
      setEditPayment({ ...item, titlePopUp: "Edit payment" });
    }
    if (item?.type === "Refund") {
      setEditPayment({ ...item, titlePopUp: "Edit refund" });
    }
    setIsAcceptPaymentPopupOpen(true);
  };

  useEffect(() => {
    if (order?.orderPayments?.length === 0) setIsOpenBodyComponent(false);
  }, [order?.orderPayments?.length]);

  const handleDeleteRefund = (idRefund) => {
    dispatch(setConfirmIsOpenAction(false));
    deleteRefundService(idRefund)
      .then(() => {
        dispatch(getOrderByIdAction(order?.id));
        success("Refund deleted");
      })
      .catch(() => error("Something went wrong"))
      .finally(() => {
        setIsAcceptPaymentPopupOpen(false);
        setViewPayment({});
      });
  };

  const handleDeletePayment = (idPayment) => {
    dispatch(setConfirmIsOpenAction(false));
    deletePaymentsService(idPayment)
      .then(() => {
        dispatch(getOrderByIdAction(order?.id));
        success("Payment deleted");
      })
      .catch(() => error("Something went wrong"))
      .finally(() => {
        setIsAcceptPaymentPopupOpen(false);
        setViewPayment({});
      });
  };

  const handleDeleteConfirmDialog = (id, type) => {
    dispatch(
      openConfirmDialogAction({
        title: (
          <Box display="flex" alignItems="center">
            <DangerIcon />
            <span style={{ marginLeft: "11px" }}>Delete {type}?</span>
          </Box>
        ),
        text: `Are you sure you want to delete this ${type}?\nThis cannot be undone.`,
        buttons: (
          <>
            <StyledButton
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              }}
              onClick={() => dispatch(setConfirmIsOpenAction(false))}
              label="Cancel"
              variant="outlined"
            />

            <StyledButton
              sx={{
                width: "98px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              onClick={() => {
                type === "Payment"
                  ? handleDeletePayment(id)
                  : handleDeleteRefund(id);
              }}
              variant="contained"
              label="Delete"
            />
          </>
        ),
      })
    );
  };

  const calcPaymentSum = useMemo(() => {
    if (!order?.orderPayments?.length) return 0;
    const paymentSum = order?.orderPayments?.reduce(
      (acc, cur) => (acc += cur?.amount),
      0
    );
    const refundSum = order?.orderRefunds?.reduce(
      (acc, cur) => (acc += cur?.amount),
      0
    );
    return paymentSum - refundSum;
  }, [order?.orderPayments, order?.orderRefunds]);

  const getTitle = useMemo(() => {
    const status = order?.paymentStatus;
    if (status === "PENDING") return "Unpaid";
    return normalizeSnakeCaseString(status);
  }, [order?.paymentStatus]);

  const handleShowDropDown = () => {
    if (order.paymentStatus !== "PENDING" && calcPaymentSum !== 0) {
      setIsOpenBodyComponent(!isOpenBodyComponent);
    } else if (order.paymentStatus === "REFUNDED") {
      setIsOpenBodyComponent(!isOpenBodyComponent);
    } else if (list.length) {
      setIsOpenBodyComponent(!isOpenBodyComponent);
    }
  };

  const disabledAcceptBtn = useMemo(() => {
    return repPermissions
      ? !repPermissions?.orders?.create_edit
      : isAdmin || (order.orderStatus === "CANCELED" && true);
  }, [isAdmin, order.orderStatus, repPermissions]);

  return (
    <>
      <AcceptPaymentPopup
        order={order}
        viewPayment={viewPayment}
        editPayment={editPayment}
        isOpen={isAcceptPaymentPopupOpen}
        handleClickEdit={(item) => handleClickEdit(item)}
        handleClose={handleCloseAcceptPaymentPopup}
        handleDelete={(id, type) => handleDeleteConfirmDialog(id, type)}
      />
      <Paper
        sx={{ border: "1px solid #D5D9D9", minHeight: "54px" }}
        elevation={0}
        variant="outlined"
      >
        <Grid
          sx={{
            justifyContent: "center",
            cursor:
              order.paymentStatus !== "PENDING" &&
              calcPaymentSum !== 0 &&
              "pointer",
          }}
          container
          onClick={() => handleShowDropDown()}
        >
          {/* header of the component */}
          <Grid
            sx={{
              height: "52px",
              borderBottom: isOpenBodyComponent && "1px solid #D5D9D9",
            }}
            item
            xs={12}
            container
          >
            <Grid
              sx={{
                ...cl.headerBox,
                justifyContent: "flex-start",
                paddingLeft: "27px",
              }}
              item
              xs={"auto"}
            >
              <Box
                sx={{
                  height: "11.25px",
                  width: "11.25px",
                  backgroundColor:
                    order.paymentStatus !== "PAID" ? "#F0C401" : "#47A06D",
                  borderRadius: "50%",
                  mr: 3,
                }}
              />
              <Typography sx={cl.nameText}>
                {calcPaymentSum === 0 && status === "PAID"
                  ? "Payment not required"
                  : getTitle}
              </Typography>
              &nbsp;
              <Typography sx={cl.nameText} fontWeight="300">
                {calcPaymentSum
                  ? `($${separateNumWithComma(calcPaymentSum)})`
                  : ""}
                {calcPaymentSum === 0 &&
                  status === "PAID" &&
                  "(Order with $0 balance)"}
              </Typography>
              {!hideDueDate && (
                <Box
                  sx={{
                    background: "#F6F6F6",
                    display: "flex",
                    justifyContent: "space-around",
                    padding: "4px 12.5px",
                    borderRadius: "5px",
                    ml: "20px",
                    // cursor: "pointer",
                  }}
                >
                  <Typography fontSize="clamp(10px, 0.8vw, 14px)">
                    Due: {finalDate}
                  </Typography>
                </Box>
              )}
            </Grid>

            <Grid sx={{ ...cl.headerBox, ml: "auto" }} item xs="auto">
              {status !== "PAID" && (
                <ActionRestrictionWrapper
                  disableHoverListener={
                    repPermissions
                      ? repPermissions?.orders?.create_edit
                      : !(
                          !!repPermissions &&
                          !repPermissions?.orders?.create_edit
                        )
                  }
                  title={
                    repPermissions?.role === "SALES"
                      ? ADMIN_ONLY_VIEW_MESSAGE
                      : ADMIN_ONLY_VIEW_MESSAGE_PAGE
                  }
                >
                  <StyledButton
                    sx={{
                      minWidth: "98px",
                      height: 28,
                      borderColor: "#47A06D",
                    }}
                    color="primary"
                    variant="outlined"
                    disabled={disabledAcceptBtn}
                    label="Accept Payment"
                    onClick={(e) => {
                      e.stopPropagation();
                      setViewPayment({});
                      setEditPayment({});
                      setIsAcceptPaymentPopupOpen(true);
                    }}
                  />
                </ActionRestrictionWrapper>
              )}
            </Grid>
            <Grid
              sx={cl.headerBox}
              item
              xs={
                ["PARTIALLY_PAID", "PAID", "REFUNDED", "PENDING"].includes(
                  status
                ) && list.length > 0
                  ? 0.9
                  : 0.36
              }
              color="#707070"
            >
              {["PARTIALLY_PAID", "PAID", "REFUNDED", "PENDING"].includes(
                status
              ) &&
                list.length > 0 && (
                  <IconButton
                    sx={{ p: "3px" }}
                    onClick={() => handleShowDropDown()}
                  >
                    {isOpenBodyComponent ? (
                      <KeyboardArrowUp
                        sx={{ fontSize: 34, color: "#47A06D" }}
                      />
                    ) : (
                      <KeyboardArrowDown sx={{ fontSize: 34 }} />
                    )}
                  </IconButton>
                )}
            </Grid>
          </Grid>
          <Grid item xs={11.34}>
            <Collapse in={isOpenBodyComponent}>
              <Grid
                sx={{
                  border: "1px solid #D5D9D9",
                  marginTop: "12px",
                  marginBottom: "18px",
                  borderRadius: "4px",
                }}
                container
                direction="column"
              >
                <OrdersListHeader title={title} />
                {list.map((payment) => (
                  <OrdersListItem
                    order={order}
                    key={payment.id}
                    type={payment?.type}
                    item={payment}
                    handleViewDetails={handleViewDetails}
                    handleEditDetails={handleEditDetails}
                    handleDeleteDelivery={(id) =>
                      handleDeleteConfirmDialog(id, payment?.type)
                    }
                    repPermissions={repPermissions}
                  />
                ))}
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

OrdersPaymentDeliveryBar.propTypes = {
  order: object,
  title: string,
  infoStatus: string,
  status: string,
  timeZone: string,
  repPermissions: object,
};

OrdersPaymentDeliveryBar.defaultProps = {
  order: null,
  title: "Title",
  infoStatus: "",
  status: "",
};

export default OrdersPaymentDeliveryBar;
