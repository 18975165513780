import { useCallback, useEffect, useMemo, useState } from "react";
import { containsEvery } from "./helpers";

const DEFAULT_ACTIVE_STATE = {
  availableProducts: [],
  tempCheckedProducts: [],
  changedIds: [],
  isAllUnchecked: true,
};

const DEFAULT_INACTIVE_STATE = {
  availableProducts: [],
  tempCheckedProducts: [],
  changedIds: [],
  isAllUnchecked: true,
};

export const useCategoryProductsWithTabs = ({
  productsList,
  inactiveProductsList,
  onSaveProductsFromPopup,
  onConfirmProductsSelect,
}) => {
  const [openAllProducts, setOpenAllProducts] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  const [productsActiveState, setProductsActiveState] =
    useState(DEFAULT_ACTIVE_STATE);

  const [productsInactiveState, setProductsInactiveState] = useState(
    DEFAULT_INACTIVE_STATE
  );

  const setStateFunc = useMemo(
    () => (showInactive ? setProductsInactiveState : setProductsActiveState),
    [showInactive]
  );

  const productsState = useMemo(
    () => (showInactive ? productsInactiveState : productsActiveState),
    [productsActiveState, productsInactiveState, showInactive]
  );

  const currentProductsList = useMemo(
    () => (showInactive ? inactiveProductsList : productsList),
    [inactiveProductsList, productsList, showInactive]
  );

  const isAllUnchecked = useMemo(
    () =>
      showInactive
        ? productsInactiveState.isAllUnchecked
        : productsActiveState.isAllUnchecked,
    [
      productsActiveState.isAllUnchecked,
      productsInactiveState.isAllUnchecked,
      showInactive,
    ]
  );

  const onProductsStateUpdate = useCallback(
    (data) => setStateFunc((prev) => ({ ...prev, ...data })),
    [setStateFunc]
  );

  const setIsAllUnchecked = useCallback(
    (isAllUnchecked) => setStateFunc((prev) => ({ ...prev, isAllUnchecked })),
    [setStateFunc]
  );

  const onTempProductSelect = useCallback(
    (product) => {
      if (product.isMultiple) {
        const filteredChildren = product.childProducts.filter(
          (childProduct) =>
            !currentProductsList?.some((prod) => prod.id === childProduct.id)
        );
        if (
          containsEvery(filteredChildren, productsState.tempCheckedProducts)
        ) {
          const newChecked = [...productsState.tempCheckedProducts];
          product.childProducts.forEach((childProduct) => {
            const index = newChecked.findIndex(
              (checkedProduct) => checkedProduct.id === childProduct.id
            );
            newChecked.splice(index, 1);
          });

          const updatedExcludeIds = [
            ...productsState.changedIds,
            ...product.childProducts.map((child) => child.id),
          ];
          onProductsStateUpdate({
            changedIds: [...new Set(updatedExcludeIds)],
          });
          return onProductsStateUpdate({
            tempCheckedProducts: [...newChecked],
          });
        }

        const addProducts = filteredChildren.map((childProduct) => ({
          ...childProduct,
          quantity: 1,
          parentProduct: product,
        }));
        const uniqueProducts = [
          ...new Map(
            [...productsState.tempCheckedProducts, ...addProducts].map(
              (item) => [item["id"], item]
            )
          ).values(),
        ];

        const updatedExcludeIds = productsState.changedIds.filter(
          (id) => !addProducts.some((childProduct) => childProduct.id === id)
        );
        onProductsStateUpdate({ changedIds: updatedExcludeIds });
        return onProductsStateUpdate({ tempCheckedProducts: uniqueProducts });
      }

      const index = productsState.tempCheckedProducts.findIndex(
        (item) => item.id === product.id
      );

      if (index > -1) {
        onProductsStateUpdate({
          changedIds: [...new Set([...productsState.changedIds, product.id])],
        });

        const newProducts = productsState.tempCheckedProducts.filter(
          (item) => item.id !== product.id
        );

        return onProductsStateUpdate({ tempCheckedProducts: newProducts });
      }

      onProductsStateUpdate({
        changedIds: productsState.changedIds.filter((id) => id !== product.id),
      });
      onProductsStateUpdate({
        tempCheckedProducts: [...productsState.tempCheckedProducts, product],
      });
    },
    [
      productsState.tempCheckedProducts,
      onProductsStateUpdate,
      productsState.changedIds,
      currentProductsList,
    ]
  );

  const onResetProduct = useCallback(() => {
    setProductsInactiveState({
      ...DEFAULT_ACTIVE_STATE,
      tempCheckedProducts: inactiveProductsList,
    });
    setProductsActiveState({
      ...DEFAULT_ACTIVE_STATE,
      tempCheckedProducts: productsList,
    });
  }, [productsList, inactiveProductsList]);

  useEffect(() => {
    onResetProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsList, inactiveProductsList]);

  const checkForProductChange = () => {
    const allLists = [...productsList, ...inactiveProductsList];
    const allCheckedLists = [
      ...productsActiveState.tempCheckedProducts,
      ...productsInactiveState.tempCheckedProducts,
    ];
    if (
      (!allLists?.length && allCheckedLists?.length) ||
      (allLists?.length && !allCheckedLists?.length)
    )
      return true;
    if (!allCheckedLists?.length && !allLists?.length) return false;
    const tempProductsIds = allCheckedLists?.map(
      (product) => product?.product?.id || product?.id
    );
    const productsIds = allLists?.filter((product) =>
      tempProductsIds?.includes(product.id || product.product?.id)
    );
    return !!productsIds?.length;
  };

  const onProductsPopupOpen = () => {
    setOpenAllProducts(true);
  };

  const onCloseAllProducts = () => {
    setOpenAllProducts(false);
    setShowInactive(false);
    onResetProduct();
  };

  const handleAddProducts = (data) => {
    const { inactiveProductsState, activeProductsState, isEdit } = data || {};

    const selectedInactiveCount = productsInactiveState.isAllUnchecked
      ? productsInactiveState.tempCheckedProducts.length
      : productsInactiveState.changedIds.length
      ? inactiveProductsState.countWithVariations -
        productsInactiveState.changedIds.length
      : null;

    const editInactiveSelectedCount =
      productsInactiveState.tempCheckedProducts.length == 0
        ? 0
        : productsInactiveState.tempCheckedProducts.length;

    const showAllInactiveProducts = isEdit
      ? productsInactiveState.tempCheckedProducts.length ===
          productsInactiveState.countWithVariations || false
      : !productsInactiveState.changedIds.length;

    const selectedCount = productsActiveState.isAllUnchecked
      ? productsActiveState.tempCheckedProducts.length
      : productsActiveState.changedIds.length
      ? activeProductsState.countWithVariations -
        productsActiveState.changedIds.length
      : null;

    const editSelectedCount =
      productsActiveState.tempCheckedProducts.length == 0
        ? 0
        : productsActiveState.tempCheckedProducts.length;

    const showAllProducts = isEdit
      ? productsActiveState.tempCheckedProducts.length ===
          productsActiveState.countWithVariations || false
      : !productsActiveState.changedIds.length;

    const productsHaveBeenChanged = checkForProductChange();
    onCloseAllProducts();

    const preparedData = {
      changedInactiveIds: productsInactiveState.changedIds,
      checkedInactiveProducts: productsInactiveState.tempCheckedProducts,
      selectedInactiveCount: isEdit
        ? editInactiveSelectedCount
        : selectedInactiveCount,
      showAllInactiveProducts,
      isAllUncheckedInactiveProducts: productsInactiveState.isAllUnchecked,

      changedIds: productsActiveState.changedIds,
      selectedCount: isEdit ? editSelectedCount : selectedCount,
      checkedProducts: productsActiveState.tempCheckedProducts,
      showAllProducts,
      isAllUnchecked: productsActiveState.isAllUnchecked,
    };

    if (!productsHaveBeenChanged) {
      onSaveProductsFromPopup(preparedData);
      return;
    }

    return onConfirmProductsSelect(
      () => onSaveProductsFromPopup(preparedData),
      () => onResetProduct()
    );
  };

  return {
    ...productsState,
    onProductsPopupOpen,
    openAllProducts,
    onCloseAllProducts,
    onTempProductSelect,
    onProductsStateUpdate,
    isAllUnchecked,
    setIsAllUnchecked,
    checkForProductChange,
    showInactive,
    setShowInactive,
    handleAddProducts,
    onResetProduct,
  };
};
