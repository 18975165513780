import { SvgIcon } from "@mui/material";

// eslint-disable-next-line react/prop-types
export const PicIcon = ({ size = 14, color = "#A5A5A5", ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fill: "none", width: size, height: size }}
      {...props}
    >
      <path
        d="m1 9.7 2.327-2.327a1.8 1.8 0 0 1 2.546 0L7.3 8.8a1.273 1.273 0 0 0 1.8 0 1.273 1.273 0 0 1 1.8 0l1.8 1.8M5.8 1h2.4c2.235 0 3.394 0 4.097.703S13 2.95 13 5.8v2.4c0 2.263 0 3.394-.703 4.097S10.463 13 8.2 13H5.8c-2.263 0-3.394 0-4.097-.703S1 10.463 1 8.2V5.8c0-2.263 0-3.394.703-4.097S3.537 1 5.8 1Z"
        stroke={color}
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
