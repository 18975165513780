/* eslint-disable react/prop-types */
import * as React from "react";
import { SvgIcon } from "@mui/material";
export const InfoIcon = ({ width = 24, height = 24, sx = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ width, height, ...sx }}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M12 23.249c6.212 0 11.249-5.037 11.249-11.25S18.212.75 11.999.75.75 5.786.75 12c0 6.212 5.036 11.249 11.25 11.249"
        fill="#fff"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10.75v7.499"
        stroke="#121212"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <circle cx={11.999} cy={7} r={1.25} fill="#121212" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);
