import { useContext, useRef } from "react";
import { Controller } from "react-hook-form";
import { Box, MenuItem, Stack } from "@mui/material";
import { useDetailFields } from "./useDetailFields";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";
import { cl } from "./DetailFields.styles";
import { CloneProps, StyledTooltip } from "components";
import { onPasteTextWithMaxLength, useAdmin } from "helpers/helpers.js";

export const DetailFields = () => {
  const { control, formField, setValue, errors, trigger } =
    useContext(FormViewContext);

  const isAdmin = useAdmin();
  const errorMsgRef = useRef({});

  const { questions } = formField;

  const { FIELDS_DATA } = useDetailFields();

  const handleSetMandatoryFields = (e) => {
    if (!questions?.length) return;

    const name = e.target.name === "allRequired" ? "required" : e.target.name;
    const checked = e.target.checked;
    const tempQuestions = questions.map((q) => ({
      ...q,
      [name]: checked,
    }));
    setValue("questions", tempQuestions);
  };

  return (
    <Stack sx={cl.cardWrapper}>
      <Stack width="100%" flexWrap="nowrap" direction="row">
        {FIELDS_DATA.slice(0, 2).map(
          ({
            id,
            Input,
            isSelect,
            menuItems,
            name,
            maxCharacters,
            ...fieldData
          }) => {
            return isSelect ? (
              <Controller
                key={id}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...fieldData}
                    {...field}
                    disabled={isAdmin}
                    error={!!error || (name === "active" && !!errors?.title)}
                  >
                    {menuItems.map((status) => (
                      <MenuItem key={status.value} value={status.value}>
                        {status.label}
                      </MenuItem>
                    ))}
                  </Input>
                )}
                name={name}
                control={control}
              />
            ) : (
              <Controller
                key={id}
                render={({ field, fieldState: { error } }) => {
                  if (error?.message)
                    errorMsgRef.current[name] = error?.message;

                  return (
                    <CloneProps>
                      {(clonedProps) => (
                        <StyledTooltip
                          arrow
                          placement="left"
                          isError
                          title={errorMsgRef.current?.[name] || ""}
                          open={!!error}
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: { offset: [0, -5] },
                              },
                            ],
                          }}
                        >
                          <Box component="span" width="100%">
                            <Input
                              {...clonedProps}
                              {...fieldData}
                              {...field}
                              onChange={(e) => {
                                const val = e.target.value;

                                if (
                                  typeof maxCharacters === "number" &&
                                  val?.length <= maxCharacters + 1
                                ) {
                                  field.onChange(val);
                                  trigger([name]);
                                }
                              }}
                              onPaste={(e) => {
                                onPasteTextWithMaxLength({
                                  event: e,
                                  value: field.value,
                                  onChange: (v) => {
                                    field.onChange(v);
                                    trigger([name]);
                                  },
                                  maxCharacters,
                                });
                              }}
                              error={!!error}
                            />
                          </Box>
                        </StyledTooltip>
                      )}
                    </CloneProps>
                  );
                }}
                name={name}
                control={control}
              />
            );
          }
        )}
      </Stack>

      {FIELDS_DATA.slice(2, 3).map(
        ({ id, Input, name, maxCharacters, ...fieldData }) => {
          return (
            <Controller
              key={id}
              render={({ field, fieldState: { error } }) => {
                if (error?.message) errorMsgRef.current[name] = error?.message;

                return (
                  <CloneProps>
                    {(clonedProps) => (
                      <StyledTooltip
                        arrow
                        placement="left"
                        isError
                        title={errorMsgRef.current?.[name] || ""}
                        open={!!error}
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: { offset: [0, -5] },
                            },
                          ],
                        }}
                      >
                        <Box component="span" width="100%">
                          <Input
                            {...clonedProps}
                            {...fieldData}
                            {...field}
                            onChange={(e) => {
                              const val = e.target.value;
                              if (
                                typeof maxCharacters === "number" &&
                                val?.length <= maxCharacters + 1
                              ) {
                                field.onChange(val);
                                trigger([name]);
                              }
                            }}
                            onPaste={(e) => {
                              onPasteTextWithMaxLength({
                                event: e,
                                value: field.value,
                                onChange: (v) => {
                                  field.onChange(v);
                                  trigger([name]);
                                },
                                maxCharacters,
                              });
                            }}
                            error={!!error}
                          />
                        </Box>
                      </StyledTooltip>
                    )}
                  </CloneProps>
                );
              }}
              name={name}
              control={control}
            />
          );
        }
      )}

      <Stack width="100%" flexWrap="nowrap" direction="row" ml="8px">
        {FIELDS_DATA.slice(3, 5).map(
          ({ id, Input, name, tooltip, ...fieldData }) => {
            return (
              <Controller
                key={id}
                render={({ field }) => (
                  <StyledTooltip
                    arrow
                    placement="left"
                    isDark
                    title={tooltip?.title || ""}
                    disableHoverListener={!tooltip?.title}
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: { offset: [0, -5] },
                        },
                      ],
                    }}
                  >
                    <Box component="span">
                      <Input
                        checked={!!field.value}
                        {...fieldData}
                        {...field}
                        onClick={handleSetMandatoryFields}
                      />
                    </Box>
                  </StyledTooltip>
                )}
                name={name}
                control={control}
              />
            );
          }
        )}
      </Stack>
    </Stack>
  );
};
