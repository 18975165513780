import { bool, func, string } from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { applyForConfirmationService } from "services/account";
import { error } from "utils/notifications";

export const StepThree = ({ loading, onSubmit, setLoading, token }) => {
  const applyForConfirmation = useCallback(async () => {
    if (!token) return;
    try {
      setLoading(true);
      await applyForConfirmationService(token);
    } catch (err) {
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }, [setLoading, token]);

  useEffect(applyForConfirmation, [applyForConfirmation]);

  return (
    <Box mb="54px">
      <Box pt="10px" display="flex" flexDirection="column">
        <Box
          sx={{
            height: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <Typography fontSize={38} fontWeight={300} color="#545454">
            Thank you!
          </Typography>
        </Box>
        <Box
          sx={{
            height: "110px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography fontSize={25} fontWeight={600} color="#5F6267">
            Your account is now active
          </Typography>
        </Box>
        <Box>
          <Button
            disabled={loading}
            sx={{
              height: "48px",
              fontSize: 20,
              fontWeight: 500,
            }}
            fullWidth
            variant="contained"
            onClick={onSubmit}
          >
            {loading ? "Loading..." : "Finish Setting Up Account"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

StepThree.propTypes = {
  loading: bool,
  onSubmit: func,
  setLoading: func,
  token: string,
};
StepThree.defautProps = {
  loading: false,
  onSubmit: () => {},
  setLoading: () => {},
  token: "",
};
