import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./LicensesDrawer.validations";
import { LICENSES } from "utils/constants";

const defaultValues = {
  licenseName: LICENSES[0].name,
  licenseOtherName: "",
  licenseNumber: "",
  isExpirationDateChecked: false,
  isAddButtonHovered: false,
};

export const useLicensesDrawer = ({
  isOpen,
  editLicense,
  currentAttachment,
  setCurrentAttachment,
  setDialogState,
  initDate,
}) => {
  const hasAnyAttachment = useMemo(() => {
    return !!currentAttachment?.length;
  }, [currentAttachment]);

  const {
    control,
    setValue,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      date: initDate,
    },
    resolver: yupResolver(validationSchema({ hasAnyAttachment })),
  });

  const formField = useWatch({ control });
  const { licenseName, licenseOtherName, licenseNumber } = formField || {};

  const isLicenseNumberInvalid = !!errors?.licenseNumber || !licenseNumber;

  const tooltipMessage = useMemo(() => {
    if (!isOpen) return "";

    if (isLicenseNumberInvalid && !hasAnyAttachment) {
      if (licenseName === "Other" && !licenseOtherName) {
        return "License name, number or attachment are missing";
      }
      return "License number or attachment is missing";
    }

    if (licenseName === "Other" && !licenseOtherName) {
      return "License name is missing";
    }

    return "";
  }, [
    hasAnyAttachment,
    isLicenseNumberInvalid,
    isOpen,
    licenseName,
    licenseOtherName,
  ]);

  const isButtonDisabled = useMemo(() => {
    if (isLicenseNumberInvalid && !hasAnyAttachment) {
      return true;
    }
    if (licenseName === "Other" && !licenseOtherName) return true;

    return false;
  }, [isLicenseNumberInvalid, hasAnyAttachment, licenseName, licenseOtherName]);

  useEffect(() => {
    if (isOpen) {
      reset({
        ...defaultValues,
        licenseName: editLicense?.type
          ? LICENSES.find((l) => l.type === editLicense?.type)?.name
          : defaultValues.licenseName,
        licenseOtherName:
          editLicense?.type === "OTHER" ? editLicense?.name : "",
        licenseNumber:
          editLicense?.documentNumber || defaultValues.licenseNumber,
        isExpirationDateChecked: !!editLicense?.expirationDate,
        date: {
          isValid: editLicense
            ? !!editLicense?.expirationDate
            : initDate.isValid,
          value: editLicense ? editLicense?.expirationDate : initDate.value,
        },
      });

      if (editLicense?.attachments) {
        setCurrentAttachment(editLicense?.attachments);
      }
    }
    // eslint-disable-next-line
  }, [editLicense, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      reset({ ...defaultValues });
      if (setDialogState) setDialogState({ attachment: null });
      setCurrentAttachment([]);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    if (hasAnyAttachment) clearErrors("licenseNumber");
  }, [clearErrors, hasAnyAttachment]);

  return {
    formField,
    setValue,
    control,
    trigger,
    tooltipMessage,
    isButtonDisabled,
    isDirty,
    setError,
    clearErrors,
  };
};
