import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes, { string, array, func, bool, object } from "prop-types";
import lodash from "lodash";
import deepdash from "deepdash";
const _ = deepdash(lodash);

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { CrossBigIcon, ShevronIcon } from "../../../../../../components/Icons";
import TerritoryCustomersTab from "../TerritoryComponent/TerritoryCustomersTab";
import TerritoryRepresentativesTab from "../TerritoryComponent/TerritoryRepresentativesTab";
import { SearchSelect } from "../../../../../../components/Selects";
import { getCustomersByTerritoryService } from "../../../../../../services/territory";
import { transferCustomersToAnotherTerritory } from "../../../../../../services/customers";
import ConfirmDialog from "../../../../../../components/ConfirmDialog/ConfirmDialog";
import {
  getCustomersByTerritoryAction,
  getTerritoryListAction,
  getTerritoryTreeAction,
} from "../../../../../../redux/actions/territory";
import {
  FETCH_LIMITS,
  UNCATEGORIZED_TERRITORY,
} from "../../../../../../utils/constants";
import { getRepsService } from "../../../../../../services/reps";
import { error, success } from "../../../../../../utils/notifications";

const DEFAULT_REPS_STATE = {
  list: [],
  count: 0,
  loading: false,
  cursor: null,
};

export const TerritoryComponent = ({
  profile,
  territories,
  territoryTabs,
  isOpenTerritoryDialog,
  handleCloseTerritoryDialog,
}) => {
  const hasCustomer = useMemo(
    () => profile?._count?.customer > 0 || profile === "Uncategorized",
    [profile]
  );
  const hasRepresentatives = useMemo(
    () => profile?.representativeCount > 0 || profile === "Uncategorized",
    [profile]
  );

  const dispatch = useDispatch();
  const [repsState, setRepsState] = useState(DEFAULT_REPS_STATE);

  const [currentTerritoryTab, setCurrentTerritoryTab] = useState(
    territoryTabs[0]
  );
  const [isShowFooter, setIsShowFooter] = useState(false);
  const [customersList, setCustomersList] = useState();
  const [customersCount, setCustomersCount] = useState(null);

  const getCustomerList = () => {
    setCustomersList([]);
    if (profile?.id || profile === "Uncategorized") {
      getCustomersByTerritoryService(profile?.id || "")
        .then((res) => {
          res?.rows?.length
            ? setCustomersList(res?.rows)
            : setCustomersList([]);

          setCustomersCount(res.count);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err?.message);
        });
    }
  };

  const onRepsStateChange = (data) =>
    setRepsState((prev) => ({ ...prev, ...data }));

  const handleFetchReps = () => {
    onRepsStateChange({ loading: true });
    const fetchQuery = {
      limit: FETCH_LIMITS.FILTER_CUSTOMERS,
      cursor: repsState.cursor,
      territoryId: profile?.id,
    };
    getRepsService(fetchQuery)
      .then((res) => {
        setRepsState((prev) => ({
          ...prev,
          list: res.cursor ? [...prev.list, ...res.rows] : [...res.rows],
          count: res.count,
          loading: false,
          cursor: res.cursor ? res.cursor : null,
        }));
      })
      .catch((err) => {
        onRepsStateChange({ loading: false });
        error(err?.response?.data?.message);
      });
  };

  const handleClearTabs = () => {
    setCustomersList([]);
    setCurrentTerritoryTab(territoryTabs[0]);
    setCustomersCount(null);
    setIsShowFooter(false);
    setRepsState(DEFAULT_REPS_STATE);
  };

  useEffect(() => {
    if (!isOpenTerritoryDialog) handleClearTabs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenTerritoryDialog]);

  useEffect(() => {
    if (hasCustomer) {
      setCurrentTerritoryTab(territoryTabs[0]);
    } else {
      setCurrentTerritoryTab(territoryTabs[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCustomer]);

  useEffect(() => {
    if (isOpenTerritoryDialog) {
      getCustomerList();
      handleFetchReps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, profile?.id, isOpenTerritoryDialog]);

  useEffect(() => {
    if (!isOpenTerritoryDialog) setCheckedCustomers([]);
  }, [isOpenTerritoryDialog]);

  const [totalCustomers, setTotalCustomers] = useState(0);
  useEffect(() => {
    setTotalCustomers(customersList?.length);
  }, [customersList]);

  const [checkedCustomers, setCheckedCustomers] = useState([]);
  const [checkedCustomersIds, setCheckedCustomersIds] = useState([]);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    setCheckedCustomersIds(checkedCustomers.map((el) => el.id));
  }, [checkedCustomers]);

  const handlerCheckedCustomer = (customer, isChecked) => {
    if (isChecked) {
      setCheckedCustomers([...checkedCustomers, customer]);
    } else {
      setCheckedCustomers(
        checkedCustomers.filter((el) => el.id !== customer.id)
      );
    }
  };

  const handlerAllCheckedCustomer = () => {
    if (allChecked) {
      setCheckedCustomers([]);
    } else {
      setCheckedCustomers(customersList);
    }
  };

  useEffect(() => {
    if (checkedCustomers?.length === customersList?.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
    checkedCustomers?.length > 0 && currentTerritoryTab === "Customers"
      ? setIsShowFooter(true)
      : setIsShowFooter(false);
  }, [checkedCustomers, customersList?.length, currentTerritoryTab]);

  const handleChangeCurrentTerritoryTab = (e, tab) => {
    setCurrentTerritoryTab(tab);
  };

  const renderContent = (currentTab, tabs) => {
    switch (currentTab) {
      case tabs[0]:
        return (
          <TerritoryCustomersTab
            customers={customersList}
            customersCount={customersCount}
            handlerCheckedCustomer={handlerCheckedCustomer}
            allchecked={allChecked}
            handlerAllCheckedCustomer={handlerAllCheckedCustomer}
            checkedCustomers={checkedCustomers}
          />
        );
      case tabs[1]:
        return (
          <TerritoryRepresentativesTab
            repsList={repsState.list}
            repsLoading={repsState.loading}
            repsCount={repsState.count}
            handleFetchReps={handleFetchReps}
          />
        );
    }
  };

  const [selectedTerritory, setSelectedTerritory] = useState("");
  const [selectedTerritoryId, setSelectedTerritoryId] = useState("");

  const handleSelectedTerritory = (e) => {
    setSelectedTerritory(e.target.value);
  };
  const handleDelete = () => {
    setSelectedTerritory("");
  };

  const handleSetTerritory = async () => {
    setConfirmIsOpen(false);
    let newTer = {
      customersIds: checkedCustomersIds,
      newTerritoryId: selectedTerritoryId,
    };
    try {
      const checkedCustomersCount = checkedCustomersIds?.length;
      const curCustomersCount = customersCount;

      await transferCustomersToAnotherTerritory(newTer);
      success(`${checkedCustomersIds?.length} customers were updated`);
      getCustomerList();
      dispatch(getTerritoryTreeAction());
      dispatch(getTerritoryListAction());

      setCustomersList([]);
      setCheckedCustomers([]);
      setSelectedTerritory("");
      dispatch(getCustomersByTerritoryAction());
      if (curCustomersCount === checkedCustomersCount)
        handleCloseTerritoryDialog();
    } catch (err) {
      if (err?.response?.data) return error(err.response.data.message);
      error(err.message);
    }
  };

  const territoriesList = (arr) => {
    let newList = [];
    _.eachDeep(
      arr,
      (ter, i, parent, ctx) => {
        return newList.push(
          ter?.children?.length > 0
            ? { ...ter, isChildren: true, deep: ctx.depth }
            : { ...ter, isChildren: false, deep: ctx.depth }
        );
      },
      { childrenPath: "children" }
    );

    return newList?.filter((item) => item.name !== profile?.name);
  };

  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const FooterCustomers = () => {
    const dropDownList = territoriesList(territories);

    return (
      <Grid
        container
        sx={{
          borderTop: "1px solid #D4D4D4",
          height: "66px",
          paddingLeft: "38px",
          paddingRight: "24px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          item
          xs={6}
        >
          <Typography sx={{ fontSize: "17px" }} color="primary">
            Move {checkedCustomers?.length} selections
          </Typography>
        </Grid>
        <ConfirmDialog
          isOpen={confirmIsOpen}
          text={`This action will be applied to ${
            checkedCustomers?.length
          } customer(s). ${"\n"}Are you sure you want to proceed?`}
          onCancel={() => {
            setConfirmIsOpen(false);
          }}
          onAccept={handleSetTerritory}
        />
        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
          item
          xs={6}
        >
          <SearchSelect
            label="Choose another territory"
            formSx={{ width: "70%" }}
            value={selectedTerritory}
            onChange={handleSelectedTerritory}
            handleDelete={handleDelete}
            PaperPropsSx={{ maxWidth: "320px" }}
          >
            {profile !== UNCATEGORIZED_TERRITORY.name && (
              <MenuItem
                sx={{ pl: "51px" }}
                value={UNCATEGORIZED_TERRITORY.name}
                onClick={() => setSelectedTerritoryId(null)}
                className={classes.textPrimary}
              >
                <Typography>{UNCATEGORIZED_TERRITORY.name}</Typography>
              </MenuItem>
            )}
            {dropDownList?.map((ter) => (
              <MenuItem
                className={
                  ter.deep === 1 ? classes.textPrimary : classes.textSecondary
                }
                key={ter.id}
                value={ter.name}
                onClick={() => setSelectedTerritoryId(ter?.id)}
              >
                {ter.isChildren ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginRight: "12px",
                      width: ter.deep * 24 + "px",
                    }}
                  >
                    <ShevronIcon color="#707070" />
                  </Box>
                ) : (
                  <Box
                    sx={{ width: ter.deep * 24 + "px", marginRight: "12px" }}
                  />
                )}
                <Typography noWrap>{ter.name}</Typography>
              </MenuItem>
            ))}
          </SearchSelect>
          {selectedTerritory && (
            <Button
              sx={{
                height: "32px",
                marginLeft: "16px",
              }}
              variant="contained"
              onClick={() => setConfirmIsOpen(true)}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                }}
              >
                Transfer
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  const useStyles = makeStyles(() => ({
    tabs: {
      minHeight: "auto",
      "& .Mui-selected": {
        backgroundColor: "transparent!important",
      },
    },
    tab: {
      color: "#707070",
    },
    textPrimary: {
      "& .MuiTypography-root": {
        color: "#5F6267",
        fontSize: "17px",
      },
    },
    textSecondary: {
      "& .MuiTypography-root": {
        color: "#5F6267",
        fontSize: "15px",
      },
    },
  }));
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="lg"
      scroll="body"
      open={isOpenTerritoryDialog}
      onClose={handleCloseTerritoryDialog}
    >
      <DialogContent
        sx={{
          width: "980px",
          p: 0,
        }}
      >
        <Grid
          sx={{ p: "26px", overflow: "hidden" }}
          container
          flexWrap="nowrap"
          justifyContent="space-between"
        >
          <Typography fontSize="30px" fontWeight="400" noWrap color="#707070">
            {profile?.name || profile}
          </Typography>
          <IconButton onClick={handleCloseTerritoryDialog}>
            <CrossBigIcon />
          </IconButton>
        </Grid>
      </DialogContent>

      <DialogContent
        sx={{
          py: 0,
          px: "26px",
          maxWidth: "980px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #D4D4D4",
        }}
      >
        <Box>
          <Tabs
            className={classes.tabs}
            value={currentTerritoryTab}
            onChange={handleChangeCurrentTerritoryTab}
          >
            {territoryTabs.map((tab) => (
              <Tab
                sx={{
                  py: 0,
                  fontSize: "20px",
                  fontWeight: 400,
                }}
                className={classes.tab}
                key={tab}
                label={<Box sx={{ textTransform: "none" }}>{tab}</Box>}
                value={tab}
                disabled={
                  (tab === "Customers" && !hasCustomer) ||
                  (tab === "Representatives" && !hasRepresentatives)
                }
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ m: "auto 0" }}>
          {currentTerritoryTab !== "Representatives" && (
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 400,
              }}
              color="groundLighter.main"
            >
              Total: {totalCustomers} customers
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogContent sx={{ py: 0, px: "26px", maxWidth: "980px" }}>
        {renderContent(currentTerritoryTab, territoryTabs)}
      </DialogContent>
      <DialogContent sx={{ minHeight: "5px", p: 0, marginTop: "22px" }}>
        {isShowFooter && <FooterCustomers />}
      </DialogContent>
    </Dialog>
  );
};

TerritoryComponent.propTypes = {
  profile: PropTypes.oneOfType([string, object]),
  territories: array,
  territoryTabs: array,
  isOpenTerritoryDialog: bool,
  handleCloseTerritoryDialog: func,
};

TerritoryComponent.defaultProps = {
  profile: {
    name: "No name",
  },
  territoryTabs: ["Customers", "Representatives"],
};

export default TerritoryComponent;
