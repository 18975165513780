import { useCallback } from "react";
import { bool, object, func } from "prop-types";
import { useFieldArray, useWatch } from "react-hook-form";
import { IconButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { PlusIcon } from "components/Icons";
import TableHeader from "../TableHeader";
import CarriersItem from "./CarriersItem";
import { defaultValues } from "../../ProfileTab.constants";

const CarriersTab = ({ isEdit, control, setValue }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "shippingCarriers",
    keyName: "key",
  });

  const setNotEdited = useCallback((carrier) => {
    const shippingCarriersNames = defaultValues?.shippingCarriers?.map(
      (c) => c.name
    );
    if (shippingCarriersNames.includes(carrier?.name)) return true;

    return false;
  }, []);

  const formField = useWatch({ control });

  const handleAddCarriers = () => {
    append({
      name: "",
      default: false,
    });
  };

  const handleChangeDefault = (pos) => {
    if (formField.shippingCarriers[pos].default) return;
    const defIndex = formField.shippingCarriers.findIndex((c) => c.default);
    setValue(`shippingCarriers.${defIndex > -1 ? defIndex : 0}.default`, false);
    setValue(`shippingCarriers.${defIndex > -1 ? pos : 0}.default`, true);
  };

  return (
    <Box>
      <TableHeader
        title="Carrier Name"
        isEdit={isEdit}
        addElement={
          <IconButton onClick={handleAddCarriers}>
            <PlusIcon stroke="#eeeeee" fill="#47A06D" width="20" height="20" />
          </IconButton>
        }
      />
      <Box
        sx={{
          maxHeight: "432px",
          overflowY: "auto",
        }}
      >
        {fields.length > 0 ? (
          fields.map((carrier, i) => (
            <CarriersItem
              key={carrier?.key || carrier?.name}
              title={carrier.name}
              carrier={formField.shippingCarriers[i]}
              isEdit={isEdit}
              control={control}
              index={i}
              handleChangeDefault={handleChangeDefault}
              handleRemoveCarrier={() => remove(i)}
              isNotEdited={setNotEdited(carrier)}
            />
          ))
        ) : (
          <Stack
            sx={{
              maxHeight: "288px",
              overflowY: "overlay",
              borderWidth: "0px 1px 1px 1px",
              borderStyle: "solid",
              borderColor: "#D5D9D9",
              borderRadius: "0 0 4px 4px",
              background: !fields.length && "white",
            }}
            id="product-tags-scroll"
          >
            <Typography
              fontWeight="300"
              fontSize="16px"
              textAlign="center"
              width="100%"
              color="#6A6A6A"
              paddingTop="20px"
              paddingBottom="20px"
            >
              No carriers created yet
            </Typography>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

CarriersTab.propTypes = {
  isEdit: bool,
  control: object,
  setValue: func,
};

export default CarriersTab;
