/* eslint-disable react/prop-types */
import * as React from "react";
export const RoutesIcon = ({ strokeParams = {}, ...props }) => (
  <svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.4 15h6.74a1.86 1.86 0 0 0 .588-3.624l-11.697-3.9A2.092 2.092 0 0 1 4.692 3.4H8m9 .343C17 5.258 14.2 8.2 14.2 8.2s-2.8-2.942-2.8-4.457S12.654 1 14.2 1 17 2.228 17 3.743M5 15a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...strokeParams}
    />
  </svg>
);
