/* eslint-disable react/prop-types */
import * as React from "react";
export const FormsIcon = (props) => (
  <svg
    width={18}
    height={20}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 3.75c0-1.414 0-2.121.44-2.56C2.378.75 3.085.75 4.5.75h9c1.414 0 2.121 0 2.56.44.44.439.44 1.146.44 2.56v12.5c0 1.414 0 2.121-.44 2.56-.439.44-1.146.44-2.56.44h-9c-1.414 0-2.121 0-2.56-.44-.44-.439-.44-1.146-.44-2.56z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M9 5h4.5M9 10h4.5M9 15h4.5m-9.244.004H6.5m-2.244-5H6.5m-2.244-5H6.5"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
