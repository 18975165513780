import { useMemo, useState } from "react";
import { bool, func, object } from "prop-types";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { CrossBigIcon } from "../../../../../../components/Icons";
import DeliveryProductsHeader from "../OrdersDelivery/DeliveryProductsTable/DeliveryProductsHeader";
import DeliveryProductsItem from "../OrdersDelivery/DeliveryProductsTable/DeliveryProductsItem";
import StyledButton from "../../../../../../components/StyledButton";
import {
  getFormattedDate,
  useAdmin,
  normalizeSnakeCaseString,
} from "../../../../../../helpers/helpers";
import { printPackingSlipService } from "../../../../../../services/orders";
import { openFilePreview } from "../../../../Orders.helper";
import { error } from "../../../../../../utils/notifications";
import { LargeCheckbox } from "../../../../../../components/Checkboxes";
import { CARRIER_INFO } from "Pages/OrdersPage/Orders.constants";
import { shippingLinks } from "utils/constants";
import { useRepsPermissions } from "helpers/hooks";

const DeliveryDetailsPopup = ({
  isOpen,
  handleClose,
  order,
  delivery,
  handleDeleteDelivery,
  handleEditDelivery,
}) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();

  const disabledRepPermissions = useMemo(() => {
    return !!repPermissions;
  }, [repPermissions]);

  const cl = {
    SSLogo: { width: "20px", height: "20px" },
    textTitle: { fontSize: "30px", color: "#707070" },
    textSubTitle: { fontSize: "20px", fontWeight: 400, color: "#707070" },

    textBody: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#8A8A8A",
      flex: "0 0 100px",
    },

    textBody2: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#000000",
      flex: "0 0 100px",
      maxWidth: "100px",
      whiteSpace: "nowrap",
    },

    block: {
      display: "inline-flex",
      width: "100%",
    },
    blockTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      p: 0,
      borderBottom: "0.5px solid #D5D9D9",
    },
    blockDialog: {
      // border: "1px solid #D5D9D9",
      borderRadius: "4px",
      width: "474px",
      paddingX: "32px",
    },
    label: {
      height: "24px",
      "& .MuiFormLabel-root": {
        color: "#B5B5AC",
        fontSize: "12px",
        top: "2px",
      },
      "& .MuiOutlinedInput-root": {
        height: "100%",
      },
    },
    button: {
      px: "5px",
      minWidth: "40px",
    },
  };

  const [printLoading, setPrintLoading] = useState(false);

  const handlePrintPackingSlip = () => {
    setPrintLoading(true);
    printPackingSlipService(
      delivery.id,
      delivery.productDeliveries ? "delivery" : "return"
    )
      .then((res) => {
        setPrintLoading(false);
        openFilePreview(res);
      })
      .catch((err) => {
        setPrintLoading(false);
        error(err?.response?.data?.message || "Something went wrong");
      });
  };

  const productsListWithGroups = useMemo(() => {
    if (delivery?.productDeliveries?.length) {
      const list = delivery?.productDeliveries.map((p) => {
        let foundProd = order?.products?.find(
          (el) => el?.id === p?.orderProductId
        );

        return {
          ...foundProd,
          quantity: p?.quantity,
          product: { ...p, quantity: foundProd?.quantity },
        };
      });

      const groups = list?.reduce(
        (acc, p, i) => {
          if (p?.product?.parentProduct) {
            if (acc[p?.product?.parentProduct?.id]) {
              acc[p?.product?.parentProduct?.id].push({ ...p, index: i });
            } else {
              acc[p?.product?.parentProduct?.id] = [
                {
                  parentId: p?.product?.parentProduct?.id,
                  id: p?.name,
                  name: p?.name,
                  manufacturerName: p?.manufacturerName,
                  photo: p?.product?.parentProduct?.photos?.[0],
                },
                { ...p, index: i },
              ];
            }
          } else {
            acc.without_group.push({ ...p, index: i });
          }
          return acc;
        },
        { without_group: [] }
      );

      const addedLastEl = Object.entries(groups).map((el) => {
        if (el[0] === "without_group") {
          return el;
        } else {
          const indexLastEl = el[1].length - 1;
          let newEl = { ...el };
          newEl[1][indexLastEl].isLastEl = true;
          return newEl;
        }
      });

      return addedLastEl?.map((group) => group[1])?.flat();
    }
    return [];
  }, [delivery?.productDeliveries, order?.products]);

  const returnsListWithGroups = useMemo(() => {
    if (delivery?.productReturns?.length) {
      const list = delivery?.productReturns.map((p) => {
        let foundProd = order?.products?.find(
          (el) => el?.id === p?.orderProductId
        );
        return { ...foundProd, quantity: p.quantity };
      });

      const groups = list?.reduce(
        (acc, p, i) => {
          if (p?.product?.parentProduct) {
            if (acc[p?.product?.parentProduct?.id]) {
              acc[p?.product?.parentProduct?.id].push({ ...p, index: i });
            } else {
              acc[p?.product?.parentProduct?.id] = [
                {
                  parentId: p?.product?.parentProduct?.id,
                  id: p?.name,
                  name: p?.name,
                  manufacturerName: p?.manufacturerName,
                  photo: p?.product?.parentProduct?.photos?.[0] || p?.photo, // p.product.parentProduct.photo (note: the "photo" needs to add on the backend)
                },
                { ...p, index: i },
              ];
            }
          } else {
            acc.without_group.push({ ...p, index: i });
          }
          return acc;
        },
        { without_group: [] }
      );

      const addedLastEl = Object.entries(groups).map((el) => {
        if (el[0] === "without_group") {
          return el;
        } else {
          const indexLastEl = el[1].length - 1;
          let newEl = { ...el };
          newEl[1][indexLastEl].isLastEl = true;
          return newEl;
        }
      });

      return addedLastEl?.map((group) => group[1])?.flat();
    }
    return [];
  }, [delivery?.productReturns, order?.products]);

  const getLink = ({ shippingCarrier, trackingId }) => {
    const url = shippingLinks[shippingCarrier?.name];
    return url ? window.open(`${url}${trackingId}`, "_blank") : "";
  };

  // const link = getLink({
  //   shippingCarrier: delivery?.shippingCarrier,
  //   trackingId: delivery?.trackingId,
  // });

  return (
    <Dialog maxWidth="lg" scroll="body" open={isOpen} onClose={handleClose}>
      <DialogTitle sx={cl.blockTitle}>
        <Box sx={{ p: "14px 20px 9px 26px" }}>
          <Typography sx={cl.textTitle} mb="9px">
            {delivery?.deliveryMethod === "RESTOCK"
              ? "Restock details"
              : delivery?.titlePopUp === "Delivery details"
              ? "Fulfillment details"
              : delivery?.titlePopUp}
          </Typography>
          <Box display="flex" gap="10px" alignItems="center">
            <Typography sx={cl.textSubTitle}>
              Order ID: {order?.customId?.customId || "-"}
            </Typography>
            <StyledButton
              disabled={disabledRepPermissions || isAdmin}
              label="Print packing slip"
              variant="outlined"
              color="ground"
              fontSize="11px"
              fontWeight="400"
              onClick={handlePrintPackingSlip}
              sx={{
                borderColor: "#D4D4D4",
                color: "#000",
                height: "24px",
                px: "9px",
              }}
            />
            {printLoading && (
              <Box display="inline-flex" alignItems="center" gap="4px">
                <Typography color="primary" fontSize="10px">
                  Preloading pdf..
                </Typography>
                <CircularProgress size="20px" />
              </Box>
            )}
          </Box>
        </Box>
        <IconButton
          sx={{ mt: "15px", marginRight: "16px" }}
          onClick={handleClose}
        >
          <CrossBigIcon size={21.4} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={cl.blockDialog}>
        <Box display="flex" justifyContent="space-between">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pt: "10px",
            }}
          >
            <Box sx={cl.block}>
              <Typography sx={cl.textBody}>Fulfilled VIA</Typography>
              <Box display="flex" alignItems="center" gap="8px">
                <Typography sx={cl.textBody2}>
                  {CARRIER_INFO[delivery?.deliveryMethod]?.carrierName ||
                    normalizeSnakeCaseString(delivery?.deliveryMethod)}
                </Typography>
                {CARRIER_INFO[delivery?.deliveryMethod]?.logo}
              </Box>
            </Box>
            <Box sx={cl.block}>
              <Typography sx={cl.textBody}>Fulfill date</Typography>
              <Typography sx={cl.textBody2}>
                {delivery?.date
                  ? getFormattedDate(delivery?.date).split(",")[0]
                  : "-"}
              </Typography>
            </Box>
            {(delivery?.delivererDuplicate?.name ||
              delivery?.shippingCarrier ||
              delivery?.shippingCarrierDuplicate) && (
              <Box sx={cl.block}>
                <Typography sx={cl.textBody}>Fulfilled by</Typography>
                <Typography sx={cl.textBody2} noWrap>
                  {delivery?.delivererDuplicate?.name}
                  {delivery?.deliveryMethod === "LOCAL_DELIVERY"
                    ? delivery?.shippingCarrier
                    : null}
                  {delivery?.deliveryMethod === "SHIPPING_CARRIER"
                    ? delivery?.shippingCarrier?.name ||
                      delivery?.shippingCarrierDuplicate?.name
                    : null}
                </Typography>
              </Box>
            )}

            {delivery?.trackingId && (
              <Box sx={cl.block}>
                <Typography sx={cl.textBody}>Tracking ID:</Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    color: "#47A06D",
                    flex: "0 0 70%",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (delivery?.shipEngineNotificationData?.trackingUrl) {
                      return window.open(
                        delivery?.shipEngineNotificationData?.trackingUrl,
                        "_blank"
                      );
                    }
                    getLink({
                      shippingCarrier: delivery?.shippingCarrier,
                      trackingId: delivery?.trackingId,
                    });
                  }}
                >
                  {delivery?.trackingId}
                </Typography>
              </Box>
            )}
          </Box>
          {!(
            delivery?.deliveryMethod === "CANCEL" ||
            delivery?.deliveryMethod === "RESTOCK" ||
            delivery?.isDeliveryDisabled ||
            delivery?.isReturnDisabled ||
            delivery?.deliveryMethod === "SHIP_ENGINE"
          ) && (
            <Box pt="10px" display="flex" height="25px">
              {delivery?.deliveryMethod !== "REFUND" && (
                <>
                  <StyledButton
                    disabled={
                      disabledRepPermissions ||
                      isAdmin ||
                      order?.orderStatus === "CANCELED"
                    }
                    label="Edit"
                    sx={cl.button}
                    fontWeight="400"
                    fontSize="15px"
                    onClick={() => {
                      handleEditDelivery({
                        ...delivery,
                        titlePopUp:
                          delivery?.titlePopUp === "Delivery details"
                            ? "Edit delivery"
                            : "Edit return",
                      });
                    }}
                  />
                  <Divider orientation="vertical" width="1px" />
                </>
              )}
              <StyledButton
                disabled={
                  disabledRepPermissions ||
                  isAdmin ||
                  order?.orderStatus === "CANCELED"
                }
                label="Delete"
                sx={{ ...cl.button, ml: "2px" }}
                fontWeight="400"
                fontSize="15px"
                onClick={() =>
                  handleDeleteDelivery(
                    delivery.id,
                    delivery.type?.toLowerCase()
                  )
                }
              />
            </Box>
          )}
        </Box>
        {delivery?.reason ? (
          <Box sx={{ mt: 2, width: "100%" }}>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                color: "#000",
              }}
            >
              Return Reason:
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 300,
                color: "#000",
              }}
              noWrap
            >
              {delivery?.reason}
            </Typography>
          </Box>
        ) : null}
        <Box mt="18px" border="0.5px solid #D5D9D9" borderRadius="4px">
          <DeliveryProductsHeader />
          <Stack
            sx={{
              maxHeight: "324px",
              overflow: "auto",
              borderRadius: "4px",
            }}
          >
            {productsListWithGroups?.map((product, index) => {
              return (
                <DeliveryProductsItem
                  key={product?.id}
                  order={order}
                  product={product}
                  index={index}
                  deliveredQty={product?.quantity}
                  titleType="details"
                />
              );
            })}
            {returnsListWithGroups?.map((product, index) => {
              return (
                <DeliveryProductsItem
                  key={product.id}
                  order={order}
                  product={product}
                  index={index}
                  deliveredQty={product.quantity}
                  titleType="details"
                />
              );
            })}
          </Stack>
        </Box>
        {delivery?.type === "Return" && (
          <Box>
            <LargeCheckbox
              disabled
              checked={delivery?.restock}
              checkedColor="#D5D9D9"
              formSx={{ mr: 0, ml: "-9px", mt: 1, flex: "1 1 auto" }}
              label={
                <Typography sx={{ ml: "-4px", fontSize: 12, fontWeight: 400 }}>
                  Restock items
                </Typography>
              }
              size={16}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

DeliveryDetailsPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  delivery: object,
  order: object,
  handleDeleteDelivery: func,
  handleEditDelivery: func,
};

export default DeliveryDetailsPopup;
