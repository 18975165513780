import { useContext } from "react";
import { FormViewContext } from "Pages/FormViewPage/FormViewPage";

import { QuestionItem, SectionTitle } from "..";

import { Stack } from "@mui/material";
import Nestable from "react-nestable";
import { DragAndDropIcon } from "components/Icons";
import { styled } from "@mui/material";

const StyledNestable = styled(Nestable)(() => ({
  "& .nestable-drag-layer": {
    "& .nestable-list": {
      "& .MuiTooltip-popper": {
        display: "none !important",
        opacity: 0,
      },
    },
  },
  "& .is-dragging": {
    "& .MuiTooltip-popper": {
      display: "none !important",
      opacity: 0,
    },
  },
}));

export const QuestionsList = () => {
  const {
    setActiveQuestion,
    activeQuestion,
    formField,
    control,
    setValue,
    errors,
    clearErrors,
    handleOpenChangeDialog,
    trigger,
  } = useContext(FormViewContext);

  // const [dragItemIndex, setDragItemIndex] = useState(null);

  return (
    <Stack gap="10px">
      <SectionTitle title="Questions & input fields" />

      <Stack
        width="100%"
        sx={{
          "& ol": {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          },
        }}
      >
        <StyledNestable
          threshold={1}
          maxDepth={0}
          onChange={({
            items,
            // targetPath
          }) => {
            const preparedItems = items.map((item, index) => {
              return { ...item, orderIndex: index };
            });
            setValue("questions", preparedItems, { shouldDirty: true });

            // if (typeof dragItemIndex === "number") {
            // clearErrors(`questions.${dragItemIndex}.options`);
            // clearErrors(`questions.${dragItemIndex}`);
            clearErrors("questions");
            // setDragItemIndex(null);
            // }

            // trigger(`questions.${targetPath[0]}.options`);
            // trigger(`questions.${targetPath[0]}`);
          }}
          handler={
            <Stack
              width="100%"
              alignItems="center"
              height="20px"
              mt="4px"
              sx={{ cursor: "move" }}
            >
              {<DragAndDropIcon />}
            </Stack>
          }
          idProp="uuid"
          items={formField?.questions}
          renderItem={({ item, handler }) => {
            return (
              <QuestionItem
                {...{
                  question: item,
                  setActiveQuestion,
                  activeQuestion,
                  handler,
                  orderIndex: item?.orderIndex,
                  control,
                  setValue,
                  formField,
                  errors,
                  clearErrors,
                  handleOpenChangeDialog,
                  trigger,
                }}
                key={item?.uuid}
              />
            );
          }}
          // onDragStart={({ dragItem }) => {
          // const index = dragItem?.orderIndex;
          // setDragItemIndex(index);
          // }}
        />
      </Stack>
    </Stack>
  );
};
