import { SvgIcon } from "@mui/material";

// eslint-disable-next-line react/prop-types
export const CheckInLocationIcon = ({ fill = "#409A65", ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 10 14"
      sx={{ fill: "none", width: 10, height: 14 }}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.826 12.55c.473.56.882 1.044 1.174 1.45v-.004c.291-.404.7-.888 1.173-1.448C7.79 10.637 10 8.016 10 5.61c.008-1.474-.516-2.894-1.461-3.963A4.9 4.9 0 0 0 6.929.43 4.6 4.6 0 0 0 5 0a4.6 4.6 0 0 0-1.929.43 4.9 4.9 0 0 0-1.61 1.217C.516 2.717-.008 4.137.001 5.61c0 2.406 2.208 5.026 3.825 6.94M5 7c.92 0 1.666-.784 1.666-1.75S5.921 3.5 5.001 3.5s-1.667.784-1.667 1.75S4.08 7 5.001 7"
        fill={fill}
      />
    </SvgIcon>
  );
};
