import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  ordersCountSelector,
  ordersExistSelector,
  ordersGetParamsSelector,
  ordersGetParamsThirdPartySelector,
  ordersListSelector,
  ordersLoadingSelector,
} from "redux/selectors/orders";
import { currentUserSelector } from "redux/selectors/auth";
import {
  bulkUpdateOrdersAction,
  endLoadingOrders,
  getOrderByIdAction,
  resetGlobalOrdersActions,
  //resetOrdersParamsThirdPartyAction,
  startLoadingOrders,
} from "redux/actions/orders";
import {
  totalSummariesOrdersDataSelector,
  totalSummariesOrdersLoadingSelector,
} from "redux/selectors/total-summaries";
import {
  bulkAssignOrderTagsService,
  getOrderReportPDF,
  getOrdersService,
  updateOrderService,
} from "services/orders";

import _ from "lodash";
import { error, success } from "utils/notifications";
import { openFilePreview } from "./Orders.helper";

import OrdersPageTab from "./components/OrdersPageTab/OrdersPageTab";
import ConfirmDeliveryPopup from "./OrderDetailsPage/OrdersTopPaymentBar/components/OrdersDelivery/ConfirmDeliveryPopup/ConfirmDeliveryPopup";
import AcceptPaymentPopup from "./OrderDetailsPage/OrdersTopPaymentBar/AcceptPaymentPopup";
import Loader from "components/Loader";
import CancelDialog from "./components/CancelDialog/CancelDialog";
// import EmailDialog from "./components/EmailDialog/EmailDialog";
import RefundDialog from "./components/RefundDialog/RefundDialog";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { DirectOrdersTab, DraftsTab, ThirdPartyOrdersTab } from "./components";
import { TABS } from "./components/OrdersPageTab/constants";
import { useThirdPartyOrdersTabActions } from "./useThirdPartyOrdersTabActions";
import StyledButton from "components/StyledButton";
import { Button } from "@mui/material";
import {
  ConfirmDeliveryBulkDialog,
  PrintPickOrdersDialog,
  ReturnDialog,
  TagsDrawer,
} from "components";
import { setTotalSummariesOrdersParamsAction } from "redux/actions/total-summaries";
import { useOrders, useOrdersActions } from "./Orders.hook";
import { OrdersActions } from "Pages/useOrdersActions";
import ViewOrderEmailDialog from "./components/ViewOrderEmailDialog/ViewOrderEmailDialog";
import { useRepsPermissions } from "helpers/hooks";
import { TAG_TYPES } from "helpers/useTagsActions";
import { updateDraftOrderService } from "services/draft_orders";
import { useDraftsTabActions } from "./components/DraftsTab/DraftsTab.hooks";
import { usePrintOrders } from "./usePrintOrders";
import { updateUserFieldAction } from "redux/actions/auth";
import { addOnlySharedTags } from "helpers/helpers";

const selector = createSelector(
  ordersListSelector,
  ordersLoadingSelector,
  ordersCountSelector,
  currentUserSelector,
  ordersExistSelector,
  ordersGetParamsSelector,
  totalSummariesOrdersDataSelector,
  totalSummariesOrdersLoadingSelector,
  ordersGetParamsThirdPartySelector,
  (
    orders,
    ordersLoading,
    ordersCount,
    currentUser,
    ordersExist,
    ordersGetParams,
    totalSummariesOrdersData,
    totalSummariesOrdersLoading,
    ordersGetParamsThirdParty
  ) => ({
    orders,
    ordersLoading,
    ordersCount,
    currentUser,
    ordersExist,
    ordersGetParams,
    totalSummariesOrdersData,
    totalSummariesOrdersLoading,
    ordersGetParamsThirdParty,
  })
);

const OrdersPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const repPermissions = useRepsPermissions();
  const {
    orders,
    ordersLoading,
    ordersCount,
    currentUser,
    ordersExist,
    ordersGetParams,
    totalSummariesOrdersData,
    totalSummariesOrdersLoading,
    ordersGetParamsThirdParty,
  } = useSelector(selector);
  const [checkedOrderIds, setCheckedOrderIds] = useState([]);
  const [checkedOrdersAll, setCheckedOrdersAll] = useState([]);
  const [allOrdersChecked, setAllOrdersChecked] = useState(false);

  const { showIncludedPrices } = currentUser || {};

  const [loadingAllItems, setLoadingAllItems] = useState(false);

  const archiveCallback = () => {
    setCheckedOrderIds([]);
    setCheckedOrdersAll([]);
  };

  const {
    cursor,
    setCursor,
    ordersThirdPartyList,
    ordersThirdPartyCount,
    ordersExistThirdParty,
    ordersThirdPartyLoading,
    handleFetchOrdersThirdParty,
  } = useThirdPartyOrdersTabActions();

  const {
    printPickListDialog,
    handlePrintPickList,
    setPrintPickListDialog,
    fetchPrintPickList,
    printLoading,
    setPrintLoading,
    handlePrintOrders,
    handlePrintPackingSlips,
  } = usePrintOrders({
    checkedOrderIds: checkedOrderIds,
    fetchCallback: () => {
      setAllOrdersChecked(false);
      setCheckedOrderIds([]);
    },
    successFetchCallback: (res) => {
      openFilePreview(res.data);
    },
  });

  const {
    setCurrentTab,
    setType,
    currentTab,
    setOpenRefundDialog,
    setNameRefundDialog,
    setOpenReturnDialog,
    setOpenCancelDialog,
    openCancelDialog,
    openRefundDialog,
    nameRefundDialog,
    openReturnDialog,
    handleChoseMenuItem,
    type,
    handleOpenEmail,
    emailOpen,
    setEmailOpen,
    isLoading,
    setIsLoading,
    order,
    checkedOrders: checkedMenuOrders,
    setCheckedOrders: setCheckedMenuOrders,
    setOrder,
    handleSendEmail,
    loadingSendEmail,
    isAcceptPaymentPopupOpen,
    setIsAcceptPaymentPopupOpen,
    isConfirmDeliveryPopupOpen,
    setIsConfirmDeliveryPopupOpen,
    confirmDeliveryState,
    setConfirmDeliveryState,
    handleCloseConfirmDeliveryDialog,
    handleBulkConfirmDelivery,
    assignTagsState,
    setAssignTagsState,
    handleCloseAssignTagsDialog,
    handleClickOnAssignTags,
    printInvoiceListDialog,
    setPrintInvoiceListDialog,
  } = OrdersActions(archiveCallback, handleFetchOrdersThirdParty);

  const [editDelivery, setEditDelivery] = useState(null);

  useEffect(() => {
    window.history.replaceState({}, document.title);
    if (typeof location.state === "string" && TABS.includes(location?.state)) {
      setCurrentTab(location.state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (location?.state?.currentTab) {
      setCurrentTab(location?.state?.currentTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [firstLoading, setFirstLoading] = useState(null);

  const {
    draftsData,
    params,
    handleFetchDrafts,
    menuItems,
    filtersData,
    emailDialogData,
    emailLinkDialogData,
    setEmailLinkDialogData,
    // handleCreateOrder,
    handleSendEmail: handleSendEmailDraft,
    updateDraftOrderInList,
  } = useDraftsTabActions({ setFirstLoading, handleClickOnAssignTags });

  const { isQuickBooksConnected } = useOrders({
    handleFetchOrdersThirdParty,
    fetchDirectList: true,
  });

  const handleCloseAcceptDeliveryPopup = () => {
    setIsConfirmDeliveryPopupOpen(false);
    setEditDelivery(null);
    setType("");
  };
  const handleCloseAcceptPaymentPopup = () => {
    setIsAcceptPaymentPopupOpen(false);
  };

  const dispatch = useDispatch();

  const handleCheckOrder = (orderId) => {
    const customerIndex = checkedOrderIds.findIndex(
      (checkedOrder) => checkedOrder === orderId
    );
    if (customerIndex > -1) {
      const newCustomers = [...checkedOrderIds];
      newCustomers.splice(customerIndex, 1);
      return setCheckedOrderIds([...newCustomers]);
    }

    setCheckedOrderIds([...checkedOrderIds, orderId]);
  };

  const checkAllOrders = () => {
    if (loadingAllItems) return;

    if (currentTab === TABS[0]) {
      if (checkedOrderIds.length === orders.length) {
        setAllOrdersChecked(false);
        return setCheckedOrderIds([]);
      }
      setCheckedOrderIds([...orders.map((o) => o.id)]);
    }

    if (currentTab === TABS[1]) {
      if (checkedOrderIds.length === ordersThirdPartyList.length) {
        setAllOrdersChecked(false);
        return setCheckedOrderIds([]);
      }
      setCheckedOrderIds([...ordersThirdPartyList.map((o) => o.id)]);
    }
  };

  useEffect(() => {
    if (currentTab === TABS[0]) {
      if (orders.length && checkedOrderIds.length === orders.length)
        return setAllOrdersChecked(true);
      setAllOrdersChecked(false);
    }

    if (currentTab === TABS[1]) {
      if (
        ordersThirdPartyList.length &&
        checkedOrderIds.length === ordersThirdPartyList.length
      )
        return setAllOrdersChecked(true);
      setAllOrdersChecked(false);
    }
  }, [
    checkedOrderIds?.length,
    currentTab,
    orders?.length,
    ordersThirdPartyList?.length,
  ]);

  const [currentFilterDate, setCurrentFilterDate] = useState("This week");

  const prepareQueryDate = (date) => {
    if (typeof date === "string") return {};
    const start_date = date?.[0]?.format()?.split("+")?.[0];
    const end_date = date?.[1]?.format()?.split("+")?.[0];
    return { start_date, end_date };
  };

  const setCurrentOrderType = useMemo(() => {
    if (currentTab === TABS[0]) return "direct";
    if (currentTab === TABS[1]) return "third_party";
  }, [currentTab]);

  useEffect(() => {
    let didCancel = false;
    if (currentFilterDate) {
      const isDateString = typeof currentFilterDate === "string";
      const { start_date, end_date } = prepareQueryDate(currentFilterDate);

      const preparedData = {
        date: isDateString ? _.snakeCase(currentFilterDate) : null,
        start_date: isDateString ? null : start_date,
        end_date: isDateString ? null : end_date,
        type: setCurrentOrderType,
      };

      //? temporary solution for the draft tab
      if (didCancel || currentTab === TABS[2]) return;
      dispatch(setTotalSummariesOrdersParamsAction(preparedData));
    }

    return () => (didCancel = true);
  }, [dispatch, setCurrentOrderType, currentFilterDate, currentTab]);

  const handleDownloadInvoice = (id) => {
    setIsLoading(true);
    getOrderReportPDF(id)
      .then((res) => {
        setIsLoading(false);
        openFilePreview(res);
      })
      .catch((err) => {
        error(err?.response?.data?.message);
        setIsLoading(false);
      });
  };

  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);

  const handleConfirmCheckedItemsDialog = useCallback(
    (id) => {
      dispatch(
        openConfirmDialogAction({
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                  dispatch(setFormChangedAction(false));
                  setCheckedOrderIds([]);
                  setAllOrdersChecked(false);
                  navigate(id);
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, editType, navigate]
  );

  useEffect(() => {
    if (currentTab === "3rd Party") dispatch(resetGlobalOrdersActions());
    //dispatch(resetOrdersParamsThirdPartyAction());
    dispatch(setEditTypeAction(""));
    dispatch(setFormChangedAction(false));
    setCheckedOrderIds([]);
    setAllOrdersChecked(false);
  }, [currentTab, dispatch]);

  const itemAllCount = useMemo(() => {
    if (currentTab === TABS[0]) return ordersCount;
    if (currentTab === TABS[1]) return ordersThirdPartyCount;
  }, [currentTab, ordersCount, ordersThirdPartyCount]);

  const LIMIT = 1000;

  const fetchOrders = useCallback(
    async (page = 1, accumulatedOrders = []) => {
      try {
        if (itemAllCount > LIMIT) {
          setLoadingAllItems(true);

          const { cursor, ...rest } = ordersGetParams || {};

          const { rows = [] } = await getOrdersService({
            ...rest,
            type: setCurrentOrderType,
            page,
            limit: LIMIT,
          });

          const newAccumulatedOrders = [...accumulatedOrders, ...rows];

          if (newAccumulatedOrders.length < itemAllCount) {
            setCheckedOrdersAll((prev) =>
              page === 1 ? rows : [...prev, ...rows]
            );
            setCheckedOrderIds((prev) =>
              page === 1
                ? rows?.map(({ id }) => id)
                : [...prev, ...rows?.map(({ id }) => id)]
            );

            return fetchOrders(page + 1, newAccumulatedOrders);
          }

          setAllOrdersChecked(true);
          setLoadingAllItems(false);

          setCheckedOrdersAll(newAccumulatedOrders);
          setCheckedOrderIds(newAccumulatedOrders?.map(({ id }) => id));

          return;
        }

        const { cursor, ...rest } = ordersGetParams || {};

        const { rows = [] } = await getOrdersService({
          ...rest,
          type: setCurrentOrderType,
        });

        setCheckedOrdersAll(rows);
        setCheckedOrderIds(rows?.map(({ id }) => id));
        setAllOrdersChecked(true);
        setLoadingAllItems(false);
      } catch (err) {
        dispatch(endLoadingOrders());
        setLoadingAllItems(false);
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      }
    },
    [itemAllCount, ordersGetParams, setCurrentOrderType, dispatch]
  );

  const fetchListAndCheckAll = useCallback(async () => {
    dispatch(startLoadingOrders());

    await fetchOrders();

    dispatch(endLoadingOrders());
  }, [dispatch, fetchOrders]);

  const handleSelectAll = useCallback(() => {
    if (
      (currentTab === TABS[0] && ordersCount === checkedOrderIds?.length) ||
      (currentTab === TABS[1] &&
        ordersThirdPartyCount === checkedOrderIds?.length)
    ) {
      setAllOrdersChecked(false);
      setCheckedOrderIds([]);
      return;
    }
    fetchListAndCheckAll();
  }, [
    checkedOrderIds?.length,
    currentTab,
    fetchListAndCheckAll,
    ordersCount,
    ordersThirdPartyCount,
  ]);

  const successFulfillOrders = useCallback(() => {
    setCheckedOrderIds([]);
    setAllOrdersChecked(false);
    handleCloseConfirmDeliveryDialog();
  }, [handleCloseConfirmDeliveryDialog]);

  const currentOrderList = useMemo(
    () => (currentTab === TABS[0] ? orders : ordersThirdPartyList),
    [currentTab, orders, ordersThirdPartyList]
  );

  const handleFulfillOrders = useCallback(() => {
    if (repPermissions && !repPermissions?.orders?.create_edit) return;

    const currentOrdersList = [];

    const setCurrentOrdersList = (array) => {
      const currentDirectState =
        !!checkedOrdersAll.length && checkedOrdersAll.length > orders.length
          ? checkedOrdersAll
          : orders;

      if (currentTab === TABS[0]) return array.push(...currentDirectState);
      if (currentTab === TABS[1]) return array.push(...ordersThirdPartyList);
    };
    setCurrentOrdersList(currentOrdersList);

    if (!currentOrdersList?.length || !checkedOrderIds?.length) return;

    const checkedOrders = currentOrdersList.filter(
      ({ type, id }) => checkedOrderIds.includes(id) && type === "DIRECT"
    );

    const filteredCheckedOrders = checkedOrders.filter((o) => {
      return !(
        o?.deliveryStatus === "FULFILLED" ||
        ["CANCELED", "COMPLETED"].includes(o?.orderStatus)
      );
    });

    if (checkedOrders?.length && filteredCheckedOrders?.length === 0) {
      // return error(
      //   "No orders available for fulfillment due to their statuses being either canceled, fulfilled, or completed."
      // );
      return success("Orders marked as fulfilled");
    }

    if (filteredCheckedOrders?.length === 1) {
      const order = filteredCheckedOrders?.[0];

      if (order) {
        handleChoseMenuItem(order, "Confirm delivery", successFulfillOrders);
      }

      return;
    }

    setConfirmDeliveryState({
      isOpenDialog: true,
      orders: filteredCheckedOrders,
    });
  }, [
    checkedOrderIds,
    checkedOrdersAll,
    currentTab,
    handleChoseMenuItem,
    orders,
    ordersThirdPartyList,
    repPermissions,
    setConfirmDeliveryState,
    successFulfillOrders,
  ]);

  const isDirectTab = useMemo(() => currentTab === "Direct", [currentTab]);

  const getOrderActions = () => {
    if (!checkedOrderIds?.length || checkedOrderIds?.length > 1) return {};
    return currentOrderList.filter(({ id }) => id === checkedOrderIds[0])[0];
  };

  const currentOrder = getOrderActions();

  const checkedOrders = useMemo(() => {
    if (!checkedOrderIds?.length) return [];
    return currentOrderList.filter(({ id }) => checkedOrderIds.includes(id));
  }, [checkedOrderIds, currentOrderList]);

  const {
    handleGetOrderActions,
    getAdditionalAction,
    handleMultipleOrderActions,
  } = useOrdersActions({
    isQuickBooksConnected,
    order: currentOrder,
    handleChoseMenuItem,
    handleClickOnAssignTags,
    checkedOrders,
    hideAssignTags: true,
    setPrintInvoiceListDialog,
  });

  const dropDownActions = useMemo(
    () =>
      checkedOrderIds?.length > 1
        ? handleMultipleOrderActions(
            currentTab !== TABS[1]
              ? () => handlePrintOrders()
              : () => setPrintInvoiceListDialog(true),
            handlePrintPickList,
            handlePrintPackingSlips,
            checkedOrderIds,
            currentOrderList
          )
        : handleGetOrderActions(isDirectTab).slice(1),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedOrderIds, currentTab]
  );

  const additionalActions = useMemo(
    () =>
      getAdditionalAction(
        checkedOrderIds,
        currentOrderList,
        checkedOrdersAll,
        handleFulfillOrders,
        isDirectTab
      ),
    [
      getAdditionalAction,
      checkedOrderIds,
      currentOrderList,
      checkedOrdersAll,
      handleFulfillOrders,
      isDirectTab,
    ]
  );

  const [loadingAssignTags, setLoadingAssignTags] = useState(false);

  const transformTagDataForAssignTags = (tags) => tags.map((tag) => ({ tag }));

  const handleSaveAssignTags = useCallback(
    async ({ tags }) => {
      assignTagsState?.isBulk
        ? setAssignTagsState((prev) => ({
            ...prev,
            orders: prev.orders.map((order) => ({
              ...order,
              tags: transformTagDataForAssignTags(tags),
            })),
          }))
        : setAssignTagsState((prev) => ({
            ...prev,
            order: { ...prev.order, tags: transformTagDataForAssignTags(tags) },
          }));
      if (loadingAssignTags) return;

      try {
        setLoadingAssignTags(true);
        const preparedData = {};
        const tagIds = [];
        const newTags = [];

        tags.forEach((tag) => {
          return tag?.newTag
            ? newTags.push(tag?.tag)
            : tagIds.push(tag?.id || tag?.tag?.id);
        });

        preparedData.tagIds = tagIds;
        if (newTags?.length) preparedData.newTags = newTags;

        if (assignTagsState?.isBulk) {
          const data = {
            orderIds: checkedOrderIds,
            tagIds,
            newTags,
          };
          await bulkAssignOrderTagsService(data);
          const tagsForUpdate = tags.map((item) => {
            return { tag: item };
          });

          if (currentTab !== "Drafts")
            dispatch(
              bulkUpdateOrdersAction(checkedOrderIds, {
                tags: tagsForUpdate,
              })
            );
        } else {
          if (!assignTagsState?.order?.id) return;

          if (assignTagsState?.isDraft) {
            await updateDraftOrderService({
              id: assignTagsState?.order?.id,
              data: preparedData,
            });
          } else {
            await updateOrderService(assignTagsState?.order?.id, preparedData);
          }
          currentTab !== "Drafts"
            ? dispatch(getOrderByIdAction(assignTagsState?.order?.id))
            : updateDraftOrderInList(assignTagsState?.order?.id);
        }
        success("Tags updated");
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        handleCloseAssignTagsDialog();
        setAllOrdersChecked(false);
        setCheckedOrderIds([]);
        setLoadingAssignTags(false);
      }
    },
    [
      assignTagsState?.isBulk,
      assignTagsState?.isDraft,
      assignTagsState?.order?.id,
      checkedOrderIds,
      currentTab,
      dispatch,
      handleCloseAssignTagsDialog,
      updateDraftOrderInList,
      loadingAssignTags,
      setAssignTagsState,
    ]
  );

  const onPrintPickOrders = (includePrices) => {
    if (includePrices !== showIncludedPrices)
      dispatch(updateUserFieldAction("showIncludedPrices", includePrices));

    handlePrintOrders(
      includePrices,
      checkedMenuOrders.length ? [checkedMenuOrders?.[0]?.id] : [],
      () => {
        setCheckedMenuOrders([]);
      }
    );
    setPrintInvoiceListDialog(false);
  };

  return (
    <>
      <Loader isLoading={isLoading} />

      <TagsDrawer
        type={TAG_TYPES.order}
        open={assignTagsState.open}
        handleClose={handleCloseAssignTagsDialog}
        orderTags={
          assignTagsState.isBulk
            ? addOnlySharedTags([...new Set([...assignTagsState?.orders])])
            : assignTagsState?.order?.tags?.map((tag) => tag?.tag)
        }
        handleSave={({ tags }) => {
          handleSaveAssignTags({ tags });
        }}
        isBulk={assignTagsState?.isBulk}
        setCheckedCustomers={() => {}}
      />

      <ViewOrderEmailDialog
        isOpen={emailOpen}
        handleClose={() => setEmailOpen(false)}
        order={order}
        handleSendEmail={handleSendEmail}
        loading={loadingSendEmail}
      />

      <ConfirmDeliveryPopup
        order={order}
        isOpen={isConfirmDeliveryPopupOpen}
        handleClose={handleCloseAcceptDeliveryPopup}
        editDelivery={editDelivery}
        type={type}
        isOrderPage={true}
        successCallback={() => setCheckedOrderIds([])}
      />

      <ConfirmDeliveryBulkDialog
        open={confirmDeliveryState.isOpenDialog}
        handleClose={handleCloseConfirmDeliveryDialog}
        orders={confirmDeliveryState.orders}
        isLoading={confirmDeliveryState.loading}
        handleConfirmDelivery={(preparedData) => {
          handleBulkConfirmDelivery({
            orderIds: confirmDeliveryState?.orders?.map(({ id }) => id),
            onSuccess: () => successFulfillOrders(),
            data: preparedData,
          });
        }}
      />

      <AcceptPaymentPopup
        order={order}
        isOpen={isAcceptPaymentPopupOpen}
        handleClose={handleCloseAcceptPaymentPopup}
        isOrderPage={true}
        successCallback={() => setCheckedOrderIds([])}
      />

      <CancelDialog
        order={order}
        title="Cancel order"
        orderId={order?.id}
        customId={order?.customId?.customId}
        paymentStatus={order?.paymentStatus}
        deliveryStatus={order?.deliveryStatus}
        totalPayments={order?.totalPayments}
        open={openCancelDialog}
        onCancelSuccess={() => setCheckedOrderIds([])}
        onClose={() => setOpenCancelDialog(false)}
        isOrderPage={true}
      />

      <RefundDialog
        open={openRefundDialog}
        onClose={() => {
          setOpenRefundDialog(false);
          setNameRefundDialog("");
          setOrder({});
        }}
        nameRefundDialog={nameRefundDialog}
        order={order}
        isOrderPage={true}
        successCallback={() => setCheckedOrderIds([])}
      />

      <ReturnDialog
        open={openReturnDialog}
        onClose={() => {
          setOpenReturnDialog(false);
          setOrder({});
        }}
        order={order}
        successCallback={() => setCheckedOrderIds([])}
      />

      <PrintPickOrdersDialog
        open={printPickListDialog}
        handleClose={() => setPrintPickListDialog(false)}
        handlePrint={(isCheckedGroupOrders) => {
          fetchPrintPickList({
            isCheckedGroupOrders,
            checkedOrdersIds: checkedOrderIds,
          });
          setPrintPickListDialog(false);
        }}
      />

      <PrintPickOrdersDialog
        open={printInvoiceListDialog}
        defaultChecked={showIncludedPrices}
        title="Print order(s)"
        checkboxText="Include Pricing"
        handleClose={() => setPrintInvoiceListDialog(false)}
        handlePrint={onPrintPickOrders}
      />

      <OrdersPageTab
        checkedCount={checkedOrderIds.length}
        {...{
          handlePrintOrders,
          handlePrintPackingSlips,
          handlePrintPickList,
          printLoading,
          setPrintLoading,
          currentTab,
          setCurrentTab: (tab) => {
            setCurrentFilterDate("This week");
            setCurrentTab(tab);
          },
          setPrintInvoiceListDialog,
        }}
      />

      {currentTab === TABS[0] && (
        <DirectOrdersTab
          itemAllCount={itemAllCount}
          additionalActions={additionalActions}
          onSelectAll={(value) => handleSelectAll(value)}
          isLoadingTotalSummaries={totalSummariesOrdersLoading}
          totalSummaries={totalSummariesOrdersData}
          setCurrentFilterDate={setCurrentFilterDate}
          orders={orders}
          ordersLoading={ordersLoading}
          loadingAllItems={loadingAllItems}
          allOrdersChecked={allOrdersChecked}
          setAllOrdersChecked={setAllOrdersChecked}
          setCheckedOrders={setCheckedOrderIds}
          checkAllOrders={checkAllOrders}
          ordersCount={ordersCount}
          checkedOrders={checkedOrderIds}
          currentUser={currentUser}
          handleCheckOrder={handleCheckOrder}
          handleChoseMenuItem={handleChoseMenuItem}
          handleDownloadInvoice={handleDownloadInvoice}
          handleOpenEmail={handleOpenEmail}
          ordersExist={ordersExist}
          handleConfirmCheckedItemsDialog={handleConfirmCheckedItemsDialog}
          isQuickBooksConnected={isQuickBooksConnected}
          currentTab={currentTab}
          repPermissions={repPermissions}
          dropDownActions={dropDownActions}
        />
      )}

      {currentTab === TABS[1] && (
        <ThirdPartyOrdersTab
          itemAllCount={itemAllCount}
          additionalActions={additionalActions}
          setCheckedOrders={setCheckedOrderIds}
          onSelectAll={(value) => handleSelectAll(value)}
          currentTab={currentTab}
          ordersLoading={ordersThirdPartyLoading}
          loadingAllItems={loadingAllItems}
          ordersThirdPartyList={ordersThirdPartyList}
          ordersThirdPartyCount={ordersThirdPartyCount}
          ordersExistThirdParty={ordersExistThirdParty}
          handleFetchOrdersThirdParty={handleFetchOrdersThirdParty}
          allOrdersChecked={allOrdersChecked}
          checkAllOrders={checkAllOrders}
          checkedOrders={checkedOrderIds}
          handleCheckOrder={handleCheckOrder}
          totalSummaries={totalSummariesOrdersData}
          isLoadingTotalSummaries={totalSummariesOrdersLoading}
          handleOpenEmail={handleOpenEmail}
          handleChoseMenuItem={handleChoseMenuItem}
          setCurrentFilterDate={setCurrentFilterDate}
          handleConfirmCheckedItemsDialog={handleConfirmCheckedItemsDialog}
          isQuickBooksConnected={isQuickBooksConnected}
          currentUser={currentUser}
          repPermissions={repPermissions}
          dropDownActions={dropDownActions}
          params={ordersGetParamsThirdParty}
          setCursor={setCursor}
          cursor={cursor}
        />
      )}

      {currentTab === TABS[2] && (
        <DraftsTab
          {...{
            currentUser,
            currentTab,
            draftsData,
            params,
            handleFetchDrafts,
            menuItems,
            filtersData,
            emailDialogData,
            emailLinkDialogData,
            setEmailLinkDialogData,
            // handleCreateOrder,
            firstLoading,
          }}
          handleSendEmail={handleSendEmailDraft}
        />
      )}
    </>
  );
};

export default OrdersPage;
