import { func, object, string } from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { CrossIcon } from "components/Icons";

export const DialogTitleComponent = ({
  title = "",
  subtitle = "",
  onClose = null,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box textAlign="center" mt={-1}>
        <Typography fontSize={32} fontWeight={400} color="#5F6267">
          {title}
        </Typography>
      </Box>
      <Box sx={{ textAlign: "center" }}>{subtitle}</Box>

      {!!onClose && (
        <IconButton
          sx={{ position: "absolute", right: 4, top: 5 }}
          onClick={onClose}
        >
          <CrossIcon />
        </IconButton>
      )}
    </Box>
  );
};

DialogTitleComponent.propTypes = {
  title: string,
  subtitle: object,
  onClose: func,
};
