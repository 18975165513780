import { useCallback, useMemo, useState } from "react";
import {
  bool,
  func,
  oneOfType,
  string,
  instanceOf,
  shape,
  number,
  arrayOf,
} from "prop-types";
import { Controller, useForm, useWatch } from "react-hook-form";

import {
  Box,
  Button,
  Dialog,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { cl } from "./OrderDirectApplicationDialog.styles";
import {
  CrossBigIcon,
  // PrinterIcon
} from "../Icons";
import { StyledSelect } from "../Selects";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { territoryListSelector } from "redux/selectors/territory";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { repsGetSalesUsers, salesCountSelector } from "redux/selectors/reps";
import { SCROLL_LIMIT } from "utils/constants";
import { getRepsAction } from "redux/actions/reps";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./OrderDirectApplicationDialog.validations";
import { StyledTooltip } from "components";
import { BusinessInfoBlock } from "./components";
import { setPhoneNumberMask } from "helpers/helpers";
import { currentUserSelector } from "redux/selectors/auth";
import { usePayTermsActions } from "helpers/usePayTermsActions";

const selector = createSelector(
  repsGetSalesUsers,
  salesCountSelector,
  territoryListSelector,
  currentUserSelector,
  (salesList, salesCount, territoryList, currentUser) => ({
    salesList,
    salesCount,
    territoryList,
    currentUser,
  })
);

export const OrderDirectApplicationDialog = ({
  open,
  handleClose,
  item,
  onClickReject,
  onSubmit,
  loading,
}) => {
  const { salesList, salesCount, territoryList, currentUser } =
    useSelector(selector);

  const { hideRequestPaymentTerms } = currentUser || {};

  const dispatch = useDispatch();
  const [openTooltip, setOpenTooltip] = useState(false);

  const name = useMemo(() => item?.name ?? "-", [item]);

  const billingAddress = useMemo(() => {
    return `${item?.billingAddress?.street}
    ${item?.billingAddress?.city}, ${item?.billingAddress?.state} ${item?.billingAddress?.zip}
    `;
  }, [item]);

  const shippingAddress = useMemo(() => {
    return `${item?.shippingAddress?.street}
    ${item?.shippingAddress?.city}, ${item?.shippingAddress?.state} ${item?.shippingAddress?.zip}
    `;
  }, [item]);

  const contactName = useMemo(() => item?.contacts?.[0]?.name ?? "-", [item]);
  const contactPhone = useMemo(() => item?.contacts?.[0]?.phone ?? "-", [item]);
  const contactEmail = useMemo(() => item?.contacts?.[0]?.email ?? "-", [item]);

  const businessPhone = useMemo(
    () => setPhoneNumberMask(item?.phone) ?? "-",
    [item]
  );
  const businessEmail = useMemo(() => item?.baseUser?.email ?? "-", [item]);

  const federalTaxId = useMemo(
    () => item?.federalTaxId?.toString().replace(/(.{2})/, "$1-") ?? "-",
    [item]
  );

  const paymentTerm = useMemo(
    () => item?.appOrderDirect?.paymentTermsDuplicate?.name ?? "-",
    [item]
  );

  const { control, reset, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      paymentTermsId: "",
      representativeId: "",
      territoryId: "",
      note: "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const { note } = useWatch({ control });

  const setLabelColor = useCallback(
    (value) => (value === "" ? "#B5B5AC" : "#000000"),
    []
  );

  useEffect(() => {
    if (open) {
      dispatch(
        getRepsAction(
          {
            limit: SCROLL_LIMIT,
            role: "sales",
          },
          { isScrolling: false }
        )
      );
    }
  }, [dispatch, open]);

  const handleFetchReps = useCallback(() => {
    const fetchQuery = {
      limit: SCROLL_LIMIT,
      role: "sales",
      cursor: salesList[salesList.length - 1].cursor,
    };
    dispatch(getRepsAction(fetchQuery, { isScrolling: true }));
  }, [dispatch, salesList]);

  useEffect(() => {
    reset({
      paymentTermsId: "",
      representativeId: "",
      territoryId: "",
      note: "",
    });
  }, [open, reset]);

  const { list: paytermsList } = usePayTermsActions({
    open,
    params: {
      status: JSON.stringify(["ACTIVE"]),
      filter_advance_payment: true,
      limit: null,
      page: null,
    },
  });

  return (
    <Dialog maxWidth="xl" onClose={handleClose} open={open}>
      <Box
        sx={cl.wrapper}
        component="form"
        id="order-direct-application-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={cl.title}>
          <Box sx={cl.title.textWrapper}>
            <Typography sx={cl.title.text}>Order Direct Application</Typography>
            {/* temporarily hidden */}
            {/* <IconButton onClick={() => onClickPrint(item?.id)}>
              <Box sx={cl.title.iconBtn}>
                <PrinterIcon width="15.38" height="13.47" />
              </Box>
            </IconButton> */}
          </Box>
          <Box sx={cl.title.crossWrapper}>
            <IconButton onClick={handleClose}>
              <CrossBigIcon size="15.5" />
            </IconButton>
          </Box>
        </Box>

        <Box sx={cl.addresses}>
          {/* Billing Address */}
          <Box sx={cl.addresses.billing}>
            <Typography sx={cl.addresses.title} noWrap>
              Billing Address
            </Typography>
            <Typography sx={cl.addresses.name} noWrap>
              {name}
            </Typography>
            <Typography sx={cl.addresses.info} noWrap>
              {billingAddress}
            </Typography>
          </Box>
          {/* Shipping Address */}
          <Box sx={cl.addresses.shipping}>
            <Typography sx={cl.addresses.title} noWrap>
              Shipping Address
            </Typography>
            <Typography sx={cl.addresses.name} noWrap>
              {name}
            </Typography>
            <Typography sx={cl.addresses.info} noWrap>
              {shippingAddress}
            </Typography>
          </Box>
          {/* Delivery Contact */}
          <Box sx={cl.addresses.delivery}>
            <Typography sx={cl.addresses.title} noWrap>
              Primary Contact
            </Typography>
            {item?.contacts?.length > 0 ? (
              <>
                <Typography sx={cl.addresses.name} noWrap>
                  {contactName}
                </Typography>
                <Typography sx={cl.addresses.info} noWrap>
                  {contactPhone}
                </Typography>
                <Typography sx={cl.addresses.info} noWrap>
                  {contactEmail}
                </Typography>
              </>
            ) : (
              <Typography sx={cl.addresses.name} noWrap>
                No contacts found
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={cl.details}>
          <BusinessInfoBlock
            {...{ name, federalTaxId, businessPhone, businessEmail }}
          />

          <Box sx={cl.details.rightWrapper}>
            <Box sx={cl.details.selectsWrapper}>
              <Box sx={cl.details.settings}>
                <Typography sx={cl.details.name} noWrap>
                  Settings
                </Typography>
              </Box>

              {!hideRequestPaymentTerms && paymentTerm && (
                <Box my={-1} ml={1} display="flex" width="100%">
                  <Typography sx={cl.details.name} noWrap width="60%">
                    Requested Payment Terms:{" "}
                  </Typography>
                  <Typography
                    ml={1}
                    sx={cl.details.name}
                    fontWeight={300}
                    noWrap
                    width="40%"
                  >
                    {paymentTerm}
                  </Typography>
                </Box>
              )}

              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledSelect
                    notched={false}
                    fullWidth
                    height="30px"
                    formSx={cl.details.select}
                    size="small"
                    color={setLabelColor(field.value)}
                    displayEmpty
                    noErrorMessage
                    error={error?.message}
                    border="0.5px solid #D5D9D9"
                    {...field}
                  >
                    <MenuItem sx={{ display: "none" }} value="">
                      Approved payment terms
                    </MenuItem>
                    {paytermsList?.map((term) => (
                      <MenuItem key={term?.id} value={term?.id}>
                        {term?.name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
                name="paymentTermsId"
                control={control}
              />

              <Box width="100%">
                <StyledTooltip
                  key={1}
                  title="No sales reps found"
                  placement="top"
                  arrow
                  open={openTooltip && !salesList?.length}
                  onOpen={() => setOpenTooltip(true)}
                  onClose={() => setOpenTooltip(false)}
                >
                  <Box>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledSelect
                          disabled={!salesList?.length}
                          notched={false}
                          fullWidth
                          height="30px"
                          formSx={cl.details.select}
                          size="small"
                          color={setLabelColor(field.value)}
                          displayEmpty
                          noErrorMessage
                          error={error?.message}
                          border="0.5px solid #D5D9D9"
                          {...field}
                          dataLength={salesList?.length}
                          dataCount={salesCount}
                          handleFetch={handleFetchReps}
                        >
                          <MenuItem sx={{ display: "none" }} value="">
                            Assign Sales Rep
                          </MenuItem>
                          {salesList?.map((sale) => (
                            <MenuItem key={sale?.id} value={sale?.id}>
                              {sale?.name}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      )}
                      name="representativeId"
                      control={control}
                    />
                  </Box>
                </StyledTooltip>
              </Box>

              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledSelect
                    notched={false}
                    fullWidth
                    height="30px"
                    formSx={cl.details.select}
                    size="small"
                    color={setLabelColor(field.value)}
                    displayEmpty
                    noErrorMessage
                    error={error?.message}
                    border="0.5px solid #D5D9D9"
                    {...field}
                  >
                    <MenuItem sx={{ display: "none" }} value="">
                      Assign Territory
                    </MenuItem>
                    <MenuItem value="">Uncategorized</MenuItem>
                    {territoryList?.map((ter) => (
                      <MenuItem key={ter?.id} value={ter?.id}>
                        {ter?.name}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
                name="territoryId"
                control={control}
              />

              <Controller
                render={({ field }) => (
                  <TextField
                    sx={cl.details.notes.border}
                    placeholder="Notes"
                    multiline
                    rows={3}
                    fullWidth
                    InputProps={{ style: cl.details.notes }}
                    {...field}
                  />
                )}
                name="note"
                control={control}
              />

              <Box sx={cl.details.btnWrapper}>
                <StyledTooltip
                  placement="top"
                  arrow
                  title="Add note to reject"
                  PopperProps={{
                    modifiers: [
                      { name: "offset", options: { offset: [0, -10] } },
                    ],
                  }}
                  disableHoverListener={!!note}
                >
                  <Box>
                    <Button
                      disabled={
                        ["APPROVED", "REJECTED"].includes(loading) ||
                        !note.trim()
                      }
                      sx={cl.details.btn}
                      variant="contained"
                      color="confirmDelete"
                      onClick={() => onClickReject({ note })}
                    >
                      {loading === "REJECTED" ? "Loading..." : "Reject"}
                    </Button>
                  </Box>
                </StyledTooltip>
                <Button
                  disabled={["APPROVED", "REJECTED"].includes(loading)}
                  sx={cl.details.btn}
                  variant="contained"
                  type="submit"
                  form="order-direct-application-form"
                >
                  {loading === "APPROVED" ? "Loading..." : "Approve"}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

OrderDirectApplicationDialog.propTypes = {
  open: bool,
  handleClose: func,
  item: shape({
    billingAddress: shape({
      appartement: oneOfType([string, instanceOf(null)]),
      city: oneOfType([string, instanceOf(null)]),
      formatted_address: oneOfType([string, instanceOf(null)]),
      lat: oneOfType([number, instanceOf(null)]),
      lng: oneOfType([number, instanceOf(null)]),
      state: oneOfType([string, instanceOf(null)]),
      street: oneOfType([string, instanceOf(null)]),
      zip: oneOfType([string, instanceOf(null)]),
    }),
    shippingAddress: shape({
      appartement: oneOfType([string, instanceOf(null)]),
      city: oneOfType([string, instanceOf(null)]),
      formatted_address: oneOfType([string, instanceOf(null)]),
      lat: oneOfType([number, instanceOf(null)]),
      lng: oneOfType([number, instanceOf(null)]),
      state: oneOfType([string, instanceOf(null)]),
      street: oneOfType([string, instanceOf(null)]),
      zip: oneOfType([string, instanceOf(null)]),
    }),
    name: string,
    contacts: arrayOf(
      shape({
        defaultContact: bool,
        email: string,
        id: number,
        role: string,
        phone: string,
        name: string,
      })
    ),
    phone: string,
    baseUser: shape({
      email: string,
    }),
    paymentTerms: string,
  }),

  onClickPrint: func,
  onSubmit: func,
  onClickReject: func,
  loading: string,
};
OrderDirectApplicationDialog.defaultProps = {
  onClickPrint: () => {},
  onClickReject: () => {},
  onSubmit: () => {},
};
