import { bool, func, object } from "prop-types";
import moment from "moment-timezone";
import { Controller } from "react-hook-form";
import {
  Divider,
  Grid,
  IconButton,
  Typography,
  Box,
  Drawer,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import {
  StyledTextField,
  StyledButton,
  LargeCheckbox,
  FileLabel,
  UploadFiles,
  ImagePreview,
  StyledTooltip,
} from "components";
import { CrossIcon } from "components/Icons";
import { DownloadUpIcon } from "components/Icons/DownloadUpIcon";
import useStyles from "./styles";
import { LICENSES } from "utils/constants";
import { generateUUID } from "helpers/helpers";
import { useUploadFile } from "./useUploadFile";
import { useLicensesDrawer } from "./useLicensesDrawer";
import { useShowPicture } from "./useShowPicture";
import LicensesDeletePopup from "Pages/CustomersPage/pages/CustomerPage/components/CustomerMainBody/components/LicensesBlock/LicensesDeletePopup";
import { useCallback, useEffect, useState } from "react";
import {
  openDiscardChanges,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { confirmDialogFormChangedSelector } from "redux/selectors/confirmDialogs";
import { DueDateBlock } from "components/TaskDrawer/components";
import { currentUserSelector } from "redux/selectors/auth";

const selector = createSelector(
  confirmDialogFormChangedSelector,
  currentUserSelector,
  (formChanged, currentUser) => ({ formChanged, currentUser })
);

export const LicensesDrawer = ({
  isOpen,
  handleClose,
  handleSetData,
  editLicense,
  setDialogState,
  handleDeleteLicenseFile,
  handleDeleteLicense,
}) => {
  const dispatch = useDispatch();
  const { formChanged, currentUser } = useSelector(selector);
  const { timeZone } = currentUser || {};

  const classes = useStyles();

  const {
    currentAttachment,
    setCurrentAttachment,
    handleUpload,
    handleDeleteFile,
    loadingUploadFile,
  } = useUploadFile();

  const initDate = {
    value: moment().tz(timeZone).hours(0).minutes(0).seconds(0).utc().format(),
    isValid: true,
  };

  const {
    formField,
    setValue,
    control,
    trigger,
    tooltipMessage,
    isButtonDisabled,
    isDirty,
    setError,
    clearErrors,
  } = useLicensesDrawer({
    isOpen,
    editLicense,
    currentAttachment,
    setCurrentAttachment,
    setDialogState,
    initDate,
  });

  const { openImagePreview, image, handleShowPicture, handleCloseShowPicture } =
    useShowPicture();

  const {
    licenseName,
    licenseOtherName,
    licenseNumber,
    isExpirationDateChecked,
    date,
    isAddButtonHovered,
  } = formField || {};

  const onSave = useCallback(() => {
    const preparedType =
      LICENSES.find((l) => l.name === licenseName).type || "OTHER";

    const preparedData = {
      type: preparedType,
      documentNumber: licenseNumber,
    };

    if (editLicense?.id) {
      preparedData.id = editLicense.id;
    }

    if (!editLicense?.id && editLicense?.tempId) {
      preparedData.tempId = editLicense.tempId;
    } else {
      preparedData.tempId = generateUUID();
    }

    if (preparedType === "OTHER") preparedData.name = licenseOtherName;

    if (isExpirationDateChecked && date?.isValid) {
      preparedData.expirationDate = date?.value;
    } else {
      preparedData.expirationDate = null;
    }

    if (currentAttachment?.length) {
      preparedData.attachments = currentAttachment;
    }

    handleSetData(preparedData);
    handleClose();
  }, [
    currentAttachment,
    date?.isValid,
    date?.value,
    editLicense?.id,
    editLicense?.tempId,
    handleClose,
    handleSetData,
    isExpirationDateChecked,
    licenseName,
    licenseNumber,
    licenseOtherName,
  ]);

  const [deleteLicense, setDeleteLicense] = useState(null);

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));

    if (!isOpen) dispatch(setFormChangedAction(false));
  }, [isDirty, dispatch, isOpen]);

  const preventCloseDrawer = useCallback(
    ({ onClose }) => {
      // eslint-disable-next-line no-console
      if (!onClose) return console.error("onClose is a required parameter");

      if (formChanged) {
        return dispatch(
          openDiscardChanges(
            () => onClose(),
            async () => {
              const resTrigger = await trigger();
              if (resTrigger && !isButtonDisabled && !loadingUploadFile) {
                onSave(formField);
              } else {
                dispatch(setFormChangedAction(isDirty));
              }
            }
          )
        );
      }

      onClose();
    },
    [
      dispatch,
      formChanged,
      formField,
      isButtonDisabled,
      isDirty,
      loadingUploadFile,
      onSave,
      trigger,
    ]
  );

  const isValidDate = (str) => {
    if (!str) return false;
    const parsedDate = moment(str, "M/D/YYYY", true);

    return parsedDate.isValid();
  };

  return (
    <>
      <LicensesDeletePopup
        isOpen={!!deleteLicense}
        license={deleteLicense}
        handleClose={() => setDeleteLicense(null)}
        onDelete={() => {
          handleDeleteLicense(editLicense);
          handleClose();
        }}
      />
      <ImagePreview
        open={openImagePreview && !!image}
        onClose={handleCloseShowPicture}
        image={image}
      />
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => preventCloseDrawer({ onClose: handleClose })}
      >
        <Box
          sx={{ borderBottom: "0.5px solid #D5D9D9", background: "#F8F8F8" }}
        >
          <IconButton
            sx={{ position: "absolute", top: 14, right: 14, p: 0 }}
            onClick={() => preventCloseDrawer({ onClose: handleClose })}
          >
            <CrossIcon />
          </IconButton>
          <Typography
            p="14px 0 12px 26px"
            fontWeight="400"
            fontSize="17px"
            color="#3F3F3F"
          >
            Add licenses & certifications
          </Typography>
        </Box>
        <Box sx={{ p: "10px 40px 21px 36px", width: "545px" }}>
          <Grid display="flex" sx={{ flexDirection: "column" }} container>
            <RadioGroup
              value={licenseName}
              onChange={(e, newVal) => {
                setValue("licenseName", newVal);
                setValue("licenseOtherName", "");
              }}
              className={classes.radioGroup}
            >
              {LICENSES.map((license) => (
                <FormControlLabel
                  key={license.name}
                  label={license.name}
                  control={<Radio />}
                  value={license.name}
                />
              ))}
            </RadioGroup>

            <Divider sx={{ mt: "17px", mb: "12px" }} />

            {licenseName === "Other" && (
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    adminIsAllowed
                    fullWidth
                    margin="normal"
                    label="License name"
                    size="small"
                    InputProps={{ className: classes.input }}
                    InputLabelProps={{ className: classes.inputLabel }}
                    error={error ? error.message : ""}
                    noErrorMessage
                    {...field}
                  />
                )}
                name="licenseOtherName"
                control={control}
              />
            )}

            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  adminIsAllowed
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  label="License #"
                  size="small"
                  InputProps={{ className: classes.input }}
                  InputLabelProps={{ className: classes.inputLabel }}
                  error={error ? error.message : ""}
                  noErrorMessage
                  {...field}
                />
              )}
              name="licenseNumber"
              control={control}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LargeCheckbox
                adminIsAllowed
                checked={isExpirationDateChecked}
                formSx={{ ml: "-9px", mr: 0 }}
                label={
                  <Typography color="#9C9C94" fontSize="11px" fontWeight="400">
                    Add expiration date
                  </Typography>
                }
                onClick={() =>
                  setValue("date", initDate, { shouldDirty: true })
                }
                onChange={(e) => {
                  setValue("isExpirationDateChecked", e.target.checked);
                }}
                size={16}
              />
            </Box>
            {isExpirationDateChecked && (
              <Box my={1}>
                <DueDateBlock
                  {...{
                    formField,
                    timeZone,
                    setValue,
                    control,
                    isValidDate,
                    setError,
                    clearErrors,
                    fieldName: "date",
                    showDefaultTime: false,
                    skipTime: true,
                    label: "MM / DD / YYYY",
                    disablePast: false,
                  }}
                />
              </Box>
            )}

            <Box mt="5px" display="flex" justifyContent="flex-end">
              <Box width="300px">
                {currentAttachment?.map((f) => {
                  const fileName = f?.fileName?.split("/")?.pop();

                  return (
                    <FileLabel
                      key={f?.id}
                      name={fileName}
                      size={Math.floor(f?.size / 1024)}
                      handleDeleteFile={() => {
                        handleDeleteFile(f);
                        if (handleDeleteLicenseFile) handleDeleteLicenseFile(f);
                      }}
                      license={{}}
                      handleShowPicture={() => handleShowPicture(f)}
                    />
                  );
                })}
              </Box>
            </Box>

            <Box ml="auto">
              <Box display="flex" alignItems="center" height="38px">
                <UploadFiles
                  variant="outlined"
                  sx={{
                    borderColor: "#d5d9d9",
                    height: "38px !important",
                    background: "#ffffff !important",
                  }}
                  startIcon={<DownloadUpIcon style={{ fontSize: "14px" }} />}
                  adminIsAllowed
                  disabled={loadingUploadFile}
                  label={loadingUploadFile ? "Loading..." : "Attach license"}
                  multiple
                  onChange={handleUpload}
                />
              </Box>
              <Typography color="#707070" fontSize="14px" ml="17px">
                Max. 10 MB
              </Typography>
            </Box>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
            background: "#F8F8F8",
            p: "24px 42px 24px 42px",
            mt: "auto",
          }}
        >
          <Box>
            {!!editLicense && !!handleDeleteLicense && (
              <StyledButton
                color="confirmDelete"
                sx={{
                  height: "31px",
                  width: "78px",
                  boxShadow: "none",
                }}
                onClick={() => setDeleteLicense(editLicense)}
                label={
                  <Typography fontSize={15} fontWeight={400}>
                    Delete
                  </Typography>
                }
                variant="contained"
              />
            )}
          </Box>

          <Box display="flex">
            <StyledButton
              sx={{ height: 31, borderColor: "#D5D9D9" }}
              onClick={() => preventCloseDrawer({ onClose: handleClose })}
              label={
                <Typography
                  fontSize="15px"
                  sx={{ color: "#5f6267" }}
                  fontWeight="normal"
                >
                  Cancel
                </Typography>
              }
              variant="text"
            />
            <StyledTooltip
              title={tooltipMessage}
              open={isAddButtonHovered && !!tooltipMessage}
              placement="top"
              arrow
            >
              <Box
                onMouseEnter={() => {
                  trigger();
                  setValue("isAddButtonHovered", true);
                }}
                onMouseLeave={() => {
                  setValue("isAddButtonHovered", false);
                }}
              >
                <StyledButton
                  variant="contained"
                  onClick={onSave}
                  disabled={isButtonDisabled}
                  label={editLicense ? "Edit " : "Add"}
                  fontSize="15px"
                  sx={{
                    height: "31px",
                    width: "78px",
                    boxShadow: "none",
                    ml: "10px",
                  }}
                />
              </Box>
            </StyledTooltip>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

LicensesDrawer.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleSetData: func,
  editLicense: object,
  dialogState: object,
  setDialogState: func,
  handleDeleteLicenseFile: func,
  handleDeleteLicense: func,
};

export default LicensesDrawer;
