import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Box, Button, Paper, TextField, Typography } from "@mui/material";

import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./Oauth2.validations";
import { cl } from "./Oauth2.styles";
import { getAuthorizationCodeService } from "services/oauth2";
import { MainLogoIcon } from "components/Icons/MainLogos";
import { toast } from "react-toastify";
import { errorConfig } from "utils/notifications";

export const Oauth2 = () => {
  const [searchParams] = useSearchParams();

  const { main_wrapper, wrapper, paper, text_field, login_btn } = cl;

  const clientId = searchParams.get("client_id");
  const redirectUri = searchParams.get("redirect_uri");

  const [state, setState] = useState({
    loading: false,
  });

  const { loading } = state;

  const { control, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      clientId: clientId,
      redirectUri: redirectUri?.replace(/\/$/, ""),
      email: "",
      password: "",
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const onSubmit = useCallback(
    async (data) => {
      try {
        setState((prev) => ({ ...prev, loading: true }));
        const { code } = await getAuthorizationCodeService(data);
        window.location.href = `${formField?.redirectUri}/?code=${code}`;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err?.message);
        toast.error(
          err?.response?.data?.message || "Something went wrong",
          errorConfig
        );
        setState((prev) => ({ ...prev, loading: false }));
      }
    },
    [formField?.redirectUri]
  );

  if (!clientId || !redirectUri)
    return <Box sx={wrapper}>Something went wrong</Box>;

  return (
    <Box sx={main_wrapper}>
      <Paper sx={paper} elevation={0} variant="outlined" square>
        <Box mt="48px">
          <MainLogoIcon width={307} height={45} />
        </Box>

        <Box textAlign="center" px={15} mt="76px">
          <Typography fontSize={26} fontWeight={600} color="#5F6267">
            Enter SimplyDepo credentials to connect with Shipstation
          </Typography>
        </Box>

        <Box width="510px" mt={5}>
          <Controller
            render={({ field, fieldState: { error } }) => {
              return (
                <TextField
                  fullWidth
                  error={!!error?.message}
                  InputProps={{ style: text_field }}
                  placeholder="Email"
                  {...field}
                />
              );
            }}
            name="email"
            control={control}
          />
        </Box>

        <Box width="510px" mt={2.5}>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <TextField
                type="password"
                fullWidth
                error={!!error?.message}
                InputProps={{ style: text_field }}
                placeholder="Password"
                {...field}
              />
            )}
            name="password"
            control={control}
          />
        </Box>

        <Box width="510px" mt={8.25} mb={11.75}>
          <Button
            sx={login_btn}
            fullWidth
            variant="contained"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? "Loading..." : "Log In"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default Oauth2;
