export const cl = {
  accordionComponent: {
    m: "0!important",
    "& .MuiAccordionSummary-root": {
      backgroundColor: "transparent",
      borderRadius: "0px",
      p: "8px 16px",
      height: "48px !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      backgroundColor: "transparent",
      borderBottom: "none",
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.12)",
    },
    "&.MuiPaper-root.MuiAccordion-root.MuiPaper-root.MuiAccordion-root": {
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "1px solid #E5E5E5",

      "&:last-child": {
        borderBottom: "none",
      },
      borderRadius: 0,
    },

    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },

    "& .MuiAccordionSummary-content": {
      alignItems: "center",
      m: 0,
    },
  },
  detailsComponent: {
    backgroundColor: "#FBFBFB !important",
    p: "7px",
  },
  childWrapper: {
    width: "100%",
    borderBottom: "0.5px solid rgba(217, 217, 217, 0.25)",
  },
};
