import {
  filterByMOQ,
  filterByMPA,
} from "Pages/DiscountsPage/components/ApplyDiscountPage/components/MSDPage/MSDPage.helpers";

export const getMOQDisabled = (discount, prodDict) => {
  if (prodDict[discount.id].length) return filterByMOQ(discount, prodDict);
};

export const getMPADisabled = (discount, prodDict) => {
  if (prodDict[discount.id].length) return filterByMPA(discount, prodDict);
};

export const checkDisabled = (discount, cartState, formField, prodDict) => {
  const sameManufacturerMSD = cartState.availableMSDs
    ?.map((dis) => dis.discounts)
    .flat()
    .filter((msd) => msd.manufacturer.id === discount.manufacturer.id);

  const oneSiblingChecked = sameManufacturerMSD?.some((child) =>
    formField.manufacturerDiscounts
      .filter((checked) => checked.manufacturer?.id === child?.manufacturer?.id)
      .find((checked) => {
        if (checked.productType === "ALL_PRODUCTS")
          return (
            (checked.id === child.id ||
              checked.manufacturerDiscountId === child.id) &&
            child.id !== discount.id
          );
        if (checked.productType === "SPECIFIC_PRODUCTS") {
          return (
            discount.productType === "ALL_PRODUCTS" ||
            checked.products.some(
              (p) =>
                checked.id !== discount.id &&
                checked.manufacturerDiscountId !== discount.id &&
                discount.products.find(
                  ({ product: compareProduct }) =>
                    compareProduct?.id === p.product?.id ||
                    compareProduct?.id === p.productId
                )
            )
          );
        }
        return false;
      })
  );

  return (
    getMOQDisabled(discount, prodDict) ||
    getMPADisabled(discount, prodDict) ||
    oneSiblingChecked
  );
};

export const countMSDs = (cartState, customerExist, formField, prodDict) => {
  let enabledCount = 0;

  if (!cartState.availableMSDs.length || !customerExist) return enabledCount;
  cartState.availableMSDs?.map((obj) => {
    if (obj.discounts.length) {
      obj.discounts.forEach((discount) => {
        const isAdded = formField.manufacturerDiscounts.some(
          ({ id }) => id === discount.id
        );

        const exceededLimit =
          discount?.limit && discount?.limit <= discount?.usageCount;

        if (
          !isAdded &&
          !exceededLimit &&
          !checkDisabled(discount, cartState, formField, prodDict)
        ) {
          enabledCount++;
        }
      });
    }
  });
  return enabledCount;
};
