import React, { useCallback, useEffect, useMemo, useState } from "react";
import { string, object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { CrossBigIcon, EyeIcon } from "components/Icons";
import ManufacturersHeader from "./ManufacturersTable/ManufacturersHeader";
import ManufacturersItem from "./ManufacturersTable/ManufacturersItem";
import {
  manufacturersListSelector,
  manufacturersCountSelector,
  manufacturersLoadingSelector,
  manufacturersInactiveListSelector,
  manufacturersInactiveCountSelector,
  manufacturersExistSelector,
  manufacturersExistInactiveSelector,
} from "redux/selectors/manufacturers";
import {
  getManufacturersAction,
  deleteManufacturerAction,
  updateManufacturerAction,
  getManufacturersInactiveAction,
} from "redux/actions/manufacturers";
import { FETCH_LIMITS, SCROLL_LIMIT } from "utils/constants.js";

import { FilterSearchTextField } from "../../../components/TextFields/TextFields";
import { useDebounce } from "../../../helpers/hooks";
import useStyles from "./styles";
import CustomerContactsHeader from "../../../components/ContactsSection/CustomerContactsHeader";
import CustomerContactItem from "../../CustomersPage/pages/NewCustomerPage/CustomerContactItem";
import { getProductsAction } from "redux/actions/products";
import ActiveTabs from "../../../components/ActiveTabs";
import {
  EmptyScreen,
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
} from "components";

const selector = createSelector(
  manufacturersListSelector,
  manufacturersCountSelector,
  manufacturersLoadingSelector,
  manufacturersInactiveListSelector,
  manufacturersInactiveCountSelector,
  manufacturersExistSelector,
  manufacturersExistInactiveSelector,
  (
    manufacturersList,
    manufacturersCount,
    loading,
    manufacturersInactiveList,
    manufacturersInactiveCount,
    manufacturersExist,
    manufacturersExistInactive
  ) => ({
    manufacturersList,
    manufacturersCount,
    loading,
    manufacturersInactiveList,
    manufacturersInactiveCount,
    manufacturersExist,
    manufacturersExistInactive,
  })
);

const ManufacturersTab = ({ currentTab, repPermissions }) => {
  const classes = useStyles();
  const {
    manufacturersList,
    manufacturersCount,
    loading,
    manufacturersInactiveList,
    manufacturersInactiveCount,
    manufacturersExist,
    manufacturersExistInactive,
  } = useSelector(selector);

  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  const [anchorEl, setAnchorEl] = useState(null);
  const [contactsList, setContactsList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showInactive, setShowInactive] = useState(false);
  const searchInputDebounced = useDebounce(searchInput, 500);

  const handleDeleteManufacturer = useCallback(
    (id) => {
      dispatch(deleteManufacturerAction(id));
    },
    [dispatch]
  );

  const handleArchiveManufacturer = useCallback(
    (id, status) => {
      dispatch(
        updateManufacturerAction({ id, data: { status }, archiving: true })
      );
    },
    [dispatch]
  );

  const handleFetchManufacturers = useCallback(() => {
    dispatch(
      getManufacturersAction({
        search: searchInputDebounced,
        status: '["active", "inactive_for_customers"]',
        page: page + 1,
      })
    );
    setPage((prev) => prev + 1);
  }, [dispatch, page, searchInputDebounced]);

  const handleFetchInactiveManufacturers = useCallback(() => {
    dispatch(
      getManufacturersInactiveAction({
        search: searchInputDebounced,
        status: '["inactive"]',
        page: page + 1,
      })
    );
    setPage((prev) => prev + 1);
  }, [dispatch, page, searchInputDebounced]);

  useEffect(() => {
    setPage(1);
    if (!showInactive) {
      dispatch(
        getManufacturersAction({
          limit: SCROLL_LIMIT,
          search: searchInputDebounced,
          status: '["active", "inactive_for_customers"]',
          page: 1,
        })
      );
    }

    if (showInactive) {
      dispatch(
        getManufacturersInactiveAction({
          limit: SCROLL_LIMIT,
          search: searchInputDebounced,
          status: '["inactive"]',
          page: 1,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputDebounced, dispatch, showInactive]);

  const handleChangeTab = (e, newVal) => {
    setShowInactive(newVal);
    setPage(1);
  };

  const [limitContacts, setLimitContacts] = useState(2 * FETCH_LIMITS.CONTACTS);
  const handleFetchContacts = () => {
    dispatch(
      getProductsAction({
        query: {
          // getContactsAction
          limit: limitContacts,
        },
      })
    );
    setLimitContacts(limitContacts + FETCH_LIMITS.CONTACTS);
  };

  const currentList = useMemo(
    () => (showInactive ? manufacturersInactiveList : manufacturersList),
    [manufacturersInactiveList, manufacturersList, showInactive]
  );

  const currentListExist = useMemo(
    () => (showInactive ? manufacturersExistInactive : manufacturersExist),
    [manufacturersExistInactive, manufacturersExist, showInactive]
  );

  return (
    <Box sx={{ px: "26px", mt: "12px" }}>
      <Paper
        sx={{
          height: "64px",
          alignItems: "center",
          width: "100%",
          boxShadow: "none",
          border: "0.5px solid #D5D9D9",
          display: "flex",
          justifyContent: "flex-end",
          borderRadius: "4px 4px 0 0",
          p: "12px 17px 12px 13px",
          gap: "15px",
        }}
      >
        <ActiveTabs value={showInactive} onChange={handleChangeTab} />
        <FilterSearchTextField
          placeholder="Search manufacturers by name, ID or email"
          fullWidth
          value={searchInput}
          loading={loading && manufacturersExist}
          onChange={(e) => setSearchInput(e.target.value)}
          handleClearValue={() => setSearchInput("")}
        />
      </Paper>
      {currentList.length && currentListExist ? (
        <>
          <ManufacturersHeader />
          <Stack
            sx={{
              height: "100%",
              maxHeight: "calc(100vh - 242px)",
              overflow: "hidden",
              position: "relative",
              borderWidth: "0 1px 1px 1px",
              borderStyle: "solid",
              borderColor: "#D5D9D9",
              borderRadius: "0 0 4px 4px",
            }}
            className="infinite-scroll-custom-scrollbar"
          >
            <Dialog
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
              PaperProps={{
                sx: {
                  maxWidth: "784px",
                },
              }}
            >
              <DialogContent
                sx={{
                  width: "784px",
                  height: "auto",
                  padding: "4px 16px 10px 16px",
                }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <EyeIcon />
                    <Typography className={classes.blockTitle}>
                      Contacts
                    </Typography>
                  </Box>
                  <IconButton onClick={() => setAnchorEl(null)}>
                    <CrossBigIcon size={21} />
                  </IconButton>
                </Box>
                <Box>
                  {contactsList.length !== 0 && <CustomerContactsHeader />}

                  <InfiniteScrollWrapper
                    maxHeight="calc(100vh - 286px)"
                    dataLength={contactsList.length}
                    loading={loading}
                    resetCallback={() => setPage(1)}
                    nextFunc={handleFetchContacts}
                    hasMoreItems={contactsList.length < 4}
                    loader={
                      <CircularProgress
                        sx={{ ml: "50%", mt: "2px" }}
                        size="30px"
                      />
                    }
                    hasMore={contactsList.length < 4} // contactsCount
                    id="all-products-scroll-table"
                  >
                    {contactsList.map((contact, index) => (
                      <CustomerContactItem
                        key={contact.id || contact.fid}
                        contact={contact}
                        IsLastItem={contactsList?.length === index + 1}
                        viewOnly
                      />
                    ))}
                  </InfiniteScrollWrapper>
                </Box>
              </DialogContent>
            </Dialog>

            <InfiniteScrollWrapper
              dataLength={currentList.length}
              resetCallback={() => setPage(1)}
              next={
                showInactive
                  ? handleFetchInactiveManufacturers
                  : handleFetchManufacturers
              }
              loader={
                <CircularProgress
                  sx={{ ml: "calc(50% - 15px)", mt: "2px" }}
                  size="30px"
                />
              }
              maxHeight="calc(100vh - 243px)"
              loading={loading}
              hasMore={
                showInactive
                  ? manufacturersInactiveList?.length <
                    manufacturersInactiveCount
                  : manufacturersList.length < manufacturersCount
              }
              id="sorted-scroll-table"
            >
              {(showInactive
                ? manufacturersInactiveList
                : manufacturersList
              ).map((manufacturer, index) => {
                return (
                  <ManufacturersItem
                    currentTab={currentTab}
                    key={manufacturer.id || manufacturer.customId}
                    lastItem={currentList?.length === index + 1}
                    manufacturer={manufacturer}
                    setContactsList={setContactsList}
                    handleDeleteManufacturer={handleDeleteManufacturer}
                    handleArchiveManufacturer={handleArchiveManufacturer}
                    setContactsAnchorEl={setAnchorEl}
                    repPermissions={repPermissions}
                  />
                );
              })}
              {showInactive
                ? manufacturersInactiveList?.length < manufacturersInactiveCount
                : manufacturersList.length < manufacturersCount && (
                    <InfiniteLoadMoreBtn
                      onClick={
                        showInactive
                          ? handleFetchInactiveManufacturers
                          : handleFetchManufacturers
                      }
                    />
                  )}
            </InfiniteScrollWrapper>
          </Stack>
        </>
      ) : (
        <EmptyScreen
          type="manufacturers"
          height="calc(100vh - 470px)"
          showAction={false}
          loading={loading}
        />
      )}
    </Box>
  );
};

ManufacturersTab.propTypes = {
  currentTab: string,
  repPermissions: object,
};
export default ManufacturersTab;
