import { array, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { largestTerritory } from "helpers/helpers";
import { SimpleArrowDownIcon } from "components/Icons";

export const TerritoryBlock = ({
  territories,
  setTerritoriesList,
  setAnchorEl,
}) => {
  const terList = largestTerritory(territories);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      {terList?.length ? (
        <>
          {territories.length > 1 ? (
            <Box display="flex" sx={{ width: "100%" }}>
              <Box position="relative" width="30px">
                <Box
                  sx={{
                    position: "absolute",
                    top: "1px",
                    right: 0,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    cursor: territories.length ? "pointer" : "auto",
                  }}
                  onClick={(e) => {
                    if (!territories.length) return;
                    setTerritoriesList(territories);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  {territories.length > 0 ? (
                    <Typography
                      noWrap
                      sx={{ fontSize: "12px", color: "#47A06D", mr: "2px" }}
                    >
                      +{territories?.length - 1}
                    </Typography>
                  ) : null}

                  {territories.length ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: "2px",
                        width: "10px",
                      }}
                    >
                      <SimpleArrowDownIcon width="20px" />
                    </Box>
                  ) : (
                    <Box width="34px" />
                  )}
                </Box>
              </Box>

              <Typography
                noWrap
                sx={{
                  color: "#1C1C19",
                  fontSize: 12,
                  width: "100%",
                }}
              >
                {terList}
              </Typography>
            </Box>
          ) : (
            <>
              <Box width="30px" />
              <Typography
                noWrap
                sx={{ color: "#1C1C19", fontSize: 12, width: "100%" }}
              >
                {terList}
              </Typography>
            </>
          )}
        </>
      ) : (
        <>
          <Box width="30px" />
          <Typography sx={{ textAlign: "center" }}>-</Typography>
        </>
      )}
    </Box>
  );
};

TerritoryBlock.propTypes = {
  territories: array,
  setTerritoriesList: func,
  setAnchorEl: func,
};
TerritoryBlock.defaultProps = {
  territories: [],
  setTerritoriesList: () => {},
  setAnchorEl: () => {},
};
