import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import { error, success } from "utils/notifications";
import { useCategoryList } from "helpers/useCategoryList";
import { validationSchema } from "./AddCategoryDrawer.validations";
import {
  checkCategoryInfoAvailabilityService,
  createCategoryService,
  deleteCategoryByIdService,
  getCategoryByIdService,
  undoDeleteCategoryService,
  updateCategoryByIdService,
} from "services/categories";
import {
  openConfirmDialogAction,
  openDiscardChanges,
  setConfirmIsOpenAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { Typography } from "@mui/material";
import { useDebounce, useRepsPermissions } from "helpers/hooks";
import { handlePrepareSubmitData } from "./AddCategoryDrawer.helpers";
import { GlobalPageContext } from "Pages/MasterPage/MasterPage";
import { formatLongWords } from "helpers/helpers";

export const DEFAULT_CATEGORY_STATE = {
  name: "",
  isSubcategory: false,
  parentCategoryId: "",
  isAllUncheckedProducts: true,
  selectedCount: 0,
  changedIds: [],
  categoryProducts: [],
  showAllProducts: false,
  selectedInactiveCount: 0,
  inactiveCategoryProducts: [],
  showAllInactiveProducts: false,
  isAllUncheckedInactiveProducts: true,
};

export const useCategoriesDrawer = ({
  openState,
  onSaveCallback,
  onClose,
  creatingMsg = "Category created",
}) => {
  const { setGlobalLoading } = useContext(GlobalPageContext);
  const repPermissions = useRepsPermissions();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const isEdit = !!openState?.element;
  const isParent = !openState?.parent?.id;

  const dispatch = useDispatch();

  const [loadingState, setLoadingState] = useState({
    loading: false,
    getDataLoading: false,
  });

  const onLoadingStateChange = useCallback(
    (newState) => {
      setLoadingState((prev) => ({ ...prev, ...newState }));
    },
    [setLoadingState]
  );

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: DEFAULT_CATEGORY_STATE,
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const nameDebounced = useDebounce(formField.name, 500);

  const getCategoryById = useCallback(
    async (id) => {
      if (!id) return;
      onLoadingStateChange({ getDataLoading: true });
      try {
        const res = await getCategoryByIdService(id);

        const { allProducts, allInactiveProducts, products, inactiveProducts } =
          res || {};

        const { name, parentCategoryId } = openState?.element || {};

        reset({
          name: name || DEFAULT_CATEGORY_STATE.name,
          isSubcategory:
            !!openState?.parent?.id ||
            !!parentCategoryId ||
            DEFAULT_CATEGORY_STATE.isSubcategory,
          parentCategoryId:
            openState?.parent?.id ||
            parentCategoryId ||
            DEFAULT_CATEGORY_STATE.parentCategoryId,
          categoryProducts: products || DEFAULT_CATEGORY_STATE.categoryProducts,
          selectedCount:
            products?.length || DEFAULT_CATEGORY_STATE.selectedCount,
          showAllProducts: allProducts,
          isAllUncheckedProducts: false,
          inactiveCategoryProducts:
            inactiveProducts || DEFAULT_CATEGORY_STATE.inactiveCategoryProducts,
          selectedInactiveCount:
            inactiveProducts?.length ||
            DEFAULT_CATEGORY_STATE.selectedInactiveCount,
          showAllInactiveProducts: allInactiveProducts,
          isAllUncheckedInactiveProducts: false,
        });

        setSelectedCategory(res);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        onLoadingStateChange({ getDataLoading: false });
      }
    },
    [onLoadingStateChange, openState?.element, openState?.parent?.id, reset]
  );

  useEffect(
    () => !openState.state && reset(DEFAULT_CATEGORY_STATE),
    [reset, openState.state]
  );

  useEffect(() => {
    if (!openState?.element?.id)
      return reset({
        ...DEFAULT_CATEGORY_STATE,
        ...(openState?.parent?.id
          ? {
              isSubcategory: true,
              parentCategoryId: openState?.parent?.id,
            }
          : {}),
      });
    getCategoryById(openState?.element?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openState?.element?.id, openState?.parent?.id]);

  useEffect(() => {
    if (!nameDebounced || nameDebounced === openState?.element?.name) return;

    checkCategoryInfoAvailabilityService({
      name: nameDebounced?.toLowerCase().trim(),
    }).then((res) => {
      if (!res?.available) {
        setError("name", {
          type: "uniq",
          message:
            "A category with the same name already exists. Please choose a different name.",
        });
        return;
      }
      clearErrors("name");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearErrors, nameDebounced, setError]);

  const onCloseDrawer = useCallback(() => {
    reset(DEFAULT_CATEGORY_STATE);
    onClose();
    setSelectedCategory(null);
  }, [onClose, reset]);

  const onUndoDeletion = useCallback(
    async (id) => {
      setGlobalLoading(true);
      try {
        await undoDeleteCategoryService(id);
        onSaveCallback();
        success("Category restored");
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setGlobalLoading(false);
      }
    },
    [onSaveCallback, setGlobalLoading]
  );

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));
  }, [isDirty, dispatch]);

  const onDeleteConfirm = useCallback(async () => {
    setLoadingState({ loading: true });
    // let toastId;
    dispatch(setConfirmIsOpenAction(false));
    try {
      const res = await deleteCategoryByIdService(openState?.element?.id);
      onSaveCallback();
      success("Category Deleted", {
        icon: "info",
        data: {
          undo: {
            text: "Undo",
            callback: () => onUndoDeletion(res.deletedCategoryId),
          },
        },
      });
      // toastId = success(
      //   <ToastUndoBlock
      //     text="Category deleted"
      //     undoCallback={() => onUndoDeletion(res.deletedCategoryId)}
      //     onClose={() => toast.dismiss(toastId)}
      //   />,
      //   { options: { autoClose: 4000 }, closeOnClick: true }
      // );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setLoadingState({ loading: false });
    }
  }, [dispatch, onSaveCallback, onUndoDeletion, openState?.element?.id]);

  const onConfirmProductsSelect = useCallback(
    (callback, discardCallback) => {
      dispatch(
        openConfirmDialogAction({
          title: "Confirm Product Update",
          text: (
            <Typography fontWeight="400" fontSize="15px" whiteSpace="pre-line">
              The selected products will be moved to the{" "}
              <span style={{ fontWeight: "500" }}>
                {formatLongWords(openState?.element?.name)}
              </span>{" "}
              category. Would you like to proceed?
            </Typography>
          ),
          isIcon: true,
          propBtns: {
            left: {
              label: "Cancel",
              color: "cancel",
              variant: "outlined",
              sx: {
                width: "110px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              },
              onClick: () => {
                !!discardCallback && discardCallback();
                dispatch(setConfirmIsOpenAction(false));
              },
            },
            right: {
              sx: {
                width: "110px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
              },
              label: "Proceed",
              color: "primary",
              variant: "contained",
              onClick: () => {
                dispatch(setConfirmIsOpenAction(false));
                callback();
              },
            },
          },
        })
      );
    },
    [dispatch, openState?.element?.name]
  );

  const onDelete = useCallback(() => {
    dispatch(
      openConfirmDialogAction({
        title: "Confirm Category Deletion",
        text: (
          <Typography fontWeight="400" fontSize="15px" whiteSpace="pre-line">
            This action will delete the category. All products within this
            category will be marked as{" "}
            <span style={{ fontWeight: "500" }}>&#39;Uncategorized.&#39;</span>{" "}
          </Typography>
        ),
        isIcon: true,
        propBtns: {
          left: {
            label: "Cancel",
            color: "cancel",
            variant: "outlined",
            sx: {
              width: "78px",
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            },
          },
          right: {
            sx: {
              width: "78px",
              color: "#FFFFFF",
              fontSize: "13px",
              height: "28px",
              boxShadow: "none",
              backgroundColor: "#EB4233",
            },
            label: "Delete",
            color: "error",
            variant: "contained",
            onClick: () => {
              onDeleteConfirm();
            },
          },
        },
      })
    );
  }, [dispatch, onDeleteConfirm]);

  const onSubmit = useCallback(
    async (data) => {
      setLoadingState({ loading: true });
      const preparedData = handlePrepareSubmitData(data, isEdit);

      try {
        if (isEdit) {
          await updateCategoryByIdService(openState?.element?.id, preparedData);
          onSaveCallback();
          return success("Category updated");
        }

        await createCategoryService(preparedData);
        onSaveCallback();
        success(creatingMsg);
        dispatch(setFormChangedAction(false));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setLoadingState({ loading: false });
      }
    },
    [dispatch, isEdit, onSaveCallback, openState?.element?.id, creatingMsg]
  );

  const preventCloseDrawer = useCallback(() => {
    if (isDirty) {
      return dispatch(
        openDiscardChanges(
          () => onCloseDrawer(),
          async () => {
            const resTrigger = await trigger();
            if (resTrigger && !Object.keys(errors).length) {
              onSubmit(formField);
            } else {
              dispatch(setFormChangedAction(isDirty));
            }
          }
        )
      );
    }

    onClose();
  }, [
    isDirty,
    onClose,
    dispatch,
    onCloseDrawer,
    trigger,
    errors,
    onSubmit,
    formField,
  ]);

  const onSaveProductsFromPopup = (data) => {
    const {
      changedIds,
      checkedProducts,
      selectedCount,
      showAllProducts,
      isAllUnchecked,
      changedInactiveIds,
      checkedInactiveProducts,
      selectedInactiveCount,
      showAllInactiveProducts,
      isAllUncheckedInactiveProducts,
    } = data;

    setValue("changedInactiveIds", changedInactiveIds, {
      shouldDirty: true,
    });

    setValue("selectedInactiveCount", selectedInactiveCount, {
      shouldDirty: true,
    });
    setValue("inactiveCategoryProducts", checkedInactiveProducts, {
      shouldDirty: true,
    });
    setValue(
      "showAllInactiveProducts",
      isAllUncheckedInactiveProducts ? false : showAllInactiveProducts
    );
    setValue("isAllUncheckedInactiveProducts", isAllUncheckedInactiveProducts);

    setValue("changedIds", changedIds, {
      shouldDirty: true,
    });

    setValue("selectedCount", selectedCount, {
      shouldDirty: true,
    });
    setValue("categoryProducts", checkedProducts, { shouldDirty: true });
    setValue("showAllProducts", isAllUnchecked ? false : showAllProducts);
    setValue("isAllUncheckedProducts", isAllUnchecked);
  };

  const resetSelectedProducts = () => {
    setValue("changedIds", []);
    setValue("changedInactiveIds", []);
    if (selectedCategory) {
      setValue("selectedCount", selectedCategory?.products.length);
      setValue("categoryProducts", selectedCategory?.products);
      setValue("showAllProducts", selectedCategory?.allProducts);
      setValue("isAllUncheckedProducts", true);

      setValue(
        "selectedInactiveCount",
        selectedCategory?.inactiveProducts.length
      );
      setValue("inactiveCategoryProducts", selectedCategory?.inactiveProducts);
      setValue(
        "showAllInactiveProducts",
        selectedCategory?.allInactiveProducts
      );
      setValue("isAllUncheckedInactiveProducts", true);
    }
  };

  const {
    loading: categoriesLoading,
    list: categoriesList,
    count: categoriesCount,
    page: categoriesPage,
    handleFetchCategories,
  } = useCategoryList({
    allowFetch: formField.isSubcategory,
    params: {
      exclude_uncategorized: true,
    },
  });

  const disabledDeleteCategories = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.catalog?.delete_categories;
    }
  }, [repPermissions]);

  return {
    isEdit,
    handleSubmit,
    control,
    onSubmit,
    formField,
    errors,
    clearErrors,
    isDirty,
    disabledSaveButton:
      !isDirty || !!Object.keys(errors).length || !formField.name,
    loading: loadingState.loading || loadingState.getDataLoading,
    getDataLoading: loadingState.getDataLoading,
    onCloseDrawer: preventCloseDrawer,
    categoriesLoading,
    categoriesList,
    categoriesCount,
    categoriesPage,
    handleFetchCategories,
    onDelete,
    setValue,
    isParent,
    onConfirmProductsSelect,
    onSaveProductsFromPopup,
    resetSelectedProducts,
    availableProducts: isEdit ? selectedCategory?.products || [] : [],
    disabledDeleteCategories,
  };
};
