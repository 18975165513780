import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentUser, updateUserFieldAction } from "redux/actions/auth";
import { updateDistributorOnboardingService } from "services/account";
import { error } from "utils/notifications";

export const useOnboardingActions = () => {
  const currentUser = useSelector(({ auth }) => auth?.currentUser);
  const onboarding = currentUser?.onboarding;
  const onboardingIsOpen = currentUser?.onboardingIsOpen;

  const [loadingOnboarding, setLoadingOnboarding] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isOpenDialog = useMemo(
    () => !!onboarding || onboardingIsOpen,
    [onboarding, onboardingIsOpen]
  );

  const [paymentTermsError, setPaymentTermsError] = useState(false);

  const stateOnboarding = useMemo(
    () => [
      {
        name: "defaultFulfillmentLocation",
        title: "Default Fulfillment Location",
        status: !!onboarding?.defaultFulfillmentLocation,
        date: onboarding?.defaultFulfillmentLocationCreatedAt || "",
        nav: () =>
          navigate("/settings", {
            state: {
              tab: "Profile",
              profileTab: 1,
              goBack: false,
              type: "onboarding",
            },
          }),
      },
      {
        name: "primaryContacts",
        title: "Primary Contacts",
        subTitle: "Set default primary contacts.",
        status: !!onboarding?.primaryContacts,
        date: onboarding?.primaryContactsCreatedAt || "",
        nav: () =>
          navigate("/settings", {
            state: {
              tab: "Profile",
              profileTab: 0,
              goBack: false,
              type: "onboarding",
            },
          }),
      },
      {
        name: "representatives",
        title: "Representatives",
        subTitle: "Add or import representative information.",
        status: !!onboarding?.representatives,
        date: onboarding?.representativesCreatedAt || "",
        nav: () =>
          navigate("/representatives", { state: { type: "onboarding" } }),
      },
      {
        name: "products",
        title: "Products",
        subTitle: "Add or import product information.",
        status: !!onboarding?.products,
        date: onboarding?.productsCreatedAt || "",
        nav: () => navigate("/catalog", { state: { type: "onboarding" } }),
      },
      {
        name: "paymentTerms",
        title: "Payment Terms",
        subTitle: "Set primary payment term for customers",
        status: !!onboarding?.paymentTerms,
        date: onboarding?.paymentTermsCreatedAt || "",
        hasError: paymentTermsError,
        nav: () =>
          navigate("/settings", {
            state: {
              tab: "Payment Terms",
              profileTab: 5,
              goBack: false,
              type: "onboarding",
            },
          }),
      },
      {
        name: "customers",
        title: "Customers",
        subTitle: "Add or import customer information.",
        status: !!onboarding?.customers,
        date: onboarding?.customersCreatedAt || "",
        nav: () => {
          if (!onboarding?.paymentTerms && !onboarding?.paymentTermsCreatedAt) {
            // has the Payment Terms step been completed?
            setPaymentTermsError(true);
            return;
          }
          navigate("/customers", { state: { type: "onboarding" } });
        },
        titleTooltip: "Complete payments step first",
        showTooltip: paymentTermsError,
      },
      {
        name: "notifications",
        title: "Notifications",
        subTitle: "Configure push notifications for critical events.",
        status: !!onboarding?.notifications,
        date: onboarding?.notificationsCreatedAt || "",
        nav: () =>
          navigate("/settings", {
            state: {
              tab: "Representatives",
              goBack: false,
              type: "onboarding",
            },
          }),
      },
    ],
    [navigate, onboarding, paymentTermsError]
  );

  const preparedData = {
    notifications: !!onboarding?.notifications,
  };

  const fetch = async (data) => {
    setLoadingOnboarding(true);
    try {
      const res = await updateDistributorOnboardingService(data);
      const hasAllStatusesTrue = res?.data?.onboarding
        ? Object.values(res.data.onboarding).every((v) => !!v)
        : false;

      if (hasAllStatusesTrue) {
        const { data } = await updateDistributorOnboardingService();
        dispatch(setCurrentUser(data));
        return;
      }
      dispatch(setCurrentUser(res.data));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setLoadingOnboarding(false);
    }
  };

  const handleFetchOnboarding = async (name) => {
    if (name !== "notifications") return;
    preparedData[name] = true;
    fetch(preparedData);
  };

  const handleCloseOnboarding = () => {
    dispatch(updateUserFieldAction("onboardingIsOpen", null));
  };

  useEffect(() => {
    if (onboarding) dispatch(updateUserFieldAction("onboardingIsOpen", true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateOnboarding.every((el) => el.status) && !loadingOnboarding) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboarding]);

  return {
    isOpenDialog,
    stateOnboarding,
    handleFetchOnboarding,
    handleCloseOnboarding,
  };
};
