/* eslint-disable no-useless-escape */
import * as Yup from "yup";

const PHONE_REGEXP =
  /^[\+][\d]{1}[\s]?[\(]?[\d]{3}[\)]?[\s]?[\d]{3}[\s]?[\d]{4}$/im;

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().trim().required("This field is required").max(25),
    email: Yup.string()
      .trim()
      .required("This field is required")
      .test("emailValidation", "Email not valid!", (value) => {
        const EMAIL_REGEXP =
          /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d)|(([a-z]|\d)([a-z]|\d|-|\.|_|~)*([a-z]|\d)))\.)+(([a-z])|(([a-z])([a-z]|\d|-|\.|_|~)*([a-z])))$/i; // eslint-disable-line
        return EMAIL_REGEXP.test(value);
      }),

    phone: Yup.string()
      .trim()
      .when("role", {
        is: (role) => role === "THIRD_PARTY",
        then: Yup.string()
          .trim()
          .test("phoneValidation", "Not valid phone number!", (value) => {
            if (!value) return true;

            return (
              value === "+1" ||
              value === "+1 (___) ___ ____" ||
              PHONE_REGEXP.test(value)
            );
          }),
        otherwise: Yup.string()
          .trim()
          .required("This field is required")
          .test("phoneValidation", "Not valid phone number!", (value) => {
            if (!value) return true;
            return PHONE_REGEXP.test(value);
          }),
      }),
    role: Yup.string().trim().required("This field is required"),
    customId: Yup.string()
      .trim()
      .test("customIdValidation", "From 1 to 10 characters", (value) => {
        if (!value) return true;
        return value.length <= 10 && value.length >= 1;
      }),
    notificationSettings: Yup.object({
      clockInAt: Yup.string()
        .trim()
        .nullable()
        .when("type", {
          is: "INDIVIDUAL",
          then: Yup.string()
            .trim()
            .nullable()
            .required("This field is required")
            .test("clockInAt", "This field is required", (value) => {
              // return !!value && ctx?.parent?.clockIn;
              return !!value;
            }),
        }),
      clockOutAt: Yup.string()
        .trim()
        .nullable()
        .when("type", {
          is: "INDIVIDUAL",
          then: Yup.string()
            .trim()
            .nullable()
            .required("This field is required")
            .test("clockOutAt", "This field is required", (value) => {
              // return !!value && ctx?.parent?.clockOut;
              return !!value;
            }),
        }),
      days: Yup.array().when("type", {
        is: "INDIVIDUAL",
        then: Yup.array().test(
          "daysValidation",
          "You should choose some days",
          (value) => !!value?.length
        ),
      }),
    }),
  });
