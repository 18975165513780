export const cl = {
  selectSx: {
    height: "40px !important",
    "& .MuiSelect-select": {
      fontWeight: 400,
      fontSize: "13px",
      lineHeight: "20px",
      color: "#5F6267",
    },
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    "& > fieldset": {
      borderColor: "transparent !important",
    },

    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#409A65 !important",
    },
    boxShadow: "0px 1.5px 3px 0px rgba(0, 0, 0, 0.16)",
  },

  buttonSx: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "20px",
    color: "#5F6267",
    height: "40px",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#FFFFFF",
    },
    boxShadow: "0px 1.5px 3px 0px rgba(0, 0, 0, 0.16)",
    display: "flex",
  },
  buttonIconSx: {
    mr: "6px",
    pr: "2px",
    color: "#5F6267",
  },
};
