import { Controller } from "react-hook-form";
import { array, bool, func, number, object, string } from "prop-types";

import { truncateText } from "helpers/helpers";
import { LargeCheckbox, StyledSelect } from "components";

import { MenuItem, Stack } from "@mui/material";

export const SubcategoriesBlock = ({
  control,
  isEdit,
  isParent,
  categoriesList,
  categoriesCount,
  categoriesPage,
  formField,
  handleFetchCategories,
  categoriesLoading,
  setValue,
  resetSelectedProducts,
  parentName = "",
  clearErrors = () => {},
}) => {
  return (
    <>
      {((isEdit && !isParent) || !isEdit) && (
        <Stack
          sx={{
            pb: "20px",
            borderBottom: "0.50px #D9D9D9 solid",
            mb: "8px",
          }}
          gap="12px"
        >
          <Controller
            render={({ field }) => (
              <LargeCheckbox
                checked={!!field.value}
                size={16}
                labelSx={{
                  fontSize: "14px",
                  color: "#6A6A6A",
                  ml: "6px",
                  fontWeight: 300,
                }}
                label="Is subcategory"
                formSx={{ m: 0 }}
                sx={{ padding: 0 }}
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  setValue("parentCategoryId", "");
                  resetSelectedProducts();
                }}
              />
            )}
            name="isSubcategory"
            control={control}
          />

          {formField.isSubcategory && (
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  fontSize="14px"
                  height="44px"
                  label="Parent category"
                  formSx={{
                    fontSize: "14px",
                    "& .MuiInputLabel-root.Mui-focused": { top: 1.5 },
                    "& .MuiInputLabel-shrink": { top: 1.5 },
                  }}
                  InputProps={{ style: { fontSize: "14px !important" } }}
                  labelProps={{ sx: { top: 3 } }}
                  labelSx={{ fontSize: "14px" }}
                  fullWidth
                  error={!!error}
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    if (error) clearErrors("parentCategoryId");
                  }}
                  dataLength={categoriesList?.length}
                  dataCount={categoriesCount}
                  handleFetch={() => handleFetchCategories(categoriesPage + 1)}
                >
                  <MenuItem
                    sx={{ display: "none" }}
                    value={formField.parentCategoryId}
                  >
                    {parentName}
                  </MenuItem>
                  {categoriesList?.length && !categoriesLoading
                    ? categoriesList.map((category) => (
                        <MenuItem key={category?.id} value={category?.id}>
                          {truncateText(category?.name, 70)}
                        </MenuItem>
                      ))
                    : null}
                </StyledSelect>
              )}
              name="parentCategoryId"
              control={control}
            />
          )}
        </Stack>
      )}
    </>
  );
};

SubcategoriesBlock.propTypes = {
  control: object,
  onEdit: func,
  setValue: func,
  resetSelectedProducts: func,
  isEdit: bool,
  categoriesList: array,
  categoriesCount: number,
  categoriesPage: number,
  formField: object,
  handleFetchCategories: func,
  categoriesLoading: bool,
  isParent: bool,
  parentName: string,
  clearErrors: func,
};
