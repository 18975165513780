import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes, {
  any,
  array,
  bool,
  func,
  number,
  object,
  string,
} from "prop-types";
import { ExpandMore } from "@mui/icons-material";
import { CalendarIcon, ClockIcon, CrossBigIcon, SearchIcon } from "../Icons";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDebounce } from "helpers/hooks";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import NumberFormat from "react-number-format";
import { useAdmin } from "helpers/helpers";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { VALIDATION_ADDRESS_ERROR } from "utils/constants";
import ClearIcon from "@mui/icons-material/Clear";
import { cl } from "./TextFields.styles";

const styles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(213,217,217)",
    },
  },
};

const StyledTextField = forwardRef(
  (
    {
      error,
      label,
      fullWidth,
      formSx,
      noErrorMessage,
      endIcon,
      labelSx,
      startAdornment,
      inputSx,
      errorMsgSx,
      LabelProps,
      adminIsAllowed,
      disabled,
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin(adminIsAllowed);
    return (
      <FormControl
        fullWidth={fullWidth}
        error={!!error}
        sx={{ ...styles, ...formSx }}
      >
        <TextField
          helperText={noErrorMessage ? "" : error}
          size="small"
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              bottom: -20,
              left: 8,
              margin: 0,
              whiteSpace: "nowrap",
              ...errorMsgSx,
            },
          }}
          InputProps={{
            sx: {
              height: "32px",
              fontSize: "12px",
              ...inputSx,
            },
            endAdornment: endIcon,
            startAdornment,
          }}
          InputLabelProps={{
            sx: {
              fontSize: "12px",
              fontWeight: 400,
              color: "#B5B5AC",
              ...labelSx,
            },
            ...LabelProps,
          }}
          fullWidth={fullWidth}
          label={label}
          error={!!error}
          inputRef={ref}
          {...props}
          adminIsAllowed
          disabled={disabled || isAdmin}
          data-testid="styled-text-field"
        />
      </FormControl>
    );
  }
);

StyledTextField.propTypes = {
  error: PropTypes.oneOfType([string, bool]),
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  noErrorMessage: bool,
  endIcon: any,
  labelSx: object,
  startAdornment: any,
  inputSx: object,
  errorMsgSx: object,
  LabelProps: object,
  disabled: bool,
  adminIsAllowed: bool,
};

StyledTextField.defaultProps = {
  error: "",
  label: "",
  fullWidth: true,
  noErrorMessage: true,
  adminIsAllowed: false,
};

StyledTextField.displayName = "StyledTextField";

const PriceFormat = forwardRef(({ type, adminIsAllowed, ...props }, ref) => {
  const isAdmin = useAdmin(adminIsAllowed);
  const [focused, setFocused] = useState(false);
  return (
    <NumberFormat
      customInput={StyledTextField}
      adminIsAllowed={adminIsAllowed}
      fullWidth
      size="small"
      placeholder={type === "ABSOLUTE" ? "0.00" : "0"}
      thousandSeparator={type === "ABSOLUTE"}
      decimalScale={type === "ABSOLUTE" ? 2 : 0}
      fixedDecimalScale={type === "ABSOLUTE" && !focused}
      disabled={isAdmin || props?.disabled}
      ref={ref}
      onBlur={() => setFocused(false)}
      onFocus={() => setFocused(true)}
      {...props}
    />
  );
});

PriceFormat.displayName = "PriceFormat";

PriceFormat.propTypes = { type: string, disabled: bool, adminIsAllowed: bool };
PriceFormat.defaultProps = { type: "ABSOLUTE", adminIsAllowed: false };

const PlaceAutocompleteField = forwardRef(
  (
    {
      error,
      label,
      value,
      fullWidth,
      loading,
      formSx,
      handleSetCustomer,
      setCustomerLoading,
      noErrorMessage,
      paperStyles,
      InputProps,
      country,
      adminIsAllowed,
      ignorePathnameSetFocused,
      locationBias,
      customAdornment,
      adornmentProps,
      placeholderWidth,
      showSearchIcon,
      showLoading = false,
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin(adminIsAllowed);
    const valueDebounced = useDebounce(value, 400);
    const [fetchable, setFetchable] = useState(true);
    const [focused, setFocused] = useState(false);
    const { pathname } = useLocation();

    const currentUser = useSelector(({ auth }) => auth.currentUser);
    const userCountry =
      country || (currentUser && currentUser?.country) || "US";

    const {
      placesService,
      placePredictions,
      getPlacePredictions,
      isPlacePredictionsLoading,
      refreshSessionToken,
    } = usePlacesService({
      debounce: 300,
      // eslint-disable-next-line no-undef
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      options: {
        types: ["establishment"],
        componentRestrictions: { country: userCountry },
        locationRestriction: locationBias,
      },
      language: "en",
      libraries: ["places", "drawing", "geometry"],
      sessionToken: true,
    });

    useEffect(() => {
      if (!ignorePathnameSetFocused) {
        setFocused(false);
      }
    }, [ignorePathnameSetFocused, pathname]);

    useEffect(() => {
      if (fetchable && focused) {
        getPlacePredictions({
          input: valueDebounced,
          componentRestrictions: { country: userCountry },
          locationRestriction: locationBias,
        });
      }
      setFetchable(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueDebounced, locationBias]);

    const handleCloseClickHere = () => {
      getPlacePredictions({ input: "" });
    };

    const loadingVar = useMemo(() => {
      return showLoading ? isPlacePredictionsLoading : loading;
    }, [isPlacePredictionsLoading, loading, showLoading]);

    return (
      <FormControl
        fullWidth={fullWidth}
        error={!!error}
        sx={{
          "& input": { pl: showSearchIcon ? "30px" : null },
          ...formSx,
          ...styles,
        }}
      >
        <TextField
          helperText={noErrorMessage ? "" : error}
          FormHelperTextProps={{
            sx: { position: "absolute", bottom: -16, left: 0, margin: 0 },
          }}
          InputProps={{
            sx: {
              fontSize: "12px",
              height: "32px",
            },
            autoComplete: "new-password",
            form: {
              autocomplete: "off",
            },
            endAdornment: (
              <>
                {showSearchIcon && (
                  <InputAdornment
                    sx={{ position: "absolute", left: 9 }}
                    position="start"
                  >
                    <SearchIcon />
                  </InputAdornment>
                )}
                {!customAdornment && isPlacePredictionsLoading && (
                  <InputAdornment position="end">
                    <CircularProgress size="20px" />
                  </InputAdornment>
                )}
                {customAdornment && (
                  <InputAdornment
                    position="end"
                    {...adornmentProps}
                    sx={{
                      ...adornmentProps?.sx,
                      width: placeholderWidth
                        ? `calc(100% - ${placeholderWidth})`
                        : "100%",
                    }}
                  >
                    {loadingVar && (
                      <CircularProgress size="20px" sx={{ mr: "8px" }} />
                    )}
                    {customAdornment}
                  </InputAdornment>
                )}
              </>
            ),
            ...InputProps,
          }}
          fullWidth={fullWidth}
          label={label}
          error={!!error}
          ref={ref}
          onFocus={() => {
            setFocused(true);
            getPlacePredictions({
              input: value,
              componentRestrictions: { country: userCountry },
            });
          }}
          onClick={() => setFocused(true)}
          {...props}
          disabled={isAdmin || props?.disabled}
          value={value}
        />
        {!!placePredictions.length && (
          <ClickAwayListener
            onClickAway={() => {
              getPlacePredictions({ input: "" });
            }}
          >
            <Paper
              sx={{
                width: "100%",
                position: "absolute",
                top: 32,
                backgroundColor: "#ffffff",
                zIndex: 100,
                border: "0.5px solid #d5d9d9",
                ...paperStyles,
              }}
            >
              {placePredictions.map((place) => (
                <Box
                  p="8px"
                  key={place.reference}
                  sx={{
                    cursor: "pointer",
                    borderBottom: "0.5px solid #d5d9d9",
                  }}
                  onClick={() => {
                    setFetchable(false);

                    if (setCustomerLoading) {
                      setCustomerLoading(true);
                    }

                    placesService?.getDetails(
                      {
                        placeId: place.place_id,
                      },
                      (placeDetails) => {
                        handleSetCustomer(placeDetails);
                        if (setCustomerLoading) {
                          setCustomerLoading(false);
                        }
                        refreshSessionToken();
                      }
                    );
                    getPlacePredictions({ input: "" });
                  }}
                >
                  <Typography color="#1C1C19" fontSize="12px" fontWeight="400">
                    {place.structured_formatting.main_text}
                  </Typography>
                  <Typography color="#1C1C19" fontSize="10px" fontWeight="400">
                    {place.structured_formatting.secondary_text}
                  </Typography>
                </Box>
              ))}
              <Box display="flex" p="15px 8px">
                <Typography fontSize="12px" ml="3px">
                  Can&apos;t find your name?
                </Typography>
                <Typography
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: "12px",
                    ml: "3px",
                  }}
                  onClick={handleCloseClickHere}
                >
                  Click Here
                </Typography>
              </Box>
            </Paper>
          </ClickAwayListener>
        )}
      </FormControl>
    );
  }
);

PlaceAutocompleteField.displayName = "PlaceAutocompleteField";

PlaceAutocompleteField.propTypes = {
  error: string,
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  value: string,
  loading: bool,
  handleSetCustomer: func,
  setCustomerLoading: func,
  noErrorMessage: bool,
  paperStyles: object,
  disabled: bool,
  country: string,
  InputProps: object,
  adminIsAllowed: bool,
  ignorePathnameSetFocused: bool,
  locationBias: object,
  customAdornment: object,
  adornmentProps: object,
  placeholderWidth: string,
  showSearchIcon: bool,
  showLoading: bool,
};

PlaceAutocompleteField.defaultProps = {
  error: "",
  label: "",
  fullWidth: true,
  value: "",
  loading: false,
  noErrorMessage: false,
  country: "",
  adminIsAllowed: false,
  ignorePathnameSetFocused: false,
};

const GoogleAutocompleteField = forwardRef(
  (
    {
      error,
      label,
      fullWidth,
      formSx,
      noErrorMessage,
      onPlaceSelected,
      inputSx,
      endAdornment,
      value,
      setValue,
      typeSetValue,
      country,
      errorMsgSx,
      adminIsAllowed,
      placePredictionsSx,
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin(adminIsAllowed);
    const valueDebounced = useDebounce(value, 400);
    const [fetchable, setFetchable] = useState(true);
    const [focused, setFocused] = useState(false);

    const hasValidationLocationError = useMemo(
      () => error === VALIDATION_ADDRESS_ERROR,
      [error]
    );

    const currentUser = useSelector(({ auth }) => auth.currentUser);
    const userCountry =
      country || (currentUser && currentUser?.country) || "US";

    const {
      placesService,
      placePredictions,
      getPlacePredictions,
      refreshSessionToken,
    } = usePlacesService({
      // eslint-disable-next-line no-undef
      apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
      inputAutocompleteValue: "country",
      options: {
        types: ["geocode", "establishment"],
        componentRestrictions: { country: userCountry },
      },
      language: "en",
      libraries: ["places", "drawing", "geometry"],
      sessionToken: true,
    });

    useEffect(() => {
      if (setValue && typeSetValue && placePredictions?.length > 0) {
        setValue(typeSetValue, placePredictions?.length * 54 + 26);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placePredictions]);

    useEffect(() => {
      if (fetchable && focused) {
        getPlacePredictions({
          input: valueDebounced,
          componentRestrictions: { country: userCountry },
        });
        if (setValue && typeSetValue) setValue(typeSetValue, 0);
      }
      setFetchable(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueDebounced]);

    useEffect(() => {
      if (hasValidationLocationError) {
        setFocused(true);
        setFetchable(true);
        getPlacePredictions({
          input: value,
          componentRestrictions: { country: userCountry },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasValidationLocationError]);

    const styles = {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "rgb(213,217,217)",
        },
      },
      "& .MuiOutlinedInput-root:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#47A06D",
        },
      },
    };

    return (
      <FormControl
        fullWidth={fullWidth}
        error={!!error}
        sx={{ ...styles, ...formSx }}
      >
        <TextField
          size="small"
          helperText={noErrorMessage ? "" : error}
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              bottom: -20,
              left: 0,
              margin: 0,
              ...errorMsgSx,
            },
          }}
          InputProps={{
            sx: {
              height: "32px",
              fontSize: "12px",
              ...inputSx,
            },
            endAdornment,
            autoComplete: "new-text",
            form: {
              autocomplete: "off",
            },
          }}
          InputLabelProps={{
            sx: {
              fontSize: "12px",
              fontWeight: 400,
              color: "#B5B5AC",
            },
          }}
          fullWidth={fullWidth}
          label={label}
          error={!!error}
          ref={ref}
          {...props}
          disabled={isAdmin || props?.disabled}
          onFocus={() => {
            setFocused(true);
            getPlacePredictions({
              input: value,
              componentRestrictions: { country: userCountry },
            });
          }}
          //onBlur={() => setFocused(false)}
          value={value}
          onClick={() => setFocused(true)}
        />
        {!!placePredictions.length && (
          <ClickAwayListener
            onClickAway={() => {
              //if (
              //  currentRef.current &&
              //  !currentRef.current.contains(event.target)
              //) {
              setFocused(false);
              getPlacePredictions({ input: "" });
              if (setValue && typeSetValue) setValue(typeSetValue, 0);
              //}
            }}
          >
            <Paper
              sx={{
                position: "absolute",
                top: 44,
                backgroundColor: "#ffffff",
                zIndex: 100,
                border: "0.5px solid #d5d9d9",
                width: "100%",
                ...placePredictionsSx,
              }}
            >
              {placePredictions.map((place) => (
                <Box
                  key={place.reference}
                  data-testid="select-option"
                  sx={{
                    cursor: "pointer",
                    borderBottom: "0.5px solid #d5d9d9",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 0.5,
                    px: "8px",
                    height: "54px",
                  }}
                  onClick={() => {
                    setFetchable(false);
                    setFocused(false);
                    placesService?.getDetails(
                      {
                        placeId: place.place_id,
                      },
                      (placeDetails) => {
                        onPlaceSelected(placeDetails);
                        refreshSessionToken();
                      }
                    );
                    getPlacePredictions({ input: "" });
                    if (setValue && typeSetValue) setValue(typeSetValue, 0);
                  }}
                >
                  <Typography
                    color="#1C1C19"
                    fontSize={12}
                    fontWeight={400}
                    noWrap
                    mb="-4px"
                  >
                    {place.structured_formatting.main_text}{" "}
                  </Typography>
                  <Typography
                    color="#1C1C19"
                    fontSize={10}
                    fontWeight={400}
                    noWrap
                    mt="-4px"
                  >
                    {place.structured_formatting.secondary_text}
                  </Typography>
                </Box>
              ))}
              {hasValidationLocationError && (
                <Typography
                  sx={{
                    px: 1,
                    pt: 1,
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#F2545B",
                  }}
                >
                  {error}
                </Typography>
              )}
              <Box
                sx={{
                  px: 1,
                  py: 0.5,
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#A2A2A2",
                  textAlign: "end",
                }}
              >
                Powered by Google
              </Box>
            </Paper>
          </ClickAwayListener>
        )}
      </FormControl>
    );
  }
);

GoogleAutocompleteField.propTypes = {
  error: string,
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  noErrorMessage: bool,
  onPlaceSelected: func,
  inputSx: object,
  country: string,
  endAdornment: any,
  disabled: bool,
  value: string,
  setValue: func,
  typeSetValue: string,
  errorMsgSx: object,
  adminIsAllowed: bool,
  placePredictionsSx: object,
};

GoogleAutocompleteField.defaultProps = {
  error: "",
  label: "",
  fullWidth: true,
  country: "",
  noErrorMessage: false,
  value: "",
  errorMsgSx: {},
  adminIsAllowed: false,
};

GoogleAutocompleteField.displayName = "GoogleAutocompleteField";

const StyledAutocomplete = forwardRef(
  (
    {
      error,
      label = "",
      fullWidth = true,
      formSx = {},
      noErrorMessage = false,
      inputSx = {},
      value = "",
      errorMsgSx = {},
      adminIsAllowed = false,
      list = [],
      ChildItem = null,
      setSearchValue = () => {},
      InputProps = {},
      loading = false,
      dataLength = 0,
      dataCount = 0,
      handleFetch = () => {},
      onClear = () => {},
      showClearIcon = false,
      childItemProps = {},
      emptyPlaceholder = "",
      CustomItem = null,
      hideEmptyPlaceholder = false,
      PaperComponent = null,
      labelSx = {},
      PaperComponentProps = {},
      ...props
    },
    ref
  ) => {
    const PAPER_HEIGHT = 235;

    const inputRef = useRef(null);
    const formRef = useRef(null);

    const isAdmin = useAdmin(adminIsAllowed);
    const [open, setOpen] = useState(false);

    const fieldValue = useMemo(() => value || "", [value]);

    return (
      <FormControl
        ref={formRef}
        fullWidth={fullWidth}
        error={!!error}
        sx={{ ...cl.styledAutocomplete.formSx, ...formSx }}
      >
        <TextField
          size="small"
          helperText={noErrorMessage ? "" : error}
          FormHelperTextProps={{
            sx: {
              position: "absolute",
              bottom: -20,
              left: 0,
              margin: 0,
              ...errorMsgSx,
            },
          }}
          InputLabelProps={{
            sx: {
              fontSize: "12px",
              fontWeight: 400,
              color: "#B5B5AC",
              ...labelSx,
            },
          }}
          fullWidth={fullWidth}
          label={label}
          error={!!error}
          {...props}
          inputRef={inputRef}
          ref={ref}
          disabled={isAdmin || props?.disabled}
          value={fieldValue}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(true);
          }}
          InputProps={{
            sx: {
              height: "32px",
              fontSize: "12px",
              ...inputSx,
            },
            autoComplete: "new-text",
            form: {
              autocomplete: "off",
            },
            ...InputProps,
            endAdornment: (
              <>
                {InputProps?.endAdornment}
                {showClearIcon && (
                  <IconButton
                    sx={{ p: 0, mr: "2px" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onClear();
                      setOpen(false);
                      if (inputRef?.current) {
                        inputRef.current.focus();
                        setOpen(true);
                      }
                    }}
                  >
                    <ClearIcon sx={{ width: "20px", height: "20px" }} />
                  </IconButton>
                )}
                <IconButton
                  sx={{ p: 0 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setOpen(!open);
                  }}
                >
                  <ExpandMore
                    style={{
                      color: "#B5B5AC",
                      transition: "all 0.2s",
                      transform: open ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                </IconButton>
              </>
            ),
          }}
        />
        {open && (
          <Popper
            open={open}
            anchorEl={formRef?.current}
            placement="bottom-start"
            style={{ zIndex: 1300, width: formRef?.current?.offsetWidth }}
          >
            <ClickAwayListener
              onClickAway={() => {
                setOpen(false);
                setSearchValue("");
              }}
            >
              <Box component="span">
                {PaperComponent ? (
                  <PaperComponent {...PaperComponentProps} setOpen={setOpen} />
                ) : (
                  <Paper
                    onClick={() => {
                      setSearchValue("");
                      setOpen(false);
                    }}
                    sx={{
                      ...cl.styledAutocomplete.paperWrapper,
                      maxHeight: `${PAPER_HEIGHT}px`,
                    }}
                    onScroll={(e) => {
                      if (
                        e.target.scrollTop + PAPER_HEIGHT <
                          e.target.scrollHeight ||
                        dataLength >= dataCount
                      )
                        return;
                      handleFetch();
                    }}
                  >
                    {!!CustomItem && CustomItem}
                    {!!list.length &&
                      !loading &&
                      list.map((listItem) => (
                        <Box component="span" key={listItem?.id || listItem}>
                          {ChildItem ? (
                            <ChildItem {...childItemProps} item={listItem} />
                          ) : (
                            <MenuItem
                              {...childItemProps}
                              onClick={() => childItemProps?.onClick(listItem)}
                              value={listItem}
                            >
                              {listItem}
                            </MenuItem>
                          )}
                        </Box>
                      ))}

                    {!list.length && !loading && !hideEmptyPlaceholder && (
                      <Typography
                        sx={{
                          p: "8px 16px",
                          fontSize: 13,
                          fontWeight: 300,
                          color: "#1C1C19",
                          flexGrow: 1,
                        }}
                      >
                        {emptyPlaceholder}
                      </Typography>
                    )}
                    {(dataLength < dataCount || loading) && (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "30px",
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    )}
                  </Paper>
                )}
              </Box>
            </ClickAwayListener>
          </Popper>
        )}
      </FormControl>
    );
  }
);

StyledAutocomplete.propTypes = {
  error: string,
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  noErrorMessage: bool,
  setSearchValue: func,
  inputSx: object,
  endAdornment: any,
  disabled: bool,
  value: string,
  setValue: func,
  errorMsgSx: object,
  InputProps: object,
  adminIsAllowed: bool,
  loading: bool,
  list: array,
  ChildItem: any,
  showClearIcon: bool,
  dataLength: number,
  dataCount: number,
  handleFetch: func,
  onClear: func,
  emptyPlaceholder: string,
  childItemProps: object,
  labelSx: object,
  CustomItem: any,
  PaperComponent: any,
  PaperComponentProps: object,
  hideEmptyPlaceholder: bool,
};

StyledAutocomplete.displayName = "StyledAutocomplete";

const FilterSearchTextField = forwardRef(
  (
    {
      label,
      fullWidth,
      formSx,
      loading,
      handleClearValue,
      bgColor,
      customAdornment,
      adornmentProps,
      placeholderWidth,
      adminIsAllowed,
      inputPropsSx,
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin(adminIsAllowed);
    return (
      <FormControl fullWidth={fullWidth} sx={formSx}>
        <TextField
          label={label}
          size="small"
          InputLabelProps={{
            sx: {
              pl: "25px",
              pr: loading && "25px",
              fontSize: "15px",
              fontWeight: 400,
              transitionProperty: "all",
              "&.Mui-focused": {
                pl: 0,
                // opacity: 0,
              },
              "&.MuiFormLabel-filled": {
                pl: 0,
                // opacity: 0,
              },
            },
          }}
          InputProps={{
            sx: {
              height: "39px",
              pl: "25px",
              pr: "5px",
              fontSize: "15px",
              fontWeight: 400,
              backgroundColor: bgColor || "#ffffff",
              "& input": {
                width: placeholderWidth || "100%",
              },
              "&:hover": {
                "&& fieldset": {
                  backgroundColor: "rgba(213, 217, 217, 0.15)",
                  border: "0.5px solid #D5D9D9",
                },
              },
              "&.Mui-focused": {
                filter: "drop-shadow(0.1px 0.2px 2px #47A06D)",
                "& > fieldset.MuiOutlinedInput-notchedOutline": {
                  borderWidth: "0.3px",
                },
              },
              "& > fieldset": {
                border: "0.5px solid #D5D9D9",
              },
              ...inputPropsSx,
            },
            endAdornment: (
              <>
                <InputAdornment
                  sx={{ position: "absolute", left: 9 }}
                  position="start"
                >
                  <SearchIcon />
                </InputAdornment>
                {!customAdornment && loading && (
                  <InputAdornment position="end">
                    <CircularProgress size="20px" sx={{ mr: "8px" }} />
                  </InputAdornment>
                )}
                {customAdornment && (
                  <InputAdornment
                    position="end"
                    {...adornmentProps}
                    sx={{
                      ...adornmentProps?.sx,
                      width: placeholderWidth
                        ? `calc(100% - ${placeholderWidth})`
                        : "100%",
                    }}
                  >
                    {loading && (
                      <CircularProgress size="20px" sx={{ mr: "8px" }} />
                    )}
                    {customAdornment}
                  </InputAdornment>
                )}
                {props?.value && handleClearValue && (
                  <InputAdornment
                    position="end"
                    sx={{ transition: "all 0.3s ease" }}
                  >
                    <IconButton
                      id="search-clear-adornment"
                      onClick={handleClearValue}
                    >
                      <CrossBigIcon size={14} />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            ),
          }}
          ref={ref}
          {...props}
          disabled={isAdmin || props?.disabled}
        />
      </FormControl>
    );
  }
);

FilterSearchTextField.displayName = "FilterSearchTextField";

FilterSearchTextField.propTypes = {
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  loading: bool,
  value: any,
  handleClearValue: func,
  bgColor: string,
  customAdornment: object,
  adornmentProps: object,
  placeholderWidth: string,
  disabled: bool,
  adminIsAllowed: bool,
  inputPropsSx: object,
};

FilterSearchTextField.defaultProps = {
  label: "",
  value: "",
  fullWidth: false,
  loading: false,
  bgColor: "#ffffff",
  adornmentProps: { sx: {} },
  adminIsAllowed: false,
  inputPropsSx: {},
};

const SearchTextField = forwardRef(
  ({ label, fullWidth, formSx, adminIsAllowed, ...props }, ref) => {
    const isAdmin = useAdmin(adminIsAllowed);
    return (
      <FormControl sx={formSx}>
        <TextField
          sx={{ backgroundColor: "#F4F6F9" }}
          label={label}
          size="small"
          InputLabelProps={{
            sx: {
              top: -2,
              color: "#B5B5AC",
              fontSize: "12px",
              "&.Mui-focused": {
                color: "#42A57F",
                top: 0,
              },
              "&.MuiFormLabel-filled": {
                top: 0,
              },
            },
          }}
          InputProps={{
            sx: { height: "28px", fontSize: "12px" },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          ref={ref}
          {...props}
          disabled={isAdmin || props?.disabled}
        />
      </FormControl>
    );
  }
);

SearchTextField.displayName = "SearchTextField";

SearchTextField.propTypes = {
  label: PropTypes.oneOfType([string, object]),
  fullWidth: bool,
  formSx: object,
  disabled: bool,
  adminIsAllowed: bool,
};

SearchTextField.defaultProps = {
  label: "",
  fullWidth: false,
  adminIsAllowed: false,
};

const StyledTimePicker = forwardRef(
  (
    {
      textFieldProps,
      error,
      dateLibInstance,
      dateAdapter,
      timezone,
      adminIsAllowed,
      OpenPickerIcon,
      disabled,
      InputLabelProps = {},
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin(adminIsAllowed);
    const [open, setOpen] = useState(false);
    return (
      <LocalizationProvider dateAdapter={dateAdapter || AdapterDateFns}>
        <TimePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          timezone={timezone}
          dateLibInstance={dateLibInstance}
          renderInput={(params) => (
            <StyledTextField
              size="small"
              {...params}
              error={error}
              InputProps={{
                sx: {
                  height: "32px",
                  "& > input": {
                    textTransform: "uppercase",
                    color: "#1C1C19",
                  },
                },
                ...params.InputProps,
              }}
              InputLabelProps={{
                ...InputLabelProps,
              }}
              ref={ref}
              {...textFieldProps}
              onMouseDown={(e) => {
                e.preventDefault();
                if (isAdmin) return;
                if (!disabled) setOpen((prev) => !prev);
              }}
              onKeyDown={(e) => e.preventDefault()}
              adminIsAllowed
              disabled={disabled || isAdmin}
            />
          )}
          components={{
            OpenPickerIcon: OpenPickerIcon || ClockIcon,
          }}
          {...props}
          disabled={disabled || isAdmin}
        />
      </LocalizationProvider>
    );
  }
);

StyledTimePicker.displayName = "StyledTimePicker";

StyledTimePicker.propTypes = {
  textFieldProps: object,
  error: string,
  disabled: bool,
  timezone: string,
  dateLibInstance: any,
  dateAdapter: any,
  adminIsAllowed: bool,
  OpenPickerIcon: any,
  InputLabelProps: object,
};

StyledTimePicker.defaultProps = {
  textFieldProps: {},
  error: "",
  disabled: false,
  adminIsAllowed: false,
};

const StyledDatePicker = forwardRef(
  (
    {
      textFieldProps,
      inputSx,
      error,
      dateAdapter,
      timezone,
      adminIsAllowed,
      disabled,
      InputLabelProps = {},
      inputRef,
      ...props
    },
    ref
  ) => {
    const isAdmin = useAdmin(adminIsAllowed);
    return (
      <LocalizationProvider dateAdapter={dateAdapter || AdapterDateFns}>
        <DatePicker
          renderInput={(params) => {
            return (
              <StyledTextField
                size="small"
                timezone={timezone}
                inputProps={{
                  ...params.inputProps,
                  autocomplete: "new-password",
                  name: Math.random().toString(36).substring(2),
                }}
                {...params}
                error={error}
                InputProps={{
                  sx: {
                    height: "32px",
                    ...inputSx,
                  },
                  ...params.InputProps,
                }}
                InputLabelProps={{
                  ...InputLabelProps,
                }}
                ref={ref}
                {...(!!inputRef && { inputRef })}
                {...textFieldProps}
                adminIsAllowed
                disabled={disabled || isAdmin}
              />
            );
          }}
          components={{
            OpenPickerIcon: CalendarIcon,
          }}
          {...props}
          disabled={disabled || isAdmin}
        />
      </LocalizationProvider>
    );
  }
);

StyledDatePicker.displayName = "StyledDatePicker";

StyledDatePicker.propTypes = {
  textFieldProps: object,
  error: string,
  inputSx: object,
  disabled: bool,
  dateAdapter: any,
  timezone: string,
  adminIsAllowed: bool,
  InputLabelProps: object,
  inputRef: any,
};

StyledDatePicker.defaultProps = {
  textFieldProps: {},
  inputSx: {},
  error: "",
  adminIsAllowed: false,
};

export {
  StyledTextField,
  FilterSearchTextField,
  SearchTextField,
  GoogleAutocompleteField,
  PlaceAutocompleteField,
  StyledTimePicker,
  StyledDatePicker,
  PriceFormat,
  StyledAutocomplete,
};
