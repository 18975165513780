import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Paper, Typography, Box } from "@mui/material";
import { TaskSmallIcon } from "components/Icons";
import { func, object, array, string, bool } from "prop-types";
import { photoUrl } from "helpers/helpers";
import {
  FlagIcon,
  NoteIcon,
  PhotoCommentIcon,
  PicIcon,
  PurchaseOrderIcon,
} from "components/Icons/Activities";
import { setPictureItemAction } from "redux/actions/gallery";
import { distributorResetRepEmailService } from "services/reps";
import { error, success } from "utils/notifications";
import {
  ActivityBlock,
  ArrowBlock,
  AssignedCustomersBlock,
  CheckboxBlock,
  NameBlock,
  OrdersBlock,
  RevenueBlock,
  RoleBlock,
  StatusBlock,
  TerritoryBlock,
  VisitsBlock,
  WorkStatusBlock,
} from "./components";
import { createSelector } from "reselect";
import { columnLayoutsSelector } from "redux/selectors/settings";
import { useNavigate } from "react-router-dom";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));

const columnWidth = {
  Status: 4,
  "Rep Name": 17,
  Role: 10,
  Territory: 12,
  "Work Status": 9,
  "Latest Activity": 15,
  "Visits Today": 6,
  Revenue: 7,
  Orders: 6.5,
  Customers: 6.5,
};

const RepsItem = ({
  user = null,
  handleOpenProfileDialog,
  handleSetCheckedUser,
  checkedUsers = [],
  setAnchorEl,
  setTerritoriesList,
  timeZone,
  setIsOpenProductDialog,
  currentTab,
  handleClickCheckin,
  handleCustomerTaskDrawer,
  repPermissions,
  handleOpenCustomerNotes,
}) => {
  const { columnLayouts } = useSelector(selector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hoverColor, setHoverColor] = useState("");

  const lastActivity = useMemo(() => user?.activities?.[0], [user?.activities]);

  const representativeDuplicate = useMemo(
    () => ({
      id: user?.id ? user.id : "",
      name: user?.name ? user.name : "",
      profilePhoto: user?.profilePhoto ? user.profilePhoto : "",
      representativeId: user?.id ? user.id : "",
      role: user?.role ? user.role : "",
    }),
    [user?.id, user?.name, user?.profilePhoto, user?.role]
  );

  const handleResetEmail = (e) => {
    e.preventDefault();
    distributorResetRepEmailService(user?.id)
      .then(() => success("Confirmation email sent"))
      .catch(() => {
        error("Something went wrong");
      });
  };

  const activityIcon = useMemo(() => {
    if (lastActivity?.type === "CHECKED_IN") {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
          onClick={() =>
            handleClickCheckin({ ...lastActivity, representativeDuplicate })
          }
        >
          <FlagIcon sx={{ width: 8, height: 10 }} color="#42A57F" />
          <Typography fontSize="12px" color="#42A57F" ml="4px" mr="3px" noWrap>
            Checked in
          </Typography>
        </Box>
      );
    }

    if (lastActivity?.type === "PHOTO_GROUP_ADDED") {
      return (
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}>
          <PicIcon size={11} color="#42A57F" />
          <Typography
            sx={{
              "&:hover": { cursor: "pointer", textDecoration: "underline" },
            }}
            fontSize="12px"
            color="#42A57F"
            ml="3px"
            mr="3px"
            noWrap
            onClick={() => {
              dispatch(
                setPictureItemAction(
                  user?.activities?.[0]?.photoGroups?.[0]?.id
                )
              );
              setIsOpenProductDialog(true);
            }}
          >
            Photo
          </Typography>
        </Box>
      );
    }

    if (lastActivity?.type === "NOTE_ADDED") {
      return (
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}>
          <NoteIcon size={11} color="#42A57F" />
          <Typography
            sx={{
              "&:hover": { cursor: "pointer", textDecoration: "underline" },
            }}
            fontSize="12px"
            color="#42A57F"
            ml="3px"
            mr="3px"
            noWrap
            onClick={() => {
              if (!lastActivity?.customer?.id) return;
              handleOpenCustomerNotes({
                customer: {
                  id: lastActivity?.customer?.id,
                  name:
                    lastActivity?.customer?.displayName ||
                    lastActivity?.customer?.name,
                },
              });
            }}
          >
            Note
          </Typography>
        </Box>
      );
    }

    if (lastActivity?.type === "COMMENT_ADDED") {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
          onClick={() => {
            dispatch(
              setPictureItemAction(user?.activities?.[0]?.photoGroups?.[0]?.id)
            );
            setIsOpenProductDialog(true);
          }}
        >
          <PhotoCommentIcon fill="#42A57F" />
          <Typography fontSize="12px" color="#42A57F" ml="3px" mr="3px" noWrap>
            Photo comment
          </Typography>
        </Box>
      );
    }

    if (lastActivity?.type === "ORDER_ADDED") {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
          onClick={() => {
            if (repPermissions && !repPermissions?.orders?.view) return;
            return (
              !!lastActivity?.orders?.length &&
              navigate(`/orders/${lastActivity?.orders[0].id}`)
            );
          }}
        >
          <PurchaseOrderIcon fill="#42A57F" sx={{ width: 10, height: 12 }} />
          <Typography fontSize="12px" color="#42A57F" ml="3px" mr="3px" noWrap>
            Order
          </Typography>
        </Box>
      );
    }

    if (lastActivity?.type === "TASK_COMPLETED") {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
          onClick={() =>
            !!lastActivity?.tasks?.length &&
            handleCustomerTaskDrawer(true, lastActivity?.tasks[0])
          }
        >
          <TaskSmallIcon color="#42A57F" sx={{ width: 10, height: 12 }} />
          <Typography fontSize="12px" color="#42A57F" ml="3px" mr="3px" noWrap>
            Completed task
          </Typography>
        </Box>
      );
    }

    if (lastActivity?.type === "TASK_ADDED") {
      return (
        <Box
          sx={{ display: "flex", alignItems: "center", flexWrap: "nowrap" }}
          onClick={() =>
            !!lastActivity?.tasks?.length &&
            handleCustomerTaskDrawer(true, lastActivity?.tasks[0])
          }
        >
          <TaskSmallIcon color="#42A57F" />
          <Typography fontSize="12px" color="#42A57F" ml="3px" mr="3px" noWrap>
            Created task
          </Typography>
        </Box>
      );
    }

    return "- ";
  }, [
    dispatch,
    handleClickCheckin,
    handleCustomerTaskDrawer,
    handleOpenCustomerNotes,
    lastActivity,
    navigate,
    repPermissions,
    representativeDuplicate,
    setIsOpenProductDialog,
    user?.activities,
  ]);

  const profilePhoto = useMemo(
    () => user?.avatar || photoUrl(user?.profilePhoto?.fileName),
    [user?.avatar, user?.profilePhoto]
  );

  const isChecked = useMemo(
    () => !!checkedUsers.find((checkedUser) => checkedUser?.id === user?.id),
    [checkedUsers, user?.id]
  );

  const defaultColumnWidth = useMemo(
    () => Object.values(columnWidth).reduce((acc, cur) => (acc += cur), 0),
    []
  );

  const rep_all_tab_disable = columnLayouts.rep_all.disable;
  const rep_all_tab = columnLayouts.rep_all.main;

  const columns = useMemo(() => {
    if (rep_all_tab_disable)
      return [
        ...rep_all_tab_disable,
        ...rep_all_tab.filter((col) => col.selected),
      ];
    return [];
  }, [rep_all_tab, rep_all_tab_disable]);

  const columnData = {
    Status: <StatusBlock {...{ user, handleResetEmail, repPermissions }} />,
    "Rep Name": (
      <NameBlock
        {...{ handleOpenProfileDialog, user, profilePhoto, repPermissions }}
      />
    ),
    Role: <RoleBlock userRole={user?.role} />,
    Territory: (
      <TerritoryBlock
        territories={user?.territories ?? []}
        {...{ setTerritoriesList, setAnchorEl }}
      />
    ),
    "Work Status": <WorkStatusBlock workStatus={user?.workStatus} />,
    "Latest Activity": (
      <ActivityBlock
        {...{ activityIcon, timeZone }}
        activities={user?.activities}
        createdAt={lastActivity?.createdAt}
      />
    ),
    "Visits Today": <VisitsBlock visits={user?.uniqueCheckIns} />,
    Revenue: <RevenueBlock revenueCount={user?.revenue} />,
    Orders: <OrdersBlock ordersCount={user?.orders} />,
    Customers: (
      <AssignedCustomersBlock assignedCustomers={user?.assignedCustomers} />
    ),
  };

  return (
    <Paper
      sx={{
        height: "51px",
        backgroundColor: isChecked ? "#ECF5F0" : hoverColor,
        "&:not(:last-child)": { borderBottom: "1px solid #D5D9D9" },
      }}
      elevation={0}
      square
      component={Grid}
      container
      columns={12}
      onMouseMove={() => setHoverColor("#F7F7F7")}
      onMouseLeave={() => setHoverColor("#FFF")}
      wrap="nowrap"
      data-testid="rep-row"
    >
      {currentTab !== "3rd Party" ? (
        <>
          <Box width="50px">
            <CheckboxBlock {...{ isChecked, handleSetCheckedUser, user }} />
          </Box>

          {/* <CustomIdBlock customId={user?.customId?.customId} /> */}
          {columns.map((col) => {
            const setWidth = columnWidth?.[col?.name]
              ? `${columnWidth?.[col?.name]}%`
              : `${
                  (100 - defaultColumnWidth) /
                  rep_all_tab.filter((col) => col.selected).length
                }%`;

            return (
              <Box
                key={col.id}
                sx={{
                  width: setWidth,
                  minWidth: setWidth,
                  maxWidth: setWidth,
                  textDecoration: "none",
                }}
              >
                {columnData?.[col?.name]}
              </Box>
            );
          })}

          <Grid item xs>
            <ArrowBlock
              {...{ handleOpenProfileDialog, user, repPermissions }}
            />
          </Grid>
        </>
      ) : (
        <>
          <Box width="50px">
            <CheckboxBlock {...{ isChecked, handleSetCheckedUser, user }} />
          </Box>

          <Box width="7.5%">
            <StatusBlock {...{ user, handleResetEmail, repPermissions }} />
          </Box>

          <Box width="18.6%">
            <NameBlock
              {...{
                handleOpenProfileDialog,
                user,
                profilePhoto,
                repPermissions,
              }}
            />
          </Box>

          <Box width="12.4%">
            <RoleBlock userRole={user?.role} />
          </Box>

          <Box width="24.9%">
            <TerritoryBlock
              territories={user.territories}
              {...{ setTerritoriesList, setAnchorEl }}
            />
          </Box>

          <Box width="16.5%">
            <AssignedCustomersBlock
              assignedCustomers={user?.assignedCustomers}
            />
          </Box>

          <Box width="17.4%">
            <ArrowBlock
              {...{
                currentTab,
                handleOpenProfileDialog,
                user,
                repPermissions,
              }}
            />
          </Box>
        </>
      )}
    </Paper>
  );
};

RepsItem.propTypes = {
  user: object,
  lastItem: bool,
  handleOpenProfileDialog: func,
  handleSetCheckedUser: func,
  checkedUsers: array,
  setAnchorEl: func,
  setTerritoriesList: func,
  timeZone: string,
  setIsOpenProductDialog: func,
  currentTab: string,
  handleClickCheckin: func,
  handleCustomerTaskDrawer: func,
  repPermissions: object,
  handleOpenCustomerNotes: func,
};

export default RepsItem;
