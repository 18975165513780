import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import useStyles from "./styles";
import { CheckmarkIcon, CrossIcon } from "components/Icons";
import moment from "moment-timezone";
import {
  ActionRestrictionWrapper,
  AddFormsComponent,
  StyledButton,
  StyledSelect,
  StyledSelectTime,
  StyledTextField,
  StyledTooltip,
} from "components";
import { getPriority, useCustomerTasks } from "./TaskDrawer.hooks";
import { Controller } from "react-hook-form";
import { useTableFilters } from "../../CustomerProfile.hooks";
import { ActivityBlock } from "./ActivityBlock/ActivityBlock";
import { useDispatch, useSelector } from "react-redux";
//import { IntervalContainer } from "components/TaskDrawer/components/IntervalContainer";
import { useFormFields } from "components/TaskDrawer/TaskDrawer.hooks";
import { cl } from "components/TaskDrawer/TaskDrawer.styles";
import { ConfirmTaskDialog } from "components/TaskDrawer/components/ConfirmTaskDialog";
import { useRepsPermissions } from "helpers/hooks";
import {
  onPasteTextWithMaxLength,
  truncateText,
  useAdmin,
} from "helpers/helpers";
import { createSelector } from "reselect";
import { confirmDialogFormChangedSelector } from "redux/selectors/confirmDialogs";
import {
  openDiscardChanges,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import {
  MAX_CHARACTERS_DESCRIPTIONS,
  MAX_CHARACTERS_TITLE,
} from "./TaskDrawer.constants";
import { ClockNewIcon } from "components/Icons/ClockNewIcon";
import { DueDateBlock } from "components/TaskDrawer/components";

const selector = createSelector(
  confirmDialogFormChangedSelector,
  (formChanged) => ({
    formChanged,
  })
);

const TaskDrawer = () => {
  const classes = useStyles();
  const errorMsgRef = useRef({});
  const { formChanged } = useSelector(selector);
  const dispatch = useDispatch();
  const repPermissions = useRepsPermissions();
  const [dialogState, setDialogState] = useState({
    isOpen: false,
    isDeleting: false,
  });

  const isAdmin = useAdmin();

  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { timeZone } = currentUser || {};

  const {
    handleSaveTask,
    onClose,
    open,
    handleDeleteTask,
    loading,
    taskState,
    taskLoading,
    editTask,
    handleUpdateCompletedTask,
    //handleOnDeleteDrawer,
    //handleOnSaveDrawer,
    handleOnCloseDrawer,
    handleConfirmDelete,
  } = useCustomerTasks({ setDialogState, formChanged });

  const incomingTask = taskState?.task?.task || taskState?.task;

  const {
    formField,
    control,
    setValue,
    setError,
    clearErrors,
    isDirty: isDirtyFields,
    //isRepeatedTask,
    handleSubmit,
    errors,
    PRIORITIES,
    STATUSES,
    trigger,
  } = useFormFields({
    open,
    taskState: incomingTask,
    isEdit: !!incomingTask,
  });

  const currentFormsList = useMemo(
    () => incomingTask?.assignedForms || formField?.assignedForms,
    [formField?.assignedForms, incomingTask?.assignedForms]
  );

  const incomingFormIds = currentFormsList?.map((f) => f?.form?.id);

  const currentFormIds = useMemo(
    () => (formField?.formIds?.length ? formField?.formIds : []),
    [formField?.formIds]
  );

  const isDirtyFormIds = useMemo(
    () => !isEqual(incomingFormIds, currentFormIds),
    [currentFormIds, incomingFormIds]
  );

  const isDirty = isDirtyFields || isDirtyFormIds;

  const {
    title,
    representative,
    status,
    distributorId,
    representativeId,
    assignedDistributorId,
    assignedRepresentatives,
  } = formField || {};
  const { dueDate: dueDateError } = errors || {};

  const disabledAddBtn = useMemo(() => {
    return repPermissions
      ? !repPermissions?.tasks?.create_edit
      : !(title && representative) || !!dueDateError;
  }, [repPermissions, title, representative, dueDateError]);

  const preventCloseDrawer = useCallback(
    ({ onClose }) => {
      // eslint-disable-next-line no-console
      if (!onClose) return console.error("onClose is a required parameter");

      if (formChanged) {
        return dispatch(
          openDiscardChanges(
            () => onClose(),
            async () => {
              const resTrigger = await trigger();
              if (resTrigger && !disabledAddBtn && !loading) {
                handleSaveTask(formField);
              } else {
                dispatch(setFormChangedAction(isDirty));
              }
            }
          )
        );
      }

      onClose();
    },
    [
      disabledAddBtn,
      dispatch,
      formChanged,
      formField,
      handleSaveTask,
      isDirty,
      loading,
      trigger,
    ]
  );

  const handleCloseTask = useCallback(() => {
    preventCloseDrawer({ onClose });
  }, [onClose, preventCloseDrawer]);

  //const isRepeatedTaskChanged = useMemo(
  //  () => isRepeatedTask && isDirty,
  //  [isRepeatedTask, isDirty]
  //);

  const activities =
    taskState?.task?.task?.activities || taskState?.task?.activities;

  const { repsState, handleFetchReps } = useTableFilters();

  const getTitleLabel = useMemo(() => {
    if (editTask) return "Edit";

    return "Add";
  }, [editTask]);

  const disabledCancelBtn = useMemo(() => {
    return loading;
  }, [loading]);

  const disabledDeleteBtn = useMemo(() => {
    return repPermissions ? !repPermissions?.tasks?.delete : loading;
  }, [loading, repPermissions]);

  const setStatusLabelBtn = useMemo(() => {
    if (status === STATUSES[0]) return "Mark Complete";
    if (status === STATUSES[1]) return "Completed";
  }, [STATUSES, status]);

  const isValidDate = (str) => {
    if (!str) return false;
    const parsedDate = moment(str, "MM/DD/YYYY", true);

    return parsedDate.isValid();
  };

  const handleOnChange = useCallback(
    ({ value, field, name, maxCharacters }) => {
      if (value?.length <= maxCharacters + 1) {
        field.onChange(value);
        trigger([name]);
      }
    },
    [trigger]
  );

  const handleOnPaste = useCallback(
    ({ event, field, name, maxCharacters }) => {
      onPasteTextWithMaxLength({
        event,
        value: field.value,
        onChange: (v) => {
          field.onChange(v);
          trigger([name]);
        },
        maxCharacters,
      });
    },
    [trigger]
  );
  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));

    if (!open) dispatch(setFormChangedAction(false));
  }, [isDirty, dispatch, open]);

  const isMarkBtnDisabled = useMemo(() => {
    if (!repPermissions) return assignedDistributorId !== currentUser?.id;

    const repsIds = assignedRepresentatives?.map(
      (rep) => rep?.representative?.id
    );

    return !repsIds?.includes(repPermissions?.representativeId);
  }, [
    repPermissions,
    assignedDistributorId,
    currentUser?.id,
    assignedRepresentatives,
  ]);

  const isNotAllowedToEdit = useMemo(() => {
    if (!taskState?.task) return false;

    if (!repPermissions)
      return distributorId !== currentUser?.id || representativeId;

    return repPermissions?.representativeId !== representativeId;
  }, [
    currentUser?.id,
    distributorId,
    repPermissions,
    representativeId,
    taskState?.task,
  ]);

  const editCheckedForms = useMemo(() => {
    return currentFormsList?.map((item) => ({
      id: item?.form?.id,
      title: item?.form?.title,
    }));
  }, [currentFormsList]);

  const handleSetCheckedFormsIds = useCallback(
    (ids) => setValue("formIds", ids),
    [setValue]
  );

  return (
    <>
      <ConfirmTaskDialog
        {...dialogState}
        loading={loading}
        handleSaveTask={handleSaveTask}
        formField={formField}
        //isRepeatedTask={isRepeatedTask}
        onClose={handleOnCloseDrawer}
        setValue={setValue}
        setDialogState={setDialogState}
        handleConfirmDelete={handleConfirmDelete}
      />
      <Drawer
        disableRestoreFocus
        open={open}
        onClose={handleCloseTask}
        anchor="right"
      >
        <Box className={classes.wrapper}>
          <Box className={classes.header}>
            <Typography>{getTitleLabel} Task</Typography>
            {loading && <CircularProgress size={23} />}
            <IconButton onClick={handleCloseTask}>
              <CrossIcon />
            </IconButton>
          </Box>

          {taskLoading ? (
            <Stack
              sx={{
                height: "calc(100vh - 147px)",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={36} />
            </Stack>
          ) : (
            <Box className={classes.bodyWrapper}>
              {/* Title */}
              <Stack sx={{ gap: "24px" }}>
                <Typography fontSize={18} color="#3F3F3F">
                  Task Details
                </Typography>
                <Controller
                  render={({ field, fieldState: { error } }) => {
                    if (error?.message)
                      errorMsgRef.current.title = error?.message;

                    return (
                      <StyledTooltip
                        arrow
                        placement="top"
                        title={errorMsgRef.current?.title}
                        open={!!error}
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: { offset: [0, -5] },
                            },
                          ],
                        }}
                      >
                        <StyledTextField
                          placeholder="Title"
                          fullWidth
                          label="Title"
                          disabled={isNotAllowedToEdit}
                          error={!!error}
                          InputProps={cl.title.InputProps}
                          InputLabelProps={cl.title.InputLabelProps}
                          sx={cl.title.sx}
                          {...field}
                          onChange={({ target: { value } }) =>
                            handleOnChange({
                              value,
                              field,
                              name: "title",
                              maxCharacters: MAX_CHARACTERS_TITLE,
                            })
                          }
                          onPaste={(event) =>
                            handleOnPaste({
                              event,
                              field,
                              name: "title",
                              maxCharacters: MAX_CHARACTERS_TITLE,
                            })
                          }
                        />
                      </StyledTooltip>
                    );
                  }}
                  name="title"
                  control={control}
                />

                {/* Description */}
                <Controller
                  render={({ field, fieldState: { error } }) => {
                    if (error?.message)
                      errorMsgRef.current.description = error?.message;

                    return (
                      <StyledTooltip
                        arrow
                        placement="top"
                        title={errorMsgRef.current?.description}
                        open={!!error}
                        PopperProps={cl.description?.PopperProps}
                      >
                        <StyledTextField
                          label="Description"
                          placeholder="Description"
                          multiline
                          rows={5}
                          disabled={isNotAllowedToEdit}
                          fullWidth
                          error={!!error}
                          InputProps={cl.description?.InputProps}
                          InputLabelProps={cl.description?.InputLabelProps}
                          sx={cl.description.sx}
                          {...field}
                          onChange={({ target: { value } }) =>
                            handleOnChange({
                              value,
                              field,
                              name: "description",
                              maxCharacters: MAX_CHARACTERS_DESCRIPTIONS,
                            })
                          }
                          onPaste={(event) =>
                            handleOnPaste({
                              event,
                              field,
                              name: "description",
                              maxCharacters: MAX_CHARACTERS_DESCRIPTIONS,
                            })
                          }
                        />
                      </StyledTooltip>
                    );
                  }}
                  name="description"
                  control={control}
                />

                {/* Due date */}
                <Stack flexDirection="row" gap={1.75}>
                  <Box width="67%">
                    <DueDateBlock
                      {...{
                        formField,
                        timeZone,
                        setValue,
                        control,
                        isNotAllowedToEdit,
                        isValidDate,
                        setError,
                        clearErrors,
                      }}
                    />
                  </Box>

                  {/* Due time */}
                  <Box width="33%">
                    <Controller
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <StyledSelectTime
                            disabled={
                              isNotAllowedToEdit ||
                              !formField?.dueDate ||
                              isAdmin
                            }
                            label="Due time"
                            fullWidth
                            error={!!error}
                            formSx={cl.dueTime.formSx}
                            sx={cl.dueTime.sx}
                            labelSx={cl.dueTime.labelSx}
                            IconComponent={ClockNewIcon}
                            height="44px"
                            fontSize="14px !important"
                            InputProps={cl.dueTime.InputProps}
                            labelProps={cl.dueTime.labelProps}
                            {...field}
                          />
                        );
                      }}
                      name="dueTime"
                      control={control}
                    />
                  </Box>
                </Stack>

                {/*<Stack direction="row" alignItems="center" gap="9px">
              <Box>
                <Controller
                  render={({ field }) => (
                    <LargeCheckbox
                      checked={!!field.value}
                      size={16}
                      disabled={isNotAllowedToEdit}
                      formSx={{ m: 0 }}
                      sx={{ padding: 0 }}
                      {...field}
                      onChange={(e, newVal) => {
                        setValue("addTime", newVal);

                        if (!newVal) {
                          setValue("repeatInterval", "day");
                          setValue("repeatStep", "1");
                        }
                      }}
                    />
                  )}
                  name="addTime"
                  control={control}
                />
              </Box>
              <Typography variant="header" fontSize="13px">
                Add time
              </Typography>
            </Stack>*/}

                {/* Interval */}

                {/*hidden in SD-8585 */}

                {/*{formField.dueDate && (
              <IntervalContainer
                control={control}
                formField={formField}
                setValue={setValue}
                isNotAllowedToEdit={isNotAllowedToEdit}
              />
            )}*/}

                <Stack flexDirection="row" gap={1.75} mb="8px">
                  {/* Assignee */}
                  <Box width="67%" maxWidth="calc(67% - 9px)">
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledSelect
                          label="Assignee"
                          disabled={
                            repsState?.loading || isAdmin || isNotAllowedToEdit
                          }
                          fullWidth
                          error={!!error}
                          labelSx={cl.assignee.labelSx}
                          height="44px"
                          fontSize="14px !important"
                          InputProps={cl.assignee.InputProps}
                          labelProps={cl.assignee.labelProps}
                          PaperPropsSx={cl.assignee.paperPropsSx}
                          {...field}
                          dataLength={repsState?.list?.length}
                          dataCount={repsState?.count}
                          handleFetch={handleFetchReps}
                        >
                          <MenuItem
                            sx={{ maxWidth: "316px" }}
                            value="distributor"
                          >
                            Admin
                          </MenuItem>
                          {repsState?.list?.length
                            ? repsState.list.map((rep) => (
                                <MenuItem
                                  sx={{ maxWidth: "316px" }}
                                  key={rep?.id}
                                  value={rep?.id}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 400,
                                      color: "#3F3F3F",
                                    }}
                                    noWrap
                                  >
                                    {truncateText(rep?.name, 70)}
                                  </Typography>
                                </MenuItem>
                              ))
                            : null}
                        </StyledSelect>
                      )}
                      name="representative"
                      control={control}
                    />
                  </Box>

                  {/* Priority */}
                  <Box width="33%">
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledSelect
                          height="44px"
                          fontSize="14px !important"
                          labelSx={cl.priority.labelSx}
                          InputProps={cl.priority.InputProps}
                          PaperPropsSx={cl.priority.paperPropsSx}
                          disabled={isNotAllowedToEdit || isAdmin}
                          label="Priority"
                          fullWidth
                          error={!!error}
                          {...field}
                        >
                          {PRIORITIES.map((p) => (
                            <MenuItem key={p} value={p}>
                              {getPriority({
                                type: p,
                                sx: {
                                  width: "60px",
                                  height: "24px",
                                  borderRadius: "8px",
                                  "& p": {
                                    fontSize: "12px !important",
                                    textTransform: "lowercase",
                                  },
                                  "& p:first-letter": {
                                    textTransform: "capitalize",
                                  },
                                },
                              })}
                            </MenuItem>
                          ))}
                        </StyledSelect>
                      )}
                      name="priority"
                      control={control}
                    />
                  </Box>
                </Stack>

                {/* Add Forms */}
                <AddFormsComponent
                  isAdmin={isAdmin}
                  isNotAllowedToEdit={isNotAllowedToEdit}
                  editCheckedForms={editCheckedForms}
                  handleSetCheckedFormsIds={handleSetCheckedFormsIds}
                />
              </Stack>

              {/* activities */}
              {editTask ? (
                <ActivityBlock
                  {...{ activities }}
                  titleProps={{ fontSize: 18, color: "#5F6267" }}
                  containerGridProps={{ height: "70px" }}
                  leftGridProps={{ xs: 1, mr: 2 }}
                  rightGridProps={{
                    sx: {
                      border: "1px solid #47A06D50",
                      borderRadius: "4px",
                      alignItems: "center",
                      height: "56px",
                    },
                  }}
                  expandCircleDownProps={{
                    marginTop: "-15px",
                    marginRight: "-15px",
                    fontSize: 30,
                  }}
                />
              ) : null}
            </Box>
          )}

          {/* Action buttons */}
          <Box className={classes.footer}>
            {editTask && (
              <ActionRestrictionWrapper
                disableHoverListener={!disabledDeleteBtn}
              >
                <StyledButton
                  disabled={disabledDeleteBtn || isAdmin || isNotAllowedToEdit}
                  label="Delete"
                  color="error"
                  sx={{ bgcolor: "#FDEBEA80" }}
                  variant="outlined"
                  onClick={() => {
                    //if (isRepeatedTask) return handleOnDeleteDrawer();
                    return handleDeleteTask();
                  }}
                />
              </ActionRestrictionWrapper>
            )}

            {editTask && (
              <ActionRestrictionWrapper
                disableHoverListener={
                  !(repPermissions && !repPermissions?.tasks?.create_edit)
                }
              >
                <StyledButton
                  sx={{
                    ml: "11px",
                    width:
                      setStatusLabelBtn === "Mark Complete" ? "153px" : "135px",
                    p: 0,
                  }}
                  disabled={
                    isMarkBtnDisabled ||
                    (repPermissions && !repPermissions?.tasks?.create_edit) ||
                    isAdmin
                  }
                  label={setStatusLabelBtn}
                  variant="outlined"
                  color="cancel"
                  startIcon={
                    setStatusLabelBtn === "Mark Complete" ? (
                      <CheckmarkIcon
                        width="11.18"
                        height="8"
                        stroke="#A0A0A0"
                      />
                    ) : null
                  }
                  onClick={() => {
                    if (status === STATUSES[0]) setValue("status", STATUSES[1]);
                    if (status === STATUSES[1]) setValue("status", STATUSES[0]);
                    if (status === STATUSES[0]) {
                      handleUpdateCompletedTask(STATUSES[1]);
                    } else if (status === STATUSES[1]) {
                      handleUpdateCompletedTask(STATUSES[0]);
                    }
                    0;
                  }}
                />
              </ActionRestrictionWrapper>
            )}

            <Box display="flex" gap="12px" ml="auto">
              <StyledButton
                disabled={disabledCancelBtn}
                label="Cancel"
                color="cancel"
                variant="outlined"
                sx={{ width: "80px" }}
                height="32px"
                onClick={handleCloseTask}
              />
              {/*{editTask && isRepeatedTaskChanged ? (
                <ActionRestrictionWrapper
                  disableHoverListener={
                    !(repPermissions && !repPermissions?.tasks?.create_edit)
                  }
                >
                  <StyledButton
                    disabled={
                      disabledAddBtn || loading || isAdmin || isNotAllowedToEdit
                    }
                    label={editTask ? "Save" : "Add"}
                    variant="contained"
                    fontSize="15px"
                    onClick={handleOnSaveDrawer}
                  />
                </ActionRestrictionWrapper>
              ) : (*/}
              <ActionRestrictionWrapper
                disableHoverListener={
                  !(repPermissions && !repPermissions?.tasks?.create_edit)
                }
              >
                <StyledButton
                  disabled={
                    disabledAddBtn || loading || isAdmin || isNotAllowedToEdit
                  }
                  sx={{
                    width: "80px",
                    "&.Mui-disabled": {
                      color: "#FFF",
                      bgcolor: ({ palette }) =>
                        `${palette.primary.main}50 !important`,
                    },
                  }}
                  label={editTask ? "Save" : "Add"}
                  variant="contained"
                  fontSize="15px"
                  onClick={handleSubmit(handleSaveTask)}
                />
              </ActionRestrictionWrapper>
              {/*)}*/}
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default TaskDrawer;
