import React, { useCallback, useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { bool, func, array, number, string, any } from "prop-types";
import { FETCH_LIMITS, SCROLL_LIMIT } from "../../../../../../utils/constants";
import { useDebounce } from "../../../../../../helpers/hooks";

import { CrossBigIcon } from "../../../../../../components/Icons";
import { FilterSearchTextField } from "../../../../../../components/TextFields/TextFields";
import StyledButton from "../../../../../../components/StyledButton";
import { error } from "../../../../../../utils/notifications";
import { InfiniteScrollWrapper } from "components";
import { getParentCustomersService } from "services/customers";

// universal for groups and customers
const AllCustomersPopup = ({
  isOpen,
  handleClose,
  handleAddCustomers,
  handleCancelCustomers,
  handleCheckCustomer,
  checkedCustomers,
  addedCustomers,
  ItemWrapper,
  type,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [itemsList, setItemsList] = useState([]);
  const [params, setParams] = useState({
    limit: FETCH_LIMITS.FILTER_CUSTOMERS,
    page: 1,
    include_uncategorized: true,
    with_candidates: false,
  });
  const searchInputDebounced = useDebounce(searchInput, 500);

  const handleFetchGroups = useCallback(
    async (page) => {
      try {
        const res = await getParentCustomersService({
          ...params,
          page: page ? page : 1,
          limit: SCROLL_LIMIT,
          search: searchInputDebounced,
        });
        const { rows, count } = res || {};

        setItemsList((prev) => ({
          ...prev,
          list: page ? [...prev.list, ...rows] : [...rows],
          count,
        }));

        setParams((prev) => ({ ...prev, page: page ? page : 1 }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setItemsList((prev) => ({ ...prev, loading: false }));
      }
    },
    [params, searchInputDebounced]
  );
  useEffect(() => {
    handleFetchGroups();
    // eslint-disable-next-line
  }, [searchInputDebounced]);

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        sx: {
          maxWidth: "784px",
        },
      }}
    >
      <DialogContent sx={{ width: "784px", height: "545px", px: "32px" }}>
        <Box display="flex" justifyContent="space-between" mb="20px">
          <Typography fontSize="30px" color="#707070">
            {type === "CUSTOMER" ? "All customers" : "All customer groups"}
          </Typography>
          <IconButton onClick={handleClose}>
            <CrossBigIcon />
          </IconButton>
        </Box>
        <FilterSearchTextField
          placeholder={
            type === "CUSTOMER"
              ? "Search customers by name, address or ID"
              : "Search customer group by name, or ID"
          }
          fullWidth
          bgColor="#F8F8F8"
          value={searchInput}
          formSx={{ overflow: "hidden" }}
          onChange={(e) => setSearchInput(e.target.value)}
          handleClearValue={() => setSearchInput("")}
          customAdornment={
            <Box
              borderLeft="0.5px solid #D5D9D9"
              pl="14px"
              display="inline-flex"
              alignItems="center"
              sx={{
                transition: "all 0.3s ease-in-out",
                position: !checkedCustomers.length && "absolute",
                right: 0,
                transform: checkedCustomers.length
                  ? "none"
                  : "translateX(160%)",
                "& > .MuiTypography-root": {
                  fontSize: "9px",
                  color: "#47A06D",
                },
              }}
            >
              <Typography
                fontWeight="500"
                sx={{
                  p: "2px 4px",
                  backgroundColor: "rgba(71, 160, 109, 0.2)",
                  borderRadius: "4px",
                }}
              >
                {checkedCustomers.length}
              </Typography>
              <Typography ml="6px" fontWeight="600">
                SELECTED
              </Typography>
            </Box>
          }
        />
        <Stack
          sx={{
            height: "330px",
            overflow: "hidden",
            border: "0.5px solid #D5D9D9",
            borderRadius: "4px",
            mt: "14px",
          }}
        >
          <InfiniteScrollWrapper
            maxHeight="330px"
            id="all-customers-scroll-table"
            dataLength={itemsList?.list?.length || 0}
            next={() => handleFetchGroups(params?.page + 1)}
            loader={
              <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
            }
            hasMore={itemsList?.list?.length < itemsList?.count}
          >
            {itemsList?.list?.map((customer) => (
              <ItemWrapper
                key={customer.id}
                handleCheckItem={handleCheckCustomer}
                item={customer}
                isChecked={checkedCustomers.some(
                  (cust) => cust.id === customer.id
                )}
                disabled={addedCustomers?.some(
                  (cust) => cust.id === customer.id
                )}
              />
            ))}
          </InfiniteScrollWrapper>
        </Stack>
        <DialogActions sx={{ p: "23px 0px 0px" }}>
          <StyledButton
            label="Cancel"
            sx={{ height: "27px", mr: "8px" }}
            color="cancel"
            onClick={handleCancelCustomers}
            fontWeight="600"
            fontSize="10px"
          />
          <StyledButton
            label="Add"
            variant="contained"
            sx={{ height: "27px" }}
            fontWeight="600"
            fontSize="10px"
            onClick={handleAddCustomers}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

AllCustomersPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  handleCancelCustomers: func,
  handleAddCustomers: func,
  handleCheckCustomer: func,
  checkedCustomers: array,
  addedCustomers: array,
  ItemWrapper: any,
  itemsGetParamsAction: func,
  loading: bool,
  itemsList: array,
  itemsCount: number,
  getItemsService: func,
  type: string,
};

AllCustomersPopup.defaultProps = {
  isOpen: false,
  checkedCustomers: [],
  addedCustomers: [],
};

export default AllCustomersPopup;
