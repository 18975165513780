import { bool, shape, func, string, number, object } from "prop-types";
import { Box } from "@mui/material";
import { CardHeader, CardBody, CardFooter } from "./components";

export const CardPlanItem = ({
  plan,
  state,
  handleSetExtraUser,
  handleChangeCurrentPlan,
  isExtra,
  handleSetPayDescription,
}) => {
  const { name, price, features, term } = plan || {};

  return (
    <Box
      sx={{
        width: "284px",
        minWidth: "284px",
        height: "358px",
        border: "0.5px solid #D5D9D9",
        "&:hover": { border: "0.5px solid #409A65" },
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardHeader
        {...{
          name,
          price: isExtra ? state?.extra_user_price : price,
          term,
          isExtra,
        }}
      />
      <CardBody
        allowedExtraUsers={state?.current_plan?.extraUser?.extraAllowedUsers}
        {...{
          isExtra,
          features,
          handleSetExtraUser,
          name,
          extraUser: state?.extra_user_count,
        }}
      />
      <CardFooter
        nameBtn={
          isExtra
            ? "Request Extra Users"
            : state?.current_plan?.subscription?.name === plan?.name
            ? "Current plan"
            : "Request Upgrade"
        }
        name={name}
        disabled={
          state?.current_plan?.subscription?.name === plan?.name && !isExtra
        }
        isExtra={isExtra}
        onChangeCurrentPlan={handleChangeCurrentPlan}
        onSetPayDescription={handleSetPayDescription}
      />
    </Box>
  );
};

CardPlanItem.propTypes = {
  plan: shape({
    name: string,
    price: number,
    features: object,
  }),
  state: shape({
    extra_user_count: number,
    current_plan: object,
  }),
  isExtra: bool,
  handleSetExtraUser: func,
  handleChangeCurrentPlan: func,
  handleSetPayDescription: func,
};
