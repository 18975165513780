export const cl = {
  avatarWrapper: {
    width: "40px",
    height: "40px",
    border: "1px #D9D9D9 solid",
    fontSize: "12px",
    fontWeight: 500,
    color: "#9CA2AE",
    backgroundColor: "#FAFAFA",
  },
  wrapper: {
    backgroundColor: "#ffff",
    transition: "all 0.3s",
    "&:hover": { backgroundColor: "rgba(195, 195, 195, 0.08)" },
    "&:not(:last-of-type)": { borderBottom: "1px solid #E5E5E5" },
    minHeight: "72px",
    maxHeight: "72px",
    overflow: "hidden",
    position: "relative",
    gap: "12px",
  },
  text: {
    fontWeight: 300,
    lineHeight: "18px",
    color: "black",
    ml: "4px",
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
    textOverflow: "ellipsis",
  },
  avatarBadge: {
    "& .MuiBadge-badge": {
      width: "24px",
      height: "24px",
      border: "0.50px #D9D9D9 solid",
      borderRadius: "50%",
      transform: "translate(6px, 6px)",
    },
  },
  deletedWrapper: {
    animation: "hideNotificationItem 2s forwards",
  },
  deletedCheckIcon: {
    animation: "hideNotificationIcon 2s forwards",
  },
  checkIcon: {
    position: "absolute",
    opacity: 0,
    right: 20,
    transition: "all 0.3s",
  },

  deletedAnimatedText: {
    zIndex: 1000,
    "& .MuiTypography-root": {
      opacity: 0,
      transition: "all 0.4s ease-out",
    },
  },

  deletedText: {
    zIndex: 1000,
    "& .MuiTypography-root": {
      opacity: 1,
      transition: "all 0.4s ease-in",
    },
  },

  textContainer: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    zIndex: 1000,
    height: "54px",
  },

  unavailableText: {
    position: "absolute",
    height: "30px",
    left: 0,
    bottom: 0,
    transition: "all 0.4s ease-out",
  },

  dateContainer: {
    transition: "all 0.3s",
    zIndex: 1,
    width: "58px",
    minWidth: "58px",
    maxWidth: "58px",
    ml: "auto",
    textAlign: "center",
    mt: "1px",

    "& span": {
      height: "10px",
    },
  },
};
