import { SvgIcon } from "@mui/material";
export const TaskBigIcon = (props) => (
  <SvgIcon
    viewBox="0 0 18 20"
    style={{ fill: "none" }}
    sx={{ width: 18, height: 20 }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 3.75c0-1.414 0-2.121.44-2.56C2.378.75 3.085.75 4.5.75h9c1.414 0 2.121 0 2.56.44.44.439.44 1.146.44 2.56v12.5c0 1.414 0 2.121-.44 2.56-.439.44-1.146.44-2.56.44h-9c-1.414 0-2.121 0-2.56-.44-.44-.439-.44-1.146-.44-2.56z"
      stroke="#5F6267"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M9.254 5.333h4.5M9.242 10.5h4.508m-4.494 5h4.498M4.5 5l1.167 1.167L7 4m-2.5 6 1.167 1.167L7 9m-2.5 6 1.167 1.167L7 14"
      stroke="#5F6267"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
