import {
  createFilterService,
  deleteFilterService,
  getFiltersService,
  updateFiltersService,
} from "services/savedFilters";

import { error, success } from "../../utils/notifications";

export const SET_SAVED_FILTERS = "SET_SAVED_FILTERS";
export const START_LOADING_SAVED_FILTERS = "START_LOADING_SAVED_FILTERS";
export const END_LOADING_SAVED_FILTERS = "END_LOADING_SAVED_FILTERS";
export const SET_VIEW_NAME_DIALOG = "SET_VIEW_NAME_DIALOG";
export const SET_TO_DEFAULT_DIALOGS = "SET_TO_DEFAULT_DIALOGS";
export const SET_EDIT_DIALOG = "SET_EDIT_DIALOG";
export const SET_SELECTED_FILTER_ID = "SET_SELECTED_FILTER_ID";

export const setSavedFilters = (payload) => ({
  type: SET_SAVED_FILTERS,
  payload,
});

export const setSelectedFilterId = (payload) => ({
  type: SET_SELECTED_FILTER_ID,
  payload,
});

export const setEditDialog = (payload) => ({
  type: SET_EDIT_DIALOG,
  payload,
});

export const setViewNameDialog = (payload) => ({
  type: SET_VIEW_NAME_DIALOG,
  payload,
});

export const closeAllDialogs = (payload) => ({
  type: SET_TO_DEFAULT_DIALOGS,
  payload,
});

export const startLoadingSavedFilters = () => ({
  type: START_LOADING_SAVED_FILTERS,
});

export const endLoadingSavedFilters = () => ({
  type: END_LOADING_SAVED_FILTERS,
});

export const getSavedFiltersAction = (type) => {
  return (dispatch) => {
    dispatch(startLoadingSavedFilters());
    getFiltersService(type)
      .then((res) => {
        dispatch(setSavedFilters({ type, filters: res }));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.message);
        error(err?.message);
      })
      .finally(() => dispatch(endLoadingSavedFilters()));
  };
};

export const createSavedFiltersAction = (data, callback) => {
  return (dispatch, getState) => {
    const state = getState();
    const loading = state?.savedFilters?.loading;
    const filtersForSave = state?.savedFilters?.filtersForSave;
    const savedFilters = state?.savedFilters?.savedFilters[data.type];

    if (!loading) dispatch(startLoadingSavedFilters());

    if (
      // eslint-disable-next-line no-prototype-builtins
      filtersForSave.hasOwnProperty("purchasedProducts") &&
      Array.isArray(filtersForSave?.purchasedProducts)
    ) {
      filtersForSave.purchasedProducts = "";
    }

    const preparedData = {
      filters: filtersForSave,
      ...data,
    };

    createFilterService(preparedData)
      .then((res) => {
        success("Layout filter updated");
        dispatch(
          setSavedFilters({ type: data.type, filters: [...savedFilters, res] })
        );
        callback && callback(res.filters);
        dispatch(setSelectedFilterIdAction(res.id));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.response);
        error(err?.response?.data?.message || "Something went wrong");
      })
      .finally(() => {
        dispatch(closeAllDialogs());
        dispatch(endLoadingSavedFilters());
      });
  };
};

export const deleteSavedFiltersAction = ({ type, id }) => {
  return (dispatch, getState) => {
    const state = getState();
    const savedFilters = state?.savedFilters?.savedFilters[type];

    const filteredState = savedFilters.filter((filter) => filter.id !== id);

    dispatch(startLoadingSavedFilters());

    deleteFilterService(id)
      .then(() => {
        success("Layout filter deleted");
        dispatch(setSavedFilters({ type: type, filters: filteredState }));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.response);
        error(err?.response?.data?.message || "Something went wrong");
      })
      .finally(() => {
        dispatch(closeAllDialogs());
        dispatch(endLoadingSavedFilters());
      });
  };
};

export const updateSavedFiltersAction = ({
  type,
  id,
  filter,
  onSuccess,
  msg,
  filtersState,
}) => {
  return (dispatch, getState) => {
    const state = getState();
    const savedFilters =
      filtersState || state?.savedFilters?.savedFilters[type];

    const index = savedFilters.findIndex((f) => f.id === id);

    dispatch(startLoadingSavedFilters());

    updateFiltersService(id, filter)
      .then((res) => {
        success(msg || "Layout filter updated");

        if (index !== -1) savedFilters[index] = res;

        dispatch(setSavedFilters({ type: type, filters: savedFilters }));
        onSuccess && onSuccess(res);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.response);
        error(err?.response?.data?.message || "Something went wrong");
      })
      .finally(() => {
        //dispatch(closeAllDialogs());
        dispatch(endLoadingSavedFilters());
      });
  };
};

export const viewNameDialogAction = (open) => {
  return (dispatch) => dispatch(setViewNameDialog(open));
};

export const setSelectedFilterIdAction = (id) => {
  return (dispatch) => dispatch(setSelectedFilterId(id ?? ""));
};

export const onEditFilterDialogAction = (filterData) => {
  return (dispatch) => dispatch(setEditDialog(filterData));
};
