/* eslint-disable react/prop-types */
import { toast } from "react-toastify";
import { getTokenFromLocalStorage } from "../helpers/auth";
import store from "redux/store";
import { setCurrentUserAction } from "redux/actions/auth";
import {
  CloseIcon,
  ErrorIcon,
  InfoIcon,
  SuccessIcon,
  WarningIcon,
} from "components/Icons/Toast";
import { Box, Typography } from "@mui/material";

const icon = {
  success: <SuccessIcon />,
  warning: <WarningIcon />,
  info: <InfoIcon />,
  error: <ErrorIcon />,
};

const setIcon = (type) => {
  if (type === null) return false;
  if (!icon[type]) return icon["success"];

  return icon[type];
};

const CustomNotification = ({
  data = {
    title: "",
    content: "",
    undo: null,
    hasIcon: false,
  },
}) => {
  return (
    <Box>
      <Box sx={{ maxWidth: "350px", display: "flex", alignItems: "center" }}>
        <Typography
          sx={{
            fontWeight: 500,
            lineHeight: 1.3,
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            textOverflow: "ellipsis",
          }}
          noWrap
        >
          {data?.title}
        </Typography>
        {!!data?.undo?.callback && (
          <Box
            sx={{
              ml: "8px",
              cursor: "pointer",
              textDecoration: "underline",
              fontWeight: 600,
              lineHeight: 1.1,
            }}
            onClick={data.undo.callback}
          >
            {data?.undo?.text}
          </Box>
        )}
      </Box>
      {!!data?.content && (
        <Box>
          <Box
            sx={{
              ml: data?.hasIcon ? "-26px" : "8px",
              pt: "8px",
              pb: "12px",
              fontWeight: 400,
              letterSpacing: "0 !important",
            }}
          >
            {data.content}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const styles = { fontWeight: 500 };
const success = (msg, options) => {
  const { icon, data, ...restOptions } = options || {};

  const preparedOptions = {
    ...((!!data || !!msg) && {
      data: {
        title: data?.title || msg || "",
        content: data?.content || "",
        hasIcon: !!setIcon(icon),
        ...(!!data?.undo?.callback && {
          undo: {
            text: data?.undo.text || "Undo",
            callback: data?.undo?.callback || null,
          },
        }),
      },
    }),
    position: "bottom-center",
    progress: undefined,
    autoClose: 5000,
    hideProgressBar: !data?.undo?.callback,
    theme: "dark",
    style: { ...styles },
    ...restOptions,
    icon: setIcon(icon),
    closeButton: <CloseIcon />,
  };

  return toast.success(CustomNotification, preparedOptions);
};

const warning = (msg, options) => {
  const { icon, ...restOptions } = options || {};

  return toast.warning(msg, {
    position: "bottom-center",
    progress: undefined,
    autoClose: 5000,
    hideProgressBar: true,
    theme: "dark",
    style: { ...styles, backgroundColor: "#F39325" },
    ...restOptions,
    icon: setIcon(icon === undefined ? "warning" : icon),
    closeButton: <CloseIcon />,
  });
};

const error = (err, options) => {
  const { icon, ...restOptions } = options || {};

  const msg = err?.response?.data?.message || err?.message || err;

  const state = store.getState();

  if (
    typeof msg === "string" &&
    (msg === "Request failed with status code 403" ||
      msg.includes("You don't have permission to access the portal.") ||
      msg.includes("You don't have permission to access that resource."))
  ) {
    if (!state?.auth?.loading) store.dispatch(setCurrentUserAction());
    return;
  }

  if (msg === "Something went wrong" && state?.auth?.loading) return;

  const token = getTokenFromLocalStorage();

  if (token && window.navigator.onLine)
    return toast.error(msg || "Something went wrong!", {
      position: "bottom-center",
      progress: undefined,
      autoClose: 5000,
      hideProgressBar: true,
      theme: "colored",
      ...restOptions,
      style: { ...styles, backgroundColor: "#FF6969" },
      icon: setIcon(icon === undefined ? "error" : icon),
      closeButton: <CloseIcon />,
    });

  return;
};

const errorConfig = {
  position: "bottom-center",
  theme: "colored",
  hideProgressBar: true,
  progress: undefined,
  autoClose: 5000,
  closeButton: <CloseIcon />,
};

export { success, error, warning, errorConfig };
export default { success, error, warning };
