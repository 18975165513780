import { useEffect, useMemo, useState, useCallback } from "react";
import { object, bool } from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import {
  CheckmarkIcon,
  CrossIcon,
  OutlinedPlusIcon,
  PenIcon,
  SmallHourglassIcon,
} from "../../../../components/Icons";

import { validationSchema } from "../../../CustomersPage/pages/NewCustomerPage/NewCustomerPage.validation";
import TypographyAddress from "../../../../components/TypographyAddress/TypographyAddress";
import { activitiesSelector } from "../../../../redux/selectors/orders";
import { paytermsListSelector } from "../../../../redux/selectors/payterms";
import {
  updateDraftOrderAction,
  updateDraftOrderCustomerAction,
  updateOrderAction,
  updateOrderCustomerAction,
} from "../../../../redux/actions/orders";
import EditCustomerPopup from "../../components/EditCustomerPopup/EditCustomerPopup";
import StyledMenu from "../../../../components/StyledMenu/StyledMenu";
import { cl } from "./styles";
import {
  formatDate,
  getFormattedDate,
  setPhoneNumberMask,
  truncateText,
  useAdmin,
} from "../../../../helpers/helpers";
import PaytermComponent from "../../../SettingsPage/components/SettingsTabs/PaytermsTab/PaytermComponent";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ContactAddDrawer from "components/ContactsSection/ContactPopup/ContactAddDrawer";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
  DRAFT_ORDER_TYPES,
} from "utils/constants";
import { ActionRestrictionWrapper } from "components";

const selector = createSelector(
  activitiesSelector,
  paytermsListSelector,
  (activitiesList, paytermsList) => ({
    activitiesList,
    paytermsList,
  })
);

const OrdersTopInfoBar = ({
  order,
  hasThirdParty,
  hasThirdPartyDraft,
  repPermissions,
}) => {
  const isAdmin = useAdmin();
  const dispatch = useDispatch();
  const { activitiesList, paytermsList } = useSelector(selector);
  const timeZone = useSelector(({ auth }) => auth.currentUser.timeZone);
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const [editCustomerOpen, setEditCustomerOpen] = useState(false);
  const [newContactOpen, setNewContactOpen] = useState(false);
  const [editCustomerType, setEditCustomerType] = useState("");
  const [editCustomerData, setEditCustomerData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openHeaderList = Boolean(anchorEl);

  const [isOpenPaymentTermModal, setOpenPaymentTermModal] = useState(false);

  const priceListName = order?.priceListDuplicate?.name;

  const hasStoreCreditCardPayments = useMemo(
    () => currentUser?.allowStoreCreditCardPayments,
    [currentUser]
  );

  const paytermsListActive = useMemo(
    () =>
      hasStoreCreditCardPayments
        ? paytermsList.filter((payterm) => payterm.status !== "INACTIVE")
        : paytermsList.filter(
            (payterm) =>
              payterm.status !== "INACTIVE" &&
              payterm.name !== "Advance payment"
          ),
    [paytermsList, hasStoreCreditCardPayments]
  );

  const formattedDate = formatDate({
    utc_date: activitiesList?.[0]?.createdAt,
    formatThisYear: "MMM DD[,] H:mm a",
  });

  const getOrderStatus = useMemo(() => {
    return activitiesList?.[0]?.type === "ORDER_CREATED" ? (
      <></>
    ) : (
      <Typography
        sx={{
          ...cl.textBody,
          color: "#000",
          marginLeft: 0.6,
        }}
      >
        <Box fontWeight={600} component="span">
          Updated:{" "}
        </Box>
        {moment(
          getFormattedDate(activitiesList?.[0]?.createdAt, timeZone)
        ).format(formattedDate)}
      </Typography>
    );
  }, [activitiesList, formattedDate, timeZone]);

  const { control, reset, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      id: order?.id || "",
      payTerms: order?.customer?.paymentTerms?.id || "",
      paymentTermsId: "",
      delivery: order?.delivery || "Local Delivery",
    },
    resolver: yupResolver(validationSchema({ activeOrderCount: 0 })),
  });

  const formField = useWatch({ control });

  const hasParentCustomer = useMemo(() => {
    return (
      !!order?.customer?.parentCustomer?.id &&
      !!order?.customer?.billToParentCustomer
    );
  }, [
    order?.customer?.parentCustomer?.id,
    order?.customer?.billToParentCustomer,
  ]);

  const parentCustomerBillAddress = useMemo(() => {
    return hasParentCustomer && order.customer.parentCustomer?.billingAddress
      ? order.customer.parentCustomer.billingAddress
      : null;
  }, [hasParentCustomer, order?.customer?.parentCustomer?.billingAddress]);

  const customerBillAddress = useMemo(() => {
    return order?.customer?.billingAddress || null;
  }, [order?.customer?.billingAddress]);

  useEffect(() => {
    reset({
      id: order?.id || "",
      payTerms: order?.customer?.paymentTerms?.id || "",
      paymentTermsId: "",
      delivery: order?.delivery || "Local Delivery",
    });
  }, [reset, order]);

  useEffect(() => {
    if (formField.paymentTermsId) {
      dispatch(
        updateOrderAction({
          id: order.id,
          data: {
            paymentTermsId: formField.paymentTermsId,
          },
        })
      );
    }
  }, [dispatch, formField?.paymentTermsId, order.id]);

  const orderStatusIcon = useMemo(() => {
    switch (order?.orderStatus || order?.status) {
      case "COMPLETED":
      case "CLOSED":
        return (
          <>
            <Box display="flex" alignItems="center">
              <CheckmarkIcon width="11.18" height="8" stroke="#52B985" />
              <CheckmarkIcon
                width="11.18"
                height="8"
                stroke="#52B985"
                style={{ marginLeft: "-7px", marginRight: "3px" }}
              />
            </Box>
            <Typography fontSize="12px" color="#53B986">
              Completed
            </Typography>
          </>
        );
      case "PENDING":
        return (
          <>
            <SmallHourglassIcon />
            <Typography fontSize="12px" color="#959595">
              Pending
            </Typography>
          </>
        );
      case "OPEN":
        return (
          <>
            <SmallHourglassIcon />
            <Typography fontSize="12px" color="#959595">
              Open
            </Typography>
          </>
        );
      case "CANCELED":
        return (
          <>
            <Box height="23px">
              <CrossIcon fill="#FF6969" />
            </Box>
            <Typography fontSize="12px" color="#FF6969">
              Canceled
            </Typography>
          </>
        );
      default:
        return (
          <>
            <CircularProgress size={15} />
          </>
        );
    }
  }, [order]);

  const handleOpenEditCustomer = (type, data) => {
    setEditCustomerOpen(true);
    setEditCustomerType(type);
    setEditCustomerData(data);
  };

  const handleChangeCustomer = (data, type, err, setError) => {
    if (!data) return;
    if (err?.formatted_address?.type === "duplicate")
      return setError("data.formatted_address", {
        ...err.formatted_address,
      });

    let fieldName = "";
    switch (type) {
      case "customer contact": {
        fieldName = "contactId";
        break;
      }
      case "billing address": {
        fieldName = "billingAddress";
        break;
      }
      case "shipping address": {
        fieldName = "shippingAddress";
        break;
      }
      default:
        return "";
    }

    const { heightOfGoogleAddresses, ...preparedData } = data || {};

    const key = Object.keys(preparedData)[0];

    const setSuccessMsg = () => {
      if (fieldName === "billingAddress") return "Billing address updated";
      if (fieldName === "shippingAddress") return "Shipping address updated";
    };

    if (
      [DRAFT_ORDER_TYPES.open, DRAFT_ORDER_TYPES.closed].includes(order?.status)
    ) {
      return dispatch(
        updateDraftOrderCustomerAction({
          id: order?.id,
          data: { [fieldName]: data[key] },
          handleClose: () => setEditCustomerOpen(false),
          onSuccessMsg: setSuccessMsg(),
        })
      );
    }

    dispatch(
      updateOrderCustomerAction({
        id: order?.id,
        data: { [fieldName]: data[key] },
        handleClose: () => setEditCustomerOpen(false),
        onSuccessMsg: setSuccessMsg(),
      })
    );
  };

  const handleAddContact = (contact) => {
    delete contact.id;
    delete contact.fid;

    if (
      [DRAFT_ORDER_TYPES.open, DRAFT_ORDER_TYPES.closed].includes(order?.status)
    ) {
      return dispatch(
        updateDraftOrderCustomerAction({
          id: order?.id,
          data: { contact },
          handleClose: () => setEditCustomerOpen(false),
        })
      );
    }

    dispatch(
      updateOrderCustomerAction({
        id: order?.id,
        data: { contact },
        handleClose: () => setEditCustomerOpen(false),
      })
    );
  };

  const handleClickLogoClient = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleCloseLogoClient = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const handleTerms = useCallback(
    (value) => {
      setAnchorEl(null);
      if (
        [DRAFT_ORDER_TYPES.open, DRAFT_ORDER_TYPES.closed].includes(
          order?.status
        )
      ) {
        return dispatch(
          updateDraftOrderAction({
            id: order.id,
            data: { paymentTermsId: value.id },
          })
        );
      }
      dispatch(
        updateOrderAction({
          id: order.id,
          data: { paymentTermsId: value.id },
        })
      );
    },
    [dispatch, order.id, order?.status]
  );

  const disabledRepPermissions = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.orders?.create_edit;
    }
    return false;
  }, [repPermissions]);

  const disabledCustomersCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
    return false;
  }, [repPermissions]);

  const phoneNumber = useMemo(() => {
    const phone = order?.contactDuplicate?.phone;
    return phone?.replace("+", "")?.length
      ? setPhoneNumberMask(`${phone}`)
      : "";
  }, [order?.contactDuplicate?.phone]);

  return (
    <Paper sx={cl.paper} elevation={0} variant="outlined">
      <ContactAddDrawer
        isOpen={newContactOpen}
        handleClose={() => setNewContactOpen(false)}
        handleAddContact={handleAddContact}
        contacts={order?.customer?.contacts}
      />
      <EditCustomerPopup
        isEdit={!!order?.id}
        isOpen={editCustomerOpen}
        data={editCustomerData}
        type={editCustomerType}
        handleClose={() => setEditCustomerOpen(false)}
        handleSave={handleChangeCustomer}
        contacts={order?.customer?.contacts}
        handleAddNewContact={() => {
          setNewContactOpen(true);
          setEditCustomerOpen(false);
        }}
        customer={order?.customer}
        withoutAbsoluteLabel
      />
      <PaytermComponent
        open={isOpenPaymentTermModal}
        onClose={() => setOpenPaymentTermModal(false)}
        isQuickAdd
        setValue={setValue}
      />
      <Box
        position="absolute"
        bottom={10}
        right={10}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        {getOrderStatus}
      </Box>
      <Box sx={cl.block} xs="auto">
        <Box marginBottom={1} sx={{ display: "flex" }}>
          <Typography sx={{ ...cl.textHeader, marginRight: 1 }}>
            BILL TO
          </Typography>
          <ActionRestrictionWrapper
            disableHoverListener={!disabledRepPermissions}
            title={
              repPermissions?.role === "SALES"
                ? ADMIN_ONLY_VIEW_MESSAGE
                : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            }
            childrenWrapper={{ height: "16px", mt: "-4px" }}
          >
            <IconButton
              sx={cl.iconBtn}
              disabled={
                disabledRepPermissions ||
                isAdmin ||
                (order?.orderStatus === "CANCELED" && true)
              }
              onClick={() => {
                if (disabledCustomersCreateEdit) return;
                handleOpenEditCustomer(
                  "billing address",
                  parentCustomerBillAddress || customerBillAddress
                );
              }}
            >
              <PenIcon
                size={10}
                color={
                  order?.orderStatus === "CANCELED"
                    ? "rgba(0, 0, 0, 0.26)"
                    : "#5F6267"
                }
              />
            </IconButton>
          </ActionRestrictionWrapper>
        </Box>
        <Box>
          <Typography sx={cl.textBody}>
            {order?.customer?.displayName || order?.customer?.name}
          </Typography>
          <TypographyAddress
            address={
              order?.customer?.billToParentCustomer
                ? parentCustomerBillAddress?.formatted_address
                : customerBillAddress?.formatted_address
            }
            styles={{
              ...cl.textBody,
              fontWeight: 400,
            }}
          />
        </Box>
      </Box>

      <Box sx={cl.divider}>
        <Divider orientation="vertical" />
      </Box>

      <Box sx={cl.block}>
        <Box marginBottom={1} sx={{ display: "flex" }}>
          <Typography sx={{ ...cl.textHeader, marginRight: 1 }}>
            SHIP TO
          </Typography>
          <ActionRestrictionWrapper
            disableHoverListener={!disabledRepPermissions}
            title={
              repPermissions?.role === "SALES"
                ? ADMIN_ONLY_VIEW_MESSAGE
                : ADMIN_ONLY_VIEW_MESSAGE_PAGE
            }
            childrenWrapper={{ height: "16px", mt: "-4px" }}
          >
            <IconButton
              sx={cl.iconBtn}
              disabled={
                disabledRepPermissions ||
                isAdmin ||
                (order?.orderStatus === "CANCELED" && true)
              }
              onClick={() => {
                if (disabledCustomersCreateEdit) return;
                handleOpenEditCustomer(
                  "shipping address",
                  order?.customer?.shippingAddress
                );
              }}
            >
              <PenIcon
                size={10}
                color={
                  order?.orderStatus === "CANCELED"
                    ? "rgba(0, 0, 0, 0.26)"
                    : "#5F6267"
                }
              />
            </IconButton>
          </ActionRestrictionWrapper>
        </Box>
        <Box>
          <Typography sx={cl.textBody}>
            {order?.customer?.displayName || order?.customer?.name}
          </Typography>
          <TypographyAddress
            address={order?.customer?.shippingAddress?.formatted_address}
            styles={{
              ...cl.textBody,
              fontWeight: 400,
            }}
          />
        </Box>
      </Box>
      <Box sx={cl.divider}>
        <Divider orientation="vertical" />
      </Box>

      <Box sx={cl.block}>
        <Box marginBottom={1} sx={{ display: "flex" }}>
          <Typography sx={{ ...cl.textHeader, marginRight: 1 }}>
            CONTACT
          </Typography>

          {order?.customer?.contacts?.length ? (
            <ActionRestrictionWrapper
              disableHoverListener={!disabledRepPermissions}
              title={
                repPermissions?.role === "SALES"
                  ? ADMIN_ONLY_VIEW_MESSAGE
                  : ADMIN_ONLY_VIEW_MESSAGE_PAGE
              }
              childrenWrapper={{ height: "16px", mt: "-4px" }}
            >
              <IconButton
                sx={cl.iconBtn}
                disabled={
                  disabledRepPermissions ||
                  isAdmin ||
                  (order?.orderStatus === "CANCELED" && true)
                }
                onClick={() => {
                  if (disabledCustomersCreateEdit) return;
                  handleOpenEditCustomer(
                    "customer contact",
                    order?.contactDuplicate
                  );
                }}
              >
                <PenIcon
                  size={10}
                  color={
                    order?.orderStatus === "CANCELED"
                      ? "rgba(0, 0, 0, 0.26)"
                      : "#5F6267"
                  }
                />
              </IconButton>
            </ActionRestrictionWrapper>
          ) : null}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              ...cl.textBody,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            component="span"
          >
            {order?.contactDuplicate ? (
              `${order?.contactDuplicate?.name} | ${order?.contactDuplicate?.role}`
            ) : (
              <ActionRestrictionWrapper
                disableHoverListener={!disabledRepPermissions}
                title={
                  repPermissions?.role === "SALES"
                    ? ADMIN_ONLY_VIEW_MESSAGE
                    : ADMIN_ONLY_VIEW_MESSAGE_PAGE
                }
                childrenWrapper={{ height: "16px", mt: "-4px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "fit-content",
                    height: "fit-content",
                    cursor: disabledCustomersCreateEdit ? "default" : "pointer",
                  }}
                  onClick={
                    disabledCustomersCreateEdit
                      ? null
                      : () => setNewContactOpen(true)
                  }
                >
                  <OutlinedPlusIcon circleColor="#707070" size={21} />
                  <Typography
                    fontSize={13}
                    fontWeight={400}
                    color="#5F6267"
                    ml={1}
                  >
                    Add new
                  </Typography>
                </Box>
              </ActionRestrictionWrapper>
            )}
          </Typography>
          <Typography
            sx={{ ...cl.textBody, fontWeight: 400 }}
            color="#1C1C19"
            component={Link}
            underline="none"
            href={`tel:${phoneNumber ?? "#"}`}
            noWrap
          >
            {phoneNumber}
          </Typography>
          <Typography
            sx={{ ...cl.textBody, fontWeight: 400 }}
            color="#1C1C19"
            noWrap
          >
            {order?.contactDuplicate?.email}
          </Typography>
        </Box>
      </Box>

      <Box sx={cl.divider}>
        <Divider orientation="vertical" />
      </Box>

      {/* Payment Term */}
      <Box sx={cl.payment.wrapper}>
        {hasThirdParty || hasThirdPartyDraft ? null : (
          <Box sx={cl.payment.termsWrapper}>
            <Typography sx={cl.textHeader}>PAYMENT TERM </Typography>
            <ActionRestrictionWrapper
              disableHoverListener={!disabledRepPermissions}
              title={
                repPermissions?.role === "SALES"
                  ? ADMIN_ONLY_VIEW_MESSAGE
                  : ADMIN_ONLY_VIEW_MESSAGE_PAGE
              }
            >
              <Box
                sx={{
                  background: "#F6F6F6",
                  display: "flex",
                  justifyContent: "space-around",
                  padding: "4.5px 10px 4.5px 10px",
                  borderRadius: "5px",
                  mt: "10px",
                  cursor:
                    isAdmin || order?.orderStatus === "CANCELED"
                      ? "auto"
                      : "pointer",
                }}
                onClick={(e) => {
                  if (
                    disabledRepPermissions ||
                    isAdmin ||
                    order?.orderStatus === "CANCELED"
                  )
                    return;
                  handleClickLogoClient(e);
                }}
              >
                <Typography sx={cl.payment.term}>
                  {order?.paymentTermsDuplicate?.name}
                </Typography>
                {openHeaderList ? (
                  <KeyboardArrowUp sx={{ fontSize: 20, color: "#707070" }} />
                ) : (
                  <KeyboardArrowDown sx={{ fontSize: 20, color: "#707070" }} />
                )}
              </Box>
            </ActionRestrictionWrapper>

            <StyledMenu
              sx={{ p: 0 }}
              anchorEl={anchorEl}
              isOpen={
                order?.orderStatus === "CANCELED" ? false : openHeaderList
              }
              handleClose={handleCloseLogoClient}
            >
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setOpenPaymentTermModal(true);
                }}
              >
                <OutlinedPlusIcon />
                <Typography
                  fontSize="12px"
                  fontWeight="400"
                  color="#42A57F"
                  ml={1}
                >
                  Add new
                </Typography>
              </MenuItem>
              {paytermsListActive.map((payterm) => (
                <MenuItem key={payterm.id} onClick={() => handleTerms(payterm)}>
                  {truncateText(payterm.name, 50)}
                </MenuItem>
              ))}
            </StyledMenu>
          </Box>
        )}
      </Box>

      <Box sx={cl.block}>
        <Box marginBottom={1.25} sx={{ display: "flex" }}>
          <Typography sx={{ ...cl.textHeader, marginRight: 1 }}>
            PRICE LIST
          </Typography>
        </Box>
        <Box>
          {priceListName ? (
            <Box
              sx={{
                height: "28px",
                maxWidth: "100%",
                bgcolor: "#409A6510",
                borderRadius: "4px",
                display: "inline-flex",
                alignItems: "center",
                px: "10px",
                gap: "10px",
              }}
            >
              <Typography fontSize={12} color="#5F6267" noWrap>
                {priceListName}
              </Typography>
            </Box>
          ) : (
            <Typography fontSize={13} color="#5F6267">
              Standard
            </Typography>
          )}
        </Box>
      </Box>

      {/* Status */}
      {!hasThirdParty && (
        <Box sx={cl.blockLast}>
          <Box sx={cl.statusText}>{orderStatusIcon}</Box>
        </Box>
      )}
    </Paper>
  );
};
OrdersTopInfoBar.propTypes = {
  order: object,
  hasThirdParty: bool,
  hasThirdPartyDraft: bool,
  repPermissions: object,
};

OrdersTopInfoBar.defaultProps = {
  order: null,
};

export default OrdersTopInfoBar;
