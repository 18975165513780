import { isValidNotPastDate } from "helpers/helpers";
import moment from "moment-timezone";
import * as Yup from "yup";

export const validationSchema = (timeZone, isEdit, defaultActivationDate) =>
  Yup.object().shape({
    name: Yup.string().trim().required("This field is required"),
    priority: Yup.string().trim().required("This field is required"),
    stops: Yup.array().test(
      "stopsValidation",
      "Add at least one stop.",
      (value) => {
        return value.length > 0;
      }
    ),
    activationDate: Yup.string().test(
      "activationDate",
      "Invalid date",
      (value) => {
        if (!value) return true;

        const taskDefDate = isEdit
          ? moment(defaultActivationDate?.current)
          : null;
        const currentDate = moment(value);

        const allowPastDate = isEdit
          ? taskDefDate.isSame(currentDate) && isEdit
          : false;

        return isValidNotPastDate(value, timeZone, allowPastDate);
      }
    ),
  });
