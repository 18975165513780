import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { bool, func, object } from "prop-types";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useDebounce } from "helpers/hooks";

import {
  CropperComponent,
  Loader,
  NewManufacturerPopup,
  PhotoGallery,
} from "components";

import { useWatch } from "react-hook-form";

import { Grid, Box, Typography } from "@mui/material";

import {
  LIMIT_UPLOAD_IMAGES,
  MAX_UPLOAD_FILE_RESOLUTION_PRODUCT_MP,
  MAX_UPLOAD_FILE_SIZE_PRODUCT_MB,
  PRODUCT_TYPE_INVENTORY,
} from "utils/constants";
import { checkProductAvailabilityService } from "services/products";
import { error, warning } from "utils/notifications";
import { checkFileSize, getImageSizes } from "helpers/helpers";
import { useUploadFiles } from "../useUploadFiles.js";
import { usePhotoGallery } from "../usePhotoGallery.js";
import {
  BarcodeBlock,
  CaseQtyBlock,
  CategoriesComponent,
  ContinueSellingBlock,
  CountryBlock,
  DescriptionBlock,
  ImagesBlock,
  ManufacturerBlock,
  PricesBlock,
  ProductNameBlock,
  SkuBlock,
  StatusBlock,
  TagsBlock,
  TrackQtyBlock,
  VariationsSelectorBlock,
} from "./components";
import { currentUserSelector } from "redux/selectors/auth.js";
import { useCountryOfOriginActions } from "helpers/useCountryOfOriginActions.js";
import { useManufacturersActions } from "helpers/useManufacturersActions.js";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

const RequiredInfoTab = ({
  control,
  setVariationsTab,
  setValue,
  isEdit,
  setError,
  errors,
  clearErrors,
  saveDataAfterChangePhotos,
  setTabNames,
}) => {
  const { currentUser } = useSelector(selector);

  const isQuickBooksConnected = !!currentUser?.quickBooksTokens;

  // const mediaXs = useMediaQuery("(max-width:1330px)");

  const inputRef = useRef(null);

  const formField = useWatch({ control });

  const [images, setImages] = useState([]);
  const [replacementPicture, setReplacementPicture] = useState(null);
  const [positionOldPicture, setPositionOldPicture] = useState();

  const [viewAllTags, setViewAllTags] = useState(false);

  const [isOpenNewMan, setIsOpenNewManDialog] = useState(false);

  const [dialogState, setDialogState] = useState({
    open: false,
    file: null,
    isSingle: false,
  });

  const { handleUploadFiles, loading: isImagesLoading } = useUploadFiles({
    isEdit,
  });

  const {
    list: countryOfOriginList,
    loading: countryOfOriginLoading,
    search: countryOfOriginSearch,
    setSearch: setCountryOfOriginSearch,
  } = useCountryOfOriginActions({
    params: {
      sort_clients_countries_first: true,
      sort_by_name: "asc",
    },
  });

  useEffect(() => {
    setVariationsTab(formField?.isMultiple);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formField.isMultiple]);

  useEffect(() => {
    if (images?.length) {
      if (images?.length + formField.photos.length > LIMIT_UPLOAD_IMAGES) {
        warning(`Maximum ${LIMIT_UPLOAD_IMAGES} images are allowed.`);
        return;
      }

      let newImages = [...formField.photos];

      const preparedData = {
        successMsg: "Photo added",
        data: newImages,
      };
      if (formField?.isMultiple)
        preparedData.variationsData = formField?.variationsFields;

      handleUploadFiles([...images], () =>
        saveDataAfterChangePhotos(preparedData)
      ).then((res) => {
        if (res) {
          newImages.push(...res);
          setValue("photos", newImages);
        }
      });
      setImages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const handleReplacePicture = async () => {
    try {
      let newImages = [...formField.photos];
      handleUploadFiles([replacementPicture], () => {
        const preparedData = {
          successMsg: "Photo added",
          data: newImages,
        };
        if (formField?.isMultiple)
          preparedData.variationsData = formField?.variationsFields;
        saveDataAfterChangePhotos(preparedData);
      }).then((res) => {
        newImages.splice(positionOldPicture, 1, ...res);
        setValue("photos", newImages);
      });
      setReplacementPicture(null);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (replacementPicture) handleReplacePicture();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replacementPicture]);

  const handleUpload = async (e) => {
    if (e.target.files.length === 0) return;

    const { files } = e.currentTarget;

    const { width, height } = await getImageSizes(files[0]);
    const megapixels = (width * height) / 1000000;
    if (megapixels > MAX_UPLOAD_FILE_RESOLUTION_PRODUCT_MP) {
      error(
        <>
          <Typography component="span" fontSize={12}>
            Photo upload failed
            <br />
            {files[0]?.name}
            <br />
            Exceeds maximum image resolution of{" "}
            {MAX_UPLOAD_FILE_RESOLUTION_PRODUCT_MP} MP
          </Typography>
          <br />
        </>
      );
      e.target.value = null;
      return;
    }

    if (
      checkFileSize({
        fileSize: files[0]?.size,
        maxFileSizeMb: MAX_UPLOAD_FILE_SIZE_PRODUCT_MB,
      })
    ) {
      error(
        <>
          <Typography component="span" fontSize={12}>
            Photo upload failed
            <br />
            {files[0]?.name}
            <br />
            Exceeds maximum file size of {MAX_UPLOAD_FILE_SIZE_PRODUCT_MB} MB
          </Typography>
          <br />
        </>
      );
      e.target.value = null;
      return;
    }

    if (e.target.files.length > LIMIT_UPLOAD_IMAGES) {
      warning(`Maximum ${LIMIT_UPLOAD_IMAGES} images are allowed.`);
      return;
    }

    if (e.target.files?.length === 1) {
      await setDialogState((prev) => ({
        ...prev,
        open: true,
        file: files[0],
        isSingle: true,
      }));
      e.target.value = null;
      return;
    }
    setImages([...e.target.files]);
    e.target.value = null;
  };

  const handleDeletePic = async (image) => {
    const isGroupContentId = !!image?.groupContentId;

    const data = formField.photos.filter((el) => {
      return isGroupContentId ? el?.uuid !== image?.uuid : el?.id !== image?.id;
    });

    setValue("photos", data);

    const preparedData = {
      successMsg: "Photo deleted",
      data,
    };
    if (formField?.isMultiple)
      preparedData.variationsData = formField?.variationsFields;

    saveDataAfterChangePhotos(preparedData);
  };

  const handleMovePic = (image, direction) => {
    const isGroupContentId = !!image?.groupContentId;
    const tempArr = [...formField.photos];
    const position = tempArr.findIndex((el) =>
      isGroupContentId ? el?.uuid === image?.uuid : el?.id === image?.id
    );
    const lestElement = formField.photos.length - 1;
    if (
      (position === 0 && direction === "left") ||
      (position === lestElement && direction === "right")
    ) {
      return;
    }
    const newPosition = direction === "left" ? position - 1 : position + 1;
    const el = tempArr.find((el) =>
      isGroupContentId ? el?.uuid === image?.uuid : el?.id === image?.id
    );

    tempArr.splice(position, 1);
    tempArr.splice(newPosition, 0, el);

    setValue("photos", tempArr);
    const preparedData = {
      successMsg: "Photo order saved",
      data: tempArr,
    };
    if (formField?.isMultiple)
      preparedData.variationsData = formField?.variationsFields;
    saveDataAfterChangePhotos(preparedData);
  };

  const handleChangePic = (e, image) => {
    const isGroupContentId = !!image?.groupContentId;
    setPositionOldPicture(
      formField.photos.findIndex((el) => {
        return isGroupContentId
          ? el?.uuid === image?.uuid
          : el?.id === image?.id;
      })
    );
    const { files } = e.currentTarget;
    setDialogState((prev) => ({ ...prev, open: true, file: files[0] }));
  };

  const {
    list: manufacturers,
    count: manufacturersCount,
    handleFetch: fetchManufacturers,
    page: manufacturersPage,
    search: customersSearchInput,
    setSearch: setCustomersSearchInput,
    handleSetPage: setManufacturersPage,
    loading: manufacturersLoading,
  } = useManufacturersActions({
    params: {
      status: '["active"]',
    },
    allowLoadingWithPageOnly: true,
  });

  const [tooltipState, setTooltipState] = useState({ open: false, title: "" });

  const nameDebounced = useDebounce(formField.name.trim(), 500);

  const containsSpecialChars = (str) => {
    const regex = /[:\t\n]/;

    return regex.test(str);
  };

  const handleCheckNameDuplicate = useCallback(() => {
    // if (!nameDebounced) return clearErrors("name");

    const containsSpecial = containsSpecialChars(nameDebounced);

    if (containsSpecial) {
      setTooltipState({
        open: true,
        title: "Invalid character used",
      });
      return;
    }
    // else {
    //   clearErrors("name");
    //   setTooltipState({ open: false, title: "" });
    // }

    if (nameDebounced)
      return checkProductAvailabilityService({ name: nameDebounced }).then(
        (res) => {
          if (!res.available && res.productWithName?.id !== formField.id) {
            return setError("name", {
              type: "duplicate",
              message: "This product name is already used.",
            });
          }
          clearErrors("name");
          setTooltipState({ open: false, title: "" });
        }
      );
    clearErrors("name");
    setTooltipState({ open: false, title: "" });
  }, [nameDebounced, setError, formField.id, clearErrors]);

  useEffect(() => {
    handleCheckNameDuplicate();
  }, [handleCheckNameDuplicate]);

  const {
    handleCloseGallery,
    handleOpenGalleryWithIndex,
    isOpenGallery,
    handleDownloadImage,
    activeSlideIndex,
    setActiveSlideIndex,
    openIndex,
  } = usePhotoGallery();

  const closeNameTooltip = useMemo(() => {
    return isOpenGallery || dialogState.open || isOpenNewMan || viewAllTags;
  }, [dialogState.open, isOpenGallery, isOpenNewMan, viewAllTags]);

  return (
    <Grid container>
      <PhotoGallery
        open={isOpenGallery}
        title={formField.name}
        handleClose={handleCloseGallery}
        imageList={formField.photos}
        customeUserIndex={openIndex}
        handleDownloadImage={handleDownloadImage}
        handleDeleteImage={handleDeletePic}
        activeSlideIndex={activeSlideIndex}
        setActiveSlideIndex={setActiveSlideIndex}
      />
      <CropperComponent
        setUploadPhotoSquare
        open={dialogState.open}
        onClose={() => {
          setDialogState((prop) => ({
            ...prop,
            open: false,
            file: null,
            isSingle: false,
          }));
          if (inputRef?.current?.value) inputRef.current.value = null;
        }}
        size="small"
        file={dialogState.file}
        handleSaveImg={(imgData) => {
          imgData.then((res) => {
            if (dialogState.isSingle) setImages((prev) => [...prev, res]);
            if (!dialogState.isSingle) setReplacementPicture(res);
          });
        }}
      />
      <NewManufacturerPopup
        isOpen={isOpenNewMan}
        handleClose={() => setIsOpenNewManDialog(false)}
        setValue={setValue}
        fetchManufacturers={() => fetchManufacturers(1)}
      />
      {isEdit && !formField?.id ? (
        <Grid item xs={12} height="453px">
          <Loader isLoading={true} />
        </Grid>
      ) : (
        <>
          <Grid xs={5.18} item>
            <VariationsSelectorBlock {...{ isEdit, control }} />

            <Grid
              sx={{
                borderRight: "1px solid #D4D4D4",
                marginBottom: "46px",
                paddingLeft: "34px",
                paddingRight: "90px",
                gap: 3,
              }}
              item
              container
            >
              <ProductNameBlock
                closeTooltip={closeNameTooltip}
                {...{ tooltipState, errors, control }}
              />

              <SkuBlock {...{ formField, control }} />

              {!formField.isMultiple && (
                <BarcodeBlock {...{ errors, setValue, control }} />
              )}

              <ManufacturerBlock
                {...{
                  manufacturersPage,
                  manufacturers,
                  manufacturersCount,
                  setCustomersSearchInput,
                  customersSearchInput,
                  setIsOpenNewManDialog,
                  setManufacturersPage,
                  formField,
                  setValue,
                  control,
                }}
                loading={manufacturersLoading}
                handleFetchManufacturers={() => {
                  fetchManufacturers(manufacturersPage + 1);
                }}
              />

              <CategoriesComponent {...{ control, setValue }} />

              <CountryBlock
                {...{
                  control,
                  setValue,
                  countryOfOriginList,
                  countryOfOriginLoading,
                  countryOfOriginSearch,
                  setCountryOfOriginSearch,
                }}
              />

              <DescriptionBlock {...{ control }} />
            </Grid>
          </Grid>

          <Grid xs={6.82} item>
            <StatusBlock {...{ control, setValue, formField }} />

            <Grid
              sx={{
                borderLeft: "1px solid #D4D4D4",
                marginLeft: "-1px",
                paddingLeft: "80px",
                paddingRight: "114px",
                marginBottom: "46px",
                gap: 3,
              }}
              item
              container
            >
              {!formField.isMultiple && (
                <PricesBlock {...{ formField, setValue, control, errors }} />
              )}

              {!formField.isMultiple && <CaseQtyBlock {...{ control }} />}

              <Grid mt="-10px" xs={12} item container>
                <TrackQtyBlock
                  {...{
                    control,
                    isQuickBooksConnected,
                    isEdit,
                    formField,
                    setTabNames,
                  }}
                />

                {formField?.type === PRODUCT_TYPE_INVENTORY.inventory && (
                  <ContinueSellingBlock {...{ formField, setValue, control }} />
                )}
              </Grid>

              <TagsBlock
                {...{
                  setValue,
                  formField,
                  errors,
                  setError,
                  viewAllTags,
                  setViewAllTags,
                }}
                adminIsAllowed
              />

              {formField.isMultiple && <Box height="50px" />}

              <ImagesBlock
                {...{
                  isImagesLoading,
                  handleUpload,
                  formField,
                  handleOpenGalleryWithIndex,
                  handleChangePic,
                  handleMovePic,
                  handleDeletePic,
                  inputRef,
                }}
                adminIsAllowed
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

RequiredInfoTab.propTypes = {
  setVariationsTab: func,
  control: object,
  setValue: func,
  isEdit: bool,
  setError: func,
  errors: object,
  clearErrors: func,
  saveDataAfterChangePhotos: func,
  setTabNames: func,
};

export default RequiredInfoTab;
