import { useCallback, useEffect, useMemo, useState } from "react";
import { func, object, string, bool, array } from "prop-types";
import { Box, Chip, Typography } from "@mui/material";
import { FilterMenu, FilterSearchTextField, StyledButton } from "components";
import CloseIcon from "@mui/icons-material/Close";
import { FilterIcon } from "components/Icons";
import useStyles from "../../styles";
import {
  FETCH_LIMITS,
  SCROLL_LIMIT,
  UNCATEGORIZED_TERRITORY,
} from "utils/constants";
import { getParentCustomersService } from "services/customers";
import { error } from "utils/notifications";
import { getRepsService } from "services/reps";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { territoryListSelector } from "redux/selectors/territory";
import { useTagsActions } from "helpers/useTagsActions";

const selector = createSelector(territoryListSelector, (territoriesList) => ({
  territoriesList,
}));

export const FiltersBlock = ({
  searchInput,
  setSearchInput,
  filterChipKeys,
  getChipLabel,
  handleDeleteFilter,
  setCustomersState,
  filterAnchor,
  filterMenuOpen,
  setFilterMenuOpen,
  filterFields,
  switches,
  handleApplyFilter,
}) => {
  const classes = useStyles();

  const { territoriesList } = useSelector(selector);

  const [params, setParams] = useState({
    limit: FETCH_LIMITS.FILTER_CUSTOMERS,
    page: 1,
    include_uncategorized: true,
    with_candidates: false,
    search: "",
  });

  const [repsList, setRepsList] = useState({
    sales: [],
    merchendiser: [],
    thirdParty: [],
  });

  const [parentsGroupList, setParentsGroupList] = useState({
    list: [],
    count: 0,
    loading: false,
  });

  const {
    list: tagsList,
    count: tagsCount,
    handleFetchTags,
    page: tagsPage,
    setTagsParams,
    searcheble,
  } = useTagsActions({
    type: "customer",
    params: {
      used: true,
      sort_by_tag: "asc",
    },
  });

  const [groupState, setGroupState] = useState({
    search: "",
    searcheble: false,
  });

  useEffect(() => {
    setParams((prev) => ({ ...prev, search: groupState.search }));
  }, [groupState.search, setParams]);

  const handleFetchGroups = useCallback(
    async (page) => {
      try {
        const res = await getParentCustomersService({
          ...params,
          page: page || 1,
          limit: SCROLL_LIMIT,
        });
        const { rows, count } = res || {};

        setParentsGroupList((prev) => ({
          ...prev,
          list: page ? [...prev.list, ...rows] : rows,
          count,
        }));
        setGroupState((prev) => ({
          ...prev,
          searcheble: !prev.searcheble ? count >= 10 : prev.searcheble,
        }));
        setParams((prev) => ({ ...prev, page: page || 1 }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setParentsGroupList((prev) => ({ ...prev, loading: false }));
      }
    },
    [params]
  );

  useEffect(() => {
    handleFetchGroups(1);
    // eslint-disable-next-line
  }, []);

  const [repsState, setRepsState] = useState({ search: "", searcheble: false });

  useEffect(() => {
    const controllerSales = new AbortController();
    const controllerMerch = new AbortController();
    const controllerThirdParty = new AbortController();

    Promise.all([
      getRepsService(
        { role: "sales", search: repsState.search },
        controllerSales.signal
      ),
      getRepsService(
        { role: "merchandiser", search: repsState.search },
        controllerMerch.signal
      ),
      getRepsService(
        { role: "third_party", search: repsState.search },
        controllerThirdParty.signal
      ),
    ])
      .then((res) => {
        setRepsList({
          sales: res[0].rows,
          merchendiser: res[1].rows,
          thirdParty: res[2].rows,
        });
        setRepsState((prev) => ({
          ...prev,
          searcheble: !prev.searcheble
            ? res[0].count + res[1].count + res[2].count >= 10
            : prev.searcheble,
        }));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err.message));

    return () => {
      controllerSales.abort();
      controllerMerch.abort();
      controllerThirdParty.abort();
    };
  }, [repsState.search]);

  const [territoriesParams, setTerritoriesParams] = useState({ search: "" });
  let filteredTerritories = territoriesList.filter((territory) =>
    territory.name
      .toLowerCase()
      .includes(territoriesParams.search.toLowerCase())
  );

  const selectMatchData = useMemo(() => {
    return [
      {
        id: "representative_id",
        childrenList: [
          { id: "no", name: "No rep assigned" },
          repsList?.sales?.length ? { type: "group", name: "Sales" } : null,
          ...repsList?.sales,
          repsList?.merchendiser?.length
            ? { type: "group", name: "Merchandisers" }
            : null,
          ...repsList?.merchendiser,
          repsList?.thirdParty?.length
            ? { type: "group", name: "Third Party" }
            : null,
          ...repsList?.thirdParty,
        ].filter((value) => value !== null),
        label: "",
        setParams: setRepsState,
        searcheble: repsState.searcheble,
      },
      {
        id: "last_visit",
        isVisit: true,
        textFieldProps: {
          inputSx: {
            pr: 0,
          },
          endIcon: (
            <Box
              backgroundColor="#F7F7F7"
              height="32px"
              display="flex"
              alignItems="center"
              px="9px"
              borderLeft="0.5px solid #D5D9D9"
            >
              <Typography fontSize="12px">days</Typography>
            </Box>
          ),
        },
        moreLessFirst: true,
        valuesForTextfield: ["more_than", "less_than"],
        label: "",
        notched: false,
        labelProps: { shrink: false },
      },
      {
        id: "territory_id",
        childrenList: [
          {
            id: UNCATEGORIZED_TERRITORY.id,
            name: UNCATEGORIZED_TERRITORY.name,
          },
          ...filteredTerritories,
        ],
        label: "",
        setParams: setTerritoriesParams,
        searcheble: territoriesList.length >= 10,
      },
      {
        id: "group_id",
        childrenList: parentsGroupList?.list,
        handleFetch: () => handleFetchGroups(params?.page + 1),
        setParams: setGroupState,
        dataCount: parentsGroupList?.count,
        dataLength: parentsGroupList?.list.length,
        label: "",
        searcheble: groupState.searcheble,
      },
      {
        id: "tag_id",
        childrenList: tagsList.map(({ tag, ...item }) => ({
          ...item,
          name: tag,
        })),
        handleFetch: () => handleFetchTags(tagsPage + 1),
        setParams: setTagsParams,
        dataCount: tagsCount,
        dataLength: tagsList?.length,
        label: "",
        searcheble,
      },
      {
        id: "last_order",
        moreLessFirst: true,
        isVisit: true,
        isOrder: true,
        childrenlist: [
          {
            iconId: "highestOrderIcon",
            name: "More Than",
            id: "more_than",
          },
          {
            iconId: "lovestOrderIcon",
            name: "Less Than",
            id: "less_than",
          },
        ],

        textFieldProps: {
          inputSx: {
            pr: 0,
          },
          endIcon: (
            <Box
              backgroundColor="#F7F7F7"
              height="32px"
              display="flex"
              alignItems="center"
              px="9px"
              borderLeft="0.5px solid #D5D9D9"
            >
              <Typography fontSize="12px">days</Typography>
            </Box>
          ),
        },
        valuesForTextfield: ["more_than", "less_than"],
        label: "",
        notched: false,
        labelProps: { shrink: false },
      },
      {
        id: "tasks_due",
        textFieldProps: {
          inputSx: { pr: 0 },
          endIcon: (
            <Box
              backgroundColor="#F7F7F7"
              height="32px"
              display="flex"
              alignItems="center"
              px="9px"
              borderLeft="0.5px solid #D5D9D9"
            >
              <Typography fontSize="12px">days</Typography>
            </Box>
          ),
        },
        label: "",
        notched: false,
        labelProps: { shrink: false },
        datesList: [
          { name: "Today", id: "today" },
          { name: "Tomorrow", id: "tomorrow" },
          { name: "This week", id: "this_week" },
          { name: "Next week", id: "next_week" },
        ],
      },
    ];
  }, [
    handleFetchGroups,
    handleFetchTags,
    params?.page,
    parentsGroupList?.count,
    parentsGroupList?.list,
    repsList?.merchendiser,
    repsList?.sales,
    repsList?.thirdParty,
    tagsCount,
    tagsList,
    tagsPage,
    territoriesList,
    searcheble,
    repsState.searcheble,
    groupState.searcheble,
    filteredTerritories,
    setTagsParams,
  ]);

  return (
    <Box className={classes.mainFilterWrapper}>
      <FilterSearchTextField
        adminIsAllowed
        formSx={{ minWidth: "380px" }}
        inputPropsSx={{ height: "36px" }}
        placeholder="Add stops by searching customer name, ID or address"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        fullWidth
        handleClearValue={() => setSearchInput("")}
        customAdornment={
          <Box className={classes.wrapperChip}>
            {filterChipKeys.map((key) => (
              <Chip
                key={key}
                className={classes.filterChip}
                size="small"
                label={getChipLabel(key)}
                deleteIcon={<CloseIcon size="15" style={{ fill: "#707070" }} />}
                onMouseDown={(e) => e.stopPropagation()}
                onDelete={() => handleDeleteFilter(key)}
              />
            ))}
          </Box>
        }
      />

      <Box>
        <StyledButton
          label="View all"
          onClick={() =>
            setCustomersState((prev) => ({
              ...prev,
              popupOpen: true,
            }))
          }
          variant="outlined"
          color="greyBtn"
          fontSize="15px"
          className={classes.viewAllBtn}
        />
      </Box>

      <StyledButton
        className={classes.filterBtn}
        fontSize="15px"
        label="Filter"
        startIcon={
          filterChipKeys.length ? (
            <Typography
              sx={{
                fontSize: "9px!important",
                color: "#ffffff",
                backgroundColor: "#47A06D",
                borderRadius: "50%",
                width: "15px",
                height: "15px",
              }}
            >
              {filterChipKeys.length}
            </Typography>
          ) : (
            <FilterIcon />
          )
        }
        variant="outlined"
        ref={filterAnchor}
        color="edit"
        onClick={() => setFilterMenuOpen(true)}
      />
      <FilterMenu
        anchorEl={filterAnchor.current}
        open={filterMenuOpen}
        onClose={() => setFilterMenuOpen(false)}
        filterFields={filterFields}
        switches={switches}
        handleApplyFilter={handleApplyFilter}
        selectMatchData={selectMatchData}
      />
    </Box>
  );
};

FiltersBlock.propTypes = {
  searchInput: string,
  setSearchInput: func,
  filterChipKeys: array,
  getChipLabel: func,
  handleDeleteFilter: func,
  setCustomersState: func,
  filterAnchor: object,
  filterMenuOpen: bool,
  setFilterMenuOpen: func,
  filterFields: object,
  switches: array,
  handleApplyFilter: func,
};
FiltersBlock.defaultProps = {};

export default FiltersBlock;
