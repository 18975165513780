// import { useCallback, useEffect, useRef, useState } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { array, bool, func, number, object, string } from "prop-types";
import ReprHeaderComponent from "./ReprHeaderComponent.jsx";
import RepsItem from "./RepsItem/ReprItem.jsx";
import TerritoriesMenu from "./TerritoriesMenu/TerritoriesMenu.jsx";
// import { getRepsAction } from "../../../redux/actions/reps";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  SCROLL_LIMIT,
} from "../../../utils/constants.js";
import {
  getRepsAction,
  repsGetParamsAction,
} from "../../../redux/actions/reps";
// import { SCROLL_LIMIT } from "../../../utils/constants.js";
import { createSelector } from "reselect";
import {
  repsExistActiveSelector,
  repsExistInactiveSelector,
  repsGetParamsSelector,
  repsListSelector,
  repsLoadingSelector,
} from "../../../redux/selectors/reps.js";
import { currentUserSelector } from "../../../redux/selectors/auth.js";
import PhotoGroupPopup from "../../CustomersPage/components/GalleryTab/components/PhotoGroupPopup/PhotoGroupPopup.jsx";
import EmptyScreen from "../../../components/EmptyScreen/EmptyScreen.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import {
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
  NoteDrawer,
  TableHeaderControlPanel,
  TaskDrawer,
} from "components";
import MapPopup from "Pages/CustomersPage/pages/NewCustomerPage/MapPopup/MapPopup.jsx";

const selector = createSelector(
  repsLoadingSelector,
  repsGetParamsSelector,
  currentUserSelector,
  repsListSelector,
  repsExistActiveSelector,
  repsExistInactiveSelector,
  (
    repsLoading,
    repsGetParams,
    currentUser,
    repsList,
    repsExistActive,
    repsExistInactive
  ) => ({
    repsLoading,
    repsGetParams,
    currentUser,
    repsList,
    repsExistActive,
    repsExistInactive,
  })
);

export const ListSalesComponent = ({
  users,
  usersCount,
  handleOpenProfileDialog,
  handleSetCheckedUser,
  checkedUsers,
  checkAllUsers,
  allChecked,
  currentTab,
  showInactive,
  repPermissions,
  setCheckedUsers,
  QUICK_ACTIONS,
  MULTIPLE_ACTIONS,
}) => {
  const { state } = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [territoriesList, setTerritoriesList] = useState([]);
  const [mapData, setMapData] = useState(null);
  const [taskDialogState, setTaskDialogState] = useState({
    open: false,
    data: null,
  });

  const handleCustomerTaskDrawer = useCallback((open, data) => {
    setTaskDialogState({
      open,
      data,
    });
  }, []);

  const [isOpenProductDialog, setIsOpenProductDialog] = useState(false);
  const handleCloseProductDialog = () => {
    setIsOpenProductDialog(false);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClickCheckin = useCallback((data) => {
    setMapData(data);
  }, []);

  const { repsLoading, repsGetParams, currentUser, repsExistInactive } =
    useSelector(selector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ref = useRef(null);

  useEffect(() => {
    ref.current = showInactive;
  }, [showInactive]);

  const handleFetch = useCallback(() => {
    const fetchQuery = {
      ...repsGetParams,
      limit: SCROLL_LIMIT,
      cursor: users[users.length - 1].cursor,
    };
    dispatch(getRepsAction(fetchQuery, { isScrolling: true }, () => {}, ref));
  }, [dispatch, users, repsGetParams]);

  const handleFetchAllAndSelect = () => {
    if (users.length === usersCount) return setCheckedUsers(users);
    return dispatch(
      getRepsAction(repsGetParams, { isScrolling: false }, (list) =>
        setCheckedUsers(list)
      )
    );
  };

  const [noteDialogState, setNoteDialogState] = useState({
    open: false,
    data: null,
  });

  const handleCloseCustomerNotes = useCallback(() => {
    setNoteDialogState({
      open: false,
      data: null,
    });
  }, []);

  const handleOpenCustomerNotes = useCallback((data) => {
    setNoteDialogState({
      open: true,
      data,
    });
  }, []);

  const quickSort = useMemo(() => {
    return Object.keys(repsGetParams).reduce((acc, key) => {
      if (key.startsWith("sort_")) {
        acc[key] = repsGetParams[key];
      }
      return acc;
    }, {});
  }, [repsGetParams]);

  const handleSetSortBy = useCallback(
    (field) => {
      const sortParams = {
        ...repsGetParams,
        sort_name: null,
        sort_role: null,
        sort_work_status: null,
        sort_latest_activity: null,
        sort_revenue: null,
        sort_orders: null,
        sort_customers: null,
        sort_visits: null,
        sort_territory: null,
        shouldLoading: false,
      };

      sortParams[field] = repsGetParams[field] === "asc" ? "desc" : "asc";

      dispatch(repsGetParamsAction(sortParams));
    },
    [repsGetParams, dispatch]
  );

  return users.length ? (
    <>
      {!!noteDialogState.open && (
        <NoteDrawer
          open={noteDialogState.open}
          onClose={handleCloseCustomerNotes}
          data={noteDialogState.data}
          refetchCallback={handleFetch}
          titleLabel="Notes"
        />
      )}

      <Box px="32px" position="relative">
        <TerritoriesMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          territories={territoriesList}
        />
        <PhotoGroupPopup
          hidePagination
          user={{
            avatar: currentUser?.profilePhoto,
            name: currentUser?.name,
            id: currentUser?.id,
          }}
          isOpenProductDialog={isOpenProductDialog}
          handleCloseProductDialog={handleCloseProductDialog}
        />
        <TableHeaderControlPanel
          actionWrapperProps={{
            sx: { padding: "0 18px 0 14.5px !important" },
          }}
          checkedCount={checkedUsers?.length}
          actionsList={QUICK_ACTIONS}
          loading={repsLoading}
          dropDownActions={MULTIPLE_ACTIONS}
          onSelectAll={handleFetchAllAndSelect}
          hasCheckedItems={!!checkedUsers?.length}
          availableSelectCount={usersCount}
          selectName="customer"
          onSelectVisible={checkAllUsers}
          cancelSelection={() => setCheckedUsers([])}
          headerComponent={
            <ReprHeaderComponent
              checkAllUsers={checkAllUsers}
              allChecked={allChecked}
              currentTab={currentTab}
              quickSort={quickSort}
              handleSetSortBy={handleSetSortBy}
            />
          }
        />

        <Stack
          sx={{
            height: "100%",
            maxHeight: `calc(100vh - ${
              currentTab === "3rd Party" ? 243 : 320
            }px)`,
            overflow: "hidden",
            position: "relative",
            opacity: repsLoading ? 0.5 : 1,
            pointerEvents: repsLoading && "none",
            borderWidth: users?.length ? "0 1px 1px 1px" : 0,
            borderStyle: "solid",
            borderColor: "#D5D9D9",
            borderRadius: "0 0 4px 4px",
          }}
        >
          <InfiniteScrollWrapper
            dataLength={users.length}
            loading={repsLoading}
            next={handleFetch}
            loader={
              <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
            }
            hasMore={users.length < usersCount}
            maxHeight={`calc(100vh - ${
              currentTab === "3rd Party" ? 243 : 320
            }px)`}
            id="rep-scroll-table"
          >
            {users.map((user) => (
              <RepsItem
                key={user.id}
                user={user}
                handleSetCheckedUser={handleSetCheckedUser}
                handleOpenProfileDialog={handleOpenProfileDialog}
                checkedUsers={checkedUsers}
                setAnchorEl={setAnchorEl}
                setTerritoriesList={setTerritoriesList}
                timeZone={currentUser.timeZone}
                setIsOpenProductDialog={setIsOpenProductDialog}
                currentTab={currentTab}
                handleClickCheckin={handleClickCheckin}
                handleCustomerTaskDrawer={handleCustomerTaskDrawer}
                repPermissions={repPermissions}
                handleOpenCustomerNotes={handleOpenCustomerNotes}
              />
            ))}
            {!repsLoading && users.length < usersCount && (
              <InfiniteLoadMoreBtn onClick={handleFetch} />
            )}
          </InfiniteScrollWrapper>
        </Stack>

        <MapPopup
          withHeader
          isOpen={!!mapData}
          handleClose={() => setMapData(null)}
          address={mapData?.customer?.shippingAddress}
          customer={mapData}
          closeBtnStyle={{
            top: 10,
            right: 10,
          }}
        />

        <TaskDrawer
          open={taskDialogState.open}
          onClose={() => handleCustomerTaskDrawer(false, null)}
          data={taskDialogState.data}
          refetchCallback={() =>
            dispatch(getRepsAction({ limit: 15 }, { isScrolling: false }))
          }
        />
      </Box>
    </>
  ) : (
    <EmptyScreen
      type="reps"
      height="calc(100vh - 470px)"
      onConfirm={() =>
        navigate("/representatives/new", {
          state: state?.type === "onboarding" && { type: "onboarding" },
        })
      }
      showAction={repsExistInactive}
      loading={repsLoading}
      disabled={!!repPermissions}
      tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
    />
  );
};

ListSalesComponent.propTypes = {
  users: array,
  handleOpenProfileDialog: func,
  handleSetCheckedUser: func,
  checkedUsers: array,
  QUICK_ACTIONS: array,
  MULTIPLE_ACTIONS: array,
  checkAllUsers: func,
  setCheckedUsers: func,
  allChecked: bool,
  usersCount: number,
  currentTab: string,
  showInactive: bool,
  repsExistActive: bool,
  repPermissions: object,
};
ListSalesComponent.defaultProps = {
  users: [],
  checkedUsers: [],
  allChecked: false,
  usersCount: 0,
  showInactive: false,
};

export default ListSalesComponent;
