import { string, func, object } from "prop-types";
import { Box, Typography } from "@mui/material";
import { TaskBigIcon } from "../../../../../../components/Icons";
import { ActionRestrictionWrapper } from "components";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const TaskItem = ({ size, onClick, sxProp, repPermissions }) => {
  const isDisabled = repPermissions && !repPermissions?.tasks?.create_edit;

  const isSmallSize = size === "small";
  return (
    <ActionRestrictionWrapper
      title={ADMIN_ONLY_VIEW_MESSAGE}
      disableHoverListener={!isDisabled}
      //tooltipProps={{
      //  sx: {
      //    "& .MuiTooltip-tooltip": {
      //      mr: "6px",
      //    },
      //  },
      //}}
    >
      <Box
        sx={{
          border: "1px solid #D9D9D9",
          borderRadius: "4px",
          width: isSmallSize ? "40px" : "56px",
          maxWidth: isSmallSize ? "40px" : "56px",
          minWidth: isSmallSize ? "40px" : "56px",
          height: isSmallSize ? "40px" : "56px",
          maxHeight: isSmallSize ? "40px" : "56px",
          minHeight: isSmallSize ? "40px" : "56px",
          display: "flex !important",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#F6F6F6",
          position: "relative",
          cursor: "pointer",
          py: "10px",
          gap: isSmallSize ? "2px" : "6px",
          ...sxProp,
        }}
        onClick={onClick}
      >
        <TaskBigIcon
          sx={{ width: isSmallSize ? 14 : 18, height: isSmallSize ? 16 : 20 }}
        />

        <Typography
          sx={{
            fontSize: "8px",
            fontWeight: 400,
            color: "#5F6267",
            lineHeight: "10px",
          }}
        >
          Task
        </Typography>
      </Box>
    </ActionRestrictionWrapper>
  );
};

TaskItem.defaultProps = {
  size: "big",
  onClick: () => {},
  sxProp: {},
};

TaskItem.propTypes = {
  size: string,
  onClick: func,
  sxProp: object,
  repPermissions: object,
};
