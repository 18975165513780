import {
  archiveRepsService,
  createRepService,
  deleteUserService,
  getRepsService,
  getRepsTotalSummeriesService,
  resetRepsService,
  transferWhenDeleteRepService,
  updateUserService,
} from "../../services/reps";
import { SCROLL_LIMIT } from "../../utils/constants";
import { error, success } from "../../utils/notifications";
import { setCurrentUserAction } from "./auth";

export const UPDATE_USER = "UPDATE_USER";
export const CREATE_USER = "CREATE_USER";
export const GET_USERS = "GET_USERS";
export const GET_SUMMARIES = "GET_SUMMARIES";
export const EXIST_REPS_ACTIVE = "EXIST_REPS_ACTIVE";
export const EXIST_REPS_INACTIVE = "EXIST_REPS_INACTIVE";
export const GET_SALES_REPS = "GET_SALES_REPS";
export const RESET_SALES_REPS = "RESET_SALES_REPS";
export const GET_THIRD_PARTY_REPS = "GET_THIRD_PARTY_REPS";
export const GET_REP_PARAMS = "GET_REP_PARAMS";
export const START_LOADING_REPS = "START_LOADING_REPS";
export const END_LOADING_REPS = "END_LOADING_REPS";
export const DELETE_USER = "DELETE_USER";
export const GET_USERS_INACTIVE = "GET_USERS_INACTIVE";
export const SHOW_INACTIVE_REPS = "SHOW_INACTIVE_REPS";
export const GET_REPS_COUNT = "GET_REPS_COUNT";
export const GET_SALES_COUNT = "GET_SALES_COUNT";
export const GET_MERCHANDISERS_REPS = "GET_MERCHANDISERS_REPS";
export const GET_MERCHANDISERS_COUNT = "GET_MERCHANDISERS_COUNT";
export const GET_THIRD_REPS_COUNT = "GET_THIRD_REPS_COUNT";
export const GET_REPS_COUNT_INACTIVE = "GET_REPS_COUNT_INACTIVE";
export const SET_CURRENT_TAB = "SET_CURRENT_TAB";
export const START_LOADING_REPS_TOTAL_SUMMERIES =
  "START_LOADING_REPS_TOTAL_SUMMERIES";
export const END_LOADING_REPS_TOTAL_SUMMERIES =
  "END_LOADING_REPS_TOTAL_SUMMERIES";
export const REPS_GET_FILTER_FIELD = "REPS_GET_FILTER_FIELD";
export const REPS_GET_SWITCH = "REPS_GET_SWITCH";
export const ROUTES_GET_FILTER_FIELD = "ROUTES_GET_FILTER_FIELD";
export const ROUTES_GET_SWITCH = "ROUTES_GET_SWITCH";
export const RESET_ROUTES_ACTION_FILTER = "RESET_ROUTES_ACTION_FILTER";
export const RESET_ROUTES_ACTION_SWITCH = "RESET_ROUTES_ACTION_SWITCH";
export const ROUTES_QUICK_FILTER = "ROUTES_QUICK_FILTER";
export const RESET_ROUTES_QUICK_FILTER = "RESET_ROUTES_QUICK_FILTER";
export const RESET_REPS_ACTION_FILTER = "RESET_REPS_ACTION_FILTER";
export const RESET_REPS_ACTION_SWITCH = "RESET_REPS_ACTION_SWITCH";

// const updateUser = (payload) => ({
//   type: UPDATE_USER,
//   payload,
// });

export const repsGetParams = (payload) => ({
  type: GET_REP_PARAMS,
  payload,
});

// const createUser = (payload) => ({
//   type: CREATE_USER,
//   payload,
// });

const getUsers = (payload, isScrolling) => ({
  type: GET_USERS,
  isScrolling,
  payload,
});

const getSummaries = (payload, isScrolling) => ({
  type: GET_SUMMARIES,
  isScrolling,
  payload,
});

const getMerchandisers = (payload, isScrolling) => ({
  type: GET_MERCHANDISERS_REPS,
  isScrolling,
  payload,
});

const getSalesReps = (payload, isScrolling) => ({
  type: GET_SALES_REPS,
  isScrolling,
  payload,
});

const resetSalesReps = () => ({
  type: RESET_SALES_REPS,
});

const getThirdPartyReps = (payload, isScrolling) => ({
  type: GET_THIRD_PARTY_REPS,
  isScrolling,
  payload,
});

const getRepsCount = (payload) => ({
  type: GET_REPS_COUNT,
  payload,
});

const getSalesCount = (payload) => ({
  type: GET_SALES_COUNT,
  payload,
});

const getMerchandisersCount = (payload) => ({
  type: GET_MERCHANDISERS_COUNT,
  payload,
});

const getThirdPartyRepsCount = (payload) => ({
  type: GET_THIRD_REPS_COUNT,
  payload,
});

const getInactiveCount = (payload) => ({
  type: GET_REPS_COUNT_INACTIVE,
  payload,
});

export const setCurrentTab = (payload) => ({
  type: SET_CURRENT_TAB,
  payload,
});

const getInactive = (payload, isScrolling) => ({
  type: GET_USERS_INACTIVE,
  isScrolling,
  payload,
});

export const setShowInactiveReps = (payload) => ({
  type: SHOW_INACTIVE_REPS,
  payload,
});

export const repsExistActive = (payload) => ({
  type: EXIST_REPS_ACTIVE,
  payload,
});
export const repsExistInactive = (payload) => ({
  type: EXIST_REPS_INACTIVE,
  payload,
});

export const repsGetFilterField = (payload) => ({
  type: REPS_GET_FILTER_FIELD,
  payload,
});
export const repsGetSwitchField = (payload) => ({
  type: REPS_GET_SWITCH,
  payload,
});

export const resetRepsActionsFilter = (payload) => ({
  type: RESET_REPS_ACTION_FILTER,
  payload,
});

export const resetRepsActionsSwitch = (payload) => ({
  type: RESET_REPS_ACTION_SWITCH,
  payload,
});

export const catalogGetFilterField = (payload) => ({
  type: ROUTES_GET_FILTER_FIELD,
  payload,
});

export const routesGetSwitchField = (payload) => ({
  type: ROUTES_GET_SWITCH,
  payload,
});

export const resetRoutesActionsFilter = (payload) => ({
  type: RESET_ROUTES_ACTION_FILTER,
  payload,
});

export const resetRoutesActionsSwitch = (payload) => ({
  type: RESET_ROUTES_ACTION_SWITCH,
  payload,
});

export const getRoutesQuickFilter = (payload) => ({
  type: ROUTES_QUICK_FILTER,
  payload,
});

export const resetRoutesQuickFilter = (payload) => ({
  type: RESET_ROUTES_QUICK_FILTER,
  payload,
});
export const startLoadingReps = () => ({ type: START_LOADING_REPS });
export const endLoadingReps = () => ({ type: END_LOADING_REPS });
const startLoadingRepsTotalSummeries = () => ({
  type: START_LOADING_REPS_TOTAL_SUMMERIES,
});
const endLoadingRepsTotalSummeries = () => ({
  type: END_LOADING_REPS_TOTAL_SUMMERIES,
});

export const resetGetActionsFilter = () => {
  return (dispatch) => {
    dispatch(resetRoutesActionsFilter());
  };
};
export const resetGetActionsSwitchRoutes = () => {
  return (dispatch) => {
    dispatch(resetRoutesActionsSwitch());
  };
};

export const resetGlobalRoutesActions = () => {
  return (dispatch) => {
    dispatch(resetGetActionsSwitchRoutes());
    dispatch(resetRoutesActionsFilter());
    dispatch(resetRoutesQuickFilter());
  };
};
export const updateUserAction = ({ data, id, onSuccess, onError }) => {
  return (dispatch) => {
    dispatch(startLoadingReps());

    updateUserService(data, id)
      .then((res) => {
        dispatch(endLoadingReps());
        // dispatch(updateUser(res));
        dispatch(
          getRepsAction({ limit: SCROLL_LIMIT }, { isScrolling: false })
        );
        dispatch(getRepsSummariesAction());
        if (onSuccess) {
          onSuccess(res);
        } else {
          success("Representative updated");
        }
      })
      .catch((err) => {
        dispatch(endLoadingReps());
        if (onError) {
          onError(err);
        } else {
          error(err?.response?.data?.message || "Something went wrong");
        }
      });
  };
};

export const getRepsSummariesAction = () => {
  return (dispatch) => {
    dispatch(startLoadingRepsTotalSummeries());
    getRepsTotalSummeriesService()
      .then((res) => {
        dispatch(getSummaries(res));
      })
      .catch((err) => {
        error(err?.response?.data?.message);
      })
      .finally(() => dispatch(endLoadingRepsTotalSummeries()));
  };
};

export const createUserAction = (data, navigate, onSuccess, onError) => {
  return (dispatch) => {
    dispatch(startLoadingReps());

    createRepService(data)
      .then((res) => {
        if (navigate) navigate();
        dispatch(endLoadingReps());
        // dispatch(createUser(res));
        dispatch(
          getRepsAction({ limit: SCROLL_LIMIT }, { isScrolling: false })
        );
        success("Representative created");
        dispatch(getRepsSummariesAction());
        dispatch(setCurrentUserAction());
        if (onSuccess) onSuccess(res);
      })
      .catch((err) => {
        if (onError) onError(err);
        dispatch(endLoadingReps());
        error(
          err?.response?.data?.message || err?.message || "Something went wrong"
        );
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const archiveRepsAction = (ids, active) => {
  return (dispatch, getState) => {
    const {
      reps: { showInactive, loading },
    } = getState();
    if (loading) return;

    dispatch(startLoadingReps());
    archiveRepsService(ids, active)
      .then(() => {
        dispatch(getRepsAction({ limit: 15 }, { isScrolling: false }));
        success(`Representative(s) ${showInactive ? "restored" : "archived"}`);
      })
      .catch((err) => {
        dispatch(endLoadingReps());
        error(err?.message || "Something went wrong");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const resetRepsAction = (emails) => {
  return (dispatch, getState) => {
    const {
      reps: { loading },
    } = getState();
    if (loading) return;

    dispatch(startLoadingReps());
    resetRepsService(emails)
      .then((res) => {
        dispatch(endLoadingReps());
        success(res.message);
      })
      .catch((err) => {
        dispatch(endLoadingReps());
        error(err?.message || "Something went wrong");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const repsGetFiltersAction = (data) => {
  return (dispatch) => {
    dispatch(repsGetFilterField(data));
  };
};

export const repsGetSwitch = (data) => {
  return (dispatch) => {
    dispatch(repsGetSwitchField(data));
  };
};

export const resetRepGetActionsFilter = () => {
  return (dispatch) => {
    dispatch(resetRepsActionsFilter());
  };
};
export const resetRepsGetActionsSwitch = () => {
  return (dispatch) => {
    dispatch(resetRepsActionsSwitch());
  };
};

export const resetGlobalRepsActions = () => {
  return (dispatch) => {
    dispatch(resetRepGetActionsFilter());
    dispatch(resetRepsGetActionsSwitch());
  };
};

export const routesGetFiltersAction = (data) => {
  return (dispatch) => {
    dispatch(catalogGetFilterField(data));
  };
};
export const routesGetSwitchFieldActions = (data) => {
  return (dispatch) => {
    dispatch(routesGetSwitchField(data));
  };
};

export const deleteUserAction = (ids) => {
  return (dispatch, getState) => {
    const {
      reps: { loading },
    } = getState();
    if (loading) return;

    dispatch(startLoadingReps());
    deleteUserService(ids)
      .then(() => {
        dispatch(
          getRepsAction({ limit: SCROLL_LIMIT }, { isScrolling: false })
        );
        // dispatch(deleteUser(ids, active));
        dispatch(getRepsSummariesAction());
        dispatch(setCurrentUserAction());
        dispatch(endLoadingReps());
        success("Representative(s) deleted");
      })
      .catch((err) => {
        dispatch(endLoadingReps());
        error(err?.message || "Something went wrong");
        // eslint-disable-next-line no-console
        console.log(err?.message);
      });
  };
};

export const transferDeleteRepsAction = (data, active, onSuccess) => {
  return (dispatch) => {
    dispatch(startLoadingReps());
    transferWhenDeleteRepService(data)
      .then(() => {
        // dispatch(deleteUserAction(data.oldRepresentativeIds, active));
        success("Customers' transferred");
        dispatch(
          getRepsAction({ limit: SCROLL_LIMIT }, { isScrolling: false })
        );
        dispatch(getRepsSummariesAction());
        onSuccess();
      })
      .catch((err) => {
        dispatch(endLoadingReps());
        error(err?.data?.response?.message || "Something went wrong");
      });
  };
};

export const resetUsersAction = () => {
  return (dispatch) => {
    dispatch(getUsers([], false));
    dispatch(getSalesReps([], false));
    dispatch(getMerchandisers([], false));
    dispatch(getThirdPartyReps([], false));
    dispatch(getInactive([], false));
    dispatch(repsExistActive(null));
    dispatch(repsExistInactive(null));
  };
};

export const getRepsAction = (
  query,
  { isScrolling, shouldLoading = true },
  onSuccess,
  ref = null
) => {
  return (dispatch, getState) => {
    if (shouldLoading) dispatch(startLoadingReps());
    const state = getState();
    Promise.all([
      // get active and inactive reps
      getRepsService({ ...state.reps.params, ...query, active: true })
        .then((res) => {
          onSuccess && onSuccess(res.rows);

          dispatch(getRepsCount(res.count));
          dispatch(getSalesCount(res.countSales));
          dispatch(getMerchandisersCount(res.countMerchandiser));
          dispatch(getThirdPartyRepsCount(res.countThirdParty));

          //if (!state.reps.showInactive) {
          const role = query?.role || state.reps.params?.role;
          switch (role) {
            case "all":
              dispatch(getUsers(res.rows, isScrolling));
              break;
            case "sales":
              dispatch(getSalesReps(res.rows, isScrolling));
              break;
            case "merchandiser":
              dispatch(getMerchandisers(res.rows, isScrolling));
              break;
            case "third_party":
              dispatch(getThirdPartyReps(res.rows, isScrolling));
              break;
            default:
              dispatch(getUsers(res.rows, isScrolling));
              break;
          }
          //repsGetParams({ cursor: res.cursor });
          //}

          //if (!state.reps.showInactive)
          dispatch(repsExistActive(res.existData));
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err?.message);
        }),

      getRepsService({
        ...state.reps.params,
        ...query,
        cursor: state.reps.params.inactiveCursor,
        active: false,
      })
        .then((res) => {
          if (!isScrolling || state.reps.showInactive) {
            dispatch(getInactive(res.rows, isScrolling));
            dispatch(repsExistInactive(res.existData));
          }
          //repsGetParams({ inactiveCursor: res.cursor });

          dispatch(getInactiveCount(res?.count));
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err?.message);
        }),
    ])
      .catch((err) => {
        if (shouldLoading) dispatch(endLoadingReps());
        // eslint-disable-next-line no-console
        console.log(err?.message);
      })
      .finally(() => {
        if (ref === null || ref?.current === state.reps.showInactive)
          if (shouldLoading) dispatch(endLoadingReps());
      });
  };
};

export const resetSalesRepsAction = () => {
  return (dispatch) => {
    dispatch(resetSalesReps());
  };
};

export const repsGetParamsAction = ({ shouldLoading, ...rest }, ref) => {
  return (dispatch) => {
    if (shouldLoading) dispatch(resetUsersAction());
    dispatch(repsGetParams(rest));
    dispatch(
      getRepsAction(
        { limit: SCROLL_LIMIT },
        { isScrolling: false, shouldLoading },
        () => {},
        ref
      )
    );
  };
};

export const resetRepsFilterAction = () => {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(setShowInactiveReps(false));
    dispatch(
      repsGetParams({
        territoryId: "",
        activity: "",
        search: state.reps.params.search,
      })
    );
    dispatch(getRepsAction({ limit: SCROLL_LIMIT }, { isScrolling: false }));
  };
};

export const routesQuickFilterActions = (data) => {
  return (dispatch) => {
    dispatch(getRoutesQuickFilter(data));
  };
};

export const resetRoutesQuickFilterActions = () => {
  return (dispatch) => {
    dispatch(resetRoutesQuickFilter());
  };
};
