import { Box, Stack, Typography } from "@mui/material";
import { cl } from "./NotificationItem.styles";
import { bool, func, object, string } from "prop-types";
import {
  formatTime,
  getNotificationAvatar,
  getNotificationText,
  onDeleteActionCheck,
} from "components/NotificationCenter/NotificationCenter.helpers";
import { ActiveIcon } from "components/Icons";
import { useMemo, useState } from "react";
import {
  ANIMATION_CHECK_TIME,
  NOTE_AVAILABLE_TEXT,
} from "components/NotificationCenter/NotificationCenter.constants";

export const NotificationItem = ({
  item,
  isItemHovered,
  onItemHover,
  onNotificationNavigate,
  onReadNotification,
  isReadTab,
  timeZone,
  getDrawerActionFunc,
  currentUser,
  repPermissions,
}) => {
  const { id, deleted } = item || {};
  const [showDeleteItem, setShowDeleteItem] = useState(false);

  const drawerCallback = getDrawerActionFunc(item);

  const avatar = getNotificationAvatar(item);

  const isActionDeleted = useMemo(() => onDeleteActionCheck(item), [item]);

  const text = useMemo(
    () =>
      getNotificationText({
        item,
        navigate: onNotificationNavigate,
        drawerCallback: isActionDeleted ? null : drawerCallback,
        currentUser,
        repPermissions,
      }),
    [
      drawerCallback,
      isActionDeleted,
      item,
      onNotificationNavigate,
      currentUser,
      repPermissions,
    ]
  );

  const onTextClick = () => {
    if (isActionDeleted) {
      setShowDeleteItem(true);

      setTimeout(() => {
        setShowDeleteItem(false);
      }, ANIMATION_CHECK_TIME);
    }
  };

  return (
    <Stack
      id={`notification-${id}`}
      direction="row"
      alignItems="center"
      width="100%"
      p="9px 16px"
      sx={{
        ...cl.wrapper,
        ...(deleted && cl.deletedWrapper),
      }}
      onMouseEnter={() => onItemHover({ [id]: true })}
      onMouseLeave={() => onItemHover({ [id]: false })}
    >
      {avatar}

      <Stack justifyContent="center" sx={cl.textContainer}>
        <Box
          component="span"
          onClick={onTextClick}
          sx={{
            ...(showDeleteItem ? cl.deletedAnimatedText : cl.deletedText),
          }}
        >
          {text}
        </Box>

        <Typography
          variant="body2"
          sx={{
            mb: showDeleteItem ? "11px" : "-2px",
            opacity: showDeleteItem ? 1 : 0,
            ...cl.unavailableText,
          }}
        >
          {NOTE_AVAILABLE_TEXT}
        </Typography>
      </Stack>

      <Box
        sx={{
          ...cl.checkIcon,
          ...(deleted && cl.deletedCheckIcon),
        }}
      >
        <ActiveIcon width="15" height="11" />
      </Box>

      <Box
        sx={{
          opacity: deleted ? 0 : 1,
          ...cl.dateContainer,
        }}
      >
        {isItemHovered ? (
          <Typography
            variant="body3"
            color="#409A65"
            ml="auto"
            sx={{
              "&:hover": { textDecoration: "underline", cursor: "pointer" },
            }}
            onClick={() => onReadNotification(item)}
          >
            {isReadTab ? "Unread" : "Read"}
          </Typography>
        ) : (
          <Typography
            fontSize="10px"
            lineHeight="14px"
            color="#6A6A6A80"
            ml="auto"
            whiteSpace="pre-wrap"
          >
            {formatTime(item.createdAt, timeZone)}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};

NotificationItem.propTypes = {
  isItemHovered: bool,
  isReadTab: bool,
  onItemHover: func,
  getDrawerActionFunc: func,
  onNotificationNavigate: func,
  onReadNotification: func,
  item: object,
  currentUser: object,
  repPermissions: object,
  timeZone: string,
};
