import { useEffect, useState } from "react";
import { getRepsService } from "../../../services/reps";
import { error } from "../../../utils/notifications";
import { SCROLL_LIMIT } from "../../../utils/constants";

const initialState = {
  loading: false,
  list: [],
  count: 0,
  params: {
    active: true,
    limit: SCROLL_LIMIT,
    roles: JSON.stringify(["merchandiser", "sales"]),
    cursor: null,
    search: "",
  },
};

export const useRepsListAction = () => {
  const [repsState, setRepsState] = useState(initialState);

  const handleFetchReps = async (signal) => {
    try {
      setRepsState((prev) => ({ ...prev, loading: true }));
      const { rows, countMerchandiser, countSales, cursor } =
        await getRepsService(repsState.params, signal);
      setRepsState((prev) => ({
        ...prev,
        list: [...prev.list, ...rows],
        count: (countMerchandiser ?? 0) + (countSales ?? 0),
        params: {
          ...prev.params,
          cursor: cursor ? cursor : null,
        },
      }));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setRepsState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleSearchReps = async (search) => {
    setRepsState((prev) => ({
      ...prev,
      params: { ...prev.params, search, cursor: null },
      list: [],
    }));
  };

  useEffect(() => {
    const controller = new AbortController();

    handleFetchReps(controller.signal);

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repsState.params.search]);

  return {
    repsList: repsState.list,
    repsCount: repsState.count,
    repsLoading: repsState.loading,
    handleFetchReps,
    handleSearchReps,
  };
};
