import { number, func, string, bool, object } from "prop-types";
import pluralize from "pluralize";
import { BadgeComponent, StyledTooltip } from "components";
import { Box } from "@mui/material";

const badgeWrapperStyle = {
  boxShadow: "0 0 0 rgba(64, 154, 101, 0.9)",
  animation: "shadowPulse 2s infinite",
  cursor: "pointer",
  "&:hover": {
    cursor: "pointer",
    animation: "none",
  },

  position: "absolute",
  left: 0,
  top: "50%",
  transform: "translate(-50%, -50%)",
};

export const DiscountCount = ({
  availableMSDCount,
  handleApplyDiscount,
  deliveryStatus,
  showOnly = false,
  badgeProps = {},
}) => {
  return (
    <StyledTooltip
      isDark
      title={`${availableMSDCount} manufacturer specific ${pluralize(
        "discounts",
        availableMSDCount
      )} available for selected customer`}
      arrow
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset: [0, -15] } }],
      }}
      placement="top"
      disableHoverListener={showOnly}
    >
      <Box
        sx={{
          position: "relative",
          left: 5,
        }}
        onClick={() => handleApplyDiscount("manufacturer")}
        disabled={
          !availableMSDCount ||
          (!!deliveryStatus && deliveryStatus !== "UNFULFILLED")
        }
      >
        <BadgeComponent
          count={availableMSDCount}
          qualitySize={50}
          wrapperStyle={badgeWrapperStyle}
          {...badgeProps}
        />
      </Box>
    </StyledTooltip>
  );
};

DiscountCount.propTypes = {
  availableMSDCount: number,
  handleApplyDiscount: func,
  deliveryStatus: string,
  badgeProps: object,
  showOnly: bool,
};
DiscountCount.defaultProps = {
  availableMSDCount: false,
  handleApplyDiscount: () => {},
  deliveryStatus: "",
};
