import { useContext } from "react";
import { object } from "prop-types";

import { CategoriesFilter, CategoriesList } from "./components";
import { AddCategoryDrawer } from "./components/AddCategoryDrawer";
import { CategoriesContext } from "..";

import Box from "@mui/material/Box";

export const CategoriesTab = ({ repPermissions = {} }) => {
  const {
    openAddDrawer: openState,
    onEditDrawerClose: onClose,
    onSaveCallback,
  } = useContext(CategoriesContext);

  return (
    <Box p="20px 32px" height="100%">
      <CategoriesFilter />
      <CategoriesList repPermissions={repPermissions} />
      <AddCategoryDrawer
        {...{
          openState,
          onClose,
          onSaveCallback,
        }}
      />
    </Box>
  );
};

CategoriesTab.propTypes = {
  repPermissions: object,
};
export default CategoriesTab;
