// import { UNCATEGORIZED_CATEGORY } from "../../../utils/constants";

import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

export const defaultProduct = {
  skuVariationFields: [{ sku: "" }],
  colorVariationFields: [{ color: "" }],
  sizeVariationFields: [{ size: "" }],
  isMultiple: false,
  variations: "No",
  name: "",
  sku: "",
  barcode: "",
  manufacturerIdInactive: {},
  manufacturer: null,
  category: "",
  description: "",
  status: "ACTIVE",
  wholesalePrice: "",
  distributorPrice: Number(0).toPrecision(3),
  minOrderQTY: "",
  whenOutOfStock: true,
  itemsPerCase: "",
  retailPrice: "",
  tags: [],
  mainPhotoUrl: null,
  photos: [],
  onHand: 0,
  expected: 1,
  allocated: 0,
  chooseVariationsType: {
    sku: true,
    color: false,
    size: false,
  },
  variationsList: [],
  variationsFields: [],
  type: PRODUCT_TYPE_INVENTORY.inventory,
  countryOfOrigin: "",
};
