import { string, number, bool } from "prop-types";
import { Box, Typography } from "@mui/material";

export const CardHeader = ({ name, price, term, isExtra }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          borderBottom: "0.5px solid #D5D9D9",
          width: "236px",
          height: "104px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography
            fontSize={24}
            fontWeight={400}
            color="#5F6267"
            letterSpacing={-0.5}
          >
            {isExtra ? "Extra User" : name}
          </Typography>
        </Box>

        <Box>
          <Typography fontSize={28} fontWeight={600} color="#5F6267">
            ${price}
            {isExtra ? (
              <>
                <Box component="span" fontSize={16} fontWeight={600}>
                  {" "}
                  monthly
                </Box>
                <Box component="span" fontSize={16} fontWeight={400}>
                  /user
                </Box>
              </>
            ) : (
              <Box component="span" fontSize={16} fontWeight={400}>
                /{term.slice(0, 3)?.toLowerCase()}
              </Box>
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

CardHeader.propTypes = {
  name: string.isRequired,
  price: number.isRequired,
  term: string.isRequired,
  isExtra: bool,
};
CardHeader.defaultProps = { name: "", price: 0, term: "" };
