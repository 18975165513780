import { bool, func, string } from "prop-types";

import { TABS } from "../NotificationCenter.constants";

import { cl } from "../NotificationCenter.styles";

import { Box, Tab, Tabs, Typography } from "@mui/material";

export const TabsBlock = ({ tab, setTab, onMarkAllAsRead, showMarkAll }) => {
  return (
    <Box sx={cl.tabsWrapper}>
      <Tabs
        sx={cl.tabs}
        value={tab}
        onChange={(e, newValue) => setTab(newValue)}
      >
        {TABS.map((tab) => (
          <Tab
            key={tab}
            sx={cl.tab}
            label={
              <Box display="flex" alignItems="center">
                <Box sx={cl.tabLabel}>{tab}</Box>
              </Box>
            }
            value={tab}
          />
        ))}
      </Tabs>

      {showMarkAll && (
        <Typography
          variant="body3"
          color="#47A06D"
          ml="auto"
          onClick={onMarkAllAsRead}
          sx={{ cursor: "pointer" }}
        >
          Mark all as read
        </Typography>
      )}
    </Box>
  );
};

TabsBlock.propTypes = {
  tab: string,
  setTab: func,
  onMarkAllAsRead: func,
  showMarkAll: bool,
};
