import { useState, useRef, useMemo } from "react";
import { bool, func, object, string, oneOfType, oneOf } from "prop-types";
import { makeStyles } from "@mui/styles";
import { IconButton, ListItemText, MenuItem, Typography } from "@mui/material";
import StyledMenu from "../StyledMenu/StyledMenu";
import { ExpandMore } from "@mui/icons-material";
import { Box } from "@mui/system";
import StyledButton from "../StyledButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftIcon, PrinterIcon, SendIcon } from "../Icons";
import {
  bulkPackingSlipOrderReportService,
  getOrderReportPDF as getOrderReportPDFService,
  printPackingSlipService,
} from "../../services/orders";
import { openFilePreview } from "../../Pages/OrdersPage/Orders.helper";
import Loader from "../Loader";
import { error } from "../../utils/notifications";
import { useOrders } from "Pages/OrdersPage/Orders.hook";
import { useAdmin } from "helpers/helpers";
import {
  ActionRestrictionWrapper,
  PrintPickOrdersDialog,
  StyledTooltip,
} from "components";
import { CART_TYPES } from "Pages/CartPage/CartPage.constants";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
  DRAFT_ORDER_TYPES,
  FETCH_LIMITS,
} from "utils/constants";
import OrderEmailDialog from "Pages/CartPage/components/OrderEmailDialog/OrderEmailDialog";
import {
  getActivitiesAction,
  getDraftActivitiesAction,
} from "redux/actions/orders";
import { useDispatch } from "react-redux";
import { updateUserFieldAction } from "redux/actions/auth";

const useStyles = makeStyles(() => ({
  smallText: {
    fontSize: "12px",
    "& .MuiFormLabel-root label": {
      fontWeight: 600,
      color: "#6A6A6A",
    },
  },
  label: {
    height: "28px",
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
    },
    "& .Mui-focused": {
      color: "#42A57F",
      fontSize: "1rem",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  smallerButton: {
    width: "22px",
    minWidth: "49px",
    height: "24px",
    "& > span": {
      fontSize: "12px",
      position: "relative",
      right: "5px",
    },
  },
  biggerButton: {
    "& > span": {
      fontSize: "14px",
    },
  },
  actionsButton: {
    background: "#F0F0F0",
    border: "1px solid #D4D4D4",
    width: "84px",
    padding: "5px 10px 5px 15px",
    minWidth: "84px",
    height: "24px",
    "& > span": {
      color: "#6A6A6A",
      fontSize: "12px",
      marginRight: "3px",
    },
    "& > .MuiButton-endIcon": {
      borderLeft: "1px solid #D4D4D4",
    },
  },
}));

const OrdersTabComponent = ({
  order,
  title,
  isReportBtn,
  addBtnName,
  addGroupBtn,
  saveButtons,
  // isEdit,
  // isChanged,
  // handleDelete,
  // form,
  // onSubmit,
  navigatePath,
  onClickRefundBtn,
  onClickCancelBtn,
  onClickDuplicate,
  orderId,
  handleOpenEmail,
  repPermissions,
  cartType,
  menuItems,
  emailDialogData,
  handleSendEmail,
  currentUser,
}) => {
  const classes = useStyles();
  const isAdmin = useAdmin();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [printInvoiceListDialog, setPrintInvoiceListDialog] = useState(false);

  const { showIncludedPrices } = currentUser || {};

  const isQuickBooksConnected = !!currentUser?.quickBooksTokens;

  const { openEmailDialog, closeEmailDialog, ...emailDialog } = emailDialogData;

  const hasThirdParty = useMemo(() => {
    if (pathname.split("/")?.[2] === "third_party_temp") return true;
    return false;
  }, [pathname]);

  const isThirdPartyOrder = useMemo(
    () => order?.type === "THIRD_PARTY",
    [order?.type]
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [printOpen, setPrintOpen] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const printRef = useRef();
  const { totalPayments, orderStatus } = order;
  const isCanceled = orderStatus === "CANCELED";

  const openHeaderList = Boolean(anchorEl);

  const hasCustomerEmail = useMemo(() => {
    return (
      !!order?.customer?.email ||
      order?.customer?.contacts?.some((contact) => contact?.email)
    );
  }, [order?.customer?.contacts, order?.customer?.email]);

  const handleClickOnDotsBtn = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCloseDotsBtn = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClickOnActionBtn = (e, label) => {
    const trimmedLabel = label.trim() || "";
    switch (trimmedLabel) {
      case "Refund":
      case "Return Items":
        onClickRefundBtn(true, trimmedLabel);
        break;

      case "Duplicate":
        onClickDuplicate();
        break;
      case "Cancel":
        onClickCancelBtn();
        break;

      default:
        break;
    }
    handleCloseDotsBtn(e);
  };

  const isOrderDraft = [
    DRAFT_ORDER_TYPES.open,
    DRAFT_ORDER_TYPES.closed,
  ].includes(order?.status);

  const getActivities = () => {
    if (orderId) {
      isOrderDraft
        ? dispatch(
            getDraftActivitiesAction(orderId, {
              limit: FETCH_LIMITS.ACTIVITIES,
              sort_by_date: "desc",
            })
          )
        : dispatch(
            getActivitiesAction(orderId, {
              limit: FETCH_LIMITS.ACTIVITIES,
              sort_by_date: "desc",
            })
          );
    }
  };

  const getOrderReportPDF = ({ orderId, onSuccess, onError, params = {} }) => {
    getOrderReportPDFService(orderId, params)
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((err) => {
        if (onError) onError(err);
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
        error(err?.response?.data?.message || "Something went wrong");
      });
  };

  const onPrintPickOrders = (includePrices) => {
    if (includePrices !== showIncludedPrices)
      dispatch(updateUserFieldAction("showIncludedPrices", includePrices));

    setPrintLoading(true);
    setPrintOpen(false);

    getOrderReportPDF({
      orderId,
      params: { include_prices: includePrices },
      onSuccess: (res) => {
        setPrintLoading(false);
        openFilePreview(res);
        getActivities();
      },
      onError: () => setPrintLoading(false),
    });
    setPrintInvoiceListDialog(false);
  };

  const handlePrintOrder = () => {
    setPrintLoading(true);
    setPrintOpen(false);

    getOrderReportPDF({
      orderId,
      onSuccess: (res) => {
        setPrintLoading(false);
        openFilePreview(res);
        getActivities();
      },
      onError: () => setPrintLoading(false),
    });
  };

  const getPrintPackingSlip = ({ orderId, type, onSuccess, onError }) => {
    printPackingSlipService(orderId, type)
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((err) => {
        if (onError) onError(err);
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
        error(err?.response?.data?.message || "Something went wrong");
      });
  };

  const handlePrintPackingSlip = () => {
    setPrintLoading(true);
    setPrintOpen(false);

    getPrintPackingSlip({
      orderId,
      type: "order",
      onSuccess: (res) => {
        setPrintLoading(false);
        openFilePreview(res);
        getActivities();
      },
      onError: () => setPrintLoading(false),
    });
  };

  const getBulkPackingSlipOrderReport = ({ data, onSuccess, onError }) => {
    bulkPackingSlipOrderReportService({
      data,
      // onDownloadProgress: (progressEvent) => {
      //   const progress = Math.round(
      //     (progressEvent.loaded * 100) / progressEvent.total
      //   );
      //   setPrintLoading((prev) => ({ ...prev, progress, downloading: true }));
      // },
    })
      .then((res) => {
        if (onSuccess) onSuccess(res);
      })
      .catch((err) => {
        if (onError) onError(err);
        // eslint-disable-next-line no-console
        console.log(err?.response?.data?.message);
        error(err?.response?.data?.message || "Something went wrong");
      });
  };

  const fetchBulkPackingSlipOrderReport = (data) => {
    setPrintOpen(false);
    setPrintLoading(true);

    getBulkPackingSlipOrderReport({
      data,
      onSuccess: (res) => {
        setTimeout(() => {
          setPrintLoading(false);
          openFilePreview(res.data);
          getActivities();
        }, 1000);
      },
      onError: () => setPrintLoading(false),
    });
  };

  const fetchPrintPickList = () => {
    if (!order.id) return;

    fetchBulkPackingSlipOrderReport({
      ordersIds: [order.id],
      groupOrders: false,
    });
  };

  const handlePrintAll = () => {
    setPrintLoading(true);
    setPrintOpen(false);

    Promise.all([
      getOrderReportPDF({
        orderId,
        onSuccess: (res) => openFilePreview(res),
      }),
      getPrintPackingSlip({
        orderId,
        type: "order",
        onSuccess: (res) => openFilePreview(res),
      }),
      getBulkPackingSlipOrderReport({
        data: { ordersIds: [orderId], groupOrders: false },
        onSuccess: (res) => {
          setTimeout(() => {
            openFilePreview(res.data);
          }, 1000);
        },
      }),
    ])
      .catch((err) => {
        error(err?.response?.data?.message || "Something went wrong");
      })
      .finally(() => {
        getActivities();
        setPrintLoading(false);
      });
  };

  const { handleBulkArchiveOrders } = useOrders({
    setIsLoading: () => {},
    // handleFetchOrdersThirdParty,
    fetchOrder: true,
  });

  const disabledActionsBtn = useMemo(() => {
    return repPermissions ? !repPermissions?.orders?.create_edit : isAdmin;
  }, [isAdmin, repPermissions]);

  const disabledEditBtn = useMemo(() => {
    return (
      isAdmin ||
      isCanceled ||
      order?.archived ||
      order?.status === DRAFT_ORDER_TYPES.closed
    );
  }, [isAdmin, isCanceled, order?.archived, order?.status]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
    >
      <PrintPickOrdersDialog
        open={printInvoiceListDialog}
        defaultChecked={showIncludedPrices}
        title="Print order(s)"
        checkboxText="Include Pricing"
        handleClose={() => setPrintInvoiceListDialog(false)}
        handlePrint={onPrintPickOrders}
      />
      <Loader isLoading={printLoading} />
      <OrderEmailDialog
        isOpen={emailDialog?.open}
        handleClose={closeEmailDialog}
        order={emailDialog?.order || {}}
        handleSendEmail={handleSendEmail}
        grandTotal={emailDialog?.order?.totalAmount || 0}
        calculatedTotal={emailDialog?.order?.totalRawAmount || 0}
        totalDiscount={
          emailDialog?.order?.totalRawAmount -
            emailDialog?.order?.totalAmountWithoutDelivery || 0
        }
        deliveryFee={emailDialog?.order?.deliveryFee || 0}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box>
          {title && (
            <Typography
              variant="h6"
              fontWeight="400"
              fontSize="20px"
              color="#9C9C94"
            >
              {title}
            </Typography>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      >
        {isReportBtn && (
          <StyledButton
            sx={{
              height: 28,
            }}
            color="groundLighter"
            variant="outlined"
            label="Report"
          />
        )}
        {addGroupBtn && (
          <StyledButton
            sx={{
              height: 28,
            }}
            color="primary"
            variant="outlined"
            label="Add group"
            component={Link}
            to={`${pathname}/new/group`}
          />
        )}
        {addBtnName && (
          <StyledButton
            sx={{
              height: 28,
              boxShadow: "none",
            }}
            variant="contained"
            label={addBtnName}
            component={Link}
            to={`${pathname}/new`}
            from={pathname}
          />
        )}
        {saveButtons && (
          <>
            <StyledButton
              color="greySecondary"
              label="Back"
              sx={{ p: 0, position: "relative", left: "10px" }}
              className={classes.label}
              component={Link}
              to={{
                pathname: navigatePath || "/orders",
              }}
              state={state}
              from={pathname}
              startIcon={<ArrowLeftIcon />}
            />
            {!hasThirdParty && (
              <ActionRestrictionWrapper
                disableHoverListener={!disabledActionsBtn}
                title={
                  repPermissions?.role === "SALES"
                    ? ADMIN_ONLY_VIEW_MESSAGE
                    : ADMIN_ONLY_VIEW_MESSAGE_PAGE
                }
              >
                <StyledButton
                  disabled={disabledActionsBtn}
                  variant="outlined"
                  label="Actions"
                  className={classes.actionsButton}
                  onClick={handleClickOnDotsBtn}
                  endIcon={<ExpandMore />}
                />
              </ActionRestrictionWrapper>
            )}
            <StyledMenu
              sx={{ paddingY: 0 }}
              anchorEl={anchorEl}
              isOpen={openHeaderList}
              handleClose={handleCloseDotsBtn}
            >
              {cartType === CART_TYPES.order ? (
                <>
                  {totalPayments !== 0 && !isCanceled && (
                    <MenuItem
                      onClick={(e) =>
                        handleClickOnActionBtn(e, e.target.outerText)
                      }
                    >
                      Refund
                    </MenuItem>
                  )}
                  {!isCanceled &&
                    !["UNFULFILLED"].includes(order.deliveryStatus) && (
                      <MenuItem
                        onClick={(e) =>
                          handleClickOnActionBtn(e, e.target.outerText)
                        }
                      >
                        Return Items
                      </MenuItem>
                    )}
                  <MenuItem
                    onClick={(e) =>
                      handleClickOnActionBtn(e, e.target.outerText)
                    }
                  >
                    Duplicate
                  </MenuItem>

                  {isQuickBooksConnected ? null : (
                    <MenuItem
                      onClick={(e) => {
                        handleCloseDotsBtn(e);
                        handleBulkArchiveOrders(order);
                      }}
                    >
                      {order?.archived ? "Unarchive order" : "Archive order"}
                    </MenuItem>
                  )}

                  {!isCanceled && (
                    <MenuItem
                      onClick={(e) =>
                        handleClickOnActionBtn(e, e.target.outerText)
                      }
                    >
                      <Typography sx={{ color: "#FF6254", fontSize: "12px" }}>
                        Cancel
                      </Typography>
                    </MenuItem>
                  )}
                </>
              ) : (
                <>
                  {menuItems(order, [
                    "Create & Email",
                    "Create & New",
                    "Create & Copy link",
                    "Duplicate draft",
                    "Delete draft",
                  ]).map((item) => {
                    return (
                      <Box
                        key={item?.label}
                        display="flex"
                        flexDirection="column"
                        component="span"
                      >
                        <MenuItem onClick={handleCloseDotsBtn}>
                          <ListItemText
                            classes={{
                              primary: item?.redText ? "deleteItem" : "",
                            }}
                            sx={{
                              "& > .MuiTypography-root": { color: "#FF6254" },
                            }}
                            onClick={() => item?.onClick(order)}
                          >
                            {item?.label}
                          </ListItemText>
                        </MenuItem>
                      </Box>
                    );
                  })}
                </>
              )}
            </StyledMenu>

            <ActionRestrictionWrapper
              disableHoverListener={
                !(!!repPermissions && !repPermissions?.orders?.create_edit)
              }
              title={
                repPermissions?.role === "SALES"
                  ? ADMIN_ONLY_VIEW_MESSAGE
                  : ADMIN_ONLY_VIEW_MESSAGE_PAGE
              }
            >
              <StyledButton
                variant="outlined"
                disabled={
                  disabledEditBtn ||
                  (!!repPermissions && !repPermissions?.orders?.create_edit)
                }
                label="Edit"
                className={classes.smallerButton}
                sx={{ paddingLeft: "22px" }}
                color="primary"
                onClick={() => {
                  const editAction = menuItems(order, ["Update draft"])?.[0]
                    ?.onClick;

                  cartType === CART_TYPES.order
                    ? navigate(`/cart/${orderId}`)
                    : !!editAction && editAction();
                }}
              />
            </ActionRestrictionWrapper>

            {cartType === CART_TYPES.order && (
              <StyledTooltip title="Print" arrow placement="left">
                <Box>
                  <IconButton
                    disabled={isAdmin}
                    onClick={() => setPrintOpen(true)}
                    ref={printRef}
                    sx={{
                      backgroundColor: "#F8F8F8",
                      borderRadius: "4px",
                      border: "0.5px solid #D4D4D4",
                      height: "24px",
                      width: "29px",
                    }}
                  >
                    <PrinterIcon />
                  </IconButton>
                </Box>
              </StyledTooltip>
            )}

            {cartType === CART_TYPES.order && (
              <StyledTooltip
                title={
                  !!repPermissions && !repPermissions?.orders?.create_edit
                    ? repPermissions?.role === "SALES"
                      ? ADMIN_ONLY_VIEW_MESSAGE
                      : ADMIN_ONLY_VIEW_MESSAGE_PAGE
                    : hasCustomerEmail
                    ? "Email order"
                    : "No email address found for this customer"
                }
                // disableHoverListener={hasCustomerEmail}
                arrow
                placement="left"
              >
                <Box>
                  <IconButton
                    onClick={handleOpenEmail}
                    sx={{
                      backgroundColor: "#F8F8F8",
                      borderRadius: "4px",
                      border: "0.5px solid #D4D4D4",
                      height: "24px",
                      width: "29px",
                    }}
                    disabled={
                      isAdmin ||
                      !hasCustomerEmail ||
                      (!!repPermissions && !repPermissions?.orders?.create_edit)
                    }
                  >
                    <SendIcon
                      fill={!hasCustomerEmail ? "#B9B9B9" : "#5f6267"}
                    />
                  </IconButton>
                </Box>
              </StyledTooltip>
            )}

            <StyledMenu
              anchorEl={printRef.current}
              isOpen={printOpen}
              handleClose={() => setPrintOpen(false)}
              sx={{ p: 0 }}
            >
              <MenuItem
                onClick={() => {
                  if (isThirdPartyOrder) {
                    setPrintInvoiceListDialog(true);
                  } else {
                    handlePrintOrder();
                  }
                }}
              >
                <ListItemText>Print order</ListItemText>
              </MenuItem>
              <MenuItem onClick={handlePrintPackingSlip}>
                <ListItemText>Print packing slip</ListItemText>
              </MenuItem>
              <MenuItem onClick={fetchPrintPickList}>
                <ListItemText>Print picklist</ListItemText>
              </MenuItem>
              <MenuItem onClick={handlePrintAll}>
                <ListItemText>Print all</ListItemText>
              </MenuItem>
            </StyledMenu>
          </>
        )}
      </Box>
    </Box>
  );
};

OrdersTabComponent.propTypes = {
  order: object,
  title: oneOfType([string, object]),
  isReportBtn: bool,
  addBtnName: string,
  addGroupBtn: bool,
  handleOpenConfirmDialog: func,
  onSubmit: func,
  saveButtons: bool,
  currentTab: string,
  isEdit: bool,
  isChanged: bool,
  handleDelete: func,
  form: string,
  navigateState: string,
  navigatePath: string,
  onClickRefundBtn: func,
  onClickCancelBtn: func,
  orderId: string,
  handleOpenEmail: func,
  onClickDuplicate: func,
  repPermissions: object,
  cartType: oneOf([CART_TYPES.order, CART_TYPES.draft]),
  menuItems: func,
  emailDialogData: object,
  handleSendEmail: func,
  currentUser: object,
};
OrdersTabComponent.defaultProps = {
  title: "",
  isReportBtn: false,
  addBtnName: "",
  addGroupBtn: false,
  saveButtons: false,
  isEdit: false,
  isChanged: false,
  form: "",
  navigatePath: "",
  orderId: "",
  currentUser: {},
};

export default OrdersTabComponent;
