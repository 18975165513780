import { useCallback, useEffect, useMemo } from "react";
import { bool, func, string } from "prop-types";
import { useDispatch } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CrossIcon } from "../Icons";
import { StyledTextField } from "../TextFields/TextFields";
import StyledButton from "../StyledButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./NewTerritoryDialog.validations";
import { createSingleTerritoreService } from "../../services/territory";
import { error } from "../../utils/notifications";
import {
  getTerritoryListAction,
  getTerritoryTreeAction,
} from "../../redux/actions/territory";

export const NewTerritoryDialog = ({
  open,
  onClose,
  setValue,
  onSave,
  name = "",
  handleSave,
}) => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: { name: "" },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  useEffect(() => {
    reset({ name });
  }, [open, reset, name]);

  const isDisabled = useMemo(() => {
    return !formField?.name || errors?.name;
  }, [errors?.name, formField?.name]);

  const onSubmit = useCallback(
    async ({ name }) => {
      try {
        const res = await createSingleTerritoreService(name);
        dispatch(getTerritoryListAction());
        dispatch(getTerritoryTreeAction());
        if (setValue) setValue("territoryId", res?.id);
        if (onSave) onSave(res);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      }
      onClose();
    },
    [dispatch, onClose, onSave, setValue]
  );

  return (
    <Dialog
      sx={{
        ".MuiPaper-root": { boxShadow: "none" },
      }}
      open={open}
    >
      <Box borderBottom="0.5px solid #CCCCCC" p="11px 0 7px 24px">
        <Grid sx={{ position: "absolute", top: 8, right: 9 }} item>
          <IconButton onClick={() => onClose()}>
            <CrossIcon />
          </IconButton>
        </Grid>
        <Typography fontWeight="400" fontSize="20px" color="#5F6267">
          New territory
        </Typography>
      </Box>

      <DialogContent sx={{ p: "8px 21px 8px 24px", minWidth: "340px" }}>
        <Grid display="flex" sx={{ flexDirection: "column" }} container>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledTextField
                adminIsAllowed
                fullWidth
                formSx={{ mt: 1, mb: "6px" }}
                size="small"
                InputProps={{
                  styles: {
                    fontSize: "14px",
                    height: "37px",
                  },
                }}
                InputLabelProps={{
                  styles: {
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "#ACAEAE",
                  },
                }}
                error={error ? error.message : ""}
                label="Add new territory"
                {...field}
              />
            )}
            name="name"
            control={control}
          />

          <DialogActions>
            <StyledButton
              sx={{ height: 27, color: "#6A6A6A" }}
              label={
                <Typography fontSize="10px" fontWeight="normal">
                  Cancel
                </Typography>
              }
              variant="text"
              onClick={() => onClose()}
            />
            <StyledButton
              disabled={isDisabled}
              sx={{ height: 27 }}
              label={
                <Typography fontSize="10px" fontWeight="normal">
                  Done
                </Typography>
              }
              variant="contained"
              onClick={
                handleSave
                  ? () => handleSave(formField.name)
                  : handleSubmit(onSubmit)
              }
            />
          </DialogActions>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

NewTerritoryDialog.propTypes = {
  open: bool,
  onClose: func,
  setValue: func,
  onSave: func,
  name: string,
  handleSave: func,
};
NewTerritoryDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSave: () => {},
  name: "",
};
