import { useMemo } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { string, func, bool, array, object, number } from "prop-types";
import { ArrowLeftIcon } from "../../../../components/Icons";
import StyledButton from "../../../../components/StyledButton";

const ApplyDiscountPageTab = ({
  type,
  customerId,
  customer,
  handleClearAll,
  isDisableBtn,
  productsList,
  orderId,
  isDuplicate,
  discount,
  deliveryFee,
  manualDeliveryFee,
  createOrderType,
  manufacturerDiscounts,
  isDraftOrder,
  isPriceList,
}) => {
  const navigate = useNavigate();

  const title = useMemo(() => {
    switch (type) {
      case "manufacturer":
        return "Apply manufacturer specific discount";
      case "item":
        return "Apply items to discount";
      default:
        return "";
    }
  }, [type]);

  return (
    <Box
      sx={{
        // backgroundColor: ''
        p: "6px 32px 11px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          gap: "4px",
          "& .MuiTypography-root": {
            fontSize: "20px",
            fontWeight: 400,
          },
        }}
      >
        <Typography
          color="#9C9C94"
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/cart")}
        >
          Order /
        </Typography>
        <Typography color="#5F6267">{title}</Typography>
      </Box>
      <Box>
        <StyledButton
          color="greySecondary"
          label="Back"
          fontSize="14px"
          onClick={() => {
            if (!orderId) return navigate("/cart");

            const navigateString = isDuplicate
              ? `/cart/duplicate/${orderId}`
              : isDraftOrder
              ? `/cart/draft/${orderId}`
              : `/cart/${orderId}`;

            const priceListProducts = productsList.map((product) => {
              const { oldWholesalePrice, price, ...rest } = product || {};
              return {
                ...rest,
                wholesalePrice: oldWholesalePrice,
                price: oldWholesalePrice || price,
                allowCalcPrice: false,
              };
            });

            const contactId = customer?.contacts?.find(
              (contact) => contact.defaultContact
            )?.id;

            navigate(navigateString, {
              state: {
                editedOrderData: {
                  customer: customer,
                  customerId: customerId,
                  contactId,
                  products: isPriceList ? priceListProducts : productsList,
                  discount,
                  deliveryFee,
                  manualDeliveryFee,
                  createOrderType,
                  manufacturerDiscounts,
                  backBtn: true,
                  freeCasesProductsIds: [],
                },
              },
            });
          }}
          startIcon={<ArrowLeftIcon />}
        />
        <StyledButton
          disabled={isDisableBtn}
          color="error"
          label="Clear all"
          variant="outlined"
          sx={{ height: "24px" }}
          onClick={handleClearAll}
        />
        <StyledButton
          disabled={isDisableBtn}
          label="Apply selected"
          variant="contained"
          type="submit"
          form="apply-discounts-form"
          sx={{ height: "24px", ml: "9.5px" }}
        />
      </Box>
    </Box>
  );
};

ApplyDiscountPageTab.propTypes = {
  type: string,
  customerId: string,
  customer: object,
  handleClearAll: func,
  isDisableBtn: bool,
  productsList: array,
  orderId: string,
  isDuplicate: bool,
  discount: object,
  deliveryFee: number,
  manualDeliveryFee: bool,
  createOrderType: string,
  manufacturerDiscounts: array,
  isDraftOrder: bool,
  isPriceList: bool,
};
ApplyDiscountPageTab.defaultProps = {
  isDisableBtn: true,
  deliveryFee: 0,
  manualDeliveryFee: false,
  manufacturerDiscounts: [],
  isDraftOrder: false,
  isPriceList: false,
};

export default ApplyDiscountPageTab;
