import { func, object } from "prop-types";
import { Avatar, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";

import {
  stringAvatar,
  photoUrl,
  largestTerritory,
} from "../../../../../../../../helpers/helpers";
import { EyeIcon } from "../../../../../../../../components/Icons";

const ListItem = ({
  representative,
  handleOpenProfileDialog,
  setTerritoriesList,
  setAnchorEl,
}) => {
  const ROLES = [
    { SALES: "Sales" },
    // { DISTRIBUTOR: "Distributor representative" },
    { MERCHANDISER: "Merchandiser" },
    { THIRD_PARTY: "Third Party" },
  ];

  const styleText = {
    fontSize: "12px",
    fontWeight: 400,
  };

  return (
    <Grid
      sx={{
        border: "1px solid #D5D9D9",
        height: "48px",
        marginTop: "-1px",
        cursor: "pointer",
        borderLeft: "none",
        borderBottom: "none",
      }}
      container
      onClick={() => handleOpenProfileDialog(representative)}
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: "24px",
        }}
        xs={5}
        item
      >
        <Box display="flex" width="100%">
          <Box display="flex" justifyContent="flex-center">
            {representative.profilePhoto?.fileName ? (
              <Avatar sx={{ width: "36px", height: "36px" }}>
                <Box
                  component="img"
                  sx={{ objectFit: "cover", width: "100%", height: "100%" }}
                  src={photoUrl(representative.profilePhoto?.fileName)}
                />
              </Avatar>
            ) : (
              <Avatar
                {...stringAvatar(representative.name, {
                  width: "36px",
                  height: "36px",
                })}
              />
            )}
          </Box>
          <Box
            sx={{
              marginLeft: "12px",
              width: "calc(100% - 36px - 18px)",
            }}
          >
            <Typography
              color="groundLight.contrastText"
              sx={{ ...styleText, fontWeight: 600 }}
              noWrap
            >
              {representative.name}
            </Typography>
            <Typography color="groundLight.contrastText" sx={styleText} noWrap>
              {representative.baseUser?.email || representative?.email}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingRight: "24px",
        }}
        xs={3}
        item
      >
        <Typography color="groundLight.contrastText" sx={styleText} noWrap>
          {
            ROLES.find(
              (item) => Object.keys(item)[0] === representative.role
            )?.[representative.role]
          }
        </Typography>
      </Grid>
      <Grid
        sx={{ display: "flex", justifyContent: "left", alignItems: "center" }}
        xs={2}
        item
      >
        <Typography color="groundLight.contrastText" sx={styleText} noWrap>
          {largestTerritory(representative.territories) ? (
            <>
              {representative.territories.length > 1 && (
                <IconButton
                  sx={{ marginLeft: "-28px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setTerritoriesList(representative.territories);
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <EyeIcon stroke="#47a06d" />
                </IconButton>
              )}
              <Typography
                variant="caption"
                color="groundLight"
                sx={{
                  color: "#1C1C19",
                  whiteSpace: "nowrap",
                }}
              >
                {largestTerritory(representative.territories)}
              </Typography>
            </>
          ) : (
            <Typography sx={{ textAlign: "center" }}>——</Typography>
          )}
        </Typography>
      </Grid>
      <Grid
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        xs={2}
        item
      >
        <Typography sx={{ ...styleText, fontWeight: 400 }} noWrap>
          {representative?.assignedCustomers}
        </Typography>
      </Grid>
    </Grid>
  );
};
ListItem.propTypes = {
  representative: object,
  handleOpenProfileDialog: func,
  setTerritoriesList: func,
  setAnchorEl: func,
};
ListItem.defaultProps = {
  representative: {
    _count: {
      customers: 0,
    },
  },
};

export default ListItem;
