import { array } from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import { MAX_CHARACTERS_OPTIONS_TITLE } from "Pages/FormViewPage/FormViewPage.constants";
import { truncateText } from "helpers/helpers";

export const MultipleDialogBlock = ({ answers = "" }) => {
  return (
    <Stack gap="4px">
      <Box>
        <Typography fontSize={14} fontWeight={400} color="#5F6267" noWrap>
          Replied:
        </Typography>
      </Box>

      <Stack>
        {answers?.map((answer) => (
          <Typography
            key={answer}
            fontSize={14}
            fontWeight={600}
            color="#5F6267"
          >
            ・{truncateText(answer, MAX_CHARACTERS_OPTIONS_TITLE)}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

MultipleDialogBlock.propTypes = { answers: array };
