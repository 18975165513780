import { useMemo } from "react";
import { number, string, shape, bool } from "prop-types";
import { Box, Link, Typography } from "@mui/material";

const SALES_LINK =
  "https://meetings.hubspot.com/john-vasylets?uuid=fe5c724c-e028-49c1-9eae-7210e7f3cd7e";

export const DialogTitleComponent = ({ state, planName, usingUserCount }) => {
  const { isUpdate } = state;

  const titleMemo = useMemo(() => {
    if (isUpdate) return "Upgrade your plan.";
    return "You have reached users limit as per your plan.";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const descriptionMemo = useMemo(() => {
    if (isUpdate)
      return "Are you enjoying our service and want to unlock even more features? Upgrade your plan now!";
    return (
      <>
        You are currently using{" "}
        <Box
          component="span"
          color="#00A252"
        >{`${usingUserCount} out of ${usingUserCount} platform users`}</Box>{" "}
        as per your {planName} plan. Purchase extra user licence or{" "}
        <Link href={SALES_LINK} target="_blank" underline="none" rel="noopener">
          contact sales
        </Link>
        {/* {planName === "Wholesaler Premium"
          ? "You can purchase extra user licence"
          : `Upgrade to premium to receive extra ${usingUserCount} users along with other benefits or ${
              isTrial
                ? "upgrade to Wholesaler Standard and request an extra user"
                : "simply purchase extra user licence"
            }`} */}
        .
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "156px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box textAlign="center" mt={-1}>
        <Typography fontSize={32} fontWeight={400} color="#5F6267">
          {titleMemo}
        </Typography>
      </Box>
      <Box
        sx={{
          px: 15,
          textAlign: "center",
        }}
      >
        <Typography fontSize={16} fontWeight={300} color="#1C1C19">
          {descriptionMemo}
        </Typography>
      </Box>
    </Box>
  );
};

DialogTitleComponent.propTypes = {
  planName: string,
  usingUserCount: number,
  state: shape({
    isTrial: bool,
  }),
};
DialogTitleComponent.defaultProps = {
  planName: "",
  usingUserCount: 0,
  state: {
    isTrial: false,
  },
};
