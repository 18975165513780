import { useCallback, useEffect, useMemo, useState } from "react";
import { array, bool, func, number, object } from "prop-types";
import { Box, Stack } from "@mui/material";
import ParentItem from "./ParentItem";
import CustomersGroupsHeader from "./CustomersGroupsHeader";
import TabHeaderComponent from "../TabSortedComponent/TabHeaderComponent";
import TabListComponent from "../TabSortedComponent/TabListComponent";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import {
  customersGetParamsAction,
  getCustomersAction,
} from "redux/actions/customers.js";
import {
  customersCountSelector,
  customersExistSelector,
  customersGetParamsSelector,
  customersInactiveCountSelector,
  customersInactiveListSelector,
  customersListSelector,
  customersLoadingSelector,
  customersShowInactiveSelector,
} from "redux/selectors/customers";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EmptyScreen,
  InfiniteLoaderWrapper,
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
  TableHeaderControlPanel,
} from "components";
import {
  flatCountSelector,
  groupsGetParamsSelector,
  parentCustomersLoadingSelector,
} from "redux/selectors/parentCustomers";
import { useAdmin } from "helpers/helpers";
import { ADMIN_ONLY_VIEW_MESSAGE, FETCH_LIMITS } from "utils/constants";

const selector = createSelector(
  customersListSelector,
  customersInactiveListSelector,
  customersShowInactiveSelector,
  customersCountSelector,
  customersInactiveCountSelector,
  customersLoadingSelector,
  customersExistSelector,
  customersGetParamsSelector,
  parentCustomersLoadingSelector,
  groupsGetParamsSelector,
  flatCountSelector,
  (
    customersActiveList,
    customersInactiveList,
    showInactive,
    customersActiveCount,
    customersInactiveCount,
    customersLoading,
    customersExist,
    customersGetParams,
    loadingParents,
    groupsGetParams,
    countParents
  ) => ({
    customersActiveList,
    customersInactiveList,
    showInactive,
    customersActiveCount,
    customersInactiveCount,
    customersLoading,
    customersExist,
    customersGetParams,
    loadingParents,
    groupsGetParams,
    countParents,
  })
);

const FlatView = ({
  handleCheckCustomer,
  checkedGroups,
  handleCheckGroup,
  checkAllGroups,
  handleOpenConfirmDialog,
  handleOpenCustomerProfile,
  handleRedirectMissingInfo,
  handleChangeTerritory,
  handleChangeGroup,
  handleChangeParentGroup,
  handleChangePaymentTerm,
  handleDeleteCustomers,
  handleArchiveCustomers,
  currentFlatTab,
  checkedCustomers,
  handleSetCheckedCustomersHeader,
  handleAssignReps,
  parentCustomersList,
  customersCount,
  handleOpenCustomerNotes,
  onReport,
  reportData,
  handleOrders,
  page,
  setPage,
  handleFetchParents,
  handleCustomerTaskDrawer,
  handleAssignTags,
  repPermissions,
  setCheckedCustomers,
  loadingCustomers,
  handleSetCheckAllCustomers,
  additionalActions,
  actionList,
  handleGetOrderActions,
  loadingGroups,
  onSelectAllGroups,
  resetTableCallback,
  tableFilterParams,
}) => {
  const { state } = useLocation();
  const isAdmin = useAdmin();
  const [allGroupsChecked, setAllGroupsChecked] = useState(false);
  const navigate = useNavigate();

  const {
    customersActiveList,
    customersInactiveList,
    showInactive,
    customersActiveCount,
    customersInactiveCount,
    customersLoading,
    customersExist,
    customersGetParams,
    loadingParents,
    groupsGetParams,
    countParents,
  } = useSelector(selector);

  const dispatch = useDispatch();

  const customersList = useMemo(
    () => (showInactive ? customersInactiveList : customersActiveList),
    [showInactive, customersInactiveList, customersActiveList]
  );

  const count = useMemo(
    () => (showInactive ? customersInactiveCount : customersActiveCount),
    [showInactive, customersInactiveCount, customersActiveCount]
  );

  useEffect(() => {
    const withoutUncategorized = parentCustomersList.filter(
      (g) => g.name !== "Uncategorized"
    );
    if (
      withoutUncategorized.length &&
      checkedGroups.length === withoutUncategorized.length
    )
      return setAllGroupsChecked(true);
    setAllGroupsChecked(false);
  }, [checkedGroups.length, parentCustomersList]);

  const handleFetch = useCallback(() => {
    dispatch(
      getCustomersAction({ limit: FETCH_LIMITS.CUSTOMERS, page: page + 1 })
    );
    setPage((prev) => prev + 1);
  }, [dispatch, page, setPage]);

  const handleSetSortVisit = useCallback(
    (sortField) => {
      const sortParams = {
        ...customersGetParams,
        sort_orders: null,
        sort_visit: null,
        sort_last_order: null,
        sort_display_name: null,
        sort_parent_customer_name: null,
        sort_territory_name: null,
        sort_order_direct: null,
        sort_by_orders_count: null,
      };

      (sortParams[sortField] =
        customersGetParams?.[sortField] !== "desc" ? "desc" : "asc"),
        dispatch(customersGetParamsAction(sortParams));
    },
    [customersGetParams, dispatch]
  );

  const pageContent = useMemo(() => {
    switch (currentFlatTab) {
      case 0:
        return customersList.length ? (
          <Box
            sx={{
              "& .tab-list-item": {
                borderColor: "#d5d9d9",
                borderWidth: "0 0.5px 0.5px 0.5px",
                borderStyle: "solid",
              },
              "& #content-wrapper > div > div:last-child > div": {
                borderRadius: "0 0 4px 4px",
              },
              borderRadius: "0 0 4px 4px",
            }}
          >
            <TableHeaderControlPanel
              actionWrapperProps={{
                sx: { padding: "0 19px 0 15px !important" },
              }}
              checkedCount={checkedCustomers?.length}
              actionsList={additionalActions}
              loading={loadingCustomers}
              dropDownActions={actionList}
              onSelectAll={(value) => handleSetCheckAllCustomers(value)}
              hasCheckedItems={!!checkedCustomers?.length}
              availableSelectCount={count}
              selectName="customer"
              onSelectVisible={handleSetCheckedCustomersHeader}
              cancelSelection={() => setCheckedCustomers([])}
              headerComponent={
                <TabHeaderComponent
                  customersListCount={customersList?.length ?? 0}
                  checkedCustomers={checkedCustomers}
                  handleSetCheckedCustomersHeader={
                    handleSetCheckedCustomersHeader
                  }
                  customersCount={customersCount}
                  customersList={customersList}
                  isFlat
                  quickSort={{
                    sort_visit: customersGetParams?.sort_visit,
                    sort_orders: customersGetParams?.sort_orders,
                    sort_last_order: customersGetParams?.sort_last_order,
                    sort_display_name: customersGetParams?.sort_display_name,
                    sort_parent_customer_name:
                      customersGetParams?.sort_parent_customer_name,
                    sort_territory_name:
                      customersGetParams?.sort_territory_name,
                    sort_order_direct: customersGetParams?.sort_order_direct,
                    sort_by_orders_count:
                      customersGetParams?.sort_by_orders_count,
                  }}
                  handleSetSortVisit={(sortField) =>
                    handleSetSortVisit(sortField)
                  }
                  currentTab="FLAT"
                />
              }
            />

            <InfiniteLoaderWrapper
              itemsList={customersList}
              itemsCount={count}
              offsetHeight={375}
              offsetWidth={61}
              loading={customersLoading}
              handleFetch={handleFetch}
              resetCallback={resetTableCallback}
              contentParams={tableFilterParams}
              itemHeight={51}
              renderChildren={(index) => (
                <TabListComponent
                  customer={customersList[index]}
                  checkedCustomers={checkedCustomers}
                  handleCheckCustomer={handleCheckCustomer}
                  handleOpenConfirmDialog={handleOpenConfirmDialog}
                  handleOpenCustomerProfile={handleOpenCustomerProfile}
                  handleRedirectMissingInfo={handleRedirectMissingInfo}
                  handleChangeTerritory={handleChangeTerritory}
                  handleChangeGroup={handleChangeGroup}
                  handleChangePaymentTerm={handleChangePaymentTerm}
                  handleDeleteCustomers={handleDeleteCustomers}
                  handleArchiveCustomers={handleArchiveCustomers}
                  handleAssignReps={handleAssignReps}
                  groupList={parentCustomersList}
                  handleOpenCustomerNotes={handleOpenCustomerNotes}
                  currentTab="FLAT"
                  onReport={onReport}
                  reportData={reportData}
                  handleGetOrderActions={handleGetOrderActions}
                  lastItem={index === customersList.length - 1}
                  style={{ borderWidth: "0 0px 0.5px 0" }}
                  {...{
                    handleOrders,
                    handleChangeParentGroup,
                    handleCustomerTaskDrawer,
                    handleAssignTags,
                    repPermissions,
                    isAdmin,
                  }}
                />
              )}
            />
          </Box>
        ) : (
          <EmptyScreen
            type="customers"
            height="calc(100vh - 470px)"
            onConfirm={() =>
              navigate("/customers/new", {
                state: state?.type === "onboarding" && { type: "onboarding" },
              })
            }
            showAction={!customersExist}
            loading={customersLoading}
            disabled={
              !!repPermissions && !repPermissions?.customers?.create_edit
            }
            tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
          />
        );

      case 1:
        return (
          <>
            {parentCustomersList?.length ? (
              <>
                <TableHeaderControlPanel
                  actionWrapperProps={{
                    sx: { padding: "0 19px 0 15px !important" },
                  }}
                  checkedCount={checkedGroups?.length}
                  actionsList={additionalActions}
                  loading={loadingGroups || loadingParents}
                  dropDownActions={actionList}
                  onSelectAll={(value) => onSelectAllGroups(value)}
                  hasCheckedItems={!!checkedGroups?.length}
                  availableSelectCount={countParents - 1}
                  selectName="group"
                  onSelectVisible={checkAllGroups}
                  cancelSelection={() => checkAllGroups(false)}
                  headerComponent={
                    <CustomersGroupsHeader
                      allGroupsChecked={allGroupsChecked}
                      checkAllGroups={checkAllGroups}
                      isAdmin={isAdmin}
                    />
                  }
                />
                <Stack
                  sx={{
                    maxHeight: "calc(100vh - 376px)",
                    overflowY: "hidden",
                    position: "relative",
                    borderWidth: parentCustomersList?.length
                      ? "0 1px 1px 1px"
                      : 0,
                    borderStyle: "solid",
                    borderColor: "#D5D9D9",
                    borderRadius: "0 0 4px 4px",
                  }}
                  className="infinite-scroll-custom-scrollbar"
                >
                  <InfiniteScrollWrapper
                    maxHeight="calc(100vh - 376px)"
                    dataLength={parentCustomersList.length}
                    loading={loadingParents}
                    next={() => handleFetchParents(groupsGetParams.page + 1)}
                    hasMore={parentCustomersList?.length < countParents}
                    id="groups-scroll-table"
                  >
                    {parentCustomersList.map((parent) => (
                      <ParentItem
                        key={parent?.id}
                        checkedParents={checkedGroups}
                        handleCheckParent={handleCheckGroup}
                        {...{
                          parent,
                          handleOpenCustomerNotes,
                          handleGetOrderActions,
                          repPermissions,
                          isAdmin,
                        }}
                      />
                    ))}
                    {!loadingParents &&
                      parentCustomersList?.length < countParents && (
                        <InfiniteLoadMoreBtn
                          onClick={() =>
                            handleFetchParents(groupsGetParams.page + 1)
                          }
                        />
                      )}
                  </InfiniteScrollWrapper>
                </Stack>
              </>
            ) : (
              <EmptyScreen
                type="groups"
                height="calc(100vh - 376px)"
                showAction={false}
                loading={loadingParents}
              />
            )}
          </>
        );

      default:
        return <></>;
    }
  }, [
    currentFlatTab,
    customersList,
    checkedCustomers,
    additionalActions,
    loadingCustomers,
    actionList,
    count,
    handleSetCheckedCustomersHeader,
    customersCount,
    customersGetParams?.sort_visit,
    customersGetParams?.sort_orders,
    customersGetParams?.sort_last_order,
    customersGetParams?.sort_display_name,
    customersGetParams?.sort_parent_customer_name,
    customersGetParams?.sort_territory_name,
    customersGetParams?.sort_order_direct,
    customersGetParams?.sort_by_orders_count,
    isAdmin,
    customersLoading,
    handleFetch,
    resetTableCallback,
    tableFilterParams,
    customersExist,
    repPermissions,
    checkedGroups,
    loadingGroups,
    loadingParents,
    countParents,
    checkAllGroups,
    allGroupsChecked,
    parentCustomersList,
    handleSetCheckAllCustomers,
    setCheckedCustomers,
    handleSetSortVisit,
    handleCheckCustomer,
    handleOpenConfirmDialog,
    handleOpenCustomerProfile,
    handleRedirectMissingInfo,
    handleChangeTerritory,
    handleChangeGroup,
    handleChangePaymentTerm,
    handleDeleteCustomers,
    handleArchiveCustomers,
    handleAssignReps,
    handleOpenCustomerNotes,
    onReport,
    reportData,
    handleGetOrderActions,
    handleOrders,
    handleChangeParentGroup,
    handleCustomerTaskDrawer,
    handleAssignTags,
    navigate,
    state?.type,
    onSelectAllGroups,
    handleFetchParents,
    groupsGetParams.page,
    handleCheckGroup,
  ]);

  return <>{pageContent}</>;
};

FlatView.propTypes = {
  handleCheckCustomer: func,
  checkedGroups: array,
  handleCheckGroup: func,
  checkAllGroups: func,
  handleOpenConfirmDialog: func,
  handleOpenCustomerProfile: func,
  handleRedirectMissingInfo: func,
  handleChangeTerritory: func,
  handleChangeGroup: func,
  handleChangeParentGroup: func,
  handleChangePaymentTerm: func,
  handleDeleteCustomers: func,
  handleArchiveCustomers: func,
  currentFlatTab: number,
  checkedCustomers: array,
  handleSetCheckedCustomersHeader: func,
  handleAssignReps: func,
  parentCustomersList: array,
  customersCount: number,
  handleOpenCustomerNotes: func,
  onReport: func,
  reportData: object,
  handleOrders: func,
  page: number,
  setPage: func,
  handleFetchParents: func,
  handleCustomerTaskDrawer: func,
  handleAssignTags: func,
  repPermissions: object,
  setCheckedCustomers: func,
  loadingCustomers: bool,
  handleSetCheckAllCustomers: func,
  additionalActions: array,
  actionList: array,
  handleGetOrderActions: func,
  loadingGroups: bool,
  onSelectAllGroups: func,
  resetTableCallback: func,
  tableFilterParams: object,
};

FlatView.defaultProps = {
  handleCheckCustomer: () => {},
  checkedGroups: [],
  handleCheckGroup: () => {},
  checkAllGroups: () => {},
  handleOpenConfirmDialog: () => {},
  handleOpenCustomerProfile: () => {},
  handleRedirectMissingInfo: () => {},
  handleChangeTerritory: () => {},
  handleChangeGroup: () => {},
  handleChangeParentGroup: () => {},
  handleChangePaymentTerm: () => {},
  handleDeleteCustomers: () => {},
  handleArchiveCustomers: () => {},
  checkedCustomers: [],
  handleSetCheckedCustomersHeader: () => {},
  handleAssignReps: () => {},
  parentCustomersList: [],
  customersCount: 0,
  onReport: () => {},
  handleOrders: () => {},
  page: 1,
  setPage: () => {},
  handleCustomerTaskDrawer: () => {},
  setCheckedCustomers: () => {},
  loadingCustomers: false,
  handleSetCheckAllCustomers: () => {},
  additionalActions: [],
  actionList: [],
  handleGetOrderActions: () => {},
};

export default FlatView;
