import { useMemo, useRef, useState } from "react";
import { bool, func, number, object, string } from "prop-types";

import { NotificationItem } from "./NotificationItem/NotificationItem";

import { CircularProgress, Stack, Typography } from "@mui/material";
import { InfiniteScrollWrapper } from "components";
import { TABS } from "../NotificationCenter.constants";
import { useSelector } from "react-redux";

export const ContentBlock = ({
  notificationState,
  tab,
  onNotificationNavigate,
  onReadNotification,
  fetchNotifications,
  page,
  getDrawerActionFunc,
  allNotificationsAreHidden,
  repPermissions,
}) => {
  const containerRef = useRef(null);
  const currentUser = useSelector(({ auth }) => auth?.currentUser);
  const [isItemHovered, setIsItemHovered] = useState({});

  const tabState = useMemo(
    () => notificationState?.[tab],
    [notificationState, tab]
  );

  return (
    <Stack minHeight="58px">
      {(!tabState.loading && !tabState?.list?.length) ||
      allNotificationsAreHidden ? (
        <Stack minHeight="58px" alignItems="center" justifyContent="center">
          <Typography variant="body2">No {tab} Notifications</Typography>
        </Stack>
      ) : tabState.loading ? (
        <Stack minHeight="58px" alignItems="center" justifyContent="center">
          <CircularProgress size={24} />
        </Stack>
      ) : (
        <Stack
          sx={{
            //maxHeight: "calc(90vh - 502px)",
            maxHeight: "calc(89vh - 102px)",
            height: "100%",
            overflowY: "hidden",
          }}
          ref={containerRef}
        >
          <InfiniteScrollWrapper
            skipScrollReset
            //maxHeight="calc(90vh - 502px)"
            maxHeight="calc(89vh - 102px)"
            dataLength={tabState.list.length}
            id="notifications-table-list"
            loading={tabState.loading}
            nextFunc={() => fetchNotifications(page + 1)}
            hasMoreItems={tabState.list.length < tabState.count}
            loader={
              <CircularProgress
                sx={{ ml: "calc(50% - 15px)", mt: "10px" }}
                size="30px"
              />
            }
          >
            {tabState.list.map((item) => (
              <NotificationItem
                key={item.id}
                isReadTab={tab === TABS[1]}
                isItemHovered={isItemHovered[item?.id] || false}
                onItemHover={setIsItemHovered}
                onNotificationNavigate={onNotificationNavigate}
                item={item}
                timeZone={currentUser?.timeZone}
                onReadNotification={onReadNotification}
                getDrawerActionFunc={getDrawerActionFunc}
                {...{ currentUser, repPermissions }}
              />
            ))}
          </InfiniteScrollWrapper>
        </Stack>
      )}
    </Stack>
  );
};

ContentBlock.propTypes = {
  notificationState: object,
  tab: string,
  allNotificationsAreHidden: bool,
  onNotificationNavigate: func,
  onReadNotification: func,
  getDrawerActionFunc: func,
  fetchNotifications: func,
  page: number,
  currentUser: object,
  repPermissions: object,
};
