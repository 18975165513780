import { useEffect, useMemo, useState } from "react";
import { bool, string } from "prop-types";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import OrdersTopInfoBar from "../OrderDetailsPage/OrdersTopInfoBar";
import { Box, Chip, Grid, Typography } from "@mui/material";
import OrdersTabComponent from "components/OrdersTabComponent";
import OrdersPaymentDeliveryBar from "../OrderDetailsPage/OrdersTopPaymentBar/OrdersPaymentDeliveryBar.jsx";
import OrdersDetailsTableHeader from "../OrderDetailsPage/OrderDetailsTable/OrderDetailsTableHeader";
import { validationSchema } from "../../CustomersPage/pages/NewCustomerPage/NewCustomerPage.validation";
import OrdersBottomInfoBar from "../OrderDetailsPage/OrderBottomInfoBar";
import RefundDialog from "../components/RefundDialog/RefundDialog.jsx";
import OrdersDeliveryBar from "../OrderDetailsPage/OrdersTopPaymentBar/components/OrdersDelivery/OrdersDeliveryBar/OrdersDeliveryBar";
import {
  getDraftOrderByIdAction,
  getOrderByIdAction,
  sendOrderEmailAction,
  setEditOrder,
} from "redux/actions/orders";
import {
  editOrderSelector,
  ordersLoadingSelector,
} from "redux/selectors/orders";
import CancelDialog from "../components/CancelDialog/CancelDialog";
import { currentUserSelector } from "redux/selectors/auth";
import { ReturnDialog, Loader } from "components";
import { IosArrowForward } from "components/Icons";
import ViewOrderEmailDialog from "../components/ViewOrderEmailDialog/ViewOrderEmailDialog";
import { TableItemWithFreeCases } from "../OrderDetailsPage/OrderDetailsTable/components";
import { useRepsPermissions } from "helpers/hooks";
import { CART_TYPES } from "Pages/CartPage/CartPage.constants";
import { normalizeEveryFirstLetterToUpper } from "helpers/helpers";
import { EmailLinkDialog } from "Pages/CartPage/components";
import { useNewOrderPageActions } from "./NewOrderPage.hooks";
import { DRAFT_ORDER_TYPES } from "utils/constants";

const GAP = "15px";

const selector = createSelector(
  editOrderSelector,
  ordersLoadingSelector,
  currentUserSelector,
  (order, loading, currentUser) => ({ order, loading, currentUser })
);

const NewOrderPage = ({ isEdit, navigatePath, isDraftOrder }) => {
  const { order, loading, currentUser } = useSelector(selector);
  const repPermissions = useRepsPermissions();

  const {
    menuItems,
    emailDialogData,
    handleSendEmail: handleSendDraftEmail,
    emailLinkDialogData,
    setEmailLinkDialogData,
  } = useNewOrderPageActions();

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { orderId } = useParams();
  const [openRefundDialog, setOpenRefundDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openReturnDialog, setOpenReturnDialog] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const dispatch = useDispatch();
  const [nameRefundDialog, setNameRefundDialog] = useState("");

  // const isColorColumnShow = order?.products?.some((product) => product?.color);
  // const isSizeColumnShow = order?.products?.some((product) => product?.size);

  const getCartType = () => {
    if (isDraftOrder) return CART_TYPES.draft;
    return CART_TYPES.order;
  };
  const cartType = getCartType();

  const { control } = useForm({
    mode: "onChange",
    defaultValues: {
      id: order?.id || 0,
      customId: order?.customId?.customId || "",
    },
    resolver: yupResolver(validationSchema({ activeOrderCount: 0 })),
  });

  // eslint-disable-next-line
  const formField = useWatch({ control });

  useEffect(() => {
    if (!document.querySelector("#gmaps-script")) {
      const gmapScriptEl = document.createElement(`script`);
      // eslint-disable-next-line no-undef
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&libraries=places,drawing,geometry`;
      gmapScriptEl.id = "gmaps-script";
      document
        .querySelector(`body`)
        .insertAdjacentElement(`beforeend`, gmapScriptEl);
    }
    if (orderId === "third_party_temp") return;

    // insert google api link manually to preload in edit customer address
    if (cartType === CART_TYPES.draft) {
      return dispatch(getDraftOrderByIdAction(orderId));
    }

    dispatch(getOrderByIdAction(orderId));

    return () => dispatch(setEditOrder({}));
  }, [orderId, dispatch, cartType]);

  const handleOpenEmail = () => {
    setEmailOpen(true);
  };

  const [loadingSendEmail, setLoadingSendEmail] = useState(false);

  const handleSendEmail = (data) => {
    setLoadingSendEmail(true);
    dispatch(
      sendOrderEmailAction(order.id, data, () => {
        setEmailOpen(false);
        setLoadingSendEmail(false);
      })
    );
  };

  const handleDuplicateOrder = () => {
    navigate(`/cart/duplicate/${orderId}`, {
      state: { duplicateOrder: order },
    });
  };

  const hasThirdParty = useMemo(() => {
    return order?.type === "THIRD_PARTY";
  }, [order?.type]);

  const hasThirdPartyDraft = useMemo(() => {
    return order?.orderType === "THIRD_PARTY";
  }, [order?.orderType]);

  const setHeight = useMemo(() => {
    const listLength = order?.products?.length;
    const itemHeight = 57;

    if (listLength <= 6) return "100%";

    return `${6 * itemHeight}px`;
  }, [order?.products?.length]);

  const paidProducts = useMemo(
    () =>
      order?.products?.filter((p) => {
        const freeQty =
          p.manufacturerDiscountDuplicates.reduce(
            (sum, d) => sum + d.quantity,
            0
          ) || 0;
        return p.quantity > freeQty;
      }) || [],
    [order?.products]
  );

  const freeCaseProducts = useMemo(
    () =>
      order?.products
        ?.filter(({ manufacturerDiscountDuplicates: msd }) =>
          msd.find((d) => !!d.quantity)
        )
        .map((p) => ({
          ...p,
          manufacturerDiscountDuplicates: [],
          price: 0,
          quantity:
            p.manufacturerDiscountDuplicates.reduce(
              (sum, d) => sum + d.quantity,
              0
            ) || 0,
          isFreeCase: true,
        })) || [],
    [order?.products]
  );

  const hasDiscount = useMemo(() => {
    if (order?.totalDiscountValue) {
      return (
        freeCaseProducts?.length > 0 ||
        order?.products?.some((product) => product?.itemDiscountValue > 0)
      );
    }
  }, [freeCaseProducts?.length, order?.products, order?.totalDiscountValue]);

  const showOrdersPaymentDeliveryBar = useMemo(() => {
    return !(cartType === CART_TYPES.draft || hasThirdParty);
  }, [cartType, hasThirdParty]);

  const isThirdParty = order?.orderType === "THIRD_PARTY";

  const showThirdPartyChip =
    [DRAFT_ORDER_TYPES.open, DRAFT_ORDER_TYPES.closed].includes(
      order?.status
    ) && isThirdParty;

  return (
    <>
      <Loader isLoading={loading} />
      <EmailLinkDialog
        open={!!emailLinkDialogData}
        onClose={() => {
          setEmailLinkDialogData(null);
        }}
        emailLinkDialogData={emailLinkDialogData}
        onSubmit={(type = "THIRD_PARTY") => {
          navigate("/orders", {
            state: type === "THIRD_PARTY" ? "3rd Party" : "Direct",
          });
        }}
      />

      <RefundDialog
        open={openRefundDialog}
        onClose={() => {
          setOpenRefundDialog(false);
          setNameRefundDialog("");
        }}
        nameRefundDialog={nameRefundDialog}
        order={order}
        isOrderPage={true}
      />
      <ReturnDialog
        open={openReturnDialog}
        onClose={() => {
          setOpenReturnDialog(false);
        }}
        order={order}
      />
      <ViewOrderEmailDialog
        order={order}
        isOpen={emailOpen}
        handleClose={() => setEmailOpen(false)}
        handleSendEmail={handleSendEmail}
        loading={loadingSendEmail}
      />
      <CancelDialog
        order={order}
        title="Cancel order"
        orderId={order.id}
        customId={order?.customId?.customId}
        paymentStatus={order?.paymentStatus}
        deliveryStatus={order?.deliveryStatus}
        totalPayments={order?.totalPayments}
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        isOrderPage={true}
      />
      <OrdersTabComponent
        title={
          <Box display="flex" alignItems="center" gap={GAP}>
            <Box color="#5F6267" display="flex" alignItems="baseline" gap={GAP}>
              <Typography
                component={Link}
                sx={{
                  fontSize: "20px",
                  cursor: "pointer",
                  textDecoration: "none",
                  mr: "5px",
                }}
                fontWeight="400"
                color="primary"
                to={{
                  pathname: navigatePath || "/orders",
                }}
                state={state}
                from={pathname}
              >
                {normalizeEveryFirstLetterToUpper(cartType)}
              </Typography>
              <IosArrowForward />
              <Box>{order?.customId?.customId}</Box>
            </Box>
            {showThirdPartyChip && (
              <Chip
                sx={{
                  height: "18px",
                  fontSize: "10px",
                  color: "#5F6267",
                  backgroundColor: "#F6F6F6",
                  border: "0.5px solid #D5D9D9",
                  "& .MuiChip-label": { px: 1 },
                }}
                label="3rd Party"
              />
            )}
          </Box>
        }
        form="new-customer-form"
        order={order}
        saveButtons
        isEdit={isEdit}
        onClickRefundBtn={(val, name) => {
          if (name === "Return Items") {
            return setOpenReturnDialog(true);
          }
          setOpenRefundDialog(val);
          setNameRefundDialog(name);
        }}
        onClickCancelBtn={() => setOpenCancelDialog(true)}
        orderId={order.id}
        handleOpenEmail={handleOpenEmail}
        onClickDuplicate={handleDuplicateOrder}
        repPermissions={repPermissions}
        cartType={cartType}
        menuItems={menuItems}
        emailDialogData={emailDialogData}
        handleSendEmail={handleSendDraftEmail}
        currentUser={currentUser}
      />

      {isEdit ? (
        <Box px="32px">
          <OrdersTopInfoBar
            {...{ order, hasThirdParty, hasThirdPartyDraft, repPermissions }}
          />

          {showOrdersPaymentDeliveryBar && (
            <Grid sx={{ marginTop: 2 }} container columnSpacing={2}>
              <Grid item xs={6}>
                <OrdersPaymentDeliveryBar
                  order={order}
                  title="Payments"
                  infoStatus={order?.totalPayments?.toFixed(2)}
                  status={order?.paymentStatus}
                  timeZone={currentUser?.timeZone}
                  repPermissions={repPermissions}
                />
              </Grid>

              <Grid item xs={6}>
                <OrdersDeliveryBar
                  order={order}
                  timeZone={currentUser?.timeZone}
                  repPermissions={repPermissions}
                />
              </Grid>
            </Grid>
          )}

          <OrdersDetailsTableHeader
            {...{
              // isColorColumnShow,
              // isSizeColumnShow,
              hasThirdParty,
              hasDiscount,
            }}
          />
          <Box
            sx={{
              counterReset: "section",
              borderWidth: "0 1px 1px 1px",
              borderStyle: "solid",
              borderColor: "#D5D9D9",
              borderRadius: "0 0 4px 4px",
              height: setHeight,
              overflow: "overlay",
            }}
          >
            {[
              ...paidProducts,
              ...freeCaseProducts.filter(
                (p) => !paidProducts.some(({ id }) => id === p.id)
              ),
            ].map((product) => (
              <TableItemWithFreeCases
                key={product.id}
                {...{
                  product,
                  hasThirdParty,
                  freeCases: freeCaseProducts,
                  hasDiscount,
                }}
              />
            ))}
          </Box>
          <OrdersBottomInfoBar
            {...{ order, repPermissions }}
            isThirdParty={hasThirdParty}
            isThirdPartyDraft={hasThirdPartyDraft}
          />
        </Box>
      ) : (
        <form id="new-customer-form">
          <Typography>Create new Order</Typography>
        </form>
      )}
    </>
  );
};

NewOrderPage.propTypes = {
  isEdit: bool,
  navigatePath: string,
  navigateState: string,
  isDraftOrder: bool,
};

NewOrderPage.defaultProps = {
  isEdit: false,
  isDraftOrder: false,
};

export default NewOrderPage;
