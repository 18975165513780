import { Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import pluralize from "pluralize";
import {
  deletePriceListService,
  getPriceListsService,
  updatePriceListService,
} from "services/priceLists";
import { error, success } from "utils/notifications";
import { ADMIN_ONLY_VIEW_MESSAGE, FETCH_LIMITS } from "utils/constants";
import { useDebounce, useRepsPermissions } from "helpers/hooks";
import { GlobalPageContext } from "Pages/MasterPage/MasterPage";
import { formatLongWords } from "helpers/helpers";

const DEFAULT_STATE = {
  loading: false,
  list: [],
  count: 0,
  existData: false,
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: FETCH_LIMITS.PRICE_LISTS,
  search: "",
};

export const usePriceListTab = (currentCatalogTab) => {
  const { setGlobalLoading, globalLoading } = useContext(GlobalPageContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const repPermissions = useRepsPermissions();
  const [openCreatePriceDialog, setOpenCreatePriceDialog] = useState(false);

  const [selectedPrices, setSelectedPrices] = useState([]);
  const [priceListParams, setPriceListParams] = useState(DEFAULT_PARAMS);
  const [priceListState, setPriceListState] = useState(DEFAULT_STATE);

  const searchInputDebounced = useDebounce(priceListParams.search, 500);

  const disabledEditPriceList = useMemo(() => {
    return repPermissions
      ? !repPermissions?.catalog?.create_edit_price_lists
      : false;
  }, [repPermissions]);

  const disabledDeletePriceList = useMemo(() => {
    return repPermissions
      ? !repPermissions?.catalog?.delete_price_lists
      : false;
  }, [repPermissions]);

  const isSingleSelect = useMemo(
    () => selectedPrices?.length === 1,
    [selectedPrices?.length]
  );

  const isPriceListTab = useMemo(
    () => currentCatalogTab === "Price Lists",
    [currentCatalogTab]
  );

  const setListSearch = (search) => {
    setPriceListParams((prev) => ({ ...prev, search }));
  };

  const setLoading = (loading) =>
    setPriceListState((prev) => ({ ...prev, loading }));

  const onPriceSelect = (price) => {
    const isAlreadyAdded = selectedPrices?.some(({ id }) => id === price.id);

    if (isAlreadyAdded)
      return setSelectedPrices(
        selectedPrices.filter(({ id }) => id !== price.id)
      );

    return setSelectedPrices([...selectedPrices, price]);
  };

  const handleOpenPriceProfile = useCallback(
    (price) => {
      navigate(`${price.id}`);
    },
    [navigate]
  );

  const checkAlreadyChecked = (id) => {
    return selectedPrices?.some((price) => price?.id === id);
  };

  const checkAllPrices = () => {
    if (selectedPrices.length !== priceListState.list.length)
      return setSelectedPrices(priceListState.list);
    return setSelectedPrices([]);
  };

  const onUncheckAllPrices = useCallback(() => setSelectedPrices([]), []);

  const onFetchPriceList = useCallback(
    async (page) => {
      //if (!page) {
      setLoading(true);
      //}
      try {
        const isFetchingLastElement =
          page > 1 && priceListState.count === FETCH_LIMITS.PRICE_LISTS;

        const res = await getPriceListsService({
          ...priceListParams,
          page: isFetchingLastElement ? 1 : page ? page : 1,
        });

        const rows = res?.rows || [];
        const count = res?.count || 0;
        const existData = res?.existData;

        setPriceListState((prev) => ({
          ...prev,
          list:
            page > 1 && !isFetchingLastElement ? [...prev.list, ...rows] : rows,
          count: count,
          existData,
        }));

        setPriceListParams((prev) => ({
          ...prev,
          page: isFetchingLastElement ? 1 : page ? page : 1,
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    },
    [priceListParams, priceListState.count]
  );

  const onPriceListDeleteConfirm = useCallback(
    async (id) => {
      setGlobalLoading(true);
      dispatch(setConfirmIsOpenAction(false));

      if (id) {
        try {
          await deletePriceListService({ priceListsIds: [id] });

          const filteredList = priceListState.list.filter(
            (price) => price.id !== id
          );

          if (priceListState.count - 1 <= FETCH_LIMITS.PRICE_LISTS) {
            setPriceListState((prev) => ({
              ...prev,
              list: filteredList,
              count: prev.count - 1,
            }));
            setSelectedPrices(selectedPrices.filter(({ id }) => id !== id));
          } else {
            setPriceListState((prev) => ({
              ...prev,
              list: [],
              count: 0,
            }));
            setSelectedPrices(selectedPrices.filter(({ id }) => id !== id));
            onFetchPriceList();
          }

          return success("Price list deleted");
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          error(err?.response?.data?.message || "Something went wrong");
        } finally {
          setGlobalLoading(false);
        }
      }

      try {
        await deletePriceListService({
          priceListsIds: selectedPrices?.map((p) => p.id),
        });

        const filteredList = priceListState.list.filter(
          ({ id }) =>
            !selectedPrices?.some(({ id: selectedId }) => id === selectedId)
        );

        if (
          priceListState.count - selectedPrices.length <=
          FETCH_LIMITS.PRICE_LISTS
        ) {
          setSelectedPrices([]);
          setPriceListState((prev) => ({
            ...prev,
            list: filteredList,
            count: prev.count - selectedPrices.length,
          }));
        } else {
          setPriceListState((prev) => ({
            ...prev,
            list: [],
            count: 0,
          }));
          setSelectedPrices([]);
          onFetchPriceList();
        }

        return success(
          `Price ${pluralize("list", selectedPrices.length)} deleted `
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setGlobalLoading(false);
      }
    },
    [
      dispatch,
      onFetchPriceList,
      priceListState.count,
      priceListState.list,
      selectedPrices,
      setGlobalLoading,
    ]
  );

  const onPriceListDelete = useCallback(
    (id, name) => {
      dispatch(
        openConfirmDialogAction({
          title: `Delete price ${pluralize("list", selectedPrices.length)}?`,
          text: (
            <>
              {isSingleSelect || id ? (
                <Typography
                  fontWeight="400"
                  fontSize="15px"
                  whiteSpace="pre-line"
                >
                  Are you sure you want to delete the{" "}
                  <span style={{ fontWeight: "500" }}>
                    &#39;{formatLongWords(selectedPrices?.[0]?.name || name)}
                    &#39;
                  </span>{" "}
                  price list?
                </Typography>
              ) : (
                <Typography
                  fontWeight="400"
                  fontSize="15px"
                  whiteSpace="pre-line"
                >
                  Are you sure you want to delete selected price lists?
                </Typography>
              )}
            </>
          ),
          propBtns: {
            left: {
              label: "Cancel",
              color: "cancel",
              variant: "outlined",
              sx: {
                width: "78px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                fontSize: "13px",
                height: "28px",
              },
            },
            right: {
              sx: {
                width: "78px",
                color: "#FFFFFF",
                fontSize: "13px",
                height: "28px",
                boxShadow: "none",
                backgroundColor: "#EB4233",
              },
              label: "Delete",
              color: "error",
              variant: "contained",
              onClick: () => {
                onPriceListDeleteConfirm(id);
              },
            },
          },
        })
      );
    },
    [dispatch, isSingleSelect, onPriceListDeleteConfirm, selectedPrices]
  );

  const handlePriceActions = useCallback(() => {
    return [
      {
        label: "Edit",
        element: null,
        disabled: disabledEditPriceList,
        onClick: () => navigate(`/catalog/price-list/${selectedPrices[0]?.id}`),
        show: isSingleSelect,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledEditPriceList,
          props: { isDark: true },
        },
      },
      {
        label: "Delete",
        sx: {
          "& .MuiListItemText-root": {
            "& .MuiTypography-root": {
              fontSize: "12px",
              fontWeight: 500,
              color: "#FF6254 !important",
            },
          },
        },
        disabled: disabledDeletePriceList,
        onClick: () => onPriceListDelete(),
        show: true,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: disabledDeletePriceList,
          props: { isDark: true },
        },
      },
    ];
  }, [
    disabledEditPriceList,
    isSingleSelect,
    disabledDeletePriceList,
    navigate,
    selectedPrices,
    onPriceListDelete,
  ]);

  const ACTIONS_LIST = useMemo(
    () => handlePriceActions(),
    [handlePriceActions]
  );

  const handleSavePriceList = (newPriceListItem) => {
    setOpenCreatePriceDialog(false);
    setPriceListState((prev) => ({
      ...prev,
      list: [newPriceListItem, ...prev.list],
      count: prev.count + 1,
    }));
    setListSearch("");
  };

  const onEditSave = async (id, name) => {
    setGlobalLoading(true);
    try {
      const res = await updatePriceListService(id, { name });
      const list = [...priceListState.list];

      let updateIndex = list.findIndex((price) => price.id === id);

      if (updateIndex !== -1 && res?.name) {
        list[updateIndex] = { ...list[updateIndex], name: res?.name };
        setPriceListState((prev) => ({ ...prev, list }));
      }

      success("Price list name updated");
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setGlobalLoading(false);
    }
  };

  const onFetchAndSelectAll = async () => {
    if (priceListState.list.length === priceListState.count) {
      return setSelectedPrices(priceListState.list);
    }
    setLoading(true);
    const res = await getPriceListsService();

    const rows = res?.rows || [];
    const count = res?.count || 0;
    const existData = rows?.existData;

    setPriceListState((prev) => ({
      ...prev,
      list: rows,
      count,
      existData,
    }));

    setSelectedPrices(rows);
    return setLoading(false);
  };

  useEffect(() => {
    if (!isPriceListTab) return;
    onFetchPriceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPriceListTab, searchInputDebounced]);

  return {
    priceList: priceListState.list,
    priceCount: priceListState.count,
    existPriceListData: priceListState.existData,
    listLoading: priceListState.loading,
    selectedPrices,
    setSelectedPrices,
    handleOpenPriceProfile,
    checkAllPrices,
    allPricesChecked: false,
    onPriceSelect,
    checkAlreadyChecked,
    ACTIONS_LIST,
    onUncheckAllPrices,
    openCreatePriceDialog,
    setOpenCreatePriceDialog,
    setListSearch,
    searchInput: priceListParams.search,
    onPriceListDelete,
    handleSavePriceList,
    onEditSave,
    onFetchPriceList,
    page: priceListParams.page,
    onFetchAndSelectAll,
    globalLoading,
    searchInputDebounced,
  };
};
