import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  ANIMATION_CHECK_TIME,
  DEBOUNCED_READ_TIME,
  DEFAULT_STATE,
  NOTIFICATION_TYPES,
  SETTING_SALES_TABS,
  TABS,
} from "./NotificationCenter.constants";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "redux/actions/confirmDialogs";
import { normalizeSnakeCaseString } from "helpers/helpers";
import { error, success } from "utils/notifications";

import {
  bulkUpdateByArrayNotificationService,
  bulkUpdateNotificationService,
  getNotificationSettingsService,
  getNotificationsService,
  updateNotificationSettingsService,
} from "services/notification-center";
import { SCROLL_LIMIT } from "utils/constants";
import { useDebounce, useRepsPermissions } from "helpers/hooks";
import { isEqual } from "lodash";
import { useNotificationCenter } from "helpers/useNotificationCenter";
import { setPictureItemAction } from "redux/actions/gallery";
import { useRedirect } from "helpers/useRedirect";
import { sortUserSettingsByRole } from "./NotificationCenter.helpers";

const DEFAULT_SETTINGS_STATE = {
  loading: true,
  list: SETTING_SALES_TABS,
  role: "",
  id: "",
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: SCROLL_LIMIT,
};

const DEFAULT_NOTES_DATA = {
  open: false,
  data: null,
};

export const useNotifications = ({ handleClose, open }) => {
  const dispatch = useDispatch();
  const navigate = useRedirect();
  const defaultSettingsRef = useRef(null);
  const repPermissions = useRepsPermissions();

  const currentUser = useSelector(({ auth }) => auth?.currentUser);

  const [tab, setTab] = useState(TABS[0]);
  const [showSettings, setShowSettings] = useState(false);
  const [userSettings, setUserSettings] = useState(DEFAULT_SETTINGS_STATE);
  const [notificationState, setNotificationState] = useState(DEFAULT_STATE);
  const [notificationParams, setNotificationParams] = useState(DEFAULT_PARAMS);
  const [idsForChange, setIdsForChange] = useState([]);
  const [noteDialogState, setNoteDialogState] = useState(DEFAULT_NOTES_DATA);
  const [taskDialogState, setTaskDialogState] = useState(DEFAULT_NOTES_DATA);
  const [allNotificationsAreHidden, setAllNotificationsAreHidden] =
    useState(false);
  const [avoidFetchAll, setAvoidFetchAll] = useState(false);

  const [photoGroupPopupState, setPhotoGroupPopupState] = useState(false);

  const onClosePhotoGroupPopup = () => setPhotoGroupPopupState(false);

  const onNotesClose = () => setNoteDialogState(DEFAULT_NOTES_DATA);
  const onTasksClose = () => setTaskDialogState(DEFAULT_NOTES_DATA);

  const onClose = (e) => {
    setShowSettings(false);
    handleClose(e);
    setNotificationParams(DEFAULT_PARAMS);
    setTab(TABS[0]);

    setTimeout(() => {
      setUserSettings(DEFAULT_SETTINGS_STATE);
    }, ANIMATION_CHECK_TIME);
  };

  const handleSetGalleryGroupItem = (data) => {
    dispatch(setPictureItemAction(data?.photoGroup?.id));
    setPhotoGroupPopupState(true);
    onClose();
  };

  const onNoteOpen = (data) => {
    onClose();
    setNoteDialogState({
      open: true,
      data: {
        customer: { ...data.customer, name: data?.customer?.displayName },
      },
    });
  };

  const onTaskOpen = (data) => {
    onClose();
    setTaskDialogState({ open: true, data });
  };

  const { notificationsCenterCount, notificationsUpdatedAt } =
    useNotificationCenter();

  const getDrawerActionFunc = (item) => {
    const { type, task, photoGroupComment } = item || {};

    switch (type) {
      case NOTIFICATION_TYPES.NOTE_ADDED:
        return () => onNoteOpen(item);
      case NOTIFICATION_TYPES.TASK_COMPLETED:
      case NOTIFICATION_TYPES.TASK_UNASSIGNED:
      case NOTIFICATION_TYPES.TASK_ASSIGNED:
      case NOTIFICATION_TYPES.TASK_OVERDUE:
        return () => onTaskOpen(task);
      case NOTIFICATION_TYPES.PHOTO_GROUP_COMMENTED:
        return () => handleSetGalleryGroupItem(photoGroupComment);
      default:
        break;
    }
  };

  const onHiddenCheck = useCallback((list) => {
    if (!list) return setAllNotificationsAreHidden(false);

    const hiddenElements = list.filter((item) => {
      const element = document.getElementById(`notification-${item.id}`);
      return element && window.getComputedStyle(element).display === "none";
    });

    return setAllNotificationsAreHidden(hiddenElements.length === list.length);
  }, []);

  const onStateChange = ({ key, data }) =>
    setNotificationState((prev) => ({
      ...prev,
      [key]: { ...prev[key], ...data },
    }));

  const onFetchAllNotificationsToCurrentPage = async () => {
    try {
      const fetchParams = {
        read: tab === TABS[1],
        limit: notificationParams.limit * notificationParams.page,
      };
      const res = await getNotificationsService(fetchParams);
      const { rows, count } = res || {};
      onStateChange({
        key: tab,
        data: {
          list: rows,
          count,
        },
      });
      onHiddenCheck(rows);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching notifications:", error);
    }
  };

  const fetchNotifications = useCallback(
    async (page) => {
      //if (!page && notificationState?.[tab]?.list.length) return;

      if (!page) {
        onStateChange({ key: tab, data: { loading: true } });
      }

      try {
        const fetchParams = {
          ...notificationParams,
          read: tab === TABS[1],
          page: page ? page : 1,
        };

        const res = await getNotificationsService(fetchParams);

        const { rows, count } = res || {};

        const list =
          page > 1 ? [...notificationState?.[tab]?.list, ...rows] : rows;

        onStateChange({
          key: tab,
          data: {
            list,
            count,
          },
        });

        setAvoidFetchAll(false);
        onHiddenCheck(list);
        setNotificationParams((prev) => ({ ...prev, page: page ? page : 1 }));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Error fetching notifications:", error);
      } finally {
        onStateChange({ key: tab, data: { loading: false } });
      }
    },
    [notificationParams, notificationState, onHiddenCheck, tab]
  );

  const fetchSettings = useCallback(async () => {
    setUserSettings((prev) => ({ ...prev, loading: true }));
    try {
      const res = await getNotificationSettingsService();

      const { role, id, distributorId, representativeId, ...rest } = res || {};

      const sortedList = sortUserSettingsByRole({ role, permissions: rest });

      setUserSettings((prev) => ({
        ...prev,
        role,
        id,
        list: sortedList,
        loading: false,
      }));

      defaultSettingsRef.current = sortedList;
    } catch (error) {
      setUserSettings((prev) => ({ ...prev, loading: false }));
      // eslint-disable-next-line no-console
      console.error("Error fetching settings:", error);
    }
  }, []);

  useEffect(() => {
    if (!open || tab !== TABS[0] || avoidFetchAll) return;
    onFetchAllNotificationsToCurrentPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsUpdatedAt]);

  useEffect(() => {
    if (!open) return;
    fetchNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, open]);

  useEffect(() => {
    if (!open || !showSettings) return;
    fetchSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, showSettings]);

  const onSettingsUpdate = async (updatedState) => {
    if (userSettings?.role === "") return;

    const updateParams = { role: userSettings.role, ...updatedState };

    try {
      await updateNotificationSettingsService(updateParams);

      success("Settings Updated");
    } catch (err) {
      error("Settings Update Failed");
      // eslint-disable-next-line no-console
      console.error("Error updating settings:", err);
    }
  };

  const onReadAllNotifications = async () => {
    onStateChange({ key: tab, data: { loading: true } });
    bulkUpdateNotificationService({
      read: true,
    })
      .then(() => {
        success("Notifications Read");
        onStateChange({
          key: tab,
          data: {
            loading: false,
            list: [],
            count: 0,
          },
        });
        setIdsForChange([]);
        setAllNotificationsAreHidden(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error("Error Notifications Read:", err);
        onStateChange({ key: tab, data: { loading: false } });
      });
  };

  const onMarkAllAsRead = () => {
    dispatch(
      openConfirmDialogAction({
        isIcon: true,
        title: "Mark All as Read?",
        text: "Do you want to mark all notifications as read?",
        propBtns: {
          left: {
            label: "Cancel",
            color: "cancel",
            variant: "outlined",
            sx: {
              width: "110px",
              color: "#707070",
              fontSize: "13px",
              height: "28px",
            },
          },
          right: {
            sx: {
              width: "110px",
              color: "#FFFFFF",
              fontSize: "13px",
              height: "28px",
              boxShadow: "none",
            },
            label: "Proceed",
            variant: "contained",
            onClick: () => {
              dispatch(setConfirmIsOpenAction(false));
              onReadAllNotifications();
            },
          },
        },
      })
    );
  };

  const onNotificationChangeService = async () => {
    setIdsForChange([]);
    await bulkUpdateByArrayNotificationService({
      read: tab === TABS[0],
      notificationIds: idsForChange,
    }).catch((err) => {
      error(err?.message || "Something went wrong");
      // eslint-disable-next-line no-console
      console.error("Error updating settings:", err);
    });
  };

  const debouncedSaveReads = useDebounce(idsForChange, DEBOUNCED_READ_TIME);

  useEffect(() => {
    if (idsForChange.length) {
      onNotificationChangeService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSaveReads]);

  const onTabChange = async (value) => {
    if (idsForChange.length) {
      setAvoidFetchAll(true);
      await onNotificationChangeService();
    }

    setTab(value);
    setNotificationParams(DEFAULT_PARAMS);
    setNotificationState(DEFAULT_STATE);
    setAllNotificationsAreHidden(false);
  };

  const debouncedSettings = useDebounce(userSettings, DEBOUNCED_READ_TIME);

  useEffect(() => {
    if (
      defaultSettingsRef.current &&
      !isEqual(defaultSettingsRef.current, userSettings.list)
    ) {
      onSettingsUpdate(userSettings.list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSettings]);

  const preparedSettingsData = useMemo(
    () =>
      Object.keys(userSettings.list).map((key) => {
        const values = Object.values(userSettings.list?.[key]);
        const keys = Object.keys(userSettings.list?.[key]);

        const setName = (childKey) => {
          return (childKey.charAt(0).toUpperCase() + childKey.slice(1))
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase());
        };

        return {
          id: key,
          name: normalizeSnakeCaseString(key),
          children: keys.map((childKey, index) => ({
            id: childKey,
            name: setName(childKey),
            value: values[index],
          })),
        };
      }),
    [userSettings]
  );

  const onReadNotification = async (item) => {
    const filteredList = notificationState?.[tab]?.list.map((i) => {
      if (i.id === item.id) {
        return {
          ...i,
          deleted: true,
        };
      }
      return i;
    });

    setIdsForChange([...idsForChange, item.id]);

    onStateChange({
      key: tab,
      data: {
        list: filteredList,
      },
    });

    setTimeout(() => {
      onHiddenCheck(filteredList);
    }, ANIMATION_CHECK_TIME);
  };

  const onUserSettingsChange = ({ type, name, value }) => {
    const updatedState = {
      ...userSettings,
      list: {
        ...userSettings.list,
        [type]: {
          ...userSettings.list?.[type],
          [name]: value,
        },
      },
    };
    setUserSettings(updatedState);

    //onSettingsUpdate(updatedState.list);
  };

  const onCheckForDefaultSettings = (key) => {
    return !isEqual(SETTING_SALES_TABS[key], userSettings.list?.[key]);
  };

  const onNotificationNavigate = (e, url, props = {}) => {
    onClose(e);
    navigate(url, props);
  };

  return {
    tab,
    setTab: onTabChange,
    onClose,
    userSettings,
    notificationState,
    onMarkAllAsRead,
    showSettings,
    setShowSettings,
    preparedSettingsData,
    onUserSettingsChange,
    onReadAllNotifications,
    onReadNotification,
    fetchNotifications,
    page: notificationParams.page,
    idsForChange,
    onCheckForDefaultSettings,
    noteDialogState,
    onNotesClose,
    getDrawerActionFunc,
    notificationsCenterCount,
    allNotificationsAreHidden,
    taskDialogState,
    onTasksClose,
    photoGroupPopupState,
    currentUser,
    repPermissions,
    onClosePhotoGroupPopup,
    onNotificationNavigate,
  };
};
