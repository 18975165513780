import { useState, useEffect, useCallback, useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

import { Box } from "@mui/material";

import PageTabComponent from "../../components/PageTabComponent";
import {
  ProfileComponent,
  NewRepresentativesFilter,
  ListSalesComponent,
} from "./components";
import {
  merchandisersCountSelector,
  repsInactiveCountSelector,
  repsInactiveListSelector,
  repsListSelector,
  repsShowInactiveSelector,
  salesCountSelector,
  repsGetThirdPartyUsers,
  thirdPartyRepsCountSelector,
  repsGetSalesUsers,
  merchandisersListSelector,
  repsSummariesSelector,
  repsTopSummariesLoadingSelector,
  repsExistActiveSelector,
  repsExistInactiveSelector,
} from "../../redux/selectors/reps";
// import { repsGetParamsAction } from "../../redux/actions/reps";

import { TABS } from "./tabs";

import { SalesRepTotalSummaries } from "./components/SalesRepTotalSummaries/SalesRepTotalSummaries";
import { currentUserSelector } from "redux/selectors/auth";
import { initialState } from "redux/reducers/settings";
import { updateDistributorSettingsService } from "services/account";
import { error } from "utils/notifications";
import { setRepAllColumnLayoutsAction } from "redux/actions/settings";
import { useAdmin } from "helpers/helpers";
import { getRepByIdService } from "services/reps";
import { useRepsPermissions } from "helpers/hooks";
import { useRepsActions } from "./useRepsActions";
import ConfirmDialog from "./components/ProfileComponent/ConfirmDialog/ConfirmDialog";
import TransferPopup from "components/TransferPopup/TransferPopup";
import ReportDialog from "Pages/ReportsPage/components/ReportDialog/ReportDialog";
import { useReportPage } from "Pages/ReportsPage/ReportsPage.hooks";
import { customersByRepGetParams } from "redux/actions/customers";
import { initialCustomersState } from "redux/reducers/customers";

const selector = createSelector(
  repsListSelector,
  merchandisersListSelector,
  repsShowInactiveSelector,
  repsInactiveListSelector,
  repsInactiveCountSelector,
  repsGetSalesUsers,
  salesCountSelector,
  merchandisersCountSelector,
  repsGetThirdPartyUsers,
  thirdPartyRepsCountSelector,
  repsSummariesSelector,
  repsTopSummariesLoadingSelector,
  currentUserSelector,
  repsExistActiveSelector,
  repsExistInactiveSelector,
  (
    repsList,
    merchandisersReps,
    showInactive,
    repsInactiveList,
    repsInactiveCount,
    salesReps,
    salesCount,
    merchandisersCount,
    thirdPartyReps,
    thirdPartyCount,
    totalSummaries,
    repsTopSummariesLoading,
    currentUser,
    repsExistActive,
    repsExistInactive
  ) => ({
    repsList,
    merchandisersReps,
    showInactive,
    repsInactiveList,
    repsInactiveCount,
    salesReps,
    salesCount,
    merchandisersCount,
    thirdPartyReps,
    thirdPartyCount,
    totalSummaries,
    repsTopSummariesLoading,
    currentUser,
    repsExistActive,
    repsExistInactive,
  })
);

const RepresentativesPage = () => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  const [isOpenProfileDialog, setIsOpenProfileDialog] = useState(false);
  const [profileData, setProfileData] = useState({
    profile: null,
    loading: false,
  });
  const [currentTab, setCurrentTab] = useState(TABS[0]);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const {
    repsList,
    merchandisersReps,
    showInactive,
    repsInactiveList,
    repsInactiveCount,
    salesReps,
    salesCount,
    merchandisersCount,
    thirdPartyReps,
    thirdPartyCount,
    totalSummaries,
    repsTopSummariesLoading,
    currentUser,
    repsExistActive,
    repsExistInactive,
  } = useSelector(selector);

  const dispatch = useDispatch();

  const fetchRepById = useCallback(async ({ id, callBack }) => {
    if (!id) return;
    setProfileData((prev) => ({ ...prev, loading: true }));
    try {
      const rep = await getRepByIdService(id);
      setProfileData((prev) => ({ ...prev, profile: rep }));
      if (callBack) callBack();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message || err?.message);
      error(err?.response?.data?.message || err?.message);
    } finally {
      setProfileData((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  const handleOpenProfileDialog = (user) => {
    if (!user?.id) return;
    fetchRepById({
      id: user.id,
      callBack: () => {
        setIsOpenProfileDialog(true);
      },
    });
  };

  const handleCloseProfileDialog = () => {
    setIsOpenProfileDialog(false);
    dispatch(
      customersByRepGetParams(initialCustomersState.customersByRepGetParams)
    );
    setProfileData({ profile: null, loading: false });
  };

  const handleSetCheckedUser = useCallback(
    (user) => {
      const userIndex = checkedUsers.findIndex(
        (checkedUser) => checkedUser.id === user.id
      );
      if (userIndex > -1) {
        const newUsers = [...checkedUsers];
        newUsers.splice(userIndex, 1);
        setCheckedUsers(newUsers);
        return;
      }
      setCheckedUsers([...checkedUsers, user]);
    },
    [checkedUsers]
  );

  const handleCancel = useCallback(() => {
    setCheckedUsers([]);
    setAllChecked(false);
  }, []);

  const usersList = useMemo(
    () => (showInactive ? repsInactiveList : repsList),
    // if (currentTab === "All") return list;
    // return list.filter((item) => item.role === currentTab.toUpperCase());
    [repsList, showInactive, repsInactiveList]
  );

  const preparedUsersList = useMemo(
    () =>
      repsList.length
        ? repsList.filter((rep) => rep.role !== "THIRD_PARTY")
        : [],
    [repsList]
  );

  const usersCount = useMemo(
    () => (showInactive ? repsInactiveCount : salesCount + merchandisersCount),

    [showInactive, repsInactiveCount, salesCount, merchandisersCount]
  );

  const getUsersByRole = useCallback(
    (currentTab) => {
      switch (currentTab) {
        case "All":
          return preparedUsersList;
        case "Sales":
          return salesReps;
        case "Merchandisers":
          return merchandisersReps;
        case "3rd Party":
          return thirdPartyReps;
      }
    },
    [merchandisersReps, salesReps, thirdPartyReps, preparedUsersList]
  );

  const usersByRole = useMemo(
    () => (showInactive ? usersList : getUsersByRole(currentTab)),
    [showInactive, usersList, getUsersByRole, currentTab]
  );

  const checkAllUsers = useCallback(() => {
    if (checkedUsers?.length === usersByRole?.length) {
      setAllChecked(false);
      return setCheckedUsers([]);
    }
    setAllChecked(true);
    setCheckedUsers([...usersByRole]);
  }, [checkedUsers?.length, usersByRole]);

  useEffect(() => {
    if (usersByRole?.length && checkedUsers?.length === usersByRole?.length) {
      return setAllChecked(true);
    }
    setAllChecked(false);
  }, [checkedUsers, dispatch, usersByRole]);

  const getRepsCounter = (currentTab) => {
    switch (currentTab) {
      case "Sales":
        return salesCount;
      case "Merchandisers":
        return merchandisersCount;
      case "3rd Party":
        return thirdPartyCount;
      case "All":
        return usersCount;
    }
  };

  const handleResetLayout = useCallback(async () => {
    const { rep_all } = initialState.column_layouts;

    try {
      const res = await updateDistributorSettingsService({
        representativeLayoutSettings: { ...rep_all },
      });
      dispatch(setRepAllColumnLayoutsAction(res?.representativeLayoutSettings));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    }
  }, [dispatch]);

  // It should reset the columns to the initial state if their counts don't match
  const savedColumnsCount = {
    rep_all:
      (currentUser?.representativeLayoutSettings?.disable?.length || 0) +
      (currentUser?.representativeLayoutSettings?.main?.length || 0),
  };
  const initColumnsCount = {
    rep_all:
      initialState.column_layouts.rep_all.disable?.length +
      initialState.column_layouts.rep_all.main?.length,
  };

  const changeCurrentTab = (tab) => {
    setCurrentTab(tab);
    setCheckedUsers([]);
  };

  useEffect(() => {
    if (savedColumnsCount.rep_all !== initColumnsCount.rep_all) {
      if (repPermissions || isAdmin) return;
      handleResetLayout();
    }
  }, [
    repPermissions,
    handleResetLayout,
    initColumnsCount.rep_all,
    isAdmin,
    savedColumnsCount.rep_all,
  ]);

  const {
    QUICK_ACTIONS,
    MULTIPLE_ACTIONS,
    confirmDialogOpen,
    confirmText,
    setConfirmDialogOpen,
    transferOpen,
    setTransferOpen,
    isOpenReportDialog,
    handleCloseReportDialog,
    currentReport,
    handleConfirmDeleteTransfer,
  } = useRepsActions({
    checkedUsers,
    setCheckedUsers,
    currentTab,
  });

  const existCurrentData = useMemo(
    () => (showInactive ? repsExistInactive : repsExistActive),
    [showInactive, repsExistActive, repsExistInactive]
  );

  const { handleSetFavorite, reportState } = useReportPage();

  return (
    <>
      <ConfirmDialog
        isOpen={confirmDialogOpen}
        title={confirmText.title}
        text={confirmText.text}
        confirmColor={confirmText.confirmColor}
        onCancel={() => setConfirmDialogOpen(false)}
        onAccept={confirmText.onAccept}
      />

      <TransferPopup
        checkedCustomersArr={[]}
        isOpen={transferOpen}
        handleClose={() => setTransferOpen(false)}
        checkedReps={checkedUsers}
        deletingReps
        handleConfirmTransfer={handleConfirmDeleteTransfer}
      />

      <ReportDialog
        open={isOpenReportDialog}
        onClose={handleCloseReportDialog}
        report={currentReport}
        isCustom
        handleSetFavorite={handleSetFavorite}
        disabledCustomReportBtn={reportState.loading}
      />

      <PageTabComponent
        title={
          <Box component="span" color="primary">
            Representatives
          </Box>
        }
        tabs={TABS}
        addBtnName="Add Representative"
        currentTab={currentTab}
        handleSetCurrentTab={changeCurrentTab}
        {...{ setCheckedUsers, repPermissions }}
      />
      {currentTab !== "3rd Party" ? (
        <Box paddingX="32px" pt="12px">
          <SalesRepTotalSummaries
            isLoading={repsTopSummariesLoading}
            totalSummaries={totalSummaries}
          />
        </Box>
      ) : (
        ""
      )}
      <NewRepresentativesFilter
        checkedUsers={checkedUsers}
        setCheckedUsers={setCheckedUsers}
        handleCancel={handleCancel}
        currentTab={currentTab}
        salesCount={salesCount}
        merchandisersCount={merchandisersCount}
        thirdPartyCount={thirdPartyCount}
        existCurrentData={existCurrentData}
      />

      <ListSalesComponent
        handleSetCheckedUser={handleSetCheckedUser}
        users={usersByRole}
        usersCount={showInactive ? usersCount : getRepsCounter(currentTab)}
        handleOpenProfileDialog={handleOpenProfileDialog}
        checkedUsers={checkedUsers}
        checkAllUsers={checkAllUsers}
        allChecked={allChecked}
        currentTab={currentTab}
        showInactive={showInactive}
        repPermissions={repPermissions}
        {...{
          setCheckedUsers,
          QUICK_ACTIONS,
          MULTIPLE_ACTIONS,
          repsExistActive: existCurrentData,
        }}
      />
      <ProfileComponent
        profile={profileData.profile}
        isOpenProfileDialog={isOpenProfileDialog}
        handleCloseProfileDialog={handleCloseProfileDialog}
        currentMainTab={currentTab}
      />
      <Outlet />
    </>
  );
};

export default RepresentativesPage;
