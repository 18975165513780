import { array, object, func, number, string, bool } from "prop-types";
import Grid from "@mui/material/Grid";
import { Controller } from "react-hook-form";
import useStyles, { cl } from "../CategoriesComponent/styles";
import { ManufacturerItem } from "./components/ManufacturerItem";
import { StyledAutocomplete } from "components/TextFields/TextFields";
import { ActionRestrictionWrapper } from "components";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";
import { useRepsPermissions } from "helpers/hooks";

export const ManufacturerBlock = ({
  manufacturers,
  manufacturersCount,
  handleFetchManufacturers,
  setCustomersSearchInput,
  customersSearchInput,
  setIsOpenNewManDialog,
  setManufacturersPage = () => {},
  setValue,
  control,
  fontSize = "14px",
  formSx = {},
  labelSx = {},
  loading = false,
  manufacturersPage,
}) => {
  const classes = useStyles();
  const repPermissions = useRepsPermissions();

  return (
    <Grid xs={12} item position="relative">
      <Controller
        render={({ field, fieldState: { error } }) => {
          return (
            <StyledAutocomplete
              list={manufacturers}
              error={error ? error.message : ""}
              noErrorMessage
              formSx={formSx}
              CustomItem={
                <ActionRestrictionWrapper
                  disableHoverListener={
                    !repPermissions || repPermissions?.catalog?.manufacturers
                  }
                  title={
                    repPermissions?.role === "SALES"
                      ? ADMIN_ONLY_VIEW_MESSAGE
                      : ADMIN_ONLY_VIEW_MESSAGE_PAGE
                  }
                >
                  <ManufacturerItem
                    disabled={
                      !!repPermissions &&
                      !repPermissions?.catalog?.manufacturers
                    }
                    isAdd
                    onClick={() => {
                      setCustomersSearchInput("");
                      setManufacturersPage(1);
                      setIsOpenNewManDialog(true);
                    }}
                  />
                </ActionRestrictionWrapper>
              }
              labelSx={labelSx}
              emptyPlaceholder="No manufacturers found"
              data-testid="manufacturer"
              label="Manufacturer *"
              dataCount={manufacturersCount}
              childItemProps={{
                isAdd: false,
                onClick: (item) => {
                  setValue("manufacturer", item, {
                    shouldDirty: true,
                  });
                  setCustomersSearchInput("");
                  setManufacturersPage(1);
                },
              }}
              handleFetch={() =>
                handleFetchManufacturers(manufacturersPage + 1)
              }
              dataLength={manufacturers.length}
              loading={loading}
              ChildItem={ManufacturerItem}
              className={classes.textfield}
              value={
                customersSearchInput ? customersSearchInput : field?.value?.name
              }
              onChange={(e) => {
                !!setManufacturersPage && setManufacturersPage(1);
                setCustomersSearchInput(e.target.value);
                setValue("manufacturer", null);
              }}
              showClearIcon={
                (!!customersSearchInput && !field?.value) ||
                (!customersSearchInput && !!field?.value)
              }
              onClear={() => {
                setCustomersSearchInput("");
                setManufacturersPage(1);
                setValue("manufacturer", null);
              }}
              InputProps={{ fontSize }}
              sx={cl.searchInput}
              hideEmptyPlaceholder
            />
          );
        }}
        name="manufacturer"
        control={control}
      />
    </Grid>
  );
};

ManufacturerBlock.propTypes = {
  manufacturers: array,
  manufacturersCount: number,
  manufacturersPage: number,
  handleFetchManufacturers: func,
  setCustomersSearchInput: func,
  customersSearchInput: string,
  setIsOpenNewManDialog: func,
  setManufacturersPage: func,
  formField: object,
  setValue: func,
  control: object,
  formSx: object,
  labelSx: object,
  fontSize: string,
  loading: bool,
};
