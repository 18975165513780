/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";

export const CompletedTaskSmallIcon = ({
  color = "#A5A5A5",
  width = 12,
  height = 14,
  ...props
}) => (
  <SvgIcon
    viewBox="0 0 12 14"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fill: "none", width, height }}
    {...props}
  >
    <path
      d="M3.62095 7.64286H8.36285M3.62095 10H8.36285M3.77901 13H8.20479C9.39698 13 9.99307 13 10.3634 12.62C10.7338 12.2401 10.7338 11.6285 10.7338 10.4054V4.56741C10.7338 3.34431 10.8467 1.75994 10.4763 1.37997C10.106 1 9.39698 1 8.20479 1H3.77901C2.58683 1 1.99073 1 1.62037 1.37997C1.25 1.75994 1.25 2.37149 1.25 3.59459V10.4054C1.25 11.6285 1.25 12.2401 1.62037 12.62C1.99073 13 2.58683 13 3.77901 13Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.74994 4.41352L5.45132 5.07155L7.36506 3"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
