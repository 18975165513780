/* eslint-disable react/prop-types */
import { Avatar, Badge, Box, Typography } from "@mui/material";
import {
  NOTIFICATION_TYPES,
  SETTING_MERCHANDISER_TABS,
  SETTING_SALES_TABS,
} from "./NotificationCenter.constants";
import { cl } from "./components/NotificationItem/NotificationItem.styles";
import { OrdersIcon, RoutesIcon } from "components/Icons/NavbarIcons";
//import { FeedbackIcon } from "components/Icons/Activities";
import { PencilIcon } from "components/Icons";
import moment from "moment-timezone";
import pluralize from "pluralize";
import { ClockIcon } from "components/Icons/Notifications/ClockIcon";
import { StoreIcon } from "components/Icons/Notifications/StoreIcon";
import { TaskIcon } from "components/Icons/Notifications/TaskIcon";
import { ORDER_SWITCHES } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfile.constants";
import { initialTasksState } from "redux/reducers/tasks";
import { initialCustomersState } from "redux/reducers/customers";
import { sortKeys } from "utils/sortRepresentativePermissions";
import { WarningIcon } from "components/Icons/Notifications/WarningIcon";

const getAvatarName = (name) => {
  if (!name) return "";
  return name.split(" ")[1]
    ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
    : name[0];
};

const BoldText = ({ onClick, children }) => (
  <Box
    component="span"
    onClick={(e) => !!onClick && onClick(e)}
    sx={{
      fontWeight: 500,
      transition: "all 0.3s",
      "&:hover": {
        transition: "all 0.3s",
        cursor: onClick ? "pointer" : "default",
        textDecoration: onClick ? "underline" : "none",
      },
    }}
  >
    {children}
  </Box>
);

export const NOTIFICATION_TYPE_ICONS = {
  ///ORDERS
  [NOTIFICATION_TYPES.ORDER_CREATED]: (
    <OrdersIcon width={10.5} height={15} strokeColor="#A5A5A5" />
  ),
  [NOTIFICATION_TYPES.ORDER_PAID]: (
    <OrdersIcon width={10.5} height={15} strokeColor="#A5A5A5" />
  ),
  [NOTIFICATION_TYPES.ORDER_CANCELED]: (
    <OrdersIcon width={10.5} height={15} strokeColor="#A5A5A5" />
  ),
  [NOTIFICATION_TYPES.ONLINE_ORDER_CREATED]: (
    <OrdersIcon width={20} height={30} strokeColor="#A5A5A5" />
  ),
  [NOTIFICATION_TYPES.ONLINE_ORDER_CANCELLED]: (
    <OrdersIcon width={20} height={30} strokeColor="#A5A5A5" />
  ),
  [NOTIFICATION_TYPES.ONLINE_ORDER_PAID]: (
    <OrdersIcon width={20} height={30} strokeColor="#A5A5A5" />
  ),

  ///FULFILLMENTS
  [NOTIFICATION_TYPES.FULFILLMENT_OVERDUE]: (
    <OrdersIcon width={20} height={30} strokeColor="#A5A5A5" />
  ),

  ///PHOTO GROUP
  [NOTIFICATION_TYPES.PHOTO_GROUP_COMMENTED]: (
    <PencilIcon sx={{ width: 14, height: 14 }} />
  ),

  ///NOTES
  [NOTIFICATION_TYPES.NOTE_ADDED]: (
    <PencilIcon sx={{ width: 14, height: 14 }} />
  ),

  ///TASKS
  [NOTIFICATION_TYPES.TASK_ASSIGNED]: (
    <TaskIcon sx={{ width: 13, height: 15, fill: "none" }} />
  ),
  [NOTIFICATION_TYPES.TASK_UNASSIGNED]: (
    <TaskIcon sx={{ width: 13, height: 15, fill: "none" }} />
  ),
  [NOTIFICATION_TYPES.TASK_DELETED]: (
    <TaskIcon sx={{ width: 13, height: 15, fill: "none" }} />
  ),
  [NOTIFICATION_TYPES.TASK_COMPLETED]: (
    <TaskIcon sx={{ width: 13, height: 15, fill: "none" }} />
  ),
  [NOTIFICATION_TYPES.TASK_OVERDUE]: <ClockIcon />,

  ///ROUTES
  [NOTIFICATION_TYPES.ROUTE_EDITED]: (
    <RoutesIcon strokeParams={{ stroke: "#A5A5A5" }} width={20} height={20} />
  ),
  [NOTIFICATION_TYPES.ROUTE_ASSIGNED]: (
    <RoutesIcon strokeParams={{ stroke: "#A5A5A5" }} width={20} height={20} />
  ),
  [NOTIFICATION_TYPES.ROUTE_UNASSIGNED]: (
    <RoutesIcon strokeParams={{ stroke: "#A5A5A5" }} width={20} height={20} />
  ),
  [NOTIFICATION_TYPES.ROUTE_DELETED]: (
    <RoutesIcon strokeParams={{ stroke: "#A5A5A5" }} width={20} height={20} />
  ),
  [NOTIFICATION_TYPES.ROUTE_DEACTIVATED]: (
    <RoutesIcon strokeParams={{ stroke: "#A5A5A5" }} width={20} height={20} />
  ),
  [NOTIFICATION_TYPES.ROUTE_ACTIVATED]: (
    <RoutesIcon strokeParams={{ stroke: "#A5A5A5" }} width={20} height={20} />
  ),
  [NOTIFICATION_TYPES.ROUTE_COMPLETED]: (
    <RoutesIcon strokeParams={{ stroke: "#A5A5A5" }} width={20} height={20} />
  ),

  ///CUSTOMERS
  [NOTIFICATION_TYPES.CUSTOMER_ASSIGNED]: <StoreIcon width={15} height={15} />,
  [NOTIFICATION_TYPES.CUSTOMER_UNASSIGNED]: (
    <StoreIcon width={15} height={15} />
  ),

  ///PAYMENTS
  [NOTIFICATION_TYPES.PAYMENT_OVERDUE]: (
    <OrdersIcon width={20} height={30} strokeColor="#A5A5A5" />
  ),

  ///LICENSE
  [NOTIFICATION_TYPES.LICENSE_EXPIRED]: <WarningIcon />,
};

export const getNotificationAvatar = (item, avatarProps = {}) => {
  const { type, creator, customer } = item || {};

  const creatorAvatar =
    creator?.representativeDuplicate?.profilePhoto?.fileName ||
    creator?.appCustomer?.profilePhoto?.fileName ||
    creator?.distributor?.profilePhoto?.fileName;

  const createdBy =
    creator?.representativeDuplicate?.name ||
    creator?.appCustomer?.name ||
    creator?.distributor?.name;

  const isOnlineOrder =
    !!creator?.appCustomer || type === NOTIFICATION_TYPES.ORDER_PAID;

  const avatarName = getAvatarName(createdBy);

  switch (type) {
    case NOTIFICATION_TYPES.NOTE_ADDED:
    case NOTIFICATION_TYPES.ORDER_CREATED:
    case NOTIFICATION_TYPES.ORDER_PAID:
    case NOTIFICATION_TYPES.ORDER_CANCELED:
    case NOTIFICATION_TYPES.ROUTE_DELETED:
    case NOTIFICATION_TYPES.ROUTE_ASSIGNED:
    case NOTIFICATION_TYPES.ROUTE_EDITED:
    case NOTIFICATION_TYPES.ROUTE_COMPLETED:
    case NOTIFICATION_TYPES.ROUTE_UNASSIGNED:
    case NOTIFICATION_TYPES.ROUTE_DEACTIVATED:
    case NOTIFICATION_TYPES.ROUTE_ACTIVATED:
    case NOTIFICATION_TYPES.TASK_ASSIGNED:
    case NOTIFICATION_TYPES.TASK_DELETED:
    case NOTIFICATION_TYPES.TASK_COMPLETED:
    case NOTIFICATION_TYPES.TASK_UNASSIGNED:
    case NOTIFICATION_TYPES.CUSTOMER_ASSIGNED:
    case NOTIFICATION_TYPES.CUSTOMER_UNASSIGNED:
    case NOTIFICATION_TYPES.PHOTO_GROUP_COMMENTED:
      return (
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={cl.avatarBadge}
          color="white"
          badgeContent={NOTIFICATION_TYPE_ICONS[type]}
        >
          <Avatar
            sx={cl.avatarWrapper}
            alt={avatarName}
            src={creatorAvatar}
            {...avatarProps}
          >
            {isOnlineOrder ? <StoreIcon width={24} height={24} /> : avatarName}
          </Avatar>
        </Badge>
      );
    case NOTIFICATION_TYPES.FULFILLMENT_OVERDUE:
    case NOTIFICATION_TYPES.PAYMENT_OVERDUE:
    case NOTIFICATION_TYPES.LICENSE_EXPIRED:
      return (
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={cl.avatarBadge}
          color="white"
          badgeContent={NOTIFICATION_TYPE_ICONS[type]}
        >
          <Avatar
            sx={cl.avatarWrapper}
            alt={customer.displayedName}
            src={creatorAvatar}
            {...avatarProps}
          >
            <StoreIcon width={24} height={24} />
          </Avatar>
        </Badge>
      );
    case NOTIFICATION_TYPES.TASK_OVERDUE:
      return (
        <Badge
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={cl.avatarBadge}
          color="white"
          badgeContent={NOTIFICATION_TYPE_ICONS[type]}
        >
          <Avatar
            sx={cl.avatarWrapper}
            alt={avatarName}
            src={creatorAvatar}
            {...avatarProps}
          >
            <TaskIcon width={19.5} height={27} />
          </Avatar>
        </Badge>
      );
    default:
      break;
  }
};

export const getNotificationText = ({
  item,
  navigate,
  drawerCallback,
  currentUser,
  repPermissions,
}) => {
  const {
    type,
    creator,
    customer,
    customerName,
    title,
    order,
    route,
    count,
    photoGroupComment,
  } = item || {};

  const createdBy =
    creator?.representativeDuplicate?.name ||
    creator?.appCustomer?.name ||
    "Admin";

  const isOnlineOrder = !!creator?.appCustomer;

  const assignedTaskRedirect = (e) => {
    if (type === NOTIFICATION_TYPES.TASK_ASSIGNED) {
      return navigate(e, "/tasks", {
        state: {
          taskFilters: {
            representative_id: {
              iconId: undefined,
              label: "Assigned to",
              name: repPermissions
                ? currentUser.backOfficeRepresentative?.name
                : "Admin",
              value: repPermissions
                ? repPermissions.representativeId
                : currentUser.id,
            },
          },
          taskSwitchers: [
            { ...initialTasksState.tasksSwitch[0], checked: true },
            initialTasksState.tasksSwitch[1],
            initialTasksState.tasksSwitch[2],
          ],
          stateFilter: {
            representative_id: repPermissions
              ? repPermissions.representativeId
              : currentUser.id,
          },
        },
      });
    }

    if (NOTIFICATION_TYPES.TASK_OVERDUE) {
      return navigate(e, "/tasks", {
        state: {
          taskFilters: {
            representative_id: {
              iconId: undefined,
              label: "Assigned to",
              name: repPermissions
                ? currentUser.backOfficeRepresentative?.name
                : "Admin",
              value: repPermissions
                ? repPermissions.representativeId
                : currentUser.id,
            },
            due_date: {
              label: "Due date",
              name: "Overdue",
              value: "Overdue",
            },
          },
          taskSwitchers: [
            { ...initialTasksState.tasksSwitch[0], checked: true },
            { ...initialTasksState.tasksSwitch[1], checked: true },
            initialTasksState.tasksSwitch[2],
          ],
          stateFilter: {
            due_date: "overdue",
            representative_id: repPermissions
              ? repPermissions.representativeId
              : currentUser.id,
          },
        },
      });
    }

    return null;
  };

  const toCustomerNavigate = (e, params = {}) => {
    if (customer?.id) return navigate(e, `/customers/${customer.id}`, params);

    return;
  };

  const toCustomerOverdue = (e) =>
    toCustomerNavigate(e, {
      state: {
        orderFilters: {
          payment_due: "overdue",
          payment_status: ["UNPAID"],
        },
        orderSwitchers: [{ ...ORDER_SWITCHES[3], checked: true }],
      },
    });

  const toCustomerByRep = (e) => {
    if (repPermissions.representativeId) return;
    navigate(e, "/customers", {
      state: {
        customersFilters: {
          representative_id: {
            iconId: undefined,
            label: "Assigned to",
            name: repPermissions
              ? currentUser.backOfficeRepresentative?.name
              : "Admin",
            value: repPermissions
              ? repPermissions.representativeId
              : currentUser.id,
          },
        },
        customersSwitchers: [
          { ...initialCustomersState.customersSwitch[0], checked: true },
        ],
        stateFilter: {
          representative_id: repPermissions
            ? repPermissions.representativeId
            : currentUser.id,
        },
      },
    });

    return navigate(e, "/customers");
  };

  switch (type) {
    ///ORDERS
    case NOTIFICATION_TYPES.ORDER_CREATED:
      return (
        <>
          {isOnlineOrder ? (
            <Typography variant="body2" sx={cl.text}>
              <BoldText onClick={(e) => toCustomerNavigate(e)}>
                {createdBy}
              </BoldText>{" "}
              placed an{" "}
              <BoldText onClick={(e) => navigate(e, `/orders/${order.id}`)}>
                online order
              </BoldText>
            </Typography>
          ) : (
            <Typography variant="body2" sx={cl.text}>
              <BoldText>{createdBy}</BoldText> placed an order at{" "}
              <BoldText onClick={(e) => toCustomerNavigate(e)}>
                {customerName}
              </BoldText>
            </Typography>
          )}
        </>
      );
    case NOTIFICATION_TYPES.ORDER_CANCELED:
      return (
        <Typography variant="body2" sx={cl.text}>
          <BoldText>{createdBy}</BoldText> canceled{" "}
          <BoldText onClick={(e) => navigate(e, `/orders/${order.id}`)}>
            order (#{order?.customId?.customId || title})
          </BoldText>
        </Typography>
      );
    case NOTIFICATION_TYPES.ORDER_PAID:
      return (
        <Typography variant="body2" sx={cl.text}>
          <BoldText onClick={(e) => navigate(e, `/orders/${order.id}`)}>
            Online order (#{order?.customId?.customId || title})
          </BoldText>{" "}
          paid
        </Typography>
      );
    case NOTIFICATION_TYPES.ONLINE_ORDER_CREATED:
      return (
        <Typography variant="body2" sx={cl.text}>
          <BoldText onClick={(e) => toCustomerNavigate(e)}>
            {customerName}
          </BoldText>{" "}
          placed an online order
        </Typography>
      );
    case NOTIFICATION_TYPES.ONLINE_ORDER_CANCELLED:
      return (
        <Typography variant="body2" sx={cl.text}>
          <BoldText onClick={(e) => toCustomerNavigate(e)}>
            {customerName}
          </BoldText>{" "}
          placed an online order
        </Typography>
      );

    ///FULFILLMENTS
    case NOTIFICATION_TYPES.FULFILLMENT_OVERDUE:
      return (
        <Typography variant="body2" sx={cl.text}>
          <BoldText onClick={(e) => toCustomerNavigate(e)}>
            {customerName}
          </BoldText>{" "}
          <BoldText>{count}</BoldText> overdue {pluralize("fulfillment", count)}
        </Typography>
      );

    ///PAYMENTS
    case NOTIFICATION_TYPES.PAYMENT_OVERDUE:
      return (
        <Typography variant="body2" sx={cl.text}>
          <BoldText onClick={(e) => toCustomerOverdue(e)}>
            {customerName}
          </BoldText>{" "}
          <BoldText onClick={(e) => toCustomerOverdue(e)}>{count}</BoldText>{" "}
          overdue {pluralize("payment", count)}
        </Typography>
      );

    ///LICENSE
    case NOTIFICATION_TYPES.LICENSE_EXPIRED:
      return (
        <Typography variant="body2" sx={cl.text}>
          <BoldText onClick={(e) => toCustomerNavigate(e)}>
            {customerName}
          </BoldText>{" "}
          has an expired{" "}
          <BoldText onClick={(e) => toCustomerNavigate(e)}>license</BoldText>
        </Typography>
      );

    ///NOTES
    case NOTIFICATION_TYPES.NOTE_ADDED:
      return (
        <Typography variant="body2" sx={cl.text}>
          <BoldText>{createdBy}</BoldText> added{" "}
          <BoldText onClick={drawerCallback}>note</BoldText> to{" "}
          <BoldText onClick={(e) => toCustomerNavigate(e)}>
            {customerName}
          </BoldText>
        </Typography>
      );

    ///PHOTO GROUP
    case NOTIFICATION_TYPES.PHOTO_GROUP_COMMENTED:
      return (
        <Typography variant="body2" sx={cl.text}>
          <BoldText>{createdBy}</BoldText> commented on a{" "}
          <BoldText onClick={photoGroupComment ? drawerCallback : null}>
            photo
          </BoldText>{" "}
          taken at{" "}
          <BoldText onClick={(e) => toCustomerNavigate(e)}>
            {customerName}
          </BoldText>
        </Typography>
      );

    ///TASKS
    case NOTIFICATION_TYPES.TASK_ASSIGNED:
      return (
        <>
          {count > 1 ? (
            <Typography variant="body2" sx={cl.text}>
              <BoldText onClick={(e) => assignedTaskRedirect(e)}>
                {count} of tasks{" "}
              </BoldText>
              assigned to you by <BoldText>{createdBy}</BoldText>
            </Typography>
          ) : (
            <Typography variant="body2" sx={cl.text}>
              <BoldText onClick={drawerCallback}>Task</BoldText> assigned to you{" "}
              by <BoldText>{createdBy}</BoldText>
            </Typography>
          )}
        </>
      );
    case NOTIFICATION_TYPES.TASK_UNASSIGNED:
      return (
        <>
          {count > 1 ? (
            <Typography variant="body2" sx={cl.text}>
              <BoldText>{count} of tasks </BoldText>
              unassigned from you by <BoldText>{createdBy}</BoldText>
            </Typography>
          ) : (
            <Typography variant="body2" sx={cl.text}>
              <BoldText>Task</BoldText> unassigned from you by{" "}
              <BoldText>{createdBy}</BoldText>
            </Typography>
          )}
        </>
      );
    case NOTIFICATION_TYPES.TASK_COMPLETED:
      return (
        <>
          {count > 1 ? (
            <Typography variant="body2" sx={cl.text}>
              <BoldText>
                {count} {pluralize("task", count)}
              </BoldText>{" "}
              completed by <BoldText>{createdBy}</BoldText> via bulk action
            </Typography>
          ) : (
            <Typography variant="body2" sx={cl.text}>
              Assigned <BoldText onClick={drawerCallback}>task</BoldText>{" "}
              completed by <BoldText>{createdBy}</BoldText>
            </Typography>
          )}
        </>
      );
    case NOTIFICATION_TYPES.TASK_DELETED:
      return (
        <>
          {count > 1 ? (
            <Typography variant="body2" sx={cl.text}>
              <BoldText>{count} tasks</BoldText> deleted by{" "}
              <BoldText>{createdBy}</BoldText> via bulk action
            </Typography>
          ) : (
            <Typography variant="body2" sx={cl.text}>
              Assigned <BoldText>task</BoldText> deleted by{" "}
              <BoldText>{createdBy}</BoldText>
            </Typography>
          )}
        </>
      );
    case NOTIFICATION_TYPES.TASK_OVERDUE:
      return (
        <>
          {count > 1 ? (
            <Typography variant="body2" sx={cl.text}>
              You have{" "}
              <BoldText onClick={(e) => assignedTaskRedirect(e)}>
                {count}
              </BoldText>{" "}
              overdue <BoldText>tasks</BoldText>
            </Typography>
          ) : (
            <Typography variant="body2" sx={cl.text}>
              You have an overdue{" "}
              <BoldText onClick={drawerCallback}>task</BoldText>
            </Typography>
          )}
        </>
      );

    ///ROUTES
    case NOTIFICATION_TYPES.ROUTE_ASSIGNED:
    case NOTIFICATION_TYPES.ROUTE_UNASSIGNED:
      return (
        <Typography variant="body2" sx={cl.text}>
          <BoldText
            onClick={(e) => !!route && navigate(e, `/routes/view/${route.id}`)}
          >
            Route: {title || route?.name}
          </BoldText>
          {type === NOTIFICATION_TYPES.ROUTE_ASSIGNED
            ? " assigned to you"
            : " unassigned from you"}{" "}
          by <BoldText>{createdBy}</BoldText>
        </Typography>
      );
    case NOTIFICATION_TYPES.ROUTE_EDITED:
      return (
        <Typography variant="body2" sx={cl.text}>
          Assigned{" "}
          <BoldText
            onClick={(e) => !!route && navigate(e, `/routes/view/${route.id}`)}
          >
            route
          </BoldText>{" "}
          edited by <BoldText>{createdBy}</BoldText>
        </Typography>
      );
    case NOTIFICATION_TYPES.ROUTE_COMPLETED:
      return (
        <Typography variant="body2" sx={cl.text}>
          Assigned{" "}
          <BoldText
            onClick={(e) => !!route && navigate(e, `/routes/view/${route.id}`)}
          >
            route
          </BoldText>{" "}
          completed by <BoldText>{createdBy}</BoldText>
        </Typography>
      );
    case NOTIFICATION_TYPES.ROUTE_DELETED:
      return (
        <>
          {count > 1 ? (
            <Typography variant="body2" sx={cl.text}>
              <BoldText>{count} of routes </BoldText>
              deleted by <BoldText>{createdBy}</BoldText> via bulk action
            </Typography>
          ) : (
            <Typography variant="body2" sx={cl.text}>
              Assigned <BoldText>route</BoldText> deleted by{" "}
              <BoldText>{createdBy}</BoldText>
            </Typography>
          )}
        </>
      );
    case NOTIFICATION_TYPES.ROUTE_DEACTIVATED:
      return (
        <>
          {count > 1 ? (
            <Typography variant="body2" sx={cl.text}>
              <BoldText>{count} routes </BoldText>
              deactivated by <BoldText>{createdBy}</BoldText> via bulk action
            </Typography>
          ) : (
            <Typography variant="body2" sx={cl.text}>
              Assigned{" "}
              <BoldText
                onClick={(e) =>
                  !!route && navigate(e, `/routes/view/${route.id}`)
                }
              >
                route
              </BoldText>{" "}
              deactivated by <BoldText>{createdBy}</BoldText>
            </Typography>
          )}
        </>
      );

    case NOTIFICATION_TYPES.ROUTE_ACTIVATED:
      return (
        <>
          {count > 1 ? (
            <Typography variant="body2" sx={cl.text}>
              <BoldText>{count} routes </BoldText>
              activated by <BoldText>{createdBy}</BoldText> via bulk action
            </Typography>
          ) : (
            <Typography variant="body2" sx={cl.text}>
              Assigned{" "}
              <BoldText
                onClick={(e) =>
                  !!route && navigate(e, `/routes/view/${route.id}`)
                }
              >
                route
              </BoldText>{" "}
              activated by <BoldText>{createdBy}</BoldText>
            </Typography>
          )}
        </>
      );

    ///CUSTOMERS
    case NOTIFICATION_TYPES.CUSTOMER_ASSIGNED:
    case NOTIFICATION_TYPES.CUSTOMER_UNASSIGNED:
      return (
        <>
          {count > 1 ? (
            <Typography variant="body2" sx={cl.text}>
              <BoldText onClick={(e) => toCustomerByRep(e)}>
                {count} of stores
              </BoldText>
              {type === NOTIFICATION_TYPES.CUSTOMER_ASSIGNED
                ? " assigned to you"
                : " unassigned from you"}{" "}
              by <BoldText>{createdBy}</BoldText>
            </Typography>
          ) : (
            <Typography variant="body2" sx={cl.text}>
              <BoldText onClick={(e) => toCustomerNavigate(e)}>
                {customerName}
              </BoldText>
              {type === NOTIFICATION_TYPES.CUSTOMER_ASSIGNED
                ? " assigned to you"
                : " unassigned from you"}{" "}
              by <BoldText>{createdBy}</BoldText>
            </Typography>
          )}
        </>
      );
    default:
      break;
  }
};

export const onDeleteActionCheck = (item) => {
  const { customer, route, task, type, count } = item || {};

  switch (type) {
    case NOTIFICATION_TYPES.TASK_OVERDUE:
    case NOTIFICATION_TYPES.TASK_ASSIGNED:
    case NOTIFICATION_TYPES.TASK_COMPLETED:
    case NOTIFICATION_TYPES.TASK_UNASSIGNED:
      return !task && count < 2;

    //case NOTIFICATION_TYPES.TASK_UNASSIGNED:
    //  return true;

    case NOTIFICATION_TYPES.ROUTE_EDITED:
    case NOTIFICATION_TYPES.ROUTE_ASSIGNED:
    case NOTIFICATION_TYPES.ROUTE_COMPLETED:
    case NOTIFICATION_TYPES.ROUTE_UNASSIGNED:
    case NOTIFICATION_TYPES.ROUTE_DEACTIVATED:
      return !route && count < 2;

    case NOTIFICATION_TYPES.CUSTOMER_ASSIGNED:
    case NOTIFICATION_TYPES.CUSTOMER_UNASSIGNED:
      return !customer && count < 2;

    default:
      return false;
  }
};

export const formatTime = (createdAt, timeZone) => {
  const now = moment().tz(timeZone);
  const date = moment.tz(createdAt, timeZone);

  const diffMinutes = now.diff(date, "minutes");
  const diffHours = now.diff(date, "hours");
  const diffDays = now.startOf("day").diff(date.startOf("day"), "days");

  const formattedTime = moment.tz(createdAt, timeZone).format("hh:mm a");
  const formattedDay = moment.tz(createdAt, timeZone).format("ddd");
  const formattedDate = moment.tz(createdAt, timeZone).format("MM/DD/YYYY");

  if (diffMinutes < 1) {
    return "now";
  } else if (diffMinutes < 60) {
    return `${diffMinutes}m ago`;
  } else if (diffHours < 3) {
    return `${diffHours}h ago`;
  } else if (diffDays === 0) {
    return formattedTime;
  } else if (diffDays === 1) {
    return `Yesterday at ${formattedTime}`;
  } else if (diffDays <= 7) {
    return `${formattedDay} \nat ${formattedTime}`;
  } else {
    return formattedDate;
  }
};

export const sortUserSettingsByRole = (data) => {
  const { permissions, role } = data;

  const ref =
    !role || role === "SALES" ? SETTING_SALES_TABS : SETTING_MERCHANDISER_TABS;

  return sortKeys(ref, permissions);
};
