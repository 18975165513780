import { array, func, bool, string } from "prop-types";
import {
  Box,
  CircularProgress,
  Collapse,
  DialogContent,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ShevronIcon, StyledProductIco } from "components/Icons";
import { photoUrl } from "helpers/helpers";
import QuantityPicker from "components/QuantityPicker";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  getMatchedByProduct,
  getProductId,
  getProductManufacturer,
  getProductManufacturerName,
} from "Pages/DiscountsPage/components/ApplyDiscountPage/components/MSDPage/MSDPage.helpers";
import { PulsingDiscount, StyledButton } from "components";
import pluralize from "pluralize";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

export const ContentBlock = ({
  list,
  discounts,
  handleAddPromotion,
  loading,
  cartProducts,
  calcProductPriceByPriceList,
  createOrderType = "",
  isEdit,
}) => {
  const theme = useTheme();

  const [openCollapse, setOpenCollapse] = useState({});
  const [isOpenCollapseApplied, setOpenCollapseApplied] = useState(false);
  const [tempList, setTempList] = useState([...list] || []);

  const handleUpdateList = useCallback(() => {
    setTempList([...list]);
  }, [list]);

  useEffect(handleUpdateList, [handleUpdateList]);

  const getCurrentDiscount = (product) => {
    const productManufacturer = getProductManufacturer(product);
    const currentDiscounts = discounts.filter(
      ({ manufacturer }) => manufacturer.id === productManufacturer
    );

    const currentDiscount = currentDiscounts.filter(
      ({ products, productType }) =>
        productType === "ALL_PRODUCTS" ||
        products.some(
          (discountProduct) =>
            (discountProduct?.productId || discountProduct?.product?.id) ===
            product?.id
        )
    );

    return currentDiscount?.[0];
  };

  const getCurrentManufacturerDiscounts = (product) => {
    const productManufacturer = getProductManufacturer(product);
    const currentDiscounts = discounts.filter(
      ({ manufacturer }) => manufacturer.id === productManufacturer
    );

    return currentDiscounts;
  };

  const getCurrentDiscounts = (product) => {
    const productManufacturer = getProductManufacturer(product);
    const currentDiscounts = discounts.filter((d) => {
      const matchedByManufacturer = d.manufacturer.id === productManufacturer;
      if (d.productType === "SPECIFIC_PRODUCTS") {
        return (
          matchedByManufacturer &&
          getMatchedByProduct({ discount: d, product, isEdit })
        );
      }
      return matchedByManufacturer;
    });

    return currentDiscounts;
  };

  const getCurrentManufacturerProductsQtySum = (product) => {
    const currentDiscounts = getCurrentDiscounts(product) ?? [];
    if (!currentDiscounts.length) return 0;

    let productsListWithAllocatedDiscountQty = [...tempList];

    const arrayOfQtySum = currentDiscounts.map((currentDiscount) => {
      let disProdIds = [];

      if (currentDiscount.productType === "ALL_PRODUCTS") {
        const discountManufacturerId = currentDiscount.manufacturer?.id;

        const currentManufacturerProducts = tempList.filter((listProd) => {
          const listProductManufacturerId = getProductManufacturer(listProd);
          return discountManufacturerId === listProductManufacturerId;
        });

        disProdIds = currentManufacturerProducts.map(
          (product) => product?.id || product?.product?.id
        );
      } else {
        disProdIds = currentDiscount.products.map((p) => getProductId(p));
      }

      const currentManufacturerProducts = tempList.filter(
        (listProd) =>
          disProdIds.includes(listProd?.id) &&
          getProductId(listProd) !== getProductId(product)
      );

      return currentManufacturerProducts.reduce(
        (prevSum, currentManufacturerProduct) => {
          const foundProductIndex =
            productsListWithAllocatedDiscountQty.findIndex(
              (p) =>
                getProductId(p) === getProductId(currentManufacturerProduct)
            );

          const productByIndex =
            foundProductIndex > -1
              ? productsListWithAllocatedDiscountQty[foundProductIndex]
              : null;

          const currentDiscountQty =
            (currentManufacturerProduct.discountQty ?? 0) -
            (productByIndex?.allocatedDiscountQty ?? 0);

          if (foundProductIndex > -1) {
            const newObj = {
              ...productByIndex,
              allocatedDiscountQty:
                (productByIndex?.allocatedDiscountQty ?? 0) +
                currentDiscountQty,
            };

            productsListWithAllocatedDiscountQty.splice(
              foundProductIndex,
              1,
              newObj
            );
          }

          return prevSum + (currentDiscountQty ?? 0);
        },
        0
      );
    });

    const currentProductWithDiscountedQuantity =
      productsListWithAllocatedDiscountQty.find(
        (listProd) => getProductId(listProd) === getProductId(product)
      );

    const sumOfQty = arrayOfQtySum.reduce((sum, qty) => sum + qty, 0);

    return sumOfQty + (currentProductWithDiscountedQuantity?.discountQty ?? 0);
  };

  const getCurrentManufacturerProductsQtySumBySpecificProducts = (
    product,
    currentManufacturerDiscounts
  ) => {
    const currentDiscount = getCurrentDiscount(product);

    const currentProductHasSpecificDiscount = currentManufacturerDiscounts.some(
      (d) =>
        d.productType === "SPECIFIC_PRODUCTS" &&
        getMatchedByProduct({ isEdit, discount: d, product })
    );

    const productsWithoutProductSpecificDiscounts = tempList.filter(
      (listProd) => {
        if (currentProductHasSpecificDiscount) {
          return (
            getProductManufacturer(listProd) === getProductManufacturer(product)
          );
        }

        return !currentManufacturerDiscounts.some(
          (d) =>
            d.productType === "SPECIFIC_PRODUCTS" &&
            getMatchedByProduct({ isEdit, discount: d, product: listProd })
        );
      }
    );

    if (!currentDiscount) return 0;

    let disProdIds = [];

    if (currentDiscount.productType === "ALL_PRODUCTS") {
      const disManId = currentDiscount.manufacturer?.id;

      const currentManufacturerProducts =
        productsWithoutProductSpecificDiscounts.filter((listProd) => {
          const listProdMan = getProductManufacturer(listProd);
          return disManId === listProdMan;
        });
      disProdIds = currentManufacturerProducts.map(
        (product) => product?.id || product?.product?.id
      );
    } else {
      disProdIds = currentDiscount.products.map(
        ({ product, productId }) =>
          productId || product?.id || product?.product?.id
      );
    }

    const currentManufacturerProducts = tempList.filter((listProd) =>
      disProdIds.includes(listProd?.id)
    );

    const sumCurrentManufacturerProducts = currentManufacturerProducts.reduce(
      (prevSum, { discountQty }) => prevSum + (discountQty || 0),
      0
    );

    return sumCurrentManufacturerProducts;
  };

  const getIsOutOfStock = (product) => {
    if (!product) return false;
    const { quantity } = cartProducts.find(
      ({ id, productId }) => id === product.id || productId === product.id
    ) || { quantity: 0 };

    const {
      sellingOutOfStock: continueSellingWhenOutOfStock,
      inventory,
      discountQty,
    } = product;
    const runOutOfStock =
      inventory?.onHand -
        inventory?.allocated -
        (discountQty || 0) -
        quantity <=
      0;

    return runOutOfStock && !continueSellingWhenOutOfStock;
  };

  const handleChangeQty = ({ product, isAdd, event }) => {
    setOpenCollapseApplied(true);
    const index = tempList.findIndex((prod) => prod.id === product.id);
    let newQty = product.discountQty || 0;
    if (event) {
      newQty = parseInt(event.target.value);
      const isOutOfStock = getIsOutOfStock({
        ...product,
        discountQty: newQty - 1,
      });
      if (isOutOfStock) return;
    } else {
      newQty = isAdd ? newQty + 1 : newQty - 1;
    }

    const productManufacturer = getProductManufacturer(product);

    const currentDiscounts = discounts.filter(
      ({ manufacturer }) => manufacturer.id === productManufacturer
    );

    const limit = currentDiscounts.reduce(
      (acc, cur) => acc + (cur?.quantityDiscount || 0) * (cur?.multiplier || 1),
      0
    );

    const currentManufacturerProductsQtySum =
      getCurrentManufacturerProductsQtySum(product, currentDiscounts);

    const newList = [...tempList];
    const oldDiscountQty = product.discountQty || 0;
    if (isAdd) {
      if (currentManufacturerProductsQtySum === limit) return;
    } else {
      if (!event && oldDiscountQty === 0) {
        return;
      }
      if (
        newQty < 0 ||
        newQty > limit ||
        currentManufacturerProductsQtySum - oldDiscountQty + newQty > limit
      ) {
        return;
      }
    }
    const newProduct = {
      ...product,
      discountQty: newQty,
    };

    newList.splice(index, 1, newProduct);
    setTempList(newList);
  };

  const sectionList = useMemo(
    () =>
      tempList.reduce((prevObj, prod) => {
        const newObj = { ...prevObj };
        const manufacturerId = getProductManufacturer(prod);
        newObj[manufacturerId] = newObj[manufacturerId]
          ? [...newObj[manufacturerId], prod]
          : [prod];
        return newObj;
      }, {}),
    [tempList]
  );

  useEffect(
    function openCollapseWhereAlreadyHaveNotDiscountsApplied() {
      if (isOpenCollapseApplied) return;

      Object.keys(sectionList).forEach((manufacturerId) => {
        const discountId = discounts.find(
          (discount) => discount?.manufacturer?.id === +manufacturerId
        )?.id;

        const expandIfOnlyOne = Object.keys(sectionList).length === 1;

        const alreadyAddedDiscountQty = discounts.reduce((acc) => {
          acc[discountId] = !sectionList[manufacturerId].some((product) =>
            expandIfOnlyOne ? !expandIfOnlyOne : +product?.discountQty > 0
          );
          return acc;
        }, {});

        setOpenCollapse((prev) => ({ ...prev, ...alreadyAddedDiscountQty }));
      });
    },
    [discounts, isOpenCollapseApplied, sectionList]
  );

  const handleSwitchCollapse = useCallback((discountId) => {
    setOpenCollapse((prev) => ({
      ...prev,
      [discountId]: !prev[discountId],
    }));
  }, []);

  return (
    <DialogContent
      sx={{
        pt: 0,
        pb: 4.25,
        px: 3.25,
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Box
        maxHeight="50%"
        overflow="auto"
        display="flex"
        flexDirection="column"
        gap="8px"
      >
        {loading ? (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          Object.keys(sectionList).map((manufacturerId) => {
            const list = sectionList[manufacturerId];

            const firstProd = list[0];
            const currentDiscount = getCurrentDiscount(firstProd);
            const currentManufacturerDiscounts =
              getCurrentManufacturerDiscounts(firstProd);

            const alreadyAddedQtyCount = list.reduce(
              (acc, cur) => acc + (cur?.discountQty || 0),
              0
            );

            const discountId = currentDiscount?.id;

            const calcAvailableQty = () => {
              return currentManufacturerDiscounts.reduce((acc, cur) => {
                return acc + (cur?.multiplier || 1) * cur?.quantityDiscount;
              }, 0);
            };
            const availableQty = calcAvailableQty();

            return (
              <Box
                key={manufacturerId}
                display="flex"
                flexDirection="column"
                gap="8px"
              >
                <Box
                  sx={{
                    background: "#EBF4EF",
                    p: "8px 15px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSwitchCollapse(discountId)}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <PulsingDiscount />
                    <Typography
                      fontSize="12px"
                      fontWeight={700}
                      color="#A5A5A5"
                    >
                      <Box color={theme.palette.primary2.main} component="span">
                        {alreadyAddedQtyCount} / {availableQty}
                      </Box>{" "}
                      Free {getProductManufacturerName(firstProd)}{" "}
                      {pluralize("Item", currentDiscount?.multiplier || 0)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      ...(openCollapse[discountId] && {
                        transform: "rotate(180deg)",
                        transition: "all 0.3s",
                      }),
                    }}
                  >
                    <ShevronIcon color="#707070" />
                  </Box>
                </Box>
                <Collapse in={openCollapse[discountId]} timeout={500}>
                  <Stack
                    sx={{ border: "0.5px solid #D5D9D9", borderRadius: "4px" }}
                  >
                    {sectionList[manufacturerId].map((product, index) => {
                      const productName =
                        product?.name || product?.parentProduct?.name;

                      const setProductType = () => {
                        return (
                          <>
                            {product?.sku}
                            {(product?.size || product?.color) &&
                              ` / ${product?.size || product?.color}`}
                          </>
                        );
                      };

                      const productType = setProductType();

                      const listCountIndexes =
                        sectionList[manufacturerId]?.length - 1;

                      const discountQty = product.discountQty || 0;

                      const isApplied = discountQty > 0;

                      const qtySumAll =
                        getCurrentManufacturerProductsQtySum(product);

                      const qtySumBySpecificProducts =
                        getCurrentManufacturerProductsQtySumBySpecificProducts(
                          product,
                          currentManufacturerDiscounts
                        );

                      const isOutOfStock = getIsOutOfStock(product);

                      const isNonInventory =
                        product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

                      const minusDisabled = !product.discountQty;

                      const onAdd = () =>
                        handleChangeQty({
                          product,
                          isAdd: true,
                          event: null,
                        });

                      const onSubtract = () =>
                        handleChangeQty({
                          product,
                          isAdd: false,
                          event: null,
                        });

                      const plusTooltipProps = {
                        title: `Cannot add this product to the cart. Items Available: ${
                          product.inventory?.onHand -
                          product.inventory?.allocated
                        }`,
                        disableHoverListener:
                          !isOutOfStock ||
                          isNonInventory ||
                          createOrderType === "THIRD_PARTY",
                      };

                      // const currentDiscountsMultiplier =
                      //   currentDiscounts.reduce(
                      //     (acc, cur) => acc + (cur?.multiplier || 1),
                      //     0
                      //   );
                      const currentDiscountsByProduct =
                        getCurrentDiscounts(product);

                      const availableDiscountsQuantityDiscountByProduct =
                        currentDiscountsByProduct.reduce(
                          (acc, cur) =>
                            acc +
                            (cur?.quantityDiscount || 0) *
                              (cur?.multiplier || 1),
                          0
                        );

                      const availableDiscountsQuantityDiscount =
                        currentManufacturerDiscounts.reduce(
                          (acc, cur) =>
                            acc +
                            (cur?.quantityDiscount || 0) *
                              (cur?.multiplier || 1),
                          0
                        );

                      const plusDisabled =
                        !isNonInventory &&
                        (qtySumAll >=
                          (availableDiscountsQuantityDiscount || 1) ||
                          qtySumBySpecificProducts >=
                            availableDiscountsQuantityDiscountByProduct ||
                          (isOutOfStock && createOrderType !== "THIRD_PARTY"));

                      const disabled =
                        !isApplied &&
                        (qtySumAll === availableDiscountsQuantityDiscount ||
                          qtySumBySpecificProducts ===
                            availableDiscountsQuantityDiscountByProduct);

                      const onChange = (e) =>
                        handleChangeQty({
                          product,
                          isAdd: false,
                          event: e,
                        });

                      return (
                        <Grid
                          key={product?.id}
                          width="100%"
                          height="60px"
                          borderBottom={
                            listCountIndexes !== index && "0.5px solid #D4D4D4"
                          }
                          container
                        >
                          {/* empty block */}
                          <Grid xs={0.2} item />

                          {/* Picture */}
                          <Grid
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            xs={1.2}
                            item
                          >
                            <Box
                              sx={{
                                width: "39px",
                                height: "39px",
                                backgroundColor: "white",
                                border: "1px solid #D5D9D9",
                                borderRadius: "4px",
                              }}
                            >
                              <StyledProductIco
                                src={
                                  product?.photos?.length
                                    ? photoUrl(product.photos[0].fileName)
                                    : product?.photo
                                    ? photoUrl(product?.photo?.fileName)
                                    : ""
                                }
                                styles={{
                                  objectFit: "contain",
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "4px",
                                }}
                              />
                            </Box>
                          </Grid>

                          {/* Name */}
                          <Grid
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            xs={5}
                            item
                          >
                            <Typography
                              fontSize={12}
                              fontWeight={600}
                              color="#1C1C19"
                              noWrap
                            >
                              {productName}
                            </Typography>
                            <Typography fontSize="12px" fontWeight="400" noWrap>
                              {productType}
                            </Typography>
                          </Grid>

                          {/* price & buttons */}
                          <Grid
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            xs
                            item
                            pr="18px"
                          >
                            <Box
                              mr="14px"
                              sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "12px",
                                  color: "#BCBCBC",
                                },
                              }}
                              display="inline-flex"
                              gap="6px"
                            >
                              {isApplied && (
                                <Typography>
                                  $
                                  <span
                                    style={{
                                      textDecoration: "line-through",
                                      color: "#1C1C19",
                                    }}
                                  >
                                    {` ${(
                                      discountQty *
                                      (calcProductPriceByPriceList({
                                        product,
                                      }) || 0)
                                    ).toFixed(2)}`}
                                  </span>
                                </Typography>
                              )}
                              <Typography
                                sx={{
                                  color: isApplied && "#47A06D!important",
                                  fontWeight: "600",
                                }}
                              >
                                $ 0.00
                              </Typography>
                            </Box>
                            <QuantityPicker
                              onAdd={onAdd}
                              onSubtract={onSubtract}
                              value={product.discountQty || 0}
                              minusDisabled={minusDisabled}
                              plusDisabled={plusDisabled}
                              plusTooltipProps={plusTooltipProps}
                              disabled={disabled}
                              onChange={onChange}
                            />
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Stack>
                </Collapse>
              </Box>
            );
          })
        )}
      </Box>
      <Box
        pt="12px"
        width="100%"
        sx={{ "& .MuiButton-root": { width: "100%" } }}
      >
        <StyledButton
          label="Add Promotion"
          variant="contained"
          fontSize="15px"
          onClick={() => handleAddPromotion(tempList)}
        />
      </Box>
    </DialogContent>
  );
};

ContentBlock.propTypes = {
  list: array,
  discounts: array,
  handleAddPromotion: func,
  calcProductPriceByPriceList: func,
  loading: bool,
  cartProducts: array,
  createOrderType: string,
  isEdit: bool,
};
ContentBlock.defaultProps = {
  list: [],
  discounts: [],
  handleAddPromotion: () => {},
  loading: false,
  cartProducts: [],
  isEdit: false,
};
