import React, { Fragment } from "react";
import {
  Box,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes, {
  bool,
  func,
  array,
  number,
  string,
  object,
} from "prop-types";
import { CrossBigIcon } from "../../../../components/Icons";

import PopupProductsItem from "./PopupProductsItem/PopupProductsItem";
import StyledButton from "../../../../components/StyledButton";
import { PRODUCT_TYPE_INVENTORY } from "../../../../utils/constants";
import { containsEvery, useAdmin } from "../../../../helpers/helpers";
import PopupProductsHeader from "./PopupProductsHeader/PopupProductsHeader";
import { NewProductsFilter } from "./components";
import StyledPopper from "../../../../components/StyledPopper/StyledPopper";
import { setIndeterminate } from "./helpers";
import { InfiniteScrollWrapper } from "components";
import { useAllProducts } from "./useAllProducts";

const AllProductsPopup = ({
  isOpen,
  handleClose,
  handleAddProducts,
  handleCancelProducts,
  handleCheckProduct,
  checkedProducts,
  addedProducts,
  specificManufacturer,
  disableOutOfStock,
  isOrder,
  isPriceList = false,
  hideAvailable,
  environment,
  skipExpanding = false,
  hideGreenNavigationBar = false,
  allowMultipleSelect = false,
  allowSelectAll = false,
  allCheckedByDefault = false,
  isEditCustomer = false,
  setCheckState,
  priceListId = null,
  excludeIds = [],
  fetchQuery = {},
  setExcludeIds = () => {},
  isAllUncheckedProducts = false,
  isAllUnchecked = false,
  setIsAllUnchecked = () => {},
  availableAssignedProducts = [],
  hideChildCheckbox = false,
  headerTitle = "All Products",
  dialogProps = {},
  showActiveTabs = false,
  showInactive = false,
  setShowInactive,
}) => {
  const isAdmin = useAdmin();

  const {
    page,
    flatList,
    textProduct,
    productsRefs,
    productsList,
    productsCount,
    productsLoading,
    expandedParentId,
    currentProductRef,
    supplierWithOnlyOneProductWithVariations,
    calcAvailable,
    setTextProduct,
    handleFetchProducts,
    setExpandedParentId,
    setCurrentProductRef,
    handleProductsChecked,
    handleProductsDisable,
    handleSelectAllProducts,
    handleDisableIfChosenProduct,
    calcProductOutOfStockForParent,
    productsAvailableLessThanMinimum,
    filterMenuOpen,
    handleApplyFilter,
    filterAnchor,
    setFilterMenuOpen,
    filterChipKeys,
    filterFields,
    setSearchInput,
    switches,
    setFilterFields,
    getChipLabel,
    setSwitches,
    searchInput,
    onSave,
    handleDeleteFilter,
    allProductsAreChecked,
    isIndeterminate,
    onCancel,
  } = useAllProducts({
    isOpen,
    isOrder,
    fetchQuery,
    excludeIds,
    priceListId,
    isPriceList,
    showInactive,
    setExcludeIds,
    setCheckState,
    isEditCustomer,
    addedProducts,
    checkedProducts,
    isAllUnchecked,
    setIsAllUnchecked,
    disableOutOfStock,
    handleAddProducts,
    allCheckedByDefault,
    specificManufacturer,
    handleCancelProducts,
    isAllUncheckedProducts,
    availableAssignedProducts,
  });

  return (
    <>
      <StyledPopper
        style={{ zIndex: 1401, whiteSpace: "pre-wrap" }}
        open={!!currentProductRef && isOpen && !hideAvailable}
        anchorEl={productsRefs.current?.[currentProductRef] || null}
        text={textProduct}
        renderRoot
        modifiers={[
          {
            name: "offset",
            options: { offset: [0, -6] },
          },
        ]}
        placement="top"
        transition
        aria-hidden="true"
      />
      <Dialog
        open={isOpen}
        PaperProps={{
          sx: {
            maxWidth: "786px",
            border: "0.5px #D5D9D9 solid",
            borderRadius: "8px",
          },
        }}
        {...dialogProps}
      >
        <DialogContent
          sx={{
            width: "783px",
            maxHeight: "580px",
            height: "100%",
            p: "24px 32px",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            mb="24px"
            alignItems="center"
          >
            <Typography fontSize="30px" color="#707070">
              {headerTitle}{" "}
              {specificManufacturer?.name ? specificManufacturer?.name : null}
            </Typography>
            <IconButton
              onClick={handleClose}
              disableRipple
              sx={{ width: "24px", height: "24px", p: 0 }}
            >
              <CrossBigIcon size={22} strokeWidth={2} />
            </IconButton>
          </Box>

          <NewProductsFilter
            {...{
              isOpen,
              specificManufacturer,
              filterMenuOpen,
              handleApplyFilter,
              filterAnchor,
              setFilterMenuOpen,
              filterChipKeys,
              filterFields,
              setSearchInput,
              switches,
              setFilterFields,
              getChipLabel,
              setSwitches,
              searchInput,
              handleDeleteFilter,
              fetchQuery,
              showInactive,
              setShowInactive,
              showActiveTabs,
            }}
          />

          <PopupProductsHeader
            {...{
              hideAvailable,
              isPriceList,
              allowSelectAll,
              flatList,
              handleSelectAllProducts,
              allChecked: allProductsAreChecked,
              isIndeterminate,
            }}
          />
          <Stack
            sx={{
              height: "281px",
              overflow: "hidden",
              border: "0.5px solid #D5D9D9",
              borderRadius: "0 0 4px 4px",
            }}
          >
            {productsLoading ? (
              <CircularProgress sx={{ ml: "48%", mt: "125px" }} size="30px" />
            ) : (
              <InfiniteScrollWrapper
                maxHeight="281px"
                dataLength={productsList.length}
                next={() => handleFetchProducts({ page: page + 1 })}
                loader={
                  <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
                }
                hasMore={productsList.length < productsCount}
                id="all-products-scroll-table"
              >
                {productsList?.length ? (
                  productsList.map((product) => {
                    const disableIfChosenProduct = handleDisableIfChosenProduct(
                      product,
                      null,
                      allowMultipleSelect
                    );

                    const isNonInventory =
                      product?.type === PRODUCT_TYPE_INVENTORY.non_inventory;

                    const setOutOfStock = (product) => {
                      if (!isOrder || !disableOutOfStock) return false;

                      return (
                        (!isNonInventory &&
                          disableOutOfStock &&
                          productsAvailableLessThanMinimum(product)) ||
                        (!isNonInventory &&
                          calcProductOutOfStockForParent(product))
                      );
                    };

                    const outOfStock = setOutOfStock(product);

                    const isChecked = handleProductsChecked(product);

                    const indeterminateParent = setIndeterminate(
                      product,
                      isChecked,
                      checkedProducts
                    );

                    const isMissingInfo = product?.missingFields;

                    return (
                      <Fragment key={product.id}>
                        <PopupProductsItem
                          isOrder={isOrder}
                          isPriceList={isPriceList}
                          productsRefs={productsRefs}
                          onMouseEnter={() => {
                            if (
                              productsAvailableLessThanMinimum(product) &&
                              !product?.isMultiple &&
                              disableOutOfStock &&
                              outOfStock
                            ) {
                              setCurrentProductRef(product?.id);
                              setTextProduct(
                                `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                                  product
                                )}, Minimum Order QTY: ${product?.minOrderQTY}`
                              );
                            }
                          }}
                          onMouseLeave={() => {
                            setCurrentProductRef(false);
                            setTextProduct("");
                          }}
                          key={product.id}
                          product={product}
                          hideGreenNavigationBar={hideGreenNavigationBar}
                          skipExpanding={skipExpanding}
                          hideAvailable
                          expandedParentId={expandedParentId}
                          parentInOrder={
                            product.isMultiple &&
                            containsEvery(
                              product.childProducts.filter(
                                // if isMultiple, check if Added products (formfield) contain
                                // any of child products from this parent
                                (childProduct) =>
                                  !addedProducts?.some(
                                    (prod) =>
                                      prod?.id === childProduct.id ||
                                      prod?.product?.id === childProduct.id
                                  )
                              ),
                              addedProducts
                            )
                          }
                          handleCheckProduct={handleCheckProduct}
                          supplierWithOnlyOneProductWithVariations={
                            supplierWithOnlyOneProductWithVariations &&
                            !!product?.childProducts?.length
                          }
                          handleExpandVariations={(id) => {
                            if (id === expandedParentId)
                              return setExpandedParentId(null);
                            setExpandedParentId(id);
                          }}
                          isChecked={isChecked}
                          isMissingInfo={isMissingInfo}
                          disabled={
                            handleProductsDisable(product, false) ||
                            disableIfChosenProduct ||
                            !!isMissingInfo?.length
                          }
                          indeterminateParent={indeterminateParent}
                          setExpandedParentId={setExpandedParentId}
                          disableIfChosenProduct={disableIfChosenProduct}
                          outOfStock={outOfStock}
                          {...{ hideAvailable }}
                        />
                        <Collapse
                          in={expandedParentId === product.id}
                          timeout={700}
                        >
                          {product.childProducts?.map(
                            (children, indexChild) => {
                              children.sellingOutOfStock =
                                product?.sellingOutOfStock;
                              children.minOrderQTY = product?.minOrderQTY;

                              const isNonInventory =
                                product?.type ===
                                PRODUCT_TYPE_INVENTORY.non_inventory;

                              const setOutOfStock = (children) => {
                                if (!isOrder || !disableOutOfStock)
                                  return false;
                                return (
                                  !isNonInventory &&
                                  disableOutOfStock &&
                                  productsAvailableLessThanMinimum(children)
                                );
                              };

                              const outOfStock = setOutOfStock(children);

                              const disableIfChosenProduct =
                                handleDisableIfChosenProduct(
                                  children,
                                  product,
                                  allowMultipleSelect
                                );
                              const isMissingInfo = children?.missingFields;

                              return (
                                <PopupProductsItem
                                  productsRefs={productsRefs}
                                  isPriceList={isPriceList}
                                  hideChildCheckbox={hideChildCheckbox}
                                  onMouseEnter={() => {
                                    if (outOfStock) {
                                      setCurrentProductRef(children?.id);
                                      setTextProduct(
                                        `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                                          children
                                        )}, Minimum Order QTY: ${
                                          product?.minOrderQTY
                                        }`
                                      );
                                    }
                                  }}
                                  hideAvailable={hideAvailable}
                                  onMouseLeave={() => {
                                    setCurrentProductRef(false);
                                    setTextProduct("");
                                  }}
                                  key={children.id}
                                  product={children}
                                  parent={product}
                                  hideGreenNavigationBar={
                                    hideGreenNavigationBar
                                  }
                                  skipExpanding={skipExpanding}
                                  supplierWithOnlyOneProductWithVariations={
                                    supplierWithOnlyOneProductWithVariations &&
                                    !!product?.childProducts?.length
                                  }
                                  handleExpandVariations={(id) => {
                                    if (id === expandedParentId)
                                      return setExpandedParentId(null);
                                    setExpandedParentId(id);
                                  }}
                                  isChild
                                  handleCheckProduct={handleCheckProduct}
                                  isChecked={checkedProducts.some(
                                    (checkedProduct) =>
                                      checkedProduct.id === children.id
                                  )}
                                  isMissingInfo={isMissingInfo}
                                  disabled={
                                    handleProductsDisable(children, true) ||
                                    disableIfChosenProduct ||
                                    !!isMissingInfo?.length
                                  }
                                  disableIfChosenProduct={
                                    disableIfChosenProduct
                                  }
                                  outOfStock={outOfStock}
                                  lastChild={
                                    product?.childProducts.length ===
                                    indexChild + 1
                                  }
                                  environment={environment}
                                />
                              );
                            }
                          )}
                        </Collapse>
                      </Fragment>
                    );
                  })
                ) : (
                  <Typography
                    fontWeight="300"
                    fontSize="16px"
                    textAlign="center"
                    width="100%"
                    color="#6A6A6A"
                    paddingTop="20px"
                    paddingBottom="20px"
                  >
                    No products found
                  </Typography>
                )}
              </InfiniteScrollWrapper>
            )}
          </Stack>
          <DialogActions sx={{ p: "24px 0px 0px" }}>
            <StyledButton
              label="Cancel"
              sx={{ height: "28px", mr: "4px", p: "5px 9px" }}
              color="cancel"
              variant="outlined"
              onClick={onCancel}
              fontSize="12px"
            />
            <StyledButton
              disabled={isAdmin}
              label="Add"
              variant="contained"
              sx={{ height: "28px", minWidth: "58px" }}
              fontSize="12px"
              onClick={onSave}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};

AllProductsPopup.propTypes = {
  isOpen: bool,
  allowSelectAll: bool,
  handleClose: func,
  handleCancelProducts: func,
  setCheckState: func,
  handleAddProducts: func,
  setExcludeIds: func,
  handleCheckProduct: func,
  checkedProducts: array,
  addedProducts: array,
  excludeIds: array,
  specificManufacturer: PropTypes.oneOfType([number, string, object]),
  disableOutOfStock: bool,
  allCheckedByDefault: bool,
  isEditCustomer: bool,
  isOrder: bool,
  isPriceList: bool,
  hideAvailable: bool,
  isAllUncheckedProducts: bool,
  allowMultipleSelect: bool,
  skipExpanding: bool,
  hideGreenNavigationBar: bool,
  environment: string,
  headerTitle: string,
  hideChildCheckbox: bool,
  priceListId: string,
  fetchQuery: object,
  dialogProps: object,
  isAllUnchecked: bool,
  showActiveTabs: bool,
  setIsAllUnchecked: func,
  availableAssignedProducts: array,
  showInactive: bool,
  setShowInactive: func,
};

AllProductsPopup.defaultProps = {
  isOpen: false,
  checkedProducts: [],
  addedProducts: [],
  disableOutOfStock: false,
  isOrder: false,
  isPriceList: false,
  hideAvailable: false,
  skipExpanding: false,
  priceListId: null,
  hideGreenNavigationBar: false,
};

export default AllProductsPopup;
