export const PRIORITIES = ["LOW", "MEDIUM", "HIGH"];
export const STATUSES = ["PENDING", "COMPLETED"];

export const defaultValues = {
  title: "",
  description: "",
  dueDate: "",
  priority: PRIORITIES[0],
  representative: "",
  assignToDistributor: null,
  //repeated: false,
  //repeatInterval: "day",
  //repeatStep: 1,
  applyForFutureTasks: false,
  //addTime: false,
  assignedDistributorId: null,
  formIds: [],
  dueTime: "", // was "09:00"
};

export const defaultRepeatInterval = [
  "day",
  "weekday",
  "week",
  "month",
  "year",
];

export const MAX_CHARACTERS_TITLE = 100;
export const MAX_CHARACTERS_DESCRIPTIONS = 1000;
