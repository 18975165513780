export const cl = {
  cancelBtn: {
    width: "98px",
    color: "#6A6A6A",
    borderColor: "#D4D4D4",
    fontSize: "13px",
    height: "28px",
  },
  confirmBtn: {
    width: "98px",
    color: "#FFFFFF",
    fontSize: "13px",
    height: "28px",
    boxShadow: "none",
  },
  actionsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "14px 34px 19px 34px",
  },
  contentWrapper: {
    p: "16px 34px 0 34px",
    maxWidth: "484px",
    minWidth: "420px",
    minHeight: "94px",
  },
  dialogTitle: {
    fontSize: "20px",
    color: "#080808",
    fontWeight: 400,
    p: "17px 28px 10px 28px",
    mb: "12px",
    borderBottom: "0.5px solid #CCCCCC",
    position: "relative",
  },
};
