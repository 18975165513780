import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { error, success, warning } from "utils/notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  openConfirmDialogAction,
  openDiscardChanges,
  setConfirmIsOpenAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./TaskDrawer.validations";
import {
  defaultValues,
  PRIORITIES,
  STATUSES,
} from "Pages/CustomersPage/pages/CustomerProfilePage/components/TaskDrawer/TaskDrawer.constants";
import {
  createTaskBulkService,
  createTaskService,
  deleteTaskService,
  getTaskByIdService,
  updateCompletedTaskService,
  updateTaskService,
} from "services/tasks";
import { CUSTOMER_STATUS_FILTERS, SCROLL_LIMIT } from "utils/constants";
import { getRepsService } from "services/reps";
import { getCustomersService } from "services/customers";
import { useDebounce } from "helpers/hooks";
import { handleError } from "helpers/helpers";
import { isEqual } from "lodash";

export const useCustomerTasks = ({
  open,
  taskState,
  onClose,
  refetchCallback,
  isAdvancedDrawer,
  selectedCustomers,
  customer,
  setCheckedCustomers,
  setDialogState,
  resetData = true,
  formChanged,
  trigger,
  formField,
  disabledAddBtn,
  isDirty,
  id,
}) => {
  const dispatch = useDispatch();

  const editTask = useMemo(
    () => (id ? formField : taskState),
    [id, formField, taskState]
  );
  const taskId = useMemo(() => taskState?.id || id, [id, taskState?.id]);
  const customerId = taskState?.customerId || formField?.id;

  // const successMsgOptions = useMemo(
  //   () => ({
  //     options: {
  //       autoClose: 4000,
  //       icon: SuccessIcon,
  //       className: "warn-toast",
  //       bodyClassName: "warn-toast-body",
  //     },
  //   }),
  //   []
  // );

  const [loading, setLoading] = useState(false);
  const [repsState, setRepsState] = useState({
    list: [],
    count: 0,
    loading: false,
    cursor: null,
  });

  const paramRef = useRef(null);

  const handleFetchReps = useCallback(async () => {
    setRepsState((prev) => ({ ...prev, loading: true }));

    const fetchQuery = {
      limit: SCROLL_LIMIT,
      roles: JSON.stringify(["sales", "merchandiser"]),
      cursor: repsState.cursor,
    };

    if (isEqual(paramRef?.current, fetchQuery))
      return setRepsState((prev) => ({ ...prev, loading: false }));

    paramRef.current = fetchQuery;

    try {
      const res = await getRepsService({
        ...fetchQuery,
      });

      const {
        countSales = 0,
        countMerchandiser = 0,
        rows = [],
        cursor,
      } = res || {};

      setRepsState((prev) => ({
        ...prev,
        list: cursor ? [...prev.list, ...rows] : rows,
        count: countSales + countMerchandiser,
        loading: false,
        cursor: cursor,
      }));
    } catch (error) {
      handleError(error);
    }
  }, [repsState]);

  useEffect(() => {
    if (open) handleFetchReps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getCustomerIds = useCallback(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!selectedCustomers.length)
      return selectedCustomers.map((customer) => customer.id);
    if (customer?.id) return [customer.id];
    return [];
  }, [selectedCustomers, customer]);

  const handleErrorTask = useCallback(() => {
    warning(
      "Please fill in required fields"
      // { options: {
      //   autoClose: 4000,
      //   icon: WarningIcon,
      //   className: "warn-toast",
      //   bodyClassName: "warn-toast-body",
      // },}
    );
  }, []);

  const handleSaveTask = useCallback(
    async (data) => {
      setLoading(true);

      setDialogState((prev) => ({ ...prev, isOpen: false }));
      const {
        title,
        description,
        dueDate,
        priority,
        representative,
        status,
        repeated,
        //repeatInterval,
        //repeatStep,
        //applyForFutureTasks,
        dueTime,
        formIds,
      } = data || {};

      const preparedData = {
        title,
        description,
        priority,
        repeated,
        //...(repeated && {
        //  repeatInterval: repeatInterval.toUpperCase(),
        //}),
        //...(repeated &&
        //  repeatInterval !== "weekday" && {
        //    repeatStep: +repeatStep,
        //  }),
        //...(editTask && isRepeatedTask && { applyForFutureTasks }),
        ...(isAdvancedDrawer
          ? { customerIds: getCustomerIds() }
          : { customerId }),
        formIds,
      };

      preparedData.dueTime = dueDate && dueTime ? dueTime : null;

      preparedData.assignToDistributor = representative === "distributor";
      preparedData.representativesIds =
        representative === "distributor" ? undefined : [representative];

      try {
        if (editTask && taskId) {
          preparedData.status = status;
          preparedData.dueDate = dueDate ? dueDate : undefined;

          await updateTaskService({ taskId, data: preparedData });
          success("Task updated");
        } else {
          preparedData.dueDate = dueDate ? dueDate : null;

          if (isAdvancedDrawer) {
            await createTaskBulkService({ data: preparedData });
            if (resetData) {
              setCheckedCustomers([]);
            }
            success(
              `${
                selectedCustomers?.length > 1
                  ? `${selectedCustomers?.length} Tasks`
                  : "1 Task"
              } ${editTask ? "updated" : "created"}`
            );
          } else {
            await createTaskService({ data: preparedData });
            success(
              `Task ${editTask ? "updated" : "created"}`
              // successMsgOptions
            );
          }
        }

        dispatch(setFormChangedAction(false));
        onClose();
        refetchCallback();
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customerId,
      dispatch,
      editTask,
      getCustomerIds,
      onClose,
      isAdvancedDrawer,
      refetchCallback,
      selectedCustomers?.length,
      setCheckedCustomers,
      taskId,
    ]
  );

  const preventCloseDrawer = useCallback(
    ({ onClose }) => {
      // eslint-disable-next-line no-console
      if (!onClose) return console.error("onClose is a required parameter");

      if (formChanged) {
        return dispatch(
          openDiscardChanges(
            () => onClose(),
            async () => {
              const resTrigger = await trigger();
              if (resTrigger && !disabledAddBtn && !loading) {
                handleSaveTask(formField);
              } else {
                dispatch(setFormChangedAction(isDirty));
              }
            }
          )
        );
      }

      onClose();
    },
    [
      disabledAddBtn,
      dispatch,
      formChanged,
      formField,
      handleSaveTask,
      isDirty,
      loading,
      trigger,
    ]
  );

  const handleCloseTask = useCallback(() => {
    preventCloseDrawer({ onClose });
  }, [onClose, preventCloseDrawer]);

  const handleUpdateCompletedTask = useCallback(
    async (data, onSuccess) => {
      const preparedData = {};
      try {
        if (editTask && taskId) {
          preparedData.status = data;
          await updateCompletedTaskService({ taskId, data: preparedData });
          onSuccess
            ? onSuccess
            : success(
                data === STATUSES[1] ? "Task completed" : "Marked incomplete"
                // successMsgOptions
              );
        }
        refetchCallback();
      } catch (err) {
        error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    },
    [editTask, refetchCallback, taskId]
  );

  const handleConfirmIncompleteTask = useCallback(
    ({ setValue }) => {
      dispatch(
        openConfirmDialogAction({
          title: "Confirm Task Incompletion",
          text: "You are about to mark this task as incomplete. Are you sure you want to proceed?",
          isIcon: true,
          propBtns: {
            left: {
              label: "Cancel",
              color: "cancel",
              variant: "outlined",
            },
            right: {
              label: "Proceed",
              variant: "contained",
              onClick: () => {
                setValue("status", STATUSES[0]);
                handleUpdateCompletedTask(
                  STATUSES[0],
                  success("Access Revoked")
                );
                dispatch(setConfirmIsOpenAction(false));
                setDialogState((prev) => ({
                  ...prev,
                  isOpen: false,
                }));
                onClose();
              },
            },
          },
        })
      );
    },
    [dispatch, handleUpdateCompletedTask, onClose, setDialogState]
  );

  const INIT_DELETE_DIALOG_DATA = useMemo(
    () => ({
      open: false,
      title: "",
      text: "",
      isShowIcon: false,
    }),
    []
  );
  const [deleteDialogData, setDeleteDialogData] = useState(
    INIT_DELETE_DIALOG_DATA
  );

  const handleCloseDeleteDialog = useCallback(() => {
    setDeleteDialogData(INIT_DELETE_DIALOG_DATA);
  }, [INIT_DELETE_DIALOG_DATA]);

  const handleOpenDeleteDialog = () => {
    setDeleteDialogData((prev) => ({
      ...prev,
      open: true,
      title: "Delete task",
      text: (
        <>
          Are you sure you want to delete this task(s)?
          <br />
          This action can’t be undone.
        </>
      ),
      isShowIcon: true,
    }));
  };

  const handleDeleteTask = () => handleOpenDeleteDialog();

  const handleConfirmDelete = useCallback(
    async ({ data, isRepeatedTask = false, notifyAssignee }) => {
      try {
        setLoading(true);
        await deleteTaskService({
          tasksIds: [taskId],
          ...(editTask &&
            isRepeatedTask && {
              applyForFutureTasks: data?.applyForFutureTasks || false,
            }),
          notifyAssignee,
        });
        success("Task deleted");
        handleCloseDeleteDialog();

        setDialogState((prev) => ({ ...prev, isOpen: false }));
        dispatch(setFormChangedAction(false));
        onClose();
        refetchCallback();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        error(err?.response?.data?.message);
      }
    },
    [
      taskId,
      editTask,
      handleCloseDeleteDialog,
      setDialogState,
      dispatch,
      onClose,
      refetchCallback,
    ]
  );

  //const handleOnDeleteDrawer = () => {
  //  setDialogState({ isOpen: true, isDeleting: true });
  //};

  //const handleOnSaveDrawer = async () => {
  //  const resTrigger = await trigger();

  //  if (resTrigger) setDialogState({ isOpen: true, isDeleting: false });
  //};

  const handleOnCloseDrawer = () => {
    setDialogState({ isOpen: false, isDeleting: false });
  };

  return {
    handleSaveTask,
    handleErrorTask,
    handleConfirmDelete,
    handleCloseTask,
    open,
    repData: taskState?.representativeDuplicate,
    taskData: taskState,
    handleDeleteTask,
    loading,
    taskState,
    editTask,
    repsState,
    handleFetchReps,
    handleUpdateCompletedTask,
    handleConfirmIncompleteTask,
    //handleOnDeleteDrawer,
    //handleOnSaveDrawer,
    handleOnCloseDrawer,
    deleteDialogData,
    handleCloseDeleteDialog,
  };
};

export const useTaskAssigne = ({
  isAdvancedDrawer,
  selectedCustomers,
  setCheckedCustomers,
}) => {
  const [customersList, setCustomersList] = useState([]);
  const [checkedTaskCustomers, setCheckedTaskCustomers] =
    useState(selectedCustomers);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customersSearchInput, setCustomersSearchInput] = useState("");

  const customersSearchInputDebounced = useDebounce(customersSearchInput, 300);

  useEffect(() => {
    if (!isAdvancedDrawer) return;
    if (!customersSearchInputDebounced) return setCustomersList([]);
    setCustomersLoading(true);
    getCustomersService({
      search: customersSearchInputDebounced,
      status: `["${CUSTOMER_STATUS_FILTERS.active}"]`,
    })
      .then((res) => {
        setCustomersLoading(false);
        setCustomersList(res.rows);
      })
      .catch(() => {
        setCustomersLoading(false);
        error("Something went wrong");
      });
  }, [customersSearchInputDebounced, isAdvancedDrawer]);

  const handleCheckCustomer = (customer, withUpdate = false) => {
    const customerIndex = checkedTaskCustomers.findIndex(
      (checkedCustomer) => checkedCustomer.id === customer.id
    );
    if (customerIndex > -1) {
      const newCustomers = [...checkedTaskCustomers];
      newCustomers.splice(customerIndex, 1);

      if (withUpdate) setCheckedCustomers([...newCustomers]);
      return setCheckedTaskCustomers([...newCustomers]);
    }

    if (withUpdate) setCheckedCustomers([...checkedTaskCustomers, customer]);
    setCheckedTaskCustomers([...checkedTaskCustomers, customer]);
  };

  return {
    customersList,
    customersLoading,
    checkedTaskCustomers,
    customersSearchInput,
    setCustomersList,
    handleCheckCustomer,
    setCustomersSearchInput,
    setCheckedTaskCustomers,
  };
};

export const useFormFields = ({
  open,
  taskState,
  isAdvancedDrawer,
  isEdit = false,
  taskId,
}) => {
  const [loading, setLoading] = useState(true);
  const [defDueDate, setDefDueDate] = useState(null);

  const currentUser = useSelector(({ auth }) => auth.currentUser);
  //const [isRepeatedTask, setIsRepeatedTask] = useState(false);

  const { timeZone } = currentUser || {};

  const {
    control,
    reset,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors, dirtyFields, isDirty },
    trigger,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues: {
      ...defaultValues,
    },
    resolver: yupResolver(
      validationSchema({ isAdvancedDrawer, timeZone, isEdit, defDueDate })
    ),
  });

  const onTaskReset = useCallback(
    (data) => {
      const {
        title,
        description,
        dueDate,
        dueTime,
        priority,
        assignedRepresentatives,
        status,
        assignedForms,
        //repeated,
        //repeatInterval,
        //repeatStep,
        applyForFutureTasks,
        distributorId,
        representativeId,
        assignedDistributorId,
        assignedDistributor,
        activities,
      } = data || {};

      let assignedRepresentativeId = "";

      if (assignedRepresentatives?.[0]?.representative?.id) {
        assignedRepresentativeId =
          assignedRepresentatives?.[0]?.representative?.id;
      }

      if (assignedDistributor) {
        assignedRepresentativeId = "distributor";
      }

      //if (repeated) {
      //  setIsRepeatedTask(true);
      //} else {
      //  setIsRepeatedTask(false);
      //}

      setDefDueDate(dueDate);
      reset({
        title: title || defaultValues.title,
        description: description || defaultValues.description,
        dueDate: dueDate || defaultValues.dueDate,
        dueTime: dueTime || defaultValues.dueTime,
        priority: priority || defaultValues.priority,
        representative: assignedRepresentativeId,
        assignToDistributor: assignedRepresentativeId === "distributor",
        status: status || null,
        //repeated: repeated || defaultValues.repeated,
        applyForFutureTasks:
          applyForFutureTasks || defaultValues.applyForFutureTasks,
        //repeatInterval:
        //  (repeatInterval && repeatInterval.toLowerCase()) ||
        //  defaultValues.repeatInterval,
        //repeatStep: repeatStep || defaultValues.repeatStep,
        distributorId,
        representativeId,
        assignedDistributorId:
          assignedDistributorId || defaultValues.assignedDistributorId,
        assignedRepresentatives,
        activities: activities?.length ? activities : [],
        assignedForms: assignedForms || [],
      });
    },
    [reset]
  );

  const getTaskById = useCallback(
    async (id) => {
      setLoading(true);
      try {
        const task = await getTaskByIdService(id);
        return onTaskReset(task);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err?.response?.data?.message);
        error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    },
    [onTaskReset]
  );

  const formField = useWatch({ control });

  useEffect(() => {
    if (open) {
      if (taskId) return getTaskById(taskId);

      setLoading(false);
      return onTaskReset(taskState);
    }
  }, [reset, open, taskState, timeZone, taskId, onTaskReset, getTaskById]);

  return {
    formField,
    control,
    handleSubmit,
    PRIORITIES,
    STATUSES,
    isDirty,
    //isRepeatedTask,
    setValue,
    setError,
    dirtyFields,
    clearErrors,
    errors,
    trigger,
    taskLoading: loading,
  };
};
