import { generateUUID } from "helpers/helpers";
import { useDebounce, useRepsPermissions } from "helpers/hooks";
import { useEffect, useState } from "react";
import { getCustomersService } from "services/customers";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import { error } from "utils/notifications";

const LIMIT_ADDED_TAGS = 30;

export const useTags = ({
  setValue,
  formField,
  tags,
  tagsList,
  open,
  type,
  selectedCustomers,
  setCheckedCustomers,
  isAdvancedDrawer,
}) => {
  const repPermissions = useRepsPermissions();

  const [customersLoading, setCustomersLoading] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [checkedTagCustomers, setCheckedTagCustomers] =
    useState(selectedCustomers);
  const [customersSearchInput, setCustomersSearchInput] = useState("");

  const customersSearchInputDebounced = useDebounce(customersSearchInput, 300);

  const handleCheckCustomer = (customer, withUpdate = false) => {
    const customerIndex = checkedTagCustomers.findIndex(
      (checkedCustomer) => checkedCustomer.id === customer.id
    );
    if (customerIndex > -1) {
      const newCustomers = [...checkedTagCustomers];
      newCustomers.splice(customerIndex, 1);

      if (withUpdate) setCheckedCustomers([...newCustomers]);
      return setCheckedTagCustomers([...newCustomers]);
    }

    if (withUpdate) setCheckedCustomers([...checkedTagCustomers, customer]);
    setCheckedTagCustomers([...checkedTagCustomers, customer]);
  };

  const { selectedTagsList } = formField || {};

  useEffect(() => {
    if (!isAdvancedDrawer) return;
    if (!customersSearchInputDebounced) return setCustomersList([]);
    setCustomersLoading(true);
    getCustomersService({
      search: customersSearchInputDebounced,
      status: `["${CUSTOMER_STATUS_FILTERS.active}"]`,
    })
      .then((res) => {
        setCustomersLoading(false);
        setCustomersList(res.rows);
      })
      .catch(() => {
        setCustomersLoading(false);
        error("Something went wrong");
      });
  }, [customersSearchInputDebounced, isAdvancedDrawer]);

  useEffect(() => {
    if (tags?.length && open)
      setValue(
        "selectedTagsList",
        tags?.map(({ tag }) => tag)
      );
  }, [tags, setValue, open]);

  const handleCreate = (tagName) => {
    if (!tagName) return;

    const newTag = {
      id: generateUUID(),
      tag: tagName || formField?.tagInput,
      newTag: true,
    };
    setValue("selectedTagsList", [...selectedTagsList, newTag], {
      shouldDirty: true,
    });
  };

  const handleAddTag = (tag) => {
    if (selectedTagsList?.length >= LIMIT_ADDED_TAGS) return;
    setValue("selectedTagsList", [...selectedTagsList, tag], {
      shouldDirty: true,
    });
  };

  const checkIsUnique = (name, arr, list) => {
    const res1 = !arr.some((t) => {
      return t?.tag?.toLowerCase() === name?.toLowerCase();
    });

    const res2 = list.find((t) => {
      return t?.tag?.toLowerCase() === name?.toLowerCase();
    });

    if (res1 && !res2) return { isUnique: true, tag: {} };

    if (res1 && res2) return { isUnique: false, tag: res2 };

    return { isUnique: false, tag: {} };
  };

  const handlePressEnter = (e) => {
    if (selectedTagsList?.length >= LIMIT_ADDED_TAGS) return;
    if (type === "order" && repPermissions && !repPermissions?.settings?.orders)
      return;

    const val = e.target.value?.trim();

    const { isUnique, tag } = checkIsUnique(val, selectedTagsList, tagsList);

    if (e.keyCode === 13 && val !== "") {
      if (isUnique) {
        handleCreate(val);
      }

      if (!isUnique && tag?.id) {
        handleAddTag(tag);
      }
      setValue("tagInput", "");
    }
  };

  const handleBlur = (e) => {
    if (selectedTagsList?.length >= LIMIT_ADDED_TAGS) return;
    if (type === "order" && repPermissions && !repPermissions?.settings?.orders)
      return;

    const val = e.target.value.trim();

    const { isUnique, tag } = checkIsUnique(val, selectedTagsList, tagsList);

    if (val !== "") {
      if (isUnique) {
        handleCreate(val);
      }

      if (!isUnique && tag?.id) {
        handleAddTag(tag);
      }
      setValue("tagInput", "");
    }
  };

  const handleDeleteTag = (tagId) => {
    const tempList = [...selectedTagsList];
    const index = selectedTagsList.findIndex((t) => t?.id === tagId);
    if (index > -1) {
      tempList.splice(index, 1);
      setValue("selectedTagsList", [...tempList], { shouldDirty: true });
    }
  };

  return {
    tagsList,
    handlePressEnter,
    handleBlur,
    handleAddTag,
    handleDeleteTag,
    handleCreate,
    handleCheckCustomer,
    setCheckedTagCustomers,
    setCustomersSearchInput,
    customersLoading,
    customersList,
    setCustomersList,
    checkedTagCustomers,
    customersSearchInput,
  };
};
