/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";
export const PhotoCommentIcon = ({
  width = 12,
  height = 12,
  fill = "#A5A5A5",
  ...props
}) => (
  <SvgIcon
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fill: "none", width, height }}
    {...props}
  >
    <path
      d="m1 8.25 1.94-1.94a1.5 1.5 0 0 1 2.12 0L6.25 7.5a1.06 1.06 0 0 0 1.5 0 1.06 1.06 0 0 1 1.5 0l1.5 1.5M5 1h2c1.862 0 2.828 0 3.414.586S11 2.626 11 5v2c0 1.886 0 2.828-.586 3.414S8.886 11 7 11H5c-1.886 0-2.828 0-3.414-.586S1 8.886 1 7V5c0-1.886 0-2.828.586-3.414S3.114 1 5 1Z"
      stroke={fill}
      strokeLinecap="round"
    />
  </SvgIcon>
);
