import { useMemo } from "react";
import { object, func } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import moment from "moment-timezone";
import { uniqBy } from "lodash";
import { StyledAvatar } from "components";
import { formatDate, getFormattedDate, photoUrl } from "helpers/helpers";
import { PhotoItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/PhotoItem";
import { OrderItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/OrderItem";
import { NoteItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/NoteItem";
import { TaskItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/TaskItem";
import { RemovedPhotoItem } from "Pages/DashboardPage/components/ActivitiesList/components/ItemList/RemovedPhotoItem";

export const CheckInItem = ({
  classes,
  repPermissions,
  handleOpenProductDialog,
  handleOpenNotes,
  handleCustomerTaskDrawer,
  customer,
  activities,
  currentUser,
  handleCloseTooltip,
}) => {
  const navigate = useNavigate();

  const disabledTaskItem = useMemo(() => {
    if (repPermissions && !repPermissions?.tasks?.create_edit) return true;

    if (repPermissions && !repPermissions?.tasks?.view) return true;
  }, [repPermissions]);

  return (
    <Box sx={{ pt: "3px", mt: "5px", borderTop: "0.5px solid #d5d9d9" }}>
      {activities?.length ? (
        activities?.map((activity) => {
          const {
            photoGroups,
            notes,
            orders,
            ordersWithAggregations,
            tasks,
            photoGroupComments,
            type,
            distributor,
            representativeDuplicate,
            updatedAt,
            createdAt,
          } = activity || {};

          const skipShowCheckIn =
            activity?.createdBy === "BACK_OFFICE_WORKER" ||
            activity?.createdBy === "DISTRIBUTOR";

          const setPhotoItems = () => {
            const photoGroupCommentsPrepared = photoGroupComments
              ?.map((el) => el?.photoGroup)
              ?.map((photo) => photo);

            return uniqBy(
              photoGroups?.concat(photoGroupCommentsPrepared),
              (v) => v?.id
            );
          };

          const photoItems = setPhotoItems();

          const setRemovedBy = () => {
            if (!photoItems?.length) {
              return distributor
                ? "Admin"
                : representativeDuplicate?.name || "";
            }
          };

          const formattedDate = formatDate({
            utc_date: updatedAt,
            formatThisYear: "MMM D, h:mm a",
          });

          const setRemovedAt = () => {
            if (!photoItems?.length) {
              return (
                moment(
                  getFormattedDate(
                    updatedAt || createdAt,
                    currentUser?.timeZone
                  )
                ).format(formattedDate) || ""
              );
            }
          };

          const stylesProps = {
            width: "40px",
            minWidth: "40px",
            maxWidth: "40px",
            height: "40px",
            minHeight: "40px",
            maxHeight: "40px",
            gap: "0px",
            "& svg": {
              width: "0.95rem",
              height: "0.95rem",
            },
          };

          return (
            <Box key={activity.id} className={classes.checkInItem}>
              <Box mr={1}>
                <Box display="flex" alignItems="center" gap="4px">
                  <StyledAvatar
                    sx={{ width: 15, height: 15 }}
                    name={
                      (activity?.createdBy === "DISTRIBUTOR" && "Admin") ||
                      activity.representativeDuplicate?.name ||
                      activity?.appCustomer?.name
                    }
                    src={photoUrl(
                      (activity?.createdBy === "DISTRIBUTOR" &&
                        currentUser?.profilePhoto?.fileName) ||
                        activity.representativeDuplicate?.profilePhoto
                          ?.fileName ||
                        activity?.appCustomer?.profilePhoto?.fileName
                    )}
                    stringStyle={{ fontSize: 9 }}
                  />
                  <Typography
                    fontSize="14px"
                    fontWeight="500"
                    noWrap
                    maxWidth="230px"
                  >
                    {(activity?.createdBy === "DISTRIBUTOR" && "Admin") ||
                      activity.representativeDuplicate?.name ||
                      activity?.appCustomer?.name}
                  </Typography>
                </Box>

                {activity?.checkIn?.checkedInAt ? (
                  <Typography fontSize="12px" mr="6px">
                    <span style={{ color: "#A7A6A6" }}>Visit:</span>{" "}
                    {moment(activity?.checkIn?.checkedInAt).fromNow()}
                  </Typography>
                ) : (
                  <>
                    {!skipShowCheckIn && (
                      <Typography fontSize="12px" mr="6px" color="#A7A6A6">
                        Skipped check in
                      </Typography>
                    )}
                  </>
                )}
              </Box>

              <Box display="flex" gap="4px">
                {photoItems?.length
                  ? photoItems.map((photo) => {
                      return (
                        <PhotoItem
                          repPermissions={repPermissions}
                          boxSx={{
                            height: "40px",
                            width: "40px",
                            minWidth: "40px",
                            minHeight: "40px",
                            "& > div > p": { fontSize: 3.5, fontWeight: 500 },
                          }}
                          calcCommentsSx={{
                            "& svg": {
                              width: "11px",
                              height: "11px",
                            },
                          }}
                          key={photo?.id}
                          photo={photo}
                          onOpenProductDialog={(data) => {
                            if (repPermissions) return;
                            handleCloseTooltip();
                            handleOpenProductDialog(data);
                          }}
                        />
                      );
                    })
                  : null}

                {/* {photoGroups && (
                  <PhotoItem
                    boxSx={{
                      height: "40px",
                      width: "40px",
                      minWidth: "40px",
                      minHeight: "40px",
                      "& > div > p": { fontSize: 3.5, fontWeight: 500 },
                    }}
                    calcCommentsSx={{
                      "& svg": {
                        width: "11px",
                        height: "11px",
                      },
                    }}
                    photo={{
                      ...photoGroups[0],
                      _count: {
                        ...photoGroups[0]?._count,
                        photos: photoGroups[0]?.photos?.length,
                      },
                    }}
                    onOpenProductDialog={(data) => {
                      if (repPermissions) return;
                      handleOpenProductDialog(data);
                    }}
                  />
                )} */}

                {!!notes?.length && (
                  <NoteItem
                    repPermissions={repPermissions}
                    note={notes?.[0]}
                    // showBorder
                    size="small"
                    item={activity}
                    // noteCount={checkIn.activity.notes.length}
                    handleClickCustomerNotes={() => {
                      if (repPermissions) return;
                      // handleOpenNotes(notes?.[0]?.id, checkIn, customer);
                      handleCloseTooltip();
                      handleOpenNotes({
                        customer: {
                          id: customer?.id,
                          name: customer?.displayName || customer?.name,
                          status: customer?.status || "ACTIVE",
                        },
                      });
                    }}
                  />
                )}

                {!!orders?.length && (
                  <OrderItem
                    size="small"
                    onClickOnOrder={() => {
                      if (repPermissions && !repPermissions?.orders?.view)
                        return;
                      navigate(`/orders/${orders[0].id}`);
                    }}
                    order={{ type: orders?.[0]?.type || "DIRECT" }}
                  />
                )}

                {!!ordersWithAggregations?.length && (
                  <OrderItem
                    size="small"
                    onClickOnOrder={() => {
                      if (repPermissions && !repPermissions?.orders?.view)
                        return;
                      navigate(`/orders/${ordersWithAggregations[0].id}`);
                    }}
                    order={{
                      type: ordersWithAggregations?.[0]?.type || "DIRECT",
                    }}
                  />
                )}

                {!!tasks?.length && (
                  <TaskItem
                    repPermissions={repPermissions}
                    size="small"
                    onClick={() => {
                      if (disabledTaskItem) return;
                      handleCloseTooltip();
                      handleCustomerTaskDrawer(true, tasks?.[0]);
                    }}
                    sxProp={{
                      "& .MuiBox-root:nth-child(1)": { mt: 0 },
                      "& .MuiBox-root:nth-child(2)": { mt: "-4px" },
                    }}
                  />
                )}
                {/* deleted a pic */}
                {!photoItems?.length &&
                  type === "PHOTO_GROUP_ADDED" &&
                  !notes?.length &&
                  !orders?.length && (
                    <RemovedPhotoItem
                      type="Photo"
                      removedBy={setRemovedBy()}
                      removedAt={setRemovedAt()}
                      stylesProps={stylesProps}
                    />
                  )}
                {/* deleted a note */}
                {!photoItems?.length &&
                  type === "NOTE_ADDED" &&
                  !notes?.length &&
                  !orders?.length && (
                    <RemovedPhotoItem
                      type="Note"
                      removedBy={setRemovedBy()}
                      removedAt={setRemovedAt()}
                      stylesProps={stylesProps}
                    />
                  )}
                {/* deleted a task */}
                {!photoItems?.length &&
                  type === "TASK_ADDED" &&
                  !notes?.length &&
                  !orders?.length &&
                  !tasks?.length && (
                    <RemovedPhotoItem
                      type="Task"
                      removedBy={setRemovedBy()}
                      removedAt={setRemovedAt()}
                      stylesProps={stylesProps}
                    />
                  )}
              </Box>
            </Box>
          );
        })
      ) : (
        <Typography fontSize="14px" color="#A7A6A6">
          No activities yet
        </Typography>
      )}
    </Box>
  );
};

CheckInItem.propTypes = {
  classes: object,
  repPermissions: object,
  handleOpenProductDialog: func,
  handleOpenNotes: func,
  handleCustomerTaskDrawer: func,
  handleCloseTooltip: func,
  customer: object,
  activities: object,
  currentUser: object,
};
CheckInItem.defaultProps = {
  classes: {},
  repPermissions: {},
  handleOpenProductDialog: () => {},
  handleOpenNotes: () => {},
  handleCustomerTaskDrawer: () => {},
  handleCloseTooltip: () => {},
  customer: {},
  activities: {},
  currentUser: {},
};
