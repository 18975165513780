import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet, useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";

import { Box } from "@mui/material";

import NavbarComponent from "../../components/NavbarComponent";
import UniversalConfirm from "../../components/UniversalConfirm/UniversalConfirm";
import { currentUserSelector } from "../../redux/selectors/auth";
import {
  getParentCustomersAction,
  getGroupsAction,
} from "../../redux/actions/parentCustomers";
import {
  getRepsAction,
  getRepsSummariesAction,
} from "../../redux/actions/reps";
import { getManufacturersAction } from "../../redux/actions/manufacturers";
// import { getProductCategoriesAction } from "../../redux/actions/product-categories";
import {
  getCustomersByTerritoryAction,
  getTerritoryListAction,
  getTerritoryTreeAction,
} from "../../redux/actions/territory";
import { getTagsAction } from "../../redux/actions/tags";
import {
  getGalleryAction,
  getRepsWithPhotoAction,
} from "../../redux/actions/gallery";
import { getProductsAction } from "../../redux/actions/products";
import { getPaytermsAction } from "../../redux/actions/payterms";
import { getDraftsAction } from "../../redux/actions/drafts";
import {
  setChatRooms,
  setGroupChatRooms,
  setGroupRoomsLoading,
  setRoomsLoading,
  setUnreadGroupRoomsCount,
  setUnreadRoomsCount,
} from "../../redux/actions/chat";
import { getManufacturersDiscountsAction } from "../../redux/actions/manufacturers-discounts.js";
import { getDistributorNotificationsService } from "../../services/notifications";

import { SCROLL_LIMIT, FETCH_LIMITS } from "../../utils/constants";
import { app } from "../../firebase/Chat/config";
import { getDistributorPaymentCardsAction } from "../../redux/actions/auth";
import {
  // getInvitesListAction,
  getReceivedListAction,
} from "../../redux/actions/order-directs";
import { getProductTagsListAction } from "../../redux/actions/product-tags";
import { getTransactionsAction } from "../../redux/actions/transactions";
import { confirmDialogFormChangedSelector } from "../../redux/selectors/confirmDialogs";
import { openDiscardChanges } from "../../redux/actions/confirmDialogs";
import history from "../../utils/history";
import {
  useLogInRedirect,
  useOffline,
  useOnBoardingTasks,
  useRepsPermissions,
} from "helpers/hooks";
import OfflinePage from "../OfflinePage";
import {
  PaymentFailed,
  ReachedUsersLimitDialog,
  // ConfirmSelectionPlanDialog,
  ConfirmedSelectionPlanDialog,
  TrialDialog,
  StatusWidget,
  SnackbarComponent,
  Loader,
} from "components";
import {
  setFlatColumnLayoutsAction,
  setSortedColumnLayoutsAction,
  setDirectColumnLayoutsAction,
  setThirdPartyColumnLayoutsAction,
  setRepAllColumnLayoutsAction,
  setDraftsColumnLayoutsAction,
} from "../../redux/actions/settings";
import { addSyncStatusAction } from "../../redux/actions/integrations";
import { createAxiosResponseInterceptor } from "../../helpers/auth";
import { toggleTrialDialogOpenAction } from "redux/actions/subscriptionDialogs";
import { useRepresentative } from "helpers/helpers";
import { resetSnackbarAction, setSnackbarAction } from "redux/actions/snackbar";
import { error } from "utils/notifications";
import {
  connectToFirebaseSnackbarSelector,
  openSnackbarSelector,
} from "redux/selectors/snackbar";
import { PermissionsLayout } from "Layouts";

export const GlobalPageContext = createContext();

const selector = createSelector(
  currentUserSelector,
  confirmDialogFormChangedSelector,
  openSnackbarSelector,
  connectToFirebaseSnackbarSelector,
  (currentUser, formChanged, openSnackbar, connectToFirebaseSnackbar) => ({
    currentUser,
    formChanged,
    openSnackbar,
    connectToFirebaseSnackbar,
  })
);

let unsubscribe;

const MasterPage = () => {
  const isRepresentative = useRepresentative();
  const dispatch = useDispatch();
  const firestore = getFirestore(app);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, formChanged, openSnackbar, connectToFirebaseSnackbar } =
    useSelector(selector);

  const [globalLoading, setGlobalLoading] = useState(false);
  const [showErrorWidget, setShowErrorWidget] = useState(false);
  const { redirectToLocalStoragePath } = useLogInRedirect();

  const [repsCollection] = useDocument(
    collection(getFirestore(app), "representatives"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const repRoomsRef = collection(
    firestore,
    `representatives/${repsCollection?.docs[0]?.id}/rooms`
  );

  const userId = useMemo(() => currentUser?.id, [currentUser?.id]);

  const [integrationsCollection] = useDocument(
    collection(getFirestore(app), `integrations/${userId}/sync`)
  );

  const onWidgetStatusClick = () => {
    navigate("/settings?tab=Integrations");
    setShowErrorWidget(false);
  };

  useEffect(() => {
    setShowErrorWidget(false);
    integrationsCollection?.docs?.forEach((d) => {
      const doc = d.data();
      if (doc.status === "ERROR") setShowErrorWidget(false);
      dispatch(addSyncStatusAction({ [d.id]: doc }));
    });
  }, [integrationsCollection?.docs, dispatch]);

  const groupChatRoomsRef = collection(firestore, `group-chats`);

  const [representativeRooms, representativeRoomsLoading] = useCollection(
    query(repRoomsRef, orderBy("updatedAt", "desc"))
  );
  const [groupChatRooms, groupChatRoomsLoading] = useCollection(
    query(groupChatRoomsRef, orderBy("updatedAt", "desc"))
  );

  useEffect(redirectToLocalStoragePath, [redirectToLocalStoragePath]);

  useEffect(() => {
    if (isRepresentative) return;
    const rooms = representativeRooms?.docs?.filter(
      (room) => room.data()?.distributorId === currentUser.id
    );
    const unreadRooms = rooms?.filter(
      (doc) => !doc.data().readBy?.find((id) => id === currentUser.id)
    );

    const groupRooms = groupChatRooms?.docs?.filter((room) => {
      return room.data()?.participants?.some((user) => user === currentUser.id);
    });

    const unreadGroupRooms = groupRooms?.filter(
      (doc) => !doc.data().readBy?.find((id) => id === currentUser.id)
    );

    dispatch(setUnreadRoomsCount(unreadRooms?.length || 0));
    dispatch(setChatRooms(rooms || []));

    dispatch(setUnreadGroupRoomsCount(unreadGroupRooms?.length || 0));
    dispatch(setGroupChatRooms(groupRooms || []));
  }, [
    representativeRooms,
    currentUser?.id,
    dispatch,
    groupChatRooms?.docs,
    isRepresentative,
  ]);

  useEffect(() => {
    dispatch(setRoomsLoading(representativeRoomsLoading));
  }, [representativeRoomsLoading, dispatch]);

  useEffect(() => {
    dispatch(setGroupRoomsLoading(groupChatRoomsLoading));
  }, [groupChatRoomsLoading, dispatch]);

  // const isLoading = useMemo(
  //   () => territoryLoading || paytermsLoading || discountsLoading,
  //   [territoryLoading, paytermsLoading, discountsLoading]
  // );

  const online = useOffline();

  useEffect(() => {
    if (online) createAxiosResponseInterceptor();
  }, [online]);

  useEffect(() => {
    if (
      !currentUser?.distributorSubscription &&
      currentUser?.subscriptionUsageStatus === "TRIAL_USED"
    )
      navigate("/");
  }, [
    currentUser?.distributorSubscription,
    currentUser?.subscriptionUsageStatus,
    navigate,
  ]);

  useEffect(() => {
    // trial dialog open/close
    dispatch(toggleTrialDialogOpenAction());
  }, [dispatch]);

  useEffect(() => {
    const columnLayouts = currentUser?.customerLayoutSettings;
    const columnLayoutsOrder = currentUser?.orderLayoutSettings;
    const columnLayoutsRep = currentUser?.representativeLayoutSettings;

    if (columnLayouts && Object.keys(columnLayouts).length !== 0) {
      if (columnLayouts?.flat)
        dispatch(setFlatColumnLayoutsAction(columnLayouts.flat));
      if (columnLayouts?.sorted)
        dispatch(setSortedColumnLayoutsAction(columnLayouts.sorted));
      if (columnLayouts?.direct)
        dispatch(setDirectColumnLayoutsAction(columnLayouts.order));
      if (columnLayouts?.thirdParty)
        dispatch(setThirdPartyColumnLayoutsAction(columnLayouts.order));
      if (columnLayouts?.drafts)
        dispatch(setDraftsColumnLayoutsAction(columnLayouts.drafts));
    }
    if (columnLayoutsOrder && Object.keys(columnLayoutsOrder).length !== 0) {
      if (columnLayoutsOrder?.direct)
        dispatch(setDirectColumnLayoutsAction(columnLayoutsOrder.direct));
      if (columnLayoutsOrder?.thirdParty)
        dispatch(
          setThirdPartyColumnLayoutsAction(columnLayoutsOrder.thirdParty)
        );
      if (columnLayoutsOrder?.drafts)
        dispatch(setDraftsColumnLayoutsAction(columnLayoutsOrder.drafts));
    }
    if (columnLayoutsRep && Object.keys(columnLayoutsRep)) {
      dispatch(setRepAllColumnLayoutsAction(columnLayoutsRep));
    }
  }, [
    currentUser,
    currentUser?.customerLayoutSettings,
    currentUser?.orderLayoutSettings,
    dispatch,
  ]);

  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP" && formChanged) {
        dispatch(openDiscardChanges(() => navigate(-1)));
        history.push(location.pathname);
      }
    });
  }, [formChanged, dispatch, location.pathname, navigate]);

  const { onBoardingTasks } = useOnBoardingTasks();
  const repPermissions = useRepsPermissions();

  useEffect(() => {
    if (onBoardingTasks.shopping_cart) {
      if (repPermissions) {
        if (repPermissions?.orders?.create_edit) {
          dispatch(getDraftsAction());
        }
      } else {
        // if (repPermissions && !repPermissions?.orders?.create_edit) return;
        dispatch(getDraftsAction());
      }
    }

    if (!repPermissions || (repPermissions && repPermissions?.customers?.view))
      dispatch(
        getGroupsAction({
          limit: FETCH_LIMITS.CUSTOMERS,
          limit_sub_customers: FETCH_LIMITS.SUB_CUSTOMERS,
        })
      );

    if (!repPermissions || (repPermissions && repPermissions?.customers?.view))
      dispatch(
        getParentCustomersAction({
          limit: FETCH_LIMITS.SUB_CUSTOMERS,
        })
      );

    const allowFetchReps = location.pathname !== "/representatives";

    if (
      allowFetchReps &&
      (!repPermissions || (repPermissions && repPermissions?.hasAnyCheckedTrue))
    )
      dispatch(getRepsAction({ limit: SCROLL_LIMIT }, { isScrolling: false }));

    if (
      !repPermissions ||
      (repPermissions && repPermissions?.customers?.territories)
    ) {
      dispatch(getTerritoryTreeAction());
    }

    if (
      !repPermissions ||
      (repPermissions && repPermissions?.representatives?.view)
    ) {
      dispatch(getRepsSummariesAction());
    }

    if (
      !repPermissions ||
      (repPermissions && repPermissions?.customers?.view) ||
      (repPermissions && repPermissions?.routes?.view) ||
      (repPermissions && repPermissions?.discounts?.view)
    )
      dispatch(getTerritoryListAction());

    dispatch(getTagsAction({ limit: SCROLL_LIMIT }));

    if (
      !repPermissions ||
      (repPermissions && repPermissions?.customers?.gallery)
    )
      dispatch(getGalleryAction({ limit: FETCH_LIMITS.GALLERY }));

    const allowFetchCatalog = location.pathname !== "/catalog";

    if (
      allowFetchCatalog &&
      (!repPermissions || (repPermissions && repPermissions?.catalog?.view))
    ) {
      dispatch(getProductsAction({ query: { limit: FETCH_LIMITS.PRODUCTS } }));
    }

    if (
      !repPermissions ||
      (repPermissions && repPermissions?.customers?.territories)
    )
      dispatch(getCustomersByTerritoryAction());

    if (
      !repPermissions ||
      (repPermissions && repPermissions?.hasAnyCheckedTrue)
    )
      dispatch(getManufacturersAction({ limit: SCROLL_LIMIT }));

    if (
      !repPermissions ||
      (repPermissions &&
        (repPermissions?.settings?.payment_terms ||
          repPermissions?.customers?.create_edit ||
          repPermissions?.orders?.create_edit))
    ) {
      dispatch(getPaytermsAction());
    }

    if (!repPermissions || (repPermissions && repPermissions?.settings?.orders))
      getDistributorNotificationsService();

    if (!repPermissions || (repPermissions && repPermissions?.discounts?.view))
      dispatch(
        getManufacturersDiscountsAction({
          limit: SCROLL_LIMIT,
          discount_limit: FETCH_LIMITS.DISCOUNTS,
        })
      );

    if (
      !repPermissions ||
      (repPermissions && repPermissions?.customers?.gallery)
    )
      dispatch(getRepsWithPhotoAction({ limit: SCROLL_LIMIT }));

    if (!repPermissions) dispatch(getDistributorPaymentCardsAction());

    if (!repPermissions)
      dispatch(getReceivedListAction({ limit: FETCH_LIMITS.INVITES }));

    if (
      !repPermissions ||
      (repPermissions && repPermissions?.hasAnyCheckedTrue)
    )
      dispatch(getProductTagsListAction({ limit: FETCH_LIMITS.PRODUCT_TAGS }));

    if (!repPermissions)
      dispatch(getTransactionsAction({ limit: SCROLL_LIMIT }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, onBoardingTasks.shopping_cart, repPermissions]);

  const fetchDeliveriesCollection = useCallback(() => {
    unsubscribe = onSnapshot(
      doc(firestore, `bulk-actions/${currentUser?.id}/deliveries/data`),
      { includeMetadataChanges: true },
      (doc) => {
        const data = doc.data();
        dispatch(
          setSnackbarAction({
            progress: data?.progress,
            countOrders: data?.countOrders,
            status: data?.status,
          })
        );
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        error(`Firebase: ${err?.message || "Something went wrong"}`);
      }
    );
  }, [currentUser?.id, dispatch, firestore]);

  useEffect(() => {
    if (currentUser?.id && connectToFirebaseSnackbar) {
      fetchDeliveriesCollection();
    }

    if (currentUser?.id && !connectToFirebaseSnackbar && unsubscribe) {
      unsubscribe();
      dispatch(resetSnackbarAction());
    }
  }, [
    connectToFirebaseSnackbar,
    currentUser?.id,
    dispatch,
    fetchDeliveriesCollection,
  ]);

  return (
    <GlobalPageContext.Provider value={{ globalLoading, setGlobalLoading }}>
      {globalLoading && <Loader isLoading={globalLoading} />}
      <TrialDialog />
      <PaymentFailed />
      <ReachedUsersLimitDialog />
      {/* <ConfirmSelectionPlanDialog /> */}
      <ConfirmedSelectionPlanDialog />
      {openSnackbar ? <SnackbarComponent /> : null}
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F8F8FA",
        }}
      >
        <UniversalConfirm />
        <NavbarComponent />
        <Box
          sx={{
            flexGrow: 1,
            backgroundColor: "#F8F8FA",
            mb: location.pathname === "/" ? "20px" : 0,
            position: "relative",
          }}
          component="main"
        >
          {showErrorWidget && (
            <StatusWidget onWidgetStatusClick={onWidgetStatusClick} />
          )}
          {online ? (
            <PermissionsLayout>
              <Outlet />
            </PermissionsLayout>
          ) : (
            <OfflinePage />
          )}
        </Box>
      </Box>
    </GlobalPageContext.Provider>
  );
};

export default MasterPage;
