import { useContext, useMemo, useState } from "react";
import { func } from "prop-types";
import moment from "moment";
import {
  Box,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";

import useStyles from "./styles";
import {
  ActionRestrictionWrapper,
  PaperWrapper,
  StyledButton,
  StyledSelect,
  StyledTooltip,
} from "components";
import {
  ArrowDropDownIcon,
  CopyIconFilled,
  EmailIcon,
  InfoIcon,
  NoteIcon,
  OrderIcon,
  TaskIcon,
} from "components/Icons";
import { RowInfo } from "./components";
import { CustomerProfileContext } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfilePage";
import {
  handleCopyToBuffer,
  normalizeSnakeCaseString,
  setPhoneNumberMask,
  setWebsiteMask,
} from "helpers/helpers";
import { useCustomerActions } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfile.hooks";
import OrderDirectAccess from "Pages/CustomersPage/pages/NewCustomerPage/OrderDirectAccess/OrderDirectAccess";
import { PRIMARY_PAYMENT_METHODS } from "Pages/CustomersPage/pages/CustomerProfilePage/CustomerProfile.constants";
import { useOrderDirectConfigured } from "helpers/hooks";
import { usePersonalDetails } from "Pages/SettingsPage/components/SettingsTabs/PaymentsTab/components/PayoutsTab/PayoutsTab.hooks";
import EmptyCartPopup from "./EmptyCartPopup";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
  CUSTOMER_STATUS_FILTERS,
  PRICE_LIST_TYPES,
} from "utils/constants";
import { RatingBlock } from "Pages/CustomersPage/pages/CustomerPage/components/CustomerMainBody/components/RatingBlock";
import { handleCustomerCategories } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage.helpers";
import { CustomerImage } from "components/CustomerImage/CustomerImage";
import { getInviteColor } from "./helpers";

const CustomerDescription = ({ handleOpenEmailDialog }) => {
  const classes = useStyles();

  const {
    handleEditCustomer,
    handleCustomerStatus,
    // handleSaveTags,
    handleSendInvite,
    handleInviteNow,
    handleCancelInvite,
    customerId,
    debouncedHandleCustomerStatus,
    currentUser,
  } = useCustomerActions();

  const { allowedToAddCard, combineOrderDirectWithBankText } =
    usePersonalDetails({
      isSetup: false,
    });

  const orderDirectConfigured = useOrderDirectConfigured();

  const {
    customerState,
    handleOpenNote,
    handleOpenTask,
    handleOpenOrder,
    emptyCartPopupOpen,
    setEmptyCartPopupOpen,
    navigateToOrderCreate,
    repPermissions,
    isAdmin,
  } = useContext(CustomerProfileContext);

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.customers?.create_edit;
    }
  }, [repPermissions]);

  // const [tagsOpen, setTagsOpen] = useState(false);
  const [isHoveringElement, setIsHoveringElement] = useState(false);
  const [orderDirectState, setOrderDirectState] = useState({ open: false });
  // const tagsList = useSelector(customerTagsListSelector);
  const customer = useMemo(() => customerState.customer, [customerState]);

  const { allowStoreCreditCardPayments } = currentUser || {};

  const disableOrderDirect = useMemo(() => {
    if (
      !allowStoreCreditCardPayments &&
      customer?.paymentTermsDuplicate?.type === "ADVANCE_PAYMENT"
    )
      return true;
    return !orderDirectConfigured || !allowedToAddCard;
  }, [
    allowStoreCreditCardPayments,
    allowedToAddCard,
    customer?.paymentTermsDuplicate?.type,
    orderDirectConfigured,
  ]);

  const {
    name,
    displayName,
    email,
    phone,
    customId,
    // federalTaxId,
    percentDiscount,
    territory,
    paymentTermsDuplicate,
    createdAt,
    lastLoggedInAt,
    // tags,
    createdByType,
    createdBy,
    createByBackOfficeWorker,
    orderDirectInvite,
    contacts,
    website,
    primaryPaymentMethod,
    parentCustomer,
    status,
    images,
    placeId,
    priceList,
  } = useMemo(() => customer || {}, [customer]);

  const phoneNumber = useMemo(() => {
    return phone?.replace("+", "")?.length
      ? setPhoneNumberMask(`${phone}`)
      : "";
  }, [phone]);

  const showRevokeBtn = useMemo(
    () => isHoveringElement && orderDirectInvite?.status === "APPROVED",
    [isHoveringElement, orderDirectInvite?.status]
  );

  const showCancelBtn = useMemo(
    () => isHoveringElement && orderDirectInvite?.status === "PENDING",
    [isHoveringElement, orderDirectInvite?.status]
  );

  const CUSTOMER_ACTIONS = useMemo(() => {
    const actions = [
      {
        label: "Order",
        icon: <OrderIcon />,
        disabled:
          (repPermissions && !repPermissions?.orders?.create_edit) || isAdmin,
        disabledPermissions:
          repPermissions && !repPermissions?.orders?.create_edit,
        onClick: () => {
          handleOpenOrder();
          // if (!hasDraftCustomer) {
          //   handleOpenOrder(customer);
          // } else {
          //   setEmptyCartPopupOpen(true);
          // }
        },
      },
      {
        label: "Email",
        icon: <EmailIcon />,
        disabled: isAdmin,
        onClick: () => handleOpenEmailDialog(),
      },
      {
        label: "Task",
        icon: <TaskIcon />,
        disabled: disabledCreateEdit,
        disabledPermissions: disabledCreateEdit,
        onClick: () => handleOpenTask(),
      },
      {
        label: "Note",
        icon: <NoteIcon />,
        disabledPermissions: disabledCreateEdit,
        disabled: disabledCreateEdit,
        onClick: () => handleOpenNote(),
      },
    ];

    return actions;
  }, [
    disabledCreateEdit,
    handleOpenEmailDialog,
    handleOpenNote,
    handleOpenOrder,
    handleOpenTask,
    isAdmin,
    repPermissions,
  ]);

  const preparedWebsite = website ? setWebsiteMask(website) : "";
  const { domainName } = preparedWebsite || {};

  const websiteLink = useMemo(() => {
    if (!website) return "-";
    return (
      <Link
        sx={{
          cursor: "pointer",
          color: "inherit",
          textDecorationColor: "inherit",
        }}
        href={website}
        target="_blank"
      >
        {domainName}
      </Link>
    );
  }, [domainName, website]);

  const disabledStatusBtn =
    customer?.activeOrderCount > 0 ||
    customer?.countSubCustomerPendingOrders > 0;
  const [showTooltip, setShowTooltip] = useState(false);

  const setGroupName = useMemo(() => {
    if (parentCustomer) {
      return (
        (!parentCustomer?.isUncategorized && parentCustomer?.name) ||
        "Uncategorized"
      );
    } else {
      return "Parent";
    }
  }, [parentCustomer]);

  const INFO_ROWS = useMemo(
    () => [
      {
        title: "Status",
        label: (
          <StyledTooltip
            placement="top"
            arrow
            sx={{
              "& .MuiTooltip-tooltip": {
                maxWidth: "315px",
              },
            }}
            title={
              disabledCreateEdit
                ? ADMIN_ONLY_VIEW_MESSAGE
                : "Customers with pending orders can't be archived."
            }
            open={showTooltip}
            onOpen={() => {
              if (
                customer?.activeOrderCount > 0 ||
                customer?.countSubCustomerPendingOrders > 0 ||
                disabledCreateEdit
              ) {
                setShowTooltip(true);
              }
            }}
            onClose={() => setShowTooltip(false)}
          >
            <Box
              component="span"
              onMouseEnter={() => {
                if (
                  customer?.activeOrderCount > 0 ||
                  customer?.countSubCustomerPendingOrders > 0 ||
                  disabledCreateEdit
                ) {
                  setShowTooltip(true);
                }
              }}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <StyledSelect
                disabled={disabledCreateEdit || disabledStatusBtn}
                className={classes.statusSelect}
                displayEmpty
                onChange={debouncedHandleCustomerStatus}
                value={status}
                iconStyles={{
                  top: "auto",
                  transform: "none",
                }}
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem value={CUSTOMER_STATUS_FILTERS.active}>
                  Active
                </MenuItem>
                <MenuItem value={CUSTOMER_STATUS_FILTERS.inactive}>
                  Inactive
                </MenuItem>
                <MenuItem value={CUSTOMER_STATUS_FILTERS.prospect}>
                  Prospect
                </MenuItem>
              </StyledSelect>
            </Box>
          </StyledTooltip>
        ),
        labelProps: { className: classes.status },
      },
      {
        title: "Customer ID",
        label: customId?.customId?.toUpperCase(),
      },
      {
        title: "Group",
        label: setGroupName,
      },
      {
        title: "Percent Discount",
        label: percentDiscount,
      },
      {
        title: "Website",
        label: websiteLink,
      },
      {
        title: "Territory",
        label: territory?.name ?? "Uncategorized",
      },
      {
        title: "Terms",
        label: paymentTermsDuplicate?.name,
      },
      {
        title: "Primary payment method",
        label: PRIMARY_PAYMENT_METHODS[primaryPaymentMethod],
        hidden:
          !primaryPaymentMethod ||
          !Object.keys(PRIMARY_PAYMENT_METHODS).includes(primaryPaymentMethod),
      },
      {
        title: "Price list",
        label: priceList ? priceList?.name || "" : PRICE_LIST_TYPES.STANDARD,
      },
    ],
    [
      disabledCreateEdit,
      showTooltip,
      disabledStatusBtn,
      classes.statusSelect,
      classes.status,
      debouncedHandleCustomerStatus,
      status,
      customId?.customId,
      setGroupName,
      percentDiscount,
      websiteLink,
      territory?.name,
      paymentTermsDuplicate?.name,
      primaryPaymentMethod,
      priceList,
      customer?.activeOrderCount,
      customer?.countSubCustomerPendingOrders,
    ]
  );

  const INFO_ROWS_2 = useMemo(
    () => [
      {
        title: "Created on",
        label: moment(createdAt).format("MMM DD, YY [at] h:mm A"),
      },
      {
        title: "Created via",
        label: normalizeSnakeCaseString(createdByType) || "Web Admin",
      },
      {
        title: "Created by",
        label: createdBy?.name || createByBackOfficeWorker?.name || "You",
      },
    ],
    [createByBackOfficeWorker?.name, createdAt, createdBy?.name, createdByType]
  );

  const ratingOptions = useMemo(() => {
    return {
      rating: customer.rating,
      reviews: customer.stats ? customer.stats.totalRatings : 0,
      price: customer.price,
      categories: handleCustomerCategories(customer.categories),
      widthCheck: !customer.price ? 12 : 6,
    };
  }, [customer.categories, customer.price, customer.rating, customer.stats]);

  return (
    <PaperWrapper>
      <EmptyCartPopup
        isOpen={emptyCartPopupOpen}
        handleClose={() => setEmptyCartPopupOpen(false)}
        onSubmit={() => navigateToOrderCreate()}
      />
      <OrderDirectAccess
        isOpen={orderDirectState.open}
        customerInfo={customer}
        contacts={contacts.filter((c) => !!c?.email)}
        handleSendInvite={handleInviteNow}
        handleClose={() =>
          setOrderDirectState((prev) => ({ ...prev, open: false }))
        }
        customerId={customerId}
        handleCustomerStatus={handleCustomerStatus}
      />
      <Box className={classes.headerWrap}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Box
            display="flex"
            gap="10px"
            alignItems="center"
            sx={{ overflow: "hidden" }}
          >
            {/* <Box className={classes.picture} /> */}
            <CustomerImage
              url={images.length ? images[0] : ""}
              placeId={placeId}
            />
            {/*<StyledAvatar
              className={classes.picture}
              name={displayName || name}
              src={photoUrl(appCustomer?.profilePhoto?.fileName)}
            />*/}
            <Box
              className={classes.headerInfo}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              sx={{ maxWidth: "100%", overflow: "hidden" }}
            >
              <Box>
                <Typography className="name" noWrap>
                  {displayName || name}
                </Typography>
              </Box>
              <Box>
                {email ? (
                  <Typography noWrap color="primary" className="website">
                    {email}
                  </Typography>
                ) : null}

                <Typography
                  noWrap
                  color="edit.main"
                  className="phone"
                  component={Link}
                  underline="none"
                  href={`tel:${phoneNumber ?? "#"}`}
                >
                  {phoneNumber ?? "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.descriptionUtils}>
            <ActionRestrictionWrapper
              disableHoverListener={!disabledCreateEdit}
            >
              <StyledButton
                className={classes.edit}
                disabled={disabledCreateEdit}
                label="Edit"
                onClick={() => handleEditCustomer(customer)}
              />
            </ActionRestrictionWrapper>
            {!!email && (
              <IconButton
                className={classes.copy}
                onClick={() =>
                  handleCopyToBuffer({ text: email, message: "Email Copied!" })
                }
              >
                <CopyIconFilled />
              </IconButton>
            )}
          </Box>
        </Box>

        {!!customer.rating && (
          <Stack mt="6px">
            <RatingBlock {...ratingOptions} />
          </Stack>
        )}

        <Box
          className={classes.actionsWrap}
          sx={{ mt: customer.rating ? "1px" : "7px" }}
        >
          {CUSTOMER_ACTIONS.map(
            ({ label, icon, onClick, disabled, disabledPermissions }) => (
              <ActionRestrictionWrapper
                disableHoverListener={!disabledPermissions}
                title={ADMIN_ONLY_VIEW_MESSAGE}
                key={label}
              >
                <IconButton
                  className={classes.actionButton}
                  disabled={disabled}
                  onClick={onClick}
                >
                  <Box
                    className={classes.actionIcon}
                    sx={{
                      backgroundColor: disabled ? "#CBCBCB" : "#47A06D",
                    }}
                  >
                    {icon}
                  </Box>
                  <Typography color="edit.main">{label}</Typography>
                </IconButton>
              </ActionRestrictionWrapper>
            )
          )}
        </Box>
      </Box>
      <Box className={classes.infoSection}>
        {INFO_ROWS.filter((row) => !row?.hidden).map(
          ({ title, label, labelProps }) => (
            <RowInfo
              key={title}
              title={title}
              label={label}
              labelProps={labelProps}
            />
          )
        )}
      </Box>
      <Box className={classes.infoSection}>
        {INFO_ROWS_2.map(({ title, label, labelProps }) => (
          <RowInfo
            key={title}
            title={title}
            label={label}
            labelProps={labelProps}
          />
        ))}
        <Box>
          <Box mt={2} borderTop="0.5px solid #D5D9D9">
            <Grid
              container
              width="100%"
              alignItems="flex-start"
              justifyContent="space-between"
              flexWrap="nowrap"
              m="6px 0 5px"
            >
              <Grid container flexWrap="nowrap" alignItems="center" gap="10px">
                <Typography
                  fontSize={13}
                  fontWeight={500}
                  sx={{ textWrap: "nowrap" }}
                  color="#AAAAAA"
                  mt="8px"
                >
                  Order Direct
                </Typography>
                <StyledTooltip
                  sx={{
                    marginBottom: "-10px !important",
                  }}
                  title={`Last logged in ${
                    lastLoggedInAt
                      ? moment(lastLoggedInAt).format("MMM DD, YY [at] h:mm A")
                      : "never"
                  } `}
                  arrow
                  placement="top"
                >
                  <Box display="flex" alignItems="flex-end" pt="7.5px">
                    <InfoIcon
                      width="11.1px"
                      height="11.4px"
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                </StyledTooltip>
              </Grid>

              {!orderDirectInvite && orderDirectInvite?.status !== "PENDING" && (
                <ActionRestrictionWrapper
                  title={
                    repPermissions
                      ? ADMIN_ONLY_VIEW_MESSAGE_PAGE
                      : combineOrderDirectWithBankText(customer)
                  }
                  disableHoverListener={!(disableOrderDirect || repPermissions)}
                >
                  <Box
                    sx={{
                      mt: 0.8,
                      cursor: "pointer",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    color={disableOrderDirect ? "grey" : "primary"}
                    underline={disableOrderDirect ? "none" : "always"}
                    component={Link}
                    onClick={() => {
                      if (disableOrderDirect || repPermissions) return;
                      setOrderDirectState((prev) => ({
                        ...prev,
                        open: true,
                      }));
                    }}
                  >
                    Invite
                  </Box>
                </ActionRestrictionWrapper>
              )}

              {orderDirectInvite?.status === "PENDING" && (
                <StyledTooltip
                  title={combineOrderDirectWithBankText(customer)}
                  placement="top"
                  arrow
                  disableHoverListener={!(disableOrderDirect || repPermissions)}
                >
                  <Box
                    sx={{
                      mt: 0.8,
                      textWrap: "nowrap",
                      cursor: "pointer",
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                    component={Link}
                    onClick={() => {
                      if (disableOrderDirect || repPermissions) return;
                      handleSendInvite({
                        email: orderDirectInvite?.email,
                      });
                    }}
                  >
                    Resend Invite
                  </Box>
                </StyledTooltip>
              )}
            </Grid>

            {!!orderDirectInvite && (
              <Box
                maxHeight="235px"
                overflow="auto"
                mt="13px"
                onMouseEnter={() => {
                  setIsHoveringElement(true);
                }}
                onMouseLeave={() => setIsHoveringElement(false)}
              >
                <Box>
                  <Box
                    sx={{
                      background: "#F8F8F8",
                      borderRadius: "4px",
                      padding: "7px 15px",
                      border: "1px solid #D5D9D9",
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "nowrap",
                      width: "100%",
                      gap: "5px",
                      height: "100%",
                    }}
                  >
                    <Box width="calc(100% - 65px)" height="100%">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            fontWeight: "bold",
                            color: "#5F6267",
                            textWrap: "nowrap",
                          }}
                        >
                          {orderDirectInvite.role}
                        </Typography>
                      </Box>
                      <Typography noWrap sx={{ fontSize: "13px" }}>
                        {orderDirectInvite.name}
                      </Typography>
                      <Typography
                        noWrap
                        sx={{
                          fontSize: "13px",
                          color: "#5F6267",
                          fontWeight: 300,
                        }}
                      >
                        {orderDirectInvite.email}
                      </Typography>
                      <Typography
                        noWrap
                        sx={{ fontSize: "13px", color: "#5F6267" }}
                      >
                        {orderDirectInvite.phone}
                      </Typography>
                    </Box>

                    <Box>
                      {orderDirectInvite.status && (
                        <Box>
                          <Typography
                            fontSize={9}
                            sx={{
                              mt: "1px",
                              p: "2px 4.7px 1px",
                              color: "#FFFFFF",
                              borderRadius: "3px",
                              width: "fit-content",
                              display:
                                showRevokeBtn || showCancelBtn
                                  ? "none"
                                  : "block",
                              background: getInviteColor(
                                orderDirectInvite.status
                              ),
                            }}
                          >
                            {orderDirectInvite.status}
                          </Typography>
                        </Box>
                      )}
                      <Box
                        sx={{
                          height: "19px",
                          display: showRevokeBtn ? "flex" : "none",
                        }}
                      >
                        <ActionRestrictionWrapper
                          disableHoverListener={!disabledCreateEdit}
                        >
                          <StyledButton
                            label="Revoke"
                            disabled={disabledCreateEdit}
                            variant="outlined"
                            color="ground"
                            fontSize="10px"
                            fontWeight="500"
                            className={classes.buttons}
                            onClick={() =>
                              handleCancelInvite(orderDirectInvite?.id, true)
                            }
                          />
                        </ActionRestrictionWrapper>
                      </Box>
                      <Box
                        sx={{
                          height: "19px",
                          display: showCancelBtn ? "flex" : "none",
                        }}
                      >
                        <ActionRestrictionWrapper
                          disableHoverListener={!disabledCreateEdit}
                        >
                          <StyledButton
                            label="Cancel"
                            disabled={disabledCreateEdit}
                            variant="outlined"
                            color="ground"
                            fontSize="10px"
                            fontWeight="500"
                            className={classes.buttons}
                            onClick={() =>
                              handleCancelInvite(orderDirectInvite?.id)
                            }
                          />
                        </ActionRestrictionWrapper>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </PaperWrapper>
  );
};

CustomerDescription.propTypes = {
  handleOpenEmailDialog: func,
};

export default CustomerDescription;
