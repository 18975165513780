import { NotificationIcon } from "components/Icons";
import { OrdersIcon, RoutesIcon } from "components/Icons/NavbarIcons";
import {
  CommentIcon,
  FormIcon,
  StoreIcon,
  TaskIcon,
} from "components/Icons/Notifications";

export const TABS = ["Unread", "Read"];

export const ANIMATION_CHECK_TIME = 2100;
export const DEBOUNCED_READ_TIME = 1000;

export const DEFAULT_STATE = {
  [TABS[0]]: {
    list: [],
    loading: false,
    count: 0,
  },
  [TABS[1]]: {
    list: [],
    loading: false,
    count: 0,
  },
};

export const SETTING_SALES_TABS = {
  orders: {
    order_created: true,
    order_canceled: true,
    online_order_payment: true,
    overdue_payment: true,
    overdue_fulfillment: true,
  },
  comments: {
    photo_commented: true,
  },
  notes: {
    note_created: true,
  },
  customers: {
    customer_assignments: true,
    license_expired: true,
    storefront_access: true,
  },
  routes: {
    route_assignments: true,
    route_completed: true,
    route_edited: true,
    route_deleted: true,
  },
  tasks: {
    task_assignments: true,
    task_completed: true,
    task_overdue: true,
    task_deleted: true,
  },
  //forms: {
  //  form_assignments: true,
  //  form_edited: true,
  //},
};
export const SETTING_MERCHANDISER_TABS = {
  comments: {
    photo_commented: true,
  },
  notes: {
    note_created: true,
  },
  customers: {
    customer_assignments: true,
    license_expired: true,
    storefront_access: true,
  },
  routes: {
    route_assignments: true,
    route_completed: true,
    route_edited: true,
    route_deleted: true,
  },
  tasks: {
    task_assignments: true,
    task_completed: true,
    task_overdue: true,
    task_deleted: true,
  },
  //forms: {
  //  form_assignments: true,
  //  form_edited: true,
  //},
};

export const SETTING_ICONS = {
  orders: {
    icon: OrdersIcon,
    iconProps: {
      strokeColor: "#A5A5A5",
      width: 17,
      height: 20,
    },
  },
  comments: {
    icon: CommentIcon,
  },
  notes: {
    icon: NotificationIcon,
    iconProps: {
      sx: {
        width: 20.5,
        height: 20.5,
        "& path": {
          stroke: "none !important",
        },
      },
    },
  },
  customers: {
    icon: StoreIcon,
    iconProps: {
      //fill: "#A5A5A5",
      sx: {
        width: 21,
        height: 21,
        fill: "none",
      },
    },
  },
  routes: {
    icon: RoutesIcon,
    iconProps: {
      width: 19,
      height: 19,
      strokeParams: { stroke: "#A5A5A5", strokeWidth: 1.4 },
    },
  },
  tasks: { icon: TaskIcon, iconProps: { width: 18, height: 22 } },
  forms: {
    icon: FormIcon,
  },
};

export const NOTIFICATION_TYPES = {
  ORDER_PAID: "ORDER_PAID",
  ORDER_CREATED: "ORDER_CREATED",
  ORDER_CANCELED: "ORDER_CANCELED",

  FULFILLMENT_OVERDUE: "FULFILLMENT_OVERDUE",

  NOTE_ADDED: "NOTE_CREATED",

  ONLINE_ORDER_PAID: "ONLINE_ORDER_PAID",
  ONLINE_ORDER_CREATED: "ONLINE_ORDER_CREATED",
  ONLINE_ORDER_CANCELLED: "ONLINE_ORDER_CANCELLED",

  PHOTO_GROUP_COMMENTED: "PHOTO_GROUP_COMMENTED",

  TASK_OVERDUE: "TASK_OVERDUE",
  TASK_DELETED: "TASK_DELETED",
  TASK_ASSIGNED: "TASK_ASSIGNED",
  TASK_COMPLETED: "TASK_COMPLETED",
  TASK_UNASSIGNED: "TASK_UNASSIGNED",

  ROUTE_EDITED: "ROUTE_EDITED",
  ROUTE_DELETED: "ROUTE_DELETED",
  ROUTE_ASSIGNED: "ROUTE_ASSIGNED",
  ROUTE_ACTIVATED: "ROUTE_ACTIVATED",
  ROUTE_COMPLETED: "ROUTE_COMPLETED",
  ROUTE_UNASSIGNED: "ROUTE_UNASSIGNED",
  ROUTE_DEACTIVATED: "ROUTE_DEACTIVATED",

  CUSTOMER_ASSIGNED: "CUSTOMER_ASSIGNED",
  CUSTOMER_UNASSIGNED: "CUSTOMER_UNASSIGNED",

  PAYMENT_OVERDUE: "PAYMENT_OVERDUE",

  LICENSE_EXPIRED: "LICENSE_EXPIRED",
};

export const NOTE_AVAILABLE_TEXT =
  "Notification unavailable: The subject is no longer relevant or has been deleted.";
