import { Tab, Tabs } from "@mui/material";
import PropTypes, { bool, func, array, string, object } from "prop-types";
import { ACTIVE_TABS } from "./ActiveTabs.constants";
import useStyles from "./styles";

export const ActiveTabs = ({
  value,
  onChange,
  customTabs,
  tabStyles,
  tabsProps = {},
  tabProps = {},
}) => {
  const classes = useStyles({ tabStyles });

  return (
    <Tabs
      {...{ value, onChange }}
      className={classes.tabs}
      TabIndicatorProps={{ style: { background: "none" } }}
      {...tabsProps}
    >
      {(customTabs || ACTIVE_TABS).map((tab) => (
        <Tab
          key={`${tab.value}-${tab.name}`}
          label={tab.name}
          name={tab.field}
          value={tab.value}
          className={classes.tab}
          {...tabProps}
        />
      ))}
    </Tabs>
  );
};

ActiveTabs.propTypes = {
  value: PropTypes.oneOfType([bool, string, array]),
  onChange: func,
  customTabs: array,
  tabStyles: object,
  tabsProps: object,
  tabProps: object,
};

ActiveTabs.defaultProps = {
  value: false,
  onChange: () => {},
  customTabs: null,
  tabStyles: {},
};

export default ActiveTabs;
