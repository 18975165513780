import { useCallback, useEffect, useState } from "react";
import { bool, func, shape, string, object, array } from "prop-types";
import {
  Box,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CrossIcon } from "../Icons";
import { NoteItemList } from "./components/NoteItemList";
import { getCustomerNotesService } from "../../services/notes";
import { error } from "../../utils/notifications";
import { InfiniteScrollWrapper } from "components";

export const NoteDialog = ({
  open,
  onClose,
  data,
  noteId,
  notes,
  // params,
}) => {
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [notesList, setNotesList] = useState(notes || []);
  const [notesCount, setNotesCount] = useState(0);
  const [page, setPage] = useState(1);
  const SCROLL_LIMIT = 5;

  const customerName = (data?.customer?.name || data?.name) ?? "";

  const fetchNotesList = useCallback(async () => {
    // const { representativeId, ...preparedParams } = params;
    if (!data?.customer?.id) return;
    setLoadingNotes(true);
    try {
      const res = await getCustomerNotesService(data?.customer?.id, {
        // ...preparedParams,
        page,
        limit: SCROLL_LIMIT,
      });
      setNotesList((prev) => [...prev, ...res?.rows]);
      setNotesCount(res?.count);
      setPage((prev) => prev + 1);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setLoadingNotes(false);
    }
  }, [data?.customer?.id, page]);

  useEffect(() => {
    if (data?.customer?.id && open) return fetchNotesList();

    setPage(1);
    setNotesList([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.customer?.id, open]);

  useEffect(() => {
    if (notes) setNotesList(notes);
  }, [notes]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <Grid container width="744px">
        <Grid
          sx={{
            height: "50px",
            px: 2,
            display: "flex",
            alignItems: "center",
          }}
          xs={12}
          item
        >
          <Typography fontSize={18} color="#707070">
            Notes{" "}
            <Box color="#D5D9D9" component="span">
              |
            </Box>{" "}
            {customerName}
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: 14, right: 24, p: 0 }}
            onClick={onClose}
          >
            <CrossIcon />
          </IconButton>
        </Grid>
        <Grid sx={{ px: 2, pb: 3.45 }} xs={12} item container>
          {loadingNotes && notesList?.length === 0 ? (
            <Grid
              sx={{
                border: "0.5px solid #D5D9D9",
                borderRadius: "4px",
                maxHeight: "183px",
                overflow: "auto",
                "&:infinite-scroll-component__outerdiv": {
                  width: "100%",
                },
              }}
              id="notes-scroll"
              xs={12}
              item
              container
            >
              <Box
                sx={{
                  width: "100%",
                  height: "60px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size="20px" />
              </Box>
            </Grid>
          ) : (
            <Grid
              sx={{
                border: "0.5px solid #D5D9D9",
                borderRadius: "4px",
                maxHeight: "184px",
                overflow: "hidden",
                "& .infinite-scroll-component__outerdiv": {
                  width: "100%",
                },
              }}
              xs={12}
              item
              container
            >
              <InfiniteScrollWrapper
                maxHeight="184px"
                loading={loadingNotes}
                dataLength={notesList.length}
                next={fetchNotesList}
                loader={
                  <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />
                }
                hasMore={notesList.length < notesCount}
                id="notes-scroll"
              >
                {notesList?.map((n) => (
                  <NoteItemList key={n?.id} item={n} noteId={noteId} />
                ))}
              </InfiniteScrollWrapper>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

NoteDialog.defaultProps = {
  open: false,
  onClose: () => {},
  data: {
    id: "",
    customer: { name: "", id: "" },
  },
  noteId: "",
  params: {},
  notes: [],
};

NoteDialog.propTypes = {
  open: bool,
  onClose: func,
  data: shape({
    id: string,
    customer: shape({
      name: string,
    }),
  }),
  noteId: string,
  params: object,
  notes: array,
};
