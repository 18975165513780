import {
  useEffect,
  useState,
  useCallback,
  Fragment,
  useMemo,
  useRef,
} from "react";
import { string } from "prop-types";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import lodash from "lodash";
import moment from "moment";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  Box,
} from "@mui/material";
import {
  LargeCheckbox,
  StyledSelect,
  PageTabComponent,
  PriceFormat,
  StyledTextField,
  StyledButton,
  StyledDatePicker,
  Loader,
  StyledTooltip,
  ValueToggleButtonGroup,
  StyledPopper,
} from "components";
import {
  UsageInfoIcon,
  DiscountInfoIcon,
  SearchIcon,
  CartTrashIcon,
  IosArrowForward,
  CrossIcon,
} from "components/Icons";
import { useDebounce } from "helpers/hooks";
import { getProductsService } from "services/products";
import { error } from "utils/notifications";
import ProductsDropDown from "../../../CartPage/components/ProductsDropDown/ProductsDropDown";
import {
  APPLIES_TO,
  defaultValues,
  DISCOUNT_APPLICABLE_ON_SCOPES,
  DISCOUNT_STATUSES,
  MIN_REQUIREMENTS,
} from "./NewDiscountsPage.constants";
import CustomersDropDown from "./components/CustomersDropDown/CustomersDropDown";
import { default as AllGroupsPopup } from "./components/AllCustomersPopup/AllCustomersPopup";
import GroupsDropDown from "./components/GroupsDropDown/GroupsDropDown";
import DiscountGroupItem from "./components/DiscountGroupItem/DiscountGroupItem";
import { validationSchema } from "./NewDiscountPage.validations";
import { containsEvery, useAdmin } from "helpers/helpers";
import DiscountProductSmall from "./components/DiscountProductSmall/DiscountProductSmall";
import AllProductsPopup from "../../../CartPage/components/AllProductsPopup/AllProductsPopup";
import {
  getCustomersService,
  getParentCustomersService,
} from "services/customers";
import { getFlatGroupsService } from "services/parentCustomers";
import useStyles from "./styles";
import {
  createDiscountAction,
  deleteDiscountAction,
  updateDiscountAction,
} from "redux/actions/discounts";
import { getManufacturerDiscountByIdService } from "services/discounts";
import { handleConfirmAction } from "../../DiscountsPage.helpers";
import {
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";
import AssignedRepsPopper from "../../../CustomersPage/components/CustomersTab/components/AssignedRepsPopper/AssignedRepsPopper";
import AllCustomersPopup from "Pages/CreateRoutePage/components/AllCustomersPopup/AllCustomersPopup";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";
import { useManufacturersActions } from "helpers/useManufacturersActions";
import { useRedirect } from "helpers/useRedirect";
import ApplicableOnSection from "./components/ApplicableOnSection";

const NewDiscountPage = ({ navigatePath, navigateState }) => {
  const isAdmin = useAdmin();
  const classes = useStyles();

  const minBox = useRef();
  const activeRef = useRef();

  const [productsSearchInput, setProductsSearchInput] = useState("");
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [showCheckedProducts, setShowCheckedProducts] = useState([]);

  const savedProductsBeforeOpenAllProducts = useRef([]);

  const [allProductsOpen, setAllProductsOpen] = useState(false);

  const [customersSearchInput, setCustomersSearchInput] = useState("");
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [checkedCustomers, setCheckedCustomers] = useState([]);
  const [allCustomersOpen, setAllCustomersOpen] = useState(false);

  const [groupsSearchInput, setGroupsSearchInput] = useState("");
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [groupsList, setGroupsList] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [allGroupsOpen, setAllGroupsOpen] = useState(false);

  const [discountLoading, setDiscountLoading] = useState(false);
  const [showActiveTooltip, setShowActiveTooltip] = useState(false);

  const productsSearchInputDebounced = useDebounce(productsSearchInput, 500);
  const customersSearchInputDebounced = useDebounce(customersSearchInput, 500);
  const groupsSearchInputDebounced = useDebounce(groupsSearchInput, 500);

  const { discountId } = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
    clearErrors,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues: { ...defaultValues },
    resolver: yupResolver(validationSchema({ checkedProducts })),
  });

  const formField = useWatch({ control });

  const dispatch = useDispatch();
  const navigate = useRedirect();

  const onSubmit = (data) => {
    const {
      id,
      discount,
      products,
      minimum,
      customers,
      hasEndDate,
      parentCustomers,
      manufacturer,
      distributorId,
      createdAt,
      usageCount,
      _count,
      ...cleanData
    } = data;
    const uploadData = {
      ...cleanData,
      manufacturerId: data.manufacturer?.id,
      percentDiscount: data.type === "PERCENTAGE" ? +data.discount : null,
      absoluteDiscount: data.type === "ABSOLUTE" ? +data.discount : null,
      quantityDiscount: data.type === "QUANTITY" ? +data.discount : null,
      startDate: new Date(data.startDate),
      endDate: hasEndDate ? new Date(data.endDate) : null,
      productIds:
        checkedProducts?.length && data.productType === "SPECIFIC_PRODUCTS"
          ? checkedProducts.map((product) => product.id)
          : null,
      minQuantity: data.requirements === "MIN_QUANTITY" ? +data.minimum : null,
      minPurchaseAmount:
        data.requirements === "MIN_PURCHASE_AMOUNT" ? +data.minimum : null,
      limit: data.isLimited ? data.limit : null,
      customerIds:
        data.eligibility === "CUSTOMERS"
          ? data.customers.map((customer) => customer.id)
          : null,
      parentCustomerIds:
        data.eligibility === "GROUPS"
          ? data.parentCustomers.map((group) => group.id)
          : null,
    };

    if (data.id) {
      delete uploadData.manufacturerId;
      return dispatch(
        updateDiscountAction(
          data.id,
          lodash.pickBy(uploadData, (value) => value !== null),
          navigate
        )
      );
    }

    dispatch(
      createDiscountAction(
        lodash.pickBy(uploadData, (value) => value !== null),
        navigate
      )
    );
  };

  useEffect(() => {
    if (formField?.name) clearErrors("name");
    if (formField?.discount) clearErrors("discount");
    if (formField?.manufacturer?.id) clearErrors("manufacturer");
  }, [clearErrors, formField]);

  useEffect(() => {
    dispatch(setFormChangedAction(isDirty));
  }, [isDirty, dispatch]);

  useEffect(() => {
    if (!formField?.id) {
      if (
        formField.type === "QUANTITY" &&
        formField.requirements === "MIN_QUANTITY"
      )
        return setValue("minimum", formField?.discount || "");

      return setValue("minimum", "");
    }

    setValue(
      "minimum",
      formField?.minQuantity || formField?.minPurchaseAmount || ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formField?.id,
    formField.type,
    formField.requirements,
    formField?.minQuantity,
    setValue,
  ]);

  useEffect(() => {
    dispatch(setEditTypeAction("msd", !discountId));
  }, [dispatch, discountId]);

  useEffect(() => {
    if (discountId) {
      setDiscountLoading(true);
      getManufacturerDiscountByIdService(discountId)
        .then((res) => {
          setDiscountLoading(false);
          const products = res?.products?.length
            ? res.products.map((prod) => prod.product)
            : [];
          reset({
            ...defaultValues,
            ...res,
            discount:
              res?.absoluteDiscount ||
              res?.percentDiscount ||
              res?.quantityDiscount,
            manufacturer: res?.manufacturer,
            limit: res?.limit || defaultValues?.limit,
            minimum:
              res?.minPurchaseAmount ||
              res?.minQuantity ||
              defaultValues?.minimum,
            endDate: res?.endDate || defaultValues?.endDate,
            hasEndDate: !!res?.endDate,
            customers: res?.customers?.length
              ? res?.customers?.map((cust) => cust.customer)
              : [],
            parentCustomers: res?.parentCustomers?.length
              ? res.parentCustomers?.map((cust) => cust.customer)
              : [],
          });
          setCheckedProducts(products);
          setShowCheckedProducts(products);
        })
        .catch((err) => {
          setDiscountLoading(false);
          error(err?.response?.data?.message || "Something went wrong0");
        });
    }
  }, [discountId, reset]);

  useEffect(() => {
    if (!productsSearchInputDebounced) return setProductsList([]);
    setProductsLoading(true);
    getProductsService({
      search: productsSearchInputDebounced,
      manufacturer_id: formField.manufacturer?.id,
    })
      .then((res) => {
        setProductsLoading(false);
        setProductsList(res.rows);
      })
      .catch(() => {
        setProductsLoading(false);
        error("Something went wrong");
      });
  }, [productsSearchInputDebounced, formField.manufacturer]);

  useEffect(() => {
    if (!customersSearchInputDebounced) return setCustomersList([]);
    setCustomersLoading(true);
    getCustomersService({
      search: customersSearchInputDebounced,
      status: `["${CUSTOMER_STATUS_FILTERS.active}"]`,
    })
      .then((res) => {
        setCustomersLoading(false);
        setCustomersList(res.rows);
      })
      .catch(() => {
        setCustomersLoading(false);
        error("Something went wrong");
      });
  }, [customersSearchInputDebounced]);

  useEffect(() => {
    if (!groupsSearchInputDebounced) return setGroupsList([]);
    setGroupsLoading(true);
    getParentCustomersService({ search: groupsSearchInputDebounced })
      .then((res) => {
        setGroupsLoading(false);
        setGroupsList(res.rows);
      })
      .catch(() => {
        setProductsLoading(false);
        error("Something went wrong");
      });
  }, [groupsSearchInputDebounced]);

  const handleAddProducts = useCallback(() => {
    clearErrors("products");
  }, [clearErrors]);

  const handleAddCustomers = useCallback(
    (customers) => {
      if (!customers) {
        setValue("customers", [...formField.customers, ...checkedCustomers], {
          shouldDirty: true,
        });
        return;
      }
      setCheckedCustomers([]);
      clearErrors("customers");
      if (formField.customers)
        return setValue("customers", [...customers], {
          shouldDirty: true,
        });
      setValue("customers", [...customers], { shouldDirty: true });
    },
    [checkedCustomers, clearErrors, formField.customers, setValue]
  );

  const handleAddGroups = useCallback(() => {
    setCheckedGroups([]);
    clearErrors("parentCustomers");
    if (formField.parentCustomers)
      return setValue(
        "parentCustomers",
        [...formField.parentCustomers, ...checkedGroups],
        { shouldDirty: true }
      );
    setValue("parentCustomers", [...checkedGroups], { shouldDirty: true });
  }, [checkedGroups, clearErrors, formField.parentCustomers, setValue]);

  const handleCheckProduct = useCallback(
    (product) => {
      if (product.isMultiple) {
        const filteredChildren = product.childProducts.filter(
          (childProduct) =>
            !formField.products?.some((prod) => prod.id === childProduct.id)
        );
        if (containsEvery(filteredChildren, checkedProducts)) {
          const newChecked = [...checkedProducts];
          product.childProducts.forEach((childProduct) => {
            const index = newChecked.findIndex(
              (checkedCustomer) => checkedCustomer.id === childProduct.id
            );
            newChecked.splice(index, 1);
          });
          return setCheckedProducts([...newChecked]);
        }

        const addProducts = filteredChildren.map((childProduct) => {
          return { ...childProduct, quantity: 1, parentProduct: product };
        });

        const uniqueProducts = [
          ...new Map(
            [...checkedProducts, ...addProducts].map((item) => [
              item["id"],
              item,
            ])
          ).values(),
        ];

        return setCheckedProducts(uniqueProducts);
      }
      const index = checkedProducts.findIndex((item) => item.id === product.id);
      if (index > -1) {
        const newProducts = checkedProducts.filter(
          (item) => item.id !== product.id
        );
        setShowCheckedProducts(newProducts);
        return setCheckedProducts(newProducts);
      }
      setCheckedProducts([...checkedProducts, product]);
    },
    [checkedProducts, formField.products]
  );

  const handleCheckCustomer = (customer) => {
    const customerIndex = checkedCustomers.findIndex(
      (checkedCustomer) => checkedCustomer.id === customer.id
    );
    if (customerIndex > -1) {
      const newCustomers = [...checkedCustomers];
      newCustomers.splice(customerIndex, 1);
      return setCheckedCustomers([...newCustomers]);
    }

    setCheckedCustomers([...checkedCustomers, customer]);
  };

  const handleCheckGroup = (group) => {
    const groupIndex = checkedGroups.findIndex(
      (checkedGroup) => checkedGroup.id === group.id
    );
    if (groupIndex > -1) {
      const newGroups = [...checkedGroups];
      newGroups.splice(groupIndex, 1);
      return setCheckedGroups([...newGroups]);
    }

    setCheckedGroups([...checkedGroups, group]);
  };

  const {
    list: manufacturersList,
    count: manufacturersCount,
    handleFetch: fetchManufacturers,
    page: manufacturersPage,
    search: manufacturersSearch,
    setSearch: setManufacturersSearch,
    handleSetPage: setManufacturersPage,
  } = useManufacturersActions({
    params: {
      with_products: true,
      status: '["active"]',
    },
  });

  const startIsInTheFuture = useMemo(
    () =>
      formField.status !== "ACTIVE" && typeof formField.startDate === "string"
        ? moment().format() < formField.startDate
        : moment().format() < moment(formField.startDate).format(),
    [formField.startDate, formField.status]
  );

  const endIsInThePast = useMemo(
    () =>
      formField.status !== "ACTIVE" &&
      !!formField.endDate &&
      (typeof formField.endDate === "string"
        ? moment().format() > formField.endDate
        : moment().format() > moment(formField.endDate).format()),
    [formField.endDate, formField.status]
  );

  const activeDisabled = (status) => {
    if (status !== "INACTIVE" && (startIsInTheFuture || endIsInThePast))
      return true;
  };

  const popoverText = useMemo(() => {
    if (startIsInTheFuture) {
      if (typeof formField?.startDate === "string") {
        return `Discount will be active at ${moment(formField.startDate).format(
          "MM/DD/YYYY"
        )}`;
      }
      if (moment.isMoment(formField?.startDate)) {
        return `Discount will be active at ${formField.startDate.format(
          "MM/DD/YYYY"
        )}`;
      }

      return `Discount will be active at ${moment(formField.startDate).format(
        "MM/DD/YYYY"
      )}`;
    }

    if (endIsInThePast)
      return `Discount was active at ${
        typeof formField.endDate === "string"
          ? moment(formField?.endDate).format("MM/DD/YYYY")
          : formField?.endDate?.format("MM/DD/YYYY")
      }`;
  }, [
    startIsInTheFuture,
    formField?.startDate,
    formField?.endDate,
    endIsInThePast,
  ]);

  useEffect(() => {
    if (startIsInTheFuture || endIsInThePast) setValue("status", "INACTIVE");
    else setValue("status", "ACTIVE");
  }, [setValue, startIsInTheFuture, endIsInThePast]);

  const openToolTip = useMemo(
    () =>
      formField?.type === "QUANTITY" &&
      formField?.requirements === "MIN_QUANTITY" &&
      +formField?.minimum < +formField?.discount,
    [
      formField?.type,
      formField?.discount,
      formField?.minimum,
      formField?.requirements,
    ]
  );

  const tooltipTitle = (key) => {
    return !formField.manufacturer
      ? "Please select manufacturer to proceed"
      : key !== "MIN_QUANTITY" && formField?.type === "QUANTITY"
      ? "This requirement is not available for the selected discount type"
      : "";
  };

  const showApplicableOn = useMemo(
    () => formField.type !== "QUANTITY",
    [formField.type]
  );

  return (
    <Box component="form" id="new-msd-form" onSubmit={handleSubmit(onSubmit)}>
      <Loader isLoading={discountLoading} />
      <AssignedRepsPopper
        open={!!(minBox.current && openToolTip)}
        anchorEl={minBox.current}
        rep={{
          name: `MOQ can not be less than ${formField.discount}`,
        }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [-115, 10],
            },
          },
        ]}
      />
      {allProductsOpen && (
        <AllProductsPopup
          skipExpanding
          disableOutOfStock={false}
          hideGreenNavigationBar
          isOpen={allProductsOpen}
          handleClose={() => {
            setAllProductsOpen(false);
            setCheckedProducts([...savedProductsBeforeOpenAllProducts.current]);
          }}
          handleCheckProduct={handleCheckProduct}
          handleCancelProducts={() => {
            setAllProductsOpen(false);
            setCheckedProducts([...savedProductsBeforeOpenAllProducts.current]);
          }}
          handleAddProducts={() => {
            setAllProductsOpen(false);
            handleAddProducts();
            setShowCheckedProducts(checkedProducts);
          }}
          checkedProducts={checkedProducts}
          addedProducts={[]}
          specificManufacturer={formField.manufacturer}
          hideAvailable
          allowMultipleSelect
        />
      )}
      {allCustomersOpen && (
        <AllCustomersPopup
          hideActive
          hideStatus
          viewCustomerPopup={false}
          handleAddCustomers={(customers) => {
            setAllCustomersOpen(false);
            handleAddCustomers(customers);
          }}
          open={allCustomersOpen}
          onClose={() => setAllCustomersOpen(false)}
          addedStops={formField.customers}
          allowMissingInfo={true}
        />
      )}
      <AllGroupsPopup
        isOpen={allGroupsOpen}
        handleClose={() => setAllGroupsOpen(false)}
        handleCheckCustomer={handleCheckGroup}
        handleCancelCustomers={() => setAllGroupsOpen(false)}
        handleAddCustomers={() => {
          setAllGroupsOpen(false);
          handleAddGroups();
        }}
        checkedCustomers={checkedGroups}
        addedCustomers={formField.parentCustomers}
        ItemWrapper={DiscountGroupItem}
        getItemsService={getFlatGroupsService}
        type="GROUP"
      />
      <PageTabComponent
        title={
          <Box height="44px" display="flex" alignItems="center">
            <Typography
              onClick={() => {
                navigate(navigatePath || -1, { state: navigateState });
              }}
              color="primary"
              sx={{
                fontSize: "20px",
                cursor: "pointer",
                // color: "primary",
              }}
            >
              Discounts
            </Typography>
            <Box component="span" mx={1.8}>
              <IosArrowForward />
            </Box>
            <Typography
              className="secondary"
              color="disable.main"
              variant="h6"
              fontWeight="400"
              fontSize="20px"
            >
              {discountId ? "Edit" : "New"} discount
            </Typography>
          </Box>
        }
        form="new-msd-form"
        navigatePath="/discounts"
        saveButtons
        isEdit={!!discountId}
        // onSubmit={handleSubmit(onSubmit)}
        // handleOpenConfirmDialog={handleOpenBackConfirmDialog}
        handleDelete={() =>
          dispatch(
            handleConfirmAction({
              title: "Delete selected discount?",
              text: "Please confirm that you would like to delete selected discount? This cannot be restored.",
              confirmColor: "confirmDelete",
              onConfirm: () =>
                dispatch(deleteDiscountAction(discountId, navigate)),
            })
          )
        }
        errors={errors}
        trigger={trigger}
        formField={formField}
        titleName="discount"
      />
      <Box className={classes.pageBody}>
        <Box className={classes.pageWrapper}>
          <Box className={classes.discountInfo}>
            <Box className={classes.pageHeader}>
              <DiscountInfoIcon />
              <Typography className={classes.title}>
                Discount information
              </Typography>
            </Box>
            <Box className={classes.pageHalf}>
              <Box display="flex" gap="11px">
                <Box flex="1 1 50%">
                  <Typography className={classes.subtitle} mb="10px">
                    Discount name
                  </Typography>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <StyledTextField
                        disabled={isAdmin}
                        size="small"
                        fullWidth
                        noErrorMessage={false}
                        error={error?.message || ""}
                        errorMsgSx={{
                          bottom: -18,
                          left: 0,
                        }}
                        {...field}
                      />
                    )}
                    name="name"
                    control={control}
                  />
                </Box>
                <Box flex="1 1 30%">
                  <Typography className={classes.subtitle} mb="10px">
                    Discount value
                  </Typography>
                  <Controller
                    render={({ field, fieldState: { error } }) => (
                      <PriceFormat
                        disabled={isAdmin}
                        noErrorMessage
                        type={formField.type}
                        fullWidth
                        InputProps={{
                          sx: {
                            height: "32px",
                            fontSize: "12px",
                            pr: "3px",
                          },
                          endAdornment: (
                            <Controller
                              render={({ field }) => (
                                <ValueToggleButtonGroup
                                  disabled={isAdmin}
                                  type={formField.type}
                                  {...field}
                                  onChange={(e) => {
                                    if (isAdmin) return;
                                    const val = e.target.value;
                                    setValue(field.name, val);
                                    if (val === "QUANTITY") {
                                      setValue("requirements", "MIN_QUANTITY");
                                      setValue(
                                        "scope",
                                        DISCOUNT_APPLICABLE_ON_SCOPES.CART_TOTAL
                                      );
                                    } else {
                                      setValue("requirements", "NONE");
                                    }
                                  }}
                                />
                              )}
                              name="type"
                              control={control}
                            />
                          ),
                        }}
                        error={error ? error.message : ""}
                        {...field}
                        onChange={() => {}}
                        onValueChange={(values) => {
                          const { value } = values ?? {};
                          setValue("discount", value);
                        }}
                      />
                    )}
                    name="discount"
                    control={control}
                  />
                </Box>
              </Box>
            </Box>
            <Box mt="2vh">
              <Typography className={classes.subtitle} mb="6px">
                Applies to
              </Typography>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledSelect
                    color="#5F6267"
                    notched={false}
                    fontSize="14px"
                    fullWidth
                    noErrorMessage
                    dataLength={manufacturersList.length}
                    dataCount={manufacturersCount}
                    handleFetch={() =>
                      fetchManufacturers(manufacturersPage + 1)
                    }
                    PaperPropsSx={{ maxWidth: "300px" }}
                    formSx={{ maxWidth: "426px" }}
                    displayEmpty
                    error={error ? error.message : ""}
                    disabled={
                      isAdmin || !!formField.id || !!checkedProducts?.length
                    }
                    {...field}
                    value={field.value?.id || ""}
                    onChange={(e) => {
                      setValue(
                        field.name,
                        {
                          id: e.target.value,
                          name:
                            manufacturersList.find(
                              (m) => m.id === e.target.value
                            )?.name || "",
                        },
                        { shouldDirty: true }
                      );
                    }}
                  >
                    <MenuItem sx={{ display: "none" }} value="">
                      Select manufacturer
                    </MenuItem>
                    <MenuItem sx={{ display: "none" }} value={field.value?.id}>
                      {field.value?.name}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        p: "10px",
                        "&:hover": { backgroundColor: "#FFF !important" },
                        "&.Mui-focused": { bgcolor: "#FFF !important" },
                        "&.Mui-focusVisible": { bgcolor: "#FFF !important" },
                        "&.Mui-selected": { bgcolor: "#FFF !important" },
                        "&.Mui-selected:hover": { bgcolor: "#FFF" },
                      }}
                      onKeyDown={(e) => e.stopPropagation()}
                    >
                      <Box width="100%">
                        <StyledTextField
                          fullWidth
                          size="small"
                          value={manufacturersSearch}
                          onChange={(e) => {
                            setManufacturersSearch(e.target.value);
                            setManufacturersPage(1);
                          }}
                          placeholder="Search manufacturers"
                          onClick={(e) => e.stopPropagation()}
                          InputProps={{
                            className: classes.textInputSearch,
                            endAdornment: (
                              <>
                                <InputAdornment
                                  sx={{ position: "absolute", left: 9 }}
                                  position="start"
                                >
                                  <SearchIcon />
                                </InputAdornment>
                                {manufacturersSearch && (
                                  <InputAdornment position="end">
                                    <IconButton
                                      sx={{ p: "1px" }}
                                      onClick={() => {
                                        setManufacturersSearch("");
                                        setManufacturersPage(1);
                                      }}
                                    >
                                      <CrossIcon size="15" />
                                    </IconButton>
                                  </InputAdornment>
                                )}
                              </>
                            ),
                          }}
                          InputLabelProps={{ className: classes.inputLabel }}
                        />
                      </Box>
                    </MenuItem>

                    {manufacturersList.map((manufacturer) => (
                      <MenuItem key={manufacturer.id} value={manufacturer.id}>
                        <Typography fontSize={12} color="#3F3F3F" noWrap>
                          {manufacturer.name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </StyledSelect>
                )}
                name="manufacturer"
                control={control}
              />
            </Box>
            <Controller
              render={({ field }) => (
                <FormControl sx={{ mt: "4px" }}>
                  <RadioGroup {...field}>
                    {Object.keys(APPLIES_TO).map((key) => (
                      <StyledTooltip
                        key={key}
                        title={
                          !formField.manufacturer
                            ? "Please select manufacturer to proceed"
                            : ""
                        }
                        sx={{
                          paddingRight: "114px",
                          height: "44px",
                        }}
                        placement="top"
                        arrow
                      >
                        <FormControlLabel
                          key={key}
                          value={key}
                          disabled={isAdmin || !formField.manufacturer}
                          componentsProps={{
                            typography: {
                              fontSize: "14px",
                              color: "#1C1C19",
                              fontWeight: 400,
                            },
                          }}
                          label={APPLIES_TO[key]}
                          control={<Radio className={classes.radio} />}
                        />
                      </StyledTooltip>
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
              name="productType"
              control={control}
            />
            {formField.productType === "SPECIFIC_PRODUCTS" && (
              <Box position="relative">
                {errors.products && (
                  <Typography
                    variant="invalid"
                    position="absolute"
                    sx={{
                      top: -10,
                      right: 0,
                    }}
                  >
                    {errors.products.message}
                  </Typography>
                )}
                <StyledTextField
                  fullWidth
                  disabled={isAdmin}
                  value={productsSearchInput}
                  onChange={(e) => setProductsSearchInput(e.target.value)}
                  formSx={{ mt: "10px" }}
                  size="small"
                  InputProps={{
                    className: classes.searchInput,
                    endAdornment: (
                      <>
                        <InputAdornment
                          position="start"
                          sx={{ position: "absolute", left: 9 }}
                        >
                          <SearchIcon />
                        </InputAdornment>
                        {productsLoading && (
                          <InputAdornment position="end">
                            <CircularProgress size="20px" />
                          </InputAdornment>
                        )}
                        <InputAdornment position="end">
                          <Box borderLeft="0.5px solid #D5D9D9" pl="2px">
                            <StyledButton
                              label="View All"
                              color="cancel"
                              fontSize="15px"
                              sx={{ py: "1px" }}
                              onClick={() => {
                                setAllProductsOpen(true);
                                savedProductsBeforeOpenAllProducts.current = [
                                  ...checkedProducts,
                                ];
                              }}
                            />
                          </Box>
                        </InputAdornment>
                      </>
                    ),
                  }}
                  InputLabelProps={{ className: classes.searchLabel }}
                  label="Search products"
                />
                {!!productsList.length && (
                  <ProductsDropDown
                    productsList={productsList}
                    formProducts={checkedProducts}
                    handleCheckProduct={handleCheckProduct}
                    checkedProducts={checkedProducts}
                    onCancel={() => {
                      setProductsSearchInput("");
                      setCheckedProducts([
                        ...savedProductsBeforeOpenAllProducts.current,
                      ]);
                    }}
                    onAdd={() => {
                      if (isAdmin) return;
                      setProductsSearchInput("");
                      handleAddProducts();
                      setShowCheckedProducts(checkedProducts);
                    }}
                  />
                )}

                {/* {!!checkedProducts?.length && ( */}
                {!!showCheckedProducts?.length && (
                  <Box className={classes.productsTable}>
                    {/* {checkedProducts.map((product) => ( */}
                    {showCheckedProducts.map((product) => (
                      <DiscountProductSmall
                        key={product.id}
                        product={product}
                        manufacturer={formField.manufacturer?.name || ""}
                        handleRemoveProduct={(product) => {
                          if (isAdmin) return;
                          handleCheckProduct(product);
                        }}
                      />
                    ))}
                  </Box>
                )}
              </Box>
            )}
            {showApplicableOn && (
              <ApplicableOnSection
                {...{ control, isAdmin }}
                hasManufacturer={!!formField.manufacturer}
              />
            )}
            <Box>
              <Typography className={classes.subtitle2} mt="13px" mb="6px">
                Start date
              </Typography>
              <Box display="flex" gap="12px" alignItems="center">
                <Controller
                  render={({ field, fieldState: { error } }) => (
                    <Box width="144px">
                      <StyledDatePicker
                        disabled={isAdmin}
                        inputSx={{
                          fontSize: "12px",
                          color: "#5F6267",
                          fontWeight: 400,
                        }}
                        textFieldProps={{
                          fullWidth: true,
                          noErrorMessage: true,
                          formSx: {
                            maxWidth: "144px",
                          },
                        }}
                        error={error}
                        {...field}
                      />
                    </Box>
                  )}
                  name="startDate"
                  control={control}
                />
                {formField.hasEndDate && (
                  <>
                    <Typography>-</Typography>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <Box width="144px">
                          <StyledDatePicker
                            disabled={isAdmin}
                            inputSx={{
                              fontSize: "12px",
                              color: "#5F6267",
                              fontWeight: 400,
                            }}
                            textFieldProps={{
                              noErrorMessage: true,
                              fullWidth: true,
                              formSx: {
                                maxWidth: "144px",
                              },
                            }}
                            error={error}
                            disablePast
                            {...field}
                          />
                        </Box>
                      )}
                      name="endDate"
                      control={control}
                    />
                  </>
                )}
                <Box position="relative">
                  <Controller
                    render={({ field }) => (
                      <LargeCheckbox
                        disabled={isAdmin}
                        size={18}
                        sx={{ p: 0, m: "9px" }}
                        stroke="#D5D9D9"
                        checked={!!field.value}
                        formSx={{ position: "absolute", left: 0, top: -18 }}
                        label={
                          <Typography
                            fontSize="10px"
                            fontWeight="400"
                            color="#6A6A6A"
                            whiteSpace="nowrap"
                          >
                            Set end date
                          </Typography>
                        }
                        {...field}
                      />
                    )}
                    name="hasEndDate"
                    control={control}
                  />
                </Box>
              </Box>
            </Box>
            <Typography fontSize="14px" color="#707070" mt="2vh">
              Status
            </Typography>
            <Box ref={activeRef} width="60px" />
            <Controller
              render={({ field }) => (
                <FormControl sx={{ mt: "4px" }}>
                  <RadioGroup {...field}>
                    {Object.keys(DISCOUNT_STATUSES).map((key) => (
                      <StyledTooltip
                        key={key}
                        title={
                          !formField.manufacturer &&
                          "Please select manufacturer to proceed"
                        }
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: { offset: [0, -2] },
                            },
                          ],
                        }}
                        sx={{
                          paddingRight: "153px",
                          height: "44px",
                        }}
                        placement="top"
                        arrow
                        disableHoverListener={!!formField.manufacturer}
                      >
                        <FormControlLabel
                          key={key}
                          value={key}
                          disabled={
                            isAdmin ||
                            !formField.manufacturer ||
                            activeDisabled(key)
                          }
                          componentsProps={{
                            typography: {
                              fontSize: "12px",
                              color: "#1C1C19",
                              fontWeight: 400,
                            },
                          }}
                          onMouseEnter={() => {
                            if (key === "ACTIVE") setShowActiveTooltip(true);
                          }}
                          onMouseLeave={() => {
                            setShowActiveTooltip(false);
                          }}
                          label={DISCOUNT_STATUSES[key]}
                          control={<Radio className={classes.radio} />}
                        />
                      </StyledTooltip>
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
              name="status"
              control={control}
            />
            <StyledPopper
              anchorEl={activeRef.current}
              open={
                !!formField.manufacturer &&
                showActiveTooltip &&
                !!(startIsInTheFuture || endIsInThePast)
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              modifiers={[
                {
                  name: "offset",
                  options: { offset: [-24.5, -4.5] },
                },
              ]}
              sx={{
                pointerEvents: "none",
              }}
              text={
                <Typography py="2px" px="5px" fontSize="12px">
                  {popoverText}
                </Typography>
              }
            />
          </Box>
          <Box className={classes.usageInfo}>
            <Box className={classes.pageHeader}>
              <UsageInfoIcon />
              <Typography className={classes.title}>
                Usage information
              </Typography>
            </Box>
            <Box className={classes.pageHalf}>
              <Box display="flex" flexDirection="column" position="relative">
                <Typography className={classes.subtitle2}>
                  Minimum requirements
                </Typography>
                <Controller
                  render={({ field }) => (
                    <FormControl sx={{ mt: "4px" }}>
                      <RadioGroup
                        {...field}
                        onChange={(e) => {
                          setValue(field.name, e.target.value, {
                            shouldDirty: true,
                          });
                        }}
                      >
                        {Object.keys(MIN_REQUIREMENTS).map((key) => (
                          <Fragment key={key}>
                            <StyledTooltip
                              key={key}
                              title={tooltipTitle(key)}
                              sx={{
                                height:
                                  formField?.type === "QUANTITY" &&
                                  formField.manufacturer &&
                                  key !== "MIN_QUANTITY"
                                    ? "61px"
                                    : "44px",
                                "& .MuiTooltip-tooltip": {
                                  transform:
                                    "translateX(calc(-50% + 17.5px))!important",
                                },
                                "& .MuiTooltip-arrow": {
                                  left: "50%!important",
                                  transform:
                                    "translateX(calc(-50% - 1px))!important",
                                },
                              }}
                              placement="top-start"
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "preventOverflow",
                                    options: {
                                      mainAxis: false,
                                    },
                                  },
                                ],
                              }}
                              arrow
                            >
                              <FormControlLabel
                                key={key}
                                value={key}
                                disabled={
                                  isAdmin ||
                                  !formField.manufacturer ||
                                  (key !== "MIN_QUANTITY" &&
                                    formField?.type === "QUANTITY")
                                }
                                componentsProps={{
                                  typography: {
                                    fontSize: "12px",
                                    color: "#1C1C19",
                                    fontWeight: 400,
                                  },
                                }}
                                label={MIN_REQUIREMENTS[key]}
                                control={<Radio className={classes.radio} />}
                              />
                            </StyledTooltip>
                            {key === "MIN_PURCHASE_AMOUNT" &&
                              formField.requirements ===
                                "MIN_PURCHASE_AMOUNT" && (
                                <Box ref={minBox}>
                                  <Controller
                                    render={({
                                      field,
                                      fieldState: { error },
                                    }) => (
                                      <StyledTextField
                                        placeholder="0"
                                        disabled={isAdmin}
                                        fullWidth
                                        noErrorMessage
                                        error={error?.message || ""}
                                        formSx={{
                                          maxWidth: "101px",
                                        }}
                                        {...field}
                                      />
                                    )}
                                    name="minimum"
                                    control={control}
                                  />
                                </Box>
                              )}
                          </Fragment>
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  name="requirements"
                  control={control}
                />
                {formField.requirements === "MIN_QUANTITY" && (
                  <Box ref={minBox}>
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledTextField
                          placeholder="0"
                          disabled={isAdmin}
                          fullWidth
                          noErrorMessage
                          error={error?.message || ""}
                          formSx={{
                            maxWidth: "101px",
                            // position: "absolute",
                            // bottom: -30,
                          }}
                          {...field}
                        />
                      )}
                      name="minimum"
                      control={control}
                    />
                  </Box>
                )}
              </Box>
              <Typography
                className={classes.subtitle2}
                mt={formField.requirements === "NONE" ? "35px" : "20px"}
              >
                Customer eligibility
              </Typography>
              <Controller
                render={({ field }) => (
                  <FormControl sx={{ mt: "4px" }}>
                    <RadioGroup defaultValue="EVERYONE" {...field}>
                      <StyledTooltip
                        key={1}
                        title={
                          !formField.manufacturer
                            ? "Please select manufacturer to proceed"
                            : ""
                        }
                        sx={{
                          paddingRight: "145px",
                          height: "45px",
                        }}
                        placement="top"
                        arrow
                      >
                        <FormControlLabel
                          value="EVERYONE"
                          disabled={isAdmin || !formField.manufacturer}
                          componentsProps={{
                            typography: {
                              fontSize: "12px",
                              color: "#1C1C19",
                              fontWeight: 400,
                            },
                          }}
                          label="Everyone"
                          control={<Radio className={classes.radio} />}
                        />
                      </StyledTooltip>
                      <StyledTooltip
                        key={2}
                        title={
                          !formField.manufacturer
                            ? "Please select manufacturer to proceed"
                            : ""
                        }
                        sx={{
                          paddingRight: "145px",
                          height: "45px",
                        }}
                        placement="top"
                        arrow
                      >
                        <FormControlLabel
                          value="CUSTOMERS"
                          disabled={isAdmin || !formField.manufacturer}
                          componentsProps={{
                            typography: {
                              fontSize: "12px",
                              color: "#1C1C19",
                              fontWeight: 400,
                            },
                          }}
                          label="Specific customers"
                          control={<Radio className={classes.radio} />}
                        />
                      </StyledTooltip>
                      {formField.eligibility === "CUSTOMERS" && (
                        <Box position="relative" width="350px">
                          {errors.customers && (
                            <Typography
                              variant="invalid"
                              position="absolute"
                              sx={{
                                top: -10,
                                right: 0,
                              }}
                            >
                              {errors.customers.message}
                            </Typography>
                          )}
                          <StyledTextField
                            disabled={isAdmin}
                            fullWidth
                            value={customersSearchInput}
                            onChange={(e) =>
                              setCustomersSearchInput(e.target.value)
                            }
                            formSx={{ mt: "10px" }}
                            size="small"
                            InputProps={{
                              className: classes.searchInput,
                              endAdornment: (
                                <>
                                  <InputAdornment
                                    position="start"
                                    sx={{ position: "absolute", left: 9 }}
                                  >
                                    <SearchIcon />
                                  </InputAdornment>
                                  <Box position="relative">
                                    {customersLoading && (
                                      <InputAdornment
                                        position="end"
                                        sx={{
                                          position: "absolute",
                                          right: 0,
                                        }}
                                      >
                                        <CircularProgress size="20px" />
                                      </InputAdornment>
                                    )}
                                  </Box>
                                  <InputAdornment position="end">
                                    <Box
                                      borderLeft="0.5px solid #D5D9D9"
                                      pl="2px"
                                    >
                                      <StyledButton
                                        label="View All"
                                        color="cancel"
                                        fontSize="15px"
                                        sx={{ py: "1px" }}
                                        onClick={() =>
                                          setAllCustomersOpen(true)
                                        }
                                      />
                                    </Box>
                                  </InputAdornment>
                                </>
                              ),
                            }}
                            InputLabelProps={{ className: classes.searchLabel }}
                            label="Search customers"
                          />
                          {!!customersList.length && (
                            <CustomersDropDown
                              customersList={customersList}
                              formCustomers={formField.customers}
                              handleCheckCustomer={handleCheckCustomer}
                              checkedCustomers={checkedCustomers}
                              onCancel={() => {
                                setCustomersSearchInput("");
                                setCheckedCustomers([]);
                                setCustomersList([]);
                              }}
                              onAdd={() => {
                                setCustomersSearchInput("");
                                setCustomersList([]);
                                handleAddCustomers();
                              }}
                              allowMissingInfo={true}
                            />
                          )}

                          {!!formField.customers?.length && (
                            <Box className={classes.customersTable}>
                              {formField.customers.map((customer) => (
                                <Box
                                  key={customer.id}
                                  display="flex"
                                  justifyContent="space-between"
                                  borderBottom="0.5px solid #F0F0F0"
                                  height="40px"
                                  p="3px 10px 5px 12px"
                                >
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    maxWidth="calc(100% - 21px)"
                                    minWidth="calc(100% - 21px)"
                                  >
                                    <Box
                                      display="inline-flex"
                                      gap="3px"
                                      className={classes.item}
                                    >
                                      <Typography noWrap>
                                        {customer.name}
                                      </Typography>
                                      <Typography
                                        minWidth="fit-content"
                                        fontWeight="400"
                                        noWrap
                                      >
                                        (ID: {customer.customId.customId})
                                      </Typography>
                                    </Box>
                                    <Box sx={{ maxWidth: "calc(100% - 1px)" }}>
                                      <Typography fontWeight="400" noWrap>
                                        {
                                          customer.shippingAddress
                                            ?.formatted_address
                                        }
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <IconButton
                                    onClick={() => {
                                      setValue(
                                        "customers",
                                        formField.customers.filter(
                                          (cust) => customer.id !== cust.id
                                        ),
                                        { shouldDirty: true }
                                      );
                                    }}
                                  >
                                    <CartTrashIcon
                                      height="15.55"
                                      width="12.65"
                                    />
                                  </IconButton>
                                </Box>
                              ))}
                            </Box>
                          )}
                        </Box>
                      )}
                      <StyledTooltip
                        key={3}
                        title={
                          !formField.manufacturer
                            ? "Please select manufacturer to proceed"
                            : ""
                        }
                        sx={{
                          paddingRight: "145px",
                          height: "45px",
                        }}
                        placement="top"
                        arrow
                      >
                        <FormControlLabel
                          value="GROUPS"
                          disabled={isAdmin || !formField.manufacturer}
                          componentsProps={{
                            typography: {
                              fontSize: "12px",
                              color: "#1C1C19",
                              fontWeight: 400,
                            },
                          }}
                          label="Specific customer groups"
                          control={<Radio className={classes.radio} />}
                        />
                      </StyledTooltip>
                    </RadioGroup>
                  </FormControl>
                )}
                name="eligibility"
                control={control}
              />

              {formField.eligibility === "GROUPS" && (
                <Box position="relative" width="350px">
                  {errors.parentCustomers && (
                    <Typography
                      variant="invalid"
                      position="absolute"
                      sx={{
                        top: -10,
                        right: 0,
                      }}
                    >
                      {errors.parentCustomers.message}
                    </Typography>
                  )}
                  <StyledTextField
                    disabled={isAdmin}
                    fullWidth
                    value={groupsSearchInput}
                    onChange={(e) => setGroupsSearchInput(e.target.value)}
                    formSx={{ mt: "10px" }}
                    size="small"
                    InputProps={{
                      className: classes.searchInput,
                      endAdornment: (
                        <>
                          <InputAdornment
                            position="start"
                            sx={{ position: "absolute", left: 9 }}
                          >
                            <SearchIcon />
                          </InputAdornment>
                          {groupsLoading && (
                            <InputAdornment position="end">
                              <CircularProgress size="20px" />
                            </InputAdornment>
                          )}
                          <InputAdornment position="end">
                            <Box borderLeft="0.5px solid #D5D9D9" pl="2px">
                              <StyledButton
                                label="View All"
                                color="cancel"
                                fontSize="15px"
                                sx={{ py: "1px" }}
                                onClick={() => setAllGroupsOpen(true)}
                              />
                            </Box>
                          </InputAdornment>
                        </>
                      ),
                    }}
                    InputLabelProps={{ className: classes.searchLabel }}
                    label="Search customer groups"
                  />
                  {!!groupsList.length && (
                    <GroupsDropDown
                      groupsList={groupsList}
                      formGroups={formField.parentCustomers}
                      handleCheckGroup={handleCheckGroup}
                      checkedGroups={checkedGroups}
                      onCancel={() => {
                        setGroupsSearchInput("");
                        setCheckedGroups([]);
                        setGroupsList([]);
                      }}
                      onAdd={() => {
                        setGroupsSearchInput("");
                        setGroupsList([]);
                        handleAddGroups();
                      }}
                    />
                  )}

                  {!!formField.parentCustomers?.length && (
                    <Box className={classes.groupsTable}>
                      {formField.parentCustomers?.map((group) => (
                        <Box
                          key={group?.id}
                          display="flex"
                          justifyContent="space-between"
                          borderBottom="0.5px solid #F0F0F0"
                          height="27px"
                          p="6px 10px"
                        >
                          <Box
                            display="inline-flex"
                            gap="3px"
                            maxWidth="calc(100% - 20px)"
                          >
                            <Typography noWrap>{group?.name}</Typography>
                            <Typography
                              fontWeight="400"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              (ID: {group?.customId.customId})
                            </Typography>
                          </Box>

                          <IconButton
                            onClick={() => {
                              setValue(
                                "parentCustomers",
                                formField.parentCustomers.filter(
                                  (fieldGroup) => group?.id !== fieldGroup?.id
                                ),
                                { shouldDirty: true }
                              );
                            }}
                          >
                            <CartTrashIcon height="15.55" width="12.65" />
                          </IconButton>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              )}
              <Typography className={classes.subtitle2} mt="40px">
                Usage limits per customer
              </Typography>
              <Controller
                render={({ field }) => (
                  <LargeCheckbox
                    disabled={isAdmin}
                    size={15}
                    formSx={{
                      mr: "0px",
                      ml: "-8px",
                      opacity: isAdmin ? 0.5 : 1,
                    }}
                    checked={!!field.value}
                    label={
                      <Typography
                        fontSize="12px"
                        fontWeight="400"
                        color="#1C1C19"
                      >
                        Limit number of times this discount can be used in total
                      </Typography>
                    }
                    {...field}
                  />
                )}
                name="isLimited"
                control={control}
              />
            </Box>
            {formField.isLimited && (
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    disabled={isAdmin}
                    error={error?.message || ""}
                    formSx={{ maxWidth: "101px" }}
                    noErrorMessage
                    {...field}
                  />
                )}
                name="limit"
                control={control}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
NewDiscountPage.propTypes = {
  navigatePath: string,
  navigateState: string,
};
export default NewDiscountPage;
