import { useMemo } from "react";
import { object, string } from "prop-types";
import { uniqBy } from "lodash";
import moment from "moment";
import { Box, Divider, Grid } from "@mui/material";
import { ExpandCircleDown } from "@mui/icons-material";
import { HeaderBlock, MainBlock } from "./components";
import { formatDate, getFormattedDate } from "helpers/helpers";

export const ActivityItem = ({ activity, timeZone }) => {
  const {
    type,
    notes,
    forms,
    orders,
    tasks,
    distributor,
    representativeDuplicate,
    updatedAt,
    createdAt,
    photoGroupComments,
    photoGroups,
  } = activity || {};

  const photoItems = useMemo(() => {
    const photoGroupCommentsPrepared = photoGroupComments
      ?.map((el) => el?.photoGroup)
      ?.map((photo) => photo);

    return uniqBy(
      photoGroups?.concat(photoGroupCommentsPrepared),
      (v) => v?.id
    );
  }, [photoGroupComments, photoGroups]);

  const setRemovedBy = useMemo(() => {
    if (!photoItems?.length) {
      return distributor ? "Admin" : representativeDuplicate?.name || "";
    }
  }, [representativeDuplicate?.name, distributor, photoItems?.length]);

  const formattedDate = formatDate({
    utc_date: updatedAt,
    formatThisYear: "MMM D, h:mm a",
  });

  const setRemovedAt = useMemo(() => {
    if (!photoItems?.length) {
      return (
        moment(getFormattedDate(updatedAt || createdAt, timeZone)).format(
          formattedDate
        ) || ""
      );
    }
  }, [photoItems?.length, updatedAt, createdAt, timeZone, formattedDate]);

  const isDeletedPic = useMemo(() => {
    return (
      !photoItems?.length &&
      type === "PHOTO_GROUP_ADDED" &&
      !notes?.length &&
      !orders?.length
    );
  }, [notes?.length, orders?.length, photoItems?.length, type]);

  const isDeletedNote = useMemo(() => {
    return (
      !photoItems?.length &&
      type === "NOTE_ADDED" &&
      !notes?.length &&
      !orders?.length
    );
  }, [notes?.length, orders?.length, photoItems?.length, type]);

  const isDeletedTask = useMemo(() => {
    return (
      !photoItems?.length &&
      type === "TASK_ADDED" &&
      !notes?.length &&
      !orders?.length &&
      !tasks?.length
    );
  }, [notes?.length, orders?.length, tasks?.length, photoItems?.length, type]);

  const isOnlyCheckIn = useMemo(() => {
    return (
      ["CHECKED_IN", "CHECKED_OUT"].includes(type) &&
      !photoItems?.length &&
      !notes?.length &&
      !tasks?.length &&
      !orders?.length &&
      !isDeletedPic &&
      !isDeletedNote &&
      !forms?.length
    );
  }, [
    isDeletedNote,
    isDeletedPic,
    notes?.length,
    orders?.length,
    photoItems?.length,
    tasks?.length,
    forms?.length,
    type,
  ]);

  return (
    <Grid sx={{ height: "144px" }} container>
      <Grid sx={{ position: "relative" }} xs={1.2} item container>
        <Grid justifyContent="center" xs={12} item container>
          <Divider sx={{ borderStyle: "dashed" }} orientation="vertical" />
        </Grid>
        <ExpandCircleDown
          sx={{
            position: "absolute",
            top: "50%",
            right: "50%",
            marginTop: "-12px",
            marginRight: "-12px",
            color: "#47A06D",
            fontSize: 24,
          }}
        />
      </Grid>

      <Grid display="flex" alignItems="center" xs={10.8} item pr="20px">
        <Box
          sx={{
            width: "100%",
            height: isOnlyCheckIn ? "auto" : "122px",
            border: "0.5px solid #D5D9D9",
            borderRadius: "4px",
          }}
        >
          <HeaderBlock {...{ activity, timeZone }} />
          <MainBlock
            {...{
              photoItems,
              setRemovedBy,
              setRemovedAt,
              isDeletedPic,
              isDeletedNote,
              isDeletedTask,
              isOnlyCheckIn,
              orders,
              notes,
              forms,
              activity,
            }}
          />
        </Box>
      </Grid>

      <Grid xs={0.4} item />
    </Grid>
  );
};

ActivityItem.propTypes = { activity: object, timeZone: string };
