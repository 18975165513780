import {
  FORMS_FILTER,
  RESET_FORMS_FILTER,
  FORMS_SWITCH,
  RESET_FORMS_SWITCH,
} from "redux/actions/forms";
import { FETCH_LIMITS } from "utils/constants";

export const initialFormsState = {
  formsFilter: {
    page: 1,
    limit: FETCH_LIMITS.FORMS,
    active: true,
    search: undefined,
    sort_by_title: undefined,
    sort_by_customers: undefined,
    sort_by_responses: undefined,
    assigned_representative_id: undefined,
  },
  formsSwitch: [
    {
      value: "assigned_representative_id",
      label: "Assigned to",
      type: "select",
      checked: false,
      selectLabel: "Assigned to",
    },
  ],
};

const formsReducer = (state = initialFormsState, { type, payload }) => {
  switch (type) {
    case FORMS_FILTER:
      return { ...state, formsFilter: payload };

    case FORMS_SWITCH:
      return { ...state, formsSwitch: payload };

    case RESET_FORMS_FILTER:
      return { ...state, formsFilter: initialFormsState.formsFilter };

    case RESET_FORMS_SWITCH:
      return { ...state, formsSwitch: initialFormsState.formsSwitch };

    default:
      return state;
  }
};

export default formsReducer;
