import { object, func, bool, string } from "prop-types";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { ActiveIcon, CrossIcon, FutureRouteIcon } from "components/Icons";
import { ActionRestrictionWrapper, ThreeDotButton } from "components";
import { daysAgo, useAdmin } from "helpers/helpers";
import RouteProgressDropDown from "components/RouteProgressDropDown";
import { useCallback, useMemo } from "react";
import moment from "moment-timezone";
import { LargeCheckbox } from "components";

const RoutesItem = ({
  route,
  timeZone,
  handleDotsClick,
  handleViewRoute,
  handleCheckRoute,
  isAlreadySelected,
  disabledCreateEdit,
  setSelectedRepData,
  setAssignedAnchorEl,
  isLastItem = false,
}) => {
  const isAdmin = useAdmin();

  const isSelected = useMemo(
    () => isAlreadySelected(route.id),
    [isAlreadySelected, route.id]
  );

  const priority = useCallback(() => {
    const colors = {
      HIGH: "#E41E3A",
      MEDIUM: "#F39325",
      LOW: "#409A65",
    };

    const bgColors = {
      HIGH: "#FCE8EB",
      MEDIUM: "rgba(243, 147, 37, 0.10)",
      LOW: "rgba(64, 154, 101, 0.10)",
    };

    const { priority } = route;
    return (
      <Box
        sx={{
          width: "60px",
          height: "24px",
          background: bgColors[priority],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
        }}
      >
        <Typography
          sx={{ fontSize: "10px!important" }}
          fontWeight={500}
          color={colors[priority]}
        >
          {route.priority}
        </Typography>
      </Box>
    );
  }, [route]);

  const ROUTE_DATA = useMemo(() => {
    return [
      {
        xs: 1.4,
        wrapperProps: {
          sx: {
            display: "flex",
            //justifyContent: "center",
            alignItems: "center",
          },
        },
        element: route.isFutureRoute ? (
          <FutureRouteIcon />
        ) : route.active ? (
          <ActiveIcon />
        ) : (
          <CrossIcon fill="#FF0000" style={{ marginLeft: "-4px" }} />
        ),
      },
      {
        xs: 3.5,
        element: (
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                color: "#1C1C19",
                fontSize: "12px",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              component={Typography}
              noWrap
              onClick={(e) => {
                if (
                  e.metaKey ||
                  e.ctrlKey
                  //!route.assignedRepresentatives.length
                )
                  return;
                handleViewRoute(route);
              }}
            >
              {route.name}
            </Box>
          </Box>
        ),
      },
      {
        xs: 2.8,
        element: (
          <Box sx={{ color: "#1C1C19", fontSize: "12px" }}>
            {route.lastCheckIn
              ? daysAgo(route.lastCheckIn, timeZone, "MMM D, YYYY")
              : "-"}
          </Box>
        ),
      },
      {
        xs: 2.4,
        wrapperProps: {},
        element: <Typography>{route._count.customers}</Typography>,
      },
      {
        xs: 2.1,
        sx: {},
        wrapperProps: {},
        element: priority(),
      },
      {
        xs: 5.2,
        wrapperProps: { maxHeight: "48px" },
        element: (
          <RouteProgressDropDown
            dropDownItems={route.assignedRepresentatives}
            setAssignedAnchorEl={setAssignedAnchorEl}
            stops={route._count.customers}
            routeId={route?.id}
            setSelectedRepData={setSelectedRepData}
          />
        ),
      },
      {
        xs: 3.1,
        wrapperProps: {},
        element: (
          <Typography color={route.isFutureRoute ? "#F39325" : "#1C1C19"}>
            {route.activationDate
              ? moment.tz(route.activationDate, timeZone).format("MM/DD/YYYY")
              : "-"}
          </Typography>
        ),
      },
      {
        xs: 0.5,
        wrapperProps: {
          sx: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "5px",
          },
        },
        element: (
          <Stack direction="row" gap="14px" alignItems="center">
            <ActionRestrictionWrapper
              disableHoverListener={!disabledCreateEdit}
            >
              <ThreeDotButton
                paddingRight="7px"
                disabled={disabledCreateEdit || isAdmin}
                onClick={(e) => handleDotsClick(e, route)}
              />
            </ActionRestrictionWrapper>
          </Stack>
        ),
      },
    ];
  }, [
    disabledCreateEdit,
    handleDotsClick,
    handleViewRoute,
    isAdmin,
    priority,
    route,
    setAssignedAnchorEl,
    setSelectedRepData,
    timeZone,
  ]);

  return (
    <Box
      sx={{
        display: "flex",
        background: "#fff",
        "&:hover": {
          background: "#F7F7F7",
        },
        borderBottom: isLastItem ? "none" : "0.5px solid #D5D9D9",
        height: "100%",
      }}
      data-testid="route-row"
    >
      <Box
        width="82px"
        height="48px"
        maxHeight="48px"
        pl="16px"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <LargeCheckbox
          checked={isSelected}
          sx={{ padding: 0 }}
          formSx={{ m: 0 }}
          size={22}
          rectSize={22}
          onChange={() => handleCheckRoute(route)}
          onClick={(e) => e.stopPropagation()}
          checkedViewBox="0 0 23 23"
        />
      </Box>
      <Grid
        container
        columns={21}
        sx={{
          width: "calc(100% - 62px)",
          display: "flex",
          alignItems: "center",
          height: "48px",
          overflow: "hidden",
          "& .MuiTypography-root": {
            fontSize: "12px",
          },
        }}
      >
        {ROUTE_DATA.map(({ xs, element, wrapperProps }, index) => (
          <Grid key={index} item xs={xs} {...wrapperProps}>
            {element}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

RoutesItem.propTypes = {
  route: object,
  isLastItem: bool,
  timeZone: string,
  handleViewRoute: func,
  handleDotsClick: func,
  handleCheckRoute: func,
  isAlreadySelected: func,
  setSelectedRepData: func,
  disabledCreateEdit: bool,
  setAssignedAnchorEl: func,
};

export default RoutesItem;
