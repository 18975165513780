import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  actionWrapper: {
    borderLeft: "1px solid #D5D9D9",
    borderRight: "1px solid #D5D9D9",
    backgroundColor: "#47A06D",
    height: "39px",
    padding: "0px 19px 0 18px",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1,
    alignItems: "center",
    gap: "10px",
  },

  selectedCountWrapper: {
    minWidth: "22px",
    height: "22px",
    backgroundColor: "#FFFFFF30",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .MuiTypography-root": {
      padding: "0 6px",
      fontSize: "13px",
      color: "#ffff",
      fontWeight: 500,
    },
  },

  crossIcon: {
    marginLeft: "auto",
    padding: 0,

    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  divider: {
    height: "14px",
    ml: "11px",
    backgroundColor: "#ffff",
  },

  textSpan: {
    fontWeight: 700,
    color: "#ffff",
  },

  selectAllText: {
    fontSize: ({ isSmallScreen }) => (isSmallScreen ? "12px" : "13px"),
    fontWeight: 400,
    color: "#ffff",
    cursor: "pointer",

    "&:hover": {
      textDecoration: "underline",
    },
  },

  actionItemText: {
    fontSize: ({ isSmallScreen }) => (isSmallScreen ? "12px" : "13px"),
    fontWeight: 400,
    color: "#ffff",

    "&:hover": {
      textDecoration: "underline",
    },
  },

  selectWrapper: {
    "& .MuiSelect-select": {
      p: "0px 14px 0 0 !important",
    },
    height: "36px",
    "& .MuiOutlinedInput-input": {
      padding: "0px 14px 0px 0 !important",
      color: "#fff",
      fontSize: ({ isSmallScreen }) => (isSmallScreen ? "12px" : "13px"),
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      color: "#fff",
    },
  },

  selectPaper: {
    marginTop: "12px",
    borderRadius: "3px",
    minWidth: "135px!important",
    border: "0.5px solid #D5D9D9",
    boxShadow: "0 3px 6px #00000036",

    "& .MuiTypography-root": {
      fontSize: "12px",
      fontWeight: 400,
    },

    "& .MuiMenuItem-root": {
      borderRadius: "4px",

      "&:hover": { backgroundColor: "#F8F8F8" },
    },

    "& .MuiMenu-list": {
      py: "6px !important",
    },
  },
}));
