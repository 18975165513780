import { bool, func } from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Link,
  Typography,
} from "@mui/material";
import { ContactSalesFooter } from "components";
import { TrialIcon } from "components/Icons";
import { LINK_TO_SALES } from "components/TrialDialog/TrialDialog.constants";
import { cl } from "./ContactToSalesDialog.styles";

export const ContactToSalesDialog = ({ open = false, onClose = () => {} }) => {
  return (
    <Dialog {...{ open, onClose }} sx={cl.dialog}>
      <DialogContent sx={cl.content}>
        <Typography sx={cl.title}>Contact Sales</Typography>

        <Typography sx={cl.subtitle}>
          Please reach out to our sales team for and questions regarding your
          subscription.
        </Typography>

        <Box sx={cl.iconWrapper}>
          <TrialIcon sx={cl.icon} />
        </Box>

        <Box sx={cl.btnWrapper}>
          <Button
            sx={cl.btn}
            fullWidth
            variant="contained"
            color="primary"
            component={Link}
            href={LINK_TO_SALES}
          >
            Talk to Sales
          </Button>
        </Box>
      </DialogContent>
      <ContactSalesFooter />
    </Dialog>
  );
};

ContactToSalesDialog.propTypes = { open: bool, onClose: func };
