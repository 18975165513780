import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { bool, func, object } from "prop-types";
import moment from "moment";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { startCase } from "lodash";
import { NavLink } from "react-router-dom";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import StyledButton from "../../../../components/StyledButton";
import { CrossBigIcon, FilledPlusIcon } from "../../../../components/Icons";
import { defaultValues } from "./AcceptPaymentPopup.constants";
import { validationSchema } from "./AcceptPaymentPopup.validation";
import StyledDatePicker from "../../../../components/StyledDatePicker/StyledDatePicker";
import { success, error } from "../../../../utils/notifications";
import {
  acceptPaymentsService,
  updatePaymentsService,
  updateRefundService,
} from "../../../../services/orders";
import { getOrderByIdAction } from "../../../../redux/actions/orders";
import {
  normalizeUppercaseString,
  getFormattedDate,
  setCardName,
  useAdmin,
} from "helpers/helpers";
import CustomerCardPopup from "../../../CustomersPage/components/CustomerCreditCards/components/CustomerCardPopup/CustomerCardPopup";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
} from "../../../../redux/actions/confirmDialogs";
import EditPaymentPopup from "../../../CustomersPage/components/CustomerCreditCards/components/EditPaymentPopup/EditPaymentPopup";
import Loader from "../../../../components/Loader";
import {
  deleteCustomerCardService,
  getCustomerCardsService,
} from "../../../../services/stripe";
import { PriceFormat } from "../../../../components/TextFields/TextFields";
import StyledLabel from "../../../../components/StyledLabel/StyledLabel";
import StyledPopper from "../../../../components/StyledPopper/StyledPopper";
import { LargeCheckbox } from "../../../../components/Checkboxes";
import { updateUserFieldAction } from "../../../../redux/actions/auth";
import StyledTooltip from "../../../../components/StyledTooltip";
import { usePersonalDetails } from "../../../SettingsPage/components/SettingsTabs/PaymentsTab/components/PayoutsTab/PayoutsTab.hooks";
import { InfiniteScrollWrapper } from "components";
import { useRepsPermissions } from "helpers/hooks";

const AcceptPaymentPopup = ({
  isOpen,
  handleClose,
  order,
  viewPayment,
  handleClickEdit,
  editPayment,
  handleDelete,
  handleGetOrder,
  isOrderPage,
  successCallback,
}) => {
  const isAdmin = useAdmin();
  const repPermissions = useRepsPermissions();
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const disabledRepPermissions = useMemo(() => {
    return !!repPermissions;
  }, [repPermissions]);

  const cl = {
    boxTitle: {
      paddingTop: "14px",
      paddingLeft: "26px",
      paddingBottom: "12px",
    },
    boxActions: {
      paddingX: 3.25,
      paddingTop: "15px",
      paddingBottom: "20px",
      flexDirection: "column",
    },
    textTitle: { fontSize: "30px", color: "#707070" },
    textSubTitle: { fontSize: "20px", fontWeight: 400, color: "#707070" },
    textBody: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#000000",
    },
    block: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      height: "100%",
      flexDirection: "column",
      marginTop: 1,
    },
    blockTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      p: 0,
    },
    blockRadio: {
      marginX: 3.25,
    },
    blockDialog: {
      borderTop: "1px solid #D5D9D9",
      borderBottom: "1px solid #D5D9D9",
      width: "342px",
      paddingX: "32px",
    },
    textField: {
      "& .MuiInputBase-input": {
        fontSize: "11px",
      },
    },
    btnsBlock: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: "6px",
      marginRight: 1,
    },
    smallBtn: {
      borderColor: "#D4D4D4",
      color: "#000",
      maxWidth: "52px",
      minWidth: "52px",
      height: "16px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    checkboxFormSx: {
      mr: 0,
      flex: "1 1 auto",
      ml: "-9px",
    },
    checkboxLabel: {
      ml: "-4px",
      fontSize: "12px",
      fontWeight: 400,
    },
  };
  const dispatch = useDispatch();
  const balance = useMemo(() => {
    const totalAmount = order?.totalAmount ?? 0;
    const totalPayments = order?.totalPayments ?? 0;
    return (totalAmount - totalPayments)?.toFixed(2);
  }, [order?.totalAmount, order?.totalPayments]);

  const setTitle = useMemo(() => {
    if (viewPayment?.titlePopUp) {
      return viewPayment?.titlePopUp;
    } else if (editPayment?.titlePopUp) {
      return editPayment?.titlePopUp;
    } else {
      return "Accept payment";
    }
  }, [editPayment?.titlePopUp, viewPayment?.titlePopUp]);

  const setProcessedBy = useMemo(() => {
    if (viewPayment?.processedByQuickbooks) return "Quickbooks";
    if (!viewPayment?.processedBy) return "";
    if (viewPayment?.paymentType === "CREDIT_CARD_FROM_QUICKBOOKS")
      return "Quickbooks";
    if (viewPayment?.processedBy === "DISTRIBUTOR") return "Admin";
    if (viewPayment?.processedBy === "APP_CUSTOMER")
      return order?.customer?.name;
    if (viewPayment?.processedBy === "REPRESENTATIVE")
      return viewPayment?.representativeDuplicate?.name;
  }, [
    order?.customer?.name,
    viewPayment?.paymentType,
    viewPayment?.processedBy,
    viewPayment?.processedByQuickbooks,
    viewPayment?.representativeDuplicate?.name,
  ]);

  const setProcessedType = useMemo(() => {
    if (!viewPayment?.processedBy) return "";
    if (["APP_CUSTOMER", "DISTRIBUTOR"].includes(viewPayment?.processedBy))
      return "";

    return `| ${normalizeUppercaseString(viewPayment?.processedBy ?? "")}`;
  }, [viewPayment?.processedBy]);

  const isCardExpired = useCallback((month, year) => {
    const cardDate = moment(`${year}.${month}`).format("yyyy.MM");

    return moment().diff(cardDate, "months") > 0;
  }, []);

  const hasCustomerEmail = useMemo(() => {
    return (
      !!order?.customer?.email ||
      order?.customer?.contacts?.some((contact) => contact?.email)
    );
  }, [order?.customer?.contacts, order?.customer?.email]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      paymentType:
        editPayment?.paymentType ||
        order?.customer?.primaryPaymentMethod ||
        defaultValues.paymentType,
      date: editPayment?.date || defaultValues.date,
      amount: +(editPayment?.amount || balance || defaultValues.amount),
      initialAmount: +(
        (editPayment?.amount && +editPayment?.amount + +balance) ||
        balance ||
        defaultValues.amount
      ),
      check: editPayment?.check || defaultValues.check,
      reference: editPayment?.reference || defaultValues.reference,
      creditCards: [],
      lastCardId: "",
      cardId: editPayment?.cardId || "",
      sendNotification: currentUser.sendNotifications && hasCustomerEmail,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  useEffect(() => {
    if (isOpen) {
      reset({
        ...defaultValues,
        paymentType:
          editPayment?.paymentType ||
          order?.customer?.primaryPaymentMethod ||
          defaultValues.paymentType,
        date: editPayment?.date || new Date(Date.now()).toISOString(),
        amount: +(editPayment?.amount || balance || defaultValues.amount),
        initialAmount: +(
          (editPayment?.amount && +editPayment?.amount + +balance) ||
          balance ||
          defaultValues.amount
        ),
        check: editPayment?.check || defaultValues.check,
        reference: editPayment?.reference || defaultValues.reference,
        cardId: editPayment?.cardId || "",
        sendNotification: currentUser.sendNotifications && hasCustomerEmail,
      });
    }
  }, [
    balance,
    editPayment?.amount,
    editPayment?.cardId,
    editPayment?.check,
    editPayment?.creditCards,
    editPayment?.date,
    editPayment?.paymentType,
    order?.customer?.primaryPaymentMethod,
    hasCustomerEmail,
    editPayment?.reference,
    currentUser.sendNotifications,
    isCardExpired,
    isOpen,
    reset,
  ]);

  const [cardOpen, setCardOpen] = useState(false);
  const [editPaymentOpen, setEditPaymentOpen] = useState(false);
  const [editCard, setEditCard] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisableBtn, setIsDisableBtn] = useState(true);

  const hasBank = useMemo(
    () => !!currentUser.stripeBankAccountId,
    [currentUser.stripeBankAccountId]
  );

  const handleFetchCards = () => {
    const params = { cursor: formField?.lastCardId };
    getCustomerCardsService(order?.customer?.id, params).then((res) => {
      setValue(
        "lastCardId",
        res.hasMore ? res?.rows[res?.rows?.length - 1]?.id : ""
      );
      setValue("creditCards", [...formField?.creditCards, ...res.rows]);
    });
  };

  useEffect(() => {
    if (!formField?.cardId && formField?.creditCards?.length) {
      const chosenCardId = formField?.creditCards?.find((card) => {
        if (card.defaultMethod && !isCardExpired(card?.expMonth, card?.expYear))
          return card;
      });
      setValue("cardId", chosenCardId?.id ? chosenCardId?.id : "");
    }
  }, [formField?.cardId, formField?.creditCards, isCardExpired, setValue]);

  useEffect(() => {
    if (formField.paymentType === "CHECK") {
      if (
        formField.amount > 0 &&
        formField.amount <= formField.initialAmount &&
        !!formField.check &&
        !!formField.date
      ) {
        setIsDisableBtn(false);
      } else {
        setIsDisableBtn(true);
      }
    }
    if (formField.paymentType === "CASH") {
      if (
        +formField.amount > 0 &&
        formField.amount <= formField.initialAmount &&
        !!formField.date
      ) {
        setIsDisableBtn(false);
      } else {
        setIsDisableBtn(true);
      }
    }
    if (formField.paymentType === "CREDIT_CARD") {
      if (
        hasBank &&
        +formField.amount > 0 &&
        formField.amount <= formField.initialAmount &&
        formField?.creditCards?.length &&
        formField?.cardId
      ) {
        setIsDisableBtn(false);
      } else {
        setIsDisableBtn(true);
      }
    }
  }, [
    formField.paymentType,
    formField.amount,
    formField.check,
    formField?.creditCards,
    formField.date,
    errors,
    formField?.cardId,
    formField.initialAmount,
    hasBank,
  ]);
  const handleSaveCard = () => {
    fetchCustomerCards();
  };

  const handleSaveEditCard = () => {
    fetchCustomerCards();
  };

  const onConfirmDelete = (cardId) => {
    setIsLoading(true);
    deleteCustomerCardService(order?.customer?.id, cardId)
      .then(() => {
        fetchCustomerCards({
          onSuccess: () => {
            if (cardId === formField.cardId) setValue("cardId", "");
          },
        });
        success("Card deleted");
      })
      .catch((err) => {
        error("Something went wrong!");
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });

    dispatch(setConfirmIsOpenAction(false));
    setEditCard(null);
  };

  const handleConfirmDeleteCard = (card) => {
    dispatch(
      openConfirmDialogAction({
        title: "Delete payment method?",
        text: (
          <span>
            Are you sure you want to delete
            <span style={{ fontWeight: "500", color: "#000000" }}>
              {" "}
              {setCardName(card?.brand)} ending in{" "}
              {card.number?.slice(-4) || card.last4}
            </span>
            ? Removing this payment method will not cancel any of your open
            orders that use this method.
          </span>
        ),
        buttons: (
          <>
            <StyledButton
              sx={{
                width: "98px",
                color: "#6A6A6A",
                borderColor: "#D4D4D4",
                height: "28px",
                ml: "auto",
              }}
              onClick={() => {
                dispatch(setConfirmIsOpenAction(false));
              }}
              fontSize="13px"
              label="Cancel"
              variant="outlined"
            />

            <StyledButton
              sx={{
                width: "98px",
                color: "#FFFFFF",
                height: "28px",
                boxShadow: "none",
              }}
              color="confirmDelete"
              fontSize="13px"
              onClick={() => onConfirmDelete(card?.id)}
              variant="contained"
              label="Confirm"
            />
          </>
        ),
      })
    );
  };

  const fetchCustomerCards = useCallback(
    ({ onSuccess } = { onSuccess: () => {} }) => {
      setIsLoading(true);
      getCustomerCardsService(order?.customer?.id)
        .then((res) => {
          setValue(
            "lastCardId",
            res.hasMore ? res?.rows[res?.rows?.length - 1]?.id : ""
          );
          setValue("creditCards", [...res.rows]);
          if (onSuccess) onSuccess();
        })
        .catch((err) => error(err?.message))
        .finally(() => setIsLoading(false));
    },
    [order?.customer?.id, setValue]
  );

  useEffect(() => {
    if (isOpen && order?.customer?.id && !repPermissions) {
      fetchCustomerCards();
    }
  }, [fetchCustomerCards, isOpen, order?.customer?.id, repPermissions]);

  const onSubmit = useCallback(
    (data) => {
      const {
        paymentType,
        check,
        amount,
        reference,
        cardId,
        sendNotification,
      } = data;

      const date = new Date(data.date).toISOString();

      let uploadData = {
        paymentType,
        amount,
        sendNotification,
      };
      if (paymentType === "CHECK") {
        uploadData = {
          ...uploadData,
          check,
          date,
        };
      }
      if (paymentType === "CASH") {
        uploadData = {
          ...uploadData,
          reference,
          date,
        };
      }
      if (paymentType === "CREDIT_CARD") {
        if (!cardId) {
          setError("newCard", {
            type: "required",
            message: "Credit card is required",
          });
          return;
        }
        uploadData.cardId = cardId;
      }
      if (editPayment?.titlePopUp === "Edit refund") {
        setIsLoading(true);
        updateRefundService(editPayment?.id, uploadData)
          .then(() => {
            dispatch(
              updateUserFieldAction("sendNotifications", data.sendNotification)
            );
            success(`Refund $${uploadData.amount} updated`);
            if (successCallback) successCallback();
            dispatch(getOrderByIdAction(order?.id));
            if (handleGetOrder) handleGetOrder(order?.id);
          })
          .catch((err) => {
            error(err?.message || "Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
            handleClose();
          });
        return;
      }
      if (editPayment?.id) {
        setIsLoading(true);
        updatePaymentsService(editPayment?.id, uploadData)
          .then(() => {
            dispatch(
              updateUserFieldAction("sendNotifications", data.sendNotification)
            );
            success("Payment updated");
            if (successCallback) successCallback();
            dispatch(getOrderByIdAction(order?.id));
            if (handleGetOrder) handleGetOrder(order?.id);
          })
          .catch((err) => {
            error(err?.message || "Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
            handleClose();
          });
      } else {
        setIsLoading(true);
        acceptPaymentsService({ ...uploadData, orderId: order?.id })
          .then(() => {
            dispatch(
              updateUserFieldAction("sendNotifications", data?.sendNotification)
            );
            success("Payment created");
            if (successCallback) successCallback();
            dispatch(getOrderByIdAction(order?.id));
            if (!isOrderPage && handleGetOrder) handleGetOrder(order?.id);
          })
          .catch((err) => {
            error(err?.response?.data?.error || "Something went wrong", {
              autoClose: 10000,
            });
          })
          .finally(() => {
            setIsLoading(false);
            handleClose();
          });
      }
    },
    [
      dispatch,
      editPayment?.id,
      editPayment?.titlePopUp,
      handleClose,
      order?.id,
      setError,
      handleGetOrder,
      isOrderPage,
      successCallback,
    ]
  );

  const [openTooltip, setOpenTooltip] = useState(false);
  const amountRef = useRef();
  const notificationRef = useRef();
  const [openTooltipAmount, setOpenTooltipAmount] = useState(false);
  const [openTooltipNotification, setOpenTooltipNotification] = useState(false);
  const [bankTooltipState, setBankTooltipState] = useState({
    processOpen: false,
    addOpen: false,
  });

  const { allowedToAddCard, verificationStatus } = usePersonalDetails({
    isSetup: false,
  });

  useEffect(() => {
    setOpenTooltipAmount(
      (formField.amount < 0 || formField.amount > formField.initialAmount) &&
        isOpen
    );
  }, [formField.amount, formField.initialAmount, isOpen]);

  const handlerOpenTooltip = useMemo(() => {
    return (
      (bankTooltipState?.processOpen &&
        !hasBank &&
        formField?.paymentType === "CREDIT_CARD") ||
      (bankTooltipState?.processOpen &&
        formField?.amount &&
        !formField?.check &&
        formField?.paymentType === "CHECK")
    );
  }, [
    bankTooltipState?.processOpen,
    formField?.amount,
    formField?.check,
    formField?.paymentType,
    hasBank,
  ]);

  const { stripeAccountVerificationStatus } = verificationStatus ?? {};

  const getTooltipTitle = useMemo(() => {
    if (
      formField?.amount &&
      !formField?.check &&
      formField?.paymentType === "CHECK"
    ) {
      return <span>Enter the check number to proceed</span>;
    }

    if (stripeAccountVerificationStatus === "PENDING_VERIFICATION")
      return (
        <span style={{ whiteSpace: "pre-wrap" }}>
          {repPermissions && !repPermissions?.settings?.payments ? (
            "Personal details"
          ) : (
            <Link
              color="primary"
              to="/settings"
              state={{ tab: "Payments", paymentsTab: 1 }}
              component={NavLink}
            >
              Personal details
            </Link>
          )}{" "}
          verification status pending.
          {"\n"}
          Once approved you will be able to add credit cards.
        </span>
      );

    return (
      <span>
        No bank account linked to your profile. Add a{" "}
        {repPermissions && !repPermissions?.settings?.payments ? (
          "bank account"
        ) : (
          <Link
            color="primary"
            to="/settings"
            state={{ tab: "Payments", paymentsTab: 1 }}
            component={NavLink}
          >
            bank account
          </Link>
        )}{" "}
        under the payment section to proceed.
      </span>
    );
  }, [
    formField?.amount,
    formField?.check,
    formField?.paymentType,
    repPermissions,
    stripeAccountVerificationStatus,
  ]);

  const renderContent = () => {
    switch (formField.paymentType) {
      case "CHECK":
        return (
          <Box sx={{ marginBottom: "30px" }}>
            <Box>
              <Typography sx={cl.textBody}>Check #</Typography>
            </Box>
            <Box sx={cl.block}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "11px",
                      },
                    }}
                    placeholder="Check"
                    fullWidth
                    size="small"
                    InputProps={{ sx: { height: "30px" } }}
                    error={!!error}
                    {...field}
                    value={formField.check}
                  />
                )}
                name="check"
                control={control}
              />
            </Box>
          </Box>
        );

      case "CASH":
        return (
          <Box sx={{ marginBottom: "30px" }}>
            <Box>
              <Typography sx={cl.textBody}>Reference #</Typography>
            </Box>
            <Box sx={cl.block}>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: "11px",
                      },
                    }}
                    placeholder="Reference (optional)"
                    fullWidth
                    size="small"
                    InputProps={{ sx: { height: "30px" } }}
                    error={!!error}
                    {...field}
                    value={formField.reference}
                  />
                )}
                name="reference"
                control={control}
              />
            </Box>
          </Box>
        );

      case "CREDIT_CARD":
        return (
          <>
            <Grid container>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                xs={6}
                item
              >
                <Typography
                  sx={{ fontSize: 16, fontWeight: 600, color: "#363531" }}
                >
                  Customer cards
                </Typography>
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
                xs={6}
                item
              >
                <StyledTooltip
                  onOpen={() =>
                    setBankTooltipState((prev) => ({ ...prev, addOpen: true }))
                  }
                  onClose={() =>
                    setBankTooltipState((prev) => ({ ...prev, addOpen: false }))
                  }
                  open={
                    bankTooltipState.addOpen &&
                    formField.paymentType === "CREDIT_CARD" &&
                    !allowedToAddCard
                  }
                  title={getTooltipTitle}
                  placement="top"
                  arrow
                >
                  <Box>
                    <StyledButton
                      label="Add"
                      fontSize="14px"
                      endIcon={
                        <FilledPlusIcon style={{ marginLeft: "10px" }} />
                      }
                      color="blackBtn"
                      sx={{ height: "28px" }}
                      onClick={() => setCardOpen(true)}
                      disabled={!allowedToAddCard}
                    />
                  </Box>
                </StyledTooltip>
              </Grid>
            </Grid>
            <Controller
              render={({ field }) => (
                <Box
                  sx={{
                    minHeight: "42px",
                    maxHeight: "338px",
                    overflow: "hidden",
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                >
                  <InfiniteScrollWrapper
                    maxHeight="338px"
                    dataLength={formField?.creditCards?.length || 0}
                    hasMore={!!formField?.lastCardId}
                    loader={
                      <CircularProgress
                        sx={{ ml: "50%", mt: "2px" }}
                        size="20px"
                      />
                    }
                    next={handleFetchCards}
                    id="cards-scroll-popup"
                  >
                    <Grid
                      sx={{
                        gap: 1,
                      }}
                      component={RadioGroup}
                      container
                      {...field}
                    >
                      {errors.newCard && (
                        <Box sx={{ width: "100%", textAlign: "center" }}>
                          <Typography
                            fontSize="14px"
                            color="#FF6969"
                            fontWeight="400"
                          >
                            {errors.newCard.message}
                          </Typography>
                        </Box>
                      )}

                      {formField?.creditCards?.map((card) => {
                        const hasExpiresDateIsDecline = isCardExpired(
                          card?.expMonth,
                          card?.expYear
                        );

                        return (
                          <StyledTooltip
                            key={card?.id}
                            title={
                              "The card has expired. Check the expiration date or use a different card."
                            }
                            placement="top"
                            arrow
                            open={
                              openTooltip &&
                              hasExpiresDateIsDecline &&
                              openTooltip?.id === card?.id
                            }
                            onOpen={() => setOpenTooltip(card)}
                            onClose={() => setOpenTooltip(false)}
                          >
                            <Box
                              sx={{
                                border: "1px solid #DBE2ED",
                                borderRadius: "4px",
                                width: "100%",
                                pl: 1,
                                height: "78px",
                                display: "flex",
                                backgroundColor:
                                  formField?.cardId === card?.id
                                    ? "rgba(66, 165, 127, 0.12)"
                                    : "",
                              }}
                            >
                              <FormControlLabel
                                sx={{
                                  width: "100%",
                                  mr: 0,
                                }}
                                checked={formField?.cardId === card.id}
                                value={card?.id}
                                control={<Radio />}
                                disabled={hasExpiresDateIsDecline}
                                label={
                                  <Box
                                    sx={{
                                      pl: 1,
                                      maxWidth: "160px",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: 500,
                                          color: "#363531",
                                        }}
                                      >
                                        {startCase(card?.brand)} ****
                                        {card?.last4}
                                      </Typography>
                                      {hasExpiresDateIsDecline ? (
                                        <StyledLabel
                                          text="expired"
                                          style={{ pb: "2px" }}
                                        />
                                      ) : (
                                        card?.defaultMethod && (
                                          <StyledLabel style={{ pb: "4px" }} />
                                        )
                                      )}
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "#363531",
                                      }}
                                      noWrap
                                    >
                                      {card?.billingAddress?.name || card?.name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        color: "#363531",
                                      }}
                                    >
                                      Expires {card?.expMonth}/{card?.expYear}
                                    </Typography>
                                  </Box>
                                }
                              />
                              {!hasExpiresDateIsDecline && (
                                <Box sx={cl.btnsBlock}>
                                  <StyledButton
                                    label="Edit"
                                    variant="outlined"
                                    color="ground"
                                    fontSize="8px"
                                    fontWeight="400"
                                    sx={cl.smallBtn}
                                    onClick={() => {
                                      setEditCard(card);
                                      setEditPaymentOpen(true);
                                    }}
                                  />
                                  <StyledButton
                                    label="Remove"
                                    variant="outlined"
                                    color="ground"
                                    sx={cl.smallBtn}
                                    fontSize="8px"
                                    fontWeight="400"
                                    onClick={() =>
                                      handleConfirmDeleteCard(card)
                                    }
                                  />
                                </Box>
                              )}
                            </Box>
                          </StyledTooltip>
                        );
                      })}
                    </Grid>
                  </InfiniteScrollWrapper>
                </Box>
              )}
              name="cardId"
              control={control}
            />
          </>
        );

      default:
        return "";
    }
  };

  const radioDisabled = useMemo(() => {
    if (["Edit refund", "Edit payment"].includes(editPayment?.titlePopUp)) {
      if (["CASH", "CHECK"].includes(formField?.paymentType)) return false;
      return true;
    } else {
      return false;
    }
  }, [editPayment?.titlePopUp, formField?.paymentType]);

  const getPayInfo = useMemo(() => {
    return (
      <Box
        sx={{
          border: "1px solid #D5D9D9",
          borderRadius: "4px",
          paddingX: 2,
          paddingY: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {viewPayment?.paymentType === "CREDIT_CARD_FROM_QUICKBOOKS" && (
          <Box width="100%">
            <Typography
              sx={{
                fontSize: 12,
                fontWeight: 400,
                color: "#000",
              }}
            >
              via Quickbooks
            </Typography>
          </Box>
        )}
        <Box display="flex">
          {viewPayment?.paymentType !== "CREDIT_CARD" ? (
            <>
              <Box width="30%">
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#8A8A8A",
                  }}
                >
                  Amount:
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#8A8A8A",
                  }}
                >
                  Date:
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#8A8A8A",
                  }}
                >
                  {viewPayment?.paymentType === "CHECK" && "Check #:"}
                  {viewPayment?.paymentType === "CASH" && "Reference #:"}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: 12, color: "#000000" }}>
                  ${viewPayment?.amount?.toFixed(2)}
                </Typography>
                <Typography sx={{ fontSize: 12, color: "#000000" }}>
                  {getFormattedDate(viewPayment?.date).split(",")[0]}
                </Typography>
                <Typography sx={{ fontSize: 12, color: "#000000" }}>
                  {viewPayment?.paymentType === "CHECK" && viewPayment?.check}
                  {viewPayment?.paymentType === "CASH" &&
                    viewPayment?.reference}
                </Typography>
              </Box>
            </>
          ) : null}
          {viewPayment?.paymentType === "CREDIT_CARD" ? (
            <>
              <Box width="100%">
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  {startCase(viewPayment?.creditCard?.brand)} ****{" "}
                  {viewPayment?.creditCard?.last4}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#8A8A8A",
                  }}
                >
                  {viewPayment?.creditCard?.billingAddress?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 400,
                    color: "#8A8A8A",
                  }}
                >
                  Expires {viewPayment?.creditCard?.expMonth}/
                  {viewPayment?.creditCard?.expYear}
                </Typography>
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    );
  }, [
    viewPayment?.amount,
    viewPayment?.check,
    viewPayment?.creditCard?.billingAddress?.name,
    viewPayment?.creditCard?.brand,
    viewPayment?.creditCard?.expMonth,
    viewPayment?.creditCard?.expYear,
    viewPayment?.creditCard?.last4,
    viewPayment?.date,
    viewPayment?.paymentType,
    viewPayment?.reference,
  ]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <CustomerCardPopup
        isOpen={cardOpen}
        handleClose={() => setCardOpen(false)}
        customerCreditCards={formField?.creditCards}
        customerId={order?.customer?.id}
        orderId={order?.id}
        billingAddress={order?.customer?.billingAddress}
        handleSave={handleSaveCard}
      />
      <EditPaymentPopup
        isOpen={editPaymentOpen}
        customerId={order?.customer?.id}
        orderId={order?.id}
        handleClose={() => setEditPaymentOpen(false)}
        card={editCard}
        handleSave={handleSaveEditCard}
      />
      <StyledPopper
        style={{ zIndex: 1401 }}
        open={openTooltipAmount}
        anchorEl={amountRef.current}
        text="Payment cannot exceed the balance amount"
        modifiers={[{ name: "offset", options: { offset: [10, 10] } }]}
        placement="top"
        transition
        aria-hidden="true"
      />
      <StyledPopper
        style={{ zIndex: 1401 }}
        open={openTooltipNotification}
        anchorEl={notificationRef.current}
        text="No email address found for this customer"
        modifiers={[{ name: "offset", options: { offset: [-130, 0] } }]}
        placement="top"
        transition
        aria-hidden="true"
      />
      <Dialog maxWidth="lg" scroll="body" open={isOpen} onClose={handleClose}>
        <DialogTitle sx={cl.blockTitle}>
          <Box sx={cl.boxTitle}>
            <Typography sx={cl.textTitle}>{setTitle}</Typography>
            <Typography sx={cl.textSubTitle}>
              Order ID: {order?.customId?.customId || "-"}
            </Typography>
          </Box>
          <IconButton
            sx={{ marginTop: "20px", marginRight: "16px" }}
            onClick={handleClose}
          >
            <CrossBigIcon size={15.5} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0, minWidth: "342px", overflow: "visible" }}>
          <Grid
            sx={{
              backgroundColor: "rgba(71, 160, 110, 0.15)",
              height: 60,
              justifyContent: "start",
              alignItems: "center",
              paddingLeft: "26px",
            }}
            container
          >
            <Box
              sx={{
                fontSize: 12,
                fontWeight: 400,
                color: "#5F6267",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  fontSize: 12,
                  display: "flex",
                  fontWeight: 400,
                  color: "black",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ fontSize: 12 }}>Amount: </Typography>
                <Typography sx={{ fontSize: 12 }}> Payments: </Typography>
                <Typography sx={{ fontSize: 12 }}>Balance </Typography>
              </Box>
              <Box
                sx={{
                  fontSize: 12,
                  display: "flex",
                  fontWeight: 500,
                  paddingLeft: "20px",
                  color: "#5F6267",
                  flexDirection: "column",
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  ${order?.totalAmount?.toFixed(2) || "-"}{" "}
                </Typography>
                <Typography sx={{ fontSize: 12 }}>
                  ${order?.totalPayments?.toFixed(2) || 0}
                </Typography>
                <Typography sx={{ fontSize: 12, fontWeight: "500" }}>
                  ${balance || 0}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {viewPayment?.titlePopUp === "View refund" && viewPayment?.reason && (
            <Box mt={1} px="26px" maxWidth="342px">
              <Typography fontSize={12}>Refund Reason</Typography>
              <StyledTooltip title={viewPayment?.reason} followCursor>
                <Typography fontSize={12} fontWeight={300} noWrap>
                  {viewPayment?.reason}
                </Typography>
              </StyledTooltip>
            </Box>
          )}
          {viewPayment?.id ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingX: "32px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#1C1C19",
                    }}
                  >
                    {viewPayment?.paymentType === "CREDIT_CARD_FROM_QUICKBOOKS"
                      ? "Credit Card"
                      : normalizeUppercaseString(
                          viewPayment?.paymentType
                        ).replace("_", " ")}{" "}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {viewPayment?.paymentType !== "CREDIT_CARD" &&
                  !(
                    order?.orderPayments.length &&
                    order?.orderRefunds?.length &&
                    viewPayment.titlePopUp !== "View refund"
                  ) &&
                  viewPayment.titlePopUp !== "Restock details" ? (
                    <>
                      <StyledButton
                        disabled={
                          disabledRepPermissions ||
                          isAdmin ||
                          order?.orderStatus === "CANCELED"
                        }
                        sx={{ width: "26px", minWidth: "20px" }}
                        label="Edit"
                        color="primary"
                        onClick={() => handleClickEdit(viewPayment)}
                      />
                      <Box
                        sx={{
                          marginX: 0.2,
                          width: "1px",
                          height: "12px",
                          borderLeft: "1px solid #D5D9D9",
                        }}
                      />
                      <StyledButton
                        disabled={
                          disabledRepPermissions ||
                          isAdmin ||
                          order?.orderStatus === "CANCELED"
                        }
                        sx={{ width: "40px", minWidth: "20px" }}
                        label="Delete"
                        color="primary"
                        onClick={() =>
                          handleDelete(viewPayment?.id, viewPayment?.type)
                        }
                      />
                    </>
                  ) : (
                    <Box sx={{ height: "31px" }}></Box>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: "342px",
                  paddingX: "32px",
                }}
              >
                {getPayInfo}
              </Box>
            </>
          ) : (
            <>
              <Box sx={cl.blockRadio}>
                <Controller
                  render={({ field }) => (
                    <RadioGroup sx={{ display: "flex" }} row {...field}>
                      <FormControlLabel
                        disabled={radioDisabled}
                        value="CHECK"
                        sx={{ marginRight: 0 }}
                        label={
                          <Typography
                            sx={{
                              marginLeft: "-6px",
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#1C1C19",
                            }}
                            variant="caption"
                          >
                            Check
                          </Typography>
                        }
                        control={
                          <Radio
                            sx={{
                              "& svg": {
                                width: "16px",
                                height: "16px",
                              },
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        disabled={radioDisabled}
                        sx={{ m: 0 }}
                        value="CASH"
                        label={
                          <Typography
                            sx={{
                              marginLeft: "-6px",
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#1C1C19",
                            }}
                            variant="caption"
                          >
                            Cash (COD)
                          </Typography>
                        }
                        control={
                          <Radio
                            sx={{
                              "& svg": {
                                width: "16px",
                                height: "16px",
                              },
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        disabled={["Edit refund", "Edit payment"].includes(
                          editPayment?.titlePopUp
                        )}
                        sx={{
                          m: 0,
                        }}
                        value="CREDIT_CARD"
                        label={
                          <Typography
                            sx={{
                              marginLeft: "-6px",
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#1C1C19",
                            }}
                            variant="caption"
                            color="groundLighter.main"
                          >
                            Credit Card
                          </Typography>
                        }
                        control={
                          <Radio
                            sx={{
                              "& svg": {
                                width: "16px",
                                height: "16px",
                              },
                            }}
                          />
                        }
                      />
                    </RadioGroup>
                  )}
                  name="paymentType"
                  control={control}
                />
              </Box>
              <Box sx={cl.blockDialog}>
                <Grid
                  sx={{ paddingTop: "14px", marginBottom: "14px" }}
                  container
                  spacing={2}
                >
                  <Grid xs={6} item>
                    <Box>
                      <Typography sx={cl.textBody}>Amount</Typography>
                    </Box>
                    <Box ref={amountRef} sx={cl.block}>
                      <Controller
                        render={({ field, fieldState: { error } }) => (
                          <PriceFormat
                            sx={{
                              "& .MuiInputBase-input": {
                                fontSize: "11px",
                              },
                            }}
                            fullWidth
                            size="small"
                            InputProps={{
                              sx: {
                                height: "30px",
                              },
                            }}
                            error={error && " "}
                            {...field}
                            onValueChange={({ value }) => {
                              setValue("amount", +value);
                            }}
                            onChange={() => {}}
                          />
                        )}
                        name="amount"
                        control={control}
                      />
                    </Box>
                  </Grid>
                  {["CHECK", "CASH"].includes(formField.paymentType) && (
                    <Grid xs={6} item>
                      <Box>
                        <Typography sx={cl.textBody}>Date</Typography>
                      </Box>
                      <Box sx={{ ...cl.block, overflow: "hidden" }}>
                        <Controller
                          render={({ field, fieldState: { error } }) => (
                            <StyledDatePicker
                              height="30px"
                              borderColor="#C4C4C4"
                              textFieldProps={{ formSx: { width: "130px" } }}
                              error={error}
                              {...field}
                            />
                          )}
                          name="date"
                          control={control}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>

                {renderContent()}
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions sx={cl.boxActions}>
          <Box width="100%">
            {!viewPayment?.id && (
              <Controller
                render={({ field }) => (
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                    ref={notificationRef}
                    onMouseEnter={() => {
                      if (!hasCustomerEmail) {
                        setOpenTooltipNotification(true);
                      }
                    }}
                    onMouseLeave={() => {
                      setOpenTooltipNotification(false);
                    }}
                  >
                    <LargeCheckbox
                      disabled={!hasCustomerEmail}
                      checked={!!field.value}
                      formSx={cl.checkboxFormSx}
                      label={
                        <Typography
                          sx={{
                            ...cl.checkboxLabel,
                            color: hasCustomerEmail ? "#000" : "#B9B9B9",
                          }}
                        >
                          Send notification to the customer
                        </Typography>
                      }
                      size={16}
                      {...field}
                    />
                  </Box>
                )}
                name="sendNotification"
                control={control}
              />
            )}
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            {viewPayment?.id ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  marginBottom: "-10px",
                }}
              >
                {!!setProcessedBy && (
                  <Typography
                    sx={{ fontSize: 12, fontWeight: 400, color: "#BCBCBC" }}
                  >
                    Processed by:&nbsp;
                  </Typography>
                )}
                <Typography
                  sx={{ fontSize: 12, fontWeight: 400, color: "#5F6267" }}
                >
                  {setProcessedBy}&nbsp;
                </Typography>
                <Typography
                  sx={{ fontSize: 12, fontWeight: 400, color: "#5F6267" }}
                >
                  {setProcessedType}
                </Typography>
              </Box>
            ) : (
              <>
                <StyledButton
                  sx={{ color: "#6A6A6A" }}
                  label="Cancel"
                  color="groundLighter"
                  onClick={handleClose}
                />
                <StyledTooltip
                  onOpen={() =>
                    setBankTooltipState((prev) => ({
                      ...prev,
                      processOpen: true,
                    }))
                  }
                  onClose={() =>
                    setBankTooltipState((prev) => ({
                      ...prev,
                      processOpen: false,
                    }))
                  }
                  open={handlerOpenTooltip}
                  title={getTooltipTitle}
                  placement="top"
                  arrow
                >
                  <Box>
                    <StyledButton
                      disabled={isDisableBtn}
                      label={
                        setTitle === "Edit refund"
                          ? "Confirm"
                          : "Process payment"
                      }
                      variant="contained"
                      onClick={handleSubmit(onSubmit)}
                    />
                  </Box>
                </StyledTooltip>
              </>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

AcceptPaymentPopup.propTypes = {
  isOpen: bool,
  handleClose: func,
  order: object,
  handleClickEdit: func,
  viewPayment: object,
  editPayment: object,
  handleDelete: func,
  handleGetOrder: func,
  successCallback: func,
  isOrderPage: bool,
};

export default AcceptPaymentPopup;
