/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
import moment from "moment-timezone";
import pluralize from "pluralize";
import {
  CANADA_PHONE_CODES,
  COUNTRY_PHONE_CODES,
  EMAIL_REGEXP,
} from "utils/constants";
import { error, success } from "utils/notifications";
import { WEEK_DAYS } from "components/ReceivingHoursDrawer/ReceivingHoursDrawer.constants";
import { DISCOUNT_TYPES } from "Pages/ProductPricePage/constants";
moment.suppressDeprecationWarnings = true;

const ROLES_WITH_DEFAULT_AVATAR = ["Admin", "DISTRIBUTOR"];

export const separateNumWithComma = (num, options = {}) => {
  if (num === 0 || num === undefined) {
    num = Number(0).toPrecision(3);
  }
  if (num) {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options,
    }).format(num);
  }
  return num;
};

export const stringAvatar = (name, props, role) => {
  if (role && ROLES_WITH_DEFAULT_AVATAR.includes(role))
    return {
      sx: props,
    };

  if (name)
    return {
      sx: {
        bgcolor: stringToColor(name),
        ...props,
      },
      children: name.split(" ")[1]
        ? `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`
        : name[0],
    };

  return {
    sx: props,
  };
};

export const setNameAvatar = (name, role) => {
  if (role && ROLES_WITH_DEFAULT_AVATAR.includes(role)) return "";

  const namesForSkip = ["You"];
  if (name && namesForSkip.includes(name)) return "";
  return name;
};

export const setWidthByRef = (ref, defaultWidth) => {
  if (ref.current) {
    const width = ref.current.offsetWidth;
    return width;
  } else {
    return defaultWidth;
  }
};

export const matchEmail = (str) => {
  return EMAIL_REGEXP.test(str);
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    const pastelValue = Math.floor((value + 200) / 2);
    color += `00${pastelValue.toString(16)}`.substr(-2);
  }

  return color;
};

export const uploadProgress = (progressEvent, setProgress, key) => {
  if (key)
    return setProgress((prev) => ({
      ...prev,
      [key]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
    }));

  return setProgress(() =>
    Math.round((progressEvent.loaded * 100) / progressEvent.total)
  );
};

export const photoUrl = (fileName) => {
  if (!fileName) return null;
  const URL_REGEX =
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})|(blob:http:\/\/)/;
  const isUrlFirebase = URL_REGEX.test(fileName);

  return isUrlFirebase
    ? fileName
    : `${process.env.REACT_APP_GOOGLE_BUCKET}/${fileName}`;
};

export const mergeObjectArrayFields = (object) => {
  let merged = [];
  Object.keys(object).forEach((key) => (merged = merged.concat(object[key])));
  return merged;
};

export const largestTerritory = (territories) =>
  territories?.sort((ter1, ter2) => ter2.count - ter1.count)[0]?.name;

export const getFormattedDate = (dateStr, zone) => {
  if (!dateStr) return "";
  return new Date(dateStr).toLocaleString("en-US", {
    timeZone: zone || "America/New_York",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const colorSpectrum = (groups) => {
  const colorMap = [],
    inc = 50,
    start = 1000;
  let index = 0;
  for (let i = start; i < start + groups.length * inc; i += inc) {
    let num = ((4095 * i) >>> 0).toString(16);
    while (num.length < 3) {
      num = "0" + num;
    }
    colorMap.push({ color: "#" + num, id: groups[index].id });
    index++;
  }
  return colorMap;
};

export const containsEvery = (objects, array) => {
  return objects?.every(
    (obj) =>
      array.findIndex((item) => item.id === obj.id || item === obj.id) > -1
  );
};

export const containsNone = (objects, array) => {
  return !objects?.some(
    (obj) =>
      array.findIndex((item) => item.id === obj.id || item === obj.id) > -1
  );
};

export const normalizeUppercaseString = (str) => {
  if (str)
    return `${str.slice(0, 1).toUpperCase()}${str.slice(1).toLowerCase()}`;
  return "";
};

export const normalizeSnakeCaseString = (str) => {
  if (!str || typeof str !== "string") return;
  const splitStr = str.split("_");

  return splitStr.map((s) => normalizeUppercaseString(s)).join(" ");
};

export const normalizeEveryFirstLetterToUpper = (str) => {
  if (str) {
    return str
      .split(/\s+/)
      .map((word) => word[0].toUpperCase() + word.substring(1).toLowerCase())
      .join(" ");
  }
  return "";
};

export const reduceDate = (dateStr) => {
  const date = dateStr ? new Date(dateStr) : new Date(Date.now());
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const sliceCountry = (str) => {
  if (!str) return "";
  return str.split(",").slice(0, -1).join(",");
};

export const calculateAvailable = (onHand, allocated = 0) => {
  return Number(onHand) - Number(allocated);
};

export const setCardName = (name) => {
  if (!name) return "";
  if (name === "visa") return "Visa";
  if (name === "mastercard") return "Mastercard";
  if (name === "amex") return "AmEx";
  return "Card";
};

export const removeSeparatorFromNum = (num) => {
  return parseFloat(num.toString().replace(/,/g, ""));
};

export const downloadExcelFile = (res, type, name) => {
  const docData = new Blob([res], { type: res?.type });
  const downloadUrl =
    type === "report"
      ? URL.createObjectURL(res.data)
      : URL.createObjectURL(docData);
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getDateExpiration = (dateStr) => {
  const dateTimeStamp = new Date(dateStr).getTime();
  const dt = new Date(dateTimeStamp),
    date = dt.getDate(),
    diffDays = new Date().getDate() - date,
    diffMonths = new Date().getMonth() - dt.getMonth(),
    diffYears = new Date().getFullYear() - dt.getFullYear();

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0)
    return `Today, ${moment(dt).format("h:mm a")}`;
  else if (diffYears === 0 && diffDays === 1 && diffMonths === 0)
    return `Yesterday, ${moment(dt).format("h:mm a")}`;
  else return "Other";
};

export const setToMidnight = (date) => {
  return date.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
};

export const getCreatedAt = (createdAt, timeZone) => {
  const date = moment(getFormattedDate(createdAt, timeZone));
  const createdAtMidnight = moment(getFormattedDate(createdAt, timeZone));

  const formDate = formatDate({
    utc_date: createdAt,
    formatThisYear: "MMM D, h:mm a",
  });

  if (
    setToMidnight(moment()).diff(setToMidnight(createdAtMidnight), "days") < 2
  ) {
    return `${date.calendar().split(" ")[0]}, ${date.format("h:mm a")}`;
  }
  return date.format(formDate);
};

export const replaceItemById = (newItem, oldItem, arr) => {
  const newArr = [...arr];
  const index = newArr.findIndex((item) => item.id === oldItem.id);
  newArr.splice(index, 1, newItem);
  return newArr;
};

export const getMonthDiff = (date) => {
  return moment().diff(moment(date), "months");
};

export const renderTextLink = (txt) => {
  const URL_REGEX =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
  return txt
    .toString()
    .split(" ")
    .map((wordText) =>
      URL_REGEX.test(wordText) ? (
        <a href={wordText} target="_blank" rel="noreferrer">
          {wordText}{" "}
        </a>
      ) : (
        wordText + " "
      )
    );
};

export const setPhoneNumberMask = (num) => {
  if (!num) return "-";
  const firstChar = num.toString()[0];
  const n = firstChar === "+" || firstChar === "(" ? num : `+${num}`;
  if (/^\+/.test(n)) {
    return n.replace(/\+(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3 $4");
  } else {
    return n;
  }
};

export const setWebsiteMask = (url) => {
  const DOMAIN_URL_REGEX =
    // eslint-disable-next-line no-useless-escape
    /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim;

  const domain = url.match(DOMAIN_URL_REGEX);
  const queryString = url.replace(DOMAIN_URL_REGEX, "");
  const domainName = domain?.length
    ? domain[0].replace(
        // eslint-disable-next-line no-useless-escape
        /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?/gim,
        ""
      )
    : "";

  if (!url) return;
  return {
    url,
    domain: domain?.length ? domain[0] : null,
    domainName,
    queryString,
  };
};

// helpers for the products
export const getRangeWholeSalePrice = (childProducts) => {
  if (!childProducts || !childProducts?.length) return "";

  const getChildrenWholesales = childProducts?.map(
    (childProd) =>
      childProd?.priceWithPriceListDiscount || childProd?.wholesalePrice
  );

  const getUniqueWholesales = [...new Set(getChildrenWholesales)];

  const sortedResult = getUniqueWholesales.sort((a, b) => a - b);

  const getRange =
    sortedResult.length > 1
      ? [sortedResult[0], sortedResult[sortedResult.length - 1]]
      : [sortedResult[0]];

  const preparePrices = getRange
    .map((price) => `$${separateNumWithComma(price)}`)
    .join(" - ");

  return preparePrices;
};

export const removeEmptyValuesInObject = (obj) => {
  return Object.entries(obj)
    .filter(([, v]) => v !== "" && v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

export const getEnvironment = (name) => {
  // eslint-disable-next-line no-undef
  const currentEnvironment = process.env.REACT_APP_ENVIRONMENT;

  if (!name && currentEnvironment) return currentEnvironment;

  return currentEnvironment === name;
};

// const getUserRole = JSON.parse(localStorage.getItem("userRole"));

// export const isAdmin = getUserRole === "SUPER_ADMIN";

export const useAdmin = (adminIsAllowed) => {
  const getUserRole = sessionStorage.getItem("userRole");

  return getUserRole === "SUPER_ADMIN" && !adminIsAllowed;
};

export const useRepresentative = () => {
  const getUserRole = JSON.parse(localStorage.getItem("userRole"));

  return getUserRole === "REPRESENTATIVE";
};

export const generateAlphabetMap = (arr) => {
  const getColumnName = (index) => {
    let columnName = "";
    while (index >= 0) {
      columnName = String.fromCharCode((index % 26) + 65) + columnName;
      index = Math.floor(index / 26) - 1;
    }
    return columnName;
  };

  return arr.map((item, index) => ({ ...item, column: getColumnName(index) }));
};

export const handleCopyToBuffer = ({ text, message }) => {
  navigator.clipboard.writeText(text);
  success(message || "Copied!");
};

const startsWithHTTPorHTTPS = (str) => {
  const regex = /^(http|https):\/\//;
  return regex.test(str);
};

export const checkAndPrepareWebsiteForCustomer = (str) => {
  if (!str) return "";
  const checkStartString = startsWithHTTPorHTTPS(str);
  return checkStartString ? str : `http://${str}`;
};

export const checkFileSize = ({ fileSize, maxFileSizeMb }) => {
  if (!fileSize || !maxFileSizeMb) return;
  const fileSizeInMegabytes = fileSize / (1024 * 1024);

  return fileSizeInMegabytes > maxFileSizeMb;
};

export const getImageSizes = (file) => {
  return new Promise((resolve) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = function () {
      URL.revokeObjectURL(objectUrl);
      resolve({
        width: this.width,
        height: this.height,
        image: img,
      });
    };

    img.src = objectUrl;
  });
};

export const containsSpecialChars = (str) => {
  const regex = /[:\t\n]/;

  return regex.test(str);
};

export const checkAndPrepareText = (text) => {
  const arr = [];
  const options = [
    {
      symbol: ":",
      message: ": (colon)",
      regex: /[:]/,
    },
    {
      symbol: "\n",
      message: "\\n (new line symbol)",
      regex: /\n/,
    },
    {
      symbol: "\t",
      message: "\\t (tabulation symbol)",
      regex: /\t/,
    },
  ];

  options.forEach((option) => {
    if (option.regex.test(text)) {
      arr.push(option.message);
    }
  });

  return arr;
};

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export const convertFeetToMeters = (feet) => {
  if (!feet) return;
  const meters = feet * 0.3048;
  return meters;
};

export const getTimeZonesList = (userCountry = "US") => {
  return moment.tz.zonesForCountry(userCountry, true).map((t) => {
    const cityName = t.name.split("/").at(-1).split("_");

    const value = `${cityName.join(" ")}`;

    const minutes = t.offset % 60;

    const hours = (t.offset - minutes) / 60;

    const utc = `(UTC${t.offset > 0 ? "-" : "+"}${hours}${
      minutes ? `:${minutes}` : ":00"
    })`;

    const name = `${utc} ${value}`;

    return { value: t.name, name };
  });
};

export const normalizeCamelCase = (str) => {
  const words = str.split("_");
  const result = words
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word[0].toUpperCase() + word.slice(1);
    })
    .join("");

  return result;
};

export const normalizeCamelCaseWithSeparate = (text) => {
  const words = text.toLowerCase().split("_");

  const formattedAnswer = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedAnswer;
};

export const getPhoneCodes = (userCountry = "US") => {
  switch (userCountry) {
    case "US":
      return COUNTRY_PHONE_CODES;
    case "CA":
      return CANADA_PHONE_CODES;
    default:
      return COUNTRY_PHONE_CODES;
  }
};

export const onPastePhone = ({ e, type, setValue, currentValue }) => {
  e.preventDefault();

  const text = e.clipboardData.getData("Text");

  // eslint-disable-next-line no-useless-escape
  const cleanedNumber = text.replace(/[^\+\d]/g, "");

  if (cleanedNumber.startsWith("1") && cleanedNumber.length === 11) {
    if (setValue) {
      return setValue(type || "phone", `+${cleanedNumber}`);
    }
  }

  if (cleanedNumber.startsWith("+1") && currentValue === "+1") {
    if (setValue) {
      return setValue(type || "phone", cleanedNumber);
    }
  }

  if (setValue) setValue(type || "phone", text);
};

export const daysAgo = (date, userTimeZone, format = "DD MMM, YYYY") => {
  const userMoment = moment.tz(date, userTimeZone);
  const currentMoment = moment().tz(userTimeZone);

  const diffDays = currentMoment.diff(userMoment, "days");
  const diffWeeks = currentMoment.diff(userMoment, "weeks");
  const diffMonth = currentMoment.diff(userMoment, "month");

  if (diffDays === 0) return "Today";
  if (diffDays <= 7) return `${diffDays}   ${pluralize("day", diffDays)} ago`;
  if (diffMonth === 0)
    return `${diffWeeks} ${pluralize("week", diffWeeks)} ago`;

  return userMoment.format(format);
};

export const getDiffTime = (startDate, endDate, timeZone) => {
  if (startDate && endDate) {
    const formattedStartDate = moment(getFormattedDate(startDate, timeZone));
    const formattedEndDate = moment(getFormattedDate(endDate, timeZone));

    const duration = moment.duration(formattedEndDate.diff(formattedStartDate));

    const hours = duration.hours();
    const minutes = duration.minutes();

    if (!hours && !minutes) return null;

    return `(${hours ? `${hours}h ` : ""}${minutes ? `${minutes} min` : ""})`;
  }

  return null;
};

export const formatDate = ({
  utc_date = "2000-01-01T01:00:00.000Z",
  formatThisYear = "MMM D, h:mm a",
  formatPrevYear = "MMM D, YYYY",
}) => {
  const d = moment(utc_date);
  const year = d.year();
  const currentYear = moment().year();

  return year === currentYear ? formatThisYear : formatPrevYear;
};

export const resetScrollHeight = (id) => {
  const element = document.getElementById(id);
  element && element.scroll({ top: 0 });
};

export const scrollIntoElementView = (id) => {
  const element = document.getElementById(id);
  element && element.scrollIntoView();
};

const createdBySales = (order) =>
  order?.activities?.[0]?.representativeDuplicate?.name ||
  order?.salesDuplicate?.name ||
  "-";

export const getCreatedOrderBy = (order) => {
  if (order?.createdType === "DISTRIBUTOR") return "Admin";
  if (order?.createdType === "SALES") return createdBySales(order);
  if (order?.createdType === "ORDER_DIRECT") return "Order Direct";
  if (order?.createdType === "QUICKBOOKS") return "Quickbooks";
  return "-";
};

const DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const setWeekDays = (weekday_text) => {
  if (!Array.isArray(weekday_text)) return WEEK_DAYS;

  const preparedWeekDays = weekday_text.reduce((acc, curr) => {
    const [day, times] = curr.split(": ");

    if (times === "Closed") {
      acc[day] = {
        name: day,
        value: "",
      };
    } else if (times === "Open 24 hours") {
      acc[day] = {
        name: day,
        value: {
          clockInAt: "2023-12-12T00:00:00.444Z",
          clockOutAt: "2023-12-12T00:00:00.444Z",
        },
      };
    } else {
      let [clockIn, clockOut] = times.split(" – ");

      const hours24regex = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/;

      if (hours24regex.test(clockIn))
        clockIn = moment(clockIn, "HH:mm").format("h:mm A");
      if (hours24regex.test(clockOut))
        clockOut = moment(clockOut, "HH:mm").format("h:mm A");

      // eslint-disable-next-line no-irregular-whitespace
      const regex = /^([0-9]|1[0-2]):[0-5][0-9][ | ](AM|PM)$/;

      if (regex.test(clockIn) && regex.test(clockOut)) {
        const time24HourFormat1 = moment(clockIn, ["h:mm A"]).format("HH:mm");
        const time24HourFormat2 = moment(clockOut, ["h:mm A"]).format("HH:mm");

        const clockInAt = moment.utc(`2023-12-12T${time24HourFormat1}:00.444Z`);
        const clockOutAt = moment.utc(
          `2023-12-12T${time24HourFormat2}:00.444Z`
        );

        acc[day] = {
          name: day,
          value: {
            clockInAt: clockInAt.toISOString(),
            clockOutAt: clockOutAt.toISOString(),
          },
        };
      } else {
        acc[day] = {
          name: day,
          value: "",
        };
      }
    }

    return acc;
  }, {});

  const checkNameEnWeeks = Object.keys(preparedWeekDays).every((e) =>
    DAYS.includes(e)
  );

  return checkNameEnWeeks ? preparedWeekDays : WEEK_DAYS;
};

export const truncateText = (text, symbolCount = 20) => {
  if (typeof text !== "string") return text;

  if (text.length > symbolCount) {
    return text.substring(0, symbolCount) + "...";
  } else {
    return text;
  }
};

export const formatLongWords = (text, maxLength = 20) => {
  return text
    .split(" ")
    .map((word) => {
      if (word.length > maxLength) {
        const regex = new RegExp(`.{1,${maxLength}}`, "g");
        return word.match(regex).join(" ");
      }
      return word;
    })
    .join(" ");
};

export const handleCheckFiltersForApply = (obj) => {
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        if (obj[key].length > 0) {
          return true;
        }
      } else if (obj[key]) {
        return true;
      }
    }
  }
  return false;
};

export const handleCheckSwitchesForEmpties = (switchesList) => {
  return !!switchesList.filter((item) => item.checked).length;
};

export const formatLargeNumber = (sum) => {
  if (typeof sum !== "number" || Number.isNaN(sum)) return sum;

  const format = (value, suffix) =>
    (value % 1 === 0 ? value.toFixed(0) : value.toFixed(1)) + suffix;

  if (sum >= 1e21) return "∞";
  if (sum >= 1e18) return format(sum / 1e18, "Q");
  if (sum >= 1e15) return format(sum / 1e15, "P");
  if (sum >= 1e12) return format(sum / 1e12, "T");
  if (sum >= 1e9) return format(sum / 1e9, "B");
  if (sum >= 1e6) return format(sum / 1e6, "M");
  if (sum >= 1e3) return format(sum / 1e3, "K");

  return sum.toString();
};

export const addOnlySharedTags = (items) => {
  if (!items.length) return [];

  const allTags = items.flatMap((item) => item?.tags);
  const uniqueTagIds = [...new Set(allTags.map((tag) => tag?.tag?.id))];

  const uniqueTags = uniqueTagIds
    .map((id) => allTags.find((tag) => tag?.tag?.id === id))
    .filter((tag) =>
      items.every((item) =>
        item?.tags?.some((t) => t?.tag?.id === tag?.tag?.id)
      )
    );

  return uniqueTags;
};

export const handleError = (err) => {
  // eslint-disable-next-line no-console
  console.error(err?.response?.data?.message || err?.message);
  error(err);
};

export const isValidNotPastDate = (str, timeZone, allowPastDate = false) => {
  if (!str) return false;

  const parsedDate = moment.tz(str, timeZone);

  if (!parsedDate.isValid()) return false;

  if (allowPastDate) return true;

  const currentDate = moment.tz(timeZone).startOf("day");

  if (parsedDate.isBefore(currentDate, "day")) {
    return false;
  }

  return true;
};

export const getCalcProductPriceByPriceList = ({
  priceList = null,
  product,
}) => {
  const discountType =
    priceList?.[product.product?.id || product.id]?.discountType;
  const discountValue =
    priceList?.[product.product?.id || product.id]?.discountValue;

  const price = product?.hasOwnProperty("price")
    ? product.price || 0
    : product.wholesalePrice || product.price || 0;

  if (
    !discountType ||
    !discountValue ||
    (product?.hasOwnProperty("allowCalcPrice") && !product.allowCalcPrice)
  )
    return price;

  const PERCENTAGE_PRICE =
    DISCOUNT_TYPES[0] === discountType && price - price * (discountValue / 100);

  const ABSOLUTE_PRICE =
    DISCOUNT_TYPES[1] === discountType && price - discountValue;

  const TOTAL_PERCENTAGE_PRICE = PERCENTAGE_PRICE < 0 ? 0 : PERCENTAGE_PRICE;
  const TOTAL_ABSOLUTE_PRICE = ABSOLUTE_PRICE < 0 ? 0 : ABSOLUTE_PRICE;

  switch (discountType) {
    case DISCOUNT_TYPES[0]:
      return TOTAL_PERCENTAGE_PRICE;
    case DISCOUNT_TYPES[1]:
      return TOTAL_ABSOLUTE_PRICE;
    default:
      return price;
  }
};

export const getObjectDifference = (obj1, obj2) => {
  const difference = {};

  // Loop through keys of obj2
  for (let key in obj2) {
    // Compare the value in obj2 with the value in obj1
    if (obj1[key] !== obj2[key]) {
      difference[key] = obj2[key];
    }
  }

  return difference;
};

export const onPasteTextWithMaxLength = ({
  event = null,
  value = "",
  onChange = () => {},
  maxCharacters = 100,
}) => {
  if (!event) return;

  const pastedText = event.clipboardData.getData("text");
  const currentValue = value;
  const maxAllowedLength = maxCharacters - currentValue.length;

  const truncatedText = pastedText.slice(0, maxAllowedLength);

  event.preventDefault();
  const newValue = currentValue + truncatedText;

  if (newValue.length <= maxCharacters) {
    onChange(newValue);
  }
};
