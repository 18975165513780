import { useCallback, useEffect, useState } from "react";
import { getCategoriesService } from "services/categories";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";

const DEFAULT_STATE = {
  loading: false,
  list: [],
  count: 0,
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: SCROLL_LIMIT,
};

export const useCategoryList = ({ allowFetch = true, params = {} }) => {
  const [categoriesState, setCategoriesState] = useState(DEFAULT_STATE);
  const [categoriesParams, setCategoriesParams] = useState({
    ...DEFAULT_PARAMS,
    ...params,
  });

  const setLoading = (loading) =>
    setCategoriesState((prev) => ({ ...prev, loading }));

  const handleFetchCategories = useCallback(
    async (page) => {
      if (!page) {
        setLoading(true);
      }
      try {
        const res = await getCategoriesService({
          ...categoriesParams,
          page: page ? page : 1,
        });
        const rows = res?.rows || [];
        const count = res?.count || 0;
        setCategoriesState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count,
        }));
        setCategoriesParams((prev) => ({ ...prev, page: page ? page : 1 }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    },
    [categoriesParams]
  );

  useEffect(() => {
    if (allowFetch) handleFetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowFetch]);

  return {
    ...categoriesState,
    ...categoriesParams,
    handleFetchCategories,
  };
};
