import { object, string, func, bool } from "prop-types";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Stack } from "@mui/material";
import { DraftsFilter, DraftsHeader, DraftsItem } from "./components";
import {
  EmptyScreen,
  InfiniteLoadMoreBtn,
  InfiniteScrollWrapper,
} from "components";
import { useRepsPermissions } from "helpers/hooks";
import { useCallback, useEffect } from "react";
import OrderEmailDialog from "Pages/CartPage/components/OrderEmailDialog/OrderEmailDialog";
import { EmailLinkDialog } from "Pages/CartPage/components";
import { updateDistributorSettingsService } from "services/account";
import { setDirectColumnLayoutsAction } from "redux/actions/settings";
import { useDispatch } from "react-redux";
import { error } from "utils/notifications";
import { initialState } from "redux/reducers/settings";
import { useAdmin } from "helpers/helpers";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const DraftsTab = ({
  currentUser,
  currentTab,
  draftsData,
  params,
  handleFetchDrafts,
  menuItems,
  filtersData,
  emailDialogData,
  emailLinkDialogData,
  setEmailLinkDialogData,
  handleSendEmail,
  firstLoading,
}) => {
  const navigate = useNavigate();
  const repPermissions = useRepsPermissions();
  const isQuickBooksConnected = !!currentUser?.quickBooksTokens;

  const isAdmin = useAdmin();
  const dispatch = useDispatch();

  const { openEmailDialog, closeEmailDialog, ...emailDialog } = emailDialogData;

  const handleResetLayout = useCallback(async () => {
    const { drafts } = initialState.column_layouts;

    try {
      const res = await updateDistributorSettingsService({
        orderLayoutSettings: { drafts },
      });
      dispatch(setDirectColumnLayoutsAction(res?.orderLayoutSettings?.direct));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    }
  }, [dispatch]);

  // It should reset the columns to the initial state if their counts don't match
  const savedColumnsCount = {
    drafts:
      (currentUser?.orderLayoutSettings?.drafts?.disable?.length || 0) +
      (currentUser?.orderLayoutSettings?.drafts?.main?.length || 0),
  };
  const initColumnsCount = {
    drafts:
      initialState.column_layouts.drafts.disable?.length +
      initialState.column_layouts.drafts.main?.length,
  };

  useEffect(() => {
    if (savedColumnsCount?.drafts !== initColumnsCount?.drafts) {
      if (repPermissions || isAdmin) return;
      handleResetLayout();
    }
  }, [
    repPermissions,
    currentTab,
    handleResetLayout,
    initColumnsCount?.drafts,
    isAdmin,
    savedColumnsCount?.drafts,
  ]);

  return (
    <Box pt={1.5} px={4}>
      {!!emailDialog.open && (
        <OrderEmailDialog
          isOpen={emailDialog.open}
          handleClose={closeEmailDialog}
          order={emailDialog.order || {}}
          handleSendEmail={handleSendEmail}
          grandTotal={emailDialog?.order?.totalAmount?.toFixed(2) || 0}
          calculatedTotal={emailDialog?.order?.totalRawAmount?.toFixed(2) || 0}
          totalDiscount={
            (
              (emailDialog?.order?.totalRawAmount ?? 0) -
              (emailDialog?.order?.totalAmountWithoutDelivery ?? 0)
            )?.toFixed(2) || 0
          }
          deliveryFee={emailDialog?.order?.deliveryFee?.toFixed(2) || 0}
          isDraft
        />
      )}

      <EmailLinkDialog
        open={!!emailLinkDialogData}
        onClose={() => {
          setEmailLinkDialogData(null);
        }}
        emailLinkDialogData={emailLinkDialogData}
        onSubmit={(type = "THIRD_PARTY") => {
          navigate("/orders", {
            state: type === "THIRD_PARTY" ? "3rd Party" : "Direct",
          });
        }}
      />

      <DraftsFilter
        {...{
          filtersData,
          currentTab,
          loading: draftsData.existData && draftsData.loading,
        }}
      />

      {draftsData.list.length && draftsData.existData ? (
        <Box position="relative">
          <DraftsHeader />
          <Stack
            sx={{
              height: "100%",
              maxHeight: "calc(100vh - 242px)",
              overflow: "hidden",
              borderWidth: "0 1px 1px 1px",
              borderStyle: "solid",
              borderColor: "#D5D9D9",
              borderRadius: "0 0 4px 4px",
            }}
          >
            <InfiniteScrollWrapper
              maxHeight="calc(100vh - 243px)"
              dataLength={draftsData.list.length}
              loading={draftsData.loading}
              nextFunc={() => handleFetchDrafts({ page: params?.page + 1 })}
              hasMoreItems={draftsData.list.length < draftsData.count}
              style={{ overflowX: "hidden" }}
              id="draft_orders-scroll-table"
              loader={
                <CircularProgress
                  sx={{ ml: "calc(50% - 15px)", mt: "5px" }}
                  size="30px"
                />
              }
            >
              {draftsData.list.map((order, index) => (
                <DraftsItem
                  key={order.id}
                  order={order}
                  timeZone={currentUser.timeZone}
                  showBorder={index !== draftsData.list.length - 1}
                  isQuickBooksConnected={isQuickBooksConnected}
                  menuItems={menuItems(order)}
                  repPermissions={repPermissions}
                />
              ))}
              {!firstLoading && draftsData.list.length < draftsData.count && (
                <InfiniteLoadMoreBtn
                  onClick={() => handleFetchDrafts({ page: params?.page + 1 })}
                />
              )}
            </InfiniteScrollWrapper>
          </Stack>
        </Box>
      ) : (
        <EmptyScreen
          type="drafts"
          onConfirm={() => navigate("/cart")}
          height="calc(100vh - 453px)"
          showAction={!draftsData.existData}
          loading={firstLoading}
          disabled={!!repPermissions && !repPermissions?.orders?.create_edit}
          tooltipTitle={ADMIN_ONLY_VIEW_MESSAGE}
        />
      )}
    </Box>
  );
};

DraftsTab.propTypes = {
  currentUser: object,
  currentTab: string,
  draftsData: object,
  params: object,
  handleFetchDrafts: func,
  menuItems: func,
  filtersData: object,
  emailDialogData: object,
  emailLinkDialogData: object,
  setEmailLinkDialogData: func,
  handleSendEmail: func,
  firstLoading: bool,
};
DraftsTab.defaultProps = {
  currentTab: "",
  currentUser: {},
  draftsData: {},
  params: {},
  handleFetchDrafts: () => {},
  menuItems: () => {},
  filtersData: {},
  emailDialogData: {},
  emailLinkDialogData: {},
  setEmailLinkDialogData: () => {},
  handleSendEmail: () => {},
  firstLoading: false,
};
