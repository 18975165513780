/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";
export const PurchaseOrderIcon = ({
  fill = "#A5A5A5",
  strokeW = 1.5,
  ...props
}) => (
  <SvgIcon
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ width: 18, height: 20 }}
    style={{ fill: "none" }}
    {...props}
  >
    <path
      d="M5.5 5.75h3m-3 4h7m-7 4h7m-7 5.5h7c1.886 0 2.828 0 3.414-.586s.586-1.528.586-3.414V4.75c0-1.886 0-2.828-.586-3.414S14.386.75 12.5.75h-7c-1.886 0-2.828 0-3.414.586S1.5 2.864 1.5 4.75v10.5c0 1.886 0 2.828.586 3.414s1.528.586 3.414.586"
      stroke={fill}
      strokeWidth={strokeW}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
