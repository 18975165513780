import { makeStyles } from "@mui/styles";

export const cl = {
  search: {
    "& .MuiAutocomplete-paper": {
      boxShadow: "none",
    },
  },
  searchInput: {
    "& .MuiAutocomplete-endAdornment": { mr: "-2px" },
    "& .MuiOutlinedInput-root": {
      height: "32px",
      py: "0px !important",
      fontSize: "14px",
      fontWeight: 300,
      backgroundColor: "#FFF",
      "& > fieldset": {
        borderColor: "rgb(213,217,217)",
        borderWidth: "1px",
      },
    },
    "& .MuiInputLabel-root": {
      color: "rgb(181, 181, 172)",
      fontSize: "14px",
      transform: "translate(14px, 6px) scale(1)",
      "&.Mui-focused": {
        transform: "translate(14px, -7px) scale(0.75)",
      },
      "&.MuiInputLabel-shrink": {
        transform: "translate(14px, -7px) scale(0.75)",
      },
    },
    "& .MuiInputLabel-root.Mui-error": {
      color: "#FF3737 !important",
    },
  },
  searchListBoxProps: {
    p: 0,
    boxShadow: "none",
    borderRadius: "4px",
    zIndex: 1300,
    maxHeight: "250px",
  },
};

const useStyles = makeStyles(() => ({
  recipientsTable: {
    border: "1px solid #D5D9D9",
    width: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.12) !important",
    borderRadius: "4px",
    marginTop: "8px",
    padding: "8px 0",
    position: "absolute",
    top: "100%",
    left: 0,
    zIndex: 1300,
    overflow: "auto",
  },
  textfield: {
    color: "#ff0000",
    "& input::placeholder, & textarea::placeholder": {
      // color: "#BEBEBE",
      color: "#B5B5AC",
      opacity: 1,
      fontWeight: 400,
    },
  },
  noOptions: {
    padding: 0,
  },
}));

export default useStyles;
