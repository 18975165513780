import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { bool, func, string, number, object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./CancelDialog.validations.js";
import { success, error } from "utils/notifications.js";

import PaymentItem from "./components/PaymentItem";
import { cancelOrderService } from "services/orders.js";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { styles, useStyles } from "./CancelDialog.styles.js";
import { CrossBigIcon } from "components/Icons";
import {
  LargeCheckbox,
  StyledSelect,
  StyledButton,
  PaymentStatus,
  StyledTextField,
  StyledTooltip,
} from "components";
import { normalizeSnakeCaseString, setCardName } from "helpers/helpers.js";
import { RETURNS } from "./CancelDialog.constants.js";
import {
  endLoadingOrders,
  getOrderByIdAction,
  startLoadingOrders,
} from "redux/actions/orders.js";
import StyledPopper from "components/StyledPopper/StyledPopper.jsx";
import { ordersLoadingSelector } from "redux/selectors/orders.js";
import { updateUserFieldAction } from "redux/actions/auth.js";

const selector = createSelector(ordersLoadingSelector, (ordersLoading) => ({
  ordersLoading,
}));

const CancelDialog = ({
  open,
  onClose,
  order,
  title,
  orderId,
  customId,
  paymentStatus,
  deliveryStatus,
  handleGetOrder,
  isOrderPage,
  onCancelSuccess,
}) => {
  const classes = useStyles();

  const distributor = useSelector(({ auth }) => auth.currentUser);
  const { showIncludedPrices } = distributor || {};

  const { ordersLoading } = useSelector(selector);
  const dispatch = useDispatch();
  const notificationRef = useRef();

  const [openTooltipNotification, setOpenTooltipNotification] = useState(false);

  const hasThirdParty = useMemo(() => {
    return order?.type === "THIRD_PARTY";
  }, [order?.type]);

  const showRestockCheckbox = useMemo(
    () => !!order?.orderDeliveries?.length,
    [order?.orderDeliveries?.length]
  );

  const paymentCashList = order?.orderPayments?.filter(
    (payment) =>
      payment?.paymentType === "CASH" && payment?.amount > payment?.totalRefunds
  );

  const hasCustomerEmail = useMemo(() => {
    return (
      !!order?.customer?.email ||
      order?.customer?.contacts?.some((contact) => contact?.email)
    );
  }, [order?.customer?.contacts, order?.customer?.email]);

  const paymentCash = paymentCashList?.map((cash) => {
    return {
      amount: (cash?.amount - cash?.totalRefunds).toFixed(2),
      totalSumToRefund: cash?.amount - cash?.totalRefunds,
      paymentType: "Cash",
      isRefund: true,
      reference: cash?.reference,
      id: cash?.id,
    };
  });

  const paymentChecksList = order?.orderPayments?.filter(
    (payment) =>
      payment?.paymentType === "CHECK" &&
      payment?.amount > payment?.totalRefunds
  );

  const paymentChecks = paymentChecksList?.map((check) => {
    return {
      amount: (check?.amount - check?.totalRefunds).toFixed(2),
      totalSumToRefund: check?.amount - check?.totalRefunds,
      paymentType: "Check",
      isRefund: true,
      check: check?.check,
      id: check?.id,
    };
  });

  const paymentCreditCardsList = order?.orderPayments?.filter(
    (payment) =>
      payment?.paymentType === "CREDIT_CARD" &&
      payment?.amount > payment?.totalRefunds
  );

  const paymentCreditCards = paymentCreditCardsList?.map((card) => {
    return {
      last4: card?.creditCard?.last4,
      brand: card?.creditCard?.brand,
      amount: (card?.amount - card?.totalRefunds).toFixed(2),
      totalSumToRefund: card?.amount - card?.totalRefunds,
      paymentType: "Credit Card",
      isRefund: true,
      id: card?.id,
    };
  });

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      reason: RETURNS[0].name,
      otherReason: "",
      refundOptions: "Refund VIA",
      paymentRefund: [],
      restock: true,
      sendNotification: hasCustomerEmail,
      includePrices: showIncludedPrices,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const { fields } = useFieldArray({
    control,
    name: "paymentRefund",
  });

  useEffect(() => {
    const paymentRefund = [
      ...(paymentCreditCards?.length ? paymentCreditCards : []),
      ...(paymentChecks?.length ? paymentChecks : []),
      ...(paymentCash?.length ? paymentCash : []),
    ];
    setValue("paymentRefund", paymentRefund);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, order]);

  const [tooltipData, setTooltipData] = useState({
    hovered: false,
    message: "",
  });

  const btnBackProps = {
    sx: { color: "#5F6267", width: "94px", height: "33px" },
    fontSize: "15px",
    label: "Back",
    variant: "outlined",
    color: "groundLighter",
    onClick: onClose,
  };
  const btnConfirmProps = {
    disabled:
      ordersLoading ||
      (formField?.reason === "Other" && !formField?.otherReason),
    sx: { width: "94px", height: "33px" },
    fontSize: "15px",
    label: "Confirm",
    variant: "contained",
    color: "confirmDelete",
  };

  useEffect(() => {
    if (!open) {
      reset({
        reason: RETURNS[0].name,
        otherReason: "",
        refundOptions: "Refund VIA",
        paymentRefund: [],
        restock: true,
        sendNotification: hasCustomerEmail,
        includePrices: showIncludedPrices,
      });
    }
  }, [hasCustomerEmail, open, reset, showIncludedPrices]);

  const onSubmit = useCallback(
    (data) => {
      dispatch(startLoadingOrders());

      const preparedData = {
        orderId,
        reason: data.reason === "Other" ? data.otherReason : data.reason,
        refund:
          data.refundOptions === "Refund VIA" &&
          order?.totalPayments > 0 &&
          data?.paymentRefund.some((el) => el?.isRefund),
        sendNotification: data.sendNotification,
        ...(hasThirdParty && { includePrices: data.includePrices }),
        // applyForInventory: formField.restock,
      };

      dispatch(updateUserFieldAction("showIncludedPrices", data.includePrices));

      hasThirdParty
        ? (preparedData.restock = false)
        : (preparedData.restock = data.restock);

      const payments = data?.paymentRefund
        ?.filter((el) => el?.isRefund)
        ?.map((payment) => {
          return {
            id: payment.id,
            amount: +payment.amount,
            // paymentType: payment?.paymentType?.toUpperCase().replace(" ", "_"),
          };
        });

      if (
        data.refundOptions === "Refund VIA" &&
        order?.totalPayments > 0 &&
        data?.paymentRefund.some((el) => el?.isRefund)
      ) {
        preparedData.payments = payments;
      }

      cancelOrderService(preparedData)
        .then(() => {
          if (onCancelSuccess) onCancelSuccess();
          dispatch(getOrderByIdAction(orderId));
          success(`Order ${customId} canceled`);
          !isOrderPage && handleGetOrder(orderId);
        })
        .catch((err) => error(err.message || "Something went wrong"))
        .finally(() => {
          dispatch(endLoadingOrders());
          onClose();
        });
    },
    [
      dispatch,
      orderId,
      order?.totalPayments,
      hasThirdParty,
      onCancelSuccess,
      customId,
      isOrderPage,
      handleGetOrder,
      onClose,
    ]
  );

  const setTitle = (item) => {
    if (item?.paymentType === "Credit Card")
      return `${setCardName(item?.brand)} **** ${item?.last4}`;
    if (item?.paymentType === "Check") return `Ref #: ${item?.check}`;
    if (item?.paymentType === "Cash") {
      if (item?.reference) {
        return `Ref # ${item?.reference}`;
      } else {
        return "";
      }
    }
  };

  return (
    <>
      <StyledPopper
        style={{ zIndex: 1401 }}
        open={openTooltipNotification}
        anchorEl={notificationRef.current}
        text="No email address found for this customer"
        modifiers={[{ name: "offset", options: { offset: [-120, 0] } }]}
        placement="top"
        transition
        aria-hidden="true"
      />

      <Dialog maxWidth="md" onClose={onClose} open={open}>
        <DialogTitle sx={styles.dialogTitleBlock}>
          <Box sx={styles.dialogTitleBox}>
            <Typography sx={styles.dialogTitle}>{title}</Typography>
            <Typography sx={styles.dialogSubtitle}>
              Order ID: {customId}
            </Typography>
          </Box>
          <IconButton sx={styles.crossIcon} onClick={onClose}>
            <CrossBigIcon size={27.9} />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={styles.dialogContentBlock}>
          <Grid sx={{ marginY: 2 }} container>
            {!hasThirdParty && (
              <Grid sx={{ paddingLeft: "26px" }} xs item container>
                <Grid item xs={12} container>
                  <Box>
                    <Typography sx={styles.text.payment}>
                      Payment Status
                    </Typography>
                  </Box>
                  <PaymentStatus str={paymentStatus} />
                </Grid>
                <Grid item xs={12} container>
                  <Box display="flex" alignItems="center">
                    <Typography sx={styles.text.delivery}>
                      Delivery Status
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        height: "6px",
                        width: "6px",
                        backgroundColor:
                          deliveryStatus === "UNFULFILLED"
                            ? "#F0C401"
                            : "#47A06D",
                        borderRadius: "50%",
                        mr: "8px",
                      }}
                    />
                    <Typography sx={styles.text.status}>
                      {normalizeSnakeCaseString(deliveryStatus)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}

            {!hasThirdParty && (
              <Grid sx={{ borderLeft: "1px solid #D5D9D9" }} xs={0.1} item />
            )}

            <Grid
              sx={{
                paddingLeft: hasThirdParty ? "26px" : "16px",
                paddingRight: hasThirdParty ? 0 : "26px",
              }}
              xs={hasThirdParty ? 6.2 : 6}
              item
            >
              <Grid xs={12} item>
                <Typography sx={{ fontSize: 12, color: "#000000" }}>
                  Reason for cancelation?
                </Typography>
                <FormControl
                  sx={{ marginTop: 1 }}
                  className={classes.selectInput}
                  fullWidth
                  size="small"
                >
                  <Controller
                    render={({ field }) => (
                      <StyledSelect
                        notched={false}
                        leftSidedIcon
                        sx={{
                          height: "28px",
                          "& .MuiInputBase-input": {
                            color: "#000000 !important",
                            paddingRight: "8px !important",
                          },
                        }}
                        IconComponent={ExpandMore}
                        {...field}
                      >
                        {RETURNS.map((el) => (
                          <MenuItem
                            sx={{ color: "#000000" }}
                            key={el.id}
                            value={el.name}
                          >
                            {el.name}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    )}
                    name="reason"
                    control={control}
                  />
                </FormControl>
              </Grid>
            </Grid>

            {formField?.reason === "Other" && (
              <>
                {!hasThirdParty && <Grid xs={6} item />}

                <Grid xs={hasThirdParty ? 6.2 : 6} item sx={{ mt: 1 }}>
                  <Box
                    marginLeft={hasThirdParty ? "26px" : "16px"}
                    marginRight={hasThirdParty ? 0 : "26px"}
                  >
                    <Controller
                      render={({ field, fieldState: { error } }) => (
                        <StyledTextField
                          placeholder="Reason"
                          multiline
                          minRows={1}
                          maxRows={5}
                          fullWidth
                          inputProps={{ maxLength: 2000 }}
                          sx={{
                            "& .MuiOutlinedInput-root": { height: "100%" },
                          }}
                          error={error ? error?.message : ""}
                          {...field}
                        />
                      )}
                      name="otherReason"
                      control={control}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          {order?.totalPayments > 0 && (
            <Grid container mb={showRestockCheckbox ? 0 : 2}>
              <Grid paddingX={3.25} container>
                <Grid sx={styles.refundHeader} item xs={12}>
                  <Typography sx={styles.text.refund}>
                    Payment Refund
                  </Typography>
                </Grid>
              </Grid>
              <Grid paddingX={3.25} container>
                <Grid sx={styles.refundBody} item xs={12}>
                  <Grid item xs={12} container justifyContent="space-between">
                    <Controller
                      render={({ field }) => (
                        <FormControl
                          sx={{
                            marginLeft: 0,
                            width: "100%",
                          }}
                          component="fieldset"
                        >
                          <RadioGroup
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                            {...field}
                          >
                            <FormControlLabel
                              value="Refund VIA"
                              sx={{
                                marginLeft: 1,
                                marginRight: 0,
                                marginBottom: 0.5,
                                "& .MuiFormControlLabel-label": {
                                  width: "100%",
                                },
                              }}
                              label={
                                <Typography
                                  sx={styles.text.labelRefund}
                                  variant="caption"
                                >
                                  Refund VIA
                                </Typography>
                              }
                              control={
                                <Radio
                                  sx={{
                                    "& svg": {
                                      width: "16px",
                                      height: "16px",
                                    },
                                  }}
                                />
                              }
                            />
                            {formField.refundOptions === "Refund VIA" && (
                              <Grid
                                sx={{
                                  maxHeight: "210px",
                                  overflow: "overlay",
                                  paddingX: 2,
                                }}
                                gap={1.5}
                                container
                              >
                                {fields?.map((payment, index) => {
                                  return (
                                    <PaymentItem
                                      key={payment?.id}
                                      type={payment?.paymentType}
                                      title={setTitle(payment)}
                                      sum={payment?.amount}
                                      control={control}
                                      setValue={setValue}
                                      index={index}
                                      isChecked={
                                        formField?.paymentRefund?.find(
                                          (refund) => refund.id === payment?.id
                                        )?.isRefund
                                      }
                                    />
                                  );
                                })}
                              </Grid>
                            )}

                            {/* {formField.refundOptions === "Refund VIA" && (
                            <>
                              <Box
                                sx={{
                                  paddingX: "21px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <>
                                  <FormControl
                                    sx={{ width: "60%" }}
                                    className={classes.selectInput}
                                    fullWidth
                                    size="small"
                                  >
                                    <Controller
                                      render={({ field }) => (
                                        <StyledSelect
                                          notched={false}
                                          leftSidedIcon
                                          sx={{
                                            height: "30px",
                                            "& .MuiInputBase-input": {
                                              color: "#000000 !important",
                                            },
                                          }}
                                          IconComponent={ExpandMore}
                                          {...field}
                                        >
                                          <MenuItem
                                            sx={{ color: "#000000" }}
                                            value="CREDIT_CARD"
                                          >
                                            Credit card
                                          </MenuItem>
                                          <MenuItem
                                            sx={{ color: "#000000" }}
                                            value="CHECK"
                                          >
                                            Check
                                          </MenuItem>
                                          <MenuItem
                                            sx={{ color: "#000000" }}
                                            value="CASH"
                                          >
                                            Cash
                                          </MenuItem>
                                        </StyledSelect>
                                      )}
                                      name="paymentType"
                                      control={control}
                                    />
                                  </FormControl>
                                  <Box
                                    sx={{
                                      width: "30%",
                                      position: "relative",
                                    }}
                                  >
                                    <Typography sx={styles.text.amount}>
                                      Amount
                                    </Typography>
                                    <Controller
                                      render={({
                                        field,
                                        fieldState: { error },
                                      }) => (
                                        <StyledTextField
                                          fullWidth
                                          placeholder="Amount"
                                          InputProps={{
                                            className: classes.textInput,
                                          }}
                                          error={!!error}
                                          {...field}
                                        />
                                      )}
                                      name="amount"
                                      control={control}
                                    />
                                  </Box>
                                </>
                              </Box>
                              <Box
                                sx={{
                                  marginTop: 1.5,
                                  paddingX: "21px",
                                }}
                              >
                                {formField.paymentType === "CREDIT_CARD" && (
                                  <FormControl
                                    sx={{ width: "60%" }}
                                    className={classes.selectInput}
                                    fullWidth
                                    size="small"
                                  >
                                    <Controller
                                      render={({ field }) => (
                                        <StyledSelect
                                          notched={false}
                                          leftSidedIcon
                                          sx={{
                                            height: "30px",
                                            "& .MuiInputBase-input": {
                                              color: "#000000 !important",
                                            },
                                          }}
                                          IconComponent={ExpandMore}
                                          {...field}
                                        >
                                          <MenuItem
                                            sx={{ color: "#000000" }}
                                            value="Credit card **** 7632"
                                          >
                                            Credit card **** 7632
                                          </MenuItem>
                                        </StyledSelect>
                                      )}
                                      name="creditCard"
                                      control={control}
                                    />
                                  </FormControl>
                                )}
                                {(formField.paymentType === "CHECK" ||
                                  formField.paymentType === "CASH") && (
                                  <Box sx={{ width: "60%" }}>
                                    <Controller
                                      render={({
                                        field,
                                        fieldState: { error },
                                      }) => (
                                        <StyledTextField
                                          fullWidth
                                          placeholder={
                                            formField.paymentType === "CHECK"
                                              ? "Check #"
                                              : "Reference (optional)"
                                          }
                                          InputProps={{
                                            className: classes.textInput,
                                          }}
                                          error={!!error}
                                          {...field}
                                        />
                                      )}
                                      name="check"
                                      control={control}
                                    />
                                  </Box>
                                )}
                              </Box>
                              <Box>
                                <Typography sx={styles.text.availableRefund}>
                                  ${totalPayments?.toFixed(2)} available for
                                  refund
                                </Typography>
                              </Box>
                              <Box sx={{ paddingX: "21px" }}>
                                <Divider />
                              </Box>
                            </>
                          )} */}
                            <FormControlLabel
                              value="Refund later"
                              sx={{ marginLeft: 1, marginY: 0.5 }}
                              label={
                                <Typography
                                  sx={styles.text.later}
                                  variant="caption"
                                >
                                  Refund later
                                </Typography>
                              }
                              control={
                                <Radio
                                  sx={{
                                    "& svg": {
                                      width: "16px",
                                      height: "16px",
                                    },
                                  }}
                                />
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                      name="refundOptions"
                      control={control}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {showRestockCheckbox && (
            <Box sx={{ paddingX: "26px" }}>
              <Controller
                render={({ field }) => (
                  <LargeCheckbox
                    checked={!!field.value}
                    formSx={styles.checkboxFormSx}
                    label={
                      <Typography sx={styles.checkboxLabel}>
                        Restock items
                      </Typography>
                    }
                    size={16}
                    {...field}
                  />
                )}
                name="restock"
                control={control}
              />
            </Box>
          )}
        </DialogContent>

        <DialogActions sx={styles.dialogActionsBlock}>
          <Stack direction="column" width="fit-content" mr="auto">
            <Controller
              render={({ field }) => (
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                  ref={notificationRef}
                  onMouseEnter={() => {
                    if (!hasCustomerEmail) {
                      setOpenTooltipNotification(true);
                    }
                  }}
                  onMouseLeave={() => {
                    setOpenTooltipNotification(false);
                  }}
                >
                  <LargeCheckbox
                    disabled={!hasCustomerEmail}
                    checked={!!field.value}
                    formSx={styles.checkboxFormSx}
                    label={
                      <Typography
                        sx={{
                          ...styles.checkboxLabel,
                          color: hasCustomerEmail ? "#000" : "#B9B9B9",
                        }}
                      >
                        Send notification to the customer
                      </Typography>
                    }
                    size={16}
                    {...field}
                  />
                </Box>
              )}
              name="sendNotification"
              control={control}
            />
            {hasThirdParty && hasCustomerEmail && formField?.sendNotification && (
              <Controller
                render={({ field }) => (
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <LargeCheckbox
                      disabled={!hasCustomerEmail}
                      checked={!!field.value}
                      formSx={styles.checkboxFormSx}
                      label={
                        <Typography
                          sx={{
                            ...styles.checkboxLabel,
                            color: hasCustomerEmail ? "#000" : "#B9B9B9",
                          }}
                        >
                          Include pricing
                        </Typography>
                      }
                      size={16}
                      {...field}
                    />
                  </Box>
                )}
                name="includePrices"
                control={control}
              />
            )}
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            mt={hasThirdParty ? "16px" : "0px"}
          >
            <StyledButton {...btnBackProps} />
            <StyledTooltip
              title={tooltipData?.message}
              open={tooltipData?.hovered && !!tooltipData?.message}
              placement="top"
              arrow
            >
              <Box
                component="span"
                marginLeft={1.5}
                onMouseEnter={() => {
                  trigger();
                  setTooltipData((prev) => ({
                    ...prev,
                    hovered: true,
                    message: errors?.otherReason
                      ? "Reason description is missing"
                      : "",
                  }));
                }}
                onMouseLeave={() => {
                  setTooltipData((prev) => ({
                    ...prev,
                    hovered: false,
                  }));
                }}
              >
                <StyledButton
                  {...btnConfirmProps}
                  onClick={handleSubmit(onSubmit)}
                />
              </Box>
            </StyledTooltip>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};

CancelDialog.propTypes = {
  open: bool,
  onClose: func,
  order: object,
  title: string,
  orderId: string,
  customId: string,
  paymentStatus: string,
  deliveryStatus: string,
  totalPayments: number,
  handleGetOrder: func,
  isOrderPage: bool,
  onCancelSuccess: func,
};
CancelDialog.defaultProps = {
  title: "Cancel order",
};

export default CancelDialog;
