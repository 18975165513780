/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";
import * as React from "react";
export const PermissionCatalogIcon = (props) => (
  <SvgIcon
    viewBox="0 0 20 18"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ width: 20, height: 18, fill: "none" }}
    {...props}
  >
    <path
      d="M19.67 1.835a.75.75 0 1 0-1.34-.67zm-18-.67a.75.75 0 0 0-1.34.67zM6 11.75a.75.75 0 0 0 0 1.5zm4 1.5a.75.75 0 0 0 0-1.5zm-7-7h14v-1.5H3zm13.25-.75v9h1.5v-9zM15 15.75H5v1.5h10zM3.75 14.5v-9h-1.5v9zM5 15.75c-.493 0-.787-.002-.997-.03a.7.7 0 0 1-.18-.043l-1.06 1.06c.309.31.684.422 1.04.47.334.045.747.043 1.197.043zM2.25 14.5c0 .45-.002.863.043 1.197.048.356.16.731.47 1.04l1.06-1.06-.001-.003-.005-.01a.7.7 0 0 1-.037-.167c-.028-.21-.03-.504-.03-.997zm14 0c0 .493-.002.787-.03.997a.7.7 0 0 1-.043.18l1.06 1.06c.31-.309.422-.684.47-1.04.045-.334.043-.747.043-1.197zM15 17.25c.45 0 .863.002 1.197-.043.356-.048.731-.16 1.04-.47l-1.06-1.06-.003.001-.01.005a.7.7 0 0 1-.167.037c-.21.028-.504.03-.997.03zm2.67-11.415 2-4-1.34-.67-2 4zm-14-.67-2-4-1.34.67 2 4zM6 13.25h4v-1.5H6z"
      fill="#409A65"
    />
  </SvgIcon>
);
