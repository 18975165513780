import { bool, func, object } from "prop-types";
import { Grid, Stack, Typography } from "@mui/material";
import { OutlinedPlusIcon } from "components/Icons";
import { useState } from "react";

export const ManufacturerItem = ({
  isAdd,
  item,
  onClick = () => {},
  disabled = false,
}) => {
  const [isItemHovered, setIsItemHovered] = useState(false);
  const { name } = item || {};

  return (
    <Grid
      onMouseEnter={() => setIsItemHovered(true)}
      onMouseLeave={() => setIsItemHovered(false)}
      onClick={() => {
        if (disabled) return;
        onClick(item);
      }}
      sx={{
        p: "4px 10px 4px 16px",
        overflow: "hidden",
        cursor: disabled ? "default" : "pointer",
        background: isItemHovered && !disabled ? "#F5F5F5" : "#ffff",
        opacity: disabled ? 0.5 : 1,
        position: "relative",
        maxHeight: "32px",
        height: "32px",
        width: "100%",
      }}
      alignItems="center"
      container
    >
      {isAdd ? (
        <Stack alignItems="center" direction="row" gap="5px">
          <OutlinedPlusIcon strokeWidth="1.2" size="13" plusStrokeWidth="0.3" />
          <Typography fontSize={12} fontWeight={400} color="#409A65" noWrap>
            Add manufacturer
          </Typography>
        </Stack>
      ) : (
        <Typography fontSize={12} fontWeight={400} color="#1C1C19" noWrap>
          {name}
        </Typography>
      )}
    </Grid>
  );
};

ManufacturerItem.propTypes = {
  item: object,
  isAdd: bool,
  disabled: bool,
  onClick: func,
};
