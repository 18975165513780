import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  number,
  bool,
  func,
  string,
  shape,
  oneOfType,
  array,
  object,
} from "prop-types";
import { useLocation } from "react-router-dom";

import {
  Paper,
  TextField,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { photoUrl } from "../../../../helpers/helpers.js";

import useStyles from "./styles";
import StyledButton from "../../../../components/StyledButton";
import UploadFile from "../../../../components/UploadFile";
import { SquareButton } from "../../../../components/Buttons";
import ArrowBtn from "components/Buttons/ArrowBtn/ArrowBtn.jsx";
import { useWatch } from "react-hook-form";
import {
  MAX_UPLOAD_FILE_RESOLUTION_PRODUCT_MP,
  MAX_UPLOAD_FILE_SIZE_PRODUCT_MB,
} from "utils/constants.js";
import { error } from "utils/notifications.js";
import { createSelector } from "reselect";
import {
  confirmDialogFormChangedSelector,
  editTypeSelector,
} from "redux/selectors/confirmDialogs.js";
import { useSelector } from "react-redux";
import { StyledPopper } from "components/index.js";

const selector = createSelector(
  editTypeSelector,
  confirmDialogFormChangedSelector,
  (editType, formChanged) => ({
    editType,
    formChanged,
  })
);

const InventoryTabItemList = ({
  singleProduct,
  product,
  positionOfList,
  setValue,
  lastItem,
  onChangeOnHand,
  handleSaveInventoryItem,
  manufacturerName,
  control,
  saveDataAfterChangePhotos,
  disabledUploadFile,
}) => {
  const { editType, formChanged } = useSelector(selector);
  const { pathname } = useLocation();
  const formField = useWatch({ control });
  const classes = useStyles();
  const cl = {
    paper: {
      borderWidth: "0 1px 1px 1px",
      borderStyle: "solid",
      borderColor: "#D5D9D9",
      position: "relative",
      width: "100%",
      height: "50px",
      backgroundColor: "white",
      ":hover": {
        backgroundColor: "#F7F7F7",
      },
      display: "flex",
    },
    gridLeft: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
    },
    gridCenter: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: {
      fontSize: "12px",
      color: "#1C1C19",
    },
    textThin: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#707070",
    },
    emptyProduct: {
      objectFit: "cover",
      width: "38px",
      height: "38px",
      margin: "2px 0 0 4px",
      borderRadius: "4px",
      backgroundColor: "white",
    },
    productPhoto: {
      width: "38px",
      height: "38px",
      marginRight: "20px",
      backgroundColor: "white",
    },
  };

  const [progressPhotoUpload, setProgressPhotoUpload] = useState(false);
  const [preparingPhoto, setPreparingPhoto] = useState(false);
  const photoUploading = editType === "product-photo" && formChanged;

  const [onHand, setOnHand] = useState(() =>
    singleProduct ? product?.onHand : product?.inventory?.onHand
  );
  const [onHandCurrent, setOnHandCurrent] = useState(0);
  const [checkSaveOnHand, setCheckSaveOnHand] = useState(false);
  const [allocated, setAllocated] = useState(
    product?.inventory?.allocated || product?.allocated || 0
  );
  const [inventoryInput, setInventoryInput] = useState("");
  const [isInventoryInputActive, setIsInventoryInputActive] = useState(false);
  const [photoHovered, setPhotoHovered] = useState(false);

  const [currentBtn, setCurrentBtn] = useState("Add");

  const tooltipRef = useRef(null);

  const [isCroppedDialogOpen, setIsCroppedDialogOpen] = useState(false);

  const [tooltipData, setTooltipData] = useState({
    open: false,
    text: "",
    modifiers: [],
  });

  useEffect(() => {
    setTooltipData((prev) => ({
      ...prev,
      text: disabledUploadFile?.length ? (
        <>
          <Box component="span" textAlign="start" fontSize={12}>
            Ensure the product name is valid and complete to upload photos
          </Box>
        </>
      ) : (
        <>
          <Box component="span" textAlign="start" fontSize={12}>
            • Use at least 500 px by 500 px <br /> • Use white or neutral
            background
          </Box>
        </>
      ),
      modifiers: [
        {
          name: "offset",
          options: { offset: [disabledUploadFile?.length ? 0 : 8, 10] },
        },
      ],
    }));
  }, [disabledUploadFile]);

  let initOnHand = useRef();
  useEffect(() => {
    initOnHand.current = product?.inventory?.onHand || product?.onHand || 0;
  }, [product?.inventory?.onHand, product?.onHand]);

  const calculateOnHand = () => {
    // if (onHand > 0 && onHandCurrent > 0) {
    if (typeof onHand === "number" && +onHandCurrent > 0) {
      return (
        <>
          <Typography sx={{ ...cl.text, color: "#47A06D" }} noWrap>
            {onHand}
          </Typography>
          <Typography sx={{ ...cl.text, color: "#47A06D" }}>
            &nbsp;→&nbsp;
          </Typography>
          <Typography sx={cl.text} noWrap>
            {parseInt(onHand) + parseInt(onHandCurrent)}
          </Typography>
        </>
      );
    }

    if (+onHandCurrent > 0) {
      return (
        <Typography sx={cl.text} noWrap>
          {+onHandCurrent}
        </Typography>
      );
    }

    return (
      <>
        {currentBtn === "Set" && isInventoryInputActive && (
          <>
            <Typography sx={{ ...cl.text }} noWrap>
              {initOnHand.current}
            </Typography>
            <Typography sx={{ ...cl.text, color: "#47A06D" }}>
              &nbsp;→&nbsp;{" "}
            </Typography>
          </>
        )}
        <Typography sx={{ ...cl.text, color: "#47A06D" }} noWrap>
          {onHand}
        </Typography>
      </>
    );
  };

  useEffect(() => {
    if (currentBtn !== "Set" && product.id && !checkSaveOnHand) {
      setOnHand(product.inventory?.onHand || product?.onHand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.inventory?.onHand, product?.onHand]);

  useEffect(() => {
    if (currentBtn === "Add" && !checkSaveOnHand) {
      setOnHand(initOnHand.current);
      setOnHandCurrent(inventoryInput);
    }
    if (currentBtn === "Set") {
      setOnHand(inventoryInput);
    }
  }, [currentBtn, inventoryInput, checkSaveOnHand]);

  const calculateAllocated = () => {
    return <Typography sx={cl.text}>{allocated}</Typography>;
  };

  const calculateAvailable = useMemo(() => {
    if (+onHand > 0 && allocated > 0 && currentBtn === "Set") {
      return (
        <>
          {isInventoryInputActive && (
            <>
              <Typography sx={{ ...cl.text }}>
                {parseInt(initOnHand.current) - parseInt(allocated)}
              </Typography>
              <Typography sx={{ ...cl.text, color: "#47A06D" }}>
                &nbsp;→&nbsp;{" "}
              </Typography>
            </>
          )}
          <Typography sx={{ ...cl.text, color: "confirmDelete.main" }}>
            {parseInt(onHand) - parseInt(allocated)}
          </Typography>
        </>
      );
    }
    // if (onHand > 0 && allocated > 0) {
    if (typeof onHand === "number" && allocated > 0) {
      return (
        <>
          <Typography sx={{ ...cl.text, color: "confirmDelete.main" }}>
            {parseInt(onHand) - parseInt(allocated)}
          </Typography>
          {+onHandCurrent > 0 && (
            <Typography sx={{ ...cl.text, color: "#47A06D" }}>
              &nbsp;→&nbsp;
            </Typography>
          )}
          {+onHandCurrent > 0 && (
            <Typography sx={cl.text}>
              {parseInt(onHand) + parseInt(onHandCurrent) - parseInt(allocated)}
            </Typography>
          )}
        </>
      );
    }
    if (+onHand > 0 && +onHandCurrent > 0) {
      return (
        <>
          <Typography sx={{ ...cl.text, color: "confirmDelete.main" }}>
            {parseInt(onHand)}
          </Typography>
          <Typography sx={{ ...cl.text, color: "#47A06D" }}>
            &nbsp;→&nbsp;
          </Typography>
          <Typography sx={cl.text}>
            {parseInt(onHand) + parseInt(onHandCurrent)}
          </Typography>
        </>
      );
    }
    if (+onHand > 0 && currentBtn === "Set" && isInventoryInputActive) {
      return (
        <>
          {currentBtn === "Set" && (
            <>
              <Typography sx={{ ...cl.text }}>{initOnHand.current}</Typography>
              <Typography sx={{ ...cl.text, color: "#47A06D" }}>
                &nbsp;→&nbsp;{" "}
              </Typography>
            </>
          )}
          <Typography sx={{ ...cl.text, color: "confirmDelete.main" }}>
            {parseInt(onHand)}
          </Typography>
        </>
      );
    }
    if (+onHand > 0) {
      return (
        <>
          <Typography sx={{ ...cl.text, color: "confirmDelete.main" }}>
            {parseInt(onHand)}
          </Typography>
        </>
      );
    }
    if (+onHandCurrent > 0) {
      return (
        <Typography sx={{ ...cl.text, color: "confirmDelete.main" }}>
          {/* {parseInt(onHand) - parseInt(allocated)} */}
          {parseInt(onHandCurrent) - parseInt(allocated)}
        </Typography>
      );
    }
    if (allocated > 0 && currentBtn === "Set") {
      return (
        <>
          <Typography sx={{ ...cl.text }}>
            {parseInt(initOnHand.current) - parseInt(allocated)}
          </Typography>
          <Typography sx={{ ...cl.text, color: "#47A06D" }}>
            &nbsp;→&nbsp;{" "}
          </Typography>
          <Typography
            sx={{ ...cl.text, color: "confirmDelete.main", fontWeight: 600 }}
          >
            {parseInt(
              onHand && typeof onHand === "number" && !isNaN(onHand)
                ? onHand
                : 0
            )}
          </Typography>
        </>
      );
    }
    return (
      <>
        {currentBtn === "Set" && (
          <>
            <Typography sx={{ ...cl.text }}>{initOnHand.current}</Typography>
            <Typography sx={{ ...cl.text, color: "#47A06D" }}>
              &nbsp;→&nbsp;{" "}
            </Typography>
          </>
        )}
        <Typography sx={{ ...cl.text, color: "confirmDelete.main" }}>
          {onHand - allocated}
        </Typography>
      </>
    );
  }, [
    allocated,
    cl.text,
    currentBtn,
    isInventoryInputActive,
    onHand,
    onHandCurrent,
  ]);

  const handleClickSave = useCallback(() => {
    setCheckSaveOnHand(true);
    const isNewProduct = pathname === "/catalog/new";
    const calcOnHand = parseInt(onHand) + parseInt(onHandCurrent);
    onChangeOnHand(product?.id || product?.customId, calcOnHand);
    setValue("onHand", calcOnHand);
    setOnHand(calcOnHand);
    setOnHandCurrent(0);
    setAllocated((prev) => (prev ? prev : 0));

    setValue(`inventoryWithoutSave[${positionOfList}]`, null);

    setIsInventoryInputActive(false);
    if (!isNewProduct && product.isMultiple && product?.id) {
      handleSaveInventoryItem(
        product?.id,
        {
          onHand: +calcOnHand,
          expected: product?.inventory?.expected,
        },
        "multiple"
      );
      setInventoryInput("");
    }

    if (!isNewProduct && !product.isMultiple && product?.id) {
      handleSaveInventoryItem(
        product?.id,
        {
          onHand: +calcOnHand,
          expected: product?.inventory?.expected,
        },
        "single"
      );
      setInventoryInput("");
    }
    setCurrentBtn("Add");

    initOnHand.current = product?.inventory?.onHand || product?.onHand || 0;
    setOnHandCurrent("");
    setCheckSaveOnHand(false);
    setIsInventoryInputActive(false);
  }, [
    pathname,
    onHand,
    onHandCurrent,
    onChangeOnHand,
    product,
    setValue,
    positionOfList,
    handleSaveInventoryItem,
  ]);

  const setOpacityPhoto = () => {
    if (singleProduct) {
      return photoHovered
        ? 0.8
        : product?.photos?.length && product?.photos?.[0]?.fileName
        ? 0
        : product?.photos?.length
        ? 0
        : 1;
    } else {
      return photoHovered
        ? 0.8
        : product?.photos?.length && product?.photos[0]?.fileName
        ? 0
        : product?.photoUrl?.length && product?.photoUrl[0]
        ? 0
        : 1;
    }
  };

  const setBackGroundPhoto = () => {
    if (singleProduct) {
      return formField.photos?.[0]?.fileName && "#ffffff";
    } else {
      return (
        (product?.photos ? product?.photos[0]?.fileName : product?.photoUrl) &&
        "#ffffff"
      );
    }
  };

  const setPicture = useMemo(() => {
    if (singleProduct) {
      return photoUrl(formField.photos?.[0]?.fileName);
    } else {
      if (product?.photoUrl?.length) return photoUrl(product?.photoUrl[0]);
      if (product?.photos?.length)
        return photoUrl(product?.photos[0]?.fileName);
    }
  }, [formField.photos, product?.photoUrl, product?.photos, singleProduct]);

  const setChildrenName = useMemo(() => {
    const hasProductVariations = !!(product.size || product.color);

    if (hasProductVariations) {
      return `${product?.sku} ${product?.size ? `/ ${product?.size}` : ""} ${
        product?.color ? `/ ${product?.color}` : ""
      }`;
    } else {
      return `${product?.name} / ${product?.sku}`;
    }
  }, [product]);

  const saveOnHandStateBeforeSave = useCallback(() => {
    if (typeof inventoryInput === "number" && isInventoryInputActive) {
      setValue(
        `inventoryWithoutSave[${positionOfList ?? 0}]`,
        {
          value:
            currentBtn === "Add"
              ? +initOnHand.current + +inventoryInput
              : inventoryInput,
        },
        { shouldDirty: true }
      );
    }
  }, [
    currentBtn,
    inventoryInput,
    isInventoryInputActive,
    positionOfList,
    setValue,
  ]);

  useEffect(() => {
    saveOnHandStateBeforeSave();
  }, [inventoryInput, saveOnHandStateBeforeSave]);

  const handleSetPhoto = (data) => {
    if (singleProduct) {
      let tempArr = [...formField?.photos];

      tempArr.splice(0, 1, data);

      saveDataAfterChangePhotos({ successMsg: "Photo added", data: tempArr });

      setValue("photos", tempArr, { shouldDirty: true });
    } else {
      setValue(`variationsFields[${positionOfList ?? 0}].photos`, [data]);

      saveDataAfterChangePhotos({
        successMsg: "Photo added",
        data: formField?.photos,
        variationsData: formField?.variationsFields,
      });
    }
    setProgressPhotoUpload(false);
  };

  return (
    <Paper
      sx={{
        ...cl.paper,
        borderRadius: lastItem ? "0 0 4px 4px" : 0,
      }}
      elevation={0}
      square
      data-testid={
        singleProduct
          ? "inventory.product"
          : `inventory[${positionOfList}].product`
      }
    >
      <Box sx={{ ...cl.gridCenter, width: "8%" }}>
        <StyledPopper
          open={tooltipData.open && !isCroppedDialogOpen}
          anchorEl={tooltipRef.current}
          text={tooltipData.text}
          modifiers={tooltipData.modifiers}
          placement="top"
          transition
          aria-hidden="true"
          style={{ zIndex: 1300 }}
        />
        <Box
          sx={{
            position: "relative",
            pointerEvents:
              (progressPhotoUpload || preparingPhoto || photoUploading) &&
              "none",
            opacity: photoUploading ? 0.5 : 1,
          }}
          ref={tooltipRef}
          onMouseEnter={() => {
            setTooltipData((prev) => ({ ...prev, open: true }));
          }}
          onMouseLeave={() =>
            setTooltipData((prev) => ({ ...prev, open: false }))
          }
          onMouseDown={() =>
            setTooltipData((prev) => ({ ...prev, open: false }))
          }
          onClick={(e) => {
            if (progressPhotoUpload || preparingPhoto || photoUploading) {
              e.preventDefault();
              return;
            }
          }}
        >
          <UploadFile
            label=""
            path="product-photos"
            onChange={handleSetPhoto}
            accept="image/png, image/gif, image/jpeg"
            Wrapper={SquareButton}
            uploadData={{
              type: "PRODUCT",
              onSuccess: () => {},
            }}
            wrapperProps={{
              label: "Upload",
              width: 42,
              height: 40,
              fontSize: "12px",
              iconW: 18.44,
              iconH: 14.89,
              onMouseEnter: () => setPhotoHovered(true),
              onMouseLeave: () => setPhotoHovered(false),
              styles: {
                opacity: setOpacityPhoto(),
                transition: "all .2s ease",
                "&:hover": {
                  backgroundColor: setBackGroundPhoto(),
                },
              },
            }}
            maxUploadFileSizeMb={{
              count: MAX_UPLOAD_FILE_SIZE_PRODUCT_MB,
              callback: (fileName) => {
                error(
                  <>
                    <Typography component="span" fontSize={12}>
                      Photo upload failed
                      <br />
                      {fileName}
                      <br />
                      Exceeds maximum file size of{" "}
                      {MAX_UPLOAD_FILE_SIZE_PRODUCT_MB} MB
                    </Typography>
                    <br />
                  </>
                );
              },
            }}
            maxUploadFileResolutionMp={{
              count: MAX_UPLOAD_FILE_RESOLUTION_PRODUCT_MP,
              callback: (fileName) => {
                error(
                  <>
                    <Typography component="span" fontSize={12}>
                      Photo upload failed
                      <br />
                      {fileName}
                      <br />
                      Exceeds maximum image resolution of{" "}
                      {MAX_UPLOAD_FILE_RESOLUTION_PRODUCT_MP} MP
                    </Typography>
                    <br />
                  </>
                );
              },
            }}
            disabled={!!disabledUploadFile?.length}
            withCropper
            dialogSize="small"
            progressUpload={setProgressPhotoUpload}
            setIsOpen={setIsCroppedDialogOpen}
            {...{ preparingPhoto, setPreparingPhoto }}
            confirmData={
              editType === "product" ? { type: "product-photo" } : null
            }
          />
          {progressPhotoUpload || preparingPhoto ? (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "42px",
                height: "40px",
                borderRadius: "8px",
                objectFit: "contain",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="inherit" size={20} />
            </Box>
          ) : (
            <Box
              component="img"
              src={setPicture}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "42px",
                height: "40px",
                borderRadius: "8px",
                objectFit: "contain",
              }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "29.5%",
        }}
      >
        <Typography sx={cl.text} width="100%" noWrap>
          {setChildrenName}
        </Typography>
        {!!manufacturerName && (
          <Typography sx={{ ...cl.text, fontWeight: 400 }} width="100%" noWrap>
            by {manufacturerName}
          </Typography>
        )}
      </Box>

      {/* ON HAND */}
      <Box
        sx={{
          ...cl.gridLeft,
          width: "9.1%",
          maxWidth: "9.1%",
        }}
      >
        {calculateOnHand()}
      </Box>

      {/* ALLOCATED */}
      <Box sx={{ ...cl.gridLeft, width: "9%" }}>{calculateAllocated()}</Box>

      {/* AVAILABLE */}
      <Box sx={{ ...cl.gridLeft, width: "15.8%" }}>{calculateAvailable}</Box>

      {/* EDIT INVENTORY */}
      <Box sx={{ ...cl.gridLeft, flexGrow: 1 }}>
        <TextField
          sx={{
            maxWidth: "108px",
            width: `${
              inventoryInput.toString().length > 4
                ? inventoryInput.toString().length
                : 5
            }8px`,
            paddingTop: "1px",
          }}
          fullWidth
          placeholder="0"
          InputProps={{
            className: classes.textInputInventory,
            endAdornment: (
              <>
                <ArrowBtn
                  stylesBox={{
                    display: "flex",
                    flexDirection: "column",
                    height: "22px",
                  }}
                  stylesUpBtn={{
                    height: "11px",
                    padding: "0 5px",
                    borderRadius: "0 5px 0 0",
                    background: "#EEEEEE",
                    "&:hover": {
                      backgroundColor: "lightgray",
                      cursor: "pointer",
                    },
                  }}
                  stylesDownBtn={{
                    padding: "0 5px",

                    height: "11px",
                    borderRadius: "0 0 5px 0",

                    background: "#EEEEEE",
                    "&:hover": {
                      backgroundColor: "lightgray",
                      cursor: "pointer",
                    },
                  }}
                  onClickUp={() => {
                    if (+inventoryInput < 99999999) {
                      if (inventoryInput === 0 || inventoryInput === "0")
                        setInventoryInput("");
                      setIsInventoryInputActive(true);
                      const prevValue = inventoryInput || 0;
                      setInventoryInput(prevValue + 1);
                    }
                  }}
                  onClickDown={() => {
                    if (+inventoryInput > 0) {
                      setIsInventoryInputActive(true);
                      setInventoryInput(inventoryInput - 1);
                    }
                  }}
                />
              </>
            ),
          }}
          InputLabelProps={{ className: classes.inputLabel }}
          value={inventoryInput}
          onChange={(e) => {
            const v = e.target.value;
            if (+v <= 99999999) {
              setInventoryInput(typeof +v === "number" && !isNaN(v) ? +v : "");
            }
          }}
          onFocus={() => {
            if (inventoryInput === 0 || inventoryInput === "0")
              setInventoryInput("");
            setIsInventoryInputActive(true);
          }}
          onBlur={() => {
            if (
              !inventoryInput ||
              inventoryInput === 0 ||
              inventoryInput === "0"
            )
              setIsInventoryInputActive(false);
          }}
          data-testid="quantity"
        />
        {isInventoryInputActive && (
          <Box>
            <StyledButton
              disabled={inventoryInput === "" || inventoryInput === 0}
              sx={{
                minWidth: "32px",
                paddingX: "2px",
                marginLeft: "6px",
                height: "24px",
              }}
              color={currentBtn !== "Add" ? "groundLighter" : "primary"}
              variant="outlined"
              label="Add"
              onClick={() => {
                setCurrentBtn("Add");
              }}
            />
            <StyledButton
              disabled={inventoryInput === ""}
              sx={{
                minWidth: "32px",
                paddingX: "2px",
                marginLeft: "6px",
                height: "24px",
              }}
              color={currentBtn !== "Set" ? "groundLighter" : "primary"}
              variant="outlined"
              label="Set"
              onClick={() => {
                if (currentBtn !== "Set") {
                  setCurrentBtn("Set");
                  setOnHand(0);
                  setOnHandCurrent(0);
                  setAllocated((prev) => (prev ? prev : 0));
                }
              }}
            />
            <StyledButton
              sx={{ minWidth: "52px", paddingX: "2px", marginLeft: "16px" }}
              disabled={
                (inventoryInput === 0 && currentBtn === "Add") ||
                inventoryInput === ""
              }
              className={classes.smallerButton}
              variant="contained"
              label="Save"
              onClick={handleClickSave}
            />
          </Box>
        )}
      </Box>
    </Paper>
  );
};
InventoryTabItemList.propTypes = {
  singleProduct: bool,
  product: shape({
    id: string,
    photos: oneOfType([array, string]),
    onHand: number,
    allocated: number,
    inventory: shape({
      allocated: number,
      expected: number,
      onHand: number,
    }),
    isMultiple: bool,
  }),
  positionOfList: number,
  setValue: func,
  lastItem: bool,
  onChangePhoto: func,
  onChangeOnHand: func,
  handleSaveInventoryItem: func,
  manufacturerName: string,
  control: object,
  saveDataAfterChangePhotos: func,
  disabledUploadFile: array,
};
InventoryTabItemList.defaultProps = {
  singleProduct: false,
  lastItem: false,
};

export default InventoryTabItemList;
