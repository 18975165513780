/* eslint-disable react/prop-types */
import * as React from "react";
import { SvgIcon } from "@mui/material";
export const SuccessIcon = ({ width = 24, height = 24, sx = {}, ...props }) => (
  <SvgIcon
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ width, height, ...sx }}
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width={24} height={24} rx={12} fill="#fff" />
      <path
        d="m7 12 3.293 3.293a1 1 0 0 0 1.414 0L18 9"
        stroke="#121212"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);
