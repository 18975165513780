import {
  PermissionCatalogIcon,
  PermissionRepsIcon,
  PermissionSettingsIcon,
} from "components/Icons";
import {
  CustomersIcon,
  DiscountsIcon,
  OrdersIcon,
  ReportsIcon,
  RoutesIcon,
  TasksIcon,
  FormsIcon,
} from "components/Icons/NavbarIcons";

export const SALES_PERMISSIONS = {
  role: "SALES",
  customers: {
    create_edit: true,
    view: true,
    delete: true,
    import: true,
    export: true,
    gallery: true,
    prospects: true,
    territories: true,
    customer_tags: true,
  },
  orders: {
    view: true,
    // drafts: true,
    create_edit: true,
    // third_party: true,
    import: true,
    export: true,
    edit_prices: true,
  },
  catalog: {
    view: true,
    import: true,
    export: true,
    create_edit: true,
    product_tags: true,
    manufacturers: true,
    view_price_lists: true,
    create_edit_price_lists: true,
    delete_price_lists: true,
    create_edit_categories: true,
    delete_categories: true,
  },
  representatives: {
    view: true,
    create_edit: true,
  },
  discounts: {
    view: true,
    create_edit: true,
  },
  tasks: {
    view: true,
    create_edit: true,
    delete: true,
  },
  forms: {
    view: true,
    create_edit: true,
    delete: true,
  },
  routes: {
    view: true,
    assign: true,
    create_edit: true,
  },
  reports: {
    view: true,
  },
  settings: {
    orders: true,
    profile: true,
    payments: true,
    customers: true,
    inventory: true,
    // import_export: true, // temporarily solution - SD-7621
    payment_terms: true,
    representatives: true,
  },
};

export const MERCHANDISER_PERMISSIONS = {
  role: "MERCHANDISER",
  customers: {
    create_edit: true,
    view: true,
    delete: true,
    import: true,
    export: true,
    gallery: true,
    prospects: true,
    territories: true,
    customer_tags: true,
  },
  orders: {
    view: true,
    // import: true,
    export: true,
  },
  catalog: {
    view: true,
    // import: true,
    export: true,
  },
  representatives: {
    view: true,
    create_edit: true,
  },
  discounts: {
    view: true,
  },
  tasks: {
    view: true,
    create_edit: true,
    delete: true,
  },
  forms: {
    view: true,
    create_edit: true,
    delete: true,
  },
  routes: {
    view: true,
    assign: true,
    create_edit: true,
  },
  reports: {
    view: true,
  },
  settings: {
    customers: true,
    // import_export: true, // temporarily solution - SD-7621
    representatives: true,
  },
};

export const SALES_PERMISSIONS_MOB = {
  role: "SALES",
  orders: {
    edit_prices: true,
  },
};

export const defaultValues = {
  email: "",
  name: "",
  // avatar: null,
  phone: "",
  status: "",
  role: "MERCHANDISER",
  active: true,
};

export const ROLES = [
  {
    key: "SALES",
    value: "Sales",
  },
  {
    key: "MERCHANDISER",
    value: "Merchandiser",
  },
  {
    key: "THIRD_PARTY",
    value: "3rd Party",
  },
];

export const PERMISSION_ICONS = {
  customers: {
    icon: CustomersIcon,
    iconProps: {
      width: 16,
      height: 16,
      fill: "#409A65",
    },
  },
  orders: { icon: OrdersIcon, iconProps: { width: 15, height: 18.5 } },
  catalog: {
    icon: PermissionCatalogIcon,
    iconProps: {
      sx: {
        width: 18,
        height: 15,
        "& path": {
          stroke: "transparent !important",
        },
      },
    },
  },
  representatives: {
    icon: PermissionRepsIcon,
    iconProps: { width: 15, height: 15 },
  },
  discounts: { icon: DiscountsIcon, iconProps: { width: 16, height: 16 } },
  tasks: { icon: TasksIcon, iconProps: { width: 15, height: 18.5 } },
  forms: { icon: FormsIcon, iconProps: { width: 15, height: 18.5 } },
  routes: { icon: RoutesIcon, iconProps: { width: 16, height: 16 } },
  reports: { icon: ReportsIcon, iconProps: { width: 16, height: 16 } },
  settings: {
    icon: PermissionSettingsIcon,
    iconProps: {
      sx: {
        width: 16,
        height: 16,
        "& path": {
          stroke: "transparent !important",
        },
      },
    },
  },
};
