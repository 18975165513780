import { func, string, bool, object } from "prop-types";
import { Box, Typography } from "@mui/material";
//import { NewOrderIcon } from "../../../../../../components/Icons";
//import { ThirdPartyItem } from "./ThirdPartyItem";
import { PurchaseOrderIcon } from "components/Icons/Activities";
import { ThirdPartyItemIcon } from "components/Icons/ThirdPartyItemIcon";
import { StoreIcon } from "components/Icons";

export const OrderItem = ({
  onClickOnOrder,
  size = "",
  order = { type: "DIRECT" },
  mainWrapperProps = {},
  isOnlineOrder = false,
}) => {
  const isDirectOrder = order?.type === "DIRECT";
  const isThirdPartyOrder = order?.type === "THIRD_PARTY";

  const isSmallSize = size === "small";

  return (
    <Box
      sx={{
        border: "1px solid #D5D9D9",
        borderRadius: "4px",
        width: isSmallSize ? "40px" : "56px",
        maxWidth: isSmallSize ? "40px" : "56px",
        minWidth: isSmallSize ? "40px" : "56px",
        height: isSmallSize ? "40px" : "56px",
        maxHeight: isSmallSize ? "40px" : "56px",
        minHeight: isSmallSize ? "40px" : "56px",
        display: "flex !important",
        flexDirection: "column",
        cursor: "pointer",
        backgroundColor: "#F6F6F6",
        position: "relative",
        textDecoration: "none",
        justifyContent: "center",
        alignItems: "center",
        py: "10px",
        gap:
          isThirdPartyOrder && isSmallSize
            ? "1px"
            : isThirdPartyOrder || isSmallSize
            ? "2px"
            : "6px",
      }}
      {...mainWrapperProps}
      onClick={onClickOnOrder}
    >
      {isOnlineOrder ? (
        <StoreIcon width={17} height={17} />
      ) : (
        isDirectOrder && (
          <PurchaseOrderIcon
            strokeW={1.5}
            sx={{ width: isSmallSize ? 15 : 19, height: isSmallSize ? 18 : 22 }}
            fill="#5F6267"
          />
        )
      )}

      {isThirdPartyOrder && (
        <ThirdPartyItemIcon
          width={isSmallSize ? 18 : 27}
          height={isSmallSize ? 22 : 28}
        />
      )}

      <Typography
        sx={{
          fontSize: 8,
          lineHeight: "10px",
          fontWeight: 400,
          color: "#5F6267",
        }}
      >
        {isOnlineOrder ? "Online Order" : "Order"}
      </Typography>
    </Box>
  );
};

OrderItem.propTypes = {
  onClickOnOrder: func,
  size: string,
  hasThirdPartyItem: bool,
  isOnlineOrder: bool,
  order: object,
  mainWrapperProps: object,
};
