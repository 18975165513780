export const sx = {
  accordionComponent: {
    m: "0!important",
    "& .MuiAccordionSummary-root": {
      backgroundColor: "transparent",
      borderRadius: "0px",
      p: 0,
      height: "48px !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      backgroundColor: "transparent",
      borderBottom: "0.5px solid #D9D9D9",
    },
    "&.MuiPaper-root.MuiAccordion-root.MuiPaper-root.MuiAccordion-root": {
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "0.5px solid #D9D9D9",
      borderRadius: 0,
    },
  },
  summaryComponent: {
    "& .MuiTypography-root": {
      fontSize: "12px",
      color: "#6A6A6A",
      textTransform: "uppercase",
      fontWeight: 400,
    },
  },
  summaryComponentGrid: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    pl: "12px",
  },
  contentWrapper: {
    boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
    borderRadius: "4px",
  },
  tabWrapper: {
    width: "100%",
    p: "31px 24px",
    maxHeight: "464px",
    overflow: "auto",

    display: "flex",
    flexDirection: "column",
    gap: 4,

    scrollbarWidth: "6px",
    scrollbarColor: "rgba(0, 0, 0, 0.35) rgba(0, 0, 0, 0.1)",

    "&::-webkit-scrollbar": {
      width: "6px",
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "7px",
      backgroundColor: "rgba(0, 0, 0, 0.35)",
    },
  },
  shevronWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "14px",

    "& svg": {
      cursor: "pointer",
      color: "#707070 !important",
    },
  },
  detailsComponent: {
    backgroundColor: "rgba(213, 217, 217, 0.12)",
  },
  childWrapper: {
    width: "100%",
    borderBottom: "0.5px solid rgba(217, 217, 217, 0.25)",
  },
};
