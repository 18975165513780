import { useMemo } from "react";
import { bool, element, number, string } from "prop-types";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { formatLongWords, truncateText } from "helpers/helpers";
import { makeStyles } from "@mui/styles";
import { CountBlock } from "../../../ControlPanel/components";
import { MAX_CHARACTERS_QUESTIONS_TEXT } from "Pages/FormViewPage/FormViewPage.constants";

const useStyles = makeStyles(() => {
  const color = {
    Required: {
      backgroundColor: "#EAEAEA",
      color: "#959595",
    },
    Optional: {
      backgroundColor: "#EAAA6B33",
      color: "#EAAA6B",
    },
    Deactivated: {
      backgroundColor: "#EE6F8133",
      color: "#EE6F81",
    },
  };
  return {
    chip: {
      backgroundColor: ({ type }) => {
        return color?.[type || "Required"]?.backgroundColor;
      },
      color: ({ type }) => color?.[type || "Required"]?.color,
      "&.MuiChip-root": {
        height: "20px",
      },
    },
  };
});

const cl = {
  iconWrapper: {
    width: "26px",
    height: "26px",
    background: "#47A06D",
    borderRadius: "3px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",

    svg: {
      transform: "rotate(0deg)",
      path: { fill: "#ffff" },
    },
  },
};

export const TitleBlock = ({
  icon = "",
  title = "",
  type = "",
  questionType = "",
  isDialog = false,
  questionsCount = 0,
  seeAllAction = false,
  hideBorder = false,
}) => {
  const classes = useStyles({ type });

  const countBlockText = useMemo(() => {
    return questionType === "PHOTO"
      ? ""
      : seeAllAction
      ? "for this question"
      : "for this answer option";
  }, [questionType, seeAllAction]);

  const preparedText = useMemo(() => {
    const formattedLongWords = formatLongWords(title, 20);

    return truncateText(formattedLongWords, MAX_CHARACTERS_QUESTIONS_TEXT);
  }, [title]);

  return (
    <Box
      sx={{
        ...(!isDialog && { pb: "14px" }),
        ...(!["YES_NO", "SINGLE_ANSWER", "MULTIPLE_ANSWER"].includes(
          questionType
        ) &&
          !(isDialog || hideBorder) && {
            borderBottom: "1px solid #D9D9D9",
          }),
      }}
    >
      <Grid container>
        <Grid xs={isDialog ? 0.5 : 0.7} item container alignItems="center">
          <Box
            sx={{
              ...cl.iconWrapper,
              opacity: type === "Deactivated" ? 0.5 : 1,
            }}
          >
            {icon}
          </Box>
        </Grid>

        <Grid xs={isDialog ? 9.65 : 9.8} item container alignItems="center">
          <Typography
            // sx={{
            //   lineHeight: 1.3,
            //   display: "-webkit-box",
            //   overflow: "hidden",
            //   WebkitBoxOrient: "vertical",
            //   WebkitLineClamp: 2,
            //   textOverflow: "ellipsis",
            // }}
            fontSize={18}
            fontWeight={400}
            color="#595959"
          >
            {preparedText}
          </Typography>
        </Grid>

        <Grid
          xs={isDialog ? 1.35 : 1.5}
          item
          container
          justifyContent="flex-end"
        >
          <Chip
            className={classes.chip}
            label={
              <Typography fontSize={10} fontWeight={500}>
                {type}
              </Typography>
            }
            size="small"
          />
        </Grid>

        {isDialog && <Grid xs={0.5} item />}
      </Grid>
      {isDialog && (
        <CountBlock
          name={questionType === "PHOTO" ? "photo" : "response"}
          text={countBlockText}
          count={questionsCount}
          wrapProps={{
            mt: "20px",
            pb: "4px",
            alignItems: "flex-start",
            borderBottom: "1px solid #D9D9D9",
          }}
          nameProps={{
            fontSize: 12,
            fontWeight: 400,
            color: "#70707080",
          }}
        />
      )}
    </Box>
  );
};

TitleBlock.propTypes = {
  icon: element,
  title: string,
  type: string,
  questionType: string,
  isDialog: bool,
  questionsCount: number,
  seeAllAction: bool,
  hideBorder: bool,
};
