import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useDebounce } from "helpers/hooks";

import { getManufacturersAction } from "redux/actions/manufacturers";

import { SCROLL_LIMIT } from "utils/constants";

export const useAssignManufacturer = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [isOpenNewMan, setIsOpenNewManDialog] = useState(false);
  const [customersSearchInput, setCustomersSearchInput] = useState("");
  const customersSearchInputDebounced = useDebounce(customersSearchInput, 500);

  const handleFetchManufacturers = (currentPage) => {
    dispatch(
      getManufacturersAction({
        limit: SCROLL_LIMIT,
        search: customersSearchInputDebounced,
        sort_by_name: "asc",
        page: currentPage ?? page + 1,
      })
    );
    setPage((prev) => currentPage ?? prev + 1);
  };

  useEffect(() => {
    handleFetchManufacturers(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersSearchInputDebounced]);

  return {
    customersSearchInput,
    setCustomersSearchInput,
    handleFetchManufacturers,
    setIsOpenNewManDialog,
    isOpenNewMan,
    page,
  };
};
