import { SvgIcon } from "@mui/material";
import React from "react";

// eslint-disable-next-line react/prop-types
export const FlagIcon = ({ color = "#A5A5A5", ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 10 12"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 10, height: 12 }}
      style={{ fill: "none" }}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.997 1.016c0-.197-.22-.32-.395-.228-.668.35-1.531.647-2.204.659A4.75 4.75 0 0 1 5.076.739 5.1 5.1 0 0 0 2.606 0C.603 0 .12.493.072.55L.01.62v.183a.5.5 0 0 0-.01.1v10.595a.5.5 0 1 0 1 0V7.181a4.8 4.8 0 0 1 1.606-.188 5.3 5.3 0 0 1 1.858.405 8 8 0 0 0 2.933.56c.838-.016 1.753-.313 2.484-.751a.24.24 0 0 0 .116-.21zM7.024 2.204c.652.027 1.593-.115 2.164-.415v4.76c-.48.556-1.738.584-2.445.556a6.8 6.8 0 0 1-2.322-.556 4.5 4.5 0 0 0-1.469-.39c-.633-.069-1.29 0-1.942.224 0 0-.017-3.733-.017-5.272.042-.04.66-.308 2.26-.216a4.26 4.26 0 0 1 1.944.67 4 4 0 0 0 1.827.639"
        fill={color}
      />
    </SvgIcon>
  );
};
