import { useCallback, useState, useMemo, useRef } from "react";
import { object, func, string, bool } from "prop-types";
import { useSelector } from "react-redux";
import { Controller, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";

import {
  Avatar,
  Grid,
  Link,
  MenuItem,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";

import {
  StyledButton,
  UploadFile,
  StyledTextField,
  StyledSelect,
  Loader,
  StyledPopper,
  StyledTooltip,
} from "components/";
import { ActiveIcon, CrossIcon } from "components/Icons";

import {
  getFormattedDate,
  useAdmin,
  photoUrl,
  stringAvatar,
  onPastePhone,
} from "helpers/helpers";
import { ROLES } from "./TabProfile.constants";

import {
  distributorResetRepEmailService,
  distributorResetRepPasswordService,
  logoutRepsByIdService,
} from "services/reps.js";
import { success, error } from "utils/notifications.js";
import { CheckboxComponent } from "./components";
import { TYPES_CONFIRM_DIALOG } from "../useProfileComponent";
import { cl } from "./TabProfileComponent.styles.js";

const TabProfileComponent = ({
  profile = null,
  timeZone = "",
  control = {},
  setValue = () => {},
  currentTab = "",
  handleRemovePhoto = () => {},
  setProgressPhotoUpload = () => {},
  preparingPhoto = false,
  setPreparingPhoto = () => {},
  repPermissions = {},
  handleConfirmDialog = () => {},
  errors = {},
}) => {
  const isAdmin = useAdmin();
  const isEdit = !!profile?.id;

  const repsLoading = useSelector(({ reps }) => reps.loading);
  const repsCurrentTab = useSelector(({ reps }) => reps.currentTab);
  const currentUser = useSelector(({ auth }) => auth.currentUser);

  const [resetPassClicked, setResetPassClicked] = useState(false);
  const [resetEmailClicked, setEmailClicked] = useState(false);

  const formField = useWatch({ control });

  const [isCroppedDialogOpen, setIsCroppedDialogOpen] = useState(false);

  const tooltipRef = useRef(null);

  const [tooltipData, setTooltipData] = useState({
    open: false,
    text: (
      <>
        <Typography sx={cl.styledPopperText}>
          • Use at least 500 px by 500 px <br /> • Use white or neutral
          background
        </Typography>
      </>
    ),
    modifiers: [{ name: "offset", options: { offset: [5, 10] } }],
  });

  const handleUploadChange = useCallback(
    (data) => {
      if (isAdmin) return;
      success(formField?.profilePhoto ? "Photo updated" : "Photo added");
      setValue("profilePhoto", data?.fileName);
    },
    [formField?.profilePhoto, isAdmin, setValue]
  );

  const profilePhoto = useMemo(() => {
    if (profile?.avatar) return profile?.avatar;
    if (formField?.profilePhoto?.fileName)
      return photoUrl(formField?.profilePhoto?.fileName);
    if (typeof formField?.profilePhoto === "string")
      return formField?.profilePhoto;
    return "";
  }, [profile?.avatar, formField?.profilePhoto]);

  const handleLogoutRepsById = (e) => {
    if (isAdmin) return;
    e.preventDefault();
    logoutRepsByIdService(formField?.id)
      .then(() => success("Representative logged out"))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err?.message);
        error("Something went wrong");
      });
  };

  const handleResetPassword = (e) => {
    if (isAdmin) return;
    setResetPassClicked(true);
    e.preventDefault();
    distributorResetRepPasswordService(profile?.id)
      .then(() => success("Reset password email sent"))
      .catch(() => {
        error("Something went wrong");
      });
  };

  const handleResetEmail = (e) => {
    if (isAdmin) return;
    setEmailClicked(true);
    e.preventDefault();
    distributorResetRepEmailService(profile?.id)
      .then(() => success("Confirmation email sent"))
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        if (
          err?.response?.data?.message ===
          "Representative profile already confirmed"
        ) {
          return success("Representative account is already confirmed");
        }

        error(err?.response?.data?.message || "Something went wrong");
      });
  };

  const hasRepsLimitBeenReached = useMemo(() => {
    const allowedUsers =
      currentUser?.distributorSubscription?.allowedUsers || 5;
    const extraAllowedUsers =
      currentUser?.distributorSubscription?.extraUser?.extraAllowedUsers || 0;

    const usedSubscriptions = currentUser?.countRepresentatives || 0;
    return !(usedSubscriptions < allowedUsers + extraAllowedUsers);
  }, [
    currentUser?.countRepresentatives,
    currentUser?.distributorSubscription?.allowedUsers,
    currentUser?.distributorSubscription?.extraUser?.extraAllowedUsers,
  ]);

  const disabledRoleField = useMemo(() => {
    if (hasRepsLimitBeenReached && !isEdit) return true;
    return false;
  }, [hasRepsLimitBeenReached, isEdit]);

  const showNameErrorTooltip = useMemo(() => {
    if (errors?.name?.message === "name must be at most 25 characters") {
      return true;
    }
    return false;
  }, [errors?.name?.message]);

  return (
    <>
      <Loader isLoading={repsLoading} />

      {/* Avatar block */}
      <Grid item>
        <Box sx={cl.avatarBlockWrapper}>
          {profile || formField.profilePhoto ? (
            profilePhoto ? (
              <Avatar sx={cl.avatar}>
                <Box component="img" sx={cl.profilePhoto} src={profilePhoto} />
              </Avatar>
            ) : (
              <Avatar {...stringAvatar(profile.name, cl.avatar)} />
            )
          ) : (
            <Avatar alt="" src="" sx={cl.avatar} />
          )}
          <Box
            sx={{
              pl: "10px",
              pt: (currentTab || repsCurrentTab) === "3rd Party" && "8px",
            }}
          >
            {(currentTab !== "3rd Party" || repsCurrentTab !== "3rd Party") &&
              profile && (
                <Typography sx={cl.joinedOnText}>
                  Joined on{" "}
                  {getFormattedDate(profile.createdAt, timeZone).split(",")[0]}
                </Typography>
              )}
            <StyledPopper
              open={tooltipData.open && !isCroppedDialogOpen}
              anchorEl={tooltipRef.current}
              text={tooltipData.text}
              modifiers={tooltipData.modifiers}
              placement="top"
              transition
              aria-hidden="true"
              style={{ zIndex: 1300 }}
            />
            {isAdmin ? null : (
              <Box
                component="span"
                ref={tooltipRef}
                onMouseEnter={() =>
                  setTooltipData((prev) => ({ ...prev, open: true }))
                }
                onMouseLeave={() =>
                  setTooltipData((prev) => ({ ...prev, open: false }))
                }
                onMouseDown={() =>
                  setTooltipData((prev) => ({ ...prev, open: false }))
                }
              >
                <UploadFile
                  label="Upload new photo"
                  onChange={handleUploadChange}
                  accept="image/png, image/gif, image/jpeg"
                  Wrapper={Button}
                  withCropper
                  dialogSize="small"
                  settingsCropper={{ cropShape: "round" }}
                  uploadData={{
                    type: "PROFILE_REPRESENTATIVE",
                    setGroupContentId: (groupContentId) => {
                      if (groupContentId)
                        setValue("groupContentId", groupContentId);
                    },
                  }}
                  setIsOpen={setIsCroppedDialogOpen}
                  progressUpload={setProgressPhotoUpload}
                  {...{ preparingPhoto, setPreparingPhoto }}
                />
              </Box>
            )}
            {profile?.profilePhoto || formField.profilePhoto ? (
              <>
                {isAdmin ? null : "•"}
                {isAdmin ? null : (
                  <StyledButton
                    sx={cl.removePhotoBtn}
                    label={
                      <Typography sx={cl.removePhotoLabel}>
                        Remove photo
                      </Typography>
                    }
                    onClick={() => {
                      if (isAdmin) return;
                      handleRemovePhoto();
                    }}
                  />
                )}
              </>
            ) : (
              ""
            )}
            <Typography sx={cl.photoHelp}>
              Photos help recognize your employees
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid container>
        <Grid item xs>
          <Box sx={cl.leftBoxWrapper}>
            <Box display="flex" justifyContent="space-between">
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTooltip
                    open={showNameErrorTooltip}
                    placement="top"
                    arrow
                    title="Max 25 symbols allowed"
                    sx={{ zIndex: ({ zIndex: { modal } }) => modal }}
                  >
                    <Box component="span" sx={{ width: "70%" }}>
                      <StyledTextField
                        fullWidth
                        formSx={{ width: "100%" }}
                        size="small"
                        InputProps={{ sx: cl.textInput }}
                        InputLabelProps={{ sx: cl.inputLabel }}
                        label="Full name *"
                        error={error ? error.message : ""}
                        {...field}
                      />
                    </Box>
                  </StyledTooltip>
                )}
                name="name"
                control={control}
              />

              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    formSx={{ maxWidth: "26%" }}
                    size="small"
                    fullWidth
                    InputProps={{ sx: cl.textInput }}
                    InputLabelProps={{ sx: cl.inputLabel }}
                    error={error ? error.message : ""}
                    label="ID"
                    {...field}
                  />
                )}
                name="customId"
                control={control}
              />
            </Box>

            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledSelect
                  disabled={disabledRoleField}
                  data-testid="select-role-option"
                  labelId="select-label-role"
                  label="Role *"
                  labelSx={{ "&.Mui-focused": { paddingLeft: 0 } }}
                  fullWidth
                  leftSidedIcon
                  error={error ? error.message : ""}
                  {...field}
                  onChange={(e) => {
                    const v = e.target.value;
                    const currentRole = formField?.role;

                    if (
                      currentRole === "SALES" &&
                      v === "MERCHANDISER" &&
                      formField?.portalAccess
                    ) {
                      handleConfirmDialog({
                        type: TYPES_CONFIRM_DIALOG.role_change,
                        repName: formField?.name || "current representative",
                        onConfirm: () => field.onChange(e),
                      });
                      return;
                    }

                    if (
                      currentRole === "MERCHANDISER" &&
                      v === "SALES" &&
                      formField?.portalAccess
                    ) {
                      handleConfirmDialog({
                        type: TYPES_CONFIRM_DIALOG.role_change,
                        repName: formField?.name || "current representative",
                        onConfirm: () => field.onChange(e),
                      });
                      return;
                    }

                    field.onChange(e);
                  }}
                >
                  {ROLES.map((role) => {
                    return (
                      <MenuItem
                        key={role.key}
                        value={role.key}
                        disabled={
                          profile
                            ? (role.key === "THIRD_PARTY" &&
                                profile?.role !== "THIRD_PARTY") ||
                              (role.key !== "THIRD_PARTY" &&
                                profile?.role === "THIRD_PARTY")
                            : false
                        }
                      >
                        {role.value}
                      </MenuItem>
                    );
                  })}
                </StyledSelect>
              )}
              name="role"
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <NumberFormat
                  customInput={StyledTextField}
                  size="small"
                  fullWidth
                  InputProps={{ sx: cl.textInput }}
                  InputLabelProps={{ sx: cl.inputLabel }}
                  error={error ? error.message : ""}
                  label={
                    ![formField?.role, profile?.role].includes("THIRD_PARTY")
                      ? "Phone number *"
                      : "Phone number"
                  }
                  format="+# (###) ### ####"
                  mask="_"
                  onClick={() => {
                    if (["", "+"].includes(field?.value)) {
                      setValue("phone", "+1");
                    }
                  }}
                  {...field}
                  autoComplete="new-password"
                  onPaste={(e) => {
                    onPastePhone({
                      e,
                      setValue,
                      currentValue: formField.phone,
                    });
                  }}
                />
              )}
              name="phone"
              control={control}
            />
            <Controller
              render={({ field, fieldState: { error } }) => (
                <StyledTextField
                  sx={{
                    backgroundColor:
                      !!profile?.id && "rgba(212, 212, 212, 0.1)",
                  }}
                  size="small"
                  label="Email *"
                  InputProps={{
                    sx: cl.textInput,
                    endAdornment:
                      !!profile?.id &&
                      (currentTab !== "3rd Party" ||
                        repsCurrentTab !== "3rd Party") &&
                      (profile?.baseUser?.isConfirmed ? (
                        <ActiveIcon />
                      ) : profile?.role === "THIRD_PARTY" ? null : (
                        <StyledTooltip
                          isDark
                          arrow
                          title={
                            <Box textAlign="left">
                              <Typography fontSize={13} color="#FFF">
                                Rep&apos;s email has not been verified yet.
                                <br /> They must verify their email to gain{" "}
                                <br />
                                access.{" "}
                                <span
                                  onClick={(e) => {
                                    if (
                                      repPermissions &&
                                      !repPermissions?.representatives
                                        ?.create_edit
                                    )
                                      return;
                                    handleResetEmail(e);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    fontWeight: 600,
                                    fontSize: "13px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Resend Verification
                                </span>
                              </Typography>
                            </Box>
                          }
                          placement="top"
                          PopperProps={{
                            modifiers: [
                              { name: "offset", options: { offset: [0, -4] } },
                            ],
                          }}
                        >
                          <Box height="24px">
                            <CrossIcon fill="#EB4F4F" opacity="1" />
                          </Box>
                        </StyledTooltip>
                      )),
                  }}
                  InputLabelProps={{ sx: cl.inputLabel }}
                  disabled={!!profile?.id && !(profile?.role === "THIRD_PARTY")}
                  error={error ? error.message : ""}
                  {...field}
                />
              )}
              name="email"
              control={control}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs
          sx={{
            ...cl.leftBoxWrapper,
            justifyContent:
              ![formField?.role, profile?.role].includes("THIRD_PARTY") &&
              "space-evenly",
            pt:
              [formField?.role, profile?.role].includes("THIRD_PARTY") &&
              "11px",
          }}
        >
          <Box sx={cl.statusWrapper}>
            <Box sx={cl.statusBlock}>
              <Box sx={cl.statusTextWrapper}>
                <Typography sx={cl.statusText} component="span">
                  Status
                </Typography>
                <Typography sx={cl.statusSubText} component="span">
                  Current status of the rep
                </Typography>
              </Box>
              <CheckboxComponent
                type="active"
                label="Active"
                disabled={isAdmin || !profile?.id}
                isAdmin={isAdmin}
                control={control}
                checkedColor={
                  !profile?.id || isAdmin
                    ? "rgba(71, 160, 110, 0.5)"
                    : "#47a06d"
                }
              />
            </Box>
          </Box>
          {![formField?.role, profile?.role].includes("THIRD_PARTY") ? (
            <>
              <Box sx={cl.securityWrapper}>
                <Typography sx={cl.statusText} component="span">
                  Security
                </Typography>
                <Box sx={cl.statusBlock}>
                  <Typography sx={cl.statusSubText} component="span">
                    Log out of all sessions
                  </Typography>
                  {isAdmin ? null : (
                    <Link
                      sx={{
                        ...cl.statusSubText,
                        color: profile ? "#707070" : "#BCBCBC",
                        pointerEvents: !profile && "none",
                      }}
                      underline="hover"
                      component="button"
                      onClick={(e) => {
                        if (isAdmin) return;
                        handleLogoutRepsById(e);
                      }}
                      disabled={isAdmin || !profile}
                    >
                      Log out
                      <ArrowForwardIos sx={cl.arrowForwardIos} />
                    </Link>
                  )}
                </Box>
              </Box>

              <Box sx={cl.securityWrapper}>
                <Typography sx={cl.statusText} component="span">
                  Password
                </Typography>
                <Box sx={cl.statusBlock}>
                  <Typography sx={cl.statusSubText} component="span">
                    Send email to reset password
                  </Typography>
                  {isAdmin ? null : (
                    <Link
                      component="button"
                      underline="hover"
                      sx={{
                        ...cl.statusSubText,
                        pointerEvents: !profile && "none",
                        color: profile ? "#707070" : "#BCBCBC",
                      }}
                      onClick={(e) => {
                        if (isAdmin) return;
                        handleResetPassword(e);
                      }}
                      disabled={isAdmin || !profile}
                    >
                      {resetPassClicked ? "Resend" : "Send"}
                      <ArrowForwardIos sx={cl.arrowForwardIos} />
                    </Link>
                  )}
                </Box>
              </Box>
              {!profile?.baseUser?.isConfirmed && (
                <Box sx={cl.securityWrapper}>
                  <Typography sx={cl.statusText} component="span">
                    Email
                  </Typography>
                  <Box sx={cl.statusBlock}>
                    <Typography sx={cl.statusSubText} component="span">
                      Resend account confirmation email
                    </Typography>
                    {isAdmin ? null : (
                      <Link
                        component="button"
                        underline="hover"
                        sx={{
                          ...cl.statusSubText,
                          pointerEvents: !profile && "none",
                          color: profile ? "#707070" : "#BCBCBC",
                        }}
                        onClick={(e) => {
                          if (isAdmin) return;
                          handleResetEmail(e);
                        }}
                        disabled={isAdmin || !profile}
                      >
                        {resetEmailClicked ? "Resend" : "Send"}
                        <ArrowForwardIos sx={cl.arrowForwardIos} />
                      </Link>
                    )}
                  </Box>
                </Box>
              )}
            </>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </>
  );
};

TabProfileComponent.propTypes = {
  profile: object,
  timeZone: string,
  control: object,
  setValue: func,
  handleSubmit: func,
  currentTab: string,
  handleRemovePhoto: func,
  setProgressPhotoUpload: func,
  preparingPhoto: bool,
  setPreparingPhoto: func,
  repPermissions: object,
  handleConfirmDialog: func,
  errors: object,
};

export default TabProfileComponent;
