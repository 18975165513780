import { shape, string, number } from "prop-types";
import { Box } from "@mui/material";
import { CardPlanItem } from "./CardPlanItem";
import { TextPlanItem } from "./TextPlanItem";
import { useSelector } from "react-redux";

export const ConfirmSelectionPlanBox = ({ state, timeZone }) => {
  const currentPlan = state?.current_plan;
  const chosenPlan = state?.chosen_plan;
  const extraUsers = state?.extra_user_count ?? 0;
  const pricePerExtraUser = useSelector(
    ({ auth }) => auth?.currentUser?.distributorSubscription?.pricePerExtraUser
  );

  const setName = (type) => {
    if (extraUsers > 0) {
      return currentPlan?.subscription?.name;
    }

    if (extraUsers === 0) {
      if (type === "Current plan") return currentPlan?.subscription?.name;
      if (type === "New plan") return chosenPlan?.name;
    }
  };

  const setAmount = (type) => {
    if (type === "Current plan") return currentPlan?.subscription?.price;
    if (type === "New plan")
      return extraUsers > 0
        ? (currentPlan?.price || currentPlan?.subscription?.price) +
            extraUsers * pricePerExtraUser
        : chosenPlan?.price;
  };

  return (
    <Box mb={3}>
      <Box
        display="flex"
        flexDirection="column"
        px={3}
        border="0.5px solid #CCCCCC"
        borderRadius="4px"
      >
        <CardPlanItem
          type="Current plan"
          {...{ state }}
          name={setName("Current plan")}
          amount={setAmount("Current plan")}
        />
        <CardPlanItem
          type="New plan"
          {...{ state }}
          name={setName("New plan")}
          amount={setAmount("New plan")}
        />
      </Box>
      <TextPlanItem
        date={currentPlan?.currentPeriodEnd}
        amount={setAmount("New plan")}
        {...{ timeZone }}
      />
    </Box>
  );
};

ConfirmSelectionPlanBox.propTypes = {
  state: shape({
    current_plan: shape({
      subscription: shape({
        name: string,
        price: number,
      }),
    }),
    chosen_plan: shape({
      name: string,
      price: number,
    }),
    extra_user_count: number,
  }),
  timeZone: string,
};
ConfirmSelectionPlanBox.defaultProps = {
  state: {
    current_plan: {
      subscription: {
        name: "",
        price: 0,
      },
    },
    chosen_plan: {
      name: "",
      price: 0,
    },
    extra_user_count: number,
  },
  timeZone: "",
};
