import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { array, bool, func, number, object, string } from "prop-types";
import { Box, Chip, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  customerGetFiltersAction,
  customerGetGroupFilter,
  customerGetSwitchFieldActions,
  customersGetParamsAction,
  customersSetParams,
  setCustomersStatusFilter,
  setSearchCustomersAction,
  setSearchGroupsAction,
  setShowInactiveCustomers,
} from "redux/actions/customers";
import {
  customersDefaultFiltersSelector,
  customersDefaultSwitchSelector,
  customersExistSelector,
  customersGetParamsSelector,
  customersGroupFilterSelector,
  customersGroupSwitchSelector,
  customersLoadingSelector,
  customersShowInactiveSelector,
  customersStatusFilterSelector,
  searchCustomersSelector,
  searchGroupsSelector,
} from "redux/selectors/customers";
import CustomerTabSwitcherComponent from "../../../../CustomerTabSwitcherComponent";
import CloseIcon from "@mui/icons-material/Close";
import {
  FILTER_DATE_VALUES_TASKS,
  getSortList,
  iconSort,
  PREFIXES,
} from "./NewCustomerFilter.constants";
import useStyles, { FilterSelect } from "./styles";
import {
  ActiveTabs,
  CustomFilterMenu,
  FilterMenu,
  FilterSearchTextField,
  FilterViewSaveDialog,
  ProductsPurchased,
  StyledButton,
} from "components";

import {
  CUSTOM_FILTERS_TYPES,
  CUSTOMER_STATUS_FILTERS,
  FETCH_LIMITS,
  NAME_VIEW_DIALOGS,
  UNCATEGORIZED_TERRITORY,
} from "utils/constants";
import { territoryListSelector } from "redux/selectors/territory";
import {
  existSortedGroupDataSelector,
  flatCountSelector,
  flatSelector,
  groupsGetExistDataSelector,
  groupsGetParamsSelector,
  groupsSortedLoadingSelector,
  parentCustomersLoadingSelector,
} from "redux/selectors/parentCustomers";
import {
  groupsGetParams as groupsParamsAction,
  groupsGetParamsAction,
} from "redux/actions/parentCustomers";
import { normalizeSnakeCaseString, resetScrollHeight } from "helpers/helpers";
import { useDebounce } from "helpers/hooks";

import { useLocation } from "react-router-dom";
import { ColumnsSettingComponent } from "../ColumnsSettingComponent/ColumnsSettingComponent";
import { getRepsService } from "services/reps";
import { isArray, isEmpty, isEqual, snakeCase } from "lodash";
import { paytermsListSelector } from "redux/selectors/payterms";
import { currentUserSelector } from "redux/selectors/auth";
import { getParentCustomersService } from "services/customers";
import { error } from "utils/notifications";
import { FilterIcon } from "components/Icons";
import { organizeIssuesFilter } from "Pages/CustomersPage/components/CustomersTab/helpers";
import { PurchasedProductsList } from "./components/PurchasedProductsFilter/PurchasedProductsList";
import { useProducts } from "../../../../../../../../helpers/useProducts";
import { setSelectedFilterIdAction } from "redux/actions/savedFilters";
import {
  checkForDefaultFilter,
  handleSetSwitchesForEdit,
} from "helpers/filters";
import { useCustomFilterCreate } from "helpers/useCustomFilterCreate";
import { initialCustomersState } from "redux/reducers/customers";

const selector = createSelector(
  customersShowInactiveSelector,
  flatSelector,
  territoryListSelector,
  customersGetParamsSelector,
  groupsGetParamsSelector,
  flatCountSelector,
  customersDefaultFiltersSelector,
  customersDefaultSwitchSelector,
  customersGroupSwitchSelector,
  customersGroupFilterSelector,
  paytermsListSelector,
  currentUserSelector,
  customersStatusFilterSelector,
  searchCustomersSelector,
  searchGroupsSelector,
  customersLoadingSelector,
  customersExistSelector,
  parentCustomersLoadingSelector,
  groupsGetExistDataSelector,
  groupsSortedLoadingSelector,
  existSortedGroupDataSelector,
  (
    showInactive,
    groupsList,
    territoriesList,
    customersGetParams,
    groupsGetParams,
    groupsCount,
    customersDefaultFilters,
    customerDefaultSwitch,
    customersSwitchGroup,
    customersFiltersGroup,
    paytermsList,
    currentUser,
    customersStatus,
    searchCustomers,
    searchGroups,
    customersLoading,
    customersExist,
    parentLoading,
    groupsGetExistData,
    groupsSortedLoading,
    existSortedGroupData
  ) => ({
    showInactive,
    groupsList,
    territoriesList,
    customersGetParams,
    groupsGetParams,
    groupsCount,
    customersDefaultFilters,
    customerDefaultSwitch,
    customersSwitchGroup,
    customersFiltersGroup,
    paytermsList,
    currentUser,
    customersStatus,
    searchCustomers,
    searchGroups,
    customersLoading,
    customersExist,
    parentLoading,
    groupsGetExistData,
    groupsSortedLoading,
    existSortedGroupData,
  })
);

const NewCustomersFilter = ({
  currentTab,
  handleChangeCurrentTab,
  isGroups,
  handleCancelSelection,
  filterFields,
  setFilterFields,
  setFilterSearch,
  switches,
  setSwitches,
  tagsList,
  tagsCount,
  handleFetchTags,
  tagsPage,
  setTagsParams,
  searcheble,
}) => {
  const isFlatOrSortedTab = useMemo(
    () => ["flat", "sorted"].includes(currentTab),
    [currentTab]
  );

  const isSortedTab = useMemo(() => currentTab === "sorted", [currentTab]);

  const classes = useStyles();
  const {
    showInactive,
    territoriesList,
    customersGetParams,
    customersDefaultFilters,
    customerDefaultSwitch,
    customersSwitchGroup,
    customersFiltersGroup,
    currentUser,
    groupsGetParams,
    customersStatus,
    searchCustomers: customersSearchInput,
    searchGroups: groupsSearchInput,
    customersLoading,
    customersExist,
    parentLoading,
    groupsGetExistData,
    groupsSortedLoading,
    existSortedGroupData,
  } = useSelector(selector);

  const [params, setParams] = useState({
    limit: FETCH_LIMITS.FILTER_CUSTOMERS,
    page: 1,
    include_uncategorized: true,
    with_candidates: false,
  });
  const [parentsGroupList, setParentsGroupList] = useState({
    list: [],
    count: 0,
    loading: false,
  });

  const [isFilterChanged, setIsFilterChanged] = useState(false);

  const haveCustomersMissingInfo =
    (currentUser?.countCustomersWithMissingInfo ?? 0) > 0;

  const dispatch = useDispatch();
  const filterAnchor = useRef();
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);

  // const [customersSearchInput, setCustomersSearchInput] = useState("");
  // const [groupsSearchInput, setGroupsSearchInput] = useState("");

  const { state } = useLocation();

  const [repsList, setRepsList] = useState({
    sales: [],
    merchendiser: [],
    thirdParty: [],
  });
  const customersSearchInputDebounced = useDebounce(customersSearchInput, 500);
  const groupsSearchInputDebounced = useDebounce(groupsSearchInput, 500);

  const handleShowInactive = (e, newVal) => {
    dispatch(setShowInactiveCustomers(newVal));
    if (groupsGetParams?.page !== 1)
      dispatch(groupsParamsAction({ ...groupsGetParams, page: 1 }));
  };

  const handleChangeCustomerStatusFilter = (e) => {
    dispatch(setCustomersStatusFilter(e.target.value));
  };

  const filterChipKeys = useMemo(
    () =>
      Object.keys(filterFields).filter((key) => {
        if (isArray(filterFields[key]) && isEmpty(filterFields[key])) return;

        return filterFields[key];
      }),
    [filterFields]
  );

  const handleApplyFilter = (newSwitches, newFields) => {
    if (currentTab === "map" && !!newFields?.issues?.length)
      handleChangeCurrentTab("flat");

    setFilterMenuOpen(false);
    setSwitches([...newSwitches]);
    setFilterFields({ ...newFields });
  };

  const handleDeleteFilter = (key) => {
    const newState = switches;
    const index = switches.findIndex((s) => s.value === key);
    if (index > -1) {
      const insert = {
        ...switches[index],
        checked: false,
      };
      newState.splice(index, 1, insert);
      dispatch(setSelectedFilterIdAction(null));
      setSwitches([...newState]);
      setFilterFields((prev) => {
        return {
          ...prev,
          [key]: isArray(filterFields[key]) ? [] : "",
        };
      });
    }
  };

  const [groupState, setGroupState] = useState({
    search: "",
    searcheble: false,
  });

  useEffect(() => {
    setParams((prev) => ({ ...prev, search: groupState.search }));
  }, [groupState.search, setParams]);

  const handleFetchGroups = useCallback(
    async (page) => {
      const currentPage = page || 1;

      try {
        const res = await getParentCustomersService({
          ...params,
          page: currentPage,
          limit: FETCH_LIMITS.PARENT_CUSTOMERS,
          search: groupState.search,
          // sort_display_name: "asc", //! temp
        });
        const { rows, count } = res || {};

        const preparedList = rows.map((item) => ({
          ...item,
          name: item?.displayName || item?.name,
        }));

        setParentsGroupList((prev) => ({
          ...prev,
          list:
            currentPage > 1 ? [...prev.list, ...preparedList] : preparedList,
          count,
        }));

        setParams((prev) => ({ ...prev, page: currentPage }));

        setGroupState((prev) => ({
          ...prev,
          searcheble: !prev.searcheble ? count >= 10 : prev.searcheble,
        }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setParentsGroupList((prev) => ({ ...prev, loading: false }));
      }
    },
    [params, groupState.search]
  );

  useEffect(() => {
    handleFetchGroups(1);
    // eslint-disable-next-line
  }, [groupState.search]);

  const [repsState, setRepsState] = useState({ search: "", searcheble: false });

  useEffect(() => {
    const controllerSales = new AbortController();
    const controllerMerch = new AbortController();
    const controllerThirdParty = new AbortController();

    Promise.all([
      getRepsService(
        { role: "sales", search: repsState.search },
        controllerSales.signal
      ),
      getRepsService(
        { role: "merchandiser", search: repsState.search },
        controllerMerch.signal
      ),
      getRepsService(
        { role: "third_party", search: repsState.search },
        controllerThirdParty.signal
      ),
    ])
      .then((res) => {
        setRepsList({
          sales: res[0].rows,
          merchendiser: res[1].rows,
          thirdParty: res[2].rows,
        });
        setRepsState((prev) => ({
          ...prev,
          searcheble: !prev.searcheble
            ? res[0].count + res[1].count + res[2].count >= 10
            : prev.searcheble,
        }));
      })
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err.message));

    return () => {
      controllerSales.abort();
      controllerMerch.abort();
      controllerThirdParty.abort();
    };
  }, [repsState.search]);

  const {
    handleApplyPurchasedProducts,
    selectedProducts,
    handleRemoveProduct,
    handleProductIds,
    isOpenedPurchasedPopup,
    setOpenPurchasedPopup,
  } = useProducts({ switches, filterFields });

  const [territoriesState, setTerritoriesState] = useState({ search: "" });
  let filteredTerritories = [
    { id: UNCATEGORIZED_TERRITORY.id, name: UNCATEGORIZED_TERRITORY.name },
    ...territoriesList,
  ].filter((territory) =>
    territory.name.toLowerCase().includes(territoriesState.search.toLowerCase())
  );

  const selectMatchData = useMemo(() => {
    return [
      {
        id: "representative_id",
        childrenList: [
          { id: "no", name: "No rep assigned" },
          repsList?.sales?.length ? { type: "group", name: "Sales" } : null,
          ...repsList?.sales,
          repsList?.merchendiser?.length
            ? { type: "group", name: "Merchandisers" }
            : null,
          ...repsList?.merchendiser,
          repsList?.thirdParty?.length
            ? { type: "group", name: "Third Party" }
            : null,
          ...repsList?.thirdParty,
        ].filter((value) => value !== null),
        label: "",
        searcheble: repsState.searcheble,
        setParams: setRepsState,
      },
      {
        id: "last_visit",
        isVisit: true,
        textFieldProps: {
          inputSx: {
            pr: 0,
          },
          endIcon: (
            <Box
              backgroundColor="#F7F7F7"
              height="32px"
              display="flex"
              alignItems="center"
              px="9px"
              borderLeft="0.5px solid #D5D9D9"
            >
              <Typography fontSize="12px">days</Typography>
            </Box>
          ),
        },
        moreLessFirst: true,
        valuesForTextfield: ["more_than", "less_than"],
        label: "",
        notched: false,
        labelProps: { shrink: false },
      },
      {
        id: "territory_id",
        childrenList: filteredTerritories,
        label: "",
        searcheble: territoriesList.length >= 10,
        setParams: setTerritoriesState,
        textFieldProps: {
          emptyLabel: "No territories found",
        },
      },
      {
        id: "group_id",
        childrenList: parentsGroupList?.list,
        handleFetch: () => handleFetchGroups(params?.page + 1),
        setParams: setGroupState,
        dataCount: parentsGroupList?.count,
        dataLength: parentsGroupList?.list.length,
        label: "",
        searcheble: groupState.searcheble,
      },
      {
        id: "tag_id",
        childrenList: tagsList.map(({ tag, ...item }) => ({
          ...item,
          name: tag,
        })),
        handleFetch: () => handleFetchTags(tagsPage + 1),
        setParams: setTagsParams,
        dataCount: tagsCount,
        dataLength: tagsList?.length,
        label: "",
        searcheble: searcheble,
      },
      {
        id: "sort_by",
        childrenList: getSortList(isGroups),
        label: "",
      },
      {
        id: "last_order",
        moreLessFirst: true,
        isVisit: true,
        isOrder: true,
        childrenlist: [
          {
            iconId: "highestOrderIcon",
            name: "More Than",
            id: "more_than",
          },
          {
            iconId: "lovestOrderIcon",
            name: "Less Than",
            id: "less_than",
          },
        ],

        textFieldProps: {
          inputSx: {
            pr: 0,
          },
          endIcon: (
            <Box
              backgroundColor="#F7F7F7"
              height="32px"
              display="flex"
              alignItems="center"
              px="9px"
              borderLeft="0.5px solid #D5D9D9"
            >
              <Typography fontSize="12px">days</Typography>
            </Box>
          ),
        },
        valuesForTextfield: ["more_than", "less_than"],
        label: "",
        notched: false,
        labelProps: { shrink: false },
      },
      {
        id: "tasks_due",
        textFieldProps: {
          inputSx: { pr: 0 },
          endIcon: (
            <Box
              backgroundColor="#F7F7F7"
              height="32px"
              display="flex"
              alignItems="center"
              px="9px"
              borderLeft="0.5px solid #D5D9D9"
            >
              <Typography fontSize="12px">days</Typography>
            </Box>
          ),
        },
        label: "",
        notched: false,
        labelProps: { shrink: false },
        datesList: FILTER_DATE_VALUES_TASKS,
      },
      { id: "created_at", label: "Select Date" },
      {
        id: "purchasedProducts",
        label: "Products purchased",
        elementProps: {
          handleOpen: () => setOpenPurchasedPopup(true),
          productsList: selectedProducts,
          handleRemoveProduct,
          type: "purchased",
        },
        Element: PurchasedProductsList,
      },
    ];
  }, [
    repsList?.sales,
    repsList?.merchendiser,
    repsList?.thirdParty,
    repsState?.searcheble,
    filteredTerritories,
    territoriesList?.length,
    parentsGroupList?.list,
    parentsGroupList?.count,
    groupState?.searcheble,
    tagsList,
    setTagsParams,
    tagsCount,
    searcheble,
    isGroups,
    selectedProducts,
    handleRemoveProduct,
    handleFetchGroups,
    params?.page,
    handleFetchTags,
    tagsPage,
    setOpenPurchasedPopup,
  ]);

  const requestRef = useRef(null);
  const paramsRef = useRef(null);

  //const issuesParams = useMemo(
  //  () => organizeIssuesFilter(filterFields?.issues),
  //  [filterFields.issues]
  //);

  const handleFetchCustomers = () => {
    if (!isFilterChanged) return;

    const {
      sort_by,
      representative_id,
      territory_id,
      group_id,
      tag_id,
      last_visit,
      last_order,
      issues,
      tasks_due,
      has_assigned_routes,
      created_at,
      purchasedProducts,
    } = filterFields || {};

    const issuesParams = organizeIssuesFilter(issues);
    const sort = sort_by ? sort_by.value.split(" ") : null;
    let removeSort = null;
    if (sort?.[0] === "sort_name") removeSort = "sort_orders";
    else removeSort = "sort_name";
    if (!isGroups) {
      const setHasAssignedRoutesValue = (value) => {
        if (value === "ASSIGNED") return true;
        if (value === "NOT_ASSIGNED") return false;
        return undefined;
      };

      setFilterSearch(customersSearchInputDebounced);

      const { start_date, end_date } = created_at?.value || {};

      const preparedData = {
        created_at: start_date ? undefined : snakeCase(created_at?.value),
        created_at_start_date: start_date,
        created_at_end_date: end_date,
        representative_id: representative_id?.value,
        territory_id:
          territory_id?.value === UNCATEGORIZED_TERRITORY.id
            ? "null"
            : territory_id?.value,
        parent_customer_id: group_id?.value,
        tag_ids: tag_id?.length
          ? JSON.stringify(tag_id?.map(({ value }) => value))
          : [],
        ordered_product_ids: purchasedProducts?.value?.length
          ? JSON.stringify(handleProductIds(purchasedProducts?.value))
          : "",
        [removeSort]: null,
        [sort?.[0]]: sort?.[1],
        search: customersSearchInputDebounced,
        last_visit: last_visit?.value?.start_date
          ? undefined
          : snakeCase(last_visit?.value),
        last_visit_than: ["more_than", "less_than"].includes(last_visit?.value)
          ? last_visit.days || 0
          : undefined,
        last_visit_start_date: last_visit?.value?.start_date,
        last_visit_end_date: last_visit?.value?.end_date,
        last_order: last_order?.value?.start_date
          ? undefined
          : snakeCase(last_order?.value),
        last_order_than: ["more_than", "less_than"].includes(last_order?.value)
          ? last_order.days || 0
          : undefined,
        last_order_start_date: last_order?.value?.start_date,
        order_direct: !filterFields?.order_direct
          ? null
          : JSON.stringify([filterFields?.order_direct.value]),
        last_order_end_date: last_order?.value?.end_date,
        ...issuesParams,
        with_missing_info: issuesParams.with_missing_info ?? undefined,
        with_duplicated_info: issuesParams.with_duplicated_info ?? undefined,
        with_expired_document: issuesParams.with_expired_document ?? undefined,
        ...(state?.limit && { limit: state?.limit }),
        tasks_due: tasks_due?.value?.start_date
          ? undefined
          : snakeCase(tasks_due?.value),
        tasks_due_start_date: tasks_due?.value?.start_date,
        tasks_due_end_date: tasks_due?.value?.end_date,
        has_assigned_routes: setHasAssignedRoutesValue(has_assigned_routes),
        status: `["${customersStatus ?? CUSTOMER_STATUS_FILTERS.active}"]`,
      };

      if (preparedData.with_duplicated_info) {
        preparedData.sort_shipping_addresses = "asc";
      } else {
        preparedData.sort_shipping_addresses = undefined;
      }

      if (preparedData.sort_newest) {
        delete customersGetParams.sort_visit;
      } else {
        preparedData.sort_visit = "desc";
      }

      if (sort?.length) {
        preparedData.sort_shipping_addresses = undefined;
      }

      if (!preparedData.sort) {
        delete customersGetParams.sort_newest;
        delete customersGetParams.sort_orders;
      }
      resetScrollHeight("sorted-scroll-table");

      const preparedParams = { ...customersGetParams, ...preparedData };

      if (isEqual(paramsRef?.current, preparedParams)) return;

      paramsRef.current = preparedParams;

      if (currentTab === "map")
        return dispatch(customersSetParams(preparedParams));

      return dispatch(customersGetParamsAction(preparedParams, requestRef));
    }
    const preparedParentCustomersQuery = {
      ...groupsGetParams,
      sort_orders: sort?.[0] === "sort_orders" ? sort?.[1] : null,
      sort_name: sort?.[0] === "sort_name" ? sort?.[1] : null,
      search: groupsSearchInputDebounced,
      status: `["${
        showInactive
          ? CUSTOMER_STATUS_FILTERS.inactive
          : CUSTOMER_STATUS_FILTERS.active
      }"]`,
    };

    dispatch(groupsGetParamsAction(preparedParentCustomersQuery));
  };

  useEffect(() => {
    handleCancelSelection();
    if (state?.filters) return;

    handleFetchCustomers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customersSearchInputDebounced,
    groupsSearchInputDebounced,
    dispatch,
    filterFields,
    //isGroups,
    isFilterChanged,
    showInactive,
    customersStatus,
    organizeIssuesFilter,
  ]);

  // useEffect(() => {
  //   if (customersGetParams.sort_visit)
  //     setFilterFields((prev) => ({ ...prev, sort_by: null }));
  // }, [customersGetParams.sort_visit, setFilterFields]);

  useEffect(() => {
    if (isGroups) {
      setSwitches(customersSwitchGroup);
      setFilterFields(customersFiltersGroup);
    } else {
      setSwitches(customerDefaultSwitch);
      setFilterFields(customersDefaultFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGroups]);

  useEffect(() => {
    if (state?.customersFilters) return;

    setIsFilterChanged(true);
    if (isFilterChanged || isGroups) return;

    checkForDefaultFilter({
      type: CUSTOM_FILTERS_TYPES.CUSTOMERS,
      list: currentUser.customFilters,
      setFilters: setFilterFields,
      setSwitches,
      switchesList: switches,
      dispatchFunc: (id) => dispatch(setSelectedFilterIdAction(id)),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.customFilters]);

  useEffect(() => {
    const { missing_info, ...dataWithoutMissingInfo } = filterFields || {};

    const preparedFilters = haveCustomersMissingInfo
      ? filterFields
      : dataWithoutMissingInfo;

    if (isGroups)
      return dispatch(customerGetGroupFilter(customersFiltersGroup));

    return dispatch(customerGetFiltersAction(preparedFilters));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterFields, customersFiltersGroup, isGroups]);

  useEffect(() => {
    const dataWithoutMissingInfo = switches.filter(
      (item) => item?.value !== "missing_info"
    );

    const preparedSwitches = haveCustomersMissingInfo
      ? switches
      : dataWithoutMissingInfo;

    if (!isGroups)
      return dispatch(customerGetSwitchFieldActions(preparedSwitches));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterFields, isGroups, switches, customersSwitchGroup]);

  const onCustomFilterApply = useCallback(
    (filters) => {
      setFilterFields(filters);
      setSwitches(
        handleSetSwitchesForEdit(filters, initialCustomersState.customersSwitch)
      );
    },
    [setFilterFields, setSwitches]
  );

  useEffect(() => {
    if (state?.customersFilters) {
      setFilterFields({
        ...filterFields,
        ...state?.customersFilters,
      });

      const stateSwitchersNames = state?.customersSwitchers.map(
        (item) => item?.value
      );

      const filteredSwitchers = switches.filter(
        (item) => !stateSwitchersNames.includes(item?.value)
      );

      setSwitches([
        ...(state?.customersSwitchers || []),
        ...(filteredSwitchers || []),
      ]);
      window.history.replaceState({}, document.title);
      setIsFilterChanged(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const {
    onCloseFilterDialog,
    onFilterViewSave,
    onOpenFilterSaveDialog,
    isOpenedMenu,
  } = useCustomFilterCreate({
    createFilterCallback: onCustomFilterApply,
    menuCallback: () => setFilterMenuOpen(false),
    viewTypeKey: NAME_VIEW_DIALOGS.CUSTOMERS,
    filterType: CUSTOM_FILTERS_TYPES.CUSTOMERS,
  });

  const getChipLabel = (key) => {
    const { days, name, value, iconId, label } = filterFields[key] || {};
    const icon = iconSort[iconId];
    const isArr = isArray(filterFields[key]);

    const prefix = PREFIXES[value];
    return (
      <Box display="flex" alignItems="center" gap="6px">
        <Typography
          sx={{ fontSize: "13px", fontWeight: 500 }}
          color="groundLighter.main"
        >
          {isArr ? (
            <>
              {normalizeSnakeCaseString(filterFields[key][0]?.label || key)}:{" "}
              <span style={{ color: "#5F6368" }}>
                {filterFields[key]
                  .map((item) => normalizeSnakeCaseString(item.name ?? item))
                  .join(", ")}
              </span>
            </>
          ) : (
            <>
              {normalizeSnakeCaseString(label || key)}:{" "}
              {!icon && (
                <span style={{ color: "#5F6368", marginTop: !!icon && "5px" }}>
                  {prefix
                    ? `${prefix} ${days || 0} days`
                    : normalizeSnakeCaseString(name || filterFields[key])}
                </span>
              )}
            </>
          )}
        </Typography>
        {icon}
      </Box>
    );
  };

  // show the clear button only if the input field has a value and does not have a chip
  const handleClearValue = () => {
    isGroups
      ? dispatch(setSearchGroupsAction(""))
      : dispatch(setSearchCustomersAction(""));
  };

  const currentTabLoading = useMemo(() => {
    return isGroups
      ? parentLoading && groupsGetExistData
      : isSortedTab
      ? groupsSortedLoading && existSortedGroupData
      : customersLoading && customersExist;
  }, [
    isGroups,
    parentLoading,
    groupsGetExistData,
    isSortedTab,
    groupsSortedLoading,
    existSortedGroupData,
    customersLoading,
    customersExist,
  ]);

  return (
    <Box>
      {isOpenedMenu && (
        <FilterViewSaveDialog
          open={isOpenedMenu}
          onClose={onCloseFilterDialog}
          handleSave={onFilterViewSave}
        />
      )}
      <ProductsPurchased
        isOpen={isOpenedPurchasedPopup}
        checkedList={selectedProducts}
        handleClose={() => setOpenPurchasedPopup(false)}
        handleApplyPurchasedProducts={handleApplyPurchasedProducts}
      />
      <Box className={classes.filterWrapper} pr={!isFlatOrSortedTab && "17px"}>
        {isGroups ? (
          <ActiveTabs value={showInactive} onChange={handleShowInactive} />
        ) : (
          <FilterSelect
            value={customersStatus}
            onChange={handleChangeCustomerStatusFilter}
            formSx={{ width: "100%", maxWidth: "fit-content" }}
            IconComponent={ArrowDropDownIcon}
            adminIsAllowed
          >
            <MenuItem value={CUSTOMER_STATUS_FILTERS.active}>Active</MenuItem>
            <MenuItem value={CUSTOMER_STATUS_FILTERS.inactive}>
              Inactive
            </MenuItem>
            <MenuItem value={CUSTOMER_STATUS_FILTERS.prospect}>
              Prospect
            </MenuItem>
          </FilterSelect>
        )}
        <CustomerTabSwitcherComponent
          tabs={["flat", "sorted", "map"]}
          currentTab={currentTab}
          handleChangeCurrentTab={handleChangeCurrentTab}
        />
        <FilterSearchTextField
          adminIsAllowed
          loading={currentTabLoading}
          formSx={{ minWidth: "380px" }}
          placeholderWidth="320px"
          placeholder={
            isGroups
              ? "Search groups by name or ID"
              : "Search customers by name, address or ID"
          }
          value={isGroups ? groupsSearchInput : customersSearchInput}
          onChange={(e) =>
            isGroups
              ? dispatch(setSearchGroupsAction(e.target.value))
              : dispatch(setSearchCustomersAction(e.target.value))
          }
          fullWidth
          adornmentProps={{
            sx: {
              justifyContent: "flex-end",
            },
          }}
          customAdornment={
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                overflow: "auto",
                justifyContent: "flex-end",
                py: "4px",
                "&::-webkit-scrollbar": {
                  height: "2px",
                },
              }}
            >
              <Box
                display="flex"
                gap="5px"
                overflow="auto"
                sx={{
                  py: "4px",
                  "&::-webkit-scrollbar": {
                    height: "2px",
                  },
                }}
              >
                {filterChipKeys.map((key) => (
                  <Chip
                    key={key}
                    className={classes.filterChip}
                    size="small"
                    label={getChipLabel(key)}
                    deleteIcon={
                      <CloseIcon size="15" style={{ fill: "#707070" }} />
                    }
                    onMouseDown={(e) => e.stopPropagation()}
                    onDelete={() => handleDeleteFilter(key)}
                  />
                ))}
              </Box>
            </Box>
          }
          handleClearValue={handleClearValue}
        />

        <CustomFilterMenu
          {...{
            selectMatchData,
            type: CUSTOM_FILTERS_TYPES.CUSTOMERS,
            switches: customerDefaultSwitch,
            onFilterApply: onCustomFilterApply,
            resetFilterFunc: () => {
              setFilterFields(initialCustomersState.customersFilters);
              setSwitches(initialCustomersState.customersSwitch);
            },
          }}
        />

        <StyledButton
          label="Filter"
          startIcon={
            filterChipKeys.length ? (
              <Typography
                fontSize="9px!important"
                color="#ffffff"
                backgroundColor="#47A06D"
                borderRadius="50%"
                width="15px"
                height="15px"
              >
                {filterChipKeys.length}
              </Typography>
            ) : (
              <FilterIcon />
            )
          }
          variant="outlined"
          ref={filterAnchor}
          color="edit"
          sx={{
            height: "39px",
            width: "100%",
            maxWidth: "74px",
            minWidth: "74px",
            border: "0.5px solid #D5D9D9",
            "& .MuiButton-startIcon": {
              ml: 0,
              mr: "",
            },
          }}
          fontSize="15px"
          onClick={() => setFilterMenuOpen(true)}
          data-testid="filter-button"
        />

        <FilterMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          onClose={() => setFilterMenuOpen(false)}
          filterFields={filterFields}
          iconDict={iconSort}
          switches={switches}
          handleApplyFilter={handleApplyFilter}
          selectMatchData={selectMatchData}
          onViewSave={onOpenFilterSaveDialog}
        />

        {isFlatOrSortedTab && (
          <Box sx={{ pr: "14px" }}>
            <ColumnsSettingComponent currentTab={currentTab} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

NewCustomersFilter.propTypes = {
  tabs: array,
  currentTab: string,
  currentFlatTab: number,
  handleChangeCurrentTab: func,
  handleCustomerTaskDrawer: func,
  handleChangeCurrentFlatTab: func,
  checkedCustomersCount: number,
  checkedGroupsCount: number,
  checkedCustomers: array,
  checkedGroups: array,
  isGroups: bool,
  handleOpenTransfer: func,
  handleOpenConfirmDialog: func,
  handleChangePaymentTerm: func,
  handleChangeTerritory: func,
  handleArchiveCustomers: func,
  handleDeleteCustomers: func,
  handleDeleteGroups: func,
  handleCancelSelection: func,
  filterFields: object,
  setFilterFields: func,
  setFilterSearch: func,
  handleCreateRoute: func,
  resetCheckedCustomers: func,
  switches: array,
  setSwitches: func,
  handleFetchParents: func,
  handleAssignTags: func,
  repPermissions: object,
  handleChangeParentGroup: func,
  tagsList: array,
  tagsCount: number,
  handleFetchTags: func,
  tagsPage: number,
  setTagsParams: func,
  searcheble: bool,
};

NewCustomersFilter.defaultProps = {
  tabs: null,
  handleCreateRoute: () => {},
  handleChangePaymentTerm: () => {},
};

export default NewCustomersFilter;
