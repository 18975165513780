import { Link, useLocation, useNavigate } from "react-router-dom";
import { array, func, string, object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useContext, useEffect, useMemo } from "react";

import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import StyledButton from "../../../components/StyledButton";
import {
  openConfirmDialogAction,
  setConfirmIsOpenAction,
  setEditItemAction,
  setFormChangedAction,
} from "../../../redux/actions/confirmDialogs";
import {
  selectedChildrensOfProductsAction,
  selectedProductsAction,
} from "../../../redux/actions/products";
import { useExport } from "../../SettingsPage/components/SettingsTabs/ImportExportTab/ImportExport.hooks";
import { ExportDialog } from "../../SettingsPage/components/SettingsTabs/ImportExportTab/components/ExportDialog";
import { useAdmin } from "helpers/helpers";
import {
  ActionRestrictionWrapper,
  CloneProps,
  StyledTooltip,
} from "components";
import { CategoriesContext, PriceContext } from "..";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  ADMIN_ONLY_VIEW_MESSAGE_PAGE,
} from "utils/constants";

const useStyles = makeStyles(() => ({
  label: {
    "& .MuiFormLabel-root": {
      color: "#B5B5AC",
      fontSize: "12px",
      top: "2px",
    },
    "& .Mui-focused": {
      color: "#42A57F",
      fontSize: "1rem",
      top: "0px",
    },
    "& .MuiOutlinedInput-root": {
      height: "100%",
    },
  },
  tabs: {
    minHeight: "auto",
    height: "44px",
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },
  },
  smallerButton: {
    height: "24px",
    "& > span": {
      fontSize: "11px",
    },
  },
}));

const CatalogSwitcherTab = ({
  tabs,
  currentTab,
  handleSetCurrentTab,
  repPermissions,
}) => {
  const isAdmin = useAdmin();
  const classes = useStyles();
  const { pathname, state } = useLocation();
  const currenTabCheck = state?.currentTab;
  const navigate = useNavigate();

  const { setOpenCreatePriceDialog } = useContext(PriceContext);
  const { onEditCategoryDrawerOpen } = useContext(CategoriesContext);

  const handleChangeCurrentTab = (e, newValue) => {
    if (formChanged && editType === "checked_items") {
      handleConfirmCheckedItemsDialog(tabs[newValue]);
      return;
    }
    handleSetCurrentTab(tabs[newValue]);
    navigate("/catalog");
  };
  useEffect(() => {
    if (currenTabCheck) handleSetCurrentTab(currenTabCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currenTabCheck]);
  const formChanged = useSelector(
    ({ confirmDialogs }) => confirmDialogs.formChanged
  );
  const editType = useSelector(({ confirmDialogs }) => confirmDialogs.editType);
  const dispatch = useDispatch();

  const handleConfirmCheckedItemsDialog = useCallback(
    (newValue, nav) => {
      dispatch(
        openConfirmDialogAction({
          path: nav,
          title: "Leaving this page?",
          text: "Your selected list will be discarded.",
          buttons: (
            <>
              <StyledButton
                variant="outlined"
                color="cancel"
                type="submit"
                form={`new-${editType}-form`}
                label="Cancel"
                sx={{ height: "28px" }}
                onClick={() => {
                  dispatch(setConfirmIsOpenAction(false));
                }}
              />
              <Button
                sx={{
                  width: "auto",
                  height: "28px",
                  color: "#FFFFFF",
                  fontSize: "13px",
                  boxShadow: "none",
                }}
                color="confirmDelete"
                onClick={() => {
                  dispatch(selectedProductsAction([]));
                  dispatch(selectedChildrensOfProductsAction([]));

                  dispatch(setFormChangedAction(false));
                  dispatch(setEditItemAction(null));

                  dispatch(setConfirmIsOpenAction(false));
                  if (newValue) handleSetCurrentTab(newValue);
                  if (nav) navigate(nav);
                }}
                variant="contained"
              >
                Confirm
              </Button>
            </>
          ),
        })
      );
    },
    [dispatch, editType, handleSetCurrentTab, navigate]
  );

  const {
    handleSubmit,
    control,
    exportOpenData,
    updateExportState,
    formField,
    onSubmit,
  } = useExport();

  const navState = useMemo(() => {
    const data = { isChildren: false };

    if (state?.type === "onboarding") data.type = "onboarding";

    return data;
  }, [state?.type]);

  const disabledExportBtn = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.catalog?.export;
    }

    return false;
  }, [repPermissions]);

  const disabledCreateEdit = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.catalog?.create_edit;
    }
  }, [repPermissions]);

  const disabledCreateEditCategories = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.catalog?.create_edit_categories;
    }
  }, [repPermissions]);

  const disabledCreateEditPriceList = useMemo(() => {
    if (repPermissions) {
      return !repPermissions?.catalog?.create_edit_price_lists;
    }
  }, [repPermissions]);

  const setDisabled = (tab) => {
    if (!repPermissions) return;

    if (tab === "Products") {
      return !repPermissions?.catalog?.view;
    }
    if (tab === "Manufacturers") {
      return !repPermissions?.catalog?.manufacturers;
    }
    if (tab === "Product Tags") {
      return !repPermissions?.catalog?.product_tags;
    }
    if (tab === "Price Lists") {
      return !repPermissions?.catalog?.view_price_lists;
    }

    return;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: "32px",
        backgroundColor: "#F0F0F0",
        borderBottom: "0.5px solid #D4D4D4",
        height: "44px",
      }}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ position: "absolute" }}
        id="products-export-form"
      >
        <ExportDialog
          open={exportOpenData.open}
          type={exportOpenData.type}
          onClose={() => updateExportState({ open: false })}
          control={control}
          format={formField.format}
          form="products-export-form"
        />
      </form>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box>
          {
            <Typography
              variant="h6"
              fontWeight="400"
              fontSize="20px"
              color="#9C9C94"
            >
              Catalog
            </Typography>
          }
        </Box>
        <Box>
          {tabs && (
            <Tabs
              className={classes.tabs}
              value={tabs.indexOf(currentTab)}
              onChange={handleChangeCurrentTab}
            >
              {tabs.map((tab) => (
                <CloneProps key={tab}>
                  {(cloneProps) => (
                    <ActionRestrictionWrapper
                      disableHoverListener={!setDisabled(tab)}
                      title={
                        repPermissions?.role === "SALES"
                          ? ADMIN_ONLY_VIEW_MESSAGE
                          : ADMIN_ONLY_VIEW_MESSAGE_PAGE
                      }
                    >
                      <Tab
                        {...cloneProps}
                        sx={{
                          py: 0,
                          height: "44px",
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#9C9C94",
                        }}
                        label={<Box sx={{ textTransform: "none" }}>{tab}</Box>}
                        value={tabs.indexOf(tab)}
                        disabled={setDisabled(tab)}
                      />
                    </ActionRestrictionWrapper>
                  )}
                </CloneProps>
              ))}
            </Tabs>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 1,
        }}
      >
        {currentTab === "Products" && (
          <>
            <ActionRestrictionWrapper disableHoverListener={!disabledExportBtn}>
              <StyledButton
                disabled={disabledExportBtn}
                sx={{ height: 28, color: "#707070" }}
                color="groundLighter"
                variant="outlined"
                onClick={() =>
                  updateExportState({ type: "products", open: true })
                }
                label="Export"
              />
            </ActionRestrictionWrapper>
            <StyledTooltip title="Coming soon" arrow placement="top">
              <Box>
                <StyledButton
                  disabled={true}
                  sx={{ height: 28, color: "#707070" }}
                  color="groundLighter"
                  variant="outlined"
                  label="Import"
                />
              </Box>
            </StyledTooltip>
            <ActionRestrictionWrapper
              disableHoverListener={!disabledCreateEdit}
            >
              <StyledButton
                disabled={disabledCreateEdit}
                sx={{ height: 28 }}
                variant="contained"
                label="Add Product"
                component={Link}
                state={{ currentTab, ...navState }}
                from={pathname}
                to={{
                  pathname: `${pathname}/new`,
                }}
                onClick={() => {
                  if (formChanged) {
                    if (editType === "checked_items")
                      return handleConfirmCheckedItemsDialog(
                        "",
                        (`${pathname}/new`,
                        { state: { currentTab, ...navState } })
                      );
                  }
                  // navigate(`${pathname}/new`, { state: navState });
                }}
              />
            </ActionRestrictionWrapper>
          </>
        )}
        {currentTab === "Manufacturers" && (
          <ActionRestrictionWrapper disableHoverListener={!disabledCreateEdit}>
            <StyledButton
              disabled={disabledCreateEdit || isAdmin}
              sx={{ height: 28 }}
              variant="contained"
              label="Add Manufacturer"
              component={Link}
              to={{
                pathname: `${pathname}/manufacturers/new`,
              }}
              state={{ currentTab }}
              from={pathname}
            />
          </ActionRestrictionWrapper>
        )}
        {currentTab === "Categories" && (
          <ActionRestrictionWrapper
            disableHoverListener={!disabledCreateEditCategories}
          >
            <StyledButton
              disabled={disabledCreateEditCategories || isAdmin}
              sx={{ height: 28 }}
              variant="contained"
              label="Add Category"
              onClick={() => onEditCategoryDrawerOpen({ isCreate: true })}
            />
          </ActionRestrictionWrapper>
        )}
        {currentTab === "Product Tags" && (
          <ActionRestrictionWrapper disableHoverListener={!disabledCreateEdit}>
            <StyledButton
              disabled={disabledCreateEdit || isAdmin}
              sx={{ height: 28 }}
              variant="contained"
              label="Add Product Tag"
              component={Link}
              to={`${pathname}/tags/new`}
              state={{ currentTab }}
              from={pathname}
            />
          </ActionRestrictionWrapper>
        )}
        {currentTab === "Price Lists" && (
          <ActionRestrictionWrapper
            disableHoverListener={!disabledCreateEditPriceList}
          >
            <StyledButton
              disabled={disabledCreateEditPriceList || isAdmin}
              sx={{ height: 28 }}
              variant="contained"
              label="Add Price List"
              onClick={() => {
                setOpenCreatePriceDialog(true);
              }}
            />
          </ActionRestrictionWrapper>
        )}
      </Box>
    </Box>
  );
};

CatalogSwitcherTab.propTypes = {
  tabs: array,
  currentTab: string,
  handleSetCurrentTab: func,
  repPermissions: object,
};
CatalogSwitcherTab.defaultProps = {
  tabs: [],
};

export default CatalogSwitcherTab;
