import { bool, func } from "prop-types";
import { IconButton, Stack, Typography } from "@mui/material";
import { cl } from "../NotificationCenter.styles";
import { SettingsIcon } from "components/Icons/NavbarIcons/MenuListIcons";

export const Header = ({ showSettings, setShowSettings }) => {
  return (
    <Stack direction="row" sx={cl.header}>
      <Typography variant="subtitle1" color="#707070">
        {showSettings ? "Notification Settings" : "Notifications"}
      </Typography>

      <IconButton
        disableRipple
        sx={{ p: 0 }}
        onClick={() => setShowSettings(!showSettings)}
      >
        <SettingsIcon
          width={17}
          height={17}
          stroke={showSettings ? "#409A65" : "#9c9c94"}
        />
      </IconButton>
    </Stack>
  );
};

Header.propTypes = {
  showSettings: bool,
  setShowSettings: func,
};
Header.defaultProps = {
  onTabsClick: () => {},
};
