import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "540px",
    maxWidth: "540px",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  header: {
    backgroundColor: "#F8F8F8",
    borderBottom: "1px solid #d5d9d9",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "67px",
    position: "relative",
    paddingLeft: "33px",
    paddingRight: "13px",
    gap: "15px",
  },

  numberBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    minWidth: "24px",
    height: "24px",
    backgroundColor: "rgba(71, 160, 110, 0.2)",
    paddingLeft: "4px",
    paddingRight: "4px",
  },

  searchInput: {
    paddingLeft: "23px",
    backgroundColor: "rgba(213, 217, 217, 0.15)",
    paddingRight: "5px",
    height: "38px",
  },

  searchLabel: {
    left: 23,
    color: "#707070",
    transitionProperty: "all",

    top: -1,
    "&.Mui-focused": {
      left: 0,
    },
    "&.MuiFormLabel-filled": {
      left: 0,
    },
  },

  bodyWrapper: {
    padding: "24px 32px 32px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "0 !important",
    overflowY: "scroll",
    height: "calc(100vh - 147px)",

    "& .MuiOutlinedInput-root": {
      fontSize: "12px",
    },
  },

  footer: {
    marginTop: "auto",
    height: "80px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 32px",
    backgroundColor: "#F8F8F8",
    borderTop: "0.5px solid #D5D9D9",
    "& .MuiButton-root": {
      height: "32px",
    },
    "& .MuiButton-root span": {
      fontSize: "14px !important",
    },
  },

  actionsButton: {
    minWidth: "80px",
    boxSizing: "border-box",
    backgroundColor: "#F7F7F7",
    borderRadius: "0 4px 4px 0",
    height: "35px",
    paddingLeft: 0,
    paddingRight: 0,
  },

  select: {
    height: "35px",
    fontSize: 13,
    color: "#707070",
    paddingRight: 0,
    "& .MuiSelect-select": {
      paddingRight: "14px!important",
      paddingLeft: "29px",
    },

    "& .MuiSelect-icon": {
      left: 11,
      width: "7px",
      height: "13px",
    },
  },
  dayInput: {
    width: "52px",
    height: "35px",
    fontSize: "12px",
    borderRadius: "4px 0 0 4px",
    pr: 0,
    background: "#fff",
    color: "#707070",

    "&:hover": {
      backgroundColor: "#F7F7F7 !important",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderRight: "none",
      borderColor: "#D5D9D9 !important",
    },
  },
}));

export default useStyles;
