import { useEffect, useMemo, useState } from "react";
import PropTypes, { bool, func, string, object } from "prop-types";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
} from "@mui/material";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmDialogIsOpenSelector,
  confirmDialogText1Selector,
  confirmDialogText2Selector,
  confirmDialogTitleSelector,
  confirmDialogUserNameSelector,
  confirmDialogIconSelector,
  confirmDialogButtonsSelector,
  confirmDialogPropBtnsSelector,
  confirmDialogFormChangedSelector,
  editTypeSelector,
  confirmDialogCenteredSelector,
  confirmDialogStylesSelector,
  confirmDialogButtonsStylesSelector,
  confirmDialogIsCancelSelector,
  confirmDialogConfirmDataSelector,
} from "redux/selectors/confirmDialogs";
import { CrossIcon, DangerIcon } from "components/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import {
  openDiscardChanges,
  resetConfirmDialogAction,
  setConfirmIsOpenAction,
  setConfirmPropBtnsAction,
  setEditItemAction,
  setEditTypeAction,
} from "redux/actions/confirmDialogs";
import { usePrevious } from "helpers/hooks";
import { StyledButton, StyledTextField } from "components";

const selector = createSelector(
  confirmDialogTitleSelector,
  confirmDialogText1Selector,
  confirmDialogUserNameSelector,
  confirmDialogText2Selector,
  confirmDialogIsOpenSelector,
  confirmDialogIconSelector,
  confirmDialogButtonsSelector,
  confirmDialogPropBtnsSelector,
  confirmDialogFormChangedSelector,
  editTypeSelector,
  confirmDialogCenteredSelector,
  confirmDialogStylesSelector,
  confirmDialogButtonsStylesSelector,
  confirmDialogIsCancelSelector,
  confirmDialogConfirmDataSelector,
  (
    title,
    text1,
    userName,
    text2,
    isOpen,
    isIcon,
    buttons,
    propBtns,
    formChanged,
    editType,
    centered,
    dialogStyles,
    dialogButtonsStyles,
    isCancel,
    confirmData
  ) => ({
    title,
    text1,
    userName,
    text2,
    isOpen,
    isIcon,
    buttons,
    propBtns,
    formChanged,
    editType,
    centered,
    dialogStyles,
    dialogButtonsStyles,
    isCancel,
    confirmData,
  })
);

const styles = {
  text: {
    fontSize: "15px",
    fontWeight: 300,
    letterSpacing: 0,
    color: "#363531",
    whiteSpace: "pre-wrap",
  },
};

const UniversalConfirm = () => {
  const {
    title,
    text1,
    userName,
    text2,
    isOpen,
    isIcon,
    buttons,
    propBtns,
    formChanged,
    editType,
    centered,
    dialogStyles,
    dialogButtonsStyles,
    isCancel,
    confirmData,
  } = useSelector(selector);
  const { pathname } = useLocation();
  const { item: prevEditType } = usePrevious(editType);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [confirmTextValue, setConfirmTextValue] = useState("");

  const disabledIsConfirmText = useMemo(() => {
    if (!confirmData?.value) return false;
    return confirmTextValue !== confirmData.value;
  }, [confirmData, confirmTextValue]);

  useEffect(() => {
    if (isOpen) {
      setLoading(false);
      setConfirmTextValue("");
    }
  }, [isOpen]);

  useEffect(() => {
    dispatch(setEditTypeAction("", false));
    if (!formChanged) dispatch(setEditItemAction(null));
    if (formChanged && editType && prevEditType) {
      dispatch(openDiscardChanges(() => navigate(-1)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dispatch]);

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      TransitionProps={{
        onExited: () => {
          dispatch(setConfirmPropBtnsAction({ propBtns: null }));
        },
      }}
      sx={{
        zIndex: (theme) => theme.zIndex.tooltip + 1,
        "& .MuiDialog-paper": {
          borderRadius: "8px",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontSize: "18px",
          color: "#363531",
          fontWeight: 400,
          p: "16px 16px 10px 16px",
          m: isCancel ? "8px 0 0 0" : "0 0 1 0",
          position: "relative",
        }}
      >
        <Box display="flex" alignItems="center" gap="8px">
          {!!isIcon && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DangerIcon />
            </Box>
          )}
          <Box sx={{ maxWidth: "90%" }}>{title}</Box>
        </Box>

        <IconButton
          disableRipple
          sx={{ position: "absolute", right: 4, top: 10 }}
          onClick={() => {
            dispatch(setConfirmIsOpenAction(false));
            propBtns?.left?.onClick && propBtns?.left?.onClick();
            //dispatch(resetConfirmDialogAction({ formChanged }));
          }}
        >
          <CrossIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          p: "0px 16px 24px 16px",
          maxWidth: "484px",
          minWidth: "420px",
          textAlign: centered && "center",
          borderBottom: isCancel ? "none" : "0.5px solid #D5D9D9",
          ...dialogStyles,
        }}
      >
        {userName ? (
          <span>
            <Typography sx={styles.text}>
              {text1}
              {userName ? (
                <span style={{ marginLeft: "5px", color: "#47A06D" }}>
                  {userName}?
                </span>
              ) : (
                ""
              )}
            </Typography>
            <Typography sx={styles.text}>
              {text2 ? <span>{text2}</span> : ""}
            </Typography>
          </span>
        ) : (
          <>
            <Box sx={styles.text}>{text1}</Box>
            <Typography sx={styles.text}>
              {text2 ? <span>{text2}</span> : ""}
            </Typography>
          </>
        )}

        {!!confirmData && (
          <Box mt="16px">
            <StyledTextField
              value={confirmTextValue}
              onChange={(e) => setConfirmTextValue(e.target.value)}
              {...confirmData?.props}
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "16px",
          "& :not(:first-of-type)": {
            ml: "12px",
          },
          ...dialogButtonsStyles,
        }}
      >
        {!!buttons && buttons}

        {
          <>
            {!!propBtns?.left && (
              <StyledButton
                // onClick={() => dispatch(setConfirmIsOpenAction(false))}
                onClick={() =>
                  dispatch(resetConfirmDialogAction({ formChanged }))
                }
                {...propBtns.left}
              />
            )}

            {!!propBtns?.right && propBtns?.right?.onClick && (
              <StyledButton
                {...propBtns.right}
                disabled={disabledIsConfirmText}
                onClick={() => {
                  const onConfirm = propBtns.right.onClick;
                  if (loading) return;
                  setLoading(true);
                  onConfirm();
                }}
              />
            )}
          </>
        }
      </DialogActions>
    </Dialog>
  );
};

UniversalConfirm.propTypes = {
  isOpen: bool,
  text: PropTypes.oneOfType([string, object]),
  onCancel: func,
  onAccept: func,
};

UniversalConfirm.defaultProps = {
  isOpen: false,
  text: "Are you sure?",
};

export default UniversalConfirm;
