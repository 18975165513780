import { useCallback, useState } from "react";
import { shape, bool, string, number, object } from "prop-types";
import { useDispatch } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { separateNumWithComma } from "helpers/helpers";
import { ItemText } from "./ItemText";
import { setCurrentUser, updateUserFieldAction } from "redux/actions/auth";
import { error } from "utils/notifications";
import { unsubscribeDistributorService } from "services/stripe";
import { getCurrentUser } from "services/account";
import { setTrialDialogOpen } from "redux/actions/subscriptionDialogs";
import moment from "moment-timezone";

export const PaymentInfo = ({
  hasStatusCanceled,
  subscription,
  hasTrialDialogState,
  currentUser,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleUnsubscribeDistributor = useCallback(() => {
    setLoading(true);
    unsubscribeDistributorService(currentUser?.distributorSubscription?.id)
      .then(() => {
        getCurrentUser()
          .then((res) => {
            dispatch(setCurrentUser(res));
          })
          .then(() =>
            dispatch(
              updateUserFieldAction("trialDialogState", {
                incomplete: true,
              })
            )
          )
          // eslint-disable-next-line no-console
          .catch((err) => console.error(err));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      })
      .finally(() => setLoading(false));
  }, [currentUser?.distributorSubscription?.id, dispatch]);

  return (
    <Box
      sx={{
        border: "0.5px solid #CCCCCC",
        borderRadius: "4px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        py: 2,
        position: "relative",
      }}
    >
      {hasStatusCanceled ? (
        <>
          {!!hasTrialDialogState && (
            <Button
              disabled={loading}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                fontSize: 16,
              }}
              onClick={() => {
                if (
                  currentUser?.distributorSubscription
                    ?.stripeSubscriptionStatus === "incomplete" &&
                  currentUser?.distributorSubscription?.failureMessage !== null
                ) {
                  handleUnsubscribeDistributor();
                  return;
                }
                dispatch(setTrialDialogOpen());
                dispatch(updateUserFieldAction("trialDialogState", null));
              }}
            >
              Update
            </Button>
          )}
          <ItemText
            name="Subscription: "
            value={subscription?.subscription?.name || subscription?.name}
            valueStyle={{
              color: "#47A06D",
            }}
          />
          <ItemText
            name="Subscription price: "
            value={`$${separateNumWithComma(
              subscription?.subscription?.price || subscription?.price
            )} / ${
              subscription?.subscription?.term?.toLowerCase() ||
              subscription?.term?.slice(0, 5)?.toLowerCase()
            }`}
          />
        </>
      ) : (
        <>
          <ItemText
            name="Subscription: "
            value={subscription?.subscription?.name}
            valueStyle={{
              fontWeight: 600,
              color: "#47A06D",
            }}
          />
          <ItemText
            name="Subscription price: "
            value={`$ ${separateNumWithComma(
              subscription?.subscription?.price
            )} / ${
              typeof subscription?.subscription?.term === "string"
                ? subscription?.subscription?.term?.toLowerCase()
                : ""
            }`}
          />
          {!currentUser?.delayedSubscriptionDraft && (
            <ItemText
              name="Date: "
              value={moment
                .tz(subscription?.subscription?.date, currentUser?.timeZone)
                .format("MMM D, YYYY")}
            />
          )}
          {!!subscription?.extraAllowedUsers && (
            <ItemText
              name="Extra users: "
              value={subscription?.extraAllowedUsers}
            />
          )}
          {!!subscription?.discountDuration && (
            <>
              <ItemText
                name="Allowed Discount: "
                value={`${subscription?.discountPercentDiscount}%`}
              />
              <ItemText
                name="Adjusted price: "
                value={`$ ${separateNumWithComma(
                  subscription?.subscription?.price -
                    (subscription?.subscription?.price *
                      subscription?.discountPercentDiscount) /
                      100
                )} / ${
                  typeof subscription?.subscription?.term === "string"
                    ? subscription?.subscription?.term?.toLowerCase()
                    : ""
                }`}
                valueStyle={{
                  fontWeight: 700,
                }}
              />
            </>
          )}
        </>
      )}

      {(hasStatusCanceled || !!currentUser?.delayedSubscriptionDraft) && (
        <Box pl="27px" py={1}>
          <Typography fontSize={16} color="#292929" whiteSpace="pre-wrap">
            {`Your subscription automatically renews until canceled.\nTo avoid being charged, you must cancel at least a day before each renewal date.`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

PaymentInfo.propTypes = {
  hasStatusCanceled: bool,
  subscription: shape({
    subscription: shape({
      date: string,
      name: string,
      price: number,
      amount: number,
    }),
  }),
  hasTrialDialogState: object,
  currentUser: object,
};
PaymentInfo.defaultProps = {};
