import { func, object } from "prop-types";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { TitleBlock } from "./components";
import {
  AccordionComponent,
  DetailsComponent,
  LargeCheckbox,
  SummaryComponent,
} from "components";
import { ShevronIcon } from "components/Icons";
import { sx } from "./TabPermissionsComponent.styles";
import { normalizeSnakeCaseString } from "helpers/helpers";
import { Controller } from "react-hook-form";
import { useCallback, useState } from "react";
import { PERMISSION_ICONS } from "../TabProfile.constants";

const TabPermissionsComponent = ({ formField, control, setValue }) => {
  const {
    role,
    permissions,
    mobilePermissions,
    portalAccess,
    mobilePermissionsEnabled,
  } = formField || {};

  const setName = (childKey) => {
    if (childKey === "edit_prices")
      return "Enable product price changes at checkout";
    if (childKey === "create_edit") return "Create and edit";
    if (childKey === "create_edit_price_lists")
      return "Create and edit price lists";
    if (childKey === "create_edit_categories")
      return "Create and edit categories";

    return (childKey.charAt(0).toUpperCase() + childKey.slice(1)).replace(
      /_/g,
      " "
    );
  };

  const webPermissionData = () => {
    const name = (role) => {
      if (role === "SALES") {
        // return "Sales Rep Access";
        return "Web Permissions";
      }
      if (role === "MERCHANDISER") {
        // return "Merchandiser Rep Access";
        return "Web Permissions ";
      }
      return "";
    };

    const temp = { ...permissions };

    delete temp.id;
    delete temp.role;
    delete temp.representativeId;

    const preparedData = Object.keys(temp).map((key) => {
      const values = Object.values(temp[key]);
      const keys = Object.keys(temp[key]);

      const setName = (childKey) => {
        if (childKey === "edit_prices")
          return "Enable product price changes at checkout";
        if (childKey === "create_edit") return "Create and edit";
        if (childKey === "create_edit_price_lists")
          return "Create and edit price lists";
        if (childKey === "create_edit_categories")
          return "Create and edit categories";

        return (childKey.charAt(0).toUpperCase() + childKey.slice(1)).replace(
          /_/g,
          " "
        );
      };

      return {
        id: key,
        name: normalizeSnakeCaseString(key),
        checked: values.every(Boolean),
        checkedCount: values.filter(Boolean).length,
        totalCount: values.length,
        children: keys.map((childKey, index) => ({
          id: childKey,
          name: setName(childKey),
          value: values[index],
        })),
      };
    });

    return {
      name: name(role),
      data: preparedData,
    };
  };
  const mobPermissionData = () => {
    const temp = { ...mobilePermissions };

    delete temp.id;
    delete temp.role;
    delete temp.representativeId;

    const preparedData = Object.keys(temp).map((key) => {
      const values = Object.values(temp[key]);
      const keys = Object.keys(temp[key]);

      return {
        id: key,
        name: normalizeSnakeCaseString(key),
        checked: values.every(Boolean),
        checkedCount: values.filter(Boolean).length,
        totalCount: values.length,
        children: keys.map((childKey, index) => ({
          id: childKey,
          name: setName(childKey),
          value: values[index],
        })),
      };
    });

    return {
      name: "Mob Permissions",
      data: preparedData,
    };
  };

  const valueCheckbox = (arr) => {
    const allTrue = arr?.every((item) => !!item?.value);
    return {
      checked: allTrue,
    };
  };

  const { name, data } = webPermissionData();

  const { name: mobName, data: mobData } = mobPermissionData();

  const handleOnChange = ({
    permissionBlock,
    permissionItem,
    type = "web",
  }) => {
    const nameType = type === "web" ? "permissions" : "mobilePermissions";

    const permissions =
      type === "web" ? formField?.permissions : formField?.mobilePermissions;

    const hasKeyViewInObject =
      "view" in permissions?.[`${permissionBlock?.id}`];

    if (
      permissionItem?.name !== "View" &&
      hasKeyViewInObject &&
      !permissions?.[`${permissionBlock?.id}`]?.view &&
      !permissionItem?.value
    ) {
      setValue(`${nameType}.${permissionBlock?.id}.view`, true);
    }

    if (
      permissionItem?.name === "View" &&
      permissions?.[`${permissionBlock?.id}`]?.view
    ) {
      setValue(`${nameType}.${permissionBlock?.id}`, {
        ...permissionBlock?.children?.reduce((acc, child) => {
          acc[child?.id] = false;
          return acc;
        }, {}),
      });
    }

    setValue(
      `${nameType}.${permissionBlock?.id}.${permissionItem.id}`,
      !permissionItem?.value
    );

    // logic only for the Catalog | View price lists ・ Create edit price lists ・ Delete price lists
    if (
      ["Create edit price lists", "Delete price lists"].includes(
        permissionItem.name
      )
    ) {
      setValue(`${nameType}.${permissionBlock?.id}.view_price_lists`, true);
    }

    if ("View price lists" === permissionItem.name && permissionItem?.value) {
      setValue(
        `${nameType}.${permissionBlock?.id}.create_edit_price_lists`,
        false
      );
      setValue(`${nameType}.${permissionBlock?.id}.delete_price_lists`, false);
    }
  };

  const handleWebAccess = useCallback(
    (accessValue, type = "web") => {
      setValue(
        type === "web" ? "portalAccess" : "mobilePermissionsEnabled",
        accessValue
      );

      const perm = type === "web" ? permissions : mobilePermissions;

      const nameType = type === "web" ? "permissions" : "mobilePermissions";

      Object.keys(perm).forEach((blockKey) => {
        const permissionBlock = perm[blockKey];
        if (typeof permissionBlock === "object") {
          Object.keys(permissionBlock).forEach((childKey) => {
            setValue(`${nameType}.${blockKey}.${childKey}`, accessValue);
          });
        }
      });
    },
    [mobilePermissions, permissions, setValue]
  );

  const [expanded, setExpanded] = useState(false);

  const handleChangeExpand = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={sx.tabWrapper}>
      <Stack sx={sx.contentWrapper}>
        <TitleBlock {...{ name, control, handleWebAccess }} />
        {data.map((item) => {
          const { totalCount, checkedCount, name } = item;
          const Icon = PERMISSION_ICONS[item?.id]?.icon;
          const iconProps = PERMISSION_ICONS[item?.id]?.iconProps;
          return (
            <AccordionComponent
              key={name}
              item={item}
              expandedDflt={false}
              sx={sx.accordionComponent}
              expandedAcc={expanded}
              handleChangeExpand={handleChangeExpand}
            >
              <SummaryComponent item={item} sx={sx.summaryComponent}>
                <Grid container alignItems="center" height="100%">
                  <Grid item xs sx={sx.summaryComponentGrid}>
                    <Controller
                      render={({ field }) => {
                        const { checked } = valueCheckbox(item.children);
                        return (
                          <LargeCheckbox
                            disabled={!portalAccess}
                            checked={checked}
                            indeterminate={
                              checkedCount > 0 && checkedCount !== totalCount
                            }
                            indeterminateIcon={21}
                            checkedColor={!portalAccess ? "#C5C5C5" : "#47a06d"}
                            size={16}
                            formSx={{ m: 0 }}
                            sx={{
                              width: "35px",
                              height: "35px",
                              p:
                                checkedCount > 0 && checkedCount !== totalCount
                                  ? "8px"
                                  : "9px",
                              "& svg": {
                                ml:
                                  checkedCount > 0 &&
                                  checkedCount !== totalCount &&
                                  "-1px",
                              },
                            }}
                            onClick={(e) => e.stopPropagation()}
                            {...field}
                            onChange={(e) => {
                              const val = e.target.checked;
                              setValue(`permissions.${item?.id}`, {
                                ...item?.children?.reduce((acc, child) => {
                                  acc[child?.id] = val;
                                  return acc;
                                }, {}),
                              });
                            }}
                          />
                        );
                      }}
                      control={control}
                      name={`permissions.${item?.id}`}
                    />
                    <IconButton
                      sx={{
                        p: 0,
                        ml: "5px",
                        "& svg": {
                          "& path": {
                            stroke: "#409A65 ",
                          },
                        },
                      }}
                    >
                      <Icon {...iconProps} />
                    </IconButton>
                    <Typography ml="12px">{item?.name}</Typography>
                  </Grid>

                  <Grid item pr="21px" xs={2.8} sx={sx.shevronWrapper}>
                    <Typography
                      sx={{ fontSize: "13px" }}
                    >{`${checkedCount}/${totalCount}`}</Typography>
                    <ShevronIcon className="my-ico" width={12} height={6} />
                  </Grid>
                </Grid>
              </SummaryComponent>

              <DetailsComponent sx={sx.detailsComponent}>
                {item?.children?.map((c) => (
                  <Box key={c?.name} pl="30px" sx={sx.childWrapper}>
                    <Controller
                      render={({ field }) => {
                        return (
                          <LargeCheckbox
                            disabled={!portalAccess}
                            checkedColor={!portalAccess ? "#C5C5C5" : "#47a06d"}
                            size={16}
                            formSx={{ m: 0, height: "44px" }}
                            sx={{ pr: "10px" }}
                            label={
                              <Typography
                                fontSize={12}
                                fontWeight={400}
                                color="#6A6A6A"
                              >
                                {c?.name}
                              </Typography>
                            }
                            checked={c?.value}
                            {...field}
                            onChange={() =>
                              handleOnChange({
                                permissionBlock: item,
                                permissionItem: c,
                                type: "web",
                              })
                            }
                          />
                        );
                      }}
                      control={control}
                      name={`permissions.${item?.id}.${c.id}`}
                    />
                  </Box>
                ))}
              </DetailsComponent>
            </AccordionComponent>
          );
        })}
      </Stack>

      <Stack sx={sx.contentWrapper}>
        <TitleBlock
          {...{
            name: mobName,
            control,
            handleWebAccess: (accessValue) => {
              handleWebAccess(accessValue, "mob");
            },
            fieldName: "mobilePermissionsEnabled",
          }}
        />
        {mobData.map((item) => {
          const { totalCount, checkedCount, name } = item;
          const Icon = PERMISSION_ICONS[item?.id]?.icon;
          const iconProps = PERMISSION_ICONS[item?.id]?.iconProps;

          return (
            <AccordionComponent
              key={name}
              item={{ ...item, id: item?.id + "mob" }}
              expandedDflt={false}
              sx={sx.accordionComponent}
              expandedAcc={expanded}
              handleChangeExpand={handleChangeExpand}
            >
              <SummaryComponent item={item} sx={sx.summaryComponent}>
                <Grid container alignItems="center" height="100%">
                  <Grid item xs sx={sx.summaryComponentGrid}>
                    <Controller
                      render={({ field }) => {
                        const { checked } = valueCheckbox(item.children);
                        return (
                          <LargeCheckbox
                            disabled={!mobilePermissionsEnabled}
                            checked={checked}
                            indeterminate={
                              checkedCount > 0 && checkedCount !== totalCount
                            }
                            indeterminateIcon={21}
                            checkedColor={
                              !mobilePermissionsEnabled ? "#C5C5C5" : "#47a06d"
                            }
                            size={16}
                            formSx={{ m: 0 }}
                            sx={{
                              width: "35px",
                              height: "35px",
                              p:
                                checkedCount > 0 && checkedCount !== totalCount
                                  ? "8px"
                                  : "9px",
                              "& svg": {
                                ml:
                                  checkedCount > 0 &&
                                  checkedCount !== totalCount &&
                                  "-1px",
                              },
                            }}
                            onClick={(e) => e.stopPropagation()}
                            {...field}
                            onChange={(e) => {
                              const val = e.target.checked;
                              setValue(`mobilePermissions.${item?.id}`, {
                                ...item?.children?.reduce((acc, child) => {
                                  acc[child?.id] = val;
                                  return acc;
                                }, {}),
                              });
                            }}
                          />
                        );
                      }}
                      control={control}
                      name={`mobilePermissions.${item?.id}`}
                    />
                    <IconButton
                      sx={{
                        p: 0,
                        ml: "5px",
                        "& svg": { "& path": { stroke: "#409A65 " } },
                      }}
                    >
                      <Icon {...iconProps} />
                    </IconButton>
                    <Typography ml="12px">{item?.name}</Typography>
                  </Grid>

                  <Grid item pr="21px" xs={2.8} sx={sx.shevronWrapper}>
                    <Typography
                      sx={{ fontSize: "13px" }}
                    >{`${checkedCount}/${totalCount}`}</Typography>
                    <ShevronIcon className="my-ico" width={12} height={6} />
                  </Grid>
                </Grid>
              </SummaryComponent>

              <DetailsComponent sx={sx.detailsComponent}>
                {item?.children?.map((c) => (
                  <Box key={c?.name} pl="30px" sx={sx.childWrapper}>
                    <Controller
                      render={({ field }) => {
                        return (
                          <LargeCheckbox
                            disabled={!mobilePermissionsEnabled}
                            checkedColor={
                              !mobilePermissionsEnabled ? "#C5C5C5" : "#47a06d"
                            }
                            size={16}
                            formSx={{ m: 0, height: "40px" }}
                            sx={{ pr: "10px" }}
                            label={
                              <Typography
                                fontSize={12}
                                fontWeight={400}
                                color="#6A6A6A"
                              >
                                {c?.name}
                              </Typography>
                            }
                            checked={c?.value}
                            {...field}
                            onChange={() =>
                              handleOnChange({
                                permissionBlock: item,
                                permissionItem: c,
                                type: "mob",
                              })
                            }
                          />
                        );
                      }}
                      control={control}
                      name={`mobilePermissions.${item?.id}.${c.id}`}
                    />
                  </Box>
                ))}
              </DetailsComponent>
            </AccordionComponent>
          );
        })}
      </Stack>
    </Box>
  );
};

TabPermissionsComponent.propTypes = {
  control: object,
  formField: object,
  setValue: func,
};
TabPermissionsComponent.defaultProps = {
  control: {},
  formField: {},
  setValue: () => {},
};

export default TabPermissionsComponent;
