import { useContext, useRef } from "react";
import { Box, FormControl, Grid, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import {
  PlaceAutocompleteField,
  StyledTextField,
  StyledTooltip,
} from "components";
import { CustomerContext } from "Pages/CustomersPage/pages/CustomerPage/CustomerPage";
import { ExpandMore } from "@mui/icons-material";
import { BusinessNameTooltip } from "../BusinessNameTooltipContent";
import { CUSTOMER_STATUS_FILTERS } from "utils/constants";

export const BusinessNameBlock = () => {
  const {
    control,
    handleSetCustomer,
    setCustomerLoading,
    formField,
    customerId,
    setValue,
    destroyConfirmDialogState,
  } = useContext(CustomerContext);

  const nameRef = useRef(null);

  const { statusData } = formField || {};

  return (
    <Grid xs={12} columnSpacing={1.625} item container>
      <Grid sx={{ display: "flex" }} xs={10} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <BusinessNameTooltip
              error={error}
              onClickViewCallback={destroyConfirmDialogState}
            >
              <PlaceAutocompleteField
                adminIsAllowed
                fullWidth={false}
                size="small"
                InputLabelProps={{
                  sx: { fontSize: "13px", color: "#5F6267", top: 3 },
                }}
                formSx={{ width: "calc(100% - 80px)" }}
                handleSetCustomer={handleSetCustomer}
                error={error ? error.message : ""}
                label="Business name"
                noErrorMessage
                InputProps={{
                  sx: {
                    fontSize: "13px",
                    height: "43px",
                    borderRadius: "4px 0 0 4px",
                    "& fieldset": { borderWidth: "1px 0.5px 1px 1px" },
                  },
                }}
                setCustomerLoading={setCustomerLoading}
                {...field}
                ref={nameRef}
                value={field.value}
              />
            </BusinessNameTooltip>
          )}
          name="name"
          control={control}
        />

        <Controller
          render={({ field, fieldState: { error } }) => (
            <FormControl>
              <StyledTooltip
                placement="top"
                arrow
                title={statusData?.message}
                open={statusData?.showTooltip}
                onOpen={() => {
                  if (statusData?.isDisabled) {
                    setValue("statusData.showTooltip", true);
                  }
                }}
                onClose={() => setValue("statusData.showTooltip", false)}
              >
                <Box
                  component="span"
                  onMouseEnter={() => {
                    if (statusData?.isDisabled) {
                      setValue("statusData.showTooltip", true);
                    }
                  }}
                  onMouseLeave={() => setValue("statusData.showTooltip", false)}
                >
                  <Select
                    disabled={statusData?.isDisabled}
                    displayEmpty
                    labelId={`${field.name}-select-label`}
                    MenuProps={{ disableScrollLock: true }}
                    sx={{
                      width: "fit-content",
                      height: 43,
                      "& .MuiInputBase-input": {
                        fontSize: 13,
                        color: "#5F6267",
                      },
                      "& .MuiSelect-select": {
                        paddingLeft: "10px !important",
                        paddingRight: "30px !important",
                      },
                      "& fieldset": {
                        border: "1px solid #D5D9D9",
                        borderRadius: "0 4px 4px 0",
                        borderWidth: "1px 1px 1px 0.5px",
                      },
                      "& svg": {
                        right: 4,
                      },
                      color: field.value === "" ? "#717171" : "",
                    }}
                    IconComponent={(props) => (
                      <ExpandMore sx={{ fontSize: 20 }} {...props} />
                    )}
                    error={!!error?.message}
                    {...field}
                  >
                    <MenuItem value={CUSTOMER_STATUS_FILTERS.active}>
                      Active
                    </MenuItem>
                    {!!customerId && (
                      <MenuItem value={CUSTOMER_STATUS_FILTERS.inactive}>
                        Inactive
                      </MenuItem>
                    )}
                    <MenuItem value={CUSTOMER_STATUS_FILTERS.prospect}>
                      Prospect
                    </MenuItem>
                  </Select>
                </Box>
              </StyledTooltip>
            </FormControl>
          )}
          name="status"
          control={control}
        />
      </Grid>

      <Grid xs={2} item>
        <Controller
          render={({ field, fieldState: { error } }) => (
            <StyledTextField
              adminIsAllowed
              fullWidth
              noErrorMessage
              InputProps={{ sx: { fontSize: "13px", height: "43px" } }}
              inputProps={{ style: { textTransform: "uppercase" } }}
              InputLabelProps={{
                sx: { fontSize: "13px", color: "#5F6267", top: 3 },
              }}
              error={error ? error.message : ""}
              label="ID"
              {...field}
            />
          )}
          name="customId"
          control={control}
        />
      </Grid>
    </Grid>
  );
};
