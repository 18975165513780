import React, { useState } from "react";
import PropTypes, {
  array,
  object,
  element,
  bool,
  func,
  string,
  oneOfType,
} from "prop-types";
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  styled,
} from "@mui/material";

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
}));

const Accordion = styled((props) => <MuiAccordion {...props} />)(() => ({
  "&.MuiAccordion-root:before": {
    display: "none",
  },
  "&.MuiAccordion-root.Mui-expanded": {
    boxShadow: "none",
    border: "1px solid #D5D9D9",
  },
  "&.MuiPaper-root.MuiAccordion-root": {
    boxShadow: "none",
    border: "1px solid #D5D9D9",
    borderRadius: "4px",
    marginBottom: "16px",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  () => ({
    "& .Mui-expanded .my-ico": {
      transform: "rotate(180deg)",
      color: "#47A06D",
    },
    "&.MuiAccordionSummary-root": {
      height: "50px",
    },
    "&.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded": {
      minHeight: "auto",
    },
  })
);

export const AccordionComponent = ({
  children,
  item,
  expandedDflt = true,
  expandedAcc = null,
  handleChangeExpand = null,
  ...props
}) => {
  const [expanded, setExpanded] = useState(expandedDflt);
  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onChange = handleChangeExpand ? handleChangeExpand : handleChange;

  return (
    <Accordion
      key={item?.id}
      expanded={handleChangeExpand ? expandedAcc === item?.id : !!expanded}
      onChange={onChange(item?.id)}
      {...props}
    >
      {children}
    </Accordion>
  );
};
AccordionComponent.propTypes = {
  children: PropTypes.oneOfType([element, array]),
  item: object,
  expandedDflt: bool,
  expandedAcc: oneOfType([string, bool]),
  handleChangeExpand: func,
};
AccordionComponent.defaultProps = {
  item: {},
};

export const SummaryComponent = ({ children, item, ...props }) => {
  return (
    <AccordionSummary key={item.id} {...props}>
      {children}
    </AccordionSummary>
  );
};
SummaryComponent.propTypes = {
  children: PropTypes.oneOfType([element, array]),
  item: object,
};
SummaryComponent.defaultProps = {
  item: {},
};

export const DetailsComponent = ({ children, ...props }) => {
  return <AccordionDetails {...props}>{children}</AccordionDetails>;
};
DetailsComponent.propTypes = {
  children: PropTypes.oneOfType([element, array]),
};
DetailsComponent.defaultProps = {
  item: {},
};
