import { any } from "prop-types";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { getTokenFromLocalStorage } from "../../helpers/auth";
import Intercom from "@intercom/messenger-js-sdk";
import { useTheme } from "@emotion/react";

const generateIntercomHash = (userId) => {
  const secretKey = process.env.REACT_APP_INTERCOM_APP_SECRET;
  if (!userId || !secretKey) return null;

  const crypto = require("crypto");
  return crypto
    .createHmac("sha256", secretKey)
    .update(userId.toString())
    .digest("hex");
};

const PrivateRoute = ({ children }) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const token = getTokenFromLocalStorage();

  const theme = useTheme();
  const zIndex = theme?.zIndex?.appBar ?? 0;

  if (!currentUser && !token) {
    return <Navigate to="/login" />;
  }

  const appId = process.env.REACT_APP_INTERCOM_APP_ID;

  if (appId) {
    const distributorName = currentUser?.name;
    const repsName = currentUser?.backOfficeRepresentative?.name;
    const distributorEmail = currentUser?.baseUser?.email;
    const repsEmail = currentUser?.backOfficeRepresentative?.baseUser?.email;
    const userHash = generateIntercomHash(currentUser?.id);
    const name =
      repsName && repsEmail && distributorEmail !== repsEmail
        ? `${distributorName}/${repsName}`
        : distributorName;

    Intercom({
      name,
      app_id: appId,
      email: distributorEmail,
      user_id: currentUser?.id,
      user_hash: userHash,
      z_index: zIndex,
    });
  }

  return children;
};

PrivateRoute.propTypes = {
  children: any,
};
PrivateRoute.defaultTypes = {
  children: null,
};

export default PrivateRoute;
