export const cl = {
  styledPopperText: {
    textAlign: "start",
    fontSize: 12,
  },
  logoutLeftBtn: {
    width: "88px",
    height: "28px",
    color: "#1C1C19",
    borderColor: "#D9D9D9",
    fontSize: "13px",
  },
  logoutRightBtn: {
    width: "88px",
    height: "28px",
    color: "#FFFFFF",
    fontSize: "13px",
    boxShadow: "none",
  },
  avatarBlockWrapper: { mt: "36px", pl: "60px", display: "flex" },
  avatar: {
    width: 70,
    height: 70,
  },
  profilePhoto: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  joinedOnText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1C1C19",
    ml: "10px",
    mb: "10px",
  },
  removePhotoBtn: {
    p: 0,
    fontWeight: "400",
    ml: "8px",
  },
  removePhotoLabel: { fontSize: 14 },
  photoHelp: {
    fontSize: "10px",
    fontWeight: "400",
    color: "#707070",
    ml: "8px",
  },
  textInput: {
    fontSize: "12px",
    height: "32px",
  },
  inputLabel: {
    fontSize: "12px",
    "& > .Mui-disabled": {
      color: "red",
      WebkitTextFillColor: "unset",
    },
  },
  leftBoxWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "430px",
    height: "230px",
    pl: "60px",
    mt: "33px",
  },
  rightBoxWrapper: {
    borderLeft: "1px solid #D4D4D4",
    display: "flex",
    flexDirection: "column",
  },
  statusWrapper: {
    pl: "64px",
    pr: "36px",
  },
  statusBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  statusTextWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  statusText: {
    fontSize: "15px",
    fontWeight: "500",
    color: "#707070",
  },
  statusSubText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#707070",
  },
  securityWrapper: { p: "10px 36px 0 64px" },
  arrowForwardIos: {
    width: "14px",
    height: "14px",
    ml: "12px",
    pt: "4px",
  },
};
