import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, ListItemText, MenuItem, Typography } from "@mui/material";
import { handleConfirmAction } from "Pages/DiscountsPage/DiscountsPage.helpers";
import { setConfirmIsOpenAction } from "redux/actions/confirmDialogs";
import {
  bulkAssignCustomersAction,
  removeAllRepsAction,
} from "redux/actions/customers";
import { useNavigate } from "react-router-dom";
import ConfirmNewInstanceText from "./components/TabsContent/TabSortedComponent/ConfirmNewInstanceText";
import { StyledTooltip } from "components";
import {
  ADMIN_ONLY_VIEW_MESSAGE,
  CUSTOMER_STATUS_FILTERS,
} from "utils/constants";
import {
  ArchiveIcon,
  DangerIcon,
  PaymentTermIcon,
  PenIcon,
  TagIcon,
  TaskSmallIcon,
  TrashIcon,
} from "components/Icons";
import { createSelector } from "reselect";
import { territoryListSelector } from "redux/selectors/territory";
import { currentUserSelector } from "redux/selectors/auth";
import {
  GroupsIcon,
  OrdersIcon,
  ReportIcon,
  TerritoryIcon,
  TransferIcon,
} from "components/Icons/MenuIcons";
import { customersStatusFilterSelector } from "redux/selectors/customers";
import { missingInfoRedirect } from "./helpers";
import { usePayTermsActions } from "helpers/usePayTermsActions";
import { useAdmin } from "helpers/helpers.js";

const selector = createSelector(
  territoryListSelector,
  currentUserSelector,
  customersStatusFilterSelector,
  (territoryList, currentUser, customersStatus) => ({
    territoryList,
    currentUser,
    customersStatus,
  })
);

export const useAssignedReps = ({
  checkedCurrent,
  //resetCheckedCustomers,
  //clearShapes,
  //setCheckedGroups,
  queryParams,
  handleFetchParents,
}) => {
  const dispatch = useDispatch();
  const [assignedRepsState, setAssignedRepsState] = useState({
    open: false,
    assignedReps: [],
    customer: null,
  });

  const handleCloseRepsAssignedDrawer = () => {
    setAssignedRepsState({
      open: false,
      assignedReps: [],
      customer: null,
    });
  };

  const handleAssignedRepresentatives = (
    reps,
    callback,
    groupsCallback,
    successParams,
    successGroupParams
  ) => {
    const representativeIds = reps.map((r) => r.id);

    const transferData = {
      customerIds: assignedRepsState?.customer?.id
        ? [assignedRepsState?.customer?.id]
        : checkedCurrent.map((customer) => customer.id),
      representativeIds,
      deleteOld: !!assignedRepsState?.customer?.id,
    };

    dispatch(
      bulkAssignCustomersAction(
        transferData,
        () => {
          handleCloseRepsAssignedDrawer();
          //resetCheckedCustomers();
          //setCheckedGroups([]);
          //clearShapes();
          if (handleFetchParents) handleFetchParents(1);
        },
        queryParams,
        callback,
        groupsCallback,
        successParams,
        successGroupParams
      )
    );
  };

  const handleDeleteAllReps = (callback) => {
    dispatch(
      handleConfirmAction({
        title: "Remove all reps",
        text: (
          <Typography
            fontWeight="400"
            fontSize="13px"
            color="#363531"
            whiteSpace="pre-line"
            marginTop="4px"
            marginBottom="8px"
          >
            Are you sure you want to remove all assigned reps for selected
            customers?
          </Typography>
        ),
        confirmColor: "confirmDelete",
        onConfirm: () => {
          const customerIds = assignedRepsState?.customer?.id
            ? [assignedRepsState?.customer?.id]
            : checkedCurrent.map((customer) => customer.id);

          dispatch(removeAllRepsAction(customerIds, callback));

          dispatch(setConfirmIsOpenAction(false));
          handleCloseRepsAssignedDrawer();
          //resetCheckedCustomers();
          //setCheckedGroups([]);
          //clearShapes();
          if (handleFetchParents) handleFetchParents(1);
        },
      })
    );
  };

  const handleOpenTransfer = () => {
    const getCommonStrings = (arrays) => {
      if (!arrays.length || !arrays) return [];
      const map = new Map();

      arrays?.forEach((array) => {
        array?.forEach((obj) => {
          const id = obj?.representative?.id;
          map.set(id, (map.get(id) || 0) + 1);
        });
      });

      const commonObjects = [];
      arrays?.[0]?.forEach((obj) => {
        if (map.get(obj?.representative?.id) === arrays.length) {
          commonObjects.push(obj);
        }
      });

      return commonObjects.map((obj) => obj?.representative);
    };

    const preparedAssignedReps = () => {
      const assignedRepsArr = checkedCurrent.map(
        (t) => t?.assignedRepresentatives
      );

      return getCommonStrings(assignedRepsArr);
    };

    setAssignedRepsState((prop) => ({
      ...prop,
      open: true,
      assignedReps: preparedAssignedReps(),
    }));
  };

  return {
    assignedRepsState,
    setAssignedRepsState,
    handleCloseRepsAssignedDrawer,
    handleAssignedRepresentatives,
    handleDeleteAllReps,
    handleOpenTransfer,
  };
};

export const useCustomersActionList = ({
  customer,
  repPermissions,
  handleOpenConfirmDialog,
  handleChangeTerritory,
  handleChangeGroup,
  handleChangePaymentTerm,
  handleDeleteCustomers,
  handleArchiveCustomers,
  handleAssignReps,
  handleCustomerTaskDrawer,
  handleAssignTags,
  handleOrders,
  onReport,
  reportData,
  checkedItemsList,
  showInactive,
  setCheckedCustomers,
}) => {
  const isAdmin = useAdmin();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { territoryList, currentUser, customersStatus } = useSelector(selector);

  const hasQuickBooks = !!currentUser?.quickBooksTokens;

  const { list: payTermActiveList } = usePayTermsActions({
    params: {
      status: JSON.stringify(["ACTIVE", "INACTIVE_FOR_CUSTOMERS"]),
      filter_advance_payment: true,
      limit: null,
      page: null,
    },
  });

  const handleGetOrderActions = (
    isTableList = false,
    isGroups = false,
    customerData
  ) => {
    if (!customerData || !Object.keys(customerData).length) return [];

    const status = customerData?.status || customer?.status;
    const isInactive = status === CUSTOMER_STATUS_FILTERS.inactive;

    const isUncategorizedItem = !!customerData?.isUncategorized;

    const disableArchive =
      ((!!customerData?.countPendingOrders ||
        !!customerData?.countSubCustomerPendingOrders) &&
        !isInactive) ||
      ((!!customerData?.countPendingOrders ||
        !!customerData?.countSubCustomerPendingOrders) &&
        hasQuickBooks);

    const navigateParams = {
      id: customerData?.id,
      navigate,
      missingInfo: !!customerData?.missingFields?.length,
      stateProps: { redirectToList: true },
    };

    return [
      {
        label: "Edit customer",
        element: null,
        disabled: repPermissions && !repPermissions?.customers?.create_edit,
        onClick: () => missingInfoRedirect(navigateParams),
        show: !isTableList && !isUncategorizedItem,
        icon: <PenIcon size={10.5} color={"#3F3F3F"} />,
        withDividerAfter: !isTableList && !isUncategorizedItem,
        withDividerBefore: false,
      },
      {
        label: "Assign reps",
        element: null,
        disabled: repPermissions && !repPermissions?.customers?.create_edit,
        icon: <TransferIcon />,
        onClick: () => handleAssignReps(customerData),
        show: !!handleAssignReps && !isTableList && !isUncategorizedItem,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Assign tags",
        element: null,
        disabled: repPermissions && !repPermissions?.customers?.create_edit,
        icon: <TagIcon size={11} />,
        onClick: () => handleAssignTags(customerData),
        show: !!handleAssignTags && !isTableList && !isUncategorizedItem,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Create task",
        element: null,
        disabled:
          (repPermissions && !repPermissions?.tasks?.create_edit) || isAdmin,
        icon: <TaskSmallIcon color="#3f3f3f" width={11} height={11} />,
        onClick: () => {
          handleCustomerTaskDrawer(true, null, customerData);
          setCheckedCustomers([customerData]);
        },
        show:
          !!handleCustomerTaskDrawer && !isTableList && !isUncategorizedItem,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Change group",
        element: null,
        disabled: repPermissions && !repPermissions?.customers?.create_edit,
        disabledPermissions:
          repPermissions && !repPermissions?.customers?.create_edit,
        icon: <GroupsIcon />,
        onClick: () => handleChangeGroup(customerData),
        show:
          customerData?.parentCustomer !== null &&
          !!handleChangeGroup &&
          !isGroups &&
          !isUncategorizedItem,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Change territory",
        element: null,
        disabled: repPermissions && !repPermissions?.customers?.create_edit,
        disabledPermissions:
          repPermissions && !repPermissions?.customers?.create_edit,
        icon: <TerritoryIcon width={11} height={11.5} />,
        onClick: () =>
          handleOpenConfirmDialog({
            title: "Change customer territory?",
            text: (
              <ConfirmNewInstanceText
                item={customerData}
                instance="territory"
                instanceListProp={territoryList}
                handleChangeInstance={handleChangeTerritory}
              />
            ),
            buttons: (
              <>
                <Button
                  sx={{
                    width: "98px",
                    color: "#6A6A6A",
                    borderColor: "#D4D4D4",
                    fontSize: "13px",
                    height: "28px",
                  }}
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: "98px",
                    color: "#FFFFFF",
                    fontSize: "13px",
                    height: "28px",
                    boxShadow: "none",
                  }}
                  color="primary"
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                    handleChangeTerritory(
                      [customerData?.id],
                      customerData?.territory?.id
                    );
                  }}
                  variant="contained"
                >
                  Confirm
                </Button>
              </>
            ),
          }),
        show: !!handleChangeTerritory && !isUncategorizedItem,
        withDividerAfter: false,
        withDividerBefore: false,
      },

      {
        label: "Change payment term",
        element: null,
        icon: <PaymentTermIcon />,
        disabled: repPermissions && !repPermissions?.customers?.create_edit,
        disabledPermissions:
          repPermissions && !repPermissions?.customers?.create_edit,
        onClick: () =>
          handleOpenConfirmDialog({
            title: "Change payment term?",
            text: (
              <ConfirmNewInstanceText
                item={[customerData]}
                instance="payment term"
                instanceListProp={payTermActiveList}
                handleChangeInstance={handleChangePaymentTerm}
              />
            ),
            buttons: (
              <>
                <Button
                  sx={{
                    width: "98px",
                    color: "#6A6A6A",
                    borderColor: "#D4D4D4",
                    fontSize: "13px",
                    height: "28px",
                  }}
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: "98px",
                    color: "#FFFFFF",
                    fontSize: "13px",
                    height: "28px",
                    boxShadow: "none",
                  }}
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                    handleChangePaymentTerm(
                      [customerData],
                      payTermActiveList?.find((term) => term?.defaultTerm)?.id
                    );
                  }}
                  variant="contained"
                >
                  Confirm
                </Button>
              </>
            ),
          }),
        show: !!handleChangePaymentTerm && !isUncategorizedItem,
        withDividerAfter: !!handleChangePaymentTerm && !isUncategorizedItem,
        withDividerBefore: false,
      },
      {
        label: "Orders",
        element: null,
        icon: <OrdersIcon />,
        disabled: repPermissions && !repPermissions?.orders?.create_edit,
        disabledPermissions:
          repPermissions && !repPermissions?.orders?.create_edit,
        onClick: () => handleOrders(customerData),
        show: !!handleOrders && !isUncategorizedItem,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Report",
        element: null,
        icon: <ReportIcon />,
        disabled: repPermissions && !repPermissions?.reports?.view,
        disabledPermissions: repPermissions && !repPermissions?.reports?.view,
        onClick: () =>
          onReport({ ...reportData, customer: customerData }, true),
        show: !!onReport,
        withDividerAfter: !!onReport && !isUncategorizedItem,
        withDividerBefore: false,
      },
      {
        label: "",
        hasTooltip: true,
        element: (
          <StyledTooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>
                Customer has pending orders
              </span>
            }
            arrow
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -10] },
                },
              ],
            }}
            disableHoverListener={!disableArchive}
          >
            <Box>
              <MenuItem
                disabled={disableArchive}
                onClick={() => {
                  const isParent = (customerData) => {
                    // eslint-disable-next-line no-prototype-builtins
                    if (!customerData.hasOwnProperty("parentCustomer"))
                      return true;
                    return customerData?.parentCustomer === null;
                  };

                  const hasParentInSelected = isParent(customerData);

                  handleOpenConfirmDialog({
                    isIcon: hasParentInSelected && !isInactive,
                    title: (
                      <Box components="span" fontSize={18}>
                        {customerData?.status ===
                        CUSTOMER_STATUS_FILTERS.inactive
                          ? "Restore customer?"
                          : "Archive customer?"}
                      </Box>
                    ),
                    text:
                      hasParentInSelected && !isInactive ? (
                        `"${
                          customerData?.displayName || customerData?.name
                        }" has sub-customers. Making this\ncustomer inactive also makes all its sub-customers \ninactive`
                      ) : (
                        <>
                          Are you sure you want to{" "}
                          {isInactive ? "restore" : "archive"}
                          <Box components="span" sx={{ color: "#47A06D" }}>
                            {customerData?.displayName || customerData?.name}
                          </Box>
                        </>
                      ),
                    text2: hasQuickBooks
                      ? "Customers with pending orders can't be archived."
                      : hasParentInSelected &&
                        !isInactive &&
                        "\nDo you want to continue?",
                    buttons: (
                      <>
                        <Button
                          sx={{
                            width: "98px",
                            color: "#6A6A6A",
                            borderColor: "#D4D4D4",
                            fontSize: "13px",
                            height: "28px",
                          }}
                          onClick={() => {
                            dispatch(setConfirmIsOpenAction(false));
                          }}
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                        <Button
                          sx={{
                            width:
                              hasParentInSelected && !isInactive
                                ? "112px"
                                : "98px",
                            color: "#FFFFFF",
                            fontSize: "13px",
                            height: "28px",
                            boxShadow: "none",
                            p: "6px",
                          }}
                          color={
                            hasParentInSelected && !isInactive
                              ? "confirmDelete"
                              : "primary"
                          }
                          onClick={() => {
                            dispatch(setConfirmIsOpenAction(false));
                            handleArchiveCustomers([customerData.id]);
                          }}
                          variant="contained"
                        >
                          {hasParentInSelected && !isInactive
                            ? "Make Inactive"
                            : "Confirm"}
                        </Button>
                      </>
                    ),
                  });
                }}
              >
                {!isTableList && <ArchiveIcon width={11} height={9.3} />}
                <ListItemText>
                  {isInactive ? "Restore customer" : "Archive customer"}
                </ListItemText>
              </MenuItem>
            </Box>
          </StyledTooltip>
        ),
        disabled: false,
        icon: <ArchiveIcon width={11} height={9.3} />,
        onClick: () => {},
        show: !!handleArchiveCustomers && !isUncategorizedItem,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "",
        disabled: repPermissions && !repPermissions?.customers?.delete,
        disabledPermissions:
          repPermissions && !repPermissions?.customers?.delete,
        onClick: () => {
          handleOpenConfirmDialog({
            title: (
              <Box display="flex" alignItems="center">
                <DangerIcon />
                <span style={{ marginLeft: "11px" }}>Delete customer?</span>
              </Box>
            ),
            text: (
              <Typography
                fontWeight="400"
                fontSize="15px"
                whiteSpace="pre-line"
              >
                {"Are you sure you want to delete "}
                <span style={{ color: "#47A06D" }}>{customerData.name}</span>?
                {"\n"}
                <span style={{ marginTop: "3px" }}>
                  {"All data will be erased and this can't be undone."}
                </span>
              </Typography>
            ),
            buttons: (
              <>
                <Button
                  sx={{
                    width: "98px",
                    color: "#6A6A6A",
                    borderColor: "#D4D4D4",
                    fontSize: "13px",
                    height: "28px",
                  }}
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    width: "98px",
                    color: "#FFFFFF",
                    fontSize: "13px",
                    height: "28px",
                    boxShadow: "none",
                  }}
                  color="confirmDelete"
                  onClick={() => {
                    dispatch(setConfirmIsOpenAction(false));
                    handleDeleteCustomers(
                      [customerData?.id],
                      !!customerData?.duplicatedFields?.length
                    );
                  }}
                  variant="contained"
                >
                  Confirm
                </Button>
              </>
            ),
          });
        },
        element: (
          <ListItemText
            classes={{ primary: "deleteItem" }}
            sx={{
              "& > .MuiTypography-root": { color: "#FF6254", ml: "1px" },
            }}
          >
            Delete
          </ListItemText>
        ),
        icon: (
          <TrashIcon
            fill="#FF6254"
            height="10.5"
            width="9"
            style={{ marginTop: "1px" }}
          />
        ),
        show: !hasQuickBooks && !!handleDeleteCustomers && !isUncategorizedItem,
        withDividerAfter: false,
        withDividerBefore: false,
      },
    ];
  };

  const handleOpenConfirm = ({
    isIcon,
    title,
    instanceListProp,
    instance,
    handleChangeInstance,
    item,
    itemAllChecked,
    handleConfirm,
    text,
    text2,
    dataCount,
    handleFetch,
    dataLength,
    confirmBtnColor,
    confirmBtnText,
  }) => {
    handleOpenConfirmDialog({
      isIcon,
      title,
      text2: text2,
      text: text || (
        <ConfirmNewInstanceText
          {...{
            instanceListProp,
            instance,
            handleChangeInstance,
            item,
            itemAllChecked,
            dataCount,
            handleFetch,
            dataLength,
          }}
        />
      ),
      buttons: (
        <>
          <Button
            sx={{
              width: "98px",
              color: "#6A6A6A",
              borderColor: "#D4D4D4",
              fontSize: "13px",
              height: "28px",
            }}
            onClick={() => {
              dispatch(setConfirmIsOpenAction(false));
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            sx={{
              width: confirmBtnText ? "112px" : "98px",
              p: confirmBtnText && "6px",
              color: "#FFFFFF",
              fontSize: "13px",
              height: "28px",
              boxShadow: "none",
            }}
            color={
              confirmBtnColor || confirmBtnText ? "confirmDelete" : "primary"
            }
            onClick={() => {
              dispatch(setConfirmIsOpenAction(false));
              handleConfirm();
            }}
            variant="contained"
          >
            {confirmBtnText || "Confirm"}
          </Button>
        </>
      ),
    });
  };

  const setDisabledArchiveMenuItem = useMemo(() => {
    if (
      checkedItemsList?.some(
        (c) => c?.countPendingOrders > 0 || c?.countSubCustomerPendingOrders > 0
      ) &&
      !showInactive
    )
      return true;
    return !checkedItemsList?.length;
  }, [checkedItemsList, showInactive]);

  const handleMultipleOrderActions = (params, isTableList) => {
    const {
      isGroups,
      checkedGroups,
      checkedCustomers,
      handleOpenTransfer,
      handleFetchParents,
      handleDeleteBulkCustomers,
      handleChangeParentGroup,
    } = params;

    const hasUncategorized = checkedItemsList?.some(
      (customer) => !!customer.isUncategorized
    );
    return [
      {
        label: "Create task",
        element: null,
        disabled:
          (repPermissions && !repPermissions?.tasks?.create_edit) || isAdmin,
        onClick: () => handleCustomerTaskDrawer(true, null),
        show: !isTableList,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Assign reps",
        element: null,
        disabled: !checkedItemsList,
        onClick: handleOpenTransfer,
        show: !isTableList,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Assign tags",
        element: null,
        disabled: repPermissions && !repPermissions?.customers?.customer_tags,
        onClick: handleAssignTags,
        show: !isTableList,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Report",
        element: null,
        disabled: repPermissions && !repPermissions?.reports?.view,
        disabledPermissions: repPermissions && !repPermissions?.reports?.view,
        onClick: () =>
          onReport(
            {
              ...reportData,
              customer: isGroups ? checkedGroups : checkedCustomers,
            },
            true
          ),
        show: true,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Change group",
        element: null,
        disabled: isGroups
          ? true
          : repPermissions && !repPermissions?.customers?.create_edit,
        disabledPermissions:
          repPermissions && !repPermissions?.customers?.create_edit,
        onClick: () => handleChangeParentGroup(),
        show: !isGroups,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Change territory",
        element: null,
        disabled: isGroups
          ? !checkedGroups.length
          : repPermissions && !repPermissions?.customers?.create_edit,
        disabledPermissions:
          repPermissions && !repPermissions?.customers?.create_edit,
        onClick: () => {
          handleOpenConfirm({
            title: "Change customer(s) territory?",
            instanceListProp: territoryList,
            instance: "territory",
            handleChangeInstance: (checkedItems, territoryId) => {
              handleChangeTerritory(
                isGroups
                  ? checkedGroups.map((c) => c.id)
                  : checkedCustomers.map((c) => c.id),
                territoryId
              );
            },
            item: isGroups ? checkedGroups : checkedCustomers,
          });
        },
        show: true,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Change payment term",
        element: null,
        disabled: isGroups
          ? !checkedGroups.length
          : repPermissions && !repPermissions?.customers?.create_edit,
        disabledPermissions:
          repPermissions && !repPermissions?.customers?.create_edit,
        onClick: () =>
          handleOpenConfirm({
            title: "Change payment term(s)?",
            instance: "payment term",

            instanceListProp: payTermActiveList,
            handleChangeInstance: handleChangePaymentTerm,
            item: isGroups ? checkedGroups : checkedCustomers,

            handleConfirm: () =>
              handleChangePaymentTerm(
                isGroups ? checkedGroups : checkedCustomers,
                payTermActiveList?.find((term) => term?.defaultTerm)?.id || ""
              ),
          }),

        show: true,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "",
        hasTooltip: true,
        element: (
          <StyledTooltip
            title={
              <span style={{ whiteSpace: "pre-wrap" }}>
                One or more customers have pending orders
              </span>
            }
            arrow
            placement="top"
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: { offset: [0, -10] },
                },
              ],
            }}
            disableHoverListener={!setDisabledArchiveMenuItem}
          >
            <Box>
              <MenuItem
                value="Archive customers"
                disabled={setDisabledArchiveMenuItem}
                onClick={() => {
                  const isParent = (item) => item?.parentCustomer === null;

                  const hasParentInSelected = isGroups
                    ? true
                    : checkedCustomers.some((c) => isParent(c));

                  handleOpenConfirm({
                    isIcon: hasParentInSelected && !showInactive,
                    title: (
                      <Box component="span" fontSize={18}>
                        {(
                          isGroups
                            ? showInactive
                            : customersStatus ===
                              CUSTOMER_STATUS_FILTERS.inactive
                        )
                          ? "Restore customer(s)?"
                          : "Archive customer(s)?"}
                      </Box>
                    ),
                    text:
                      hasParentInSelected &&
                      customersStatus !== CUSTOMER_STATUS_FILTERS.inactive
                        ? "Some customers have sub-customers. Making this\ncustomer inactive also makes all its sub-customers \ninactive"
                        : `Are you sure you want to ${
                            (
                              isGroups
                                ? showInactive
                                : customersStatus ===
                                  CUSTOMER_STATUS_FILTERS.inactive
                            )
                              ? "restore"
                              : "archive"
                          } customer(s)`,
                    text2: currentUser.quickBooksTokens
                      ? "\nCustomers with pending orders can't be archived."
                      : hasParentInSelected &&
                        customersStatus !== CUSTOMER_STATUS_FILTERS.inactive &&
                        "\nDo you want to continue?",
                    confirmBtnColor:
                      hasParentInSelected &&
                      customersStatus === CUSTOMER_STATUS_FILTERS.inactive &&
                      "confirmDelete",
                    confirmBtnText:
                      hasParentInSelected &&
                      customersStatus !== CUSTOMER_STATUS_FILTERS.inactive &&
                      "Make Inactive",
                    handleConfirm: () => handleArchiveCustomers(),
                  });
                }}
              >
                {(
                  isGroups
                    ? showInactive
                    : customersStatus === CUSTOMER_STATUS_FILTERS.inactive
                )
                  ? "Restore customers"
                  : "Archive customers"}
              </MenuItem>
            </Box>
          </StyledTooltip>
        ),
        disabled: setDisabledArchiveMenuItem,
        onClick: () => {},
        show: true,
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Delete",
        sx: { color: "#FF6254" },
        element: null,
        disabled: repPermissions && !repPermissions?.customers?.delete,
        disabledPermissions:
          repPermissions && !repPermissions?.customers?.delete,
        onClick: () =>
          handleOpenConfirm({
            title: (
              <Box display="flex" alignItems="center">
                <DangerIcon />
                <span style={{ marginLeft: "11px" }}>
                  Delete {isGroups ? "group(s)" : "customer(s)"}?
                </span>
              </Box>
            ),
            text: `Please confirm that you would like to delete selected ${
              isGroups ? "group(s)" : "customer(s)"
            }. All data will be erased and this can’t be undone.`,
            handleConfirm: () =>
              handleDeleteBulkCustomers(false, false, handleFetchParents),
          }),
        show: !hasQuickBooks && !hasUncategorized,
        withDividerAfter: false,
        withDividerBefore: false,
      },
    ];
  };

  const handleGetActions = (isTableList, params) => {
    if (!checkedItemsList.length) return [];

    if (checkedItemsList.length === 1)
      return handleGetOrderActions(isTableList, params.isGroups, customer);
    return handleMultipleOrderActions(params, isTableList);
  };

  const getAdditionalActions = (
    isGroups,
    handleCreateRoute,
    handleOpenTransfer
  ) => {
    const navigateParams = {
      id: checkedItemsList?.[0]?.id,
      navigate,
      missingInfo: !!checkedItemsList?.[0]?.missingFields?.length,
      stateProps: { redirectToList: true },
    };

    return [
      {
        label: "Edit",
        disabled: repPermissions
          ? !repPermissions?.customers?.create_edit
          : false,
        onClick: () => missingInfoRedirect(navigateParams),
        show: checkedItemsList.length === 1,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions
            ? !repPermissions?.customers?.create_edit
            : false,
          props: { isDark: true },
        },
      },
      {
        label: "Create Route",
        disabled: repPermissions ? !repPermissions?.routes?.create_edit : false,
        onClick: handleCreateRoute,
        show: !isGroups,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions ? !repPermissions?.routes?.create_edit : false,
          props: { isDark: true },
        },
      },
      {
        label: "Assign reps",
        element: null,
        disabled: repPermissions
          ? !repPermissions?.customers?.create_edit
          : !checkedItemsList,
        onClick: () =>
          checkedItemsList.length > 1
            ? handleOpenTransfer()
            : handleAssignReps(checkedItemsList[0]),
        show: !!handleAssignReps,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions
            ? !repPermissions?.customers?.create_edit
            : false,
          props: { isDark: true },
        },
        withDividerAfter: false,
        withDividerBefore: false,
      },
      {
        label: "Assign tags",
        element: null,
        disabled: repPermissions && !repPermissions?.customers?.create_edit,
        onClick: () =>
          checkedItemsList.length > 1
            ? handleAssignTags()
            : handleAssignTags(checkedItemsList[0]),
        show: !!handleAssignTags,
        withDividerAfter: false,
        withDividerBefore: false,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions && !repPermissions?.customers?.create_edit,
          props: { isDark: true },
        },
      },
      {
        label: "Create task",
        element: null,
        disabled:
          (repPermissions && !repPermissions?.tasks?.create_edit) || isAdmin,
        onClick: () =>
          checkedItemsList.length > 1
            ? handleCustomerTaskDrawer(true, null)
            : handleCustomerTaskDrawer(true, null, checkedItemsList[0]),
        show: !!handleCustomerTaskDrawer,
        withDividerAfter: false,
        withDividerBefore: false,
        tooltip: {
          title: ADMIN_ONLY_VIEW_MESSAGE,
          show: repPermissions && !repPermissions?.customers?.customer_tags,
          props: { isDark: true },
        },
      },
    ].filter((action) => action.show);
  };

  return {
    handleGetOrderActions,
    handleGetActions,
    getAdditionalActions,
  };
};
