export const cl = {
  dialog: {
    ".MuiPaper-root": {
      borderRadius: "18px",
      boxShadow: "none",
      width: "100%",
      maxWidth: "742px",
    },
  },
  content: {
    background: "white",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  title: {
    fontWeight: "bold",
    fontSize: "32px",
    color: "#5F6267",
    marginTop: "20px",
  },
  subtitle: {
    fontSize: "16px",
    margin: "16px",
    textAlign: "center",
    width: "500px",
    color: "#1C1C19",
  },
  iconWrapper: { maxWidth: "142px" },
  icon: { marginTop: "20px", width: "100%", height: "100%" },
  btnWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    py: "66px",
  },
  btn: {
    width: "180px",
    height: "51px",
    fontSize: 18,
    borderRadius: "8px",
  },
};
