import { SvgIcon } from "@mui/material";

// eslint-disable-next-line react/prop-types
export const TrashIcon = ({ ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fill: "none", width: 22, height: 22 }}
      {...props}
    >
      <path
        d="M4 6v-.75a.75.75 0 0 0-.75.75zm14 0h.75a.75.75 0 0 0-.75-.75zM7 6h-.75c0 .414.336.75.75.75zm8 0v.75a.75.75 0 0 0 .75-.75zM1 5.25a.75.75 0 0 0 0 1.5zm20 1.5a.75.75 0 0 0 0-1.5zM9.75 10a.75.75 0 0 0-1.5 0zm-1.5 6a.75.75 0 0 0 1.5 0zm5.5-6a.75.75 0 0 0-1.5 0zm-1.5 6a.75.75 0 0 0 1.5 0zM4 6.75h14v-1.5H4zM17.25 6v9h1.5V6zM12 20.25h-2v1.5h2zM4.75 15V6h-1.5v9zM10 20.25c-1.435 0-2.437-.002-3.192-.103-.734-.099-1.122-.28-1.399-.556l-1.06 1.06c.601.603 1.36.861 2.26.983.878.118 1.998.116 3.391.116zM3.25 15c0 1.393-.002 2.513.117 3.392.12.9.38 1.658.981 2.26L5.41 19.59c-.277-.277-.457-.665-.556-1.4-.101-.755-.103-1.756-.103-3.191zm14 0c0 1.435-.002 2.436-.103 3.192-.099.734-.28 1.122-.556 1.399l1.06 1.06c.603-.601.861-1.36.983-2.26.118-.878.116-1.998.116-3.391zM12 21.75c1.393 0 2.513.002 3.392-.116.9-.122 1.658-.38 2.26-.982L16.59 19.59c-.277.277-.665.457-1.4.556-.755.101-1.756.103-3.191.103zm-5-15h8v-1.5H7zM15.75 6V5h-1.5v1zm-9.5-1v1h1.5V5zM11 .25A4.75 4.75 0 0 0 6.25 5h1.5A3.25 3.25 0 0 1 11 1.75zM15.75 5A4.75 4.75 0 0 0 11 .25v1.5A3.25 3.25 0 0 1 14.25 5zM1 6.75h20v-1.5H1zM8.25 10v6h1.5v-6zm4 0v6h1.5v-6z"
        fill="#5F6267"
      />
    </SvgIcon>
  );
};
