import { useMemo, useRef, useState } from "react";
import { bool, func, number, object, string } from "prop-types";
import { StyledButton, StyledTextField, StyledTooltip } from "components";
import { Box } from "@mui/material";
import { cl } from "./MsdQtyComponent.styles";
import { TrashTaskIcon } from "components/Icons";

export const MsdQtyComponent = ({
  value = 1,
  minValue = 0,
  maxValue = 100,
  sx = {},
  error = null,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  handleDecreaseQty = () => {},
  handleIncreaseQty = () => {},
  disabledLeftBtn = null,
  disabledRightBtn = null,
  onChange = () => {},
  discount,
  handleDeleteDiscount = null,
  // isShowLeftTooltip = true,
  disabledTextField = true,
  ...props
}) => {
  const minusRef = useRef();

  const [count, setCount] = useState(value);

  const isShowDeleteBtn = useMemo(
    () => handleDeleteDiscount && count === minValue,
    [count, handleDeleteDiscount, minValue]
  );

  return (
    <Box sx={{ width: "80px", ...sx }}>
      <StyledTextField
        value={count}
        size="small"
        disabled={disabledTextField}
        InputProps={{
          startAdornment: (
            // <StyledTooltip
            //   arrow
            //   placement="top"
            //   title={
            //     <Box sx={{ textAlign: "center" }}>
            //       Adjusting the quantity
            //       <br />
            //       will remove
            //       <br />
            //       the free discounts items
            //       <br />
            //       from the list
            //     </Box>
            //   }
            //   PopperProps={{
            //     modifiers: [
            //       {
            //         name: "offset",
            //         options: { offset: [0, -5] },
            //       },
            //     ],
            //   }}
            //   disableHoverListener={
            //     !(disabledLeftBtn || count === minValue) ||
            //     !isShowLeftTooltip ||
            //     count === 1
            //   }
            //   disableFocusListener={
            //     !(disabledLeftBtn || count === minValue) ||
            //     !isShowLeftTooltip ||
            //     count === 1
            //   }
            // >
            <Box
              ref={minusRef}
              onMouseEnter={onMouseEnter}
              height="100%"
              onMouseLeave={onMouseLeave}
            >
              <StyledButton
                startIcon={
                  isShowDeleteBtn ? (
                    <TrashTaskIcon width={15} height={15} />
                  ) : null
                }
                label={isShowDeleteBtn ? null : "–"}
                disabled={
                  !!disabledLeftBtn || (count <= minValue && !isShowDeleteBtn)
                }
                sx={cl.startAdornment}
                color="edit"
                onClick={() => {
                  !!handleDecreaseQty && handleDecreaseQty();
                  if (isShowDeleteBtn) return handleDeleteDiscount(discount);

                  if (1 < count) {
                    const newVal = +count - 1;
                    setCount(newVal);
                    !!onChange && onChange({ discount, value: newVal });
                  }
                }}
                variant="outlined"
              />
            </Box>
            // </StyledTooltip>
          ),
          endAdornment: (
            <StyledTooltip
              arrow
              placement="top"
              title={
                disabledRightBtn?.title ? (
                  disabledRightBtn.title
                ) : (
                  <Box sx={{ textAlign: "center" }}>
                    Discount can&apos;t be multiplied.
                    <br />
                    Requirements not met
                  </Box>
                )
              }
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: { offset: [0, -5] },
                  },
                ],
              }}
              disableHoverListener={!(disabledRightBtn || count === maxValue)}
              disableFocusListener={!(disabledRightBtn || count === maxValue)}
            >
              <Box sx={{ height: "100%" }}>
                <StyledButton
                  label="+"
                  disabled={!!disabledRightBtn || count === maxValue}
                  sx={cl.endAdornment}
                  color="edit"
                  onClick={() => {
                    !!handleIncreaseQty && handleIncreaseQty();
                    if (maxValue > count) {
                      const newVal = +count + 1;
                      setCount(newVal);
                      !!onChange && onChange({ discount, value: newVal });
                    }
                  }}
                  variant="outlined"
                />
              </Box>
            </StyledTooltip>
          ),
          sx: cl.inputPropsSx({ error }),
        }}
        sx={{ borderColor: "#707070" }}
        error={error ? " " : ""}
        {...props}
        onChange={({ target: { value } }) => {
          if (/^\d*$/.test(value) && +value >= minValue && +value <= maxValue) {
            setCount(value ? +value : minValue);
            !!onChange && onChange({ discount, value: +value });
          }
        }}
      />
    </Box>
  );
};

MsdQtyComponent.propTypes = {
  value: number,
  minValue: number,
  maxValue: number,
  sx: object,
  error: string,
  onMouseEnter: func,
  onMouseLeave: func,
  handleDecreaseQty: func,
  handleIncreaseQty: func,
  disabledLeftBtn: object,
  disabledRightBtn: object,
  onChange: func,
  discount: object,
  handleDeleteDiscount: func,
  isShowLeftTooltip: bool,
  disabledTextField: bool,
};
