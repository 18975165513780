import { SvgIcon } from "@mui/material";

// eslint-disable-next-line react/prop-types
export const NoteIcon = ({ color = "#A5A5A5", size = 12, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ fill: "none", width: size, height: size }}
      {...props}
    >
      <path
        d="M9.8 17v-.8c0-3.017 0-4.525.937-5.463S13.183 9.8 16.2 9.8h.8M7.4 1h3.2c3.017 0 4.526 0 5.463.937S17 4.383 17 7.4v.326c0 1.415 0 2.123-.28 2.748-.282.625-.81 1.095-1.868 2.035l-3.24 2.88c-.893.794-1.339 1.19-1.883 1.399l-.017.006C9.167 17 8.57 17 7.376 17c-2.994 0-4.49 0-5.425-.924l-.027-.027C1 15.114 1 13.618 1 10.624V7.4c0-3.017 0-4.525.937-5.463S4.383 1 7.4 1Z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
