import { useCallback, useRef } from "react";
import { func, object, number } from "prop-types";
import { Controller } from "react-hook-form";
import { Box } from "@mui/material";
import { CloneProps, StyledTextField, StyledTooltip } from "components";
import { MAX_CHARACTERS_OPTIONS_TITLE } from "Pages/FormViewPage/FormViewPage.constants";
import { onPasteTextWithMaxLength } from "helpers/helpers";
import { cl } from "./Input.styles";

export const Input = ({
  handleUpdateOption = () => {},
  item = {},
  control = {},
  orderIndex = 0,
  trigger = () => {},
}) => {
  const errorMsgRef = useRef({});

  const handleOnChange = useCallback(
    (e, field) => {
      const val = e.target.value;
      if (val?.length <= MAX_CHARACTERS_OPTIONS_TITLE + 1) {
        field.onChange(val);
        trigger(`questions[${orderIndex}].options`, { shouldFocus: false });
      }
    },
    [trigger, orderIndex]
  );

  const handleOnPaste = useCallback(
    (e, field) => {
      onPasteTextWithMaxLength({
        event: e,
        value: field.value,
        onChange: (v) => {
          field.onChange(v);
          trigger(`questions[${orderIndex}].options`);
        },
        maxCharacters: MAX_CHARACTERS_OPTIONS_TITLE,
      });
    },
    [orderIndex, trigger]
  );

  return (
    <Controller
      render={({ field, fieldState: { error } }) => {
        if (error?.message)
          errorMsgRef.current[
            `questions[${orderIndex}].options[${item?.orderIndex}].text`
          ] = error?.message;

        return (
          <CloneProps>
            {(clonedProps) => (
              <StyledTooltip
                arrow
                placement="left"
                isError
                title={
                  errorMsgRef.current?.[
                    `questions[${orderIndex}].options[${item?.orderIndex}].text`
                  ] || ""
                }
                open={!!error}
                PopperProps={{
                  disablePortal: true,
                  modifiers: [{ name: "offset", options: { offset: [0, -5] } }],
                }}
              >
                <Box component="span" width="100%">
                  <StyledTextField
                    {...clonedProps}
                    fullWidth={true}
                    placeholder="Enter the answer option"
                    InputProps={cl.inputProps({ item, error })}
                    error={!!error}
                    {...field}
                    onChange={(e) => {
                      handleOnChange(e, field);
                    }}
                    onPaste={(e) => handleOnPaste(e, field)}
                    onBlur={handleUpdateOption}
                  />
                </Box>
              </StyledTooltip>
            )}
          </CloneProps>
        );
      }}
      name={`questions[${orderIndex}].options[${item?.orderIndex}].title`}
      control={control}
    />
  );
};

Input.propTypes = {
  handleUpdateOption: func.isRequired,
  item: object.isRequired,
  control: object.isRequired,
  orderIndex: number.isRequired,
  trigger: func.isRequired,
};
