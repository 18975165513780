export const SET_TRIAL_DIALOG_OPEN = "SET_TRIAL_DIALOG_OPEN";
export const SET_TRIAL_DIALOG_CLOSE = "SET_TRIAL_DIALOG_CLOSE";
export const SET_PAYMENT_FAILED_DIALOG_OPEN = "SET_PAYMENT_FAILED_DIALOG_OPEN";
export const SET_PAYMENT_FAILED_DIALOG_CLOSE =
  "SET_PAYMENT_FAILED_DIALOG_CLOSE";

export const setTrialDialogOpen = (payload) => ({
  type: SET_TRIAL_DIALOG_OPEN,
  payload,
});
export const setTrialDialogClose = () => ({
  type: SET_TRIAL_DIALOG_CLOSE,
});

export const setPaymentFailedDialogOpenAction = () => ({
  type: SET_PAYMENT_FAILED_DIALOG_OPEN,
});
export const setPaymentFailedDialogCloseAction = () => ({
  type: SET_PAYMENT_FAILED_DIALOG_CLOSE,
});

const handleToggleTrialDialog = ({
  distributorSubscription,
  trialDialogState,
  subscriptionUsageStatus,
  delayedSubscriptionDraft,
}) => {
  if (
    (distributorSubscription?.stripeSubscriptionStatus === "incomplete" ||
      distributorSubscription?.stripeSubscriptionStatus ===
        "incomplete_expired") &&
    distributorSubscription?.failureMessage !== null &&
    trialDialogState?.incomplete
  )
    return true;

  if (trialDialogState) return false;

  if (
    subscriptionUsageStatus === "TRIAL_USED" &&
    distributorSubscription?.stripeSubscriptionStatus === "canceled"
  )
    return true;

  return (
    subscriptionUsageStatus === "TRIAL_USED" &&
    distributorSubscription === null &&
    !delayedSubscriptionDraft
  );
};

export const toggleTrialDialogOpenAction = () => {
  return (dispatch, getState) => {
    const {
      auth: { currentUser },
    } = getState();

    const {
      distributorSubscription,
      trialDialogState,
      subscriptionUsageStatus,
      delayedSubscriptionDraft,
    } = currentUser || {};

    const isOpenTrialDialog = handleToggleTrialDialog({
      distributorSubscription,
      trialDialogState,
      subscriptionUsageStatus,
      delayedSubscriptionDraft,
    });

    dispatch(isOpenTrialDialog ? setTrialDialogOpen() : setTrialDialogClose());
  };
};
