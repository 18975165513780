import { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { errorConfig } from "../../utils/notifications.js";
import { toast } from "react-toastify";
import { Box, Button, Grid, Link, Paper, Typography } from "@mui/material";
import {
  setCurrentUser,
  setRepresentativeUser,
  updateUserFieldAction,
} from "../../redux/actions/auth";
import { loginUser } from "../../services/account";
import {
  setTokenToLocalStorage,
  setTokenToAxios,
  setUserRoleToLocalStorage,
} from "../../helpers/auth";
import { LargeCheckbox } from "../../components/Checkboxes";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchema } from "./LoginPage.validation.js";
import { StyledTextField } from "../../components/TextFields/TextFields.jsx";
import { ActiveIcon } from "../../components/Icons";
import useStyles from "./styles";
import { MainLogoIcon } from "../../components/Icons/MainLogos";

const LoginPage = () => {
  const classes = useStyles();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      password: "",
      isRememberMe: true,
    },
    resolver: yupResolver(validationSchema()),
  });

  const formField = useWatch({ control });

  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const getUserRole = () => JSON.parse(localStorage.getItem("userRole"));

  // useLayoutEffect(() => {
  //   if (distributorId && getUserRole() === "SUPER_ADMIN") {
  //     removeAllTokens();
  //     localStorage.clear();
  //     dispatch(deleteCurrentUser());
  //   }
  // }, [dispatch, distributorId, navigate]);

  const [isLoading, setIsLoading] = useState(false);
  // const [emailLoading, setEmailLoading] = useState(false);
  // const [exists, setExists] = useState(false);

  // const emailDebounced = useDebounce(formField.email.toLowerCase(), 500);

  // useEffect(() => {
  //   if (emailDebounced) {
  //     if (!errors.email) {
  //       setEmailLoading(true);
  //       checkEmailService(emailDebounced)
  //         .then((res) => {
  //           setEmailLoading(false);
  //           if (res.exists) return setExists(true);
  //           setEmailLoading(false);
  //           setExists(false);
  //         })
  //         .catch(() => {
  //           setExists(false);
  //           setEmailLoading(false);
  //         });
  //     }
  //   }
  // }, [emailDebounced, errors.email]);

  useEffect(() => {
    if (currentUser) navigate("/");
  }, [currentUser, navigate]);

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        let res;

        // if (distributorId) {
        //   res = await loginSuperAdminAsDistributorService({
        //     ...data,
        //     distributorId,
        //   });
        //   setUserRoleToLocalStorage("SUPER_ADMIN");
        // } else {
        res = await loginUser(data);

        if (res?.distributor?.id) setUserRoleToLocalStorage("DISTRIBUTOR");

        if (res?.representative?.id) {
          setUserRoleToLocalStorage("REPRESENTATIVE");
        }

        // }
        setTokenToLocalStorage(res?.token);
        setTokenToAxios(res?.token);

        dispatch(setCurrentUser(res?.distributor));

        if (res?.representative) {
          dispatch(setRepresentativeUser(res.representative));
          dispatch(
            updateUserFieldAction(
              "backOfficeRepresentative",
              res.representative
            )
          );
        }

        setIsLoading(false);
        navigate("/");
      } catch (err) {
        setIsLoading(false);
        const message = err?.response?.data?.message;
        if (err?.response?.status === 401) {
          setError("email", {
            type: "customEmail",
            message: "Wrong email or password, please try again",
          });
          setError("password", {
            type: "customPassword",
            message: "Wrong email or password, please try again",
          });

          toast.error("Wrong email or password, please try again", errorConfig);
        } else {
          toast.error(message || err?.message);
        }
      }
    },
    [dispatch, navigate, setError]
  );

  const emailAdornment = useMemo(() => {
    if (formField.email.length > 0 && !errors.email) return <ActiveIcon />;
  }, [formField, errors]);

  return (
    <form
      id="login-form"
      onSubmit={handleSubmit(onSubmit)}
      className={classes.background}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item>
          <Paper className={classes.mainBlock} elevation={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                py: "27px",
              }}
            >
              <MainLogoIcon width={307} height={45} />
            </Box>

            <Typography
              variant="h6"
              textAlign="center"
              color="#5F6267"
              mb={2}
              mt="50px"
              fontSize="25px"
            >
              Login
            </Typography>

            <>
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    fullWidth
                    formSx={{ mb: "10px" }}
                    margin="normal"
                    placeholder="Email"
                    size="medium"
                    error={
                      error
                        ? error.message
                        : // : !exists
                          // ? "This email is not registered."
                          ""
                    }
                    InputProps={{
                      className: classes.input,
                      endAdornment: emailAdornment,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    {...field}
                  />
                )}
                name="email"
                control={control}
              />
              <Controller
                render={({ field, fieldState: { error } }) => (
                  <StyledTextField
                    fullWidth
                    margin="normal"
                    id="password-input"
                    placeholder="Password"
                    type="password"
                    autoComplete="on"
                    size="medium"
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      className: classes.inputLabel,
                    }}
                    error={error ? error.message : ""}
                    {...field}
                  />
                )}
                name="password"
                control={control}
              />
              <Grid container justifyContent="space-between" mt="22px">
                <Grid item>
                  <Controller
                    name="isRememberMe"
                    control={control}
                    render={({ field }) => (
                      <LargeCheckbox
                        stroke="#CFCEC9"
                        fill="#ffffff"
                        formSx={{ ml: "-9px" }}
                        onChange={(e) => field.onChange(e.target.checked)}
                        label={
                          <Typography
                            sx={{
                              fontSize: "19px",
                              ml: "8px",
                              color: "#5F6267",
                            }}
                          >
                            Remember me
                          </Typography>
                        }
                        checked={!!field.value}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid sx={12} item>
                  {errors?.email || errors?.password && }
                </Grid> */}
                <Grid item sx={{ m: "auto 0" }}>
                  <Link underline="hover" color="inherit" href="/reset">
                    <Typography fontSize={19} color="#5F6267">
                      Forgot password?
                    </Typography>
                  </Link>
                </Grid>
              </Grid>

              <Button
                disabled={isLoading}
                className={classes.loginButton}
                sx={{
                  marginTop: 3,
                }}
                variant="contained"
                fullWidth
                type="submit"
                color="primary2"
                form="login-form"
              >
                {isLoading ? "Loading..." : "Log in"}
              </Button>
            </>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

export default LoginPage;
