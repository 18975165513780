import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { CheckCircle, Download, Phone } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Link,
  Typography,
  CircularProgress,
  Grid,
  Menu,
  MenuList,
  Button,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { CrossIcon, MailIcon, PrinterIcon, StoreIcon } from "components/Icons";
import { MainLogoIcon } from "components/Icons/MainLogos";
import {
  getFormattedDate,
  separateNumWithComma,
  setPhoneNumberMask,
  setWebsiteMask,
} from "helpers/helpers";
import { defaultValues } from "./PaymentPage.constants";
import { validationSchema } from "./PaymentPage.validations";
import { Loader, StyledButton } from "components";
import {
  ActionsBlock,
  DistributorNameBlock,
  InfoBlock,
  PaymentReceivedBlock,
  ProfilePhotoBlock,
  StripeElement,
} from "./components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { error } from "utils/notifications";
import {
  getPaidPaymentIntentClientDataService,
  getPaymentIntentClientDataService,
} from "services/orders";
import pluralize from "pluralize";
import moment from "moment";
import { capitalize } from "lodash";
import { usePaymentReceived } from "./components/PaymentReceivedBlock/usePaymentReceived";
import { useBreakpoint } from "helpers/useBreakpoint";

const PaymentPage = () => {
  const breakpoint = useBreakpoint();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();

  // eslint-disable-next-line no-undef
  const CUSTOMER_APP = process.env.REACT_APP_CUSTOMER_APP;

  const [loading, setLoading] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingStripeElement, setLoadingStripeElement] = useState(true);

  const [isPaymentHasBeenReceived, setPaymentHasBeenReceived] = useState(false);

  const { control, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: { ...defaultValues },
    resolver: yupResolver(validationSchema()),
  });
  const formField = useWatch({ control });

  const { distributor, stripeData, order } = formField || {};
  const {
    profilePhoto,
    name: distributorName,
    storeName: distributorStoreName,
    baseUser: distributorBaseUser,
    phone: distributorPhone,
    website: distributorWebsite,
    billingAddress,
    timeZone: distributorTimeZone,
    brandLogo: distributorBrandLogo,
  } = distributor || {};

  const showLoginBtn = !!distributorBrandLogo?.id;

  const {
    customId,
    balance,
    totalQuantity,
    paymentTermsDuplicate,
    orderPayments,
    totalAmount,
  } = order || {};

  const { clientSecret, options } = stripeData || {};

  const { loading: loadingReport, handleDownloadReport } = usePaymentReceived({
    token,
  });

  const [anchorContactInfo, setAnchorContactInfo] = useState(null);
  const openContactInfoList = Boolean(anchorContactInfo);
  const handleOpenContactInfoBtn = (e) => {
    e.stopPropagation();
    setAnchorContactInfo(e.currentTarget);
  };

  const handleCloseContactInfoBtn = () => {
    setAnchorContactInfo(null);
  };

  const { domainName } = useMemo(() => {
    if (!distributorWebsite) return "-";
    return setWebsiteMask(distributorWebsite);
  }, [distributorWebsite]);

  const dueDate = moment(
    moment(getFormattedDate(order?.createdAt, distributorTimeZone)).format(
      "MMM D, YY"
    )
  )
    .add(paymentTermsDuplicate?.daysInvoices, "days")
    .format("MMM D, YYYY");

  const invoiceNumber = useMemo(() => customId?.customId, [customId?.customId]);

  const hasOrderAlreadyPaid = useMemo(() => {
    return clientSecret === null && balance === 0;
  }, [balance, clientSecret]);

  const [paymentHasBeenReceivedData, setPaymentHasBeenReceivedData] =
    useState(null);

  useEffect(() => {
    if (hasOrderAlreadyPaid) {
      const preparedPaymentMethod = () => {
        if (orderPayments?.length === 1) {
          const orderPayment = orderPayments[0];

          if (orderPayment?.paymentType === "CREDIT_CARD") {
            return `${capitalize(orderPayment?.creditCard?.brand) || "-"}**${
              orderPayment?.creditCard?.last4 || "-"
            }`;
          }
          if (orderPayment?.paymentType === "CASH") return "Cash";
          if (orderPayment?.paymentType === "CHECK") return "Check";
        }

        if (orderPayments?.length > 1) return "mixed";

        return false;
      };

      setPaymentHasBeenReceived(true);

      const preparedData = [
        { title: "Paid to", value: distributorStoreName },
        { title: "Customer name", value: distributorName },
        { title: "Customer email", value: distributorBaseUser?.email },
        { title: "Invoice #", value: invoiceNumber },
        {
          title: "Paid date",
          value: moment(
            orderPayments?.[orderPayments?.length - 1]?.date
          ).format("MMM DD, YYYY"),
        },
        {
          title: "Payment method",
          value: preparedPaymentMethod(),
        },
        {
          title: "Invoice amount",
          value: `$${separateNumWithComma(totalAmount)}`,
        },
      ];

      setPaymentHasBeenReceivedData(
        preparedPaymentMethod()
          ? preparedData
          : preparedData.filter((item) => item?.title !== "Payment method")
      );
    }
  }, [
    balance,
    distributorBaseUser?.email,
    distributorName,
    distributorStoreName,
    hasOrderAlreadyPaid,
    invoiceNumber,
    orderPayments,
    orderPayments?.creditCard,
    orderPayments?.length,
    totalAmount,
  ]);

  const preparedAddress = (address) => {
    if (!address) return;

    const preparedData = address.split(", ");
    const firstLine = preparedData?.length ? preparedData.splice(0, 1) : "";
    const secondLine = preparedData.join(", ") || "";
    return (
      <>
        <Box component="span">{firstLine}</Box>
        <br />
        <Box component="span">{secondLine}</Box>
      </>
    );
  };

  const getPaymentIntentClientData = useCallback(
    async (token) => {
      if (!token) return error("Something went wrong!");

      try {
        const { distributor, stripeData, order } =
          await getPaymentIntentClientDataService(token);

        setValue("distributor", distributor);
        setValue("stripeData", stripeData);
        setValue("order", order);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        navigate("/status");
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setLoading(false);
      }
    },
    [setValue, navigate]
  );

  useEffect(() => {
    getPaymentIntentClientData(token);
  }, [getPaymentIntentClientData, token]);

  const [paymentError, setPaymentError] = useState(null);

  const onSubmit = async (e, data) => {
    e.preventDefault();
    setPaymentError(null);

    const { stripe, elements } = data || {};

    if (!stripe || !elements) return;

    setLoadingPayment(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setLoadingPayment(false);
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/payment`,
      },
      redirect: "if_required",
    });

    if (result?.error) {
      if (result?.error?.type === "validation_error") return;

      setPaymentError(result?.error);
      setLoadingPayment(false);
      return;
    }

    const { paymentIntent } = result || {};
    const { id } = paymentIntent || {};

    try {
      const paymentData = await getPaidPaymentIntentClientDataService({
        token,
        payment_intent_id: id,
      });

      const { paymentCard } = paymentData || {};
      const { brand, last4 } = paymentCard || {};

      setPaymentHasBeenReceivedData([
        { title: "Paid to", value: distributorStoreName },
        { title: "Customer name", value: distributorName },
        { title: "Invoice #", value: invoiceNumber },
        { title: "Customer email", value: distributorBaseUser?.email },
        {
          title: "Paid date",
          value: moment(Date.now()).format("MMM DD, YYYY"),
        },
        {
          title: "Payment method",
          value: `${capitalize(brand) || "-"}***${last4 || "-"}`,
        },
        {
          title: "Invoice amount",
          value: `$${separateNumWithComma(totalAmount)}`,
        },
      ]);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      setPaymentHasBeenReceived(true);
      setLoadingPayment(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <Box
          sx={{
            background: "#F9F9F8",
            height: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: "2vw",
            }}
          >
            <MainLogoIcon
              width={breakpoint === "md" ? 240 : 125}
              height={100}
            />
            {showLoginBtn && distributorStoreName ? (
              <Button
                variant="contained"
                sx={{
                  fontSize: { xs: 15, md: 18 },
                  height: { xs: "37px", md: "44px" },
                }}
                components={Link}
                href={`${CUSTOMER_APP}login/${distributorStoreName}`}
              >
                Sign In
              </Button>
            ) : null}
          </Box>

          <Box mt={10} mb={2} display={{ xs: "block", md: "none" }}>
            <Box height="67px" width="230px" textAlign="center">
              <ProfilePhotoBlock fileName={profilePhoto?.fileName} />
            </Box>
            <Box textAlign="center">
              <DistributorNameBlock distributorName={distributorName} />
            </Box>
          </Box>

          <Box
            sx={{
              mt: "-100px",
              py: 12.5,
              width: "100%",
              height: "100%",
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isPaymentHasBeenReceived && paymentHasBeenReceivedData ? (
              <PaymentReceivedBlock
                paymentSum={balance}
                paidFields={paymentHasBeenReceivedData}
                token={token}
              />
            ) : (
              <Box mx={2}>
                <Box display="flex" flexDirection="column">
                  <Box
                    sx={{
                      width: { xs: "100%", lg: "1044px" },
                      background: "#fff",
                      borderRadius: "16px",
                      border: "0.5px solid #D5D9D9",
                      padding: {
                        xs: "12px 16px 29px",
                        lg: "37px 46px 21px 28px",
                      },
                      display: "flex",
                      flexDirection: { xs: "column", lg: "row" },
                      justifyContent: "space-between",
                      gap: { xs: "6px", md: "35px" },
                    }}
                  >
                    <>
                      <Box
                        display={{ xs: "none", md: "block" }}
                        sx={{ flex: "1 1 60%" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box>
                            <Box
                              sx={{
                                height: "67px",
                                width: "230px",
                              }}
                            >
                              <ProfilePhotoBlock
                                fileName={profilePhoto?.fileName}
                              />
                            </Box>
                            <Box>
                              <DistributorNameBlock
                                distributorName={distributorName}
                              />
                            </Box>
                          </Box>
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "17px",
                                marginBottom: "16px",
                              }}
                            >
                              <IconButton
                                disabled={loadingReport}
                                sx={{
                                  borderRadius: "4px",
                                  border: "0.5px solid #5F6267",
                                  padding: "4px",
                                  width: "36px",
                                  height: "36px",
                                }}
                                onClick={() =>
                                  handleDownloadReport({ download: true })
                                }
                              >
                                <Download style={{ fontSize: "26px" }} />
                              </IconButton>
                              <IconButton
                                disabled={loadingReport}
                                sx={{
                                  borderRadius: "4px",
                                  border: "0.5px solid #5F6267",
                                  padding: "4px",
                                  width: "36px",
                                  height: "36px",
                                }}
                                onClick={() =>
                                  handleDownloadReport({ print: true })
                                }
                              >
                                <PrinterIcon width="19.92" height="17.43" />
                              </IconButton>
                            </Box>
                            <Link
                              disabled={loadingReport}
                              sx={{
                                cursor: loadingReport ? "auto" : "pointer",
                                fontSize: 15,
                                color: "#1C1C19",
                              }}
                              onClick={handleDownloadReport}
                            >
                              View Invoice
                            </Link>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginTop: "19px",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",

                              "& .MuiTypography-root": {
                                fontSize: "23px",
                                color: "#1c1c19",
                                fontWeight: 300,
                              },
                            }}
                          >
                            <Typography>Invoice #</Typography>
                            <Typography>{invoiceNumber}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",

                              "& .MuiTypography-root": {
                                fontSize: "23px",
                                color: "#1c1c19",
                                fontWeight: 300,
                              },
                            }}
                          >
                            <Typography fontSize={15} color="#707070">
                              Due date
                            </Typography>
                            <Typography fontSize={15} color="#000">
                              {dueDate}
                            </Typography>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "18px",
                            borderTop: "0.7px solid #D5D9D9",
                            borderBottom: "0.7px solid #D5D9D9",
                            height: "57px",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 29,
                              fontWeight: 600,
                              color: "#000",
                            }}
                          >
                            Total
                            <Box
                              fontSize={20}
                              fontWeight={300}
                              color="#000"
                              component="span"
                            >
                              {" "}
                              ({pluralize("item", totalQuantity, true)})
                            </Box>
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: 29,
                              fontWeight: 600,
                              color: "#1C1C19",
                            }}
                          >
                            $ {separateNumWithComma(balance)}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            padding: "16px 0 24px",
                            borderBottom: "0.7px solid #D5D9D9",
                            "& .MuiTypography-root": {
                              fontSize: "22px",
                              color: "#1c1c19",
                            },
                          }}
                        >
                          <Typography fontWeight="600">
                            {distributorName}
                          </Typography>
                          <Typography whiteSpace="pre-wrap" fontWeight={300}>
                            {preparedAddress(
                              billingAddress?.formatted_address
                            ) || "-"}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "22px",
                            paddingTop: "17px",
                            "& .MuiTypography-root": {
                              fontSize: "22px",
                              color: "#1c1c19",
                              fontWeight: "300",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "15px",
                              alignItems: "center",
                              "& .icon": {
                                width: "22px",
                                display: "flex",
                              },
                            }}
                          >
                            <Box className="icon">
                              <Phone style={{ fill: "#47A06D" }} />
                            </Box>
                            <Typography>
                              {setPhoneNumberMask(distributorPhone) || "-"}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "15px",
                              alignItems: "center",
                              "& .icon": {
                                width: "22px",
                                display: "flex",
                              },
                            }}
                          >
                            <Box className="icon">
                              <MailIcon />
                            </Box>
                            <Typography>
                              {distributorBaseUser?.email || "-"}
                            </Typography>
                          </Box>
                          {domainName ? (
                            <Typography mt="35px">{domainName}</Typography>
                          ) : null}
                        </Box>
                      </Box>

                      <Box
                        display={{ xs: "flex", md: "none" }}
                        sx={{
                          borderBottom: "0.5px solid #D5D9D9",
                          pb: 2,
                        }}
                      >
                        <Box
                          sx={{
                            width: "calc(100% - 80px)",
                          }}
                        >
                          <Grid container>
                            <Grid xs={4} item>
                              <Typography fontSize={15} color="#707070">
                                Invoice #
                              </Typography>
                            </Grid>
                            <Grid xs={8} item>
                              <Typography fontSize={15} color="#000">
                                {invoiceNumber}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid xs={4} item>
                              <Typography fontSize={15} color="#707070">
                                Due date:
                              </Typography>
                            </Grid>
                            <Grid xs={8} item>
                              <Typography fontSize={15} color="#000">
                                {dueDate}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid xs={4} item>
                              <Typography fontSize={15} color="#707070">
                                Amount:
                              </Typography>
                            </Grid>
                            <Grid xs={8} item>
                              <Typography fontSize={15} color="#000">
                                ${separateNumWithComma(balance)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box
                          sx={{
                            width: "80px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          <StyledButton
                            sx={{ borderRadius: "8px", px: "6.5px" }}
                            variant="outlined"
                            fontSize="10px"
                            color="cancel"
                            label="View Invoice"
                            onClick={handleDownloadReport}
                          />
                          <StyledButton
                            sx={{ borderRadius: "8px", px: "6.5px" }}
                            variant="outlined"
                            fontSize="10px"
                            color="cancel"
                            label="Contact Info"
                            onClick={handleOpenContactInfoBtn}
                          />
                          <Menu
                            id="basic-menu-list"
                            anchorEl={anchorContactInfo}
                            open={openContactInfoList}
                            // onClose={handleClose}
                            onClick={(e) => e.stopPropagation()}
                            sx={{
                              "& .MuiList-root": {
                                padding: "0 !important",
                                width: "245px",
                              },
                            }}
                          >
                            <MenuList sx={{ "& .MuiList-root": { p: 0 } }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  height: "48px",
                                  pl: "23px",
                                  pr: "10px",
                                }}
                              >
                                <Typography fontSize={19} color="#363531">
                                  Contact info
                                </Typography>
                                <IconButton onClick={handleCloseContactInfoBtn}>
                                  <CrossIcon />
                                </IconButton>
                              </Box>

                              <Box
                                sx={{
                                  height: "42px",
                                  px: "12px",
                                  borderTop: "0.3px solid #B2B2B2",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                                component={Link}
                                underline="none"
                                href={`mailto:${
                                  distributorBaseUser?.email || "#"
                                }`}
                              >
                                <MailIcon width={12.93} height={8.5} />
                                <Typography fontSize={12} color="#000" noWrap>
                                  {distributorBaseUser?.email || "-"}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  height: "42px",
                                  pl: "10px",
                                  pr: "12px",
                                  borderTop: "0.3px solid #B2B2B2",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                                component={Link}
                                underline="none"
                                href={`tel:${distributorPhone || "#"}`}
                              >
                                <Phone
                                  style={{ fill: "#47A06D" }}
                                  sx={{ fontSize: 17.6 }}
                                />
                                <Typography fontSize={12} color="#000" noWrap>
                                  {setPhoneNumberMask(distributorPhone) || "-"}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  height: "48px",
                                  px: "12px",
                                  borderTop: "0.3px solid #B2B2B2",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <StoreIcon width={14.23} height={11.83} />
                                <Typography fontSize={12} color="#000" noWrap>
                                  {preparedAddress(
                                    billingAddress?.formatted_address
                                  ) || "-"}
                                </Typography>
                              </Box>
                            </MenuList>
                          </Menu>
                        </Box>
                      </Box>

                      <Box sx={{ flex: "1 1 40%" }}>
                        <Box
                          sx={{
                            border: { xs: "", lg: "0.5px solid #D5D9D9" },
                            borderRadius: "4px",
                            width: { xs: "100%", lg: "405px" },
                            padding: { xs: "0", lg: "13px 25px 40px 33px" },
                            paddingBottom: "10px",
                          }}
                        >
                          <Typography
                            display={{ xs: "none", md: "block" }}
                            fontSize="18px"
                            color="#707070"
                          >
                            Payment amount
                          </Typography>
                          <Box
                            display={{ xs: "none", md: "block" }}
                            sx={{
                              height: "45px",
                              borderBottom: "1px solid #47A06D",
                              "& div": {
                                display: "flex",
                                gap: "8px",
                                alignItems: "center",
                              },
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "22px",
                                  color: "#000",
                                }}
                              >
                                ${separateNumWithComma(balance)}
                              </Typography>
                              <CheckCircle style={{ fill: "#409A65" }} />
                            </Box>
                          </Box>

                          <Box sx={{ marginTop: "11px" }}>
                            <Typography
                              color="#363531"
                              fontWeight="600"
                              fontSize="15px"
                              mb="15px"
                              display={{ xs: "none", md: "block" }}
                            >
                              Credit Card Details
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "14px",
                              }}
                            >
                              {clientSecret && options?.stripeAccount && (
                                <StripeElement
                                  stripeAccount={options?.stripeAccount}
                                  clientSecret={clientSecret}
                                  onSubmit={onSubmit}
                                  setLoadingStripeElement={
                                    setLoadingStripeElement
                                  }
                                />
                              )}
                              {loadingStripeElement && (
                                <Box sx={{ textAlign: "center" }}>
                                  <CircularProgress color="inherit" size={20} />
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>

                        {paymentError && (
                          <Box
                            mt="22px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            color="#df1b41"
                          >
                            {paymentError?.message}
                          </Box>
                        )}

                        <Box display={{ xs: "none", md: "block" }}>
                          <ActionsBlock
                            {...{
                              loadingPayment,
                              isPaymentHasBeenReceived,
                              loadingStripeElement,
                              balance,
                            }}
                          />

                          <InfoBlock {...{ balance }} />
                        </Box>
                      </Box>
                    </>
                  </Box>
                </Box>
                <Box display={{ xs: "block", md: "none" }} mt="80px">
                  <ActionsBlock
                    {...{
                      loadingPayment,
                      isPaymentHasBeenReceived,
                      loadingStripeElement,
                      balance,
                    }}
                  />

                  <InfoBlock {...{ balance }} />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default PaymentPage;
