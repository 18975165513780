/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";

export const TaskSmallIcon = ({
  color = "#A5A5A5",
  width = 12,
  height = 14,
  ...props
}) => (
  <SvgIcon
    viewBox="0 0 12 14"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ width, height }}
    style={{ fill: "none" }}
    {...props}
  >
    <path
      d="M1.25 4c0-1.414 0-2.121.44-2.56C2.128 1 2.835 1 4.25 1h3.5c1.414 0 2.121 0 2.56.44.44.439.44 1.146.44 2.56v6c0 1.414 0 2.121-.44 2.56-.439.44-1.146.44-2.56.44h-3.5c-1.414 0-2.121 0-2.56-.44-.44-.439-.44-1.146-.44-2.56z"
      stroke={color}
      strokeLinejoin="round"
    />
    <path
      d="M5.75 4h3m-3 3h3m-3 3h3m-5.6-6.144.443.497.507-.923m-.95 3.426.443.497.507-.923m-.95 3.426.443.497.507-.923"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
