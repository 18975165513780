import { makeStyles } from "@mui/styles";

export const cl = {
  wrapper: {
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  overLine: {
    width: "140%",
    height: "1px",
    backgroundColor: " #000000",
    //border: "0.2px solid #000000",
    position: "absolute",
    transform: "rotateY(0deg) rotate(135deg)",
  },
};

export const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: "#ffffff",
    border: "0.5px solid #D5D9D9",
    p: "2px 20px 4px 20px",
    borderRadius: "4px",
    textAlign: "center",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
    color: "#1C1C19",
    marginBottom: "0!important",
  },
  customArrow: {
    width: "7px",
    height: "10px",
    left: "45%",
    bottom: "-8px",
    zIndex: 1,
    color: "#fff",
    backgroundColor: "#ffffff",
    clipPath: "polygon(100% 7%, 0% 7%, 50% 100%)",
    position: "absolute",
  },
}));
