import { string, object, func } from "prop-types";
import { Paper, Typography } from "@mui/material";
import { useAdmin } from "helpers/helpers";
import { IOSSwitch } from "components";
import { Controller } from "react-hook-form";

export const TitleBlock = ({
  name = "",
  control = {},
  handleWebAccess,
  fieldName = "portalAccess",
}) => {
  const isAdmin = useAdmin();

  return (
    <Paper
      sx={{
        height: "64px",
        border: "none",
        borderBottom: "0.5px solid rgba(217, 217, 217, 1) !important",
        display: "flex",
        borderRadius: "4px 4px 0 0",
        justifyContent: "space-between",
        alignItems: "center",
        pl: "21px",
      }}
      variant="outlined"
    >
      <Typography fontSize={20} fontFamily={400} color="#707070">
        {name}
      </Typography>

      <Controller
        render={({ field }) => {
          return (
            <IOSSwitch
              small
              label=""
              onChange={(e) => {
                if (isAdmin) return;
                if (handleWebAccess) handleWebAccess(e.target.checked);
                field.onChange(e.target.checked);
              }}
              checked={!!field.value}
              formSx={{ mr: "0px" }}
            />
          );
        }}
        control={control}
        name={fieldName}
      />
    </Paper>
  );
};

TitleBlock.propTypes = {
  name: string,
  control: object,
  handleWebAccess: func,
  fieldName: string,
};
