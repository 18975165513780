import { IconButton, Menu, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { array, func, object } from "prop-types";
import { CityIcon, CrossIcon } from "../../../../components/Icons";
import useStyles from "./styles";

const TerritoriesMenu = ({ anchorEl, territories, handleClose }) => {
  const classes = useStyles();

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      PaperProps={{ className: classes.menu }}
    >
      <Box className={classes.head}>
        <Box className={classes.menuTitleBlock}>
          <Typography className={classes.title}>Territories</Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CrossIcon />
        </IconButton>
      </Box>
      <Box className={classes.menuHeaderBlock}>
        <Box className={classes.headerAssigned}>
          <CityIcon />
          <Typography ml="9px">Assigned Territories</Typography>
        </Box>
        <Typography>Customer Count</Typography>
      </Box>
      {territories
        .sort((ter1, ter2) => ter2.count - ter1.count)
        .map((territory) => (
          <Box key={territory.id} className={classes.territoryItem}>
            <Typography noWrap>- {territory.name}</Typography>
            <Typography>{territory.count}</Typography>
          </Box>
        ))}
    </Menu>
  );
};

TerritoriesMenu.propTypes = {
  anchorEl: object,
  handleClose: func,
  territories: array,
};

TerritoriesMenu.defaultProps = {
  anchorEl: null,
  territories: [],
};

export default TerritoriesMenu;
