import { useEffect, useMemo, useRef, useState } from "react";
import { IconButton, Typography } from "@mui/material";
import PropTypes, { string, func, object, bool, number } from "prop-types";
import {
  uploadFile,
  uploadGenerateService,
  uploadPhotoService,
} from "services/files";
import { checkFileSize, getImageSizes, uploadProgress } from "helpers/helpers";
import { CrossIcon } from "../Icons";
import { CropperComponent } from "./CropperComponent";
import { error } from "utils/notifications";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { app } from "firebase/Chat/config";
import { useDispatch } from "react-redux";
import {
  setEditTypeAction,
  setFormChangedAction,
} from "redux/actions/confirmDialogs";

export const UploadFile = ({
  label,
  onChange,
  accept,
  Wrapper,
  wrapperProps,
  path,
  isEmpty,
  icon,
  handleUploadFiles,
  fontSize,
  withCropper,
  settingsCropper,
  dialogSize,
  progressUpload,
  defaultAspect,
  setIsOpen,
  uploadData,
  preparingPhoto,
  setPreparingPhoto,
  maxUploadFileSizeMb,
  maxUploadFileResolutionMp,
  confirmData,
  token,
  adminIsAllowed,
  ...props
}) => {
  const dispatch = useDispatch();

  const firestore = getFirestore(app);
  const [dialogState, setDialogState] = useState({
    open: false,
    file: null,
  });

  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef(null);

  const handleUploadPhoto = async (file) => {
    if (confirmData?.type === "product-photo") {
      dispatch(setEditTypeAction(confirmData?.type, false));
      dispatch(setFormChangedAction(true));
    }
    setIsUploading(true);
    progressUpload(true);

    if (uploadData?.type) {
      let preparedData = {
        gallery: [
          {
            name: file.name,
            type: uploadData?.type,
          },
        ],
      };
      if (uploadData.representativeId)
        preparedData.representativeId = uploadData.representativeId;
      if (uploadData.manufacturerId)
        preparedData.manufacturerId = uploadData.manufacturerId;
      if (uploadData.distributorId)
        preparedData.distributorId = uploadData.distributorId;

      try {
        const uploadGenerate = await uploadGenerateService(preparedData, token);

        if (!uploadGenerate?.groupContentId) {
          throw new Error("Something went wrong!");
        }

        const groupContentId = uploadGenerate?.groupContentId;
        const uuid = uploadGenerate?.signedUrlItems?.length
          ? uploadGenerate?.signedUrlItems?.[0]?.uuid
          : null;
        const link = uploadGenerate?.signedUrlItems?.length
          ? uploadGenerate?.signedUrlItems?.[0]?.signedUrl
          : null;
        const headers = uploadGenerate?.signedUrlItems?.length
          ? uploadGenerate?.signedUrlItems?.[0]?.headers
          : null;

        await uploadPhotoService({
          file,
          url: link,
          headers: {
            uuid,
            "Content-Type": file.type,
            ...headers,
          },
          config: {
            onUploadProgress: (progressEvent) =>
              uploadProgress(progressEvent, setProgress),
          },
        });

        setPreparingPhoto(true);

        const snapshotListener = onSnapshot(
          doc(firestore, "image-uploads", groupContentId),
          (doc) => {
            const res = doc?.data();

            if (res?.finalized) {
              const objectUrl = URL.createObjectURL(file);
              onChange({ fileName: objectUrl, uuid, groupContentId });
              if (groupContentId && uploadData?.setGroupContentId)
                uploadData.setGroupContentId(groupContentId);
              setPreparingPhoto(false);
              if (confirmData?.type === "product-photo")
                dispatch(setEditTypeAction("product"));
              snapshotListener();
            }
          }
        );
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setProgress(0);
        setIsUploading(false);
        progressUpload(false);
      }

      return;
    }

    uploadFile(file, path, {
      onUploadProgress: (progressEvent) =>
        uploadProgress(progressEvent, setProgress),
    }).then((res) => {
      setProgress(0);
      setIsUploading(false);
      onChange(res);
      progressUpload(false);
    });
  };

  useEffect(() => {
    if (setIsOpen) setIsOpen(dialogState?.open);
  }, [dialogState?.open, setIsOpen]);

  const handleUpload = async (e) => {
    const { files } = e.currentTarget;
    if (!files[0]) return;

    const fileType = files[0].type.split("/")[1] || "png";
    const modifiedFileName = `user-logo-${Math.floor(
      Date.now() / 1000
    )}.${fileType}`;

    const modifiedFile = new File([files[0]], modifiedFileName, {
      type: files[0].type,
      lastModified: files[0].lastModified,
    });

    if (
      maxUploadFileSizeMb?.count &&
      checkFileSize({
        fileSize: modifiedFile?.size,
        maxFileSizeMb: maxUploadFileSizeMb?.count,
      })
    ) {
      if (maxUploadFileSizeMb?.callback)
        maxUploadFileSizeMb.callback(modifiedFile?.name);
      e.target.value = null;
      return;
    }

    if (maxUploadFileResolutionMp?.count) {
      const { width, height } = await getImageSizes(modifiedFile);

      const megapixels = (width * height) / 1000000;

      if (
        megapixels > maxUploadFileResolutionMp?.count &&
        maxUploadFileResolutionMp?.callback
      ) {
        maxUploadFileResolutionMp.callback(modifiedFile?.name);
        e.target.value = null;
        return;
      }
    }

    if (withCropper)
      await setDialogState((prev) => ({
        ...prev,
        open: true,
        file: modifiedFile,
      }));

    if (!withCropper) handleUploadPhoto(modifiedFile);
  };

  const handleSaveImg = (imgData) => {
    imgData.then((res) => handleUploadPhoto(res));
  };

  const loadingProp = useMemo(
    () => (path === "product-photos" ? { loading: isUploading } : {}),
    [isUploading, path]
  );

  return (
    <>
      <CropperComponent
        setUploadPhotoSquare={uploadData?.type === "PRODUCT"}
        open={dialogState.open}
        onClose={() => {
          setDialogState((prop) => ({ ...prop, open: false, file: null }));
          inputRef.current.value = null;
        }}
        file={dialogState.file}
        handleSaveImg={handleSaveImg}
        settings={settingsCropper}
        size={dialogSize || ""}
        defaultAspect={defaultAspect}
      />
      <Wrapper
        onClick={() => {
          if (isUploading) return;
          inputRef.current.click();
        }}
        {...wrapperProps}
        {...loadingProp}
      >
        {path !== "product-photos" &&
          (preparingPhoto ? (
            <Typography fontSize={fontSize}>Preparing</Typography>
          ) : (
            <Typography fontSize={fontSize}>
              {isUploading ? `Uploading: ${progress}%` : label}
            </Typography>
          ))}
        {icon}
        <input
          type="file"
          hidden
          onChange={handleUploadFiles || handleUpload}
          accept={accept}
          ref={inputRef}
          {...props}
        />
        {!isEmpty && (
          <IconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              p: "4px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              onChange(null);
            }}
          >
            <CrossIcon />
          </IconButton>
        )}
      </Wrapper>
    </>
  );
};

UploadFile.propTypes = {
  label: string,
  accept: string,
  path: string,
  onChange: func,
  Wrapper: PropTypes.oneOfType([object, func]),
  wrapperProps: object,
  isEmpty: bool,
  icon: object,
  handleUploadFiles: func,
  fontSize: string,
  withCropper: bool,
  settingsCropper: object,
  dialogSize: string,
  progressUpload: func,
  defaultAspect: number,
  setIsOpen: func,
  uploadData: object,
  preparingPhoto: bool,
  setPreparingPhoto: func,
  maxUploadFileSizeMb: object,
  maxUploadFileResolutionMp: object,
  confirmData: object,
  token: string,
  adminIsAllowed: bool,
};

UploadFile.defaultProps = {
  label: "Upload file",
  accept: "*",
  path: "users/representatives/profile-photo",
  isEmpty: true,
  fontSize: "14px",
  withCropper: false,
  progressUpload: () => {},
  defaultAspect: 1,
  uploadData: {},
  preparingPhoto: false,
  setPreparingPhoto: () => {},
  adminIsAllowed: false,
};

export default UploadFile;
