import { useCallback, useEffect, useState } from "react";
import { getFormAssignedRepsService } from "services/forms";
import { SCROLL_LIMIT } from "utils/constants";
import { error } from "utils/notifications";

const init = {
  loading: false,
  count: 0,
  existData: false,
  list: [],
};

export const useRepsAssignedActions = ({ params = {}, open = true }) => {
  const [repsState, setRepsState] = useState(init);

  const [repsParams, setRepsParams] = useState({
    page: 1,
    limit: SCROLL_LIMIT,
    search: "",
    ...params,
  });

  const handleFetchReps = useCallback(
    async (page) => {
      setRepsState((prev) => ({ ...prev, loading: true }));
      try {
        const res = await getFormAssignedRepsService({
          ...repsParams,
          ...params,
          page: page || repsParams.page,
        });

        const count = res?.count || 0;
        const existData = !!res?.existData;
        const rows = res?.rows || [];

        setRepsState((prev) => ({
          ...prev,
          list: page > 1 ? [...prev.list, ...rows] : rows,
          count: count,
          initCount: !repsParams.search ? count : prev.initCount,
          existData,
        }));

        if (page) setRepsParams((prev) => ({ ...prev, page }));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setRepsState((prev) => ({ ...prev, loading: false }));
      }
    },
    [repsParams, params]
  );

  useEffect(() => {
    if (open) {
      handleFetchReps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, params?.form_active, repsParams?.search]);

  return {
    ...repsState,
    page: repsParams.page,
    handleFetchReps,
    setRepsParams,
  };
};
