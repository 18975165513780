import { useEffect, useState } from "react";
import { bool, any, string, object, func, array } from "prop-types";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";

import { Box, Chip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FilterIcon } from "../../../../../components/Icons";

import { FilterSearchTextField } from "../../../../../components/TextFields/TextFields";
import FilterMenu from "../../../../../components/FilterMenu";
import StyledButton from "../../../../../components/StyledButton";

import useStyles, { cl } from "./styles";
import { getFilterSwitches } from "./constants";
import { SCROLL_LIMIT } from "../../../../../utils/constants";

import {
  manufacturersCountSelector,
  manufacturersListSelector,
} from "../../../../../redux/selectors/manufacturers";
import { getManufacturersAction } from "../../../../../redux/actions/manufacturers";
import { useTagsActions } from "helpers/useTagsActions";
import { ActiveTabs } from "components";

const selector = createSelector(
  manufacturersListSelector,
  manufacturersCountSelector,
  (manufacturersList, manufacturersCount) => ({
    manufacturersList,
    manufacturersCount,
  })
);

export const NewProductsFilter = ({
  isOpen,
  specificManufacturer,
  filterMenuOpen,
  handleApplyFilter,
  filterAnchor,
  setFilterMenuOpen,
  filterChipKeys,
  filterFields,
  setSearchInput,
  switches,
  setFilterFields,
  getChipLabel,
  setSwitches,
  searchInput,
  handleDeleteFilter,
  fetchQuery = {},
  showInactive,
  setShowInactive,
  showActiveTabs = false,
}) => {
  const classes = useStyles();
  const {
    NewProductsFilter: { wrapper, searchAdor, btnLabel, btnFilter },
  } = cl;

  const { manufacturersList, manufacturersCount } = useSelector(selector);

  const dispatch = useDispatch();

  const [limitManufacturers, setLimitManufacturers] = useState(
    2 * SCROLL_LIMIT
  );

  const [manufacturersState, setManufacturersState] = useState({
    search: "",
    searcheble: false,
  });

  const handleFetchManufacturers = () => {
    const params = {
      limit: limitManufacturers,
      search: manufacturersState.search,
      exclude_categories_ids: fetchQuery?.exclude_categories_ids?.length
        ? fetchQuery?.exclude_categories_ids
        : null,
      categories_ids: fetchQuery?.categories_ids?.length
        ? fetchQuery?.categories_ids
        : null,
    };

    dispatch(getManufacturersAction(params));
    setManufacturersState((prev) => ({
      ...prev,
      searcheble: !prev.searcheble ? manufacturersCount >= 10 : prev.searcheble,
    }));
    setLimitManufacturers(limitManufacturers + SCROLL_LIMIT);
  };

  useEffect(() => {
    handleFetchManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manufacturersState.search]);

  const {
    list: tagsList,
    count: tagsCount,
    handleFetchTags,
    page: tagsPage,
    setTagsParams,
    searcheble,
  } = useTagsActions({
    open: isOpen,
    type: "product",
    params: {
      used: true,
      sort_by_tag: "asc",
    },
  });

  useEffect(() => {
    if (!specificManufacturer) return;
    setFilterFields((prev) => {
      return { ...prev, manufacturer: specificManufacturer };
    });

    setSwitches(getFilterSwitches(!!specificManufacturer));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specificManufacturer]);

  // show the clear button only if the input field has a value and does not have a chip
  const handleClearValue = (
    specificManufacturer
      ? filterChipKeys.filter((c) => c !== "manufacturer").length
      : filterChipKeys?.length
  )
    ? null
    : () => setSearchInput("");

  const handleChangeTab = (e, newVal) => {
    setShowInactive(newVal);
  };

  return (
    <Box sx={wrapper}>
      {showActiveTabs && (
        <ActiveTabs
          value={showInactive}
          onChange={handleChangeTab}
          tabsProps={{ style: { marginRight: 0 } }}
          tabProps={{ style: { fontSize: "16px" } }}
        />
      )}
      <FilterSearchTextField
        formSx={{ minWidth: "380px" }}
        placeholder="Search products by name or SKU"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        fullWidth
        adornmentProps={{
          sx: {
            justifyContent: "flex-end",
          },
        }}
        placeholderWidth="260px"
        handleClearValue={handleClearValue}
        customAdornment={
          <Box sx={searchAdor}>
            {filterChipKeys.map((key) => (
              <Chip
                sx={{
                  display:
                    key === "manufacturer" && !!specificManufacturer
                      ? "none"
                      : "inline-flex",
                }}
                key={key}
                className={classes.filterChip}
                size="small"
                label={getChipLabel(key)}
                deleteIcon={<CloseIcon size="15" style={{ fill: "#707070" }} />}
                onMouseDown={(e) => e.stopPropagation()}
                disabled={key === "manufacturer" && !!specificManufacturer}
                onDelete={() => handleDeleteFilter(key)}
              />
            ))}
          </Box>
        }
      />

      <StyledButton
        sx={btnFilter}
        fontSize="15px"
        label="Filter"
        startIcon={
          (
            specificManufacturer
              ? filterChipKeys.length - 1
              : filterChipKeys.length
          ) ? (
            <Typography sx={btnLabel}>
              {specificManufacturer
                ? filterChipKeys.length - 1
                : filterChipKeys.length}
            </Typography>
          ) : (
            <FilterIcon />
          )
        }
        variant="outlined"
        ref={filterAnchor}
        color="edit"
        onClick={() => setFilterMenuOpen(true)}
      />
      <FilterMenu
        anchorEl={filterAnchor.current}
        open={filterMenuOpen}
        onClose={() => setFilterMenuOpen(false)}
        filterFields={filterFields}
        switches={switches}
        handleApplyFilter={handleApplyFilter}
        categoriesProps={{
          excludeIds: fetchQuery?.exclude_categories_ids || [],
          includeIds: fetchQuery?.categories_ids || [],
        }}
        selectMatchData={[
          {
            id: "manufacturer_id",
            childrenList: manufacturersList,
            handleFetch: handleFetchManufacturers,
            setParams: setManufacturersState,
            dataCount: manufacturersCount,
            dataLength: manufacturersList?.length,
            label: "Select Manufacturer",
            searcheble: manufacturersState.searcheble,
            textFieldProps: {
              emptyLabel: "No manufacturer available",
            },
          },
          {
            id: "tag_ids",
            childrenList: tagsList,
            handleFetch: () => handleFetchTags(tagsPage + 1),
            setParams: setTagsParams,
            dataCount: tagsCount,
            dataLength: tagsList?.length,
            label: "Select Tag",
            searcheble,
          },
        ]}
      />
    </Box>
  );
};

NewProductsFilter.propTypes = {
  isOpen: bool,
  specificManufacturer: any,
  filterMenuOpen: bool,
  handleApplyFilter: func,
  filterAnchor: any,
  setFilterMenuOpen: func,
  filterChipKeys: array,
  filterFields: object,
  fetchQuery: object,
  setSearchInput: func,
  searchInput: string,
  handleDeleteFilter: func,
  getChipLabel: func,
  switches: array,
  setFilterFields: func,
  setSwitches: func,
  showInactive: bool,
  showActiveTabs: bool,
  setShowInactive: func,
};
NewProductsFilter.defaultProps = { isOpen: false, priceListId: null };
