import { useCallback, useMemo, useState } from "react";
import { func, oneOf } from "prop-types";
import { useForm, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import Nestable from "react-nestable";
import { Box, Button } from "@mui/material";
import { ReorderBurgerIcon } from "components/Icons";
import { columnLayoutsSelector } from "redux/selectors/settings";
import {
  setDirectColumnLayoutsAction,
  setFlatColumnLayoutsAction,
  setSortedColumnLayoutsAction,
  setThirdPartyColumnLayoutsAction,
  setRepAllColumnLayoutsAction,
  setDraftsColumnLayoutsAction,
} from "redux/actions/settings";
import { ColumnsSettingsItem } from "./ColumnsSettingsItem";
import { error } from "utils/notifications";
import { updateDistributorSettingsService } from "services/account";
import { initialState } from "redux/reducers/settings";

const selector = createSelector(columnLayoutsSelector, (columnLayouts) => ({
  columnLayouts,
}));

export const ColumnsSettingsList = ({ onClose, currentTab }) => {
  const { columnLayouts } = useSelector(selector);

  const flat_tab_disable = columnLayouts.flat.disable;
  const flat_tab = columnLayouts.flat.main;
  const sorted_tab_disable = columnLayouts.sorted.disable;
  const sorted_tab = columnLayouts.sorted.main;
  const direct_tab_disable = columnLayouts.direct.disable;
  const direct_tab = columnLayouts.direct.main;
  const third_party_tab_disable = columnLayouts.thirdParty.disable;
  const third_party_tab = columnLayouts.thirdParty.main;

  const rep_all_tab_disable = columnLayouts.rep_all.disable;
  const rep_all_tab = columnLayouts.rep_all.main;
  const drafts_tab_disable = columnLayouts.drafts.disable;
  const drafts_tab = columnLayouts.drafts.main;

  const dispatch = useDispatch();

  const { control, setValue, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues: {
      columnsFlat: [...flat_tab],
      columnsSorted: [...sorted_tab],
      columnsDirect: [...direct_tab],
      columnsThirdParty: [...third_party_tab],
      columnsRepAll: [...rep_all_tab],
      columnsDrafts: [...drafts_tab],
    },
  });

  const formField = useWatch({ control });
  const disabledColumns = useMemo(() => {
    if (currentTab === "flat") return flat_tab_disable;
    if (currentTab === "sorted") return sorted_tab_disable;
    if (currentTab === "Direct") return direct_tab_disable;
    if (currentTab === "Drafts")
      return [...drafts_tab_disable].map((col) => {
        if (col?.name === "Order") return { ...col, name: "Draft" };
        return col;
      });
    if (currentTab === "3rd Party") return third_party_tab_disable;
    if (["rep_All", "rep_Sales", "rep_Merchandisers"].includes(currentTab))
      return rep_all_tab_disable;

    return [];
  }, [
    currentTab,
    flat_tab_disable,
    sorted_tab_disable,
    direct_tab_disable,
    drafts_tab_disable,
    third_party_tab_disable,
    rep_all_tab_disable,
  ]);

  const columns = useMemo(() => {
    if (currentTab === "flat") return formField.columnsFlat;
    if (currentTab === "sorted") return formField.columnsSorted;
    if (currentTab === "Direct") return formField.columnsDirect;
    if (currentTab === "Drafts") return formField.columnsDrafts;
    if (currentTab === "3rd Party") return formField.columnsThirdParty;
    if (["rep_All", "rep_Sales", "rep_Merchandisers"].includes(currentTab))
      return formField.columnsRepAll;

    return [];
  }, [
    currentTab,
    formField.columnsFlat,
    formField.columnsSorted,
    formField.columnsDirect,
    formField.columnsDrafts,
    formField.columnsThirdParty,
    formField.columnsRepAll,
  ]);

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async (data) => {
      const flat = {
        flat: {
          disable: flat_tab_disable,
          main: data.columnsFlat.map(({ id, name, selected, disable }) => ({
            id,
            name,
            selected,
            disable,
          })),
        },
      };
      const sorted = {
        sorted: {
          disable: sorted_tab_disable,
          main: data.columnsSorted.map(({ id, name, selected, disable }) => ({
            id,
            name,
            selected,
            disable,
          })),
        },
      };
      const direct = {
        direct: {
          disable: direct_tab_disable,
          main: data.columnsDirect.map(({ id, name, selected, disable }) => ({
            id,
            name,
            selected,
            disable,
          })),
        },
      };
      const drafts = {
        drafts: {
          disable: drafts_tab_disable,
          main: data.columnsDrafts.map(({ id, name, selected, disable }) => ({
            id,
            name,
            selected,
            disable,
          })),
        },
      };
      const thirdParty = {
        thirdParty: {
          disable: third_party_tab_disable,
          main: data.columnsThirdParty.map(
            ({ id, name, selected, disable }) => ({
              id,
              name,
              selected,
              disable,
            })
          ),
        },
      };
      const rep_all = {
        disable: rep_all_tab_disable,
        main: data.columnsRepAll.map(({ id, name, selected, disable }) => ({
          id,
          name,
          selected,
          disable,
        })),
      };

      let preparedData;

      if (currentTab === "flat") preparedData = flat;
      if (currentTab === "sorted") preparedData = sorted;
      if (currentTab === "Direct") preparedData = direct;
      if (currentTab === "Drafts") preparedData = drafts;
      if (currentTab === "3rd Party") preparedData = thirdParty;
      if (["rep_All", "rep_Sales", "rep_Merchandisers"].includes(currentTab))
        preparedData = rep_all;

      try {
        setLoading(true);
        let res;

        if (["flat", "sorted"].includes(currentTab)) {
          res = await updateDistributorSettingsService({
            customerLayoutSettings: preparedData,
          });

          if (currentTab === "flat") {
            dispatch(
              setFlatColumnLayoutsAction(res?.customerLayoutSettings?.flat)
            );
          } else if (currentTab === "sorted") {
            dispatch(
              setSortedColumnLayoutsAction(res?.customerLayoutSettings?.sorted)
            );
          }
        }

        if (["Direct", "3rd Party", "Drafts"].includes(currentTab)) {
          res = await updateDistributorSettingsService({
            orderLayoutSettings: preparedData,
          });
          if (currentTab === "Direct")
            dispatch(
              setDirectColumnLayoutsAction(res?.orderLayoutSettings?.direct)
            );
          if (currentTab === "3rd Party")
            dispatch(
              setThirdPartyColumnLayoutsAction(
                res?.orderLayoutSettings?.thirdParty
              )
            );
          if (currentTab === "Drafts")
            dispatch(
              setDraftsColumnLayoutsAction(res?.orderLayoutSettings?.drafts)
            );
        }

        if (
          ["rep_All", "rep_Sales", "rep_Merchandisers"].includes(currentTab)
        ) {
          res = await updateDistributorSettingsService({
            representativeLayoutSettings: preparedData,
          });

          dispatch(
            setRepAllColumnLayoutsAction(res?.representativeLayoutSettings)
          );
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        error(err?.response?.data?.message || "Something went wrong");
      } finally {
        setLoading(false);
        onClose();
      }
    },
    [
      flat_tab_disable,
      sorted_tab_disable,
      direct_tab_disable,
      drafts_tab_disable,
      third_party_tab_disable,
      rep_all_tab_disable,
      currentTab,
      dispatch,
      onClose,
    ]
  );

  const handleResetLayout = async () => {
    try {
      let res;
      const { direct, thirdParty, drafts, ...newColumnLayouts } =
        initialState.column_layouts;
      delete newColumnLayouts.rep_all;

      const { flat, sorted, ...newOrderColumnLayouts } =
        initialState.column_layouts;
      delete newOrderColumnLayouts.rep_all;

      const { rep_all } = initialState.column_layouts;

      if (["flat", "sorted"].includes(currentTab)) {
        res = await updateDistributorSettingsService({
          customerLayoutSettings: newColumnLayouts,
        });

        if (currentTab === "flat") {
          return dispatch(
            setFlatColumnLayoutsAction(res?.customerLayoutSettings?.flat)
          );
        } else if (currentTab === "sorted") {
          return dispatch(
            setSortedColumnLayoutsAction(res?.customerLayoutSettings?.sorted)
          );
        }
      }

      if (["Direct", "3rd Party", "Drafts"].includes(currentTab)) {
        res = await updateDistributorSettingsService({
          orderLayoutSettings: newOrderColumnLayouts,
        });

        if (currentTab === "Direct")
          return dispatch(
            setDirectColumnLayoutsAction(res?.orderLayoutSettings?.direct)
          );

        if (currentTab === "3rd Party")
          return dispatch(
            setThirdPartyColumnLayoutsAction(
              res?.orderLayoutSettings?.thirdParty
            )
          );
        if (currentTab === "Drafts")
          return dispatch(
            setDraftsColumnLayoutsAction(res?.orderLayoutSettings?.drafts)
          );
      }

      if (["rep_All", "rep_Sales", "rep_Merchandisers"].includes(currentTab)) {
        res = await updateDistributorSettingsService({
          representativeLayoutSettings: rep_all,
        });
        return dispatch(
          setRepAllColumnLayoutsAction(res?.representativeLayoutSettings)
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      error(err?.response?.data?.message || "Something went wrong");
    } finally {
      onClose();
    }
  };

  return (
    <Box>
      <Box
        sx={{
          borderBottom: "0.5px solid #CCCCCC",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
          px: 1,
        }}
      >
        <Box
          sx={{
            ml: 1,
            fontSize: 14,
            color: "#000",
            display: "flex",
            alignItems: "center",
          }}
        >
          Column Layouts
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            sx={{ fontSize: 13, color: "#EB4233" }}
            onClick={handleResetLayout}
          >
            Reset
          </Button>

          <Box
            sx={{
              borderLeft: "1px solid #D5D9D9",
              height: "11px",
            }}
          />

          <Button
            disabled={loading}
            sx={{ fontSize: 13 }}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box sx={{ pt: 1 }}>
        {disabledColumns.map((col) => (
          <ColumnsSettingsItem
            key={col?.id}
            item={col}
            isDisabled={col?.disable}
          />
        ))}
        <Nestable
          items={columns}
          renderItem={({ index, item }) => (
            <ColumnsSettingsItem
              {...{
                item,
                index,
                setValue,
                currentTab,
              }}
              isDisabled={item?.disable}
            />
          )}
          maxDepth={0}
          renderCollapseIcon={() => <ReorderBurgerIcon />}
          onChange={({ items }) => {
            if (currentTab === "flat") return setValue("columnsFlat", items);
            if (currentTab === "sorted")
              return setValue("columnsSorted", items);
            if (currentTab === "Direct")
              return setValue("columnsDirect", items);
            if (currentTab === "Drafts")
              return setValue("columnsDrafts", items);
            if (currentTab === "3rd Party")
              return setValue("columnsThirdParty", items);
            if (
              ["rep_All", "rep_Sales", "rep_Merchandisers"].includes(currentTab)
            )
              return setValue("columnsRepAll", items);
          }}
        />
      </Box>
    </Box>
  );
};

ColumnsSettingsList.propTypes = {
  onClose: func,
  currentTab: oneOf([
    "flat",
    "sorted",
    "Direct",
    "3rd Party",
    "rep_All",
    "rep_Sales",
    "rep_Merchandisers",
    "Drafts",
  ]),
};
