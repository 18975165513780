import { useMemo } from "react";
import { shape, string, func, array, object, bool } from "prop-types";
import { Box, Typography } from "@mui/material";
import { StyledProductIco } from "components/Icons";
import { photoUrl } from "helpers/helpers";
import { PhotoCommentRoundedIcon } from "components/Icons/Activities";
import { ActionRestrictionWrapper } from "components";
import { ADMIN_ONLY_VIEW_MESSAGE } from "utils/constants";

export const PhotoItem = ({
  photo,
  onOpenProductDialog,
  boxSx,
  calcCommentsSx,
  hidePhotoCount,
  repPermissions,
}) => {
  const isDisabled = repPermissions && !repPermissions?.customers?.gallery;

  const calcComments = useMemo(() => {
    return photo?.note ? photo?._count?.comments + 1 : photo?._count?.comments;
  }, [photo?._count?.comments, photo?.note]);

  return (
    <ActionRestrictionWrapper
      title={ADMIN_ONLY_VIEW_MESSAGE}
      disableHoverListener={!isDisabled}
      //tooltipProps={{
      //  sx: {
      //    "& .MuiTooltip-tooltip": {
      //      mr: "6px",
      //    },
      //  },
      //}}
    >
      <Box component="span">
        {photo?._count?.photos > 0 ? (
          <Box
            sx={{
              width: "56px",
              minWidth: "56px",
              maxWidth: "56px",
              height: "56px",
              minHeight: "56px",
              maxHeight: "56px",
              backgroundColor: "white",
              borderRadius: "4px",
              position: "relative",
              cursor: "pointer",
              overflow: "hidden",
              ...boxSx,
            }}
            onClick={() => {
              onOpenProductDialog(photo);
            }}
          >
            <StyledProductIco
              src={photoUrl(
                photo?.photos?.[0]?.compressedFileName ||
                  photo?.photos?.[0]?.fileName
              )}
              styles={{
                objectFit: "contain",
                width: "100%",
              }}
              placeholderStyles={{
                width: 56,
                height: 56,
              }}
              showAlt={true}
            />
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                backgroundColor: !hidePhotoCount && "#5F6267",
                display: "flex",
                justifyContent: calcComments > 0 ? "space-between" : "center",
                pl: "2.5px",
                pr: "5px",
                alignItems: "center",
                width: "100%",
                height: "14px",
              }}
            >
              {!!calcComments > 0 && (
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "12px",
                    height: "14px",
                    ...calcCommentsSx,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 7.5,
                      fontWeight: 500,
                      color: "#5F6267",
                      zIndex: 1,
                      mt: hidePhotoCount && "-4px",
                    }}
                  >
                    {calcComments}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      mt: "1px",
                    }}
                  >
                    <PhotoCommentRoundedIcon />
                  </Box>
                </Box>
              )}

              {!hidePhotoCount && (
                <Typography
                  sx={{
                    fontSize: 6,
                    fontWeight: 600,
                    color: "#FFF",
                    ml: "2px",
                  }}
                >{`${photo?._count?.photos} PHOTO${
                  photo?._count?.photos > 1 ? "S" : ""
                }`}</Typography>
              )}
            </Box>
          </Box>
        ) : null}
      </Box>
    </ActionRestrictionWrapper>
  );
};

PhotoItem.propTypes = {
  photo: shape({
    id: string,
    photos: array,
  }),
  onOpenProductDialog: func,
  boxSx: object,
  calcCommentsSx: object,
  hidePhotoCount: bool,
  repPermissions: object,
};

PhotoItem.defaultProps = {
  boxSx: {},
  calcCommentsSx: {},
  hidePhotoCount: false,
};
