import { app } from "firebase/Chat/config";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { currentUserSelector } from "redux/selectors/auth";
import { createSelector } from "reselect";
import { error } from "utils/notifications";
import { useRepsPermissions } from "./hooks";

const selector = createSelector(currentUserSelector, (currentUser) => ({
  currentUser,
}));

export const useNotificationCenter = () => {
  const firestore = getFirestore(app);
  const notificationCenterRef = useRef(null);
  const [notificationsCenterCount, setNotificationsCenterCount] = useState(0);
  const [notificationsUpdatedAt, setNotificationsUpdatedAt] = useState(null);
  const repPermissions = useRepsPermissions();

  const { currentUser } = useSelector(selector);

  const [anchorNotificationCenter, setAnchorNotificationCenter] =
    useState(null);

  const openNotificationCenter = Boolean(anchorNotificationCenter);

  const onNotificationCenterOpen = (e) =>
    setAnchorNotificationCenter(e.currentTarget);

  const onCloseNotificationCenter = (e) => {
    if (e) {
      if (e.metaKey || e.ctrlKey) return;
      e.preventDefault();
    }

    setAnchorNotificationCenter(null);
  };

  const fetchDeliveriesCollection = useCallback(() => {
    onSnapshot(
      doc(
        firestore,
        `notifications/${repPermissions?.representativeId ?? currentUser?.id}`
      ),
      { includeMetadataChanges: true },
      (doc) => {
        const data = doc.data();
        setNotificationsCenterCount(data?.count || 0);
        setNotificationsUpdatedAt(data?.updatedAt || null);
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        error(`Firebase: ${err?.message || "Something went wrong"}`);
      }
    );
  }, [currentUser?.id, firestore, repPermissions?.representativeId]);

  useEffect(() => {
    if (currentUser?.id) fetchDeliveriesCollection();
  }, [currentUser?.id, fetchDeliveriesCollection]);

  return {
    setAnchorNotificationCenter,
    onNotificationCenterOpen,
    onCloseNotificationCenter,
    notificationsCenterCount,
    openNotificationCenter,
    anchorNotificationCenter,
    notificationCenterRef,
    notificationsUpdatedAt,
  };
};
