/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";

export const TaskIcon = ({ width = 12, height = 16, ...props }) => (
  <SvgIcon
    viewBox="0 0 12 16"
    xmlns="http://www.w3.org/2000/svg"
    sx={{ fill: "none", width, height }}
    {...props}
  >
    <path
      d="M3.496 8.75h4.991M3.496 11.5h4.991M3.662 15h4.659c1.255 0 1.882 0 2.272-.443.39-.444.39-1.157.39-2.584V5.162c0-1.427.119-3.275-.271-3.719S9.576 1 8.32 1H3.662C2.407 1 1.78 1 1.39 1.443 1 1.887 1 2.6 1 4.027v7.946c0 1.427 0 2.14.39 2.584S2.407 15 3.662 15"
      stroke="#A5A5A5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.988 4h.5m-.5 2h.5" stroke="#A5A5A5" strokeLinecap="round" />
    <path
      d="m3.5 5.414.701.658L6.115 4"
      stroke="#A5A5A5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
