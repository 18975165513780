import { object, func, array, string, bool } from "prop-types";
import { Grid, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";
import useStyles, { cl } from "../CategoriesComponent/styles";
import { StyledAutocomplete } from "components/TextFields/TextFields";

export const CountryBlock = ({
  control,
  setValue,
  countryOfOriginLoading,
  countryOfOriginList,
  countryOfOriginSearch,
  setCountryOfOriginSearch,
}) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item>
      <Controller
        render={({ field, fieldState: { error } }) => (
          <StyledAutocomplete
            list={countryOfOriginList}
            error={error ? error.message : ""}
            noErrorMessage
            data-testid="country_of_origin"
            label="Country of Origin"
            childItemProps={{
              onClick: (item) => {
                setCountryOfOriginSearch("");
                setValue("countryOfOrigin", item, {
                  shouldDirty: true,
                });
              },
            }}
            loading={countryOfOriginLoading}
            sx={cl.searchInput}
            className={classes.textfield}
            value={countryOfOriginSearch ? countryOfOriginSearch : field?.value}
            onChange={(e) => {
              setCountryOfOriginSearch(e.target.value);
              setValue("countryOfOrigin", null);
            }}
            showClearIcon={
              (!!countryOfOriginSearch && !field?.value) ||
              (!countryOfOriginSearch && !!field?.value)
            }
            onClear={() => {
              setCountryOfOriginSearch("");
              setValue("countryOfOrigin", null);
            }}
            CustomItem={
              <MenuItem
                value={""}
                onClick={() => {
                  setValue("countryOfOrigin", null);
                  setCountryOfOriginSearch("");
                }}
                sx={{ opacity: "50%" }}
              >
                not specified
              </MenuItem>
            }
            hideEmptyPlaceholder
          />
        )}
        name="countryOfOrigin"
        control={control}
      />
    </Grid>
  );
};

CountryBlock.propTypes = {
  control: object,
  setValue: func,
  countryOfOriginLoading: bool,
  countryOfOriginList: array,
  countryOfOriginSearch: string,
  setCountryOfOriginSearch: func,
};
CountryBlock.defaultProps = {
  control: {},
  setValue: () => {},
  countryOfOriginList: [],
  countryOfOriginSearch: "",
  setCountryOfOriginSearch: () => {},
};
