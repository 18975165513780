/* eslint-disable react/prop-types */
import { SvgIcon } from "@mui/material";
export const PersonIcon = ({ color = "#B1B1B1", ...props }) => (
  <SvgIcon
    viewBox="0 0 18 18"
    sx={{ fill: "none", width: 18, height: 18 }}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2.25c-1.162 0-2.25 1.09-2.25 2.625S7.838 7.5 9 7.5s2.25-1.09 2.25-2.625S10.162 2.25 9 2.25M5.25 4.875C5.25 2.682 6.848.75 9 .75s3.75 1.932 3.75 4.125S11.152 9 9 9 5.25 7.068 5.25 4.875M1.275 13.3a7 7 0 0 1 5.6-2.8h4.25a7 7 0 0 1 5.6 2.8l.037.05a2.438 2.438 0 0 1-1.95 3.9H3.188a2.437 2.437 0 0 1-1.95-3.9l.6.45-.6-.45zm5.6-1.3a5.5 5.5 0 0 0-4.4 2.2l-.564-.423.564.423-.038.05a.938.938 0 0 0 .75 1.5h11.626a.937.937 0 0 0 .75-1.5l-.038-.05a5.5 5.5 0 0 0-4.4-2.2z"
      fill={color}
    />
  </SvgIcon>
);
