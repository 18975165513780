import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Dialog, Link, Typography } from "@mui/material";
// import { useTrialDialogActions } from "./useTrialDialogActions";
import { DialogTitleComponent } from "./components";
import { ContactUsBar } from "components";
import {
  CONTACT_EMAIL,
  CONTACT_PHONE,
  LINK_TO_SALES,
} from "./TrialDialog.constants";
import { setTrialDialogClose } from "redux/actions/subscriptionDialogs";

const DIALOG_WIDTH = 624;

const SUBTITLE_TEXT = (
  <Typography mt="10px" fontSize={16} fontWeight={300} color="#1C1C19">
    Thanks for taking the time to explore SimplyDepo
    <br />
    platform in action. Please reach out to our
    <br />
    Sales team to start your subscription.
  </Typography>
);

const INIT_STATE = {
  TITLE: {
    DEFAULT: "Your trial has ended",
    REACHED_USERS_LIMIT: "You have reached users limit",
  },
  SUBTITLE: {
    DEFAULT: SUBTITLE_TEXT,
    REACHED_USERS_LIMIT: SUBTITLE_TEXT,
  },
};

export const TrialDialog = () => {
  const { open, shouldAllowClose } = useSelector(
    ({ subscriptionDialogs }) => subscriptionDialogs.trialDialog
  );

  const dispatch = useDispatch();

  const [type, setType] = useState("DEFAULT");

  useEffect(() => {
    if (open) return setType("REACHED_USERS_LIMIT");
  }, [open, setType]);

  // const { state, handleChooseCurrentPlan } = useTrialDialogActions();

  return (
    <Dialog
      open={open}
      onClose={shouldAllowClose ? () => dispatch(setTrialDialogClose()) : null}
      sx={{
        ".MuiPaper-root": {
          borderRadius: "18px",
          boxShadow: "none",
          width: "100%",
          maxWidth: `${DIALOG_WIDTH}px`,
          minWidth: `${DIALOG_WIDTH}px`,
        },
      }}
    >
      <Box position="relative">
        <ContactUsBar
          text="Need Help?"
          email={CONTACT_EMAIL}
          phone={CONTACT_PHONE}
          rawPhone
          left={`${DIALOG_WIDTH / 2}px`}
        />
        <Box height="90px" />
        <DialogTitleComponent
          title={INIT_STATE.TITLE[type]}
          subtitle={INIT_STATE.SUBTITLE[type]}
          onClose={
            shouldAllowClose ? () => dispatch(setTrialDialogClose()) : null
          }
        />
        <Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: "66px",
            }}
          >
            <Button
              sx={{
                width: "180px",
                height: "51px",
                fontSize: 18,
                borderRadius: "8px",
              }}
              fullWidth
              variant="contained"
              color="primary"
              component={Link}
              href={LINK_TO_SALES}
            >
              Talk to Sales
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
