import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { error } from "utils/notifications";
import { getCurrentUser } from "services/account";
import { setCurrentUser } from "redux/actions/auth";
import { useDispatch } from "react-redux";

export const ViewAdminPage = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUpdateProfile = async (onSuccess) => {
    try {
      const user = await getCurrentUser();
      dispatch(setCurrentUser(user));
      onSuccess();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err?.response?.data?.message || "Something went wrong");
      error(err?.response?.data?.message || "Something went wrong");
    }
  };

  if (token) {
    sessionStorage.setItem("userRole", "SUPER_ADMIN");
    sessionStorage.setItem("token", token);
    handleUpdateProfile(() => navigate("/"));
  }

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="inherit" size={20} />
    </Box>
  );
};

export default ViewAdminPage;
