export const cl = {
  moqInput: {
    fontSize: "12px",
    color: "#5F6267",
    fontWeight: 500,
    borderRadius: "4px",
    borderWidth: "0.5px !important",

    "& input": {
      padding: "2.5px 5px",
      textAlign: "center",
    },

    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },

    "& input::placeholder": {
      opacity: 1,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: "0.5px solid #D5D9D9",
    },

    "&.Mui-focused fieldset": {
      borderColor: "yellow",
    },

    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
      },
    },

    "& .Mui-focused": {
      border: "0.5px solid #D5D9D9 !important",
    },

    width: "56px",
    height: "24px",
    pr: "0.5px",
    position: "relative",
  },
  quantityButton: {
    borderWidth: "0.5px",
    borderRadius: "4px 0 0 4px",
    minWidth: "24px",
    width: "24px",
    p: 0,
    backgroundColor: "#F7F7F7",
    height: "100%",
    borderTop: "none",
    borderBottom: "none",
    "&.Mui-disabled": {
      borderTop: "none",
      borderBottom: "none",
      borderWidth: "0.5px",
      borderLeft: "0.5px solid #D5D9D9",
      opacity: 0.75,
    },
  },
  dollarIconWrapper: {
    position: "absolute",
    left: 0,
    transform: "translate(-50%, -50%)",
    top: "50%",
    boxShadow: "0 0 0 rgba(64, 154, 101, 0.9)",
    animation: "shadowPulse 2s infinite",
    borderRadius: "50%",
    width: "14px",
    height: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    "&:hover": { animation: "none" },
  },
  nameWrapper: {
    position: "relative",
    right: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  inventoryWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
};
