export const cl = {
  menu: {
    "&.MuiModal-root ul": { p: "0px" },
    transform: "translate(-50px, -17px)",
  },
  paperContainer: {
    width: "379px",
    boxShadow: "0px 6px 10px rgba(0,0,0,16%)",
    border: "1px solid #E5E5E5",
    borderRadius: "6px",
    transform: "translateX(200px)",
    maxHeight: "89vh",
  },
  header: {
    p: "16px",
    borderBottom: "1px solid #E5E5E5",
    justifyContent: "space-between",
    gap: "10px",
    alignItems: "center",
  },
  tabsWrapper: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #E5E5E5",
    pr: "16px",
  },
  tabs: {
    pt: "14px",
    minHeight: "38px",
    height: "38px",
    "& .MuiButtonBase-root.MuiTab-root": {
      minHeight: "18px",
    },
    "& .MuiTabs-indicator": {
      borderRadius: "6px 6px 0 0",
    },
    "& .Mui-selected": {
      backgroundColor: "transparent!important",
    },
  },
  tab: {
    fontSize: 12,
    color: "#707070",
    ml: "21px",
    py: 0,
    px: 0,
    minWidth: 40,

    "&:not(:last-child)": {
      ml: "17px",
    },
  },
  tabLabel: { textTransform: "none", pointerEvents: "auto" },
};
