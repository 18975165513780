import { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createSelector } from "reselect";
import { string, object } from "prop-types";

import {
  productTagsListSelector,
  tagsCountSelector,
} from "../../../redux/selectors/tags.js";

import { Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { CircularProgress } from "@mui/material";
import {
  deleteTagAction,
  getTagsAction,
  mergeTagsAction,
} from "../../../redux/actions/tags.js";
import { TagsIcon } from "../../../components/Icons";
import { TAGS_TYPE } from "../../../utils/constants.js";
import TagItem from "../../../components/TagComponents/TagItem/TagItem";
import TableHeader from "../../../components/TableHeader";
import { handleConfirmAction } from "../../DiscountsPage/DiscountsPage.helpers";
import { setConfirmIsOpenAction } from "../../../redux/actions/confirmDialogs";
import MergeTagDialog from "../../../components/TagComponents/MergeTagDialog/MergeTagDialog.jsx";
import { InfiniteScrollWrapper } from "components";
import { formatLongWords } from "helpers/helpers.js";

const selector = createSelector(
  productTagsListSelector,
  tagsCountSelector,
  (tagsList, tagsCount) => ({
    tagsList,
    tagsCount,
  })
);

const ProductTagsTab = ({ currentTab, repPermissions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { tagsList, tagsCount } = useSelector(selector);
  const [mergeDialogState, setMergeDialogState] = useState({
    open: false,
    tag: null,
    loading: false,
  });

  const [page, setPage] = useState(1);

  const handleDelete = useCallback(
    (tag) => {
      dispatch(
        handleConfirmAction({
          title: "Delete product tag?",
          text: (
            <Typography
              fontWeight="400"
              fontSize="13px"
              color="#363531"
              whiteSpace="pre-line"
              marginTop="4px"
              marginBottom="8px"
            >
              {"Are you sure you want to delete "}
              <span style={{ color: "#47A06D" }}>
                {formatLongWords(tag?.tag)}
              </span>
              ?
            </Typography>
          ),
          confirmColor: "confirmDelete",
          onConfirm: () => {
            dispatch(deleteTagAction(tag?.id, TAGS_TYPE.PRODUCT));
            dispatch(setConfirmIsOpenAction(false));
          },
        })
      );
    },
    [dispatch]
  );

  const handleRename = useCallback(
    (tag) => {
      navigate(`tags/${tag.id}`, { state: { ...tag, currentTab } });
    },
    [navigate, currentTab]
  );

  const handleFetchTags = useCallback(() => {
    dispatch(getTagsAction({ page: page + 1 }));
    setPage((prev) => prev + 1);
  }, [dispatch, page]);

  const handleMerge = (tag) => {
    setMergeDialogState((prev) => ({ ...prev, open: true, tag }));
  };

  const onMerge = (mergeIds) => {
    dispatch(
      mergeTagsAction(
        TAGS_TYPE.PRODUCT,
        mergeDialogState.tag?.id,
        mergeIds,
        setMergeDialogState,
        "count"
      )
    );
  };

  return (
    <Grid
      sx={{ marginTop: "64px", overflow: "auto" }}
      container
      justifyContent="center"
    >
      <MergeTagDialog
        isOpen={mergeDialogState.open}
        onClose={() =>
          setMergeDialogState((prev) => ({ ...prev, tag: null, open: false }))
        }
        tag={mergeDialogState.tag}
        tagsList={tagsList.filter((tag) => tag.id !== mergeDialogState.tag?.id)}
        loading={mergeDialogState.loading}
        {...{ onMerge, handleFetchTags, tagsCount }}
      />

      <Grid sx={{ width: "980px" }} item>
        <Paper elevation={0}>
          <Box
            sx={{
              p: "24px",
              borderBottom: "1px solid #D4D4D4",
            }}
          >
            <Typography
              sx={{
                variant: "h6",
                fontSize: "30px",
                fontWeight: "400",
                color: "#707070",
              }}
            >
              Product Tags
            </Typography>
          </Box>
          <Box p="24px">
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Box>
                <TagsIcon />
              </Box>
              <Box ml="18px">
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: 400,
                    color: "#707070",
                  }}
                >
                  Manage tags
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: "#6A6A6A",
                  }}
                >
                  Rename or delete tags. Organize product tags by your own
                  needs.
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginTop: "24px" }}>
              <TableHeader columns={12}>
                <Grid container>
                  {/* Empty block */}
                  <Grid item xs={0.6} />

                  {/* Product Tags */}
                  <Grid item xs={3.3}>
                    <Typography
                      sx={{
                        my: "6px",
                        // ml: "48px",
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#6A6A6A",
                        textTransform: "uppercase",
                        overflow: "auto",
                      }}
                    >
                      Product Tags
                    </Typography>
                  </Grid>

                  {/* Used in products */}
                  <Grid item xs={1.7}>
                    <Typography
                      sx={{
                        my: "6px",
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#6A6A6A",
                        textTransform: "uppercase",
                        overflow: "auto",
                      }}
                    >
                      Used in products
                    </Typography>
                  </Grid>

                  {/* Used in gallery */}
                  <Grid item xs>
                    <Typography
                      sx={{
                        my: "6px",
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#6A6A6A",
                        textTransform: "uppercase",
                        overflow: "auto",
                      }}
                    >
                      Used in gallery
                    </Typography>
                  </Grid>
                </Grid>
              </TableHeader>

              <Stack
                sx={{
                  height: "100%",
                  maxHeight: "288px",
                  overflow: "hidden",
                  borderWidth: "0px 1px 1px 1px",
                  borderStyle: "solid",
                  borderColor: "#D5D9D9",
                  borderRadius: "0 0 4px 4px",
                  background: !tagsList.length && "white",
                }}
              >
                {tagsCount > 0 ? (
                  <InfiniteScrollWrapper
                    maxHeight="287px"
                    dataLength={tagsList.length}
                    next={handleFetchTags}
                    resetCallback={() => setPage(1)}
                    loader={
                      <CircularProgress
                        sx={{ ml: "50%", mt: "2px" }}
                        size="30px"
                      />
                    }
                    hasMore={tagsList.length < tagsCount}
                    id="product-tags-scroll"
                  >
                    {tagsList.map((tag) => (
                      <TagItem
                        key={tag.id}
                        {...{
                          tag,
                          tagsList,
                          handleDelete,
                          handleRename,
                          handleMerge,
                          repPermissions,
                        }}
                        showGalleryCount
                        type="product"
                      />
                    ))}
                  </InfiniteScrollWrapper>
                ) : (
                  <Typography
                    fontWeight="300"
                    fontSize="16px"
                    textAlign="center"
                    width="100%"
                    color="#6A6A6A"
                    paddingTop="20px"
                    paddingBottom="20px"
                  >
                    No tags found
                  </Typography>
                )}
              </Stack>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

ProductTagsTab.propTypes = {
  currentTab: string,
  repPermissions: object,
};

export default ProductTagsTab;
