import { string, func } from "prop-types";
import { Box, Typography } from "@mui/material";
import { FormIcon } from "components/Icons/Activities/FormIcon";

export const FormItem = ({ callback = () => {}, size = "big" }) => {
  const isSmallSize = size === "small";

  return (
    <Box
      sx={{
        border: "1px solid #D5D9D9",
        borderRadius: "4px",
        width: isSmallSize ? "40px" : "56px",
        maxWidth: isSmallSize ? "40px" : "56px",
        minWidth: isSmallSize ? "40px" : "56px",
        height: isSmallSize ? "40px" : "56px",
        maxHeight: isSmallSize ? "40px" : "56px",
        minHeight: isSmallSize ? "40px" : "56px",
        display: "flex !important",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        py: "10px",
        gap: isSmallSize ? "2px" : "6px",
        cursor: "pointer",
        backgroundColor: "#F6F6F6",
        position: "relative",
      }}
      onClick={callback}
    >
      <FormIcon size={isSmallSize ? 17.5 : 23} />

      <Typography
        sx={{
          fontSize: 8,
          lineHeight: "10px",
          color: "#5F6267",
        }}
      >
        Form
      </Typography>
    </Box>
  );
};

FormItem.propTypes = {
  callback: func,
  size: string,
};
