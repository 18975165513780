import { app } from "firebase/Chat/config";
import { deleteDoc, doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  existOrdersThirdParty,
  getOrdersThirdPartyList,
  getOrdersThirdPartyCount,
  startLoadingOrdersThirdParty,
  endLoadingOrdersThirdParty,
} from "../../redux/actions/orders";
import {
  ordersExistThirdPartySelector,
  ordersGetParamsThirdPartySelector,
  ordersThirdPartyCountSelector,
  ordersThirdPartyListSelector,
  ordersThirdPartyLoadingSelector,
} from "../../redux/selectors/orders";
import { getOrdersService } from "../../services/orders";
import { FETCH_LIMITS } from "../../utils/constants";
import { error } from "../../utils/notifications";

const selector = createSelector(
  ordersThirdPartyListSelector,
  ordersThirdPartyCountSelector,
  ordersExistThirdPartySelector,
  ordersGetParamsThirdPartySelector,
  ordersThirdPartyLoadingSelector,
  (
    ordersThirdPartyList,
    ordersThirdPartyCount,
    ordersExistThirdParty,
    paramsThirdParty,
    ordersThirdPartyLoading
  ) => ({
    ordersThirdPartyList,
    ordersThirdPartyCount,
    ordersExistThirdParty,
    paramsThirdParty,
    ordersThirdPartyLoading,
  })
);

export const useThirdPartyOrdersTabActions = () => {
  const {
    ordersThirdPartyList,
    ordersThirdPartyCount,
    ordersExistThirdParty,
    ordersThirdPartyLoading,
  } = useSelector(selector);
  const dispatch = useDispatch();
  const firestore = getFirestore(app);
  const { id: currentUserId } = useSelector(
    ({ auth }) => auth.currentUser || {}
  );
  const [thirdPartyOrdersCount, setThirdPartyOrdersCount] = useState({
    count: 0,
  });

  const fetchThirdPartyOrdersCount = useCallback(async () => {
    await onSnapshot(
      doc(firestore, "count-third-party-orders", currentUserId),
      (doc) => {
        setThirdPartyOrdersCount(doc.data() || { count: 0 });
      }
    );
  }, [currentUserId, firestore]);

  useEffect(() => {
    fetchThirdPartyOrdersCount();
  }, [fetchThirdPartyOrdersCount]);

  const deleteThirdPartyOrdersCount = useCallback(async () => {
    try {
      const documentRef = doc(
        firestore,
        "count-third-party-orders",
        currentUserId
      );
      await deleteDoc(documentRef);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error deleting document: ", error);
    }
  }, [firestore, currentUserId]);

  const [cursor, setCursor] = useState("");

  const handleFetchOrdersThirdParty = (params, isScrolling = false) => {
    dispatch(startLoadingOrdersThirdParty());
    getOrdersService(
      params
        ? { ...params, cursor }
        : {
            type: "THIRD_PARTY",
            limit: FETCH_LIMITS.ORDERS,
            cursor,
            sort_by_order_date: "desc",
          }
    )
      .then((res) => {
        dispatch(existOrdersThirdParty(res.existData));
        setCursor(res.cursor);
        dispatch(
          getOrdersThirdPartyList(
            params && !isScrolling
              ? [...res.rows]
              : [...ordersThirdPartyList, ...res.rows]
          )
        );
        dispatch(getOrdersThirdPartyCount(res.count));
      })
      .catch((err) => {
        error(err?.response?.data?.message || err?.message);
        // eslint-disable-next-line no-console
        console.error(err?.message);
      })
      .finally(() => {
        dispatch(endLoadingOrdersThirdParty());
      });
  };

  return {
    ordersThirdPartyList,
    ordersThirdPartyCount,
    ordersExistThirdParty,
    handleFetchOrdersThirdParty,
    thirdPartyOrdersCount: thirdPartyOrdersCount.count,
    deleteThirdPartyOrdersCount,
    setCursor,
    cursor,
    ordersThirdPartyLoading,
  };
};
